import { useEffect, useState, useRef, useContext } from "react";
import ServiceUtils from "../../utils/services";
import { GET_CALENDAR_DATA, NGUOI_GIAO_VIEC_DETAIL } from "../../apis/_index";
import { toast } from "react-toastify";
import { ModalController } from "../homeUI/_index";
import { useDispatch } from "react-redux";
import {
  ONCHANGE_FROMDATE,
  ONCHANGE_TODATE,
  ONCHANGE_VIEW,
  ONLOAD_EVENTLIST,
} from "../../redux/reducers/ScheduleReducer/_index";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CustomToolbar, EventCustom } from "./components";
import HandleDate from "../../utils/date/handleDate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
const localizer = momentLocalizer(moment);

interface Event {
  event_id?: string;
  start: Date;
  end: Date;
  title: string;
  type: number;
  status: number;
}

const ScheduleUI = () => {
  // const [fromDate, setFromDate] = useState<any>(
  //   new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
  //     .toISOString()
  //     .slice(0, 24)
  // );
  // const [toDate, setToDate] = useState<any>(
  //   new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1, 7, 0, 0)
  //     .toISOString()
  //     .slice(0, 24)
  // );
  // const [events, setEvents] = useState<Event[]>([]);
  const context = useContext(ModalController);
  const dispatch = useDispatch();
  const { eventList, fromDate, toDate, optionList, selectedUser } = useSelector(
    (state: any) => state.scheduleReducer
  );
  // const [view, setView] = useState<string>("month");

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
  //       const res = await ServiceUtils.getV2(USER_READ_ALL);
  //       if (res.data.StatusCode === 200) {
  //         const users = res.data.Data;
  //         const convertedUserOptions = users.map((user: any) => ({
  //           label: user.FullName,
  //           value: user.Id,
  //         }));
  //         setUserList(convertedUserOptions);
  //       }
  //     } catch {
  //       toast.error(
  //         "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
  //       );
  //     }
  //   };

  //   fetchUser();
  // }, []);

  const processedEvents = (event: any) => {
    return {
      event_id: event.eventId,
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      type: event.type,
      status: event.TaskStatus,
    };
  };

  const isCheckAll = () => {
    return optionList.every((option: any) => option.checked);
  };

  const isCheckNotAll = () => {
    return optionList.every((option: any) => !option.checked);
  };
  const fetchScheduleData = async () => {
    let text = "&";
    if (isCheckNotAll()) {
      dispatch(ONLOAD_EVENTLIST([]));
      return;
    }

    if (isCheckAll()) {
      text += "type=0";
    } else {
      optionList?.forEach((item: any) => {
        if (item.checked) {
          text += "type=" + item.value + "&";
        }
      });
      text = text.slice(0, -1);
    }

    // if (dataselectedOptions.length === 0) {
    //   text = "type=0";
    // } else {
    //   selectedOptions.map((item: any) => {
    //     text += "type=" + item.value + "&";
    //   });
    //   text = text.slice(0, -1);
    // }
    let text2 = "";
    if (selectedUser?.value) {
      text2 = "&employee=" + selectedUser.value;
    } else {
      text2 = "";
    }

    let url = `${GET_CALENDAR_DATA}?start=${fromDate}&end=${toDate}${text}${text2}`;

    const res = await ServiceUtils.getV2(url);
    try {
      if (res.data.StatusCode === 200) {
        const data = res.data.Data;
        const processedEventsArray = data.map((event: any) =>
          processedEvents(event)
        );
        dispatch(ONLOAD_EVENTLIST(processedEventsArray));
      }
    } catch {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };
  useEffect(() => {
    // const first = startOfWeek(
    //   new Date(
    //     new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
    //       .toISOString()
    //       .slice(0, 24)
    //   ),
    //   {
    //     weekStartsOn: 0,
    //   }
    // );
    // const last = endOfWeek(
    //   new Date(
    //     new Date(
    //       new Date().getFullYear(),
    //       new Date().getMonth() + 1,
    //       1,
    //       7,
    //       0,
    //       0
    //     )
    //       .toISOString()
    //       .slice(0, 24)
    //   ),
    //   {
    //     weekStartsOn: 0,
    //   }
    // );
    // last.setDate(last.getDate() + 1);
    // dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    // dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(last)));
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth());
    const first = startOfWeek(new Date(startOfMonth(newDate)), {
      weekStartsOn: 0,
    });

    const end = endOfWeek(new Date(endOfMonth(newDate)), {
      weekStartsOn: 0,
    });
    end.setDate(end.getDate() + 1);
    dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));
    dispatch(ONCHANGE_VIEW("month"));
  }, []);

  useEffect(() => {
    fetchScheduleData();
  }, [fromDate, toDate, selectedUser, optionList]);

  // const handleCategoryChange = (selectedValues: any) => {
  //   setSelectedOptions(selectedValues);
  // };

  const [clientWidthOptionListScroll, setClientWidthOptionListScroll] =
    useState<any>(0);
  const [clientWidthNavbar, setClientWidthNavbar] = useState<any>(0);
  const scheduleRef = useRef<any>(null);

  useEffect(() => {
    if (scheduleRef.current) {
      scheduleRef.current.scrollLeft = scheduleRef.current.scrollWidth;
    }
  }, []);

  useEffect(() => {
    const optionListScroll = document.getElementById("optionListScroll");

    setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);

    let windowWidth = window.visualViewport?.width ?? window.innerWidth;

    const clientWidthNavbar = calculatorWidthNavbar(windowWidth);

    setClientWidthNavbar(clientWidthNavbar);

    window.addEventListener("resize", () => {
      windowWidth = window.visualViewport?.width ?? window.innerWidth;

      const clientWidthNavbar = calculatorWidthNavbar(windowWidth);

      setClientWidthNavbar(clientWidthNavbar);

      const optionListScroll = document.getElementById("optionListScroll");

      setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);
    });
  }, []);

  const calculatorWidthNavbar = (windowWidth: any) => {
    const navbar = document.getElementById("navbar");
    const navbar2 = document.getElementById("navbar2");

    const isMobile = windowWidth <= 1024 ? true : false;

    let clientWidthNavbar = 0;

    if (navbar && !isMobile) {
      clientWidthNavbar = navbar.clientWidth ?? 0;
    }

    if (navbar2 && isMobile) {
      clientWidthNavbar = navbar2.clientWidth ?? 0;
    }

    return clientWidthNavbar;
  };

  const isSmallScreen = window.innerWidth < 500;

  const _onSelectEvent = async (event: Event) => {
    if (event.type === 1) {
      if (event.event_id) {
        const API = NGUOI_GIAO_VIEC_DETAIL(event.event_id);
        ServiceUtils.getV2(API)
          .then((res: any) => {
            if (res?.data && res?.data.StatusCode === 200) {
              const task = res.data.Data.task;
              context.setTaskId(task.ID);
              if (task.TaskStatus === 5) {
                context.setFuncs("taskNeedConfirm");
              } else {
                context.setFuncs("readTotalTask_nguoigiao");
              }
              context.setDataModal({
                tempFuncs: fetchScheduleData,
              });
              context.handleOpen();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((error: any) => {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          });
        // const task = await fetchTaskById(event.event_id);
        // console.log("object, ", task);
        // context.setTaskId(taskDetails.ID);
        // if (taskDetails.TaskStatus === 5) {
        //   context.setFuncs("taskNeedConfirm");
        // } else {
        //   context.setFuncs("readTotalTask_nguoigiao");
        // }

        // context.handleOpen();
      }
    } else if (event.type === 4) {
      if (event.event_id) {
        context.setFuncs("detailOnLeaving");
        context.setTaskId(event.event_id);
        context.setDataModal({
          tempFuncs: fetchScheduleData,
        });
        context.handleOpen();
      }
    } else if (event.type === 3) {
      if (event.event_id) {
        context.setFuncs("detailOnHoliday");
        context.setTaskId(event.event_id);
        context.setDataModal({
          tempFuncs: fetchScheduleData,
        });
        context.handleOpen();
      }
    } else if (event.type === 5) {
      if (event.event_id) {
        context.setFuncs("detailMeeting");
        context.setTaskId(event.event_id);
        context.setDataModal({
          tempFuncs: fetchScheduleData,
        });
        context.handleOpen();
      }
    } else if (event.type === 6) {
      if (event.event_id) {
        context.setFuncs("detailBusinessTrip");
        context.setTaskId(event.event_id);
        context.setDataModal({
          tempFuncs: fetchScheduleData,
        });
        context.handleOpen();
      }
    }
  };

  const handleShowMore = (events: Event[], date: Date) => {
    console.log("Data:", events);
    console.log("Date:", date);
    context.setFuncs("showMoreSchedule");
    context.setTaskId(events);
    context.setDataModal({
      tempFuncs: fetchScheduleData,
    });
    context.handleOpen();
  };

  const handleDrillDown = () => {
    return false;
  };

  // const handleSelect = ({ start, end }: { start: Date; end: Date }) => {
  //   const title = window.prompt("New Event name");
  //   const type = 2;
  //   if (title) {
  //     const newEvent: Event = {
  //       start,
  //       end,
  //       title,
  //       type,
  //     };
  //     console.log("newEvent, ", newEvent);
  //     setEvents([...events, newEvent]);
  //   }
  // };

  // const handleEventDrop = ({ event, start, end }: any) => {
  //   const updatedEvents = events.map((e: any) =>
  //     e.event_id === event.event_id
  //       ? { ...e, start: new Date(start), end: new Date(end) }
  //       : e
  //   );

  //   setEvents(updatedEvents);

  //   // You can perform additional actions here, such as making an API call to update the event on the server
  //   console.log("Event dropped:", event);
  //   console.log("New start date:", start);
  //   console.log("New end date:", end);
  // };

  // const onChangeEventTime = useCallback(
  //   ({
  //     event,
  //     start,
  //     end,
  //     event_id,
  //   }: {
  //     event: Event;
  //     start: stringOrDate;
  //     end: stringOrDate;
  //     event_id: number;
  //   }) => {
  //     setEvents((prevEvents) =>
  //       prevEvents.map((prevEvent) =>
  //         prevEvent?.data?.appointment?.id === event?.data?.appointment?.id
  //           ? { ...event, start, end, resourceId }
  //           : prevEvent
  //       )
  //     );
  //   },
  //   []
  // );

  // const onDroppedFromOutside = useCallback(
  //   ({
  //     start,
  //     end,
  //     resource,
  //   }: {
  //     start: stringOrDate;
  //     end: stringOrDate;
  //     resource: number;
  //   }) => {
  //     if (draggedEvent === "undroppable") return;
  //     setEvents((prevEvents) => [
  //       ...prevEvents,
  //       {
  //         start,
  //         end,
  //         resourceId: resource,
  //         data: { appointment: draggedEvent },
  //         isDraggable: true,
  //         isResizable: true,
  //       },
  //     ]);
  //   },
  //   [draggedEvent]
  // );

  // useEffect(() => {
  //   if (workerData.receiverList === null) {
  //     loadReceiver(
  //       (res: any) => dispatch(ONLOAD_RECEIVERLIST(res.data.Data)),
  //       shouldLog1
  //     );
  //   }
  // });
  return (
    <div className="relative">
      <div
        style={{
          width: isSmallScreen
            ? `calc(100vw - ${
                clientWidthOptionListScroll + clientWidthNavbar
              }px)`
            : undefined,
          height: isSmallScreen ? "calc(100vh - 50px)" : "calc(100vh - 100px)",
        }}
        className="lg:px-3  w-full mx-auto relative"
      >
        <Calendar
          step={15}
          timeslots={4}
          localizer={localizer}
          events={eventList}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "100%", overflowY: "auto" }}
          views={["day", "week", "month", "agenda"]}
          defaultDate={new Date()}
          defaultView="month"
          onSelectEvent={_onSelectEvent}
          // onSelectSlot={handleSelectSlot}
          eventPropGetter={(event: Event) => {
            let color = "gray";

            const matchingItem = optionList.find(
              (item: any) => item.value == event.type
            );
            if (matchingItem) {
              color = matchingItem.color;
            }

            const style = {
              backgroundColor: color,
            };

            return {
              style,
            };
          }}
          components={{
            toolbar: (props: any) => (
              <CustomToolbar {...props} fetchScheduleData={fetchScheduleData} />
            ),
            event: EventCustom,
          }}
          messages={{
            showMore: (total) => `+ ${total}`,
          }}
          onShowMore={handleShowMore}
          onDrillDown={handleDrillDown}
        />
      </div>
      <ReactTooltip
        id="my-tooltip-3"
        place="bottom"
        content="Đang xử lý"
        className="z-[999]"
      />
      <ReactTooltip
        id="my-tooltip-4"
        place="bottom"
        content="Hoàn tất"
        className="z-[999]"
      />
      <ReactTooltip
        id="my-tooltip-2"
        place="bottom"
        content="Chưa xử lý"
        className="z-[999]"
      />
      <ReactTooltip
        id="my-tooltip-1"
        place="bottom"
        content="Chưa nhận"
        className="z-[999] "
      />
    </div>
  );
};
export default ScheduleUI;
