import React, { useRef, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { KE_HOACH_GUI, KE_HOACH_SEND_BOSS } from "../../../apis/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import SendIcon from "@mui/icons-material/Send";

import DeleteIcon from "@mui/icons-material/Delete";

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";

import {
  NGUOI_GIAO_VIEC_XOA,
  NGUOI_GIAO_VIEC_LIST,
} from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";

const PlanSend = (props: any) => {
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const shouldLog = useRef(true);
  const urlLink = window.location.pathname;
  const context = useContext(ModalController);

  const onLoad = context.funcsLoad?.onLoad;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // funcs
  const _onClickSend = async () => {
    const accessToken = localStorage.getItem("accessToken");

    const fetchData = async () => {
      try {
        await ServiceUtils.getV2(KE_HOACH_SEND_BOSS + `?ID=${taskId}`, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Gửi kế hoạch thành công");
            onLoad();
            handleClose();
            context.handleClose();
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData();
  };

  return (
    <div
      className="
      flex bg-emerald-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <SendIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>
          Bạn có đồng ý <span className="font-semibold">GỬI</span> kế hoạch này
          không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className="w-[5rem] h-max p-2 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickSend}
            disabled={isLoading}
          >
            <p>Có</p>
          </button>
          <button
            className="w-[5rem] h-max p-2 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanSend;
