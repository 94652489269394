import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";
import SubModal from "../_subModal/_index";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import { onTouchForm } from "../../../funcs/onTochForm";

import { takePermission } from "../../../funcs";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

import {
  PRIOR_DETAIL,
  PRIOR_UPDATE,
  PRIOR_LIST,
  SECURITY_DETAIL,
  SECURITY_UPDATE,
  SECURITY_READ_ALL,
} from "../../../apis/_index";
import { ONLOAD_DIFFICULT_LIST } from "../../../redux/reducers/settingReducer/_index";
import { DIFICULT_UPDATE, DIFFICULT_LEVEL } from "../../../apis/_index";
import {
  ONLOAD_PRIORLIST,
  ONLOAD_SECURITY_LIST,
} from "../../../redux/reducers/settingReducer/_index";
import { toast } from "react-toastify";

import { DIFICULT_DETAIL } from "../../../apis/_index";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";
const DetailSecurity = (props: any) => {
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const shouldLog = useRef(true);

  const menu = takePermission(40);

  const dispatch = useDispatch();

  // state
  const [securityState, setSecurityState] = useState<any>("");
  const [securityDescription, setSecurityDescription] = useState<any>("");
  const [securityLevel, setSecurityLevel] = useState<any>("");
  const [securityColor, setSecurityColor] = useState<any>("#aabbcc");

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  // funcs
  const _onClickDelete = () => {
    handleOpenSub();
    setFuncsSub("deleteDificult");
  };

  const _onChangeSecurityState = (e: any) => {
    e.preventDefault();
    setSecurityState(e.target.value);
  };

  const _onChangeSecurityDescription = (e: any) => {
    e.preventDefault();
    setSecurityDescription(e.target.value);
  };

  const _onChangeSecurityLevel = (e: any) => {
    e.preventDefault();
    setSecurityLevel(e.target.value);
  };

  const _onClickSubmit = async () => {
    const body: any = {
      Id: taskId,
      DifficultLevelName: securityState,
      Color: securityColor,
      Description: securityDescription,
      LevelNumber: securityLevel,
    };

    try {
      await ServiceUtils.postV2(DIFICULT_UPDATE, body, {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Cập nhật thành công.");
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(DIFFICULT_LEVEL, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_DIFFICULT_LIST(res.data.Data));
                }
              });
            } catch (error: any) {
              // toast.error(
              //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              // );
            }
          };

          takeData();
        } else {
          toast.error("Cập nhật thất bại.");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  useEffect(() => {
    if (
      securityState === "" &&
      securityDescription === "" &&
      securityLevel === "" &&
      securityColor === "#aabbcc" &&
      shouldLog.current
    ) {
      const accessToken = localStorage.getItem("accessToken");
      const API = DIFICULT_DETAIL + taskId.toString();
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setSecurityState(res.data.Data[0].DifficultLevelName);
            setSecurityDescription(res.data.Data[0].Description);
            setSecurityLevel(res.data.Data[0].LevelNumber);
            setSecurityColor(res.data.Data[0].Color);
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[52rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
    rounded-md
  "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết / Chỉnh sửa</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên độ khó&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={securityState}
              onChange={(e: any) => {
                _onChangeSecurityState(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Mô tả&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
              value={securityDescription}
              onChange={(e: any) => {
                _onChangeSecurityDescription(e);
              }}
            />
          </div>
        </div>

        <div className="flex w-full h-max space-x-6">
          <div className="w-1/3 h-max flex flex-col space-y-6">
            <div className="flex items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Hiển thị </label>
              <input type="checkbox" className="w-5 h-5" />
            </div>

            <div className="flex lg:flex-row flex-col items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Mức độ</label>
              <input
                className="lg:w-1/3 w-full h-max rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={securityLevel}
                onChange={(e: any) => {
                  _onChangeSecurityLevel(e);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>

          <div className="flex flex-col w-2/5 h-max">
            <p>Màu sắc</p>

            <div className="w-full h-max ">
              <div className="w-[6rem] h-[6rem]">
                <HexColorPicker
                  color={securityColor}
                  onChange={setSecurityColor}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div
        className={`flex w-full p-12-16 footer rounded-b-md ${
          menu.PerDelete === true ? "justify-between" : "justify-end"
        } items-center text-sm space-x-4`}
      >
        <button
          className={`
          items-center space-x-1 w-max h-max p-2 bg-slate-500 text-white rounded-md
          hover:bg-slate-400
          ${menu.PerDelete === true ? "flex" : "hidden"}
        `}
          onClick={_onClickDelete}
        >
          <DeleteIcon fontSize="small" />
          <p>Xoá</p>
        </button>

        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Cập nhật</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailSecurity;
