export const formatFileSize = (fileSizeInBytes: number) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;
  
    if (fileSizeInBytes < kilobyte) {
      return fileSizeInBytes + ' Bytes';
    } else if (fileSizeInBytes < megabyte) {
      return (fileSizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else if (fileSizeInBytes < gigabyte) {
      return (fileSizeInBytes / megabyte).toFixed(2) + ' MB';
    } else {
      return (fileSizeInBytes / gigabyte).toFixed(2) + ' GB';
    }
};