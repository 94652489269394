import React from "react";

import HomeUI from "../../../../ui/homeUI/_index";

const ReportWaitingVerify = () => {
  return (
    <>
      <HomeUI />
    </>
  );
};

export default ReportWaitingVerify;