import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import {
  ONLOAD_SELECTED_RECEIVER,
  ONLOAD_SELECTED_PRIOR,
  ONCHANGE_TASK_NAME,
} from "../../redux/reducers/workingReducer/_index";

const WorkingReciverTaskMyselfTSX = () => {
  const urlLink = window.location.pathname;
  // change funcs
  const renameObjectKey = (object: any) => {
    object.FullName && (object.label = object.FullName);

    object.FullName && (object.value = object.FullName);

    object.PriorityLevelName && (object.label = object.PriorityLevelName);

    object.PriorityLevelName && (object.value = object.PriorityLevelName);

    object.label && (object.value = object.label);

    object.Name && (object.label = object.Name);
  };

  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const tempPriorList = workingData.priorList?.map((item: any) => {
    return {
      ...item,
      label: item.PriorityLevelName,
      value: item.PriorityLevelName,
    };
  });

  const [selectedReceiver, setSelectedReceiver] = useState("");
  useEffect(() => {
    setSelectedReceiver("");
  }, [urlLink]);

  return (
    <div className="flex lg:flex-row flex-col w-full h-max justify-between gap-2 lg:gap-5 mb-2">
      <div className="flex flex-col w-full pr-0 h-max">
        <label className="mb-1">Tên công việc</label>
        <input
          className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
          placeholder="Nhập tên công việc"
          value={workingData.searchFilter.taskName}
          onChange={(e: any) => {
            e.preventDefault();
            dispatch(ONCHANGE_TASK_NAME(e.target.value));
          }}
          // onTouchStart={(e: any) => onTouchForm(e)}
        />
      </div>

      {/* <div className="flex flex-col w-full pr-0 h-max">
        <label className="mb-1">Người nhận</label>

        <Select
          className="z-[6]"
          options={tempReceiverList}
          onChange={(data: any) => {
            dispatch(ONLOAD_SELECTED_RECEIVER(data));
            setSelectedReceiver(data);
          }}
          value={selectedReceiver}
          isClearable
          placeholder="--- Chọn người nhận ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div> */}

      <div className="flex flex-col w-full pr-0 h-max">
        <label className="mb-1">Mức độ</label>
        <Select
          className="z-[5]"
          options={tempPriorList}
          onChange={(data: any) => dispatch(ONLOAD_SELECTED_PRIOR(data))}
          isClearable
          placeholder="--- Chọn mức độ ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  );
};

export default WorkingReciverTaskMyselfTSX;
