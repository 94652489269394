import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import InfoIcon from "@mui/icons-material/Info";
import ListIcon from "@mui/icons-material/List";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useRef, useState } from "react";
import {
  ContentUI,
  Add,
  DropdownType,
  DropdownPerson,
  DropdownRecentEdit,
} from "./components";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { searchData } from "./utils/helper";
import {
  ONLOAD_FILELIST,
  ONLOAD_FOLDERLIST,
} from "../../redux/reducers/driveReducer/_index";
import { toast } from "react-toastify";
import DeviceUtils from "../../utils/devices/deviceUtils";
import { VARIABLES } from "../../constants/constants";
const FileComponent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const initialChangeType = localStorage.getItem("changeType") === "true";
  const [changeType, setChangeType] = useState(initialChangeType);


  const [clientWidthOptionListScroll, setClientWidthOptionListScroll] =
    useState<any>(0);
  const [clientWidthNavbar, setClientWidthNavbar] = useState<any>(0);
  const [offsetWidth, setOffsetWidth] = useState<any>(0);

  useEffect(() => {
    localStorage.setItem("changeType", String(changeType));
  }, [changeType]);

  const _onClickChangeType = () => {
    setChangeType((prevChangeType) => !prevChangeType);
  };

  const systemData = useSelector((state: any) => state.systemReducer);

  const spreadrums = systemData.spreadrums || [];

  const onClickSpreadRum = (item: any) => {
    if (item) {
      if (item.callBack) {
        item.callBack();
      }
    }
  };

  const renderSpreadRum = () => {
    const elements = spreadrums.map((p: any, index: any) => {
      return (
        <div className="flex whitespace-nowrap">
          <p
            className={`cursor-pointer items-center text-md leading-normal antialiased duration-300 font-medium transition-colors hover:text-[#00b1ff] ${
              id == p.id ? "text-[#00b1ff]" : " text-gray-800"
            } `}
            onClick={() => onClickSpreadRum(p)}
          >
            {p.name}
          </p>

          {index < spreadrums.length - 1 && <NavigateNextIcon />}
        </div>
      );
    });

    return elements;
  };

  const BreadScrumRef = useRef<any>(null);

  useEffect(() => {
    if (BreadScrumRef.current) {
      BreadScrumRef.current.scrollLeft = BreadScrumRef.current.scrollWidth;
    }
  }, [renderSpreadRum]);

  useEffect(() => {
    const optionListScroll = document.getElementById("optionListScroll");

    setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);
  }, [systemData.navBarButton]);

  useEffect(() => {
    const optionListScroll = document.getElementById("optionListScroll");

    setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);
  }, []);

  useEffect(() => {
    let windowWidth = window.visualViewport?.width ?? window.innerWidth;

    const clientWidthNavbar = calculatorWidthNavbar(windowWidth);

    setClientWidthNavbar(clientWidthNavbar);

    setOffsetWidth(calculatorOffsetWidth(windowWidth));

    window.addEventListener("resize", () => {
      windowWidth = window.visualViewport?.width ?? window.innerWidth;

      setOffsetWidth(calculatorOffsetWidth(windowWidth));

      const clientWidthNavbar = calculatorWidthNavbar(windowWidth);

      setClientWidthNavbar(clientWidthNavbar);

      const optionListScroll = document.getElementById("optionListScroll");

      setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);
    });
  }, []);

  const calculatorOffsetWidth = (windowWidth: any) => {
    return windowWidth <= 1024 ? 44 : 80;
  };

  const calculatorWidthNavbar = (windowWidth: any) => {
    const navbar = document.getElementById("navbar");
    const navbar2 = document.getElementById("navbar2");

    const isMobile = windowWidth <= 1024 ? true : false;

    let clientWidthNavbar = 0;

    if (navbar && !isMobile) {
      clientWidthNavbar = navbar.clientWidth ?? 0;
    }

    if (navbar2 && isMobile) {
      clientWidthNavbar = navbar2.clientWidth ?? 0;
    }

    return clientWidthNavbar;
  };

  // ${systemData.navBarButton == "true" ? "w-[70vw]" : "w-[90vw]
  const isSearch = () => {
    if (spreadrums.length == 1 && spreadrums[0].id == "search") return true;
    return false;
  };

  return (
    <>
      <div
        className="flex flex-col w-full h-full "
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className=" bg-white z-5 pb-5 w-full ">
          {/* <nav className="w-[80%] text-gray-900 bg-gray-50 rounded-lg border border-gray-300">
              <menu className="overflow-y-scroll whitespace-nowrap [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
                {renderSpreadRum()}
              </menu>
            </nav> */}
          {/* <nav className="">
              <div className="flex i">
                {renderSpreadRum()}
              </div>
            </nav> */}
          <div className=" text-gray-900 rounded-md bg-gray-50 border border-gray-300 m-3 p-2 w-[100%-2.5rem]">
            <div
              className={`overflow-x-scroll overflow-hidden flex`}
              ref={BreadScrumRef}
              style={{
                width: `calc(100vw - ${
                  clientWidthOptionListScroll + clientWidthNavbar + offsetWidth
                }px)`,
              }}
              id="style-4"
            >
              {renderSpreadRum()}
            </div>
          </div>
          {/* <form className="flex w-full lg:w-[300px]">
              <input
                type="search"
                className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-l-lg rounded-r-none border border-gray-300 focus:ring-[#00b1ff] focus:border-[#00b1ff]"
                placeholder="Tìm kiếm..."
                required
                onChange={(e) => setSearchStr(e.target.value)}
              />
              <button
                type="submit"
                onClick={_onClickSearch}
                className="p-2 text-white bg-[#00b1ff] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-r-lg text-sm"
              >
                <SearchIcon />
              </button>
            </form> */}

          <div className="flex justify-between gap-3 px-3">
            <div className="text-left flex gap-3">
              {id != "trash" && id != "share" && !isSearch() && (
                <>
                  <Add />
                  {/* <DropdownType />
                <DropdownPerson />
                <DropdownRecentEdit /> */}
                </>
              )}
            </div>
            <div className="flex gap-3 items-center">
              {changeType ? (
                <CalendarViewMonthIcon
                  onClick={_onClickChangeType}
                  className="cursor-pointer"
                />
              ) : (
                <ListIcon
                  onClick={_onClickChangeType}
                  className="cursor-pointer"
                />
              )}
              <InfoIcon className="cursor-pointer" />
            </div>
          </div>
        </div>

        <ContentUI changeType={changeType} />
      </div>
    </>
  );
};
export default FileComponent;
