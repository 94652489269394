import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";

// components importing
import Title from "../components/title";
import SubmitHeader from "../components/submitHeader";
import ContentTop from "../components/contentTop";
import ContentBottom from "../components/contentBottom";
import Table from "../components/table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DateTimePickerControl from "../controls/datetimePicker/datetimePickerControl";
import { loadBoss } from "../funcs/taskNguoiGiaoViec";
import ReplayIcon from "@mui/icons-material/Replay";
import AddIcon from "@mui/icons-material/Add";

import {
  VotingListNeedToJoin,
  VotingListNeedToJoinWithParams,
  VotingListRequest,
  VotingListRequestWithParams,
} from "../funcs/_votingFuncs";

import {
  NGUOI_GIAO_VIEC_LIST,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
  NGUOI_GIAO_VIEC_LIST_DELAY,
  NGUOI_GIAO_VIEC_LIST_END_TODAY,
  NGUOI_GIAO_VIEC_LIST_END_SOON,
  NGUOI_NHAN_VIEC_LIST,
  NGUOI_NHAN_VIEC_LIST_RECEIVED,
  NGUOI_NHAN_VIEC_LIST_PROCESSING,
  NGUOI_NHAN_VIEC_LIST_DELAY,
  NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
  NGUOI_GIAO_VIEC_LIST_FINISH,
  NGUOI_GIAO_VIEC_LIST_TOTAL,
  NGUOI_NHAN_VIEC_LIST_END_SOON,
  NGUOI_NHAN_VIEC_LIST_ALL,
  NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
  NGUOI_GIAO_LOAD_TASK_LATE,
  NGUOI_GIAO_LOAD_TASK_EARLY,
  NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
  NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
  NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
  NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
  NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
  NGUOI_GIAO_TASK_RETURN,
  NGUOI_NHAN_TASK_RETURN,
  NGUOI_NHAN_CHO_DUYET_TASK,
  NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
  NGUOI_NHAN_VIEC_LIST_SHARED,
  PROPOSESENDLIST,
  PROPOSEGETLIST,
  TASK_RECOVERY_LIST,
  TASK_REVOKE_LIST,
  SETTING_PROCEDUCE_LIST,
  NGUOI_NHAN_VIEC_TASK_MY_SELF,
  NGUOI_GIAO_LOAD_TASK_MY_SELF,
  NGUOI_GIAO_TASK_MYSELF_ALL,
} from "../apis/_index";
import {
  ONCHAGE_FILTER_RESULT_WITH_NUMBER,
  ONCHANGE_CHOOSEPAGE,
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_SEARCH_MONTH,
  ONCHANGE_SEARCH_YEAR,
  ONCHANGE_TOI_HAN_HOM_NAY,
  ONLOAD_SETTINGPROCEDUCE_ALL,
  ONLOAD_TASKLIST_ALL,
  ONLOAD_TASKMYSELF_NGUOINHAN,
  ONLOAD_TASKRECOVERYLIST_ALL,
  ONLOAD_TASKREVOKELIST_ALL,
} from "../redux/reducers/workingReducer/_index";
import {
  ONLOAD_RECEIVERLIST,
  ONLOAD_PRIORLIST,
  ONCHANGE_NGUOI_NHAN_RETURN,
  ONCHANGE_VIEC_CAN_GIAO,
  ONCHANGE_DANG_TRE_HAN,
  ONCHANGE_SAP_TOI_HAN,
  ONCHANGE_NGUOI_NHAN_MOI,
  ONCHANGE_NGUOI_NHAN_DA_NHAN,
  ONSET_RETURN_PAGE_CURRENT,
  ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN,
  ONCHANGE_NGUOI_NHAN_TRE_HAN,
  ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY,
  ONCHANGE_NGUOI_GIAO_HOAN_TAT,
  ONCHANGE_NGUOI_GIAO_TONG_HOP,
  ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN,
  ONCHANGE_NGUOI_NHAN_TAT_CA,
  ONCHANGE_TASKLIST_WITH_NORMAL,
  ONCHANGE_NGUOI_NHAN_XONG_SOM,
  ONCHANGE_NGUOI_NHAN_XONG_TRE,
  ONCHANGE_NGUOI_NHAN_XONG,
  ONCHANGE_NGUOI_GIAO_DANG_XU_LY,
  ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN,
  ONLOAD_BOSS_LIST,
  ONCHANGE_RESET_FILTER,
  ONCHANGE_TASK_WAITING,
  ONCHANGE_VOTING_LIST,
  ONCHANGE_TASK_COMPLETED_PUNCTUAL,
  ONCHANGE_TASK_COMPLETED_EARLY,
  ONCHANGE_TASK_COMPLETED_LATE,
  ONCHANGE_NGUOI_GIAO_RETURN,
  ONCHANGE_NGUOI_NHAN_CHIA_SE,
  ONLOAD_TASKMYSELF_NGUOIGIAO,
  ONCHANGE_NGUOI_GIAO_TONG_HOP_TU_TAO,
} from "../redux/reducers/workingReducer/_index";

import { ONCHANGE_REPORT_NANG_LUC_NHAN_SU_NGUOI_GIAO } from "../redux/reducers/reportReducer/_index";

import { readList, loadPrior, loadReceiver } from "../funcs/taskNguoiGiaoViec";
import { _onClickFuncs } from "../funcs/onClickShowBtn";
import { StarRate } from "@mui/icons-material";

import SearchIcon from "@mui/icons-material/Search";
import { propose } from "../funcs/propose";
import { NAVIGATION_KEYS } from "../constants/constants";
import HandleDate from "../utils/date/handleDate";
import { ModalController } from "../ui/homeUI/_index";
import Filter from "./filter";
import { useLocation } from "react-router-dom";

const ContentCore = () => {
  // redux
  const { workingReducer: data } = useSelector((state: any) => ({ ...state }));
  const dispatch = useDispatch();
  const link = window.location.pathname;
  const location = useLocation();
  const { state } = location;

  let currentMonth =
    state?.month != null && state?.year != null
      ? state?.month - 1
      : new Date().getMonth();
  let currentYear =
    state?.month != null && state?.year != null
      ? state?.year
      : new Date().getFullYear();
  let nextMonth;
  let nextYear;
  if (currentMonth + 1 === 12) {
    nextMonth = 0;
    nextYear = parseInt(currentYear, 10) + 1;
  } else {
    nextMonth = currentMonth + 1;
    nextYear = currentYear;
  }

  const [fromDate, setFromDate] = useState(
    HandleDate.firstDayOfTheMonth(link, currentMonth, currentYear)
  );
  const [toDate, setToDate] = useState(
    HandleDate.firstDayOfTheMonth(link, nextMonth, nextYear)
  );
  // const [prior, setPrior] = useState(state?.prior ? state?.prior : -1);

  const shouldLog = useRef(true);
  const shouldLog1 = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);
  const context = useContext(ModalController);

  const [loaded, setLoaded] = useState(false);

  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);

  const _onChangeIsShowFilter = () => {
    if (isShowFilter === true) {
      setIsShowFilter(false);
    } else {
      setIsShowFilter(true);
    }
  };

  const resetDispatch = async () => {
    // await Promise.all([
    //   dispatch(ONCHANGE_RESET_FILTER({})),
    //   dispatch(ONSET_RETURN_PAGE_CURRENT()),
    // ]);

    // link != "/working/task-totalall" && dispatch(ONCHANGE_RESET_FILTER({}));
    // dispatch(ONCHANGE_RESET_FILTER({}));
    dispatch(ONSET_RETURN_PAGE_CURRENT());
  };

  useEffect(() => {
    resetDispatch();

    setIsShowFilter(false);

    // setLoaded(true);

    fetchData();

    // return () => {
    //   setLoaded(false);
    // };
  }, [link]);

  // useEffect(() => {
  //   if (loaded) {
  //     fetchData();

  //     setLoaded(false);
  //   }

  //   return () => {};
  // }, [data.searchFilter]);

  useEffect(() => {
    fetchData();

    return () => {};
  }, [data.pageCurrent]);

  const receiver = data.searchFilter.selectedReceiver;

  // funcs
  const checkFilterParams = (): boolean => {
    // console.log({
    //   "pageCurrent": data.pageCurrent,
    //   "choosePage": data.choosePage,
    //   "taskName": data.searchFilter.taskName,
    //   "selectedPrior": data.searchFilter.selectedPrior,
    //   "selectedReceiver": data.searchFilter.selectedReceiver,
    //   "fromDate": fromDate,
    //   "toDate": toDate,
    //   "deadLine": data.searchFilter.deadLine,
    // })

    if (
      data.searchFilter.deadLine === "" &&
      fromDate === "" &&
      toDate === "" &&
      data.searchFilter.selectedPrior === -1 &&
      data.searchFilter.selectedReceiver === -1 &&
      data.searchFilter.taskName === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // -- on change page of main table

  const fetchData = async () => {
    if (link === "/working/new-task") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }
    } else if (link === "/voting/voting_work") {
      VotingListNeedToJoinWithParams(
        data.pageCurrent,
        data.choosePage,
        function (res: any) {
          dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
        },
        data.searchFilter.taskName,
        data.searchFilter.selectedReceiver
      );
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // } else {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     fromDate,
      //     toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // }
    } else if (link === "/voting/voting_wait") {
      // VotingListRequest(function (res: any) {
      //   dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
      // });
      VotingListRequestWithParams(
        data.pageCurrent,
        10,
        function (res: any) {
          dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
        },
        data.searchFilter.taskName,
        data.searchFilter.selectedReceiver
      );
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // } else {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     fromDate,
      //     toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // }
    } else if (link === "/working/receiver/task-waiting") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_CHO_DUYET_TASK,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient

          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_CHO_DUYET_TASK,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      }
    } else if (link === "/working/receiver/task-returnwork") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      } else {
        readList(
          NGUOI_NHAN_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient

          //searchMonth,
          //searchYear
        );
      }
    } else if (link === "/working/task-return") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.selectedDiff
        );
      } else {
        readList(
          NGUOI_GIAO_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.selectedDiff
        );
      }
    } else if (link === "/working/receiver/task-finish_early") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
          },

          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
          },

          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      }
    } else if (link === "/working/receiver/task-finish_puntual") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient

          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      }
    } else if (link === "/working/receiver/task-share") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_SHARED,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_CHIA_SE(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_SHARED,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_CHIA_SE(res.data.Data));
          }
        );
      }
    } else if (link === "/working/receiver/task-finish_late") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
          },

          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
          },

          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      }
    } else if (link === "/working/task-need-give") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }
      // readList(
      //   NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data)) }
      // )
    } else if (link === "/working/task-delay") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
          },
          "",
          data.searchFilter.selectedBoss,
          "",
          data.searchFilter.selectPhongBan
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/task-end-today") {
      // re check

      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_TODAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_TODAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_END_TODAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data)) }
      // )
    } else if (link === "/working/task-end-soon") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_END_SOON,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/task-change-deadline") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.isNow == -1
            ? null
            : data.searchFilter.isNow == 1
            ? true
            : false
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.isNow == -1
            ? null
            : data.searchFilter.isNow == 1
            ? true
            : false
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-new") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data))
      //   }
      // )
    } else if (link === "/working/receiver/task-received") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_RECEIVED,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_RECEIVED,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_RECEIVED,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-processing") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_PROCESSING,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_PROCESSING,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-delay") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
          },
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-end-today") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data)) }
      // )
    } else if (link === "/working/task-finish") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_FINISH,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null
        );
      } else {
        // alert(data.searchFilter.selectedBoss);
        readList(
          NGUOI_GIAO_VIEC_LIST_FINISH,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          data.searchFilter.selectPhongBan
          //searchMonth,
          //searchYear
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_FINISH,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data)) }
      // )
    } else if (link === "/working/task-total") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_TOTAL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_TOTAL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
          },
          data.searchFilter.status,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_TOTAL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      // )
    } else if (link === "/working/task-total-my-seft") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_TASK_MYSELF_ALL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP_TU_TAO(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_TASK_MYSELF_ALL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP_TU_TAO(res.data.Data));
          },
          data.searchFilter.status,
          data.searchFilter.selectedBoss,
          data.searchFilter.result,
          data.searchFilter.selectPhongBan || null
          //searchMonth,
          //searchYear
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_TOTAL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      // )
    } else if (link === "/working/task-totalall") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
          },
          data.searchFilter.status,
          data.searchFilter.selectedBoss,
          data.searchFilter.result,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
          },
          data.searchFilter.status,
          data.searchFilter.selectedBoss,
          data.searchFilter.result,
          null
          //searchMonth,
          //searchYear
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_TOTAL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-end-soon") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_END_SOON,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/all-task") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_ALL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
          },
          null,
          "",
          data.searchFilter.result,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_ALL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
          },
          null,
          "",
          data.searchFilter.result,
          null,
          null,
          null,
          null,
          null,
          null,
          data.searchFilter.recipient
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_ALL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data))
      //   }
      // )
    } else if (link === "/working/task-proccessing") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
          },
          "",
          data.searchFilter.selectedBoss,
          "",
          data.searchFilter.selectPhongBan
          //searchMonth,
          //searchYear
        );
      }

      // console.log({
      //   "pageCurrent": data.pageCurrent,
      //   "choosePage": data.choosePage,
      //   "taskName": data.searchFilter.taskName,
      //   "selectedPrior": data.searchFilter.selectedPrior,
      //   "selectedReceiver": data.searchFilter.selectedReceiver,
      //   "fromDate": fromDate,
      //   "toDate": toDate,
      //   "deadLine": data.searchFilter.deadLine,
      // })

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
      //   }
      // );
    } else if (link === "/working/task-finish-late") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_LOAD_TASK_LATE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_LOAD_TASK_LATE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }
    } else if (link === "/working/task-finish-early") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_LOAD_TASK_EARLY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
          },
          null,
          null,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_LOAD_TASK_EARLY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }
    } else if (link === "/working/task-finish-punctual") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null
          //searchMonth,
          //searchYear
        );
      } else {
        readList(
          NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
          },
          null,
          data.searchFilter.selectedBoss,
          null,
          null
          //searchMonth,
          //searchYear
        );
      }
    } else if (link === NAVIGATION_KEYS.recoveryTask) {
      if (checkFilterParams() === true) {
        readList(
          TASK_RECOVERY_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKRECOVERYLIST_ALL(res.data.Data));
          }
        );
      } else {
        readList(
          TASK_RECOVERY_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKRECOVERYLIST_ALL(res.data.Data));
          }
        );
      }
    } else if (link === NAVIGATION_KEYS.revokeTask) {
      if (checkFilterParams() === true) {
        readList(
          TASK_REVOKE_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKREVOKELIST_ALL(res.data.Data));
          }
        );
      } else {
        readList(
          TASK_REVOKE_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKREVOKELIST_ALL(res.data.Data));
          }
        );
      }
    } else if (link === NAVIGATION_KEYS.settingProceduce) {
      if (checkFilterParams() === true) {
        readList(
          SETTING_PROCEDUCE_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_SETTINGPROCEDUCE_ALL(res.data.Data));
          }
        );
      } else {
        readList(
          SETTING_PROCEDUCE_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_SETTINGPROCEDUCE_ALL(res.data.Data));
          }
        );
      }
    } else if (link === "/working/receiver/task-myself") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_TASK_MY_SELF,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_TASK_MY_SELF,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          fromDate,
          toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
          }
        );
      }
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    if (data.receiverList === null) {
      loadReceiver(
        (res: any) => dispatch(ONLOAD_RECEIVERLIST(res.data.Data)),
        shouldLog1
      );
    }

    if (data.priorList === null) {
      loadPrior(
        (res: any) => dispatch(ONLOAD_PRIORLIST(res.data.Data)),
        shouldLog2
      );
    }

    if (data.bossList === null) {
      loadBoss(
        (res: any) => dispatch(ONLOAD_BOSS_LIST(res.data.Data)),
        shouldLog3
      );
    }
  });

  const _onClickFindTask = () => {
    // 0;
    // workingData.searchFilter.taskName = null;
    readList(
      NGUOI_GIAO_VIEC_LIST,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };
  const _onClickFindTaskProposed = () => {
    readList(
      NGUOI_GIAO_LOAD_TASK_MY_SELF,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKMYSELF_NGUOIGIAO(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan,
      null,
      null,
      data.searchFilter.statusTaskMyself
    );
  };

  const _onClickFindTaskReturnOfReceiver = () => {
    readList(
      NGUOI_NHAN_TASK_RETURN,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindWaiting = () => {
    // 0;
    readList(
      NGUOI_NHAN_CHO_DUYET_TASK,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      },
      null,
      data.searchFilter.selectedBoss,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickVotingWork = () => {
    //0;
    VotingListNeedToJoinWithParams(
      data.pageCurrent,
      data.choosePage,
      function (res: any) {
        dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
        dispatch(ONSET_RETURN_PAGE_CURRENT());
      },
      data.searchFilter.taskName,
      data.searchFilter.selectedReceiver
    );
  };

  const _onClickVotingWaiting = () => {
    VotingListRequestWithParams(
      data.pageCurrent,
      data.choosePage,
      function (res: any) {
        dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
        dispatch(ONSET_RETURN_PAGE_CURRENT());
      },
      data.searchFilter.taskName,
      data.searchFilter.selectedReceiver
    );
  };

  const _onClickFindTaskReturn = () => {
    //0;
    readList(
      NGUOI_GIAO_TASK_RETURN,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan,
      null,
      null,
      null,
      data.searchFilter.selectedDiff
    );
  };

  const _onClickTaskFinishLate = () => {
    //0;
    readList(
      NGUOI_GIAO_LOAD_TASK_LATE,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickTaskFinishEarly = () => {
    //0;
    readList(
      NGUOI_GIAO_LOAD_TASK_EARLY,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickTaskFinishPunctual = () => {
    //0;
    readList(
      NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindTaskGive = () => {
    const link = window.location.pathname;
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindTaskFinish = () => {
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_FINISH,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
      },
      "",
      "",
      "",
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindTaskProccessing = () => {
    //0;
    // console.log(workingData.searchFilter.selectedReceiver)

    // console.log({
    //   "pageCurrent": workingData.pageCurrent,
    //   "choosePage": workingData.choosePage,
    //   "taskName": workingData.searchFilter.taskName,
    //   "selectedPrior": workingData.searchFilter.selectedPrior,
    //   "selectedReceiver": workingData.searchFilter.selectedReceiver,
    //   "fromDate": workingfromDate,
    //   "toDate": workingtoDate,
    //   "deadLine": workingData.searchFilter.deadLine,
    // })
    readList(
      NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
      },
      data.searchFilter.status,
      data.searchFilter.selectedBoss,
      data.searchFilter.result,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindTaskShare = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_SHARED,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_CHIA_SE(res.data.Data));
      },
      data.searchFilter.status,
      data.searchFilter.selectedBoss,
      data.searchFilter.result,
      data.searchFilter.selectPhongBan
    );
  };

  const _onClickFindTaskEndSoon = () => {
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_END_SOON,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
      }
    );
  };

  const _onClickFindTaskEndToday = () => {
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_END_TODAY,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
      }
    );
  };

  const _onClickFindTaskAll2 = () => {
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_TOTAL,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
      },
      data.searchFilter.status,
      data.searchFilter.selectedBoss,
      data.searchFilter.result,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };
  const _onClickFindTaskAll3 = () => {
    readList(
      NGUOI_GIAO_TASK_MYSELF_ALL,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP_TU_TAO(res.data.Data));
      },
      data.searchFilter.status,
      data.searchFilter.selectedBoss,
      data.searchFilter.result,
      data.searchFilter.selectPhongBan || null
    );
  };

  const _onClickReFindTaskNew = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
      },
      null,
      data.searchFilter.selectedBoss,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickReFindTaskDelay = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST_DELAY,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
      },
      null,
      null,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickReFindTaskEndSoon = () => {
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan,
      null,
      null,
      null,
      null,
      data.searchFilter.isNow == -1
        ? null
        : data.searchFilter.isNow == 1
        ? true
        : false
    );
  };

  const _onClickReFindTaskEndToday = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
      }
    );
  };

  const _onClickReFindTaskProccessing = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST_PROCESSING,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
      },
      null,
      null,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickReFindTaskRE = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST_RECEIVED,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
      },
      null,
      null,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickReFindTaskAll = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST_ALL,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
      },
      data.searchFilter.status,
      data.searchFilter.selectedBoss,
      data.searchFilter.result,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickProposeSend = () => {
    //0;
    propose(
      PROPOSESENDLIST,
      0,
      0,
      null,
      0,
      null,
      null,
      "",
      "",
      shouldLog,
      link,
      (res: any) => {
        // dispatch(ONLOAD_PROPOSE_GUI_LIST(res.data.Data));
      }
    );
  };

  const _onClickFindTaskAll = () => {
    //0;
    readList(
      NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
      },
      data.searchFilter.status,
      data.searchFilter.selectedBoss,
      data.searchFilter.result,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };

  const _onClickProposeGet = () => {
    //0;
    propose(
      PROPOSEGETLIST,
      0,
      0,
      null,
      0,
      null,
      null,
      "",
      "",
      shouldLog,
      link,
      (res: any) => {
        // dispatch(ONCHANGE_PROPOSE_NHAN_LIST(res.data.Data));
      }
    );
  };

  const _onClickFindTaskFinishEarly = () => {
    // 0;
    readList(
      NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
      },
      null,
      null,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindTaskFinishPunctual = () => {
    // 0;
    readList(
      NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
      },
      null,
      null,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickTaskRecovery = () => {
    readList(
      TASK_RECOVERY_LIST,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKRECOVERYLIST_ALL(res.data.Data));
      },
      "",
      "",
      "",
      data.searchFilter.selectPhongBan
    );
  };

  const _onClickTaskRevoke = () => {
    readList(
      TASK_REVOKE_LIST,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKREVOKELIST_ALL(res.data.Data));
      }
    );
  };

  const _onClickSettingProceduce = () => {
    readList(
      SETTING_PROCEDUCE_LIST,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_SETTINGPROCEDUCE_ALL(res.data.Data));
      }
    );
  };

  const _onClickFindTaskFinishLate = () => {
    //0;
    readList(
      NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      null,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
      },
      null,
      data.searchFilter.selectedBoss,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };
  const _onClickFindTaskReceiverMyself = () => {
    readList(
      NGUOI_NHAN_VIEC_TASK_MY_SELF,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      null,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKMYSELF_NGUOINHAN(res.data.Data));
      },
      null,
      null,
      null,
      null
      //searchMonth,
      //searchYear
    );
  };

  const _onClickFindTaskDelay = () => {
    //0;

    readList(
      NGUOI_GIAO_VIEC_LIST_DELAY,
      data.pageCurrent,
      data.choosePage,
      data.searchFilter.taskName,
      data.searchFilter.selectedPrior,
      data.searchFilter.selectedReceiver,
      fromDate,
      toDate,
      data.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
      },
      null,
      null,
      null,
      data.searchFilter.selectPhongBan
      //searchMonth,
      //searchYear
    );
  };
  const _onClickCreateNewTask = () => {
    context.setFuncs("createNewTask");
    context.handleOpen();
  };
  const onSearch = () => {
    dispatch(ONSET_RETURN_PAGE_CURRENT());

    switch (link) {
      case "/working/task-proposed":
        _onClickFindTaskProposed();
        break;
      case "/working/new-task":
        _onClickFindTask();
        break;
      case "/working/receiver/task-returnwork":
        _onClickFindTaskReturnOfReceiver();
        break;
      case "/working/receiver/task-waiting":
        _onClickFindWaiting();
        break;
      case "/voting/voting_work":
        _onClickVotingWork();
        break;
      case "/voting/voting_wait":
        _onClickVotingWaiting();
        break;
      case "/working/task-return":
        _onClickFindTaskReturn();
        break;
      case "/working/task-finish-late":
        _onClickTaskFinishLate();
        break;
      case "/working/task-finish-early":
        _onClickTaskFinishEarly();
        break;
      case "/working/task-finish-punctual":
        _onClickTaskFinishPunctual();
        break;
      case "/working/task-need-give":
        _onClickFindTaskGive();
        break;
      case "/working/task-finish":
        _onClickFindTaskFinish();
        break;
      case "/working/task-delay":
        _onClickFindTaskDelay();
        break;
      case "/working/task-change-deadline":
        _onClickReFindTaskEndSoon();
        break;
      case "/working/task-proccessing":
        _onClickFindTaskProccessing();
        break;
      case "/working/task-end-soon":
        _onClickFindTaskEndSoon();
        break;
      case "/working/task-end-today":
        _onClickFindTaskEndToday();
        break;
      case "/working/task-total":
        _onClickFindTaskAll2();
        break;
      case "/working/task-total-my-seft":
        _onClickFindTaskAll3();
        break;
      case "/working/receiver/task-new":
        _onClickReFindTaskNew();
        break;
      case "/working/receiver/task-delay":
        _onClickReFindTaskDelay();
        break;
      case "/working/receiver/task-end-soon":
        _onClickReFindTaskEndSoon();
        break;
      case "/working/receiver/task-end-today":
        _onClickReFindTaskEndToday();
        break;
      case "/working/receiver/task-processing":
        _onClickReFindTaskProccessing();
        break;
      case "/working/receiver/task-received":
        _onClickReFindTaskRE();
        break;
      case "/report/deliver/task-general-deliver":
        break;
      case "/report/deliver/worker-capacity":
        break;
      case "/report/deliver/detail-worker-capacity":
        break;
      case "/report/receiver/individual-capacity":
        break;
      case "/report/receiver/table-report-capacity":
        break;
      case "/working/receiver/all-task":
        _onClickReFindTaskAll();
        break;
      case "/propose/sender/list":
        _onClickProposeSend();
        break;
      case "/working/task-totalall":
        _onClickFindTaskAll();
        break;
      case "/working/receiver/task-share":
        _onClickFindTaskShare();
        break;
      case "/propose/receiver/list":
        _onClickProposeGet();
        break;
      case "/working/receiver/task-finish_early":
        _onClickFindTaskFinishEarly();
        break;
      case "/working/receiver/task-finish_puntual":
        _onClickFindTaskFinishPunctual();
        break;
      case "/working/receiver/task-finish_late":
        _onClickFindTaskFinishLate();
        break;
      case NAVIGATION_KEYS.recoveryTask:
        _onClickTaskRecovery();
        break;
      case NAVIGATION_KEYS.revokeTask:
        _onClickTaskRevoke();
        break;
      case NAVIGATION_KEYS.settingProceduce:
        _onClickSettingProceduce();
        break;
      case "/working/receiver/task-myself":
        _onClickFindTaskReceiverMyself();
        break;
    }
  };

  const linkIncludes = [
    "/voting/voting_wait",
    "/voting/voting_work",
    "/working/task-totalall",
    "/working/task-proposed",
    "/working/receiver/task-myself",
    "/working/receiver/all-task",
    "/working/receiver/task-finish_late",
    "/working/receiver/task-finish_puntual",
    "/working/receiver/task-finish_early",
    "/working/receiver/task-waiting",
    "/working/receiver/task-returnwork",
    "/working/receiver/task-delay",
    "/working/receiver/task-processing",
    "/working/receiver/task-received",
    "/working/receiver/task-new",
    "/working/receiver/task-share",
    "/working/new-task",
    "/working/task-change-deadline",
    "/working/task-need-give",
    "/working/task-proccessing",
    "/working/task-delay",
    "/working/task-return",
    "/working/task-finish",
    "/working/task-finish-early",
    "/working/task-finish-punctual",
    "/working/task-finish-late",
    "/working/task-total",
    "/working/task-total-my-seft",
    NAVIGATION_KEYS.recoveryTask,
    NAVIGATION_KEYS.revokeTask,
    NAVIGATION_KEYS.settingProceduce,
  ];

  return (
    <div className="flex flex-col w-full h-full">
      <Title />
      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5  p-3 text-sm">
        {/* {linkIncludes.find((p) => p == link) ? null : (
          <div
            className={`lg:flex flex-col w-full lg:h-[8rem] hidden h-max ${
              [
                "/voting/voting_work",
                "/working/task-totalall",
                "/voting/voting_wait",
              ].find((p) => p == link)
                ? "height-auto"
                : ""
            }`}
          >
            <ContentTop />

            {link === "/voting/voting_wait" ||
            link === "/voting/voting_work" ? (
              <></>
            ) : (
              <ContentBottom />
            )}
          </div>
        )} */}

        <div
          className={`lg:hidden flex-col w-full lg:h-[8rem] flex h-max space-y-3 ${
            linkIncludes.find((p) => p == link)
              ? "display-flex height-auto"
              : ""
          }`}
        >
          <div className="flex justify-between">
            <button
              className="bg-blue-400 p-2 rounded-md hover:bg-blue-300 text-white flex justify-center items-center max-w-sm"
              onClick={_onChangeIsShowFilter}
            >
              <p>Bộ lọc</p>
              {isShowFilter === false ? (
                <ArrowDropDownIcon fontSize="small" />
              ) : (
                <ArrowDropUpIcon fontSize="small" />
              )}
            </button>
            <div className="flex justify-end space-x-3 h-[2rem]">
              {link == "/working/new-task" && (
                <button
                  className="
              flex p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
            "
                  onClick={_onClickCreateNewTask}
                >
                  <AddIcon fontSize="small" />
                  <p className="hidden lg:flex">Thêm mới</p>
                </button>
              )}

              <button
                className="
            flex items-center p-2 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
                onClick={() => window.location.reload()}
              >
                <ReplayIcon fontSize="small" />
                <p className="hidden lg:flex">Tải lại</p>
              </button>
            </div>
            {/* <SubmitHeader /> */}
          </div>

          <div
            className={`${isShowFilter === false ? "hidden" : ""} ${
              link === "/voting/voting_work" ? "height-auto" : ""
            }`}
          >
            <Filter
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              // prior={prior}
              // setPrior={setPrior}
            />
            <div className="core-filter-bottom-search mt-5">
              <button
                className="
                      flex items-center p-2 bg-primary rounded-md text-white
                      hover:bg-sky-400 core-filter-bottom-search-button
                    "
                onClick={onSearch}
              >
                <SearchIcon fontSize="small" />
                <p className="hidden lg:flex">Tìm kiếm</p>
              </button>
            </div>
            {/* <ContentTop />

            <div className="core-filter-bottom">
              {link === "/voting/voting_wait" ||
              link === "/voting/voting_work" ? (
                <>
                  <div className="core-filter-bottom-search">
                    <button
                      className="
                      flex items-center p-2 bg-primary rounded-md text-white
                      hover:bg-sky-400 core-filter-bottom-search-button
                    "
                      onClick={onSearch}
                    >
                      <SearchIcon fontSize="small" />
                      <p className="hidden lg:flex">Tìm kiếm</p>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ContentBottom />

                  <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 lg:gap-5 mt-2">
                    {link === "/working/task-recovery" ||
                    link === "/working/receiver/task-recovery" ? (
                      <>
                        <div></div>
                        <div></div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-col w-full h-max">
                          <label className="mb-1">Tháng</label>
                          <input
                            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                            placeholder="Nhập năm"
                            type="number"
                            value={//searchMonth}
                            onChange={(e: any) => {
                              setSearchMonth(e.target.value);
                              dispatch(ONCHANGE_SEARCH_MONTH(e.target.value));
                            }}
                            onTouchStart={(e: any) => onTouchForm(e)}
                          />
                        </div>
                        <div className="flex flex-col w-full h-max">
                          <label className="mb-1">Năm</label>
                          <input
                            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                            placeholder="Nhập năm"
                            type="number"
                            value={//searchYear}
                            onChange={(e: any) => {
                              setSearchYear(e.target.value);
                              dispatch(ONCHANGE_SEARCH_YEAR(e.target.value));
                            }}
                            onTouchStart={(e: any) => onTouchForm(e)}
                          />
                        </div>
                      </>
                    )}
                    <div></div>
                  </div>
                </>
              )}
            </div> */}
          </div>
        </div>
        {/* table */}
        <Table />
      </div>
    </div>
  );
};

export default ContentCore;
