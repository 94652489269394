import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import { XOA_TIEU_CHI_DANH_GIA } from "../../../apis/_index";

import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const DELETE_TIEUCHI = (props: any) => {
  // take props
  const taskId = props.taskId;
  const matieuchi = props.matieuchi;
  const handleClose = props.handleClose;

  const _onClickDeleteTieuChi = async () => {
    const body: any = {
      id: taskId,
      matieuchi: matieuchi,
    };

    try {
      await ServiceUtils.postV2(XOA_TIEU_CHI_DANH_GIA, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        toast.success("Xóa thành công!");
        window.location.reload();
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>
          Bạn có đồng ý <span className="font-semibold">XOÁ</span> Tiêu chí này
          không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className="w-[5rem] h-max p-2 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickDeleteTieuChi}
          >
            Có
          </button>
          <button
            className="w-[5rem] h-max p-2 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default DELETE_TIEUCHI;
