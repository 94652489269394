import React, { useState, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { ONCHANGE_TARGET_RATING } from "../../../redux/reducers/settingReducer/_index";

import { tieuchidanhgia_list } from "../../../action/tieuchidanhgiaAction";

import { onTouchForm } from "../../../funcs/onTochForm";
import CloseIcon from '@mui/icons-material/Close';
import {
  nhomThamChieu,
  tieuchidanhgia_create,
} from "../../../action/tieuchidanhgiaAction";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";

const CreateTargetRatingCapacity = (props: any) => {
  const [color, setColor] = useState<string>("#aabbcc");
  const [nhomThamChieuList, setNhomThamChieuList] = useState<any | null>(null);
  const [selectedNhomThamChieu, setSelectedNhomThamChieu] = useState<
    any | null
  >(-1);
  const [tenTieuChi, setTenTieuChi] = useState<string>("");
  const [moTa, setMoTa] = useState<string>("");
  const [maTieuChi, setMaTieuChi] = useState<string>("");
  const [nhomtieuchi, setNhomtieuchi] = useState<string>("");
  const [IsActive, setIsActive] = useState<boolean>(false);

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const dispatch = useDispatch()

  // ref
  const shouldLog = useRef(true);

  const getNhomThamChieu = () => {
    nhomThamChieu().then((res: any) => {
      setNhomThamChieuList(res.data);
    });
  };

  const handleOnChangeIsActive = () => {
    setIsActive(!IsActive);
  };

  const handleCreate = () => {
    if (
      tenTieuChi === "" &&
      moTa === "" &&
      selectedNhomThamChieu === -1 &&
      maTieuChi === "" &&
      nhomtieuchi === "" &&
      IsActive === false
    ) {
      alert("Vui lòng nhập đầy đủ thông tin");
      return;
    }

    const data = {
      nhomtieuchi: nhomtieuchi,
      maTieuChi: maTieuChi,
      noidung: moTa,
      tenTieuChi: tenTieuChi,
      IsActive: IsActive,
      Query: parseInt(selectedNhomThamChieu),
    };

    tieuchidanhgia_create(data).then((res: any) => {
      if (res.data) {
        tieuchidanhgia_list().then((res: any) => {
          dispatch(ONCHANGE_TARGET_RATING(res.data))
        });
        toast.success("Tạo mới tiêu chí thành công.")
        handleClose();
      } else {
        toast.error("Tạo mới tiêu chí thất bại.")
      }
    });
  };

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      getNhomThamChieu();
    }
  }, []);

  return (
    <div className=" flex flex-col bg-white responsive-container lg:w-[34rem] w-screen lg:h-[28rem] rounded-md justify-between">
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tạo tiêu chí mới</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
      {nhomThamChieuList !== null ? (
        <div className="relative flex flex-col w-full lg:h-[calc(100%-6rem)] h-auto overflow-auto p-4 text-sm styled-scrollbars space-y-4">
          {/* lines */}
          <div className="lg:flex w-full h-max">
            <div className="flex flex-col w-full pr-0 h-max lg:pr-1">
              <label>
                Tên tiêu chí&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                onChange={(e: any) => {
                  e.preventDefault();
                  setTenTieuChi(e.target.value);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>

            <div className="flex flex-col w-full pr-0 h-max lg:pl-1">
              <label>
                Mã tiêu chí&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                onChange={(e: any) => {
                  e.preventDefault();
                  setMaTieuChi(e.target.value);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>

          <div className="lg:flex w-full h-max">
            <div className="flex flex-col w-full pr-0 h-max lg:pr-1">
              <label>
                Nhóm tiêu chí&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                onChange={(e: any) => {
                  e.preventDefault();
                  setNhomtieuchi(e.target.value);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
            <div className="flex flex-col w-full h-max lg:pl-1">
              <label>
                Nhóm tham chiếu&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
                value={
                  selectedNhomThamChieu === -1 ? -1 : selectedNhomThamChieu
                }
                onChange={(e: any) => {
                  e.preventDefault();
                  setSelectedNhomThamChieu(e.target.value);
                }}
              >
                <option className="text-gray-300" value={-1} disabled>
                  --- Chọn nhóm tham chiếu ---
                </option>
                {nhomThamChieuList !== null
                  ? nhomThamChieuList.map((item: any, index: any) => (
                      <option key={index} value={item.ID}>
                        {item.QueryName}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-row w-1/2 space-x-2 h-max">
              <p className="font-bold text-primary">Hiển thị</p>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={IsActive}
                onChange={handleOnChangeIsActive}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Mô tả&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập mô tả"
                onChange={(e: any) => {
                  e.preventDefault();
                  setMoTa(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
          >
            <SaveIcon fontSize="small" />
            <p
              onClick={() => {
                handleCreate();
              }}
            >
              Tạo mới
            </p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTargetRatingCapacity;
