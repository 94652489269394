import axios from 'axios';

import { getAccessToken } from "../../utils/users/userUtils";
import { GET_LIST_SUPPORTER } from '../../apis/_index';
import ServiceUtils from '../../utils/services';

class UserRepository {
    static async getListSupporter() {
        return new Promise(async resolve => {
            const accessToken = getAccessToken();

            await ServiceUtils.getV2(GET_LIST_SUPPORTER, {
                    headers: {
                        Accept: "text/plain",
                        'Content-Type': "application/json",
                    },
                })
                .then((res: any) => {
                    if (((res || {}).data || {}).StatusCode === 200) {
                        resolve({
                            status: true,
                            data: ((res || {}).data || {}).Data || [],
                            message: ((res.data || {}).Data || {}).RMessage
                        });
                    } else {
                        resolve({
                            status: false,
                            message: ((res.data || {}).Data || {}).RMessage
                        });
                    }
                });
        });
    }
}

export default UserRepository;