import { useEffect, useState } from "react";
import LoaderSpinner from "../../components/loader-spinner/_index";
import ErrorIcon from '@mui/icons-material/Error';

const LoadingControl = (props: any) => {
    const [isLoading, setIsLoading] = useState<any>(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 10000);
    }, []);

    return <div className="w-full h-full justify-center items-center flex flex-col">
        {isLoading ? <>
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang tải dữ liệu</p>
        </> : <>
            <ErrorIcon fontSize="large" color="error" />
            <p className="text-danger">Lỗi hệ thống, xin vui lòng thử lại sau !</p></>}
    </div>;
}

export default LoadingControl;