// import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// // redux importing
// import store from './redux/store/_index';
// import { Provider } from 'react-redux';

// // const root = ReactDOM.createRoot(
// //   document.getElementById('root') as HTMLElement
// // );

// //HUB

// // const userData_: any = localStorage.getItem("userData");
// // const token: any = localStorage.getItem("accessToken")

// // if (userData_ && token) {
// //   Start({ users: userData_, token: token });
// //   Notification();
// // }

// //---

// ReactDOM.render(
//   // <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   // </React.StrictMode>
//   ,document.getElementById('root') as HTMLElement
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store/_index";
import { PersistGate } from "redux-persist/integration/react";
import axios from 'axios';
import { getAccessToken, logout, refreshToken } from "./utils/users/userUtils";
import { SERVER_STATUSES, VARIABLES } from "./constants/constants";

// const apiAxios = axios.create({

// });

// const waitingRefreshToken = async (): Promise<any> => {
//   return new Promise((resolve: any) => {
//     let interval = setInterval(() => {
//       console.log(VARIABLES.isRefeshToken);

//       if (!VARIABLES.isRefeshToken) {
//         clearInterval(interval);

//         resolve(true);
//       }
//     }, 100);
//   });
// }

// // Sau khi trước recall api
// axios.interceptors.request.use(async (config: any) => {
//   await waitingRefreshToken();

//   const accessToken = getAccessToken();

//   config.headers.authorization = `Bearer ${accessToken}`;

//   return config;
// }, (err) => {
//   return err;
// });

// // Xử lý khi vừa bị 401
// axios.interceptors.response.use(async (res: any) => {
//   if (res.status == SERVER_STATUSES.unAuthorized) {
//     const resultRefreshToken = await refreshToken();

//     if (!resultRefreshToken.status) {
//       logout();

//       return res;
//     } else {
//       const originalRequest = res.config;

//       const accessToken = getAccessToken();

//       originalRequest.headers.authorization = `Bearer ${accessToken}`;

//       console.log(originalRequest);

//       const response = await apiAxios(originalRequest);

//       console.log(response);

//       return response;
//     }
//   }

//   return res;
// }, async (err: any) => {
//   if (err.response?.status == SERVER_STATUSES.unAuthorized) {
//     const resultRefreshToken = await refreshToken();

//     if (!resultRefreshToken.status) {
//       logout();

//       return Promise.reject(err);
//     } else {
//       const originalRequest = err.config;

//       const accessToken = getAccessToken();

//       originalRequest.headers.authorization = `Bearer ${accessToken}`;

//       console.log(originalRequest);

//       const response = await apiAxios(originalRequest);

//       console.log(response);

//       return response;
//     }
//   }

//   return Promise.reject(err);
// });

ReactDOM.render(
  // <React.StrictMode>
  <React.Fragment>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.Fragment>,
  // </React.StrictMode>
  document.getElementById("root")
);

reportWebVitals();
