import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// ui importing
import HomeUI from "../../ui/homeUI/_index";

const HomePage = () => {

  return (
    <div className="w-full h-full">
      <HomeUI />
    </div>
  )
}

export default HomePage;
