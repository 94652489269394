import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import SubModal from "../_subModal/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import { ModalController } from "../../../ui/homeUI/_index";
import { toast } from "react-toastify";
import axios from "axios";

import {
  GET_DETAIL_PROPOSE,
  HANDLE_PROCEDURE_STEP,
  PROPOSE_CONFIRM,
  PROPOSE_DETAIL,
  PROPOSE_TYPE_LIST,
  PROPOSE_UNCONFIRM,
} from "../../../apis/_index";
import moment from "moment";
import {
  PROCEDUCE_STEP_TYPES,
  PROCEDUCE_STEP_TYPE_OBJECTS,
} from "../../../constants/dataConstants";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import ServiceUtils from "../../../utils/services";
import { getUserID } from "../../../utils/users/userUtils";
import { STORAGE_KEYS } from "../../../constants/constants";

const ProposeDetail = (props: any) => {
  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;
  const id = props.id;

  // state

  const urlLink = window.location.pathname;

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const [isForce, setIsForce] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<any>(null);
  const data = useSelector((state: any) => state.appReducer);
  const context = useContext(ModalController);
  const [settingProcedureSteps, setSettingProcedureStep] = useState<any>([]);
  const [contentProcedureStep, setContentProcedureStep] = useState<any>("");
  const [rateValue, setRateValue] = React.useState<number | null>(0);
  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };
  const [proposeList, setProposeList] = useState<any[] | null>(null);
  const [selectedPropose, setSelectedPropose] = useState<any | null>(null);
  const [currentStep, setCurrentStep] = useState<any | null>(null);

  // load detail
  useEffect(() => {
    const takeData1 = () => {
      try {
        ServiceUtils.getV2(PROPOSE_TYPE_LIST, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setProposeList(res.data.Data);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData1();

    const API = GET_DETAIL_PROPOSE.replace("{id}", taskId);

    const takeData = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          console.log("123123, ", res);

          if ((res.data || {}).StatusCode === 200) {
            setDataDetail(((res.data || {}).Data || {}).propose);
            setSettingProcedureStep(
              ((res.data || {}).Data || {}).settingProcedureSteps || []
            );

            // console.log("res.data", res.data.Data[0]);
          } else {
            toast.error(res.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  }, [taskId]);
  console.log("settingProcedureSteps", settingProcedureSteps);
  console.log("dataDetail", dataDetail);
  console.log("contentProcedureStep", contentProcedureStep);

  // onClick confirm
  const _onClickConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeConfirm");
  };
  const onOpenSub = (text: string, id: String) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // onClick unConfirm
  const _onClickUnConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeUnConfirm");
  };

  const onHandleProcedureStep = (result: any, labelFunction: any) => () => {
    if (!contentProcedureStep) {
      toast.error("Vui lòng nhập nội dung gửi đi!");

      return;
    }

    const apiUrl = HANDLE_PROCEDURE_STEP;

    const body: any = {
      ProposeId: taskId,
      Content: contentProcedureStep,
      FuctionType: result,
    };

    const messageSuccessfully = labelFunction
      ? `${labelFunction} thành công!`
      : "Gửi thành công!";
    const messageFailure = labelFunction
      ? `${labelFunction} thất bại!`
      : "Gửi thất bại!";

    try {
      ServiceUtils.patchV2(apiUrl, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        const data = res.data || {};

        if (data.RCode === 200) {
          handleClose();
          toast.success(data.RMessage || messageSuccessfully);
        } else {
          toast.error(data.RMessage || messageFailure);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  // const renderSelectProcedureStep = () => {
  //   const detail = dataDetail || {};

  //   const result1CurrentStep = detail.ProcedureStepResult1;
  //   const result2CurrentStep = detail.ProcedureStepResult2;
  //   const result3CurrentStep = detail.ProcedureStepResult3;

  //   const typeCurrentStep = detail.ProcedureStepType;

  //   let isHaveSelect = false;

  //   const nextStepResult1 = settingProcedureSteps.find(p => p.StepNumber == result1CurrentStep);
  //   const nextStepResult2 = settingProcedureSteps.find(p => p.StepNumber == result2CurrentStep);
  //   const nextStepResult3 = settingProcedureSteps.find(p => p.StepNumber == result3CurrentStep);

  //   if (nextStepResult1 && nextStepResult1.OrganizationID && nextStepResult1.PositionID) {
  //     isHaveSelect = true;
  //   } else if (nextStepResult2 && nextStepResult2.OrganizationID && nextStepResult1.PositionID) {
  //     isHaveSelect = true;
  //   } else if (nextStepResult3 && nextStepResult3.OrganizationID && nextStepResult3.PositionID) {
  //     isHaveSelect = true;
  //   }

  //   if (isHaveSelect) {
  //     return <>
  //       <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
  //         <div className="w-full h-max flex flex-col p-1">
  //           <p>Chọn phòng ban</p>
  //           <textarea
  //             style={{
  //               height: '100px'
  //             }}
  //             rows={5}
  //             className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
  //           />
  //         </div>
  //       </div>
  //     </>;
  //   }

  //   return null;
  // }

  const onChangeContentProcedureStep = (e: any) => {
    const value = e.target.value;

    setContentProcedureStep(value);
  };

  const renderContentProcedureStep = () => {
    const detail = dataDetail || {};

    const contentCurrentByUser = detail.ContentCurrentByUser;
    const contentPrevious = detail.ContentPrevious;

    if (contentCurrentByUser) {
      return (
        <>
          <div
            style={{
              marginTop: "10px",
              width: "100%",
              height: "2px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          ></div>
          <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
            <div className="w-full h-max flex flex-col p-1">
              <p>Nội dung bước trước</p>
              <textarea
                readOnly={true}
                style={{
                  height: "100px",
                }}
                rows={5}
                className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
                value={contentCurrentByUser}
              />
            </div>
          </div>
        </>
      );
    }

    if (contentPrevious) {
      return (
        <>
          <div
            style={{
              marginTop: "10px",
              width: "100%",
              height: "2px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          ></div>
          <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
            <div className="w-full h-max flex flex-col p-1">
              <p>Nội dung người thực hiện bước trước đó</p>
              <textarea
                readOnly={true}
                style={{
                  height: "100px",
                }}
                rows={5}
                className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
                value={contentPrevious}
              />
            </div>
          </div>
        </>
      );
    }
  };

  //1

  const renderFormProcedureStep = () => {
    const detail = dataDetail || {};

    // const isFinishProcedureStep =
    //   detail.ProcedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.noContent ||
    //   settingProcedureSteps.length == detail.CurrentStepNumber
    //     ? true
    //     : false;

    if (detail.Status === 1 || detail.Status === 2) {
      return null;
    }
    // if (isFinishProcedureStep || detail.Status === 1 || detail.Status === 2) {
    //   return null;
    // }

    if (!detail.IsPermissionHandleProcedureStep) {
      return null;
    }

    return (
      <>
        {/* {settingProcedureSteps[detail.CurrentStepNumber - 1]?.Type == 1 && (
          <div className=" w-full h-max flex flex-row p-1">
            <p className="mb-2">Đánh giá (*)</p>
            <div className="ml-10">
              <Box
                sx={{
                  "& > legend": { mt: 2 },
                }}
                className="scale-150"
              >
                <Typography component="legend"></Typography>
                <Rating
                  name="simple-controlled"
                  value={rateValue}
                  onChange={(event, newValue) => {
                    setRateValue(newValue);
                  }}
                />
              </Box>
            </div>
          </div>
        )} */}
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            height: "2px",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        ></div>
        <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
          <div className="w-full h-max flex flex-col p-1">
            <p>Nội dung gửi đi (*)</p>
            <textarea
              style={{
                height: "100px",
              }}
              rows={5}
              className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
              onChange={(e: any) => onChangeContentProcedureStep(e)}
              value={contentProcedureStep}
            />
          </div>
        </div>
      </>
    );
  };

  const renderFunctionProcedureType = () => {
    return <></>;
  };

  //1
  const renderFunctionProcedureStep = () => {
    const detail = dataDetail || {};
    const steps = settingProcedureSteps || [];
    const currentUserString = localStorage.getItem(STORAGE_KEYS.currentUser);
    const currentUser = JSON.parse(currentUserString || "");

    if (
      !detail.IsPermissionHandleProcedureStep ||
      detail.Status === 1 ||
      detail.Status === 2
      // detail.UserAdd === ((currentUser.Data || [])[0] || {}).Id || ""
    ) {
      return null;
    }

    const currentStep = steps.find(
      (item: any) => item.Id == detail.ProcedureStepID
    );

    return (
      <>
        {currentStep?.Status1 === 1 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md hover:bg-green-700"
            onClick={onHandleProcedureStep(
              1,
              detail.ProcedureStepLabelFunction1
            )}
          >
            <CheckIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction1}</p>
          </button>
        )}

        {currentStep?.Status1 === 2 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md hover:bg-red-700"
            onClick={onHandleProcedureStep(
              1,
              detail.ProcedureStepLabelFunction1
            )}
          >
            <CloseIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction1}</p>
          </button>
        )}

        {currentStep?.Status1 === 3 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
            onClick={onHandleProcedureStep(
              1,
              detail.ProcedureStepLabelFunction1
            )}
          >
            <SkipNextIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction1}</p>
          </button>
        )}

        {currentStep?.Status2 === 1 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md hover:bg-green-700"
            onClick={onHandleProcedureStep(
              2,
              detail.ProcedureStepLabelFunction2
            )}
          >
            <CheckIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction2}</p>
          </button>
        )}

        {currentStep?.Status2 === 2 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md hover:bg-red-700"
            onClick={onHandleProcedureStep(
              2,
              detail.ProcedureStepLabelFunction2
            )}
          >
            <CloseIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction2}</p>
          </button>
        )}

        {currentStep?.Status2 === 3 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
            onClick={onHandleProcedureStep(
              2,
              detail.ProcedureStepLabelFunction2
            )}
          >
            <SkipNextIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction2}</p>
          </button>
        )}

        {currentStep?.Status3 === 1 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md hover:bg-green-700"
            onClick={onHandleProcedureStep(
              3,
              detail.ProcedureStepLabelFunction3
            )}
          >
            <CheckIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction3}</p>
          </button>
        )}

        {currentStep?.Status3 === 2 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md hover:bg-red-700"
            onClick={onHandleProcedureStep(
              3,
              detail.ProcedureStepLabelFunction3
            )}
          >
            <CloseIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction3}</p>
          </button>
        )}

        {currentStep?.Status3 === 3 && (
          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
            onClick={onHandleProcedureStep(
              3,
              detail.ProcedureStepLabelFunction3
            )}
          >
            <SkipNextIcon fontSize="small" />
            <p>{detail.ProcedureStepLabelFunction3}</p>
          </button>
        )}
      </>
    );

    // const procedureStepType = detail.ProcedureStepType;
    // if (procedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.transferStep) {
    //   return (
    //     <button
    //       className="
    //         flex items-center space-x-1 w-max h-max p-2 bg-yellow-500 text-white rounded-md
    //         hover:bg-yellow-700
    //       "
    //       onClick={onHandleProcedureStep(1, detail.ProcedureStepLabelFunction1)}
    //     >
    //       <SkipNextIcon fontSize="small" />
    //       <p>{detail.ProcedureStepLabelFunction1}</p>
    //     </button>
    //   );
    // } else if (
    //   procedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.confirmNotConfirm
    // ) {
    //   return (
    //     <>
    //       <button
    //         className="
    //           flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
    //           hover:bg-red-700
    //         "
    //         onClick={onHandleProcedureStep(
    //           2,
    //           detail.ProcedureStepLabelFunction2
    //         )}
    //       >
    //         <CloseIcon fontSize="small" />
    //         <p>{detail.ProcedureStepLabelFunction2}</p>
    //       </button>
    //       <button
    //         className="
    //           flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
    //           hover:bg-green-700
    //         "
    //         onClick={onHandleProcedureStep(
    //           1,
    //           detail.ProcedureStepLabelFunction1
    //         )}
    //       >
    //         <SkipNextIcon fontSize="small" />
    //         <p>{detail.ProcedureStepLabelFunction1}</p>
    //       </button>
    //     </>
    //   );
    // } else if (
    //   procedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.confirmSuggestNotConfirm
    // ) {
    //   return (
    //     <>
    //       <button
    //         className="
    //           flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
    //           hover:bg-red-700
    //         "
    //         onClick={onHandleProcedureStep(
    //           2,
    //           detail.ProcedureStepLabelFunction2
    //         )}
    //       >
    //         <SkipNextIcon fontSize="small" />
    //         <p>{detail.ProcedureStepLabelFunction2}</p>
    //       </button>
    //       <button
    //         className="
    //           flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
    //           hover:bg-green-700
    //         "
    //         onClick={onHandleProcedureStep(
    //           3,
    //           detail.ProcedureStepLabelFunction3
    //         )}
    //       >
    //         <SkipNextIcon fontSize="small" />
    //         <p>{detail.ProcedureStepLabelFunction3}</p>
    //       </button>
    //       <button
    //         className="
    //           flex items-center space-x-1 w-max h-max p-2 bg-yellow-500 text-white rounded-md
    //           hover:bg-yellow-700
    //         "
    //         onClick={onHandleProcedureStep(
    //           1,
    //           detail.ProcedureStepLabelFunction1
    //         )}
    //       >
    //         <SkipNextIcon fontSize="small" />
    //         <p>{detail.ProcedureStepLabelFunction1}</p>
    //       </button>
    //     </>
    //   );
    // }

    return null;
  };

  const isHaveProcedureStep = (dataDetail || {}).ProcedureStepID ? true : false;

  useEffect(() => {
    const foundPropose = proposeList?.find(
      (propose) => propose.ID === (dataDetail || {})?.ProposeTypeID
    );
    const foundPropose2 = settingProcedureSteps?.find(
      (propose: any) =>
        propose.StepNumber === (dataDetail || {})?.CurrentStepNumber
    );

    setSelectedPropose(foundPropose);
    setCurrentStep(foundPropose2);
  }, [dataDetail, proposeList, settingProcedureSteps]);

  const _handleCheckedColor = (status: any) => {
    if (status === 2) {
      return "#ff0000";
    } else if (status === 1) {
      return "#00ff00";
    } else {
      return "#FFAA00";
    }
  };

  const _handleCheckedStatus = (item: any) => {
    if (item.StatusName) {
      return item.StatusName;
    }

    const status = item.Status;

    if (status === 3) {
      return "Chuyển bước tiếp theo";
    } else if (status === 2) {
      return "Không duyệt";
    } else if (status === 1) {
      return "Đã duyệt";
    } else {
      return "Chưa duyệt";
    }
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[40rem] md:w-[40rem] w-screen h-[90vh]
        rounded-md
      "
    >
      <SubModal taskId={taskId} open={openSub} handleClose={handleCloseSub} />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Chi tiết đề xuất</p>
        <button
          className="hover:bg-slate-300 px-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      <>
        {dataDetail === null ? (
          <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars"></div>
        ) : (
          <div className="flex flex-col w-full items-center text-sm p-2 overflow-auto styled-scrollbars">
            {currentStep && (
              <div className="w-full h-max font-semibold items-center rounded-sm">
                <p className="p-1 text-2xl">
                  Bước {currentStep?.StepNumber} - {currentStep?.Name}
                </p>
              </div>
            )}

            <div className="w-full h-max flex font-semibold items-center rounded-sm gap-5 justify-between">
              <p className="font-semibold text-black bg-slate-200 px-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm">
                Mã đề xuất - {dataDetail.ProposeCode}
              </p>
              <p
                style={{
                  backgroundColor: _handleCheckedColor(dataDetail.Status),
                }}
                className=" text-center text-white px-3 py-1.5 w-max rounded-2xl text-base"
              >
                {_handleCheckedStatus(dataDetail)}
              </p>
            </div>

            <div className="w-full h-max font-semibold items-center rounded-sm">
              <p className="p-1">
                Tên đề xuất -
                <span className="w-full h-[1.9rem] rounded-md px-2">
                  {dataDetail.Name}
                </span>
              </p>
            </div>

            <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
              <div className="w-full h-max flex flex-col p-1">
                <p>Loại đề xuất</p>
                <input
                  className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
                  value={dataDetail.ProposeTypeName}
                  disabled
                />
              </div>
              <div className="w-full h-max flex flex-col p-1">
                <p className="">Mức độ</p>
                <input
                  className="w-full h-[1.9rem] rounded-md px-2 border-2 border-slate-200"
                  value={dataDetail.PriorityLevelName}
                  disabled
                />
              </div>
            </div>

            <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
              <div className="w-full h-max flex flex-col p-1">
                <p>Người đề xuất</p>
                <input
                  className="w-full h-[1.9rem] rounded-md border-2 pl-2"
                  value={dataDetail.UserAddName}
                  disabled
                />
              </div>
              <div className="w-full h-max flex flex-col p-1">
                <p>Người nhận</p>
                <input
                  className="w-full h-[1.9rem] rounded-md px-2 border-2"
                  value={dataDetail.UserCheckName}
                  disabled
                />
              </div>
            </div>

            {selectedPropose?.ProposeType !== 0 && (
              <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">
                    Số tiền {selectedPropose?.ProposeType === 1 ? "chi" : "thu"}
                  </p>
                  <input
                    className="w-full h-[1.9rem] rounded-md px-2 border-2 border-slate-200"
                    value={dataDetail.ProposeValue}
                    disabled
                  />
                </div>
              </div>
            )}

            {dataDetail.ListNamePropose && (
              <div className="w-full h-max flex flex-col p-1">
                <p>Người hỗ trợ</p>
                <textarea
                  className="w-full h-[1.9rem] rounded-md border-2 items-center p-1"
                  value={dataDetail.ListNamePropose}
                  disabled
                />
              </div>
            )}

            {dataDetail.IsUseTime === true ? (
              <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">Ngày bắt đầu</p>
                  <input
                    className="w-full h-[1.9rem] rounded-md border-2 pl-2"
                    value={moment(dataDetail.StartTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                    disabled
                  />
                </div>
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">Ngày kết thúc</p>
                  <input
                    className="w-full h-[1.9rem] rounded-md px-2 border-2"
                    value={moment(dataDetail.EndTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                    disabled
                  />
                </div>
              </div>
            ) : (
              <div className="w-full h-max flex font-bold flex-col p-1">
                <p className="">Đề xuất không có thời hạn</p>
              </div>
            )}

            <div className="w-full h-max flex flex-col p-1">
              <p className="">Nội dung</p>
              <textarea
                className="w-full h-[3rem] rounded-md pt-2 px-2 border-2"
                value={dataDetail.Content}
                disabled
              />
            </div>

            {!currentStep && (
              <>
                {dataDetail.Status !== 0 && (
                  <div className="danh-gia w-full h-max flex flex-row p-1">
                    <p className="mb-2">Đánh giá</p>
                    <div>
                      <Box
                        sx={{
                          "& > legend": { mt: 2 },
                        }}
                      >
                        <Rating
                          name="simple-controlled"
                          value={dataDetail.Star}
                          readOnly={dataDetail.Status}
                        />
                      </Box>
                    </div>
                  </div>
                )}
              </>
            )}
            {dataDetail.Reason !== null && (
              <div className="w-full h-max flex flex-col p-1">
                <p className="">Lý do</p>
                <textarea
                  className="w-full h-[3rem] rounded-md pt-2 px-2 border-2"
                  value={dataDetail.Reason}
                  disabled
                />
              </div>
            )}
            {isHaveProcedureStep && renderContentProcedureStep()}
            {isHaveProcedureStep && renderFormProcedureStep()}
          </div>
        )}
      </>
      {/* footer */}
      <div
        className={`flex w-full p-12-16 footer rounded-b-md ${
          urlLink === "/working/receiver/task-new" && isForce === false
            ? "justify-end"
            : "justify-end"
        }  items-center text-sm space-x-4`}
      >
        {isHaveProcedureStep ? (
          renderFunctionProcedureStep()
        ) : (
          <>
            {urlLink !== "/propose/sender/list" && dataDetail?.Status === 0 ? (
              <>
                <button
                  className="
                    flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
                    hover:bg-red-700
                  "
                  onClick={() => _onClickUnConfirm(dataDetail.ID)}
                >
                  <CloseIcon fontSize="small" />
                  <p>Không duyệt</p>
                </button>
                <button
                  className="
                    flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
                    hover:bg-green-700
                  "
                  onClick={() => _onClickConfirm(dataDetail.ID)}
                >
                  <CheckIcon fontSize="small" />
                  <p>Duyệt</p>
                </button>
              </>
            ) : null}
          </>
        )}
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
          hover:bg-slate-500
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default ProposeDetail;
