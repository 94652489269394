import React from "react";

// ui importing
import HomeUI from "../../../../ui/homeUI/_index";

const TaskEndTodayUI = () => {
  return (
    <>
      <HomeUI />
    </>
  )
}

export default TaskEndTodayUI;
