import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

import { DELETE_QUYTRINH, READ_ALL_LIST_QUYTRINH } from "../../../apis/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import { PRIOR_DELETE, PRIOR_LIST } from "../../../apis/_index";
import { ONLOAD_PRIORLIST } from "../../../redux/reducers/settingReducer/_index";
import { ONLOAD_QUYTRINH_LIST } from "../../../redux/reducers/workingReducer/_index";
import SubModal from "./_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const DeleteQuytrinh = (props: any) => {
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // funcs
  const _onClickDelete = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const API = DELETE_QUYTRINH + taskId.toString();

    try {
      await ServiceUtils.getV2(API, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Xoá thành công.");
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(READ_ALL_LIST_QUYTRINH, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_QUYTRINH_LIST(res.data.Data));
                }
                // else {
                //   toast.error("Tải dữ liệu thất bại.");
                // }
              });
            } catch (error: any) {
              // toast.error(
              //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              // );
            }
          };

          takeData();
          handleClose();
          context.handleClose();
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>
          Bạn có đồng ý <span className="font-semibold">XOÁ</span> quy trình này
          không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className="w-[5rem] h-max pt-1 pb-1 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickDelete}
          >
            Có
          </button>
          <button
            className="w-[5rem] h-max p-2 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteQuytrinh;
