import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const driveReducer = createSlice({
  name: "driveReducer",

  initialState: {
    copiedItem: null,

    cutItem: null,

    fileList: null,

    folderList: null,

    upLoadList: null,

    searchStr: null,
  },

  reducers: {
    ONCOPY_ITEM: (state, action) => {
      state.copiedItem = action.payload;
    },

    ONCUT_ITEM: (state, action) => {
      state.cutItem = action.payload;
    },

    ONLOAD_FOLDERLIST: (state: any, actions: PayloadAction<any>) => {
      state.folderList = actions.payload;
    },

    ONLOAD_FILELIST: (state: any, actions: PayloadAction<any>) => {
      state.fileList = actions.payload;
    },

    ONLOAD_UPLOADLIST: (state: any, actions: PayloadAction<any>) => {
      state.uploadList = actions.payload;
    },

    ONSEARCHSTR: (state: any, actions: PayloadAction<any>) => {
      state.searchStr = actions.payload;
    },
  },
});

export const {
  ONCOPY_ITEM,
  ONCUT_ITEM,
  ONLOAD_FOLDERLIST,
  ONLOAD_FILELIST,
  ONLOAD_UPLOADLIST,
  ONSEARCHSTR,
} = driveReducer.actions;

export default driveReducer.reducer;
