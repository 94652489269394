import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ONLOAD_USERLIST } from "../../../redux/reducers/settingReducer/_index";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import SubModal from "../_subModal/_index";

import { takePermission } from "../../../funcs";

import { onTouchForm } from "../../../funcs/onTochForm";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import {
  USER_DETAIL,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  GROUP_READ_ALL,
  USER_CREATE,
  USER_READ_ALL,
  USER_UPDATE,
} from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

const DetailListOfEmployeeSingle = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const menu = takePermission(10);

  const _onDeleteEmployee = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickChoosePermission = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const shouldLog = useRef(true);
  const shouldLog1 = useRef(true);

  const dispatch = useDispatch();

  // state
  const [username, setUsername] = useState<any>();
  const [fullname, setFullname] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [isActive, setIsActive] = useState<any>();

  const [employeeBophan, setEmployeeBophan] = useState<any>();
  const [employeeChucvu, setEmployeeChucvu] = useState<any>();
  const [employeePhongban, setEmployeePhongban] = useState<any>();
  const [employeeNhomquyen, setEmployeeNhomquyen] = useState<any>([]);

  const [bophanList, setBophanList] = useState<any>(undefined);
  const [chucvuList, setChucvuList] = useState<any>(undefined);
  const [phongbanList, setPhongbanList] = useState<any>(undefined);
  const [nhomquyenList, setNhomquyenList] = useState<any>(undefined);

  const [overloadType, setOverloadType] = useState<number>(0);
  const [overloadFree, setOverloadFree] = useState<number>(0);
  const [overloadBusy, setOverloadBusy] = useState<number>(0);
  const [overloadFull, setOverloadFull] = useState<number>(0);

  // funcs
  const _onChangeOverload = (e: any, type: string) => {
    e.preventDefault();
    switch (type) {
      case "overloadFree":
        setOverloadFree(parseInt(e.target.value));
        break;
      case "overloadBusy":
        setOverloadBusy(parseInt(e.target.value));
        break;
      case "overloadFull":
        setOverloadFull(parseInt(e.target.value));
        break;
      default:
        break;
    }
  };

  const _onChangeOverloadType = () => {
    switch (overloadType) {
      case 0:
        setOverloadType(1);
        break;
      case 1:
        setOverloadType(0);
        break;
    }
  };

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");
    let tempArray: any[] = [];

    employeeNhomquyen.forEach((item: any, index: any) => {
      tempArray.push(parseInt(item));
    });

    const body: any = {
      Id: taskId.toString(),
      UserName: username,
      FullName: fullname,
      Address: address,
      PhoneNumber: phoneNumber,
      BoPhan: parseInt(employeeBophan),
      ChucVu: parseInt(employeeChucvu),
      PhongBan: parseInt(employeePhongban),
      IsActive: isActive === 1 ? true : false,
      GroupsIDs: tempArray,
    };

    try {
      await ServiceUtils.postV2(USER_UPDATE, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Cập nhật thành công.");
          try {
            const takeData = async () => {
              await ServiceUtils.getV2(USER_READ_ALL, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_USERLIST(res.data.Data));
                }
              });
            };

            takeData();
          } catch (error: any) {
            // toast.error(
            //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            // );
          }
        } else {
          toast.error("Cập nhật thất bại.");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const _onChangeSelectedNhomquyen = (e: any) => {
    if (employeeNhomquyen.includes(e.target.value) === false) {
      setEmployeeNhomquyen((oldValue: any) => [...oldValue, e.target.value]);
    } else {
      setEmployeeNhomquyen((oldValue: any) =>
        oldValue.filter((index: any) => index !== e.target.value)
      );
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const API_BOPHAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_BOPHAN";
    const API_CHUCVU = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_CHUCVU";
    const API_PHONGBAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

    try {
      if (shouldLog1.current) {
        shouldLog1.current = false;

        const takeBophan = async () => {
          await ServiceUtils.getV2(API_BOPHAN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setBophanList(res.data.Data);
            }
          });
        };

        const takeChucvu = async () => {
          await ServiceUtils.getV2(API_CHUCVU, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setChucvuList(res.data.Data);
            }
          });
        };

        const takePhongban = async () => {
          await ServiceUtils.getV2(API_PHONGBAN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setPhongbanList(res.data.Data);
            }
          });
        };

        const takeNhomquyen = async () => {
          await ServiceUtils.getV2(GROUP_READ_ALL, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setNhomquyenList(res.data.Data);
            }
          });
        };

        takeBophan();
        takeChucvu();
        takePhongban();
        takeNhomquyen();
      }
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const API = USER_DETAIL + taskId.toString();

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setUsername(res.data.Data[0].UserName);
              setFullname(res.data.Data[0].FullName);
              setEmail(res.data.Data[0].Email);
              setPhoneNumber(res.data.Data[0].PhoneNumber);
              setAddress(res.data.Data[0].Address);
              setIsActive(res.data.Data[0].IsActive);

              setEmployeeBophan(res.data.Data[0].BoPhan);
              setEmployeeChucvu(res.data.Data[0].ChucVu);
              setEmployeePhongban(res.data.Data[0].PhongBan);
              // setEmployeeNhomquyen(res.data.Data[0].GroupIds);
              setOverloadType(res.data.Data[0].OverloadType);
              setOverloadFree(res.data.Data[0].OverloadFree);
              setOverloadBusy(res.data.Data[0].OverloadBusy);
              setOverloadFull(res.data.Data[0].OverloadFull);

              if (res.data.Data[0].GroupsIDs === null) {
                setEmployeeNhomquyen([]);
              } else {
                const tempArray = res.data.Data[0].GroupIds.split(",");
                const convertArray: any[] = [];

                tempArray.forEach((item: any, index: any) => {
                  convertArray.push(item.trim());
                });

                setEmployeeNhomquyen(convertArray);
              }
            } else {
              toast.error(res.data.Message);
            }
          });
        };

        takeData();
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  });

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[50rem] w-screen h-[35rem]
    rounded-md
  "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
        nhomquyenList={nhomquyenList}
        selectedNhomquyen={employeeNhomquyen}
        _onChangeSelectedNhomquyen={_onChangeSelectedNhomquyen}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Thông tin cán bộ</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max core-control-group">
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
            <label>Mã số cán bộ</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={username}
              onChange={(e: any) => {
                e.preventDefault();
                setUsername(e.target.value);
              }}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>

          <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
            <label>Tên cán bộ</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={fullname}
              onChange={(e: any) => {
                e.preventDefault();
                setFullname(e.target.value);
              }}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max core-control-group">
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
            <label>Email</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={email}
              onChange={(e: any) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>

          <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
            <label>Điện thoại</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={phoneNumber}
              onChange={(e: any) => {
                e.preventDefault();
                setPhoneNumber(e.target.value);
              }}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>Địa chỉ liên hệ</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={address}
              onChange={(e: any) => {
                e.preventDefault();
                setAddress(e.target.value);
              }}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col w-full h-max core-control-group">
          <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
            <label>Bộ phận</label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={employeeBophan}
              disabled
              onChange={(e: any) => {
                e.preventDefault();
                setEmployeeBophan(e.target.value);
              }}
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn bộ phận ---
              </option>
              {bophanList === undefined
                ? ""
                : bophanList.map((item: any, index: any) => (
                    <option key={index} value={item.ParameterId}>
                      {item.ParameterValue}
                    </option>
                  ))}
            </select>
          </div>

          <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
            <label>Chức vụ</label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={employeeChucvu}
              onChange={(e: any) => {
                e.preventDefault();
                setEmployeeChucvu(e.target.value);
              }}
              disabled
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn danh mục ---
              </option>
              {chucvuList === undefined
                ? ""
                : chucvuList.map((item: any, index: any) => (
                    <option key={index} value={item.ParameterId}>
                      {item.ParameterValue}
                    </option>
                  ))}
            </select>
          </div>

          <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
            <label>Phòng ban</label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={employeePhongban}
              onChange={(e: any) => {
                e.preventDefault();
                setEmployeePhongban(e.target.value);
              }}
              disabled
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn phòng ban ---
              </option>
              {phongbanList === undefined
                ? ""
                : phongbanList.map((item: any, index: any) => (
                    <option key={index} value={item.ParameterId}>
                      {item.ParameterValue}
                    </option>
                  ))}
            </select>
          </div>

          <div className="flex flex-col lg:w-1/4 w-full lg:pl-2 pr-0 h-max space-y-1">
            <label>Nhóm quyền hạn</label>

            <button
              disabled
              onClick={() => _onClickChoosePermission("choosePermission")}
              className="w-max h-max bg-slate-200 pl-1.5 pr-1.5 rounded-md"
            >
              chọn
            </button>
            {/* <select
              multiple
              className="w-full h-[5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={employeeNhomquyen}
              onChange={(e: any) => {
                e.preventDefault();
                setEmployeeNhomquyen((oldValue: any) => [...oldValue, e.target.value]);
              }}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn phòng ban ---</option>
              {nhomquyenList === undefined ? ""
                : nhomquyenList.map((item: any, index: any) => (
                  <option key={index} value={item.ID}>{item.GroupName}</option>
                ))
              }
            </select> */}
          </div>
        </div>

        {/* <div className="flex lg:flex-row flex-col w-full h-max">
          <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
            <label className="text-green-500">Có thể nhận nhiều việc</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-green-500"
              type="number"
              value={overloadFree}
              onChange={(e: any) => _onChangeOverload(e, "overloadFree")}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
            <label className="text-yellow-500">Nhiều việc</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-yellow-500"
              type="number"
              value={overloadBusy}
              onChange={(e: any) => _onChangeOverload(e, "overloadBusy")}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
            <label className="text-red-500">Quá tải</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-red-500"
              type="number"
              value={overloadFull}
              onChange={(e: any) => _onChangeOverload(e, "overloadFull")}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div> */}

        <div className="flex w-full h-max">
          <fieldset
            className="flex flex-col w-full h-max"
            onChange={_onChangeOverloadType}
          >
            <div className="flex items-center space-x-3">
              <input
                type="radio"
                id="1"
                name="fav_language"
                value={0}
                placeholder=""
              />
              <p>Quá tải = Số đầu việc đang xử lý</p>
            </div>
            <div className="flex items-center space-x-3">
              <input
                type="radio"
                id="1"
                name="fav_language"
                value={1}
                placeholder=""
              />
              <p>Quá tải = Số đầu việc được giao trong tháng</p>
            </div>
          </fieldset>
        </div>
      </div>

      {/* footer */}
      <div
        className={`flex w-full p-12-16 footer rounded-b-md ${
          menu.PerDelete === true ? "justify-end" : "justify-end"
        } items-center text-sm space-x-4`}
      >
        <div className="absolute left-4 bottom-3 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max">
          <p className="font-bold text-primary">Sử dụng phần mềm</p>
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={isActive === 1 ? true : false}
            onChange={() => {
              if (isActive === 1) {
                setIsActive(0);
              } else {
                setIsActive(1);
              }
            }}
          />
        </div>

        <div
          className={`${
            menu.PerDelete === true ? "flex" : "hidden"
          } w-max h-max space-x-3`}
        >
          <button
            className={`
          ${
            menu.PerDelete === true ? "flex" : "hidden"
          } items-center space-x-1 w-max h-max p-2 bg-slate-500 text-white rounded-md
          hover:bg-slate-400
        `}
            onClick={() => _onDeleteEmployee("deleteEmployee")}
          >
            <DeleteIcon fontSize="small" />
            <p className="lg:flex hidden">Xoá</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailListOfEmployeeSingle;
