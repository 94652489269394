import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const SettingProceducePage = () => {
  return(
    <HomeUI />
  )
}

export default SettingProceducePage;
