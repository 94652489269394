import React, { useContext, useState } from "react";

import AddIcon from '@mui/icons-material/Add';

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";

const TargetRatingCapacityUI = () => {
  const context = useContext(ModalController);
  const [filter, setFilter] = useState<any>({});

  const _onClickCreateTargetCapacity = () => {
    context.setFuncs("createTargetCapacity");
    context.handleOpen();
  }

  const onChangeSearch = (e: any) => {
    const value = e.target.value;

    setFilter({
      keyword: value
    });
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Tìm kiếm tiêu chí đánh giá.."
            onChange={onChangeSearch}
          />

          <button className="
              flex p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
            "
            onClick={_onClickCreateTargetCapacity}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table filter={filter} />
      </div>
    </div>
  )
}

export default TargetRatingCapacityUI;
