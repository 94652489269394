import React from "react";

import ContentCore from "../../../../core/workingContent";

import "../../_index.css";

const TaskReceiveReceiverUI = () => {
  return (
    <>
      <ContentCore />
    </>
  )
}

export default TaskReceiveReceiverUI;
