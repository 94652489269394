const reportView = `<html lang="en">

<head>
    <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
    <style>

        body {
            width: 100%;
            font-size: 9px;
        }

        header {
            display: flex;
            gap: 10px;
            align-items: center;

            border-bottom: 4px solid rgb(251, 146, 60);
        }

        header .article::after {
            margin-left: 0px;
        }

        header h2 {
            color: #3498db;
            font-weight: 700;
        }

        #nameOfReport {
            font-weight: 700;
            color: rgb(251, 146, 60);
            text-align: center;
        }

        .type-report-tilte {
            text-align: center;
            font-size: 10px;
            font-weight: 600;
        }

        .type-report {
            text-align: center;
            display: flex;
            justify-content: center;
            gap: 20px;
        }

        .type-report>div {
            display: flex;
            justify-content: center;
            gap: 5px;
        }

        .fr p span {
            font-weight: 400;
        }

        h6 {
            font-size: 12px;
            font-weight: 600;
        }

        table {
            border-spacing: 0;
            border-top: 2px solid black !important;
            border-left: 2px solid black !important;
        }

        th {
            background: #00b1ff;
        }

        th,
        td {
            border: none !important;
            border-right: 2px solid black !important;
            border-bottom: 2px solid black !important;
        }

        tr td {
            padding: 12px;
        }

        tr td:first-child {
            text-align: center;
            width: 20px;
        }
        tr td:nth-child(2) {
            width: 180px;
        }

        tr td:last-child {
            width: 240px;
        }

        tr td:nth-child(2)>div {
            display: flex;
            gap: 6px;
        }

        tr td:nth-child(2) p {
            margin-block-start: 6px;
            margin-block-end: 6px;
        }

        table .text-gray-500 {
            color: rgb(107, 114, 128);
            font-weight: 600;
        }

        table .text-amber-500 {
            color: rgb(251, 146, 60);
            font-weight: 600;
        }

        table .text-green-500 {
            color: rgb(34, 197, 94);
            font-weight: 600;
        }

        table .text-teal-500 {
            color: rgb(20, 184, 166);
            font-weight: 600;
        }

        table .text-red-500 {
            color: rgb(239, 68, 68);
            font-weight: 600;
        }

        .section_2>div,
        .section_2>div .ket-qua {
            padding-left: 18px;
            padding-bottom: 8px;
        }

        .section_2>div .pt-3 {
            padding-top: 8px;
        }

        .section_2>div .font-semibold {
            font-weight: 700;
        }
    </style>
</head>

<body>
    <div class="border-solid">
        <form action="#" method="GET">
            <div class="mx-[5%] my-[3%]">
                <header class="pb-2 flex border-b-orange-400 space-x-3">
                <!--<article>
                        <img id="lacoImage" style="width:8rem;height:6rem;"
                            src="https://thanhnien.mediacdn.vn/Uploaded/nhutnq/2022_10_02/220928180903-03-dall-e-ai-2189.jpg"
                            alt="" /> 
                    </article>-->
                    <article class="my-0">
                        <div class="">
                            <h2 class="text-blue-400 font-bold" style="font-size:16px">
                                LACO TRADE SERVICE CORPORATION
                            </h2>
                            <p class="text-xs" style="font-size:9px">Địa chỉ: Biệt thự Chủ động Anna, Khu nhà ở Minh Ân, X. Xuân Thới Đông, H. Hóc Môn, TP.HCM <br />
                                MST: 0307287200 <br />
                                Website: <a href="www.lacocorp.vn">www.lacocorp.vn</a>
                            </p>
                        </div>
                    </article>

                </header>
                <div>
                    <div class="flex justify-center ">
                        <h1 id="nameOfReport" style="font-size:18px; margin: 10px 0px">BÁO CÁO CÔNG VIỆC</h1>
                        
                    </div>
                    <div style="font-size:10px" class="flex flex-col space-y-1">

                        <div class="type-report-tilte">
                            <p class="font-semibold style="font-size:12px">LOẠI BÁO CÁO</p>
                        </div>
                        <div class="type-report w-full h-max flex justify-center items-center">
                            <div class="w-max h-max flex gap-1 items-center">
                                <input id="reportDay" class="w-3 h-3 my-auto" type="checkbox" disabled="" readonly="" />
                                NGÀY
                            </div>

                            <div class="w-max h-max flex gap-1 items-center">
                                <input id="reportMonth" class="w-3 h-3 my-auto" type="checkbox" disabled="" readonly="" />
                                THÁNG
                            </div>

                            <div class="w-max h-max flex space-x-1 items-center">
                                <input id="reportQuarter" class="w-3 h-3 my-auto " type="checkbox" disabled="" readonly="" />
                                QUÝ
                            </div>

                            <div class="w-max h-max flex gap-1 items-center">
                                <input id="reportYear" class="w-3 h-3 my-auto" type="checkbox" disabled="" readonly="" />
                                NĂM
                            </div>

                            <div class="w-max h-max flex gap-1 items-center">
                                <input id="reportCreateOwnwer" class="w-3 h-3 my-auto" type="checkbox" disabled=""
                                    readonly="" />
                                TỰ CHỌN
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end items-stretch">

                    </div>
                    <br />

                    <br />
                    <!--first report-->
                    <div class="fr space-y-3" style="font-size:10px;font-weight:bold">
                        <div class="flex flex-col" style="width:100%">
                            <p class="w-full">Kính gửi: <span class="font-normal" id="taskOwner">Nguyễn Thị Trúc Đào</span></p>
                            <!-- -->
                            <p class="w-full">Chức vụ: <span class="font-normal" id="levelBoss">Trưởng phòng</span></p>
                            <p class="w-full">Phòng ban: <span class="font-normal" id="phongBanBoss">Kế toán - Hành chánh - Nhân sự</span>
                            </p>
                            <!-- -->
                        </div>
                        <div class="flex flex-col" style="width:100%">
                            <p class="w-full">Tôi tên: <span class="font-normal" id="nameStaff">Lưu Anh Dũng</span></p>
                            <!-- -->
                            <p class="w-full">Chức vụ: <span class="font-normal" id="levelStaff">Trưởng phòng</span></p>
                            <p class="w-full">Phòng Ban: <span class="font-normal" id="phongBanStaff">Phần mềm</span></p>
                            <!-- -->
                        </div>
                    </div>
                    <br />
                </div>
                <div class=" section_1">
                    <h6 style="font-weight:bold">I. CÁC CÔNG VIỆC ĐANG CHỊU TRÁCH NHIỆM</h6>

                    <div style="margin:15px auto">
                        <div id="fromTextDate" class="" style="font-size:15px;margin-left:15px">
                        </div>
                        <p style="margin-left:15px">Tôi hiện đang chịu trách nhiệm thực hiện những công
                            việc như sau: </p>
                    </div>

                    <div style="background-color:#0051ff">
                    </div>
                    <table style="width:100%">
                        <tbody>
                            <tr style="font-weight:bold;height:2rem">
                                <th style="color:white;border:2px solid black" scope="col">STT</th>
                                <th style="color:white;border:2px solid black" scope="col">Nội dung công việc</th>
                                <th style="color:white;border:2px solid black" scope="col">Mô tả</th>
                            </tr>
                        </tbody>
                        <tbody id="bodyOfTaskResponsible">
                            <tr>
                                <td style="border:2px solid black;height:2rem" scope="row">1</td>
                                <td style="border:2px solid black; width: 45%">
                                    <p class="font-semibold">Tets viec co qiuuyy trinh</p>
                                    <p>Người giao: <span>Nguyễn Hoàng Thanh Lam</span></p>
                                    <p>Ngày tạo: <!-- -->05-03-2024</p>
                                    <p>Thời hạn: <!-- -->31-03-2024</p>
                                    <div class="flex w-full h-max space-x-1">
                                        <p>Trạng thái:</p>
                                        <p class="text-amber-500 font-bold">Đang xử lý</p>
                                    </div>
                                    <div class="flex w-full h-max space-x-1">
                                        <p>Kết quả:</p>
                                        <p></p>
                                    </div>
                                </td>
                                <td class="w-1/2 h-full" style="border:2px solid black"></td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div class=" section_2">
                    <h6 style="font-weight:bold;margin:15px auto">II. KẾT QUẢ</h6>
                    <!-- -->
                    <div class="flex flex-col px-4 w-full h-max">
                        <p>Thực hiện chính: <span id="receiverTask"></span> việc</p>
                        <p>Hỗ trợ: <span id="supportTask"></span> việc</p>
                        <p>Hoàn thành: <span id="completeTask"></span> việc</p>
                        
                        <p class="pt-3">Kết quả: </p>

                        <div class="ket-qua flex flex-col w-full h-max px-4 pb-3">
                            <p>+ Xuất sắc: <span id="perfectResult"></span></p>
                            <p>+ Tốt: <span id="goodResult"></span></p>
                            <p>+ Trung bình: <span id="aveResult"></span></p>
                            <p>+ Kém: <span id="badResult"></span></p>
                        </div>

                        <p class="pt-3">Kết quả hỗ trợ: </p>
                        <div class="ket-qua flex flex-col w-full h-max px-4 pb-3">
                            <p>+ Xuất sắc: <span id="perfectResult1"></span></p>
                            <p>+ Tốt: <span id="goodResult1"></span></p>
                            <p>+ Trung bình: <span id="aveResult1"></span></p>
                            <p>+ Kém: <span id="badResult1"></span></p>
                        </div>

                        <p>Năng suất lao động: <span id="workingCapacity"></span></p>
                        <p>Năng suất hỗ trợ: <span id="supportCapacity"></span></p>
                        <p>Năng lực làm việc: <span id="workingLevel"></span></p>
                        <p>Năng lực hỗ trợ: <span id="supportLevel"></span></p>
                    </div>
                    <div id="resultText">
                    </div>

                </div>

                <div class=" section_2">
                    <h6 style="font-weight:bold;margin:15px auto">III. THƯỞNG PHẠT</h6>
                    <div id="bonusOrPayoff"></div>
                </div>
                <div class="section_2">
                    <h6 style="font-weight:bold;margin:15px auto">IV. KẾ HOẠCH HÀNH ĐỘNG</h6>
                    <div id="planOfAction"></div>
                </div>

                
                <div class="review" style="font-size:12px">
                </div>
                <!-- -->

            </div>
        </form>

    </div>


</body>

</html>`;

// const reportView = `
// <!DOCTYPE html>
// <html lang="en">
//     <head>
//         <meta charset="UTF-8">
//         <meta name="viewport" content="width=device-width, initial-scale=1.0">
//         <!-- <link rel="stylesheet" href="reset.css">
//         <link rel="stylesheet" href="style.css"> -->
//         <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"> -->
//         <script src="https://cdn.tailwindcss.com"></script>
//         <title>Document</title>
//     </head>
//     <body style="font-size: 9px;">
//         <div class="border-solid mx-[10%] my-[5%]">
//             <form action="#" method="GET">
//                 <div class="mx-[5%] my-[3%]">
//                     <header class="h-[9rem] flex border-b-[1rem] border-b-orange-400 space-x-3"
//                     style="margin-left: -5.5%; margin-right: -5.5%;">
//                         <article>
//                             <img id="lacoImage" style="width: 8rem; height: 6rem; margin-left: 20%; padding-right: 10%; border-right: 1px solid gray;" src="../Asset1.png" alt="">
//                         </article>
//                         <article class="my-0">
//                             <div class="ml-5">
//                             <h2 class="text-blue-400 font-bold" style="font-size: 26px;">
//                                 LACO TRADE SERVICE CORPORATION
//                             </h2>
//                             <p class="text-xs">Địa chỉ: Biệt thự Chủ động Anna, Khu nhà ở Minh Ân, X. Xuân Thới Đông, H. Hóc Môn, TP.HCM <br>
//                                 MST: 0307287200 <br>
//                                 Website: <a href="www.lacocorp.vn">www.lacocorp.vn</a>
//                             </p>
//                             </div>
//                         </article>

//                     </header>
//                     <div>
//                         <div class="flex justify-center " >
//                             <h1 id="nameOfReport" class="text-orange-400 font-bold text-3xl my-[3%] ">BÁO CÁO CÔNG VIỆC</h1>
//                             <!-- <h1 style="font-weight: bold;">CÔNG VIỆC VÀ KẾ HOẠCH HÀNH ĐỘNG</h1> -->
//                         </div>
//                         <div style="font-size: 9px" class="flex flex-col space-y-1">

//                             <div class="w-full h-max flex justify-center items-center space-x-3">
//                                 <p class="font-semibold text-md">LOẠI BÁO CÁO</p>
//                             </div>
//                             <div class="w-full h-max flex justify-center items-center space-x-3">
//                                 <div class="w-max h-max flex space-x-1 items-center">
//                                     <input
//                                         id="reportDay"
//                                         class="w-3 h-3"
//                                         type="checkbox"
//                                         disabled
//                                         readOnly
//                                     />
//                                     <p>NGÀY</p>
//                                 </div>

//                                 <div class="w-max h-max flex space-x-1 items-center">
//                                     <input
//                                         id="reportMonth"
//                                         class="w-3 h-3"
//                                         type="checkbox"
//                                         disabled
//                                         readOnly
//                                     />
//                                     <p>THÁNG</p>
//                                 </div>

//                                 <div class="w-max h-max flex space-x-1 items-center">
//                                     <input
//                                         id="reportQuarter"
//                                         class="w-3 h-3"
//                                         type="checkbox"
//                                         disabled
//                                         readOnly
//                                     />
//                                     <p>QUÝ</p>
//                                 </div>

//                                 <div class="w-max h-max flex space-x-1 items-center">
//                                     <input
//                                         id="reportYear"
//                                         class="w-3 h-3"
//                                         type="checkbox"
//                                         disabled
//                                         readOnly
//                                     />
//                                     <p>NĂM</p>
//                                 </div>

//                                 <div class="w-max h-max flex space-x-1 items-center">
//                                     <input
//                                         id="reportCreateOwnwer"
//                                         class="w-3 h-3"
//                                         type="checkbox"
//                                         disabled
//                                         readOnly
//                                     />
//                                     <p>TỰ TẠO</p>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="flex justify-end items-stretch">

//                         </div>
//                         <br>

//                         <br>
//                         <div classname="space-y-3" style="font-size: 9px;font-weight: bold;">
//                             <div classname="flex flex-col" style="width: 100%;">
//                                 <p classname="w-full" >Kính gửi: <span id="taskOwner">fafafa</span></p>
//                                 <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Ông/Bà">
//                                     <option selected>Ông</option>
//                                     <option value="1">Bà</option>
//                                     </select>
//                                     <input style="height: 1.3rem; width: 10rem;border: 0cap;" type="text" value="" id="input"> -->
//                                 <p classname="w-full">Chức vụ: <span id="levelBoss"></span></p>
//                                 <p classname="w-full">Phòng ban: <span id="phongBanBoss"></span></p>
//                                 <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
//                                     <option selected>Giám đốc</option>
//                                     <option value="1">One</option>
//                                     <option value="2">Two</option>
//                                     <option value="3">Three</option>
//                                     </select> -->
//                             </div>
//                             <div classname="flex flex-col" style="width: 100%;">
//                                 <p classname="w-full">Tôi tên: <span id="nameStaff"></span></p>
//                                 <!-- <input style="height: 1.3rem; width: 13.9rem; border: 0cap;"  type="text" value="" id="input">
//                                     <i class="recipient_subttl">Chức vụ : </i>
//                                     <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
//                                         <option selected>Nhân viên</option>
//                                         <option value="1">One</option>
//                                         <option value="2">Two</option>
//                                         <option value="3">Three</option>
//                                       </select> -->
//                                 <p classname="w-full">Chức vụ: <span id="levelStaff"></span></p>
//                                 <p classname="w-full">Phòng Ban: <span id="phongBanStaff"></span></p>
//                                 <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Phòng Ban">
//                                     <option selected>Phần mềm</option>
//                                     <option value="1">One</option>
//                                     <option value="2">Two</option>
//                                     <option value="3">Three</option>
//                                     </select> -->
//                             </div>
//                         </div>
//                         <br>
//                     </div>
//                     <div class=" section_1">
//                         <h6 style="font-weight: bold;">I. CÁC CÔNG VIỆC ĐANG CHỊU TRÁCH NHIỆM</h6>

//                         <div style="margin: 15px auto;">
//                             <div id="fromTextDate" class="" style="font-size: 15px;margin-left: 15px;">
//                             </div>
//                             <p style="font-size: 12px;margin-left: 15px;">Tôi hiện đang chịu trách nhiệm thực hiện những công việc như sau: </p>
//                         </div>

//                         <table style="font-size: 12px; width: 100%;  ">
//                             <div style="background-color: #0051ff;">
//                                 <tr style="font-weight: bold; height: 2rem; font-size: 12px;">
//                                     <th style="color:white; border: 2px solid black;" scope="col">STT</th>
//                                     <th style="color:white; border: 2px solid black;" scope="col">Nội dung công việc</th>
//                                     <th style="color:white; border: 2px solid black;" scope="col">Mô tả</th>
//                                 </tr>
//                             </div>
//                             <tbody id="bodyOfTaskResponsible">
//                                 <tr>
//                                     <th style="border: 2px solid black; height: 2rem;" scope="row">1</th>
//                                     <td style="border: 2px solid black;">
//                                     </td>
//                                     <td class="" style="border: 2px solid black;">
//                                         ok
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                     <div class=" section_2">
//                         <h6 style="font-weight: bold;margin: 15px auto;">II.	KẾT QUẢ</h6>
//                         <!-- <table class="table table-bordered table-hover border-dark"> -->
//                         <div class="flex flex-col px-4 w-full h-max">
//                             <p>Tổng việc đã nhận: <span id="receiverTask"></span></p>
//                             <p>Hoàn thành: <span id="completeTask"></span></p>
//                             <p>Hỗ trợ: <span id="supportTask"></span></p>
//                             <p class="pt-3">Kết quả: </p>

//                             <div class="flex flex-col w-full h-max px-4 pb-3">
//                                 <p>+ Xuất sắc: <span id="perfectResult"></span></p>
//                                 <p>+ Tốt: <span id="goodResult"></span></p>
//                                 <p>+ Trung bình: <span id="aveResult"></span></p>
//                                 <p>+ Kém: <span id="badResult"></span></p>
//                             </div>

//                             <p>Năng suất lao động: <span id="workingCapacity"></span></p>
//                             <p>Năng suất hỗ trợ: <span id="supportCapacity"></span></p>
//                             <p>Năng lực làm việc: <span id="workingLevel"></span></p>
//                             <p>Năng lực hỗ trợ: <span id="supportLevel"></span></p>
//                         </div>
//                         <div id="resultText">
//                         </div>

//                     </div>

//                     <div class=" section_2">
//                         <h6 style="font-weight: bold;margin: 15px auto;">III.	THƯỞNG PHẠT</h6>
//                         <div id="bonusOrPayoff"></div>
//                     </div>

//                     <div class="section_3">
//                         <h6 style="font-weight: bold;margin:15px auto">IV. KẾ HOẠCH HÀNH ĐỘNG</h6>
//                         <div id="planOfAction"></div>
//                         <div class="section_3--content" style="font-size: 12px;">

//                         </div>
//                     </div>
//                     <div class="review" style="font-size: 12px;">
//                     </div>
//                     <!-- <div class="btn_submit" style="width: 100%; text-align: center;">
//                         <button type="submit" class="btn btn-primary">
//                             Gui
//                         </button>
//                         </div> -->

//                 </div>
//             </form>
//             <footer>
//                 <img style=" width:100%;" src="Asset1.svg" alt="">
//             </footer>
//         </div>
//     </body>
// </html>
// `;

export default reportView;
