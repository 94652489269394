import { TASK_STATUSES } from '../../constants/dataConstants';

class TaskUtils {
    static isHaveRecovery = (status: any): Boolean => {
        const _isHaveRecovery = [TASK_STATUSES.assigned, TASK_STATUSES.processing, TASK_STATUSES.received].find(p => p == status) ? true : false;
    
        return _isHaveRecovery;
    }

    static isHaveTransfer = (status: any): Boolean => {
        const _isHaveRecovery = TaskUtils.isHaveRecovery(status);
    
        const _isHaveTransfer = (_isHaveRecovery || TASK_STATUSES.recovery == status);
    
        return _isHaveTransfer;
    }
}

export default TaskUtils;