import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import moment from "moment";
import { Checkcolor } from "../../../funcs/checkColor";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import ServiceUtils from "../../../utils/services";
import { CONFIG_IS_FORCE, NGUOI_GIAO_VIEC_DETAIL } from "../../../apis/_index";
import { toast } from "react-toastify";
import SubModal from "../_subModal/_index";
import { ModalController } from "../../../ui/homeUI/_index";
import { useDispatch } from "react-redux";

const ListTaskDeduct = (props: any) => {
  _onClickOther();
  const appData = useSelector((state: any) => state.appReducer);
  const handleClose = props.handleClose;
  const data = props.taskDeduct;
  const [bienDoTreHan, setbienDoTreHan] = useState<any>(null);
  const [completedEarly, setCompletedEarly] = useState<any>(null);
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const [taskId, setTaskId] = useState<any>("");

  async function fetchBienDoTreHan() {
    try {
      await ServiceUtils.getV2(CONFIG_IS_FORCE, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          setbienDoTreHan(res?.data?.Data[0]?.BienDoTreHan);
          setCompletedEarly(res?.data?.Data[0]?.CompletedEarly);
        } else {
          toast.error(res.data.Message);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  }

  useEffect(() => {
    fetchBienDoTreHan();
  }, []);

  const _renderStatus = (item: any) => {
    const currentTime = new Date().getTime();
    const deadlineTime = new Date(item?.DeadLine).getTime();
    const newDeadline = new Date(item?.NewDeadline).getTime();
    const assignmentDate = new Date(item?.AssignmentDate).getTime();
    const finishedDate = new Date(item?.FinishedDate).getTime();
    const isInProgres =
      assignmentDate <= currentTime && currentTime <= deadlineTime;
    const isWithinExtraTime = currentTime <= newDeadline;
    const isWithinExtraTime_ =
      deadlineTime <= finishedDate && finishedDate <= newDeadline;

    if (item?.TaskStatus === 4) {
      if (!item?.IsCompleted_History) {
        if (isInProgres) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      } else {
        if (assignmentDate <= finishedDate && finishedDate <= deadlineTime) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime_) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      }
    } else if (item?.TaskStatus === 5) {
      const timeEarlyCompleted = new Date(
        deadlineTime - ((deadlineTime - assignmentDate) / 100) * completedEarly
      ).getTime();
      if (timeEarlyCompleted >= finishedDate && finishedDate <= deadlineTime) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Sớm hạn
          </p>
        );
      } else if (
        timeEarlyCompleted < finishedDate &&
        finishedDate <= deadlineTime
      ) {
        return (
          <p className="bg-pink-700 text-white w-max p-1 rounded-md text-xs font-semibold">
            Đúng hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    } else if (item?.TaskStatus === 2 || item?.TaskStatus === 3) {
      if (isInProgres) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong tiến độ
          </p>
        );
      } else if (isWithinExtraTime) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    }
  };

  const _onClickDetail = (id: any) => {
    const API = NGUOI_GIAO_VIEC_DETAIL(id);
    ServiceUtils.getV2(API)
      .then((res: any) => {
        if (res?.data && res?.data.StatusCode === 200) {
          const task = res.data.Data.task;
          setTaskId(task.ID);
          if (task.TaskStatus === 5) {
            setFuncsSub("taskNeedConfirm");
          } else {
            setFuncsSub("readTotalTask_nguoigiao");
          }
          handleOpenSub();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error: any) => {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      });
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[70vw] w-screen lg:h-max max-h-[90vh]
        rounded-md
        "
    >
      <SubModal
        open={openSub}
        // timeExtend={task === null ? "" : task.DeadLine}
        // hoursExtend={task === null ? "" : task?.HoursUsed}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        // isTransfer={isTransfer}
        // taskHistory={taskHistory}
        // takeHistoryDetail={takeHistoryDetail}
        // isViewHistoty={isViewHistoty}
        // taskContentsHistory={taskContentsHistory}
        // taskContentHistoryId={taskContentHistoryId}
        taskId={taskId}
        // idHistory={idHistory}
        // _taskContents={_taskContents}
        // _handleCheckCompled={_handleCheckCompled}
        // taskInfoText={taskInfoText}
        // task={task === null ? " " : task}
        // taskDeduct={taskDeduct}
      />
      {/* header */}
      <div
        className="
    flex w-full bg-sky-500 rounded-t-md
    p-12-16 items-center font-semibold text-white
    text-lg justify-between

  "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Danh sách chia việc</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {data.length === 0 ? (
        <div className="flex justify-center items-center h-full overflow-auto p-4 text-lg">
          <p className="text-primary">Không chia việc nào</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Kết quả</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {data.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      {index + 1}
                    </td>
                    <td className="border border-slate-300 space-y-0.5">
                      <p className="lg:hidden font-bold">
                        <span className="lg:hidden">
                          {index}
                          .&nbsp;
                        </span>
                        {item.TaskName}
                      </p>
                      <p className="hidden lg:flex">{item.TaskName}</p>
                      <p className="lg:hidden">
                        <span className="text-xs"> Người giao: </span>
                        <span className="font-semibold">
                          {item.TaskOwnerName}
                        </span>
                      </p>
                      <p className="lg:hidden">
                        <span className="text-xs">Người nhận: </span>
                        <span className="font-semibold">
                          {item.RecipientName}
                        </span>
                      </p>
                      {item.SupporterName !== null && (
                        <p className="lg:hidden">
                          <span className="text-xs">Người hỗ trợ:&nbsp;</span>
                          <span className="text-slate-600">
                            {item.SupporterName}
                          </span>
                        </p>
                      )}

                      <div className="lg:hidden w-full h-max flex flex-col justify-center">
                        <p>
                          <span className="text-xs">Thời hạn: </span>
                          <span className="italic">
                            {moment(item.AssignmentDate).format("HH:mm")}
                            &nbsp;
                            {moment(item.AssignmentDate).format("DD/MM")}
                          </span>
                          &nbsp;-&nbsp;
                          <span className="italic">
                            {moment(item.DeadLine).format("HH:mm")}&nbsp;
                            {moment(item.DeadLine).format("DD/MM")}
                          </span>
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold mr-2 ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="work-status flex gap-1 flex-wrap">
                          {item.TaskStatus === 2 ? (
                            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                              Chưa nhận
                            </p>
                          ) : null}
                          {item.TaskStatus === 3 ? (
                            <p className="bg-sky-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                              Chưa xử lý
                            </p>
                          ) : null}
                          {item?.TaskStatus === 4 ? (
                            <>
                              {!item?.IsCompleted_History ? (
                                <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Đang xử lý
                                </p>
                              ) : (
                                <p className="bg-orange-300 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Đã báo cáo chờ duyệt
                                </p>
                              )}
                            </>
                          ) : null}
                          {item.TaskStatus === 5 ? (
                            <>
                              <p className="bg-teal-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                Hoàn tất
                              </p>
                            </>
                          ) : null}
                          {<>{_renderStatus(item)}</>}
                          {item.NumberReturnWork > 0 && (
                            <p className="text-red-500 text-xs lg:text-sm font-semibold">
                              Bị trả {item.NumberReturnWork} lần
                            </p>
                          )}
                        </div>

                        <div className="lg:hidden flex justify-end items-center text-primary decoration-clone">
                          <button
                            onClick={() => {
                              _onClickDetail(item.ID);
                            }}
                          >
                            Chi tiết
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <p
                        style={{
                          color: Checkcolor(item.PriorityLevelID, appData),
                        }}
                        className={`font-bold ${Checkcolor(
                          item.PriorityLevelID,
                          appData
                        )}`}
                      >
                        {item.PriorityLevelName}
                      </p>
                    </td>
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <div className="w-full h-max flex flex-col justify-center items-center">
                        <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                        <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                      </div>
                    </td>
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <div className="w-full h-max flex flex-col justify-center items-center">
                        <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                        <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                      </div>
                    </td>
                    <td className="hidden lg:table-cell border border-slate-300">
                      {item.TaskOwnerName}
                    </td>
                    <td className="hidden lg:table-cell border border-slate-300">
                      {item.RecipientName}
                      {item.SupporterName && (
                        <p className="text-xs">
                          <span className="text-slate-600">Hỗ trợ:</span>{" "}
                          {item.SupporterName}
                        </p>
                      )}
                    </td>
                    <td className="hidden lg:table-cell border border-slate-300">
                      {item.TaskScorecardName}
                    </td>

                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <div className="dropdown">
                        {/* button options */}
                        <button className="dropbtn">
                          <MoreHorizIcon fontSize="small" />
                        </button>

                        {/* options fields */}
                        {item.TaskStatus === 5 ? (
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickDetail(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickDetail(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex p-12-16 footer rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          <button
            className="
      flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
      hover:bg-slate-300
    "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ListTaskDeduct;
