import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const CategorySuggestPage = () => {
  return(
    <HomeUI />
  )
}

export default CategorySuggestPage;
