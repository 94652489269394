import ReadTotalTask from "../working/readTotalTask";

const ReviewHistoryTaskPreviousSubModal = (props: any) => {
    const taskId = props.taskId;
    const handleClose = props.handleClose;
    const isReviewHistoryTaskPrevious = true;

    return <ReadTotalTask isReviewHistoryTaskPrevious={isReviewHistoryTaskPrevious} taskId={taskId} handleClose={handleClose} />;
} 

export default ReviewHistoryTaskPreviousSubModal;