import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { takePermission } from "../../../funcs";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";
import { ModalController } from "../../../ui/homeUI/_index";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HandleUI from "../../../utils/handleUI";

const SettingListOfEmployee = () => {
  const menu = takePermission(10);
  _onClickOther();
  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const appData = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);
  const [bienDoTreHan, setbienDoTreHan] = useState<any>(null);
  const [completedEarly, setCompletedEarly] = useState<any>(null);

  const link = window.location.pathname;

  const shouldLog = useRef(true);

  const _onClickDeleteTask = (id: any) => {
    context.setFuncs("deleteEmployee");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickListOfEmployee = (id: any) => {
    context.setFuncs("detailListOfEmployee");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickListOfEmployeeSingle = (id: any) => {
    context.setFuncs("detailListOfEmployeeSingle");
    context.setTaskId(id);
    context.handleOpen();
  };

  const renderPosition = (item: any) => {
    const positions = ((item || {}).TenChucVu || "")
      .split(",")
      .filter((p: any) => p);
    const organizations = ((item || {}).TenPhongBan || "")
      .split(",")
      .filter((p: any) => p);

    const results = [];

    for (let i = 0; i < positions.length; i++) {
      results.push(`${positions[i] || ""}: ${organizations[i] || ""}`);
    }

    return results.join(", ");
  };

  return (
    <div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] lg:mt-0 mt-3 lg:pb-0 pb-[5rem]">
      {" "}
      {/* warm */}
      <div
        className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md"
        id="table"
      >
        <table>
          <thead className="text-white font-semibold text-center w-full h-[2rem]">
            <tr>
              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                STT
              </th>
              <th className="border-l-[1px] border-slate-300">Mã số</th>
              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                Họ và tên
              </th>
              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                Chức vụ
              </th>
              {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Bộ phận
                </th> */}
              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                Hoạt động
              </th>
              <th className="hidden lg:table-cell border-l-[1px] border-r-[1px] border-slate-300"></th>
            </tr>
          </thead>
          <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
            {settingData.userList === null ? (
              <tr></tr>
            ) : (
              settingData.userList.map((item: any, index: any) => (
                <tr key={index} className="w-max h-[3rem] bg-white">
                  <td className="hidden lg:table-cell text-center border border-slate-300">
                    {index + 1}
                  </td>
                  <td className="border border-slate-300 space-y-0.5">
                    <p className="lg:hidden font-bold">
                      <span className="lg:hidden">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                        .&nbsp;
                      </span>
                      {item.UserName}
                    </p>
                    <p className="hidden lg:flex">{item.UserName}</p>
                    <p className="lg:hidden">
                      <span className="text-xs"> Họ và tên: </span>
                      <span className="font-semibold">{item.FullName}</span>
                    </p>
                    <p className="lg:hidden">
                      <span className="text-xs">Chức vụ: </span>
                      <span className="font-semibold">
                        {renderPosition(item)}
                      </span>
                    </p>
                    {/* <p className="lg:hidden">
                        <span className="text-xs">Bộ phận: </span>
                        <span className="font-semibold">{item.TenBoPhan}</span>
                      </p> */}
                    <div className="flex items-center justify-between">
                      <p className="lg:hidden flex items-center">
                        <span className="text-xs mr-1">Hoạt động: </span>
                        <input
                          type="checkbox"
                          className="w-5 h-5"
                          checked={item.IsActive === 1 ? true : false}
                          readOnly
                        />
                      </p>
                      <div className="lg:hidden ml-3 flex justify-end items-center">
                        <div className="dropdown2">
                          {/* button options */}
                          <button
                            className="dropbtn2"
                            onClick={() => _onClickFuncs(index.toString())}
                          >
                            ...
                          </button>

                          {/* options fields */}
                          <div
                            id={index.toString()}
                            className="dropdown-content2 text-xs font-semibold"
                          >
                            {/* detail */}
                            <div
                              className={`${menu.PerEdit === true ? "flex" : "hidden"
                                } w-full h-full`}
                            >
                              <button
                                className="flex w-full h-full p-2"
                                onClick={() =>
                                  _onClickListOfEmployee(item.Id)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chỉnh sửa</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`${menu.PerEdit === true ? "hidden" : "flex"
                                } w-full h-full`}
                            >
                              <button
                                className="flex w-full h-full p-2"
                                onClick={() =>
                                  _onClickListOfEmployeeSingle(item.Id)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`${menu.PerDelete === true ? "flex" : "hidden"
                                } w-full h-full`}
                            >
                              <button
                                className="flex w-full h-full hover:text-red-500 p-2"
                                onClick={() => _onClickDeleteTask(item.Id)}
                              >
                                {/* delete - contain */}
                                <div className="flex w-full space-x-1">
                                  {/* icon */}
                                  <DeleteTwoToneIcon
                                    className="text-red-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div className="h-full">Xoá</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  {/* <td className="border border-slate-300">{item.UserName}</td> */}
                  <td className="hidden lg:table-cell text-left border border-slate-300">
                    {item.FullName}
                  </td>
                  <td className="hidden lg:table-cell text-left border border-slate-300">
                    {renderPosition(item)}
                  </td>
                  {/* <td className="hidden lg:table-cell text-left border border-slate-300">
                      {item.TenBoPhan}
                    </td> */}
                  <td className="hidden lg:table-cell text-center border border-slate-300">
                    <input
                      type="checkbox"
                      className="w-5 h-5"
                      checked={item.IsActive === 1 ? true : false}
                      readOnly
                    />
                  </td>
                  <td className="hidden lg:table-cell text-center border border-slate-300">
                    <div className="dropdown">
                      {/* button options */}
                      <button
                        className="dropbtn"
                        onMouseEnter={() => {
                          HandleUI.hoverMenuContextScrollTable(
                            index,
                            settingData.userList.length
                          );
                        }}
                      >
                        <MoreHorizIcon fontSize="small" />
                      </button>

                      {/* options fields */}
                      <div className="dropdown-content text-xs font-semibold">
                        {/* detail */}
                        <div
                          className={`${menu.PerEdit === true ? "flex" : "hidden"
                            } w-full h-full`}
                        >
                          <button
                            className="flex w-full h-full p-2"
                            onClick={() => _onClickListOfEmployee(item.Id)}
                          >
                            {/* detail - contain */}
                            <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                              {/* icon */}
                              <DescriptionTwoToneIcon
                                className="text-sky-600"
                                sx={{ fontSize: 18 }}
                                fontSize="small"
                              />

                              {/* title */}
                              <div>Chỉnh sửa</div>
                            </div>
                          </button>
                        </div>

                        <div
                          className={`${menu.PerEdit === true ? "hidden" : "flex"
                            } w-full h-full`}
                        >
                          <button
                            className="flex w-full h-full p-2"
                            onClick={() =>
                              _onClickListOfEmployeeSingle(item.Id)
                            }
                          >
                            {/* detail - contain */}
                            <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                              {/* icon */}
                              <DescriptionTwoToneIcon
                                className="text-sky-600"
                                sx={{ fontSize: 18 }}
                                fontSize="small"
                              />

                              {/* title */}
                              <div>Chi tiết</div>
                            </div>
                          </button>
                        </div>

                        <div
                          className={`${menu.PerDelete === true ? "flex" : "hidden"
                            } w-full h-full`}
                        >
                          <button
                            className="flex w-full h-full hover:text-red-500 p-2"
                            onClick={() => _onClickDeleteTask(item.Id)}
                          >
                            {/* delete - contain */}
                            <div className="flex w-full space-x-1">
                              {/* icon */}
                              <DeleteTwoToneIcon
                                className="text-red-600"
                                sx={{ fontSize: 18 }}
                                fontSize="small"
                              />

                              {/* title */}
                              <div className="h-full">Xoá</div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SettingListOfEmployee;