import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import axios from "axios";

import {
  CREATE_CATEGORY_SUGGEST_API,
  CREATE_PROCEDURE_API,
  DETAIL_PARAMETER,
  GET_DETAIL_CATEGORY_SUGGEST_API,
  GET_DETAIL_PROCEDURE_API,
  GET_LIST_CATEGORY_SUGGEST_API,
  GET_LIST_POSITION_BY_ORGANIZATION,
  GET_LIST_POSITION_DROPDOWN,
  GET_LIST_PROCEDURE_STEP_STATUS_DROPDOWN,
  PRIOR_CREATE,
  PRIOR_LIST,
  SETTING_PROCEDUCE_LIST,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  UPDATE_CATEGORY_SUGGEST_API,
  UPDATE_PROCEDURE_API,
} from "../../../apis/_index";
import {
  ONLOAD_CATEGORY_SUGGEST,
  ONLOAD_PRIORLIST,
} from "../../../redux/reducers/settingReducer/_index";

import { onTouchForm } from "../../../funcs/onTochForm";
import { ModalController } from "../../../ui/homeUI/_index";
import AddIcon from "@mui/icons-material/Add";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  PROCEDUCE_STEP_CONTENT_TYPES,
  PROCEDUCE_STEP_CONTENT_TYPE_OBJECTS,
  PROCEDUCE_STEP_SENDER_TYPES,
  PROCEDUCE_STEP_TYPES,
  PROCEDUCE_STEP_TYPE_OBJECTS,
} from "../../../constants/dataConstants";
import { Guid } from "guid-typescript";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { useSelector } from "react-redux";
import { ONLOAD_SETTINGPROCEDUCE_ALL } from "../../../redux/reducers/workingReducer/_index";
import OrganizationUtils from "../../../utils/organizations/organizationUtils";
import LevelComponent from "../../organizations/levels/levelComponent";
import OrganizationRepository from "../../../repositories/organizations/organizationRepository";
import ServiceUtils from "../../../utils/services";

import "../../../styles/createOrUpdateSettingProceduces/index.css";

const CreateOrUpdateSettingProceduce = (props: any) => {
  const { workingReducer } = useSelector((state: any) => ({ ...state }));
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const id = props.id;
  const handleClose = props.handleClose;

  // state
  const [name, setName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [selectedProceduce, setSelectedProceduce] = useState<any>(0);
  const [proceduces, setProceduceList] = useState<any>([]);
  const [proceduceSteps, setProceduceStep] = useState<any>([]);
  const [organizations, setOrganization] = useState<any>([]);
  const [procedureStepStatues, setProcedureStepStatus] = useState<any>([]);

  const [dataPosition, setDataPosition] = useState<any>([]);

  const listOptions = [
    { ChucVu: 0, ParameterValue: "Người quản lý từ cấp trên" },
    { ChucVu: 1, ParameterValue: "Người quản lý chính" },
    { ChucVu: 2, ParameterValue: "Người quản lý phụ" },
  ];

  const _getListOrganization = () => {
    // const accessToken = localStorage.getItem("accessToken");

    // const url = TAKE_PARAMETER_LISTKEY_DROPDOWN + 'LI_PHONGBAN';

    // ServiceUtils.getV2(url, {
    //     headers: {
    //         Accept: "text/plain"
    //     }
    // }).then(res => {
    //     const organizations = (res.data || {}).Data || [];

    //     const organizationTrees = OrganizationUtils.getListTree(organizations, {
    //         id: 'ParameterId',
    //         parentId: 'RefId'
    //     });

    //     organizationTrees.unshift({
    //         ParameterId: -1,
    //         ParameterValue: 'Trống'
    //     });

    //     setOrganization(organizationTrees);
    // });

    OrganizationRepository.getListDropdown().then((res: any) => {
      if (res.status) {
        const data = res.data.organizations || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: "ParameterId",
          parentId: "RefID",
        });

        setOrganization(organizationTrees);
      }
    });
  };

  const getListProcedureStepStatus = () => {
    const url = GET_LIST_PROCEDURE_STEP_STATUS_DROPDOWN;

    ServiceUtils.getV2(url, {
      headers: {
        Accept: "text/plain",
      },
    }).then((res: any) => {
      const procedureStepStatuses =
        ((res.data || {}).Data || {}).settingProcecureStepStatuses || [];

      setProcedureStepStatus(procedureStepStatuses);
    });
  };

  const _close = () => {
    handleClose();

    context.setTaskId("");

    context.handleClose();
    context.handleClose();
  };

  const _getDetail = async () => {
    if (id) {
      const api = GET_DETAIL_PROCEDURE_API.replace("{id}", id);

      try {
        await ServiceUtils.getV2(api, {
          headers: {
            "Content-Type": "application/json",
            Accept: "text/plain",
          },
        }).then(async (res: any) => {
          if ((res.data || {}).statusCode == 200) {
            const data = (res.data || {}).data || {};
            console.log("data, ", data);
            const procedure = data.procedure || {};
            const procedureSteps = data.procedureSteps || [];

            for (let i = 0; i < procedureSteps.length; i++) {
              let positions: any = [];
              if (
                procedureSteps[i].organizationID === null ||
                procedureSteps[i].organizationID === ""
              ) {
                positions = listOptions;
              } else {
                positions = await _getListPositionByOrganization(
                  procedureSteps[i].organizationID
                );
              }
              procedureSteps[i].positions = positions;
            }
            setName(procedure.name);
            setDescription(procedure.description);
            setProceduceStep(procedureSteps);
          } else {
            toast.error("Lấy chi tiết quy trình thất bại");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  };

  // funcs
  const _checkValid = (): boolean => {
    if (!name) {
      return true;
    }

    if (proceduceSteps.length <= 0) {
      return true;
    }

    const checkStepNumber = proceduceSteps.find((p: any) => !p.stepNumber);

    if (checkStepNumber) {
      return true;
    }

    const checkStepName = proceduceSteps.find((p: any) => !p.name);

    if (checkStepName) {
      return true;
    }

    // const checkOrganization = proceduceSteps.find(
    //   (p: any) =>
    //     !p.organizationID && p.senderType == PROCEDUCE_STEP_SENDER_TYPES.option
    // );

    // if (checkOrganization) {
    //   return true;
    // }

    // const checkPosition = proceduceSteps.find(
    //   (p: any) =>
    //     !p.positionID && p.senderType == PROCEDUCE_STEP_SENDER_TYPES.option
    // );

    // if (checkPosition) {
    //   return true;
    // }

    // const checkContentType = proceduceSteps.find(
    //   (p: any) => !p.contentType && p.isRequireContent
    // );

    // if (checkContentType) {
    //   return true;
    // }

    // const checkType = proceduceSteps.find(
    //   (p: any) => !PROCEDUCE_STEP_TYPES.find((m: any) => m.value == p.type)
    // );

    // if (checkType) {
    //   return true;
    // }

    // const checkOrganizationSuggest = proceduceSteps.find(
    //   (p: any) =>
    //     p.type == PROCEDUCE_STEP_TYPE_OBJECTS.confirmSuggestNotConfirm &&
    //     !p.organizationSuggestId
    // );

    // if (checkOrganizationSuggest) {
    //   return true;
    // }

    // const checkPositionSuggest = proceduceSteps.find(
    //   (p: any) =>
    //     p.type == PROCEDUCE_STEP_TYPE_OBJECTS.confirmSuggestNotConfirm &&
    //     !p.positionSuggestID
    // );

    // if (checkPositionSuggest) {
    //   return true;
    // }
    const checkAction = proceduceSteps.map((item: any) => {
      if (item.type == "1") {
        if (!item.status1 && item.status1 != 0) return false;

        if (!item.labelFunction1) return false;

        if (item.status1 == 3) if (!item.result1) return false;
      }
      if (item.type == "2") {
        if (!item.status1 && item.status1 != 0) return false;
        if (!item.labelFunction1) return false;
        if (item.status1 == 3) if (!item.result1) return false;

        if (!item.status2 && item.status2 != 0) return false;
        if (!item.labelFunction2) return false;
        if (item.status2 == 3) if (!item.result2) return false;
      }
      if (item.type == "3") {
        if (!item.status1 && item.status1 != 0) return false;
        if (!item.labelFunction1) return false;
        if (item.status1 == 3) if (!item.result1) return false;

        if (!item.status2 && item.status2 != 0) return false;
        if (!item.labelFunction2) return false;
        if (item.status2 == 3) if (!item.result2) return false;

        if (!item.status3 && item.status3 != 0) return false;
        if (!item.labelFunction3) return false;
        if (item.status3 == 3) if (!item.result3) return false;
      }
      return true;
    });

    const action = !checkAction.some((value: boolean) => value === false);
    if (!action) {
      return true;
    }

    return false;
  };

  const _onChangeName = (e: any) => {
    e.preventDefault();

    setName(e.target.value);
  };

  const _onChangeSelectedProcedure = (e: any) => {
    e.preventDefault();
    setSelectedProceduce(e.target.value);
  };

  const _onClickAddStep = () => {
    const proceduceStepNews = [...proceduceSteps];
    proceduceStepNews.push({
      // id: Guid.create().toString(),
      // name: "",
      // description: "",
      // organizationID: "",
      // positionID: listOptions[0].ChucVu,
      // positions: listOptions,
      // isRequireContent: false,
      // contentType: PROCEDUCE_STEP_CONTENT_TYPE_OBJECTS.file,
      // stepNumber: proceduceStepNews.length + 1,
      // type: PROCEDUCE_STEP_TYPE_OBJECTS.transferStep,
      // labelFunction1: "",
      // labelFunction2: "",
      // labelFunction3: "",
      // result1: null,
      // result2: null,
      // result3: null,
      // isExpand: true,
      // isSendEmailPersonReceived: true,
      // isSendAlertPersonReceived: true,
      // isSendEmailPersonFeedback: true,
      // isSendAlertPersonFeedback: true,
      // senderType: PROCEDUCE_STEP_SENDER_TYPES.option,
      contentType: PROCEDUCE_STEP_CONTENT_TYPE_OBJECTS.file,
      description: "",
      id: Guid.create().toString(),
      isRequireContent: false,
      isSendEmailPersonReceived: true,
      isSendAlertPersonReceived: true,
      isSendEmailPersonFeedback: true,
      isSendAlertPersonFeedback: true,

      labelFunction1: "",
      labelFunction2: "",
      labelFunction3: "",
      name: "",

      organizationID: null,
      organizationSuggestId: null,

      positionID: listOptions[0].ChucVu,
      positionSuggestID: listOptions[0].ChucVu,

      positions: listOptions,
      // positionSuggests: listOptions,

      result1: null,
      result2: null,
      result3: null,
      senderType: PROCEDUCE_STEP_SENDER_TYPES.option,
      status1: null,
      status2: null,
      status3: null,
      stepNumber: proceduceStepNews.length + 1,
      type: PROCEDUCE_STEP_TYPE_OBJECTS.transferStep,
      isExpand: true,
    });

    setProceduceStep(proceduceStepNews);
  };

  const _onToggleExpandProceduceStep = (item: any) => () => {
    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isExpand = !proceduceStep.isExpand;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onDeleteProceduceStep = (item: any) => () => {
    let _proceduceSteps = [...proceduceSteps];
    _proceduceSteps = _proceduceSteps.filter((p) => p.id != item.id);
    for (let i = item.stepNumber - 1; i < _proceduceSteps.length; i++) {
      _proceduceSteps[i].stepNumber -= 1;
    }
    setProceduceStep(_proceduceSteps);
  };

  const _onCollapseProceduceStep = (item: any) => () => {
    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isExpand = false;

      setProceduceStep(_proceduceSteps);
    }
  };
  //1
  // const _onChangeOrganization = (item: any) => async (e: any) => {
  //   const value = e.target.value;

  //   const _proceduceSteps: any = [...proceduceSteps];

  //   const proceduceStep = _proceduceSteps.find((p: any) => p.id == item.id);

  //   if (proceduceStep) {
  //     proceduceStep.organizationID = value;

  //     let positions: any = [];
  //     if (value == "") {
  //       positions = listOptions;
  //     } else {
  //       positions = await _getListPositionByOrganization(
  //         proceduceStep.organizationID
  //       );
  //     }
  //     proceduceStep.positions = positions;
  //     proceduceStep.positionID = positions[0].ChucVu || "";
  //     setProceduceStep(_proceduceSteps);
  //   }
  // };
  // const _onChangeOrganizationSuggest = (item: any) => async (e: any) => {
  //   const value = e.target.value;

  //   const _proceduceSteps = [...proceduceSteps];

  //   const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

  //   if (proceduceStep) {
  //     proceduceStep.organizationSuggestId = value;

  //     let positions: any = [];
  //     if (value == "") {
  //       positions = listOptions;
  //     } else {
  //       positions = await _getListPositionByOrganization(
  //         proceduceStep.organizationSuggestId
  //       );
  //     }

  //     proceduceStep.positionSuggests = positions;

  //     proceduceStep.positionSuggestID = positions[0].ChucVu || "";
  //     setProceduceStep(_proceduceSteps);
  //   }
  // };
  const updateProceduceStep = async (
    item: any,
    propertyName: string,
    value: any
  ) => {
    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p: any) => p.id === item.id);

    if (proceduceStep) {
      proceduceStep[propertyName] = value;

      let positions: any = [];
      if (value === "") {
        positions = listOptions;
      } else {
        positions = await _getListPositionByOrganization(
          proceduceStep[propertyName]
        );
      }

      proceduceStep[
        propertyName === "organizationID" ? "positions" : "positionSuggests"
      ] = positions;

      proceduceStep[
        propertyName === "organizationID" ? "positionID" : "positionSuggestID"
      ] = positions.length > 0 ? positions[0].ChucVu : null;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeOrganization = (item: any) => async (e: any) => {
    const value = e.target.value;
    await updateProceduceStep(item, "organizationID", value);
  };

  const _onChangeOrganizationSuggest = (item: any) => async (e: any) => {
    const value = e.target.value;
    await updateProceduceStep(item, "organizationSuggestId", value);
    console.log("proceduceSteps: ", proceduceSteps);
  };

  //9
  // const _onChangePosition = (item: any) => async (e: any) => {
  //   const value = e.target.value;

  //   const _proceduceSteps = [...proceduceSteps];

  //   const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

  //   if (proceduceStep) {
  //     proceduceStep.positionID = value;

  //     setProceduceStep(_proceduceSteps);
  //   }
  // };
  //9
  // const _onChangePositionSuggest = (item: any) => async (e: any) => {
  //   const value = e.target.value;

  //   const _proceduceSteps = [...proceduceSteps];

  //   const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

  //   if (proceduceStep) {
  //     proceduceStep.positionSuggestID = value;

  //     setProceduceStep(_proceduceSteps);
  //   }
  // };

  const updateProceduceStepPosition = (
    item: any,
    propertyName: string,
    value: any
  ) => {
    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p: any) => p.id === item.id);

    if (proceduceStep) {
      proceduceStep[propertyName] = value;
      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangePosition = (item: any) => async (e: any) => {
    const value = e.target.value;
    await updateProceduceStepPosition(item, "positionID", value);
  };

  const _onChangePositionSuggest = (item: any) => async (e: any) => {
    const value = e.target.value;
    await updateProceduceStepPosition(item, "positionSuggestID", value);
    console.log("proceduceSteps: ", proceduceSteps);
  };

  const _getListPositionByOrganization = (organizationID: any) => {
    return new Promise((resolve) => {
      const url = DETAIL_PARAMETER + organizationID;
      // const url = GET_LIST_POSITION_BY_ORGANIZATION.replace(
      //   "{organizationId}",
      //   organizationID
      // );

      ServiceUtils.getV2(url, {
        headers: {
          Accept: "text/plain",
        },
      })
        .then((res: any) => {
          const positions = (res.data || {}).Data.diagrams || [];

          resolve(positions);
        })
        .catch((err: any) => {
          resolve([]);
        });
    });
  };

  const _getListPosition = () => {
    try {
      ServiceUtils.getV2(GET_LIST_POSITION_DROPDOWN, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if ((res.data || {}).StatusCode === 200) {
          setDataPosition(res.data.Data.positions);
        } else {
          toast.error("Lấy dữ liệu chức vụ thất bại!");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  // const handleProcedureStepChange = (item: any, field: string) => (e: any) => {
  //   const value = e.target.value;

  //   const _proceduceSteps = [...proceduceSteps];

  //   const proceduceStep = _proceduceSteps.find((p) => p.id === item.id);

  //   if (proceduceStep) {
  //     proceduceStep[field] = value;

  //     if (field.includes("Status")) {
  //       proceduceStep[field] = parseInt(value, 10);
  //     }

  //     setProceduceStep(_proceduceSteps);
  //   }
  //   console.log("123123123, ", proceduceSteps);
  // };

  // // Usage example
  // const _onChangeLabelFunction1 = (item: any) =>
  //   handleProcedureStepChange(item, "labelFunction1");
  // const _onChangeLabelFunction2 = (item: any) =>
  //   handleProcedureStepChange(item, "labelFunction2");
  // const _onChangeLabelFunction3 = (item: any) =>
  //   handleProcedureStepChange(item, "labelFunction3");

  // const _onChangeResult1 = (item: any) =>
  //   handleProcedureStepChange(item, "result1");
  // const _onChangeResult2 = (item: any) =>
  //   handleProcedureStepChange(item, "result2");
  // const _onChangeResult3 = (item: any) =>
  //   handleProcedureStepChange(item, "result3");

  // const _onChangeProcedureStepStatus1 = (item: any) =>
  //   handleProcedureStepChange(item, "status1");
  // const _onChangeProcedureStepStatus2 = (item: any) =>
  //   handleProcedureStepChange(item, "status2");
  // const _onChangeProcedureStepStatus3 = (item: any) =>
  //   handleProcedureStepChange(item, "status3");

  const _onChangeLabelFunction1 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.labelFunction1 = value;

      setProceduceStep(_proceduceSteps);
    }
  };
  const _onChangeLabelFunction2 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.labelFunction2 = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeLabelFunction3 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.labelFunction3 = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeResult1 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.result1 = value;

      setProceduceStep(_proceduceSteps);
    }
  };
  const _onChangeResult2 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.result2 = value;

      setProceduceStep(_proceduceSteps);
    }
  };
  const _onChangeResult3 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.result3 = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeProcedureStepStatus1 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      if (value == "") {
        proceduceStep.status1 = null;
      } else {
        proceduceStep.status1 = parseInt(value, 10);
      }

      setProceduceStep(_proceduceSteps);
    }
  };
  const _onChangeProcedureStepStatus2 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      if (value == "") {
        proceduceStep.status1 = null;
      } else {
        proceduceStep.status2 = parseInt(value, 10);
      }

      setProceduceStep(_proceduceSteps);
    }
  };
  const _onChangeProcedureStepStatus3 = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      if (value == "") {
        proceduceStep.status1 = null;
      } else {
        proceduceStep.status3 = parseInt(value, 10);
      }

      setProceduceStep(_proceduceSteps);
    }
  };

  const _renderProceduceStepType = (item: any) => {
    if (item.type == PROCEDUCE_STEP_TYPE_OBJECTS.transferStep) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              flex: 1,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              marginTop: "5px",
              borderRadius: "5px",
            }}
            className="core-create-or-update-setting-proceduce-step-item-type-group"
          >
            <div
              style={{
                padding: "5px",
                backgroundColor: "rgb(14 165 233 / var(--tw-bg-opacity))",
                borderRadius: "5px",
              }}
            >
              <label
                style={{
                  color: "#ffffff",
                }}
              >
                Hành động 1
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Tên hiển thị</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  onTouchStart={(e: any) => onTouchForm(e)}
                  style={{
                    width: "100px",
                  }}
                  value={item.labelFunction1}
                  onChange={_onChangeLabelFunction1(item)}
                />
              </div>
              {item.status1 == 3 && (
                <>
                  <div style={{ width: "5px" }}></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>Tới bước</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      onTouchStart={(e: any) => onTouchForm(e)}
                      type="number"
                      style={{
                        width: "80px",
                        textAlign: "center",
                      }}
                      value={item.result1}
                      onChange={_onChangeResult1(item)}
                    />
                  </div>
                </>
              )}

              <div style={{ width: "5px" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Trạng thái</label>
                <select
                  value={item.status1}
                  onChange={_onChangeProcedureStepStatus1(item)}
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                >
                  <option className="text-gray-300" value={""}>
                    --- Chọn trạng thái ---
                  </option>
                  {procedureStepStatues.map((item: any, index: any) => {
                    return (
                      <option
                        key={`status-${item.Id}-1-${index}`}
                        className="text-gray-300"
                        value={item.Status}
                      >
                        {item.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type == PROCEDUCE_STEP_TYPE_OBJECTS.confirmNotConfirm) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5px",
          }}
          className="core-create-or-update-setting-proceduce-step-item-type-group"
        >
          <div
            style={{
              flex: 1,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              marginTop: "5px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                padding: "5px",
                backgroundColor: "rgb(14 165 233 / var(--tw-bg-opacity))",
                borderRadius: "5px",
              }}
            >
              <label
                style={{
                  color: "#ffffff",
                }}
              >
                Hành động 1
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Tên hiển thị</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  onTouchStart={(e: any) => onTouchForm(e)}
                  style={{
                    width: "100px",
                  }}
                  value={item.labelFunction1}
                  onChange={_onChangeLabelFunction1(item)}
                />
              </div>
              {item.status1 == 3 && (
                <>
                  <div style={{ width: "5px" }}></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>Nhảy bước</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      onTouchStart={(e: any) => onTouchForm(e)}
                      type="number"
                      style={{
                        width: "80px",
                        textAlign: "center",
                      }}
                      value={item.result1}
                      onChange={_onChangeResult1(item)}
                    />
                  </div>
                </>
              )}

              <div style={{ width: "5px" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Trạng thái</label>
                <select
                  value={item.status1}
                  onChange={_onChangeProcedureStepStatus1(item)}
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                >
                  <option className="text-gray-300" value={""}>
                    --- Chọn trạng thái ---
                  </option>
                  {procedureStepStatues.map((item: any, index: any) => {
                    return (
                      <option
                        key={`status-${item.Id}-1-${index}`}
                        className="text-gray-300"
                        value={item.Status}
                      >
                        {item.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div style={{ width: "5px" }}></div>
          <div
            style={{
              flex: 1,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              marginTop: "5px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                padding: "5px",
                backgroundColor: "rgb(248 113 113 / var(--tw-bg-opacity))",
                borderRadius: "5px",
              }}
            >
              <label
                style={{
                  color: "#ffffff",
                }}
              >
                Hành động 2
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Tên hiển thị</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  onTouchStart={(e: any) => onTouchForm(e)}
                  style={{
                    width: "100px",
                  }}
                  value={item.labelFunction2}
                  onChange={_onChangeLabelFunction2(item)}
                />
              </div>
              {item.status2 == 3 && (
                <>
                  <div style={{ width: "5px" }}></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>Nhảy bước</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      onTouchStart={(e: any) => onTouchForm(e)}
                      type="number"
                      style={{
                        width: "80px",
                        textAlign: "center",
                      }}
                      value={item.result2}
                      onChange={_onChangeResult2(item)}
                    />
                  </div>
                </>
              )}

              <div style={{ width: "5px" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Trạng thái</label>
                <select
                  value={item.status2}
                  onChange={_onChangeProcedureStepStatus2(item)}
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                >
                  <option className="text-gray-300" value={""}>
                    --- Chọn trạng thái ---
                  </option>
                  {procedureStepStatues.map((item: any, index: any) => {
                    return (
                      <option
                        key={`status-${item.Id}-1-${index}`}
                        className="text-gray-300"
                        value={item.Status}
                      >
                        {item.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      item.type == PROCEDUCE_STEP_TYPE_OBJECTS.confirmSuggestNotConfirm
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
            className="core-create-or-update-setting-proceduce-step-item-type-group"
          >
            <div
              style={{
                flex: 1,
                border: "1px solid rgba(0, 0, 0, 0.1)",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  padding: "5px",
                  backgroundColor: "rgb(14 165 233 / var(--tw-bg-opacity))",
                  borderRadius: "5px",
                }}
              >
                <label
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Hành động 1
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Tên hiển thị</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    onTouchStart={(e: any) => onTouchForm(e)}
                    style={{
                      width: "100px",
                    }}
                    value={item.labelFunction1}
                    onChange={_onChangeLabelFunction1(item)}
                  />
                </div>
                {item.status1 == 3 && (
                  <>
                    <div style={{ width: "5px" }}></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Nhảy bước</label>
                      <input
                        className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        onTouchStart={(e: any) => onTouchForm(e)}
                        type="number"
                        style={{
                          width: "80px",
                          textAlign: "center",
                        }}
                        value={item.result1}
                        onChange={_onChangeResult1(item)}
                      />
                    </div>
                  </>
                )}

                <div style={{ width: "5px" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Trạng thái</label>
                  <select
                    value={item.status1}
                    onChange={_onChangeProcedureStepStatus1(item)}
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  >
                    <option className="text-gray-300" value={""}>
                      --- Chọn trạng thái ---
                    </option>
                    {procedureStepStatues.map((item: any, index: any) => {
                      return (
                        <option
                          key={`status-${item.Id}-1-${index}`}
                          className="text-gray-300"
                          value={item.Status}
                        >
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div style={{ width: "5px" }}></div>
            <div
              style={{
                flex: 1,
                border: "1px solid rgba(0, 0, 0, 0.1)",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  padding: "5px",
                  backgroundColor: "rgb(248 113 113 / var(--tw-bg-opacity))",
                  borderRadius: "5px",
                }}
              >
                <label
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Hành động 2
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Tên hiển thị</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    onTouchStart={(e: any) => onTouchForm(e)}
                    style={{
                      width: "100px",
                    }}
                    value={item.labelFunction2}
                    onChange={_onChangeLabelFunction2(item)}
                  />
                </div>
                {item.status2 == 3 && (
                  <>
                    <div style={{ width: "5px" }}></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Nhảy bước</label>
                      <input
                        className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        onTouchStart={(e: any) => onTouchForm(e)}
                        type="number"
                        style={{
                          width: "80px",
                          textAlign: "center",
                        }}
                        value={item.result2}
                        onChange={_onChangeResult2(item)}
                      />
                    </div>
                  </>
                )}

                <div style={{ width: "5px" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Trạng thái</label>
                  <select
                    value={item.status2}
                    onChange={_onChangeProcedureStepStatus2(item)}
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  >
                    <option className="text-gray-300" value={""}>
                      --- Chọn trạng thái ---
                    </option>
                    {procedureStepStatues.map((item: any, index: any) => {
                      return (
                        <option
                          key={`status-${item.Id}-1-${index}`}
                          className="text-gray-300"
                          value={item.Status}
                        >
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
            className="core-create-or-update-setting-proceduce-step-item-type-group"
          >
            <div
              style={{
                flex: 1,
                border: "1px solid rgba(0, 0, 0, 0.1)",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  padding: "5px",
                  backgroundColor: "rgb(14 165 233 / var(--tw-bg-opacity))",
                  borderRadius: "5px",
                }}
              >
                <label
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Hành động 3
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Tên hiển thị</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    onTouchStart={(e: any) => onTouchForm(e)}
                    style={{
                      width: "100px",
                    }}
                    value={item.labelFunction3}
                    onChange={_onChangeLabelFunction3(item)}
                  />
                </div>
                {item.status3 == 3 && (
                  <>
                    <div style={{ width: "5px" }}></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Nhảy bước</label>
                      <input
                        className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        onTouchStart={(e: any) => onTouchForm(e)}
                        type="number"
                        style={{
                          width: "80px",
                          textAlign: "center",
                        }}
                        value={item.result3}
                        onChange={_onChangeResult3(item)}
                      />
                    </div>
                  </>
                )}

                <div style={{ width: "5px" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Trạng thái</label>
                  <select
                    value={item.status3}
                    onChange={_onChangeProcedureStepStatus3(item)}
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  >
                    <option className="text-gray-300" value={""}>
                      --- Chọn trạng thái ---
                    </option>
                    {procedureStepStatues.map((item: any, index: any) => {
                      return (
                        <option
                          key={`status-${item.Id}-1-${index}`}
                          className="text-gray-300"
                          value={item.Status}
                        >
                          {item.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
              }}
            ></div>
          </div>
        </div>
      );
    }

    return null;
  };

  const _onChangeSenderType = (item: any, senderType: any) => (e: any) => {
    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.senderType = senderType;

      setProceduceStep(_proceduceSteps);
    }
  };
  //2
  const _renderProceduceStep = () => {
    return proceduceSteps.map((item: any, index: any) => {
      return (
        <div
          key={`proceduce-step-${index}`}
          style={{
            borderRadius: "5px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              borderRadius: "5px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                marginRight: "5px",
                width: "64px",
                height: "64px",
                backgroundColor: "rgb(34 197 94 / var(--tw-bg-opacity))",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                }}
              >
                {item.stepNumber}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "5px",
                flex: 1,
              }}
              className="core-create-or-update-setting-proceduce-step-item-header-content"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Tên bước (*)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={item.name}
                  onChange={_onChangeStepName(item)}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                className="core-create-or-update-setting-proceduce-step-item-header-content-function"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Số bước (*)</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={item.stepNumber}
                    onTouchStart={(e: any) => onTouchForm(e)}
                    type="number"
                    onChange={_onChangeStepNumber(item)}
                    style={{
                      width: "80px",
                      textAlign: "center",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={_onToggleExpandProceduceStep(item)}
                    >
                      {item.isExpand ? (
                        <ArrowDropDownIcon fontSize="large" />
                      ) : (
                        <ArrowDropUpIcon fontSize="large" />
                      )}
                    </button>
                  </div>
                  <div
                    style={{
                      marginLeft: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: "#FF0000",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={_onDeleteProceduceStep(item)}
                    >
                      <DeleteIcon
                        fontSize="large"
                        style={{
                          color: "#ffffff",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "5px",
              display: item.isExpand ? "block" : "none",
            }}
          >
            <div
              style={{
                marginTop: "5px",
              }}
            >
              <label>Mô tả bước</label>
              <textarea
                value={item.description}
                onChange={(e: any) => {
                  _onChangeDescriptionStep(item)(e);
                }}
                className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
            {/* <label>Người thực hiện bước đề xuất</label>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '5px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <input style={{
                                marginRight: '5px'
                            }} type="radio" checked={item.senderType == PROCEDUCE_STEP_SENDER_TYPES.suggester ? true : false} onChange={_onChangeSenderType(item, PROCEDUCE_STEP_SENDER_TYPES.suggester)} />
                            <label>Người gửi đề xuất</label>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0px 10px'
                        }}>
                            <input style={{
                                marginRight: '5px'
                            }} type="radio" checked={item.senderType == PROCEDUCE_STEP_SENDER_TYPES.option ? true : false} onChange={_onChangeSenderType(item, PROCEDUCE_STEP_SENDER_TYPES.option)} />
                            <label>Tùy chọn</label>
                        </div>
                    </div> */}
            {item.senderType == PROCEDUCE_STEP_SENDER_TYPES.option && (
              <>
                <div
                  style={{
                    marginTop: "5px",
                  }}
                >
                  <label>
                    Phòng ban có thẩm quyền thực hiện bước của đề xuất
                  </label>
                  <select
                    value={item.organizationID}
                    onChange={_onChangeOrganization(item)}
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  >
                    <option className="text-gray-300" value={""}>
                      Phòng ban tương ứng với người tạo đề xuất
                    </option>
                    {organizations.map((item: any, index: any) => {
                      return (
                        <option
                          key={`organization-${item.id}-1-${index}`}
                          className="text-gray-300"
                          value={item.ParameterId}
                        >
                          <LevelComponent item={item} />
                          {item.ParameterValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  style={{
                    marginTop: "5px",
                  }}
                >
                  <label>
                    Chức vụ có thẩm quyền thực hiện bước của đề xuất
                  </label>
                  <select
                    value={item.positionID}
                    onChange={_onChangePosition(item)}
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  >
                    {(item.positions || []).map(
                      (positionItem: any, index: any) => {
                        let parameter: any;

                        if (
                          item.organizationID == "" ||
                          item.organizationID == null
                        ) {
                          parameter = positionItem;
                        } else {
                          parameter = dataPosition.find(
                            (param: any) =>
                              param.ParameterId == positionItem.ChucVu
                          );
                        }

                        return (
                          <option
                            key={`position-${positionItem.id}-${index}`}
                            className="text-gray-300"
                            value={positionItem.ChucVu}
                          >
                            {parameter && parameter.ParameterValue}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </>
            )}
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                type="checkbox"
                checked={item.isSendEmailPersonReceived}
                onChange={_onChangeIsSendMailPersonReceived(item)}
              />
              <label>Gửi gmail cho người đề xuất</label>
            </div>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                type="checkbox"
                checked={item.isSendAlertPersonReceived}
                onChange={_onChangeIsSendAlertPersonReceived(item)}
              />
              <label>Gửi thông báo cho người đề xuất</label>
            </div>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                type="checkbox"
                checked={item.isSendEmailPersonFeedback}
                onChange={_onChangeIsSendMailPersonFeedback(item)}
              />
              <label>
                Gửi gmail cho người có thẩm quyền thực hiện bước của đề xuất
              </label>
            </div>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                type="checkbox"
                checked={item.isSendAlertPersonFeedback}
                onChange={_onChangeIsSendAlertPersonFeedback(item)}
              />
              <label>
                Gửi thông báo người có thẩm quyền thực hiện bước của đề xuất
              </label>
            </div>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                checked={item.isRequireContent}
                onChange={_onChangeIsRequireContent(item)}
                style={{
                  marginRight: "5px",
                }}
                type="checkbox"
              />
              <label>
                Bắt buộc phải có nội dung gửi lên{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    color: "rgb(248 113 113 / var(--tw-bg-opacity))",
                  }}
                >
                  (Nhấn chọn để bắt buộc khi người thực hiện bước đó phải tải
                  nội dụng bằng chứng, hóa đơn,... liên quan lên mới cho thực
                  hiện)
                </span>
              </label>
            </div>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label>
                Loại nội dung gửi lên{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    color: "rgb(248 113 113 / var(--tw-bg-opacity))",
                  }}
                >
                  (Loại nội dung mà người thực hiện bước đó tải lên, ví dụ như
                  tệp tin, nhập dữ liệu chữ, số,...)
                </span>
              </label>
              <select
                value={item.contentType}
                onChange={_onChangeContentType(item)}
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              >
                <option className="text-gray-300" value={-1} disabled>
                  --- Chọn loại nội dung ---
                </option>
                {PROCEDUCE_STEP_CONTENT_TYPES.map(
                  (proceduceStepContentTypeItem: any, index: any) => {
                    return (
                      <option
                        key={`proceduce-step-content-type-${index}`}
                        className="text-gray-300"
                        value={proceduceStepContentTypeItem.value}
                      >
                        {proceduceStepContentTypeItem.label}
                      </option>
                    );
                  }
                )}
              </select>
            </div>

            {/* <div
              style={{
                marginTop: "5px",
              }}
            >
              <label>Phòng ban được góp ý</label>
              <select
                value={item.organizationSuggestId}
                onChange={_onChangeOrganizationSuggest(item)}
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              >
                <option className="text-gray-300" value={""}>
                  Phòng ban tương ứng với người tạo đề xuất
                </option>
                {organizations.map((item: any, index: any) => {
                  return (
                    <option
                      key={`organization-${item.id}-1-${index}`}
                      className="text-gray-300"
                      value={item.ParameterId}
                    >
                      <LevelComponent item={item} />
                      {item.ParameterValue}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              style={{
                marginTop: "5px",
              }}
            >
              <label>Chức vụ được góp ý</label>
              <select
                value={item.positionSuggestID}
                onChange={_onChangePositionSuggest(item)}
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              >
                {(item.positionSuggests || []).map(
                  (positionItem: any, index: any) => {
                    let parameter: any;

                    if (
                      item.positionSuggestID == "" ||
                      item.positionSuggestID == null
                    ) {
                      parameter = positionItem;
                      console.log("parameter[", index, "]: ", parameter);
                    } else {
                      parameter = dataPosition.find(
                        (param: any) =>
                          param.ParameterId === positionItem.ChucVu
                      );
                    }

                    return (
                      <option
                        key={`position-${item.id}-2-${index}`}
                        className="text-gray-300"
                        value={positionItem.ChucVu}
                      >
                        {parameter && parameter.ParameterValue}
                      </option>
                    );
                  }
                )}
              </select>

            </div> */}
            <div
              style={{
                marginTop: "5px",
              }}
            >
              <label>
                Hành động của bước (*){" "}
                <span
                  style={{
                    fontStyle: "italic",
                    color: "rgb(248 113 113 / var(--tw-bg-opacity))",
                  }}
                >
                  (Hành động mà người thực hiện bước đó có thể thao tác, ví dụ
                  như Duyệt, không duyệt, góp ý,...)
                </span>
              </label>
              <select
                value={item.type}
                onChange={_onChangeType(item)}
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              >
                <option className="text-gray-300" value={-1} disabled>
                  --- Chọn hành động ---
                </option>
                {PROCEDUCE_STEP_TYPES.map(
                  (proceduceStepTypeItem: any, index: any) => {
                    return (
                      <option
                        key={`proceduce-step-type-${index}`}
                        className="text-gray-300"
                        value={proceduceStepTypeItem.value}
                      >
                        {proceduceStepTypeItem.label}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            {_renderProceduceStepType(item)}
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                onClick={_onCollapseProceduceStep(item)}
                type="button"
                style={{
                  width: "48px",
                  height: "48px",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArrowDropUpIcon fontSize="large" />
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  const _onClickSubmit = async () => {
    const _proceduceSteps = [...proceduceSteps];

    for (let i = 0; i < _proceduceSteps.length; i++) {
      _proceduceSteps[i].contentType = parseInt(_proceduceSteps[i].contentType);
      _proceduceSteps[i].organizationID = parseInt(
        _proceduceSteps[i].organizationID
      );
      _proceduceSteps[i].positionID = parseInt(_proceduceSteps[i].positionID);
      _proceduceSteps[i].result1 = parseInt(_proceduceSteps[i].result1);
      _proceduceSteps[i].result2 = parseInt(_proceduceSteps[i].result2);
      _proceduceSteps[i].result3 = parseInt(_proceduceSteps[i].result3);
      _proceduceSteps[i].type = parseInt(_proceduceSteps[i].type);

      delete _proceduceSteps[i].isExpand;
      delete _proceduceSteps[i].positions;
      delete _proceduceSteps[i].positionSuggests;
    }

    const body: any = {
      Name: name,
      Description: description,
      ID: id,
      Steps: _proceduceSteps,
    };

    // console.log("object123123, ", body);

    const url = id ? UPDATE_PROCEDURE_API : CREATE_PROCEDURE_API;
    const messageSuccessfully = id ? "Sửa thành công." : "Tạo mới thành công.";
    const messageFailure = id ? "Tạo mới thất bại." : "Sửa thất bại.";
    try {
      await ServiceUtils[id ? "putV2" : "postV2"](url, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if ((res.data || {}).StatusCode === 200) {
          toast.success(messageSuccessfully);
          readList(
            SETTING_PROCEDUCE_LIST,
            workingReducer.pageCurrent,
            workingReducer.choosePage,
            workingReducer.searchFilter.taskName,
            workingReducer.searchFilter.selectedPrior,
            workingReducer.searchFilter.selectedReceiver,
            workingReducer.searchFilter.fromDate,
            workingReducer.searchFilter.toDate,
            workingReducer.searchFilter.deadLine,
            { current: true },
            window.location.pathname,
            (res: any) => {
              dispatch(ONLOAD_SETTINGPROCEDUCE_ALL(res.data.Data));
            },
            null,
            null,
            null,
            workingReducer.searchFilter.selectPhongBan
          );
          _close();
        } else {
          const message = (res.data || {}).Message;

          toast.error(message || messageFailure);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();

    setDescription(e.target.value);
  };

  const _onChangeDescriptionStep = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.description = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeStepName = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.name = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeStepNumber = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.stepNumber = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeStepDescription = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.description = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeIsSendAlertPersonReceived = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isSendAlertPersonReceived =
        !proceduceStep.isSendAlertPersonReceived;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeIsSendMailPersonReceived = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isSendEmailPersonReceived =
        !proceduceStep.isSendEmailPersonReceived;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeIsSendMailPersonFeedback = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isSendEmailPersonFeedback =
        !proceduceStep.isSendEmailPersonFeedback;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeIsSendAlertPersonFeedback = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isSendAlertPersonFeedback =
        !proceduceStep.isSendAlertPersonFeedback;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeIsRequireContent = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.isRequireContent = !proceduceStep.isRequireContent;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeContentType = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      proceduceStep.contentType = value;

      setProceduceStep(_proceduceSteps);
    }
  };

  const _onChangeType = (item: any) => (e: any) => {
    const value = e.target.value;

    const _proceduceSteps = [...proceduceSteps];

    const proceduceStep = _proceduceSteps.find((p) => p.id == item.id);

    if (proceduceStep) {
      if (value == 0) {
        proceduceStep.status1 = null;
        proceduceStep.status2 = null;
        proceduceStep.status3 = null;

        proceduceStep.result1 = null;
        proceduceStep.result2 = null;
        proceduceStep.result3 = null;

        proceduceStep.labelFunction1 = null;
        proceduceStep.labelFunction2 = null;
        proceduceStep.labelFunction3 = null;
      } else if (value == 1) {
        proceduceStep.status2 = null;
        proceduceStep.status3 = null;

        proceduceStep.result2 = null;
        proceduceStep.result3 = null;

        proceduceStep.labelFunction2 = null;
        proceduceStep.labelFunction3 = null;
      } else if (value == 2) {
        proceduceStep.status3 = null;

        proceduceStep.result3 = null;

        proceduceStep.labelFunction3 = null;
      }
      proceduceStep.type = value;

      setProceduceStep(_proceduceSteps);
    }
    console.log("proceduceStep", proceduceSteps);
  };

  useEffect(() => {
    _getDetail();

    _getListOrganization();

    getListProcedureStepStatus();

    _getListPosition();
  }, []);

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[52rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
        rounded-md
      "
    >
      <div
        className="
                flex w-full bg-sky-500 rounded-t-md
                p-12-16 items-center font-semibold text-white
                text-lg justify-between

      "
      >
        <p>{id ? "Sửa" : "Tạo"} quy trình</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={_close}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên quy trình&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={name}
              onChange={(e: any) => {
                _onChangeName(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Mô tả quy trình&nbsp;<span className="text-red-500"></span>
            </label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              value={description}
              onChange={(e: any) => {
                _onChangeDescription(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
        <div className="flex w-full h-max">
          <div
            className="flex flex-row w-full pr-0 h-max"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label>
              Các bước của quy trình&nbsp;
              <span className="text-red-500">(*)</span>
            </label>
          </div>
        </div>
        <div>{_renderProceduceStep()}</div>
        <div className="flex w-full h-max justify-end">
          <button
            className={`
                            p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              flex
            `}
            onClick={_onClickAddStep}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm bước</p>
          </button>
        </div>
      </div>
      <div
        className="flex w-full h-max space-x-6"
        style={{
          bottom: "46px",
        }}
      >
        <div
          className={`${
            _checkValid() === true ? "flex" : "hidden"
          } absolute left-4 bottom-4`}
        >
          <p className="text-red-500">Vui lòng điền đầy đủ thông tin</p>
        </div>
      </div>

      <div className="flex w-full p-12-16 footer bg-slate-100 rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
              flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${
                _checkValid() === true
                  ? "bg-slate-200"
                  : "bg-primary hover:bg-sky-400"
              }
            `}
            onClick={_onClickSubmit}
            disabled={_checkValid() === true ? true : false}
          >
            <SaveIcon fontSize="small" />
            <p>{id ? "Chỉnh sửa" : "Tạo mới"}</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200 core-create-or-update-setting-proceduce-button-close
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateOrUpdateSettingProceduce;
