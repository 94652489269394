import React from "react";

// ui importing
import HomeUI from "../../../../ui/homeUI/_index";

const TaskWaitingPage = () => {
  return (
    <>
      <HomeUI />
    </>
  )
}

export default TaskWaitingPage;