import React, { useEffect, useRef, useState } from "react";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
const EditFileSubAlready = (props: any) => {
  // take props
  const handleClose = props.handleClose;
  const bodyAdding = props.bodyAdding;
  const saveEditFile = props.bodyAdding.saveEditFile;

  console.log(bodyAdding);

  const shouldLog = useRef(true);

  // console.log(bodyAdding)
  console.log(bodyAdding);

  const [fileName, setFileName] = useState<string>("");
  const [typeOfFile, setTypeOfFile] = useState<string>("");
  console.log("fileName, ", fileName);
  // onmount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      const lastDotIndex =
        bodyAdding.filesList2[bodyAdding.index].listFileName.lastIndexOf(".");
      if (
        bodyAdding.filesList2[bodyAdding.index].listFileName.includes(
          ".png"
        ) === true
      ) {
        // setFileName(
        //   bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, -4)
        // );
        setFileName(
          bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, lastDotIndex)
        );
        setTypeOfFile(".png");
      } else if (
        bodyAdding.filesList2[bodyAdding.index].listFileName.includes(
          ".jpg"
        ) === true
      ) {
        // setFileName(
        //   bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, -5)
        // );
        setFileName(
          bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, lastDotIndex)
        );
        setTypeOfFile(".jpg");
      } else if (
        bodyAdding.filesList2[bodyAdding.index].listFileName.includes(
          ".jpeg"
        ) === true
      ) {
        // setFileName(
        //   bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, -4)
        // );
        setFileName(
          bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, lastDotIndex)
        );
        setTypeOfFile(".jpeg");
      } else {
        // const splitFileName = (
        //   bodyAdding.filesList2[bodyAdding.index].listFileName || ""
        // ).split(".");

        // const fileName = splitFileName[0] || "";

        // const fileExtension = splitFileName[splitFileName.length - 1] || "";

        // setFileName(fileName);
        // setTypeOfFile(`.${fileExtension}`);
        setFileName(
          bodyAdding.filesList2[bodyAdding.index].listFileName.slice(0, lastDotIndex)
        );
        setTypeOfFile(
          bodyAdding.filesList2[bodyAdding.index].listFileName.slice(lastDotIndex)
        );
      }
    }
  }, []);

  const _onChangeFileName = (e: any) => {
    e.preventDefault();
    setFileName(e.target.value);
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* Header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chỉnh sửa tên file</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        <p>Tên file</p>
        <input
          className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
          value={fileName}
          onChange={(e: any) => _onChangeFileName(e)}
        />
      </div>

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
            onClick={() => {
              saveEditFile(bodyAdding.index, fileName + typeOfFile);
              toast.success("Thay đổi tên file thành công.");
              handleClose();
            }}
          >
            <SaveIcon fontSize="small" />
            <p>Lưu</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFileSubAlready;
