import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const appReducer = createSlice({
  name: "appReducer",

  initialState: {
    // navbar
    navBarButton: "",
    navBarButtonResponsive: false,
    totalNoti: 0,
    navBarMain: [
      {
        id: "1",
        name: "workingTableOptions",
        active: "false",
      },
      {
        id: "2",
        name: "workingOptions",
        active: "false",
      },
      {
        id: "3",
        name: "workingTableOptions",
        active: "false",
      },
      {
        id: "4",
        name: "workingTableOptions",
        active: "false",
      },
      {
        id: "5",
        name: "workingTableOptions",
        active: "false",
      },
    ],

    mainMenu: [],
    subMenu: [],
    showingMenu: sessionStorage.getItem("whatMainOptionsShowing"),
    userData: null,

    userList: null,

    loadNoti: null,
    
    loadNoti2: null,

    priorListDropdown: null
  },

  reducers: {
    ONCHANGE_PRIORLIST_DROPDOWN: (state: any, actions: PayloadAction<any>) => {
      state.priorListDropdown = actions.payload
    },

    ONCHANGE_ALL_NOTI2: (state: any, actions: PayloadAction<any>) => {
      state.loadNoti2 = actions.payload
    },

    ONCHANGE_ALL_NOTI: (state: any, actions: PayloadAction<any>) => {
      state.loadNoti = actions.payload
    },

    ONLOGIN: (state: any) => {
      state.navBarButton = "false";
      sessionStorage.setItem("isShowButtonNavbar", state.navBarButton);
      // window.history.pushState({}, "", "/workingtable/total")
      // window.location.reload()
    },

    ONLOAD_RESET_NOTI: (state: any) => {
      localStorage.clear()
      sessionStorage.clear()

      window.history.pushState({}, "", "/login")
      window.location.reload()
    },

    ONLOAD_SET_USER_LIST: (state: any, actions: PayloadAction<any>) => {
      state.userList = actions.payload;
    },

    ONLOAD_USERDATA: (state: any, actions: PayloadAction<any>) => {
      state.userData = actions.payload;
    },

    ONCHANGE_NAVBAR_RESPONSIVE: (state: any) => {
      state.navBarButtonResponsive = true;
    },

    ONCHANGE_NOTI: (state: any, actions: PayloadAction<any>) => {
      state.totalNoti = actions.payload;
    },

    ONCHANGE_NOTI_PLUS1: (state: any) => {
      state.totalNoti += 1;
    },

    ONCHANGE_NOTI_MINUS1: (state: any) => {
      state.totalNoti -= 1;
    },

    ONCHANGE_NAVBAR_CLOSE_RESPONSIVE: (state: any) => {
      state.navBarButtonResponsive = false;
    },

    ONSET_NAVBAR_OPTIONS_AT_FIRST: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.navBarMain.forEach((item: any) => {
        if (item.id === actions.payload) {
          item.active = "true";
        } else {
          item.active = "false";
        }
      });
    },

    ONSET_NAVBAR_BUTTON_AT_FIRST: (state: any, actions: PayloadAction<any>) => {
      state.navBarButton = actions.payload;

      sessionStorage.setItem("isShowButtonNavbar", state.navBarButton);
    },

    ONCHANGE_NAVBAR_BUTTON: (state: any) => {
      if (state.navBarButton === "true") {
        state.navBarButton = "false";
      } else {
        state.navBarButton = "true";
      }
      sessionStorage.setItem("isShowButtonNavbar", state.navBarButton);
    },

    ONCHANGE_NAVBAR_OPTIONS: (state: any, actions: PayloadAction<any>) => {
      sessionStorage.setItem("isShowButtonNavbar", "true");
      state.mainMenu.forEach((item: any) => {
        if (
          item.FormId.toString() === actions.payload ||
          actions.payload === "0"
        ) {
          sessionStorage.setItem(
            "whatMainOptionsShowing",
            actions.payload === "0" ? 0 : item.FormId
          );
          state.showingMenu = actions.payload;
        } else {
          return;
        }
      });
    },

    // new thing
    ONCHANGE_MENULIST: (state: any) => {
      const tempMenuList = localStorage.getItem("menuList");
      state.subMenu = [];
      state.mainMenu = [];
      if (tempMenuList === null) {
        return;
      } else {
        const menuList = JSON.parse(tempMenuList);

        menuList.forEach((item: any) => {
          if (item.PerActive === true && item.ParentFormId === 0) {
            state.mainMenu.push(item);
          } else if (item.PerActive === true && item.ParentFormId !== 0) {
            state.subMenu.push(item);
          } else {
            return;
          }
        });
      }

      // state.mainMenu.forEach((item: any, index: any) => {
      //   console.log(item);
      // })
    },
  },
});

export const {
  ONCHANGE_PRIORLIST_DROPDOWN,
  ONCHANGE_ALL_NOTI2,
  ONCHANGE_ALL_NOTI,
  ONLOGIN,
  ONLOAD_RESET_NOTI,
  ONCHANGE_NOTI_MINUS1,
  ONCHANGE_NOTI_PLUS1,
  ONCHANGE_NOTI,
  ONLOAD_SET_USER_LIST,
  ONLOAD_USERDATA,
  ONSET_NAVBAR_BUTTON_AT_FIRST,
  ONSET_NAVBAR_OPTIONS_AT_FIRST,
  ONCHANGE_NAVBAR_BUTTON,
  ONCHANGE_NAVBAR_OPTIONS,
  ONCHANGE_NAVBAR_RESPONSIVE,
  ONCHANGE_NAVBAR_CLOSE_RESPONSIVE,
  ONCHANGE_MENULIST,
} = appReducer.actions;

export default appReducer.reducer;
