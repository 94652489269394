import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import HandleDate from "../../../../utils/date/handleDate";
import { ModalController } from "../../../homeUI/_index";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  ONCHANGE_FROMDATE,
  ONCHANGE_TODATE,
  ONCHANGE_VIEW,
} from "../../../../redux/reducers/ScheduleReducer/_index";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const CustomToolbar = ({ fetchScheduleData, ...props }: any) => {
  const { eventList, fromDate, toDate, optionList, selectedUser, view } =
    useSelector((state: any) => state.scheduleReducer);
  const dropdownRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  function addMonths(date: any, months: any) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }

    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth());
    const first = startOfWeek(new Date(startOfMonth(newDate)), {
      weekStartsOn: 0,
    });
    // first.setDate(first.getDate() + 1);
    const end = endOfWeek(new Date(endOfMonth(newDate)), { weekStartsOn: 0 });
    end.setDate(end.getDate() + 1);
    // console.log("first2, ", new Date(HandleDate.convertDateToISOString(first)));
    // console.log("end2, ", new Date(HandleDate.convertDateToISOString(end)));

    // let from = new Date(
    //   first.getFullYear(),
    //   first.getMonth(),
    //   first.getDate(),
    //   7,
    //   0,
    //   0
    // )
    //   .toISOString()
    //   .slice(0, 16);
    // let to = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 7, 0, 0)
    //   .toISOString()
    //   .slice(0, 16);
    // console.log("first, ", first);
    // console.log("end, ", end);
    // console.log("from, ", from);
    // console.log("to, ", to);

    dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));

    return date;
  }

  function addWeeks(date: any, weeks: any) {
    date.setDate(date.getDate() + 7 * weeks);

    const firstDayOfWeek = startOfWeek(new Date(date), { weekStartsOn: 0 });

    const lastDayOfWeek = endOfWeek(new Date(date), { weekStartsOn: 0 });
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 1);

    dispatch(
      ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(firstDayOfWeek))
    );
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(lastDayOfWeek)));
    return date;
  }

  function addDays(date: any, days: any) {
    date.setDate(date.getDate() + days);
    const first = new Date(date);

    const end = new Date(date);
    end.setDate(end.getDate() + 1);

    dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));

    return date;
  }

  const goToDayView = () => {
    const first = new Date(props.date);

    const end = new Date(props.date);
    end.setDate(end.getDate() + 1);

    dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));
    dispatch(ONCHANGE_VIEW("day"));
    props.onView("day");
  };

  const goToWeekView = () => {
    const first = startOfWeek(new Date(props.date), {
      weekStartsOn: 0,
    });

    const end = endOfWeek(new Date(props.date), { weekStartsOn: 0 });
    end.setDate(end.getDate() + 1);

    dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));
    dispatch(ONCHANGE_VIEW("week"));

    props.onView("week");
  };

  const goToMonthView = () => {
    const newDate = new Date(props.date);
    newDate.setMonth(newDate.getMonth());
    const first = startOfWeek(new Date(startOfMonth(newDate)), {
      weekStartsOn: 0,
    });

    const end = endOfWeek(new Date(endOfMonth(newDate)), { weekStartsOn: 0 });
    end.setDate(end.getDate() + 1);

    dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
    dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));
    dispatch(ONCHANGE_VIEW("month"));
    props.onView("month");
  };

  const goToAgendaView = () => {
    props.onView("agenda");
  };

  const goToBack = () => {
    if (view === "day") {
      props.onNavigate("prev", addDays(props.date, -1));
    } else if (view === "week") {
      props.onNavigate("prev", addWeeks(props.date, -1));
    } else {
      props.onNavigate("prev", addMonths(props.date, -1));
    }
  };

  const goToNext = () => {
    if (view === "day") {
      props.onNavigate("next", addDays(props.date, +1));
    } else if (view === "week") {
      props.onNavigate("next", addWeeks(props.date, +1));
    } else {
      props.onNavigate("next", addMonths(props.date, +1));
    }
  };
  const _onChangeDate = (e: any) => {
    if (view === "day") {
    } else if (view === "week") {
    } else {
    }
  };

  const goToToday = () => {
    const now = new Date();
    props.date.setMonth(now.getMonth());
    props.date.setYear(now.getFullYear());
    props.date.setDate(now.getDate());
    if (view === "day") {
      const first = new Date(props.date);
      const end = new Date(props.date);
      end.setDate(end.getDate() + 1);
      dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
      dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));
    } else if (view === "week") {
      const firstDayOfWeek = startOfWeek(new Date(props.date), {
        weekStartsOn: 0,
      });
      const lastDayOfWeek = endOfWeek(new Date(props.date), {
        weekStartsOn: 0,
      });
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 1);
      dispatch(
        ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(firstDayOfWeek))
      );
      dispatch(
        ONCHANGE_TODATE(HandleDate.convertDateToISOString(lastDayOfWeek))
      );
    } else {
      const newDate = new Date(props.date);
      newDate.setMonth(newDate.getMonth());
      const first = startOfWeek(new Date(startOfMonth(newDate)), {
        weekStartsOn: 0,
      });

      const end = endOfWeek(new Date(endOfMonth(newDate)), {
        weekStartsOn: 0,
      });
      end.setDate(end.getDate() + 1);
      dispatch(ONCHANGE_FROMDATE(HandleDate.convertDateToISOString(first)));
      dispatch(ONCHANGE_TODATE(HandleDate.convertDateToISOString(end)));
    }
    props.onNavigate("current");
  };

  const _handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", _handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);

  console.log("props.date, ", props.date);

  return (
    <div className="lg:flex gap-3 justify-between my-2 px-3 lg:px-0">
      <div className="flex justify-between">
        <div className="flex gap-3">
          <button
            onClick={goToToday}
            className="py-1 px-3 lg:px-5  font-semibold border border-gray-300 rounded-md bg-gray-50 w-max lg:mx-0 flex"
          >
            Hôm nay
          </button>
          <button
            onClick={goToBack}
            className="w-[36px] h-[36px] font-semibold border-gray-300 border rounded-md bg-gray-50 text-4xl flex justify-center items-center pb-2.5"
          >
            &#8249;
          </button>

          <button
            onClick={goToNext}
            className="w-[36px] h-[36px] font-semibold border-gray-300 border rounded-md bg-gray-50 text-4xl flex justify-center items-center pb-2.5"
          >
            &#8250;
          </button>
        </div>
        <div
          className="border border-gray-300 rounded-md bg-gray-50 lg:hidden relative "
          ref={dropdownRef}
        >
          <button
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}
            className=" p-1 font-semibold"
          >
            <MoreVertIcon />
          </button>
          {openDropdown && (
            <div className="absolute w-[100px] right-0 z-[100] text-center font-normal border border-gray-300 rounded-md bg-gray-50 flex flex-col">
              <button
                onClick={goToMonthView}
                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-primary hover:text-white border-b border-gray-300"
              >
                Tháng
              </button>
              <button
                onClick={goToWeekView}
                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-primary hover:text-white border-b border-gray-300"
              >
                Tuần
              </button>
              <button
                onClick={goToDayView}
                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-primary hover:text-white border-b border-gray-300"
              >
                Ngày
              </button>
              {/* <button
                onClick={goToAgendaView}
                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-primary hover:text-white"
              >
                Chi tiết
              </button> */}
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col">
        <input
          value={props.date.toISOString().split("T")[0]}
          type="date"
          className="font-semibold text-center my-auto py-1 px-3 lg:px-5 max-lg:mt-3 border border-gray-300 rounded-md bg-gray-50 w-max h-full mx-auto lg:mx-0 "
          onChange={_onChangeDate}
          disabled
        />
      </div>
      <div className=" border border-gray-300 rounded-md bg-gray-50 w-max mx-auto lg:mx-0 hidden lg:flex">
        <button
          onClick={goToMonthView}
          className=" py-1 px-5 font-semibold border-gray-300 border-r"
        >
          Tháng
        </button>
        <button
          onClick={goToWeekView}
          className="py-1 px-5  font-semibold border-gray-300 border-r"
        >
          Tuần
        </button>
        <button
          onClick={goToDayView}
          className="py-1 px-5  font-semibold border-gray-300 border-r"
        >
          Ngày
        </button>
        {/* <button
          onClick={goToAgendaView}
          className="py-1 px-5 font-semibold border-gray-300 border-r rounded-l-md"
        >
          Chi tiết
        </button> */}
      </div>
    </div>
  );
};

export default CustomToolbar;
