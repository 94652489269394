import React, { useState, useEffect, useRef, useContext } from "react";
import { ModalController } from "../../../homeUI/_index";

import { BAO_CAO_READ_SENDER, KE_HOACH_SENDER } from "../../../../apis/_index";

import { Pagination } from "@mui/material";

import { readReport } from "../../../../funcs/reportCalling";
import { takePhongban, takeUser } from "../../../../funcs/reportCalling";
import { REPORT_PERSONAL_SUMWORK } from "../../../../apis/_index";
import { randomColor } from "../../../../funcs/reportCalling";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import LoaderSpinner from "../../../../components/loader-spinner/_index";
import EditIcon from "@mui/icons-material/Edit";
import ServiceUtils from "../../../../utils/services";
import LoadingControl from "../../../../controls/loading/loadingControl";
import HandleDate from "../../../../utils/date/handleDate";

const PlanSenderUI = (props: any) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Lấy năm hiện tại
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
  const day = currentDate.getDate().toString().padStart(2, "0"); // Lấy ngày hiện tại

  const [dataCot, setDataCot] = useState<any[] | null>(null);
  const [dataTile, setDataTile] = useState<any[] | null>(null);
  const [dataList, setDataList] = useState<any | null>(null);

  const [arrayColor, setArrayColor] = useState<any[] | null>(null);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [selectedUserList, setSelectedUserList] = useState<any>(-1);

  const [arrayPercentTile, setArrayPercentTile] = useState<any[] | null>(null);
  const [arrayTotalThongKe, setArrayTotalThongKe] = useState<any[] | null>(
    null
  );

  // page Info
  const [selectedPage, setSelectedPage] = useState<any | null>(0);
  const [totalPage, setTotalPage] = useState<any | null>(null);
  const [pageShowing, setPageShowing] = useState<any | null>(10);

  const [fromDate, setFromDate] = useState<any | null>(
    HandleDate.firstDayOfYear
  );
  const [toDate, setToDate] = useState<any | null>(HandleDate.lastDayOfYear);

  const shouldLog = useRef(true);

  // onMount data
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      Page: 0,
      Limit: 10,
    };
    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(KE_HOACH_SENDER, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setDataList(res.data.Data);

            if (res.data.Data.count % pageShowing === 0) {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing));
            } else {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing) + 1);
            }
          }
        });
      } catch (_error) {
        toast.error("Tải danh sách báo cáo thất bại.");
      }
    };

    fetchData();
  }, []);

  // funcs
  const _onLoadPlan = () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      Page: selectedPage,
      Limit: pageShowing,
    };
    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(KE_HOACH_SENDER, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setDataList(res.data.Data);

            if (res.data.Data.count % pageShowing === 0) {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing));
            } else {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing) + 1);
            }
          }
        });
      } catch (_error) {
        toast.error("Tải danh sách báo cáo thất bại.");
      }
    };

    fetchData();
  };

  // onChange selectedPage
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      Page: selectedPage,
      Limit: pageShowing,
    };
    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(KE_HOACH_SENDER, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setDataList(res.data.Data);
          }
        });
      } catch (_error) {
        toast.error("Tải danh sách báo cáo thất bại.");
      }
    };

    fetchData();
  }, [selectedPage]);

  // onChange pageShowing
  useEffect(() => {
    setSelectedPage(0);
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      Page: selectedPage,
      Limit: pageShowing,
    };
    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(KE_HOACH_SENDER, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setDataList(res.data.Data);

            if (res.data.Data.count % pageShowing === 0) {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing));
            } else {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing) + 1);
            }
          }
        });
      } catch (_error) {
        toast.error("Tải danh sách báo cáo thất bại.");
      }
    };

    fetchData();
  }, [pageShowing]);

  // funcs
  const _onClickDetail = (id: string, periodic: string, taskName: string) => {
    context.setTaskId(id);
    context.setDataModal({ periodic: periodic, taskName: taskName });

    context.setFuncsLoad({
      onLoad: _onLoadPlan,
    });

    context.setFuncs("planElectric");
    context.handleOpen();
  };

  const _onClickEditPlan = (id: string, periodic: string, taskName: string) => {
    context.setTaskId(id);
    context.setDataModal({ periodic: periodic, taskName: taskName });
    context.setFuncs("editPlanning");
    context.handleOpen();
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setSelectedPage(value - 1);
  };

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate,
        DenNgay: toDate,
        _isCurUser: true,
      };

      readReport(REPORT_PERSONAL_SUMWORK, body, (res: any) => {
        setDataTile(res.data.Data.BieuDoTiLe);
        setDataCot(res.data.Data.BieuDoCot);
      });

      takePhongban((res: any) => setPhongBanList(res.data.Data));
      takeUser((res: any) => setUserList(res.data.Data));
    }
  });

  // follow first call
  useEffect(() => {
    if (dataTile === null) {
    } else {
      let tempArray: any[] = [];
      let tempMau: any[] = [];

      dataTile.forEach((item: any) => {
        let tempValue = item._percent.toFixed(2);
        let tempColor = randomColor();

        tempArray.push(tempValue);
        tempMau.push(tempColor);
      });

      setArrayPercentTile(tempArray);
      setArrayColor(tempMau);
    }
  }, [dataTile]);

  useEffect(() => {
    if (dataCot === null) {
    } else {
      let tempArray: any[] = [];

      dataCot.forEach((item: any) => {
        let tempValue = item.SoLuong;

        tempArray.push(tempValue);
      });

      setArrayTotalThongKe(tempArray);
    }
  }, [dataCot]);
  const context = useContext(ModalController);
  const _onClickSubmit = () => {
    let body: any;

    body = {
      TuNgay: fromDate,
      DenNgay: toDate,
      _isCurUser: true,
    };

    readReport(REPORT_PERSONAL_SUMWORK, body, (res: any) => {
      setDataTile(res.data.Data.BieuDoTiLe);
      setDataCot(res.data.Data.BieuDoCot);
    });
  };

  // funcs open modal
  const _onClickCreateNewReport = () => {
    context.setFuncs("createElectricReportOwner");

    context.setFuncsLoad({
      onLoad: _onLoadPlan,
    });

    context.handleOpen();
  };

  return (
    <>
      <br></br>
      <div className="w-full h-max p-3">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:space-x-2 md:space-x-2 grid-cols-1 mb-3">
          <div className="w-full">
            <label className="drop-shadow-lg">Từ ngày</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Từ Ngày"
              type="date"
              value={fromDate}
              onChange={(e: any) => {
                e.preventDefault();
                setFromDate(e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <label className="drop-shadow-lg">Đến ngày</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Đến Ngày"
              value={toDate}
              type="date"
              onChange={(e: any) => {
                e.preventDefault();
                setToDate(e.target.value);
              }}
            />
          </div>
          <div className="flex space-x-2 items-end justify-end lg:pl-3 md:pl-3 pt-3">
            <button
              className="
            flex items-center h-max p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 
            "
              onClick={_onClickSubmit}
            >
              <p>Xem</p>
            </button>
            <button
              className="
              flex h-max p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
            "
              onClick={_onClickCreateNewReport}
            >
              <AddIcon fontSize="small" />
              <p className="">Thêm kế hoạch</p>
            </button>
          </div>
        </div>
      </div>

      {dataList === null ? (
        // <div className="flex flex-col justify-center items-center w-full h-[calc(100%-10rem)] lg:mt-0 pb-10">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 pb-10">
          <div className="text-sm grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {dataList === null ? "" : dataList.count}
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {dataList === null
                  ? ""
                  : dataList.data.length + selectedPage * pageShowing}
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={pageShowing}
                onChange={(e: any) => {
                  e.preventDefault();
                  setPageShowing(e.target.value);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>

          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên kế hoạch</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Loại kế hoạch</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày tạo</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người yêu cầu</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người duyệt</p>
                    </div>
                  </th>
                  <th className="border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {dataList === null ? (
                  <></>
                ) : (
                  dataList.data?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="border border-slate-300 text-center">
                        {index + 1 + selectedPage * pageShowing}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.TaskName}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.Periodic === 1
                          ? "KẾ HOẠCH THEO NGÀY"
                          : item.Periodic === 2
                          ? "KẾ HOẠCH THEO THÁNG"
                          : item.Periodic === 3
                          ? "KẾ HOẠCH THEO QUÝ"
                          : item.Periodic === 4
                          ? "KẾ HOẠCH THEO NĂM"
                          : ""}
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="w-full h-full flex flex-col justify-center items-center">
                          <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.NameTaskManager}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.NameTaskOwner}
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="ml-1 dropdown">
                          <div className="flex">
                            <button className="dropbtn">
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            <div className="dropdown-content text-xs font-semibold">
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickDetail(
                                    item.ID,
                                    item.Periodic,
                                    item.TaskName
                                  )
                                }
                              >
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  <div>Chi tiết / Chỉnh sửa</div>
                                </div>
                              </button>

                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickEditPlan(
                                    item.ID,
                                    item.Periodic,
                                    item.TaskName
                                  )
                                }
                              >
                                <div className="flex w-full h-full space-x-1 hover:text-amber-500">
                                  <EditIcon
                                    className="text-amber-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  <div>Cập nhật</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={totalPage === null ? 0 : totalPage}
              page={selectedPage + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PlanSenderUI;
