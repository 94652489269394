import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import LoaderSpinner from "../../loader-spinner/_index";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";
import { CHECK_REPORT } from "../../../apis/_index";

const DetailReportWaiting = (props: any) => {
  // Take props
  const ReportHistoryID = props.ReportHistoryID;
  const ReportID = props.ReportID;
  const FileName = props.FileName;
  const _onFetchData = props._onFetchData;
  const handleClose = props.handleClose;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusList = [
    { value: 3, label: "Duyệt" },
    { value: 5, label: "Không duyệt" },
  ];
  const scoreList = [
    { value: 1, label: "Xuất xắc" },
    { value: 2, label: "Tốt" },
    { value: 3, label: "Trung Bình" },
    { value: 4, label: "Kém" },
  ];

  const [status, setStatus] = useState<string>("");
  const [score, setScore] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const _onClickSubmit = async () => {
    if (!status) {
      toast.warning("Vui lòng chọn trạng thái!");
      return;
    }
    if (status == "3" && !score) {
      toast.warning("Vui lòng chọn kết quả!");
      return;
    }
    if (status == "3") {
      setScore("4");
    }

    const body: any = {
      ReportHistoryID: ReportHistoryID,
      ReportID: ReportID,
      Result: parseInt(score),
      Status: parseInt(status),
      IsResult: true,
      CommentUserAdd: content,
    };
    setIsLoading(true);

    try {
      await ServiceUtils.postV2(CHECK_REPORT, body).then((res: any) => {
        if ((res.data || {}).StatusCode === 200) {
          toast.success(
            status == "3"
              ? "Duyệt báo cáo thành công"
              : "Không duyệt báo cáo thành công"
          );
          _onFetchData();
          handleClose();
        } else {
          toast.error(
            status == "3"
              ? "Duyệt báo cáo thất bại!"
              : "Không duyệt báo cáo thất bại"
          );
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[35rem] w-screen lg:max-h-[90vh] 
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        "
      >
        <p>Chi tiết/đánh giá</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}

      {/* content */}
      {isLoading === true ? (
        <div className="relative justify-center items-center flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang xử lý dữ liệu</p>
        </div>
      ) : (
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
          <div className="flex w-full h-max">
            <span className="font-medium">File báo cáo: </span>{" "}
            <p
              className="hover:text-blue-600 ml-1 cursor-pointer"
              onClick={() => window.open(FileName, "_blank")}
            >
              Xem chi tiết
            </p>
          </div>

          <div className="w-full h-max flex items-center border-slate-300 border-t-2">
            <div className="w-full h-max flex flex-col space-y-4">
              <div className="w-full h-full pt-2 flex flex-col space-y-4">
                <div className="flex flex-col w-full h-max space-y-1">
                  <label className="uppercase font-semibold text-center">
                    Bảng đánh giá&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>

                  <table>
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr className="">
                        <th className="border-l-[1px] border-slate-300">
                          Thông tin đánh giá
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      <tr className="border-solid border-black border-[2px]">
                        <td className="border-l-[1px] border-slate-300 border-[1px] space-y-1">
                          <div className="flex flex-col w-full h-max">
                            <label>
                              Chọn loại đánh giá&nbsp;
                              <span className="text-red-500">(*)</span>
                            </label>
                            <select
                              className={`border-2 border-slate-200 rounded-md h-[2rem] text-center font-bold ${
                                status == "3"
                                  ? "border-blue-600 text-blue-600"
                                  : status == "5"
                                  ? "border-red-600 text-red-600"
                                  : "border-slate-200"
                              }`}
                              value={status === "" ? "0" : status}
                              onChange={(e: any) => {
                                setStatus(e.target.value);
                              }}
                            >
                              <option value={0} disabled>
                                --- Chọn loại đánh giá ---
                              </option>
                              {statusList?.map((item: any, index: any) => (
                                <option key={index} value={item?.value} className="text-gray-700">
                                  {item?.label}
                                </option>
                              ))}
                              {/* {scoreList?.map((item: any, index: any) => (
                                <option key={index} value={item?.value}>
                                  {item?.label}
                                </option>
                              ))} */}
                            </select>
                          </div>
                          {status == "3" && (
                            <div className="flex flex-col w-full h-max">
                              <label>
                                Chọn kết quả&nbsp;
                                <span className="text-red-500">(*)</span>
                              </label>
                              <select
                                className="border-2 border-slate-200 rounded-md h-[2rem] text-center"
                                value={score === "" ? "0" : score}
                                onChange={(e: any) => {
                                  setScore(e.target.value);
                                }}
                              >
                                <option value={0} disabled>
                                  --- Chọn loại kết quả ---
                                </option>

                                {scoreList?.map((item: any, index: any) => (
                                  <option key={index} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <div>
                            <label>Nhập nội dung:</label>
                            <textarea
                              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                              placeholder="Nhập nhận xét"
                              value={content}
                              onChange={(e: any) => {
                                e.preventDefault();
                                setContent(e.target.value);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md  items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-end items-center">
          <div className="w-max h-max flex space-x-3">
            <button
              className={`
                flex items-center space-x-1 w-fit h-max p-2 text-white rounded-md
                ${
                  isLoading === true
                    ? "bg-slate-400"
                    : "bg-primary hover:bg-primary/85"
                }
                `}
              disabled={isLoading === true ? true : false}
              onClick={_onClickSubmit}
            >
              <DoneIcon fontSize="small" />
              <p className="">Gửi đánh giá</p>
            </button>

            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailReportWaiting;
