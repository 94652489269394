import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";
import MinimizeIcon from "@mui/icons-material/Minimize";

const findRootElementModal = (element: any, level: any): any => {
    console.log('a');
    
    if (level < 0) {
        return null;
    }

    if (element) {
        const parentElement = element.parentElement;

        if (parentElement) {
            if (parentElement.className.split(' ').find((p: any) => p == 'core-modal-minimize')) {
                return parentElement;
            } else {
                level--;

                return findRootElementModal(parentElement, level);
            }
        }
    }

    return null;
}

type Props = { component?: any };
interface States {
    isMinimize: boolean
}

class ModalMinimizeControl extends Component<Props, States> {
    private ref: React.RefObject<HTMLInputElement>;
    private timeOut: any;

    constructor(props: any) {
        super(props);

        this.state = {
            isMinimize: false
        }

        this.ref = React.createRef();
        this.timeOut = null;
    }

    public static defaultProps = {

    };

    componentWillUnmount(): void {
        document.querySelector('.core-modal-minimize-box-footer')?.remove();
    }

    componentDidMount(): void {
        document.querySelector('.core-modal-minimize-box-footer')?.remove();

        const div = document.createElement('div');

        div.className = 'core-modal-minimize-box-footer';

        const button = document.createElement('button');

        button.className = 'core-modal-minimize-box-footer-button';

        const parentElement = findRootElementModal(this.ref.current, 5);

        if (parentElement) {
            const coreModalMinimizeHeaderTitle = parentElement.querySelector('.core-modal-minimize-header-title');

            if (coreModalMinimizeHeaderTitle) {
                button.textContent = coreModalMinimizeHeaderTitle.textContent;
            }
        }

        button.onclick = () => {
            this.onMinimize();
        };

        div.appendChild(button);

        document.body.appendChild(div);
    }

    onMinimize = () => {
        const isMinimize = !this.state.isMinimize;

        this.setState({
            isMinimize
        });

        const parentElement = findRootElementModal(this.ref.current, 5);

        if (parentElement) {
            const parentElementBackdrop = parentElement.querySelector('.MuiBackdrop-root');
            const coreModalMinimizeBoxFooterButton = document.querySelector('.core-modal-minimize-box-footer-button');

            if (parentElementBackdrop) {
                if (isMinimize) {
                    parentElementBackdrop.classList.add('minimize');
                } else {
                    parentElementBackdrop.classList.remove('minimize');
                }
            }

            if (coreModalMinimizeBoxFooterButton) {
                if (isMinimize) {
                    coreModalMinimizeBoxFooterButton.classList.add('minimize');
                } else {
                    coreModalMinimizeBoxFooterButton.classList.remove('minimize');
                }
            }

            if (isMinimize) {
                this.timeOut = setTimeout(() => {
                    parentElement.classList.add('minimize');

                    clearTimeout(this.timeOut);

                    this.timeOut = null;
                }, 150);
            } else {
                this.timeOut = setTimeout(() => {
                    parentElement.classList.remove('minimize');

                    clearTimeout(this.timeOut);

                    this.timeOut = null;
                }, 150);
            }
        }
    }

    render() {
        const { isMinimize } = this.state;
        const { component: Component } = this.props;

        return (<div className='core-modal-minimize-box' ref={this.ref}>
            <div className={`core-modal-minimize-box-content ${isMinimize ? 'minimize' : ''}`}>
                <Component onMinimize={this.onMinimize} />
            </div>
        </div>
        );
    }
}

type PropButtons = { onMinimize?: Function, title?: any };
interface StateButtons {

}

class ModalMinimizeHeaderControl extends Component<PropButtons, StateButtons> {
    private ref: React.RefObject<HTMLInputElement>

    constructor(props: any) {
        super(props);

        this.state = {

        }

        this.ref = React.createRef();
    }

    public static defaultProps = {

    };

    componentDidMount() {

    }

    onClick = (e: any) => {
        const { onMinimize } = this.props;

        if (onMinimize) {
            onMinimize(e);
        }
    }

    render() {
        const { title } = this.props;

        return (
            <div className='core-modal-minimize-header' ref={this.ref}>
                <div className='core-modal-minimize-header-title'>{title}</div>
                <button type='button' onClick={this.onClick} className='core-modal-minimize-header-button'>
                    <MinimizeIcon className='core-modal-minimize-header-button-icon' fontSize="small" />
                </button>
            </div>
        );
    }
}

export {
    ModalMinimizeHeaderControl
}

export default ModalMinimizeControl;
