import React from "react";

// ui importing
import NewPasswordUI from "../../ui/newPasswordUI/_index";

const NewPasswordPage = () => {
  return (
    <div className="w-full h-full">
      <NewPasswordUI />
    </div>
  )
}

export default NewPasswordPage;
