import React from "react";
import HomeUI from "../../ui/homeUI/_index";

function FileManagementPage() {
  return (
    <div style={{
      display: 'flex',
      flex: 1
    }}>
      <HomeUI />
    </div>
  );
}

export default FileManagementPage;
