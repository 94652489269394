import React from "react";

// ui importing
import HomeUI from "../../../../ui/homeUI/_index";

const WorkingNewTaskUI = () => {
  return (
    <>
      <HomeUI />
    </>
  )
}

export default WorkingNewTaskUI;
