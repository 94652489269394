export const truncateParagraph = (paragraph: string, wordLimit: number) => {
  const words = paragraph.split(" ");
  if (words.length <= wordLimit) {
    return paragraph; // Return the original paragraph if it already meets or is below the word limit
  } else {
    const truncatedWords = words.slice(0, wordLimit);
    return (
      truncatedWords.join(" ") + "..."
      // Return the truncated paragraph with an ellipsis
    );
  }
};
