import React, { useState, useEffect, useRef } from "react";

import Title from "../../../../components/title";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

import { takePermission } from "../../../../funcs";

import {
  CONFIG_REMINDINFO,
  CONFIG_REMINDSETTING,
} from "../../../../apis/_index";
import { toast } from "react-toastify";
import ServiceUtils from "../../../../utils/services";

const AlertTaskingSettingUI = () => {
  // state
  const [deadline, setDeadline] = useState<number | null>(null);
  const [accept, setAccept] = useState<number | null>(null);
  const [overdue, setOverdue] = useState<number | null>(null);
  const [hourTimeNumber, setHourTimeNumber] = useState<number | null>(null);
  const [hourTimeSaturday, setHourTimeSaturday] = useState<number | null>(null);
  const [hourTimeSunday, setHourTimeSunday] = useState<number | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  const menu = takePermission(18);

  const shouldLog = useRef(true);

  // funcs
  const _onChangeDeadline = (e: any) => {
    e.preventDefault();
    setDeadline(parseInt(e.target.value));
  };

  const _onChangeAccept = (e: any) => {
    e.preventDefault();
    setAccept(parseInt(e.target.value));
  };

  const _onChangeOverdue = (e: any) => {
    e.preventDefault();
    setOverdue(parseInt(e.target.value));
  };

  const _onChangeHourTimeNumber = (e: any) => {
    e.preventDefault();
    setHourTimeNumber(parseInt(e.target.value));
  };

  const _onChangeHourTimeSaturday = (e: any) => {
    e.preventDefault();
    setHourTimeSaturday(parseInt(e.target.value));
  };

  const _onChangeHourTimeSunday = (e: any) => {
    e.preventDefault();
    setHourTimeSunday(parseInt(e.target.value));
  };

  const _onChangeIsActive = () => {
    setIsActive((value: any) => !value);
  };

  const _onClickSubmitUpdate = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      RemindDeadline: deadline,
      RemindAccept: accept,
      RemindOverdue: overdue,
      HourTimeNumber: hourTimeNumber,
      HourTimeSaturday: hourTimeSaturday,
      HourTimeSunday: hourTimeSunday,
      IsActiveRemind: isActive,
    };

    try {
      await ServiceUtils.postV2(CONFIG_REMINDSETTING, body, {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Cập nhật thành công.");
        } else {
          toast.error("Cập nhật thất bại.");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  useEffect(() => {
    try {
      if (shouldLog.current) {
        shouldLog.current = false;

        const takeData = async () => {
          await ServiceUtils.getV2(CONFIG_REMINDINFO, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setDeadline(res.data.Data[0].RemindDeadline);
              setAccept(res.data.Data[0].RemindAccept);
              setOverdue(res.data.Data[0].RemindOverdue);
              setHourTimeNumber(res.data.Data[0].HourTimeNumber);
              setHourTimeSaturday(res.data.Data[0].HourTimeSaturday);
              setHourTimeSunday(res.data.Data[0].HourTimeSunday);
              setIsActive(res.data.Data[0].IsActiveRemind);
            }
          });
        };

        takeData();
      }
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  });

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 text-sm space-y-3">
        <div className="w-full h-[2rem] flex justify-end items-end">
          <button
            className={`
              p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 items-center space-x-1
              ${menu.PerEdit === true ? "flex" : "hidden"}
            `}
            onClick={_onClickSubmitUpdate}
          >
            <SaveIcon fontSize="small" />
            <p>Lưu lại</p>
          </button>
        </div>

        <div className="w-full h-[calc(100%-2rem)] space-y-4 flex flex-col">
          <p>Tính năng cho phép hệ thống gửi nhắc việc tự động</p>

          <div className="flex w-full h-max items-center space-x-3">
            <p className="flex">
              Kích hoạt tính năng, đổi màu sắc nhân viên khi giao việc
            </p>
            <input
              type="checkbox"
              className="w-5 h-5"
              checked={isActive}
              onChange={_onChangeIsActive}
              disabled={menu.PerEdit === true ? false : true}
            />
          </div>

          <p className="font-bold">Ghi chú:</p>

          <div className="flex w-full h-max space-x-3">
            <div className="flex flex-col w-max h-max space-y-3">
              <div className="flex gap-2 sm:gap-3 items-center">
                <div className="flex justify-between gap-2 sm:gap-3 items-center w-[100px] md:w-[185px]">
                  <p className="h-[1rem] flex items-center">
                    Cảnh báo việc tới hạn trước
                  </p>
                </div>
                <div className="flex justify-between gap-2 sm:gap-3 items-center">
                  <input
                    className="h-[1.5rem] w-[3rem] rounded-md pl-2 pr-2 border-2 border-green-500"
                    value={deadline?.toString()}
                    onChange={(e: any) => {
                      _onChangeDeadline(e);
                    }}
                    disabled={menu.PerEdit === true ? false : true}
                  />
                </div>
                <div className="flex gap-2 sm:gap-3 items-center w-[150px] md:w-auto">
                  <p className="w-max h-[1rem] flex items-center">%</p>
                  <p className="w-max h-[1rem] flex items-center">
                    thời gian còn lại
                  </p>
                </div>
              </div>

              <div className="flex gap-2 sm:gap-3 items-center">
                <div className="flex justify-between gap-2 sm:gap-3 items-center w-[100px] md:w-[185px]">
                  <p className="h-[1rem] flex items-center">
                    Cảnh báo chưa nhận việc mỗi
                  </p>
                </div>
                <div className="flex justify-between gap-2 sm:gap-3 items-center">
                  <input
                    className="h-[1.5rem] w-[3rem] rounded-md pl-2 pr-2 border-2 border-yellow-500"
                    value={accept?.toString()}
                    onChange={(e: any) => {
                      _onChangeAccept(e);
                    }}
                    disabled={menu.PerEdit === true ? false : true}
                  />
                </div>
                <div className="flex gap-2 sm:gap-3 items-center w-[150px] md:w-auto">
                  <p className="w-max h-[1rem] flex items-center">%</p>
                  <p className="w-max h-[1rem] flex items-center">
                    thời gian sau khi có công việc được giao mà chưa nhận
                  </p>
                </div>
              </div>

              <div className="flex gap-2 sm:gap-3 items-center">
                <div className="flex justify-between gap-2 sm:gap-3 items-center w-[100px] md:w-[185px]">
                  <p className="h-[1rem] flex items-center">
                    Cảnh báo việc quá hạn mỗi
                  </p>
                </div>
                <div className="flex justify-between gap-2 sm:gap-3 items-center">
                  <input
                    className="h-[1.5rem] w-[3rem] rounded-md pl-2 pr-2 border-2 border-red-500"
                    value={overdue?.toString()}
                    onChange={(e: any) => {
                      _onChangeOverdue(e);
                    }}
                    disabled={menu.PerEdit === true ? false : true}
                  />
                </div>
                <div className="flex gap-2 sm:gap-3 items-center w-[150px] md:w-auto">
                  <p className="w-max h-[1rem] flex items-center">%</p>
                  <p className="w-max h-[1rem] flex items-center">
                    thời gian khi quá hạn công việc đó
                  </p>
                </div>
              </div>

              <div className="flex gap-2 sm:gap-3 items-center">
                <div className="flex justify-between gap-2 sm:gap-3 items-center w-[100px] md:w-[185px]">
                  <p className="h-[1rem] flex items-center">
                    Áp dụng ngày làm việc
                  </p>
                </div>
                <div className="flex justify-between gap-2 sm:gap-3 items-center">
                  <input
                    className="h-[1.5rem] w-[3rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={hourTimeNumber?.toString()}
                    onChange={(e: any) => {
                      _onChangeHourTimeNumber(e);
                    }}
                    disabled={menu.PerEdit === true ? false : true}
                  />
                </div>
                <div className="flex gap-2 sm:gap-3 items-center w-[150px] md:w-auto">
                  <p className="w-max h-[1rem] flex items-center">
                    giờ làm việc
                  </p>
                </div>
              </div>

              <div className="flex gap-2 sm:gap-3 items-center">
                <div className="flex justify-between gap-2 sm:gap-3 items-center w-[100px] md:w-[185px]">
                  <p className="h-[1rem] flex items-center">
                    Áp dụng tính ngày thứ 7
                  </p>
                </div>
                <div className="flex justify-between gap-2 sm:gap-3 items-center">
                  <input
                    className="h-[1.5rem] w-[3rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={hourTimeSaturday?.toString()}
                    onChange={(e: any) => {
                      _onChangeHourTimeSaturday(e);
                    }}
                    disabled={menu.PerEdit === true ? false : true}
                  />
                </div>
                <div className="flex gap-2 sm:gap-3 items-center w-[150px] md:w-auto">
                  <p className="w-max h-[1rem] flex items-center">
                    giờ làm việc
                  </p>
                </div>
              </div>

              <div className="flex gap-2 sm:gap-3 items-center">
                <div className="flex justify-between gap-2 sm:gap-3 items-center w-[100px] md:w-[185px]">
                  <p className="h-[1rem] flex items-center">
                    Áp dụng tính ngày CN
                  </p>
                </div>
                <div className="flex justify-between gap-2 sm:gap-3 items-center">
                  <input
                    className="h-[1.5rem] w-[3rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={hourTimeSunday?.toString()}
                    onChange={(e: any) => {
                      _onChangeHourTimeSunday(e);
                    }}
                    disabled={menu.PerEdit === true ? false : true}
                  />
                </div>
                <div className="flex gap-2 sm:gap-3 items-center w-[150px] md:w-auto">
                  <p className="w-max h-[1rem] flex items-center">
                    giờ làm việc
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertTaskingSettingUI;
