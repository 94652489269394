import { useState, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../_index";
import { toast } from "react-toastify";

import Select from "react-select";

import SubModal from "../_subModal/_index";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import {
  ON_BUSINESSTRIP_DETAIL,
  ON_BUSINESSTRIP_UPDATE,
} from "../../../apis/_index";

const DetailBusinessTrip = (props: any) => {
  // Props
  const workingData = useSelector((state: any) => state.workingReducer);
  const shouldLog = useRef(true);

  const taskId = props.taskId;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  console.log();

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { label: item.FullName, value: item.Id };
  });

  // States
  const [id, setId] = useState<any>(undefined);
  const [dayStart, setDayStart] = useState<any>("");
  const [dayEnd, setDayEnd] = useState<any>("");
  const [timeOff, setTimeOff] = useState<any>("");
  const [reason, setReason] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [person, setPerson] = useState<any | null>([]);

  // Const
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  function mapReceiverWithPerson(personList: any, tempReceiverList: any) {
    const personMap = new Map();

    personList.forEach((person: any) => {
      personMap.set(person.UserID.trim(), person);
    });

    const mappedList = tempReceiverList
      .map((receiver: any) => {
        const person = personMap.get(receiver.value);
        if (person) {
          return { value: receiver.value, label: receiver.label };
        }
        return null;
      })
      .filter(Boolean);

    return mappedList;
  }

  // Funcs
  const loadDetail = () => {
    const API = ON_BUSINESSTRIP_DETAIL + `?id=${taskId}`;

    const fetchData = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setId(res.data.Data.ID);
            setTimeOff(res.data.Data.HoursUsed);
            setDayStart(res.data.Data.StartDate);
            setDayEnd(res.data.Data.EndDate);
            setReason(res.data.Data.Title);
            setPerson(
              mapReceiverWithPerson(
                res.data.Data.Li_BusinessTrip_Users,
                tempReceiverList
              )
            );
            setDescription(res.data.Data.Content);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData();
  };

  // const _onClickDelete = async (id: string) => {
  //   setIsLoading(true);
  //   const API = ON_MEETING_DELETE + `${id}`;

  //   try {
  //     await ServiceUtils.getV2(API, {
  //       headers: {
  //         Accept: "text/plain",
  //       },
  //     }).then((res: any) => {
  //       if (res.data.StatusCode === 200) {
  //         // toast.success("Xoá thành công");
  //         // context.dataModal.tempFuncs();
  //         // handleClose();
  //         // context.handleClose();
  //       } else {
  //         // toast.error(res.data.Message);
  //         // handleClose();
  //       }
  //     });
  //   } catch (error: any) {
  //     // toast.error(
  //     //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
  //     // );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const _onClickUpdate = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (
      dayStart === "" ||
      dayEnd === "" ||
      timeOff === "" ||
      reason === "" ||
      person.length === 0
    ) {
      toast.warning("Vui lòng nhập đầy đủ thông tin.");
      return;
    }

    const body: any = {
      ID: id,
      StartDate: dayStart,
      EndDate: dayEnd,
      HoursUsed: timeOff,
      Title: reason,
      UserBusinessTrips: person.map((item: any) => item.value).join(", "),
    };

    const fetchData = async () => {
      setIsLoading(true);
      try {
        await ServiceUtils.postV2(ON_BUSINESSTRIP_UPDATE, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            // _onClickDelete(taskId);
            setIsLoading(false);
            context.dataModal.tempFuncs();
            toast.success("Cập nhật lịch công tác thành công");
            handleClose();
            context.handleClose();
          } else {
            toast.warning(res.data.Message || "Lỗi không xác định.");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
        setIsLoading(false);
      }
    };

    fetchData();
  };

  // CRUD
  const _onClickConfirmDelete = () => {
    setFuncsSub("deleteBusinessTrip");
    handleOpenSub();
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      loadDetail();
    }
  });

  // const tempPerson = useMemo(() => {
  //   return tempReceiverList?.find((item: any) => item.Id === per);
  // }, [userAdd]);

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      <SubModal
        taskId={taskId}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />

      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết lịch công tác</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {id === undefined || isloading === true ? (
        <LoadingControl />
      ) : (
        <div className="relative flex flex-col w-full h-max overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          <div className="space-y-4">
            <div className="flex items-center">
              <label className="w-1/4">Tiêu đề</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="text"
                value={reason}
                onChange={(e: any) => {
                  e.preventDefault();
                  setReason(e.target.value);
                }}
              />
            </div>

            <div className="flex items-center">
              <label className="w-1/4">Số giờ</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="number"
                placeholder="Nhập số giờ nghỉ"
                value={timeOff}
                onChange={(e: any) => {
                  e.preventDefault();
                  setTimeOff(e.target.value);
                }}
              />
            </div>

            <div className="flex items-center">
              <label className="w-1/4">Người tham gia</label>
              <Select
                className="z-30 w-full"
                options={tempReceiverList}
                // isDisabled
                isClearable
                isMulti
                placeholder="--- Chọn người nghỉ ---"
                value={person}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                onChange={(data: any) => {
                  // console.log("data, ", data);
                  if (data === null) {
                    setPerson(null);
                  } else {
                    setPerson(data);
                  }
                }}
              />
            </div>

            <div className="flex items-center">
              <label className="w-1/4">Từ ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="datetime-local"
                value={dayStart}
                onChange={(e: any) => {
                  e.preventDefault();
                  setDayStart(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="w-1/4">Đến ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="datetime-local"
                value={dayEnd}
                onChange={(e: any) => {
                  e.preventDefault();
                  setDayEnd(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="w-full h-max flex justify-between items-center">
          <div className="w-max h-max flex">
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${
                isResult === false && result === ""
                  ? "bg-slate-300"
                  : "bg-red-500 hover:bg-red-400"
              }`}
              disabled={isResult === false && result === ""}
              onClick={_onClickConfirmDelete}
            >
              <DeleteIcon fontSize="small" />
              <p>Xoá</p>
            </button>
          </div>

          <div className="flex w-max h-max space-x-3">
            {isloading ? (
              <LoaderSpinner h={25} w={25} />
            ) : (
              <button
                className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${
                isResult === false && result === ""
                  ? "bg-slate-300"
                  : "bg-sky-500 hover:bg-sky-400"
              }`}
                disabled={isResult === false && result === ""}
                onClick={_onClickUpdate}
              >
                <EditIcon fontSize="small" />
                <p>Cập nhật</p>
              </button>
            )}

            <button
              className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailBusinessTrip;
