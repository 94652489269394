import { toast } from "react-toastify";
import { DOMAIN_CLOUD } from "../../../apis/_index";
import ServiceUtils from "../../../utils/services";

export const fetchMenuFolder = async () => {
  try {
    const res = await ServiceUtils.getV2(
      `${DOMAIN_CLOUD}/api/Cloud/MenuFolder`
    );

    if (res.status === 200) {
      return res.data.Data;
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const fetchFolder = async (id: any) => {
  try {
    const res = await ServiceUtils.getV2(
      `${DOMAIN_CLOUD}/api/Cloud/Folder/${id}`
    );

    if (res.status === 200) {
      return res.data.Data[0];
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const postFolder = async (body: any) => {
  try {
    const res: any = await ServiceUtils.postV2(
      `${DOMAIN_CLOUD}/api/Cloud/Folder`,
      body
    );

    if (res.status === 200 && res.data.StatusCode === 200) {
      toast.success(res.data.Message);
      return res;
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const fetchData = async (id: any) => {
  try {
    const res = await ServiceUtils.getV2(
      `${DOMAIN_CLOUD}/api/Cloud/Folder/${id}/files`
    );

    if (res.status === 200) {
      if (res.data.StatusCode === 200) return res.data.Data;
      else toast.error(res.data.Message);
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const uploadFile = async (
  id: any,
  file: File,
  onUploadProgress: any
) => {
  try {
    const formData: any = new FormData();

    formData.append("[0].FolderId", id);
    formData.append("[0].File", file);

    const url = `${DOMAIN_CLOUD}/api/Cloud/Files`;

    await ServiceUtils.postV2(
      url,
      formData,
      {
        "Content-Type":
          "multipart/form-data;" + " boundary=" + formData._boundary,
      },
      {
        onUploadProgress: (progressEvent: any) => {
          if (onUploadProgress) {
            const progress = Math.round(
              (progressEvent.loaded / (progressEvent.total || 1)) * 100
            );
            console.log(progress);
            onUploadProgress(progress);
          }
        },
      }
    ).then((res: any) => {
      if (res.status !== 200) {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const patchDnD = async (value: any, target: any) => {
  try {
    const url = value.FileId
      ? `${DOMAIN_CLOUD}/api/Cloud/File/${value.FileId}/Move?toFolderId=${target}`
      : `${DOMAIN_CLOUD}/api/Cloud/Folder/${value.FolderId}/Move?toParentId=${target}`;

    await ServiceUtils.patchV2(url).then((res: any) => {
      if (res.status === 200 && res.data.StatusCode === 200) {
        toast.success("Di chuyển thành công!");
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const moveToTrash = async (value: any) => {
  try {
    const url = value.FileId
      ? `${DOMAIN_CLOUD}/api/Cloud/Files/${value.FileId}/MoveToTrash`
      : `${DOMAIN_CLOUD}/api/Cloud/Folder/${value.FolderId}/MoveToTrash`;

    await ServiceUtils._deleteV2(url, {}).then((res: any) => {
      if (res.status === 200 && res.data.StatusCode === 200) {
        toast.success("Di chuyển vào thùng rác thành công!");
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const deleteOnTrash = async (value: any) => {
  try {
    const url = value.FileId
      ? `${DOMAIN_CLOUD}/api/Cloud/Files/${value.FileId}/Delete`
      : `${DOMAIN_CLOUD}/api/Cloud/Folder/${value.FolderId}/Delete`;

    await ServiceUtils._deleteV2(url, {}).then((res: any) => {
      if (res.status === 200 && res.data.StatusCode === 200) {
        toast.success("Xóa khỏi thùng rác thành công!");
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const renameDrive = async (target: any, value: any) => {
  try {
    await ServiceUtils.patchV2(
      target.FileId
        ? `${DOMAIN_CLOUD}/api/Cloud/File/${target.FileId}/Rename?fileName=${value}`
        : `${DOMAIN_CLOUD}/api/Cloud/Folder/${target.FolderId}/Rename?folderName=${value}`
    ).then((res: any) => {
      if (res.status === 200 && res.data.StatusCode === 200) {
        toast.success(res.data.Message);
        return res;
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const downloadFile = async (id: any, fileName: any) => {
  const url = `${DOMAIN_CLOUD}/api/Cloud/DownloadFile?fileId=${id}`;

  try {
    const res = await ServiceUtils.getV2(url, {}, { responseType: "blob" });

    const blobUrl = window.URL.createObjectURL(res.data);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);

    window.URL.revokeObjectURL(blobUrl);
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const shareDrive = async (
  target: any,
  users: string[],
  roles: number[]
) => {
  try {
    const url = target.FileId
      ? `${DOMAIN_CLOUD}/api/Cloud/File/${target.FileId}/Share`
      : `${DOMAIN_CLOUD}/api/Cloud/Folder/${target.FolderId}/Share`;

    const requestBody: any =
      users.length === 0 && roles.length === 0
        ? {}
        : {
            ShareUserId: users,
            ShareFileMode: roles,
          };

    await ServiceUtils.patchV2(url, requestBody).then((res: any) => {

      if (res.status === 200 && res.data.StatusCode === 200) {
        toast.success(res.data.Message);
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const listShareDrive = async (item: any) => {
  try {
    const url = !item.FileId
      ? `${DOMAIN_CLOUD}/api/Cloud/Folder/${item.FolderId}/Share`
      : `${DOMAIN_CLOUD}/api/Cloud/File/${item.FileId}/Share`;

    const res = await ServiceUtils.getV2(url);

    if (res.status === 200) {
      if (res.data.StatusCode === 200) {
        return res.data.Data;
      } else {
        toast.error(res.data.Message);
      }
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const fetchFolderShare = async (page: any, pageSize: any) => {
  try {
    const res = await ServiceUtils.getV2(
      `${DOMAIN_CLOUD}/api/Cloud/Folder/Sharefiles?page=${page}&pageSize=${pageSize}`
    );

    if (res.status === 200) {
      return (res.data || {}).Data || [];
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const fetchDriveTrash = async () => {
  try {
    const res = await ServiceUtils.getV2(
      `${DOMAIN_CLOUD}/api/Cloud/Folder/DeletedFiles`
    );

    if (res.status === 200) {
      return (res.data || {}).Data || [];
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const restoreDrive = async (value: any) => {
  try {
    const url = value.FileId
      ? `${DOMAIN_CLOUD}/api/Cloud/File/${value.FileId}/revert`
      : `${DOMAIN_CLOUD}/api/Cloud/Folder/${value.FolderId}/revert`;

    await ServiceUtils.patchV2(url).then((res: any) => {
      if (res.status === 200 && res.data.StatusCode === 200) {
        toast.success(res.data.Message);
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const searchData = async (name: String) => {
  try {
    const res = await ServiceUtils.getV2(
      `${DOMAIN_CLOUD}/api/Cloud?search=${name}`
    );

    if (res.status === 200) {
      if (res.data.StatusCode === 200) return res.data.Data;
      else toast.error(res.data.Message);
    } else {
      toast.error(res.data.Message);
    }
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};
