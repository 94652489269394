
import EditTask from './editTask';

const EditTaskRecoveryAndTransfer = (props: any) => {
    const taskId = props.taskId;
    const handleClose = props.handleClose;
    const taskCode = props.taskCode;
    
    return <EditTask isTransfer={true} taskId={taskId} handleClose={handleClose} taskCode={taskCode} />;
}

export default EditTaskRecoveryAndTransfer;