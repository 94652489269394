import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { ONLOAD_USERDATA } from "../../../redux/reducers/_appReducer/_index";

import {
  READ_ALL_LIST_QUYTRINH_DROPDOWN,
  LOAD_PHONG_BAN,
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  READ_TASK_LIST_BY_USER,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  DETAIL_STEP_DROPDOWN,
  DETAIL_RESULT_DROPDOWN,
  CREATE_TASK_MY_SELF,
  NGUOI_GIAO_VIEC_LIST,
  DIFFICULT_LEVEL,
  CONFIG_IS_FORCE,
  DIFICULT_LEVEL_DROPDOWN,
  LOAD_PHONG_BAN_BY_USER,
  APPROVE_TASK,
  LOAD_SEP,
  LOAD_SUGGEST_LIST,
  READ_ALL_TASK_LIST_DROPDOWN,
  TAKE_USER_INFO,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import Select, { StylesConfig } from "react-select";

import { takePermission } from "../../../funcs";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import CircleIcon from "@mui/icons-material/Circle";
import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";
import DateTimePickerControl from "../../../controls/datetimePicker/datetimePickerControl";
import OrganizationUtils from "../../../utils/organizations/organizationUtils";
import LevelComponent from "../../organizations/levels/levelComponent";
import TextareaControl from "../../../controls/textarea/textareaControl";
import DeviceUtils from "../../../utils/devices/deviceUtils";
import ServiceUtils from "../../../utils/services";
import moment from "moment";
import { getUserID } from "../../../utils/users/userUtils";

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const CreateTaskMyself = (props: any) => {
  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  let userData: any = localStorage.getItem("userData");

  if (userData) {
    userData = userData?.replace(/'/g, '"');
    userData = JSON.parse(userData);
  }

  // take props
  const handleClose = props.handleClose;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;
  const selectDauViec = useRef(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const menu = takePermission(19);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [suggest, setSuggest] = useState<string>("");
  const [suggestList, setSuggestList] = useState<any[] | null>(null);

  const [isExChange, setIsExChange] = useState<boolean>(true);
  const [isEvaluate, setIsEvaluate] = useState<boolean>(false);

  const [useHourSupporter, setUseHourSupporter] = useState<any[]>([]);
  const [useMinutesSupporter, setUseMinutesSupporter] = useState<any[]>([]);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [phongBanSelectedForSelect, setPhongBanSelectedForSelect] =
    useState<any>(-1);
  const [phongBanListForSelect, setPhongBanListForSelect] = useState<
    any[] | undefined
  >(undefined);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [userListForSelect, setUserListForSelect] = useState<any[] | undefined>(
    undefined
  );
  const [userListForSelected, setUserListForSelected] = useState<
    any[] | undefined
  >(undefined);
  const [loaiViecList, setLoaiViecList] = useState<any[] | null>(null);
  const [nguonVanBanList, setNguonVanBanList] = useState<any[] | null>(null);
  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);
  const [dauViecList, setDauViecList] = useState<any[] | null>(null);
  const [quyTrinhList, setQuyTrinhList] = useState<any[] | null>(null);
  const [doKhoList, setDoKhoList] = useState<any[] | null>(null);
  const uploadFileChat = useRef<any>(null);

  const [selectedDoKho, setSelectedDoKho] = useState<any>(-1);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);
  const [isMulti, setIsMulti] = useState<boolean>(false);
  const [listMultiUser, setListMultiUser] = useState<any[]>([]);
  const [userDanhGiaList, setUserDanhGiaList] = useState<any>(null);
  const [phongBanDanhGiaList, setPhongBanDanhGiaList] = useState<any[] | null>(
    null
  );

  // const [phongBan_BossAsking, setPhongBan_BossAsking] = useState<any>(null);

  const [selectedNguoiDanhGiaList, setSelectedNguoiDanhGiaList] =
    useState<any>(null);

  const [bossAsking, setBossAsking] = useState<any>(null);

  const [selectedLoaiViec, setSelectedLoaiViec] = useState<any>(-1);
  const [selectedNguonVanBan, setSelectedNguonVanBan] = useState<any>("");
  const [selectedUuTien, setSelectedUuTien] = useState<any>(-1);
  const [selectedDoMat, setSelectedDoMat] = useState<any>(-1);
  const [selectedDauViec, setSelectedDauViec] = useState<any>(-1);
  const [selectedQuyTrinh, setSelectedQuyTrinh] = useState<any>(-1);

  const [isAutoForce, setIsAutoForce] = useState<boolean | null>(null);
  const [isAutoRequiredUseHours, setIsAutoRequiredUseHours] = useState<
    boolean | null
  >(null);
  const [isAutoSecurity, setIsAutoSecurity] = useState<boolean | null>(null);
  const [isAutoPriority, setIsAutoPriority] = useState<boolean | null>(null);
  const [isAutoDifference, setIsAutoDifference] = useState<boolean | null>(
    null
  );
  const [isAutoLoaiViec, setIsAutoLoaiViec] = useState<boolean | null>(null);
  const [priorityDefault, setPriorityDefault] = useState<number | null>(null);
  const [isAutoOrigin, setIsAutoOrigin] = useState<boolean | null>(null);

  const [selectedNguoiPhoiHopList, setSelectedNguoiPhoiHopList] =
    useState<any>(null);

  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );
  const [selectedNguoiGopYList, setSelectedNguoiGopYList] = useState<any>([]);

  const [selectedBuocList, setSelectedBuocList] = useState<any>(null);
  const [selectedKetQuaList, setSelectedKetQuaList] = useState<any>(null);

  const [filesList, setFilesList] = useState<any>([]);
  const [notes, setNotes] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [askingContent, setAskingContent] = useState<string>("");
  const [isForce, setIsForce] = useState<boolean>(false);
  const [isAskingBoss, setIsAskingBoss] = useState<boolean>(false);
  const [isSelectedBuocOK, setIsSelectedBuocOK] = useState<boolean>(false);

  // other thing
  const [taskName, setTaskName] = useState<any>("");
  const [assignmentDate, setAssignmentDate] = useState<any>("");
  const [deadLine, setDeadLine] = useState<any>("");
  const [refCode, setRefCode] = useState<any>("");

  const [bodyAdding, setBodyAdding] = useState<any>();

  // props - states
  const [positionStep, setPositionStep] = useState<number | null>(null);
  const [hourStep, setHourStep] = useState<number | null>(null);
  const [contentStep, setContentStep] = useState<string>("");

  const [ketqua, setKetqua] = useState<string>("");
  const [isAllUser, setIsAllUser] = useState<boolean>(false);
  const [useHours, setUseHours] = useState<number | null>(0);
  const [useMinutes, setUseMinutes] = useState<number | null>(0);

  const [organizationId, setOrganizationId] = useState<any>(null);

  const [listBoss, setListBoss] = useState();

  // funcs
  const _onChangeRefCode = (e: any) => {
    e.preventDefault();
    setRefCode(e.target.value);
  };

  const convertHours = (e: number): any => {
    if (e) {
      const days = Math.floor(e / 24);
      const hours = Math.floor(e % 24);
      const minutes = Math.round((e % 1) * 60);

      let result = "";

      if (days > 0) {
        result += `${days} ngày `;
      }

      if (hours > 0) {
        result += `${hours} giờ `;
      }

      if (minutes > 0) {
        result += `${minutes} phút`;
      }

      return result.trim();
    } else {
      return null;
    }
  };

  useEffect(() => {
    const newUserList: any = [];
    if (userData?.Data[0]) {
      newUserList.push(userData?.Data[0]);
    }
    setSelectedNguoiLamViecList((oldValue: any) =>
      [...oldValue].concat(newUserList)
    );
    const takeData = async () => {
      try {
        await ServiceUtils.getV2(LOAD_SEP, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {

            let _dataBoss: any = res?.data?.Data?.map((item: any) => {
              return { ...item, label: item?.FullName, value: item?.Id };
            });
            if (_dataBoss.length == 1) {
              setBossAsking(_dataBoss[0]);
            }
            setListBoss(_dataBoss);
          } else {
            toast.error(res?.data?.Message || "Tải danh sách sếp thất bại");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  }, []);

  const _onChangeHours = (e: any) => {
    e.preventDefault();
    setUseHours(e?.target?.value);
  };

  const _onChangeMinutes = (e: any) => {
    e.preventDefault();
    setUseMinutes(e?.target?.value);
  };

  const _onChangeIsEvaluate = () => {
    if (isEvaluate === false) {
      setIsEvaluate(true);
    } else {
      setIsEvaluate(false);
    }
  };

  const _onConvertToSelect = (
    arr: any[] | null = [],
    name: string,
    value: string,
    color: string | null = null
  ) => {
    let newArr: any[] | null = arr === null ? [] : [...arr];
    newArr = newArr.map((x: any) => ({
      ...x,
      label: x[name],
      value: x[value],
      color: color !== null ? x[color] : "#000",
    }));
    return newArr;
  };

  // -- edit files
  const saveEditFile = (_index: number, fileName: string) => {
    const tempArray = [...filesList];

    const updateArray = tempArray.map((item: any, index: any) => {
      if (index === _index) {
        const renamedFile = new Blob([item], {
          type: item?.type,
        });

        const fileWithNewName = new File([renamedFile], fileName, {
          type: item?.type,
          lastModified: item?.lastModified,
        });

        return fileWithNewName;
      } else {
        return item;
      }
    });

    setFilesList(updateArray);
  };

  const _onClickEditFile = (index: string, file: any, setFile: any) => {
    setFuncsSub("openEditFileSub");
    setBodyAdding({
      filesList2: file,
      setFilesList2: setFile,
      saveEditFile,
      index,
    });
    handleOpenSub();
  };
  // -- end edit file

  // const _onChangePhongBanDanhGia = (e: any) => {
  //   e.preventDefault();

  //   setPhongBan_BossAsking(
  //     phongBanDanhGiaList?.find((item: any) =>
  //       (item?.IdPhongBan || "")
  //         .toString()
  //         .split(",")
  //         .find((p: any) => p == parseInt(e.target.value))
  //         ? true
  //         : false
  //     )
  //   );

  //   setSelectedNguoiDanhGiaList(
  //     userDanhGiaList?.filter((item: any) =>
  //       (item?.IdPhongBan || "")
  //         .toString()
  //         .split(",")
  //         .find((p: any) => p == parseInt(e.target.value))
  //         ? true
  //         : false
  //     )
  //   );
  //   setBossAsking(null);

  // };

  const _onChangeNguoiDanhGia = (e: any) => {
    e.preventDefault();
    setBossAsking(
      selectedNguoiDanhGiaList?.find((item: any) => item?.Id === e.target.value)
    );
  };

  const _onChangeBoss = (e: any) => {
    if (e) {
      setBossAsking(e);
    }
  };

  const _onChangeStepList = (type: string, value: any, index: any) => {
    switch (type) {
      case "stepName":
        let newArray = [...selectedBuocList];
        newArray[index].StepName = value;
        setSelectedBuocList(newArray);
        break;
      case "orderBy":
        let newArray2 = [...selectedBuocList];
        newArray2[index].SortOrder = value;
        setSelectedBuocList(newArray2);
        break;
      case "result":
        let newArray3 = [...selectedKetQuaList];
        newArray3[index].tenketqua = value;
        setSelectedKetQuaList(newArray3);
        break;
      default:
        break;
    }
  };

  const _onCheckNguoiLamViec = (id: any, users: any) => {
    const isTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    const isCounterTrue = selectedNguoiGopYList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      const newUserList: any = [];

      const newUser = users?.find((item: any) => item?.Id == id);

      if (newUser) {
        newUserList.push(newUser);
      }

      setSelectedNguoiLamViecList((oldValue: any) =>
        [...oldValue].concat(newUserList)
      );

      setUseHourSupporter((oldValue: any) => [
        ...oldValue,
        {
          id: id,
          value: 0,
        },
      ]);

      setUseMinutesSupporter((oldValue: any) => [
        ...oldValue,
        {
          id: id,
          value: 0,
        },
      ]);

      // Countering
      if (isCounterTrue !== undefined) {
        const tempArray = selectedNguoiGopYList?.filter(
          (item: any) => item?.Id !== id
        );

        setSelectedNguoiGopYList(tempArray);
      }
    } else {
      const tempArray = selectedNguoiLamViecList?.filter(
        (item: any) => item?.Id !== id
      );

      const tempHours = useHourSupporter?.filter(
        (item: any) => item?.id !== id
      );

      const tempMinutes = useMinutesSupporter?.filter(
        (item: any) => item?.id !== id
      );

      setSelectedNguoiLamViecList(tempArray);

      setUseHourSupporter(tempHours);
      setUseMinutesSupporter(tempMinutes);
    }
  };

  const _onCheckNguoiGopY = (id: any, users: any) => {
    const isTrue = selectedNguoiGopYList?.find((item: any) => item?.Id === id);

    const isCounterTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      const newUserList: any = [];

      const newUser = users?.find((item: any) => item?.Id == id);

      if (newUser) {
        newUserList.push(newUser);
      }

      setSelectedNguoiGopYList((oldValue: any) =>
        [...oldValue].concat(newUserList)
      );

      // Countering
      if (isCounterTrue !== undefined) {
        const tempArray = selectedNguoiLamViecList?.filter(
          (item: any) => item?.Id !== id
        );

        const tempHours = useHourSupporter?.filter(
          (item: any) => item?.id !== id
        );
        const tempMinutes = useMinutesSupporter?.filter(
          (item: any) => item?.id !== id
        );

        setSelectedNguoiLamViecList(tempArray);
        setUseHourSupporter(tempHours);
        setUseMinutesSupporter(tempMinutes);
      }
    } else {
      const tempArray = selectedNguoiGopYList?.filter(
        (item: any) => item?.Id !== id
      );

      setSelectedNguoiGopYList(tempArray);
    }
  };

  const _onChangeQuyTrinh = (e: any) => {
    e.preventDefault();

    if (e.target.value === "-1") {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item?.id === parseInt(e.target.value))
      );

      const takeStep = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_STEP_DROPDOWN + e.target.value, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            let tempArray: any[] = [];

            res.data.Data.forEach((item: any, index: any) => {
              const body = {
                StepName: item?.tenbuoc,
                UserDo: "",
                DeadLine_Step: "",
                SortOrder: item?.orderBy,
              };
              tempArray.push(body);
            });

            setSelectedBuocList(tempArray);
          });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_RESULT_DROPDOWN + e.target.value, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setSelectedKetQuaList(res.data.Data);
          });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  // non - funcs
  const _onChangeNguoiPhoiHop = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditBuoc = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditKetqua = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // props - funcs
  // buoc
  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setPositionStep(parseInt(e.target.value));
  };

  const _onChangeHourStep = (e: any) => {
    e.preventDefault();
    setHourStep(e.target.value);
  };

  const _onChangeContentStep = (e: any) => {
    e.preventDefault();
    setContentStep(e.target.value);
  };

  const _onPushStep = () => {
    const body = {
      StepName: contentStep,
      UserDo: "",
      DeadLine_Step: "",
      SortOrder: positionStep,
    };

    if (selectedBuocList === null || selectedBuocList === undefined) {
      setSelectedBuocList([body]);
    } else {
      let isExistedStep = selectedBuocList.find(
        (item: any) => item?.SortOrder === positionStep
      );

      if (isExistedStep === undefined) {
        setSelectedBuocList((oldValue: any) => [...oldValue, body]);
      } else {
      }
    }

    setPositionStep(null);
    setHourStep(null);
    setContentStep("");
  };

  const _onDeleteQuyTrinh = (step: any) => {
    setSelectedBuocList(
      selectedBuocList.filter((item: any) => item?.SortOrder !== step)
    );
  };

  const _onChangeNguoiPhoiHopEachStep = (orderBy: any, nguoiPhoiHop: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item?.SortOrder === orderBy) {
        return {
          ...item,
          UserDo: nguoiPhoiHop,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };

  const _onChangDeadLineStep = (orderBy: any, deadLine: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item?.SortOrder === orderBy) {
        return {
          ...item,
          DeadLine_Step: deadLine,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };
  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };
  // ket qua
  const _onChangeKetqua = (e: any) => {
    e.preventDefault();
    setKetqua(e.target.value);
  };

  const _onPushKetqua = () => {
    const body = {
      idquytrinh: selectedQuyTrinh?.Id,
      tenketqua: ketqua,
    };

    if (selectedKetQuaList === null) {
      setSelectedKetQuaList([body]);
    } else {
      setSelectedKetQuaList((oldValue: any) => [...oldValue, body]);
    }

    setKetqua("");
  };

  const _onChangePhongBanSelect = (e: any, type: number) => {
    e.preventDefault();
    // <<<<<<< HEAD
    //     const nguoiNhan = userListForSelect?.filter((item: any) => {
    //       const idPhongBanArray = (item?.IdPhongBan || "").split(",");
    //       const idPhongBanNumbers = idPhongBanArray.map((id: any) =>
    //         parseInt(id, 10)
    //       );
    //       return idPhongBanNumbers.includes(parseInt(e?.target?.value, 10));
    //     });
    // =======

    const nguoiNhan = userListForSelect?.filter((item: any) =>
      (item?.IdPhongBan || "")
        .toString()
        .split(",")
        .find((p: any) => p == parseInt(e?.target?.value))
        ? true
        : false
    );

    setUserListForSelected(nguoiNhan);

    setPhongBanSelectedForSelect(
      phongBanListForSelect?.find(
        //         (item: any) => item?.IdPhongBan == parseInt(e?.target?.value)
        (item: any) =>
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == parseInt(e?.target?.value))
            ? true
            : false
      )
    );
  };

  const _onDeleteKetqua = (ketQua: string) => {
    setSelectedKetQuaList(
      selectedKetQuaList.filter((item: any) => item?.tenketqua !== ketQua)
    );
  };

  // files
  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onDeleteElements = (index: number) => {
    const tempArray: any[] = [...filesList];
    tempArray.splice(index, 1);
    setFilesList(tempArray);
  };

  // other things
  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  };

  const _onChangeDeadLine = (e: any) => {
    e.preventDefault();
    setDeadLine(e.target.value);
  };

  const _onChangeDeadLine2 = (e: any) => {
    // e.preventDefault();
    //console.log(moment(e).format("DD/MM/YYYY hh:mm:ss"));
    const value = e;
    setDeadLine(value);
  };
  const _onChangeAssignmentDate = (e: any) => {
    const value = e;
    setAssignmentDate(value);
  };

  const _onChangeSelectedLoaiCongViec = (e: any) => {
    e.preventDefault();
    setSelectedLoaiViec(
      loaiViecList?.find(
        (item: any) => item?.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(
      nguonVanBanList?.find(
        (item: any) => item?.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNewNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(e.target.value);
  };

  const _onChangeDoKho = (e: any) => {
    e.preventDefault();
    setSelectedDoKho(
      doKhoList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeSelectedUuTien = (e: any) => {
    e.preventDefault();
    setSelectedUuTien(
      uuTienList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeDoMat = (e: any) => {
    e.preventDefault();
    setSelectedDoMat(
      doMatList?.find((item: any) => item?.Id === e.target.value)
    );
  };

  const _onChangeDauViec = (e: any) => {
    e.preventDefault();
    setSelectedDauViec(
      dauViecList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeNotes = (e: any) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const _onChangeAskingContent = (e: any) => {
    setAskingContent(e?.target?.value || "");
  };

  const _onChangeIsChooseTaskReviewer = () => {
    setIsAskingBoss((item: any) => !item);
  };

  // submit
  // funcs
  const _onClickSubmit = async (type?: boolean): Promise<any> => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList.length - 1) {
        tempStringNguoiPhoiHop += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item?.Id.toString()},`;
      }
    });

    let tempStringNguoiGopY: string = "";

    selectedNguoiGopYList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiGopYList.length - 1) {
        tempStringNguoiGopY += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiGopY += `${item?.Id.toString()},`;
      }
    });

    let tempHourUseSupporter: string = "";

    useHourSupporter.forEach((item: any, index: any) => {
      if (index === useHourSupporter.length - 1) {
        tempHourUseSupporter += `${item?.value.toString()}`;
      } else {
        tempHourUseSupporter += `${item?.value.toString()},`;
      }
    });

    let tempMinutesUseSupporter: string = "";

    useMinutesSupporter.forEach((item: any, index: any) => {
      if (index === useMinutesSupporter.length - 1) {
        tempMinutesUseSupporter += `${item?.value.toString()}`;
      } else {
        tempMinutesUseSupporter += `${item?.value.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("TaskName", taskName ? taskName : "");

    let tempArrayDauViec: string = "";

    selectedDauViec.forEach((item: any, index: any) => {
      if (index === selectedDauViec.length - 1) {
        tempArrayDauViec += `${item?.Id.toString()}`;
      } else {
        tempArrayDauViec += `${item?.Id.toString()},`;
      }
    });

    formData.append(
      "TaskListID",
      tempArrayDauViec
      // (selectedDauViec ? selectedDauViec.Id : "").toString()
    );

    formData.append(
      "PriorityLevelId",
      selectedUuTien != -1 && selectedUuTien
        ? selectedUuTien.Id
        : priorityDefault
          ? priorityDefault
          : ""
    );
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : null);

    formData.append(
      "DifficultLevelID",
      selectedDoKho ? selectedDoKho.Id : null
    );

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec.ParameterId : null
    );
    formData.append("NguonViec", selectedNguonVanBan);
    formData.append(
      "QuyTrinhID",
      selectedQuyTrinh ? selectedQuyTrinh?.id : null
    );
    formData.append("Recipient", userData?.Data[0]?.Id);

    formData.append("IsTaskMySelf", "true");
    formData.append("BossAsking", bossAsking ? bossAsking?.Id : null);
    formData.append("IsAskingBoss", isAskingBoss?.toString());
    // formData.append("PhongBan_BossAsking", phongBan_BossAsking ? phongBan_BossAsking?.IdPhongBan : null)
    formData.append("AskingContent", askingContent ? askingContent : "");

    // formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("IDRole", tempStringNguoiGopY);
    formData.append("IsExChange", isExChange?.toString());
    formData.append("IsEvaluate", isEvaluate?.toString());

    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");

    formData.append("DeadLine", new Date(deadLine).toString().substring(0, 24));

    formData.append(
      "AssignmentDate",
      assignmentDate !== ""
        ? new Date(assignmentDate).toString().substring(0, 24)
        : ""
    );
    formData.append(
      "IsWaitAssignment",
      assignmentDate !== "" ? "true" : "false"
    );

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());

    formData.append("HoursUsed", useHours ? useHours.toString() : "");
    formData.append("HoursUsed_Supporter", tempHourUseSupporter);
    formData.append("MinutesUsed", useMinutes ? useMinutes.toString() : "");
    formData.append("MinutesUsed_Supporter", tempMinutesUseSupporter);

    formData.append("IdPhongBan", (organizationId || "").toString());
    formData.append("PhongBanId", (organizationId || "").toString());

    console.log("isProtractedTask, ", isProtractedTask);

    if (isProtractedTask) {
      const clearedList = taskTimeUsed?.map((item: any) => {
        const { TaskId, index, ...rest } = item;
        return rest;
      });

      clearedList?.map((item: any, index: number) => {
        formData.append(
          `Task_TimeUsed[${index}].UserID`,
          userData?.Data[0]?.Id
        );
        formData.append(`Task_TimeUsed[${index}].Month`, item.Month);
        formData.append(`Task_TimeUsed[${index}].Year`, item.Year);
        formData.append(`Task_TimeUsed[${index}].HoursUsed`, item.HoursUsed);
        formData.append(
          `Task_TimeUsed[${index}].MinutesUsed`,
          item.MinutesUsed
        );
      });
    } else {
      formData.append(`Task_TimeUsed[0].UserID`, userData?.Data[0]?.Id);
      formData.append(
        `Task_TimeUsed[0].Month`,
        (new Date(deadLine).getMonth() + 1).toString()
      );
      formData.append(
        `Task_TimeUsed[0].Year`,
        new Date(deadLine).getFullYear().toString()
      );
      if (useHours) {
        formData.append(`Task_TimeUsed[0].HoursUsed`, useHours.toString());
      }

      if (useMinutes) {
        formData.append(`Task_TimeUsed[0].MinutesUsed`, useMinutes.toString());
      }
    }

    const takeData = (): Promise<any> => {
      // try {
      return new Promise((resolve, reject) => {
        ServiceUtils.postFormDataV2(CREATE_TASK_MY_SELF, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res: any) => {
            if (res?.data?.StatusCode === 200) {
              readList(
                NGUOI_GIAO_VIEC_LIST,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog3,
                urlLink,
                (res: any) => {
                  dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
                },
                null,
                null,
                null,
                null,
                data.searchFilter.searchMonth,
                data.searchFilter.searchYear
              );

              localStorage.removeItem("userData");
              const takeData_ReloadUsers = async () => {

                try {
                  await ServiceUtils.getV2(TAKE_USER_INFO, {
                    headers: {},
                  }).then((res: any) => {
                    localStorage.setItem("userData", JSON.stringify(res?.data));
                    dispatch(ONLOAD_USERDATA(res?.data?.Data[0]));
                  });
                } catch (error: any) {
                  toast.error(
                    "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
                  );
                }
              };
              takeData_ReloadUsers();

              setIsLoading(false);
              toast.success("Tạo công việc mới thành công.");
              handleClose();
            } else {
              resolve(false);
              setIsLoading(false);
              toast.error(res?.data?.Message || "Tạo công việc thất bại");
            }
          })
          .catch((error: any) => {
            setIsLoading(false);
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
            reject(error);
          });
      });
    };

    takeData();
  };

  // load selecting data
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      if (isAutoForce === null) {
        // const accessToken = localStorage.getItem("accessToken");

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(CONFIG_IS_FORCE, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                if (res?.data?.Data[0]?.AllowTaskReject === false) {
                  setIsForce(true);
                }

                setIsAutoForce(res?.data?.Data[0]?.AllowTaskReject);
                setIsAutoRequiredUseHours(
                  res?.data?.Data[0]?.IsRequiredUseHours
                );
                setIsAutoSecurity(res?.data?.Data[0]?.AllowUseSecurity);
                setIsAutoPriority(res?.data?.Data[0]?.AllowUsePriority);
                setIsAutoDifference(res?.data?.Data[0]?.AllowUseDifference);
                setIsAutoOrigin(res?.data?.Data[0]?.AllowUseOrigin);
                setIsAutoLoaiViec(res?.data?.Data[0]?.AllowUseLoaiViec);
                setPriorityDefault(res?.data?.Data[0]?.PriorityDefault);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (suggestList === null) {
        const fetchData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_SUGGEST_LIST, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.data.StatusCode === 200) {
                setSuggestList(res.data.Data);
              } else {
                toast.error(res.data.Message);
              }
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        fetchData();
      }

      if (phongBanList === null || userList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_PHONG_BAN_BY_USER, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                const organizations =
                  ((res.data || {}).Data || {}).PhongBan || [];

                const organizationTrees = OrganizationUtils.getListTree(
                  organizations,
                  {
                    id: "IdPhongBan",
                    parentId: "RefID",
                  }
                );

                setPhongBanList(organizationTrees);
                setPhongBanListForSelect(
                  _onConvertToSelect(
                    res?.data?.Data?.PhongBan,
                    "TenPhongBan",
                    "IdPhongBan",
                    "ColorText"
                  )
                );
                setUserList(res.data.Data.NguoiNhan);
                setUserListForSelect(
                  _onConvertToSelect(
                    res?.data?.Data?.NguoiNhan.filter(
                      (x: any) => x.Id !== userData?.Data[0]?.Id
                    ),
                    "FullName",
                    "Id"
                  )
                );
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (phongBanDanhGiaList === null || userDanhGiaList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_PHONG_BAN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                const organizations = res?.data?.Data?.PhongBan;
                const organizationTrees = OrganizationUtils.getListTree(
                  organizations,
                  {
                    id: "IdPhongBan",
                    parentId: "RefID",
                  }
                );

                setPhongBanDanhGiaList(organizationTrees);
                setUserDanhGiaList(res?.data?.Data?.NguoiNhan);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (loaiViecList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_LOAIVIEC";

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setLoaiViecList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (nguonVanBanList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_NGUONVANBAN";

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setNguonVanBanList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (uuTienList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setUuTienList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doMatList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDoMatList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (dauViecList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(READ_ALL_TASK_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDauViecList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (quyTrinhList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(READ_ALL_LIST_QUYTRINH_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setQuyTrinhList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doKhoList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(DIFICULT_LEVEL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDoKhoList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }
    }
  });

  const _onCheckValid = (): boolean => {
    if (
      selectedBuocList === null ||
      selectedKetQuaList === null ||
      selectedQuyTrinh === -1
    ) {
      return true;
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          return false;
        }
      }
      return true;
    }
  };

  useEffect(() => {
    if (selectedBuocList === null || selectedBuocList === undefined) {
      setIsSelectedBuocOK(false);
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          setIsSelectedBuocOK(false);
          break;
        } else {
          setIsSelectedBuocOK(true);
        }
      }
    }
  }, [selectedBuocList]);

  // Preprocessing
  const tempQuyTrinhList = quyTrinhList?.map((item) => {
    return { ...item, value: item?.tenquytrinh, label: item?.tenquytrinh };
  });
  const tempDauViecList = dauViecList?.map((item) => {
    return { ...item, value: item?.Tendauviec, label: item?.Tendauviec };
  });

  const convertToPercent = (total: any, param: any) => {
    let value = 0;
    if (param && total) {
      value = (param / total) * 100;
    }
    return value.toFixed(2);
  };

  const _onChangeQuytrinhList = (data: any) => {
    if (data === null) {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item?.id === parseInt(data.id))
      );

      const takeStep = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_STEP_DROPDOWN + data.id, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            let tempArray: any[] = [];

            res.data.Data.forEach((item: any, index: any) => {
              const body = {
                StepName: item?.tenbuoc,
                UserDo: "",
                DeadLine_Step: "",
                SortOrder: item?.orderBy,
              };
              tempArray.push(body);
            });

            setSelectedBuocList(tempArray);
          });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_RESULT_DROPDOWN + data.id, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setSelectedKetQuaList(res.data.Data);
          });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  const _onChangeDauViecList = (_data: any) => {
    if (_data === null) {
      setSelectedDauViec(-1);
    } else {
      setSelectedDauViec(
        dauViecList?.find((item: any) => item?.Id === _data?.Id)
      );
    }
  };

  const _onChangeDauViecList2 = (_data: any) => {
    setSelectedDauViec(_data);
  };

  const getUnique = (arr: any[], index: any) => {
    if (arr.length == 0) {
      return [];
    }
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);

    return unique;
  };

  const _onChangeListMultiUser = (type: number, e: any) => {
    const _listMultiUser = [...listMultiUser];

    if (type === 1) {
      userListForSelect?.map((item: any) => {
        if (
          item &&
          e.value &&
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == e.value)
        ) {
          if (item?.Id !== userData?.Data[0]?.Id) {
            _listMultiUser.push(item);
          }
        }
      });

      setListMultiUser(getUnique(_listMultiUser, "value"));
    } else if (type === 2 || type === 3) {
      if (e?.Id !== userData?.Data[0]?.Id) {
        _listMultiUser.push(e);
      }
      setListMultiUser(getUnique(_listMultiUser, "value"));
    } else if (type === 4) {
      setListMultiUser(e);
    } else if (type === 5) {
      // setListMultiUser(e)
      setIsAllUser((item: any) => !item);
    }
  };

  useEffect(() => {
    let _listMultiUser: any[] = [];
    _listMultiUser = [...listMultiUser];
    if (isAllUser) {
      userListForSelect?.map((item: any) => {
        if (item?.Id !== userData?.Data[0]?.Id) {
          _listMultiUser.push(item);
        }
      });
    } else {
      _listMultiUser = [];
    }
    setListMultiUser(getUnique(_listMultiUser, "value"));
  }, [isAllUser]);

  // funcs
  const _onChangeWorkerViaDepart = (data: any) => {
    if (data === null) {
      setSelectedNguoiPhoiHopList(
        userList?.filter(
          (item: any) => item?.Id !== selectedNguoiLamViecList[0]?.Id
        )
      );
    } else {
      setSelectedNguoiPhoiHopList(
        userList?.filter(
          (item: any) =>
            (item?.IdPhongBan || "")
              .toString()
              .split(",")
              .find((p: any) => p == data.ParameterId) &&
            item?.Id !== selectedNguoiLamViecList[0].Id
        )
      );
    }
  };

  // reset filter
  const resetFilter = () => {
    setSelectedNguoiPhoiHopList(
      userList?.filter(
        (item: any) => item?.Id !== selectedNguoiLamViecList[0]?.Id
      )
    );
  };

  const tempSuggestList = useMemo(() => {
    return suggestList?.filter((item) => item.NameSuggest.includes(suggest));
  }, [suggest]);

  const isOkWithHourSupporter = useMemo(() => {
    if (isAutoRequiredUseHours === true) {
      if (selectedNguoiLamViecList.length === 0) {
        return true;
      } else if (selectedNguoiLamViecList.length !== 0) {
        for (let i = 0; i < useHourSupporter?.length; i++) {
          if (
            useHourSupporter[i].value === 0 ||
            useHourSupporter[i].value === "0" ||
            isNaN(useHourSupporter[i].value) === true
          ) {
            return false;
          }
        }
        for (let i = 0; i < useMinutesSupporter?.length; i++) {
          if (
            useMinutesSupporter[i].value === 0 ||
            useMinutesSupporter[i].value === "0" ||
            isNaN(useMinutesSupporter[i].value) === true
          ) {
            return false;
          }
        }
      }
    } else {
      return true;
    }
  }, [selectedNguoiLamViecList, useHourSupporter, useMinutesSupporter]);

  // JSX
  const DauMucViecJSX = () => {
    return (
      <div className="w-full h-max">
        <label>
          Đầu mục&nbsp;<span className="text-red-500">(*)</span>
        </label>

        <Select
          ref={selectDauViec}
          className="z-[5]"
          options={tempDauViecList}
          value={selectedDauViec}
          onChange={(data: any) => _onChangeDauViecList2(data)}
          isClearable
          isMulti
          placeholder="--- Chọn đầu mục ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        // defaultValue={tempDauViecList?.find(
        //   (item) => item?.Id === selectedDauViec?.Id
        // )}
        />
      </div>
    );
  };

  const [isProtractedTask, setIsProtractedTask] = useState<boolean>(false);
  const [taskTimeUsed, setTaskTimeUsed] = useState<any>([]);

  useEffect(() => {
    if (deadLine === null || deadLine === "") return;

    let currentDate: Date;

    if (assignmentDate != null && assignmentDate !== "") {
      currentDate = new Date(assignmentDate);
    } else {
      currentDate = new Date();
    }
    let currentMonth = currentDate.getMonth(); // Tháng hiện tại
    let currentYear = currentDate.getFullYear(); // Năm hiện tại

    const selectedDate = new Date(deadLine);
    let selectedMonth = selectedDate.getMonth(); // Tháng được chọn
    let selectedYear = selectedDate.getFullYear(); // Năm được chọn

    // Kiểm tra nếu tháng và năm được chọn lớn hơn tháng và năm hiện tại
    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth)
    ) {
      let listTimeUse = [];
      setIsProtractedTask(true);
      while (
        currentYear < selectedYear ||
        (currentYear === selectedYear && currentMonth <= selectedMonth)
      ) {
        listTimeUse.push({
          TaskId: "",
          UserID: selectedNguoiNhan?.Id || "",
          Month: currentMonth + 1,
          Year: currentYear,
          HoursUsed: 0,
          MinutesUsed: 0,
          index: 0,
        });
        currentMonth++;
        if (currentMonth > 12) {
          currentMonth = 1;
          currentYear++;
        }
      }
      setTaskTimeUsed(listTimeUse);
    } else {
      setIsProtractedTask(false);
    }
  }, [assignmentDate, deadLine]);

  const mergeTimeData = (
    hoursUsed: any,
    hoursUsedSupporter: any,
    minutesUsed: any,
    minutesUsedSupporter: any
  ) => {
    const result = [];

    if (hoursUsed !== undefined && minutesUsed !== undefined) {
      result.push({
        Hours: parseInt(hoursUsed, 10),
        Minutes: parseInt(minutesUsed, 10),
      });
    }

    if (
      hoursUsedSupporter !== undefined &&
      minutesUsedSupporter !== undefined
    ) {
      const hoursArray = hoursUsedSupporter.map((item: any) => item.value);
      const minutesArray = minutesUsedSupporter.map((item: any) => item.value);

      for (let i = 0; i < hoursArray.length; i++) {
        const hours = hoursArray[i];
        const minutes = minutesArray[i];
        result.push({
          Hours: parseInt(hours, 10),
          Minutes: parseInt(minutes, 10),
        });
      }
    }

    return result;
  };
  function convertToHoursAndMinutes(
    sumHours: number,
    sumMinutes: number
  ): { hours: number; minutes: number } {
    const hours = Math.floor(sumMinutes / 60) + sumHours;
    const minutes = sumMinutes % 60;
    return { hours, minutes };
  }
  const isOKTotalTimeByIndex = (index: number) => {
    const mergedTimeData = mergeTimeData(
      useHours || 0,
      useHourSupporter || [],
      useMinutes || 0,
      useMinutesSupporter || []
    );

    let sumHours = 0;
    let sumMinutes = 0;

    taskTimeUsed.map((item: any) => {
      if (item.index === index) {
        sumHours += item?.HoursUsed;
        sumMinutes += item?.MinutesUsed;
      }
    });
    const { hours, minutes } = convertToHoursAndMinutes(sumHours, sumMinutes);

    if (
      mergedTimeData[index]?.Hours === hours &&
      mergedTimeData[index]?.Minutes === minutes
    )
      return true;

    return false;
  };
  console.log("taskTimeUsed, ", taskTimeUsed);
  const _onChangeTaskTimeUsed = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    isHoursUsed: boolean,
    subIndex: number
  ) => {
    let newTaskTimeUsed = [...taskTimeUsed];
    let itemCount = 0;

    const updatedData = newTaskTimeUsed?.map((item) => {
      if (item.index === index) {
        itemCount++;
        if (itemCount === subIndex + 1) {
          const updatedHoursUsed = isHoursUsed
            ? e.target.value
            : item.HoursUsed;
          const updatedMinutesUsed = !isHoursUsed
            ? e.target.value
            : item.MinutesUsed;
          return {
            ...item,
            HoursUsed: parseInt(updatedHoursUsed, 10),
            MinutesUsed: parseInt(updatedMinutesUsed, 10),
          };
        }
      }
    });
    const mergedArray = newTaskTimeUsed?.map((targetObj) => {
      const sourceObj = updatedData.find(
        (obj) =>
          obj &&
          obj.UserID === targetObj.UserID &&
          obj.Month === targetObj.Month &&
          obj.Year === targetObj.Year
      );
      if (sourceObj) {
        return { ...targetObj, ...sourceObj };
      }
      return targetObj;
    });
    setTaskTimeUsed(mergedArray);
  };
  const getValueTime = (
    index: number,
    isHoursUsed: boolean,
    subIndex: number
  ): number => {
    let itemCount = 0;
    const targetItem = taskTimeUsed.find((item: any) => {
      if (item.index === index) {
        itemCount++;
        return itemCount === subIndex + 1;
      }
      return false;
    });

    if (targetItem) {
      return isHoursUsed ? targetItem.HoursUsed : targetItem.MinutesUsed;
    }

    return 0;
  };
  const AssignmentDateJSX = () => {
    return (
      <DateTimePickerControl
        className={"width-100-percent padding-right-0"}
        customLabelHTML={"<p>Thời gian bắt đầu</p>"}
        value={assignmentDate}
        onChange={(e: any) => {
          const value = e;
          _onChangeAssignmentDate(value);
        }}
      />
    );
  };

  const DeadLineJSX = () => {
    return (
      <DateTimePickerControl
        className={"width-100-percent padding-right-0"}
        customLabelHTML={
          '<p>Thời hạn hoàn thành <span class="text-red-500">(*)</span></p>'
        }
        value={deadLine}
        onChange={(e: any) => {
          const value = e;
          _onChangeDeadLine2(value);
        }}
      />
    );
  };

  const QuytrinhJSX = () => {
    return (
      <>
        <div
          className="w-full h-max justify-start items-center flex space-x-3"
          style={{
            marginBottom: "5px",
          }}
        >
          <label>Quy trình&nbsp;(nếu có)</label>

          <div
            className={`
                    ${menu?.PerInsert === true ? "flex" : "hidden"}
                    w-max h-max
                  `}
          >
            {/* <button className="text-xs pl-1.5 pr-1.5 pt-0.5 pb-0.5 bg-primary
                      text-white rounded-md hover:bg-sky-400
                    "
                      onClick={() => {
                        setFuncsSub("createWorkflowSub")
                        handleOpenSub()
                      }}
                    > 
                      <p>Tạo quy trình</p>
                    </button> */}
          </div>
        </div>
        <Select
          className=""
          options={tempQuyTrinhList}
          onChange={(data: any) => _onChangeQuytrinhList(data)}
          isClearable
          placeholder="--- Chọn quy trình ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          defaultValue={tempQuyTrinhList?.find(
            (item) => item?.id === selectedQuyTrinh?.id
          )}
        />
      </>
    );
  };

  const StepListJSX = () => {
    return (
      <>
        {/* Web */}
        <div
          style={{
            zIndex: 0,
          }}
          className={`w-2/3 pr-3 tableFixHead
          ${selectedBuocList === null || selectedBuocList === undefined
              ? "lg:flex flex-col hidden"
              : "lg:flex flex-col hidden"
            }
        `}
        >
          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300">Bước</th>

                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300">Người thực hiện</th>
                <th className="border border-slate-300">Thời hạn</th>

                <th className="border border-slate-300 w-[1rem]"></th>
              </tr>
            </thead>

            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null || selectedBuocList === undefined
                ? ""
                : selectedBuocList
                  .sort((a: any, b: any) => {
                    return a.SortOrder - b.SortOrder;
                  })
                  .map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="text-center border border-slate-300">
                        <p>{item?.SortOrder}</p>
                      </td>
                      <td className="border border-slate-300">
                        <p>{item?.StepName}</p>
                      </td>
                      <td className="text-center border border-slate-300">
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          value={item?.UserDo}
                          onChange={(e: any) => {
                            e.preventDefault();
                            _onChangeNguoiPhoiHopEachStep(
                              item?.SortOrder,
                              e.target.value
                            );
                          }}
                        >
                          <option
                            className="text-gray-300"
                            value={""}
                            disabled
                          >
                            --- Chọn người phối hợp ---
                          </option>
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList.map(
                              (item: any, index: any) => (
                                <option key={index} value={item?.Id}>
                                  {item?.FullName}
                                </option>
                              )
                            )}
                        </select>
                      </td>
                      <td className="text-center border border-slate-300">
                        <DateTimePickerControl
                          className={"width-100-percent padding-right-0"}
                          value={item?.DeadLine_Step}
                          onChange={(e: any) => {
                            _onChangDeadLineStep(item?.SortOrder, e);
                          }}
                        />
                        {/* <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      type="datetime-local"
                      value={item.DeadLine_Step}
                      onChange={(e: any) => {
                        e.preventDefault();
                        _onChangDeadLineStep(
                          item.SortOrder,
                          e.target.value
                        );
                      }}
                    /> */}
                      </td>
                      <td className="border border-slate-300">
                        <button
                          className="text-red-500 w-1/6"
                          onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>

          <div className="my-4 w-full h-max justify-end items-end flex">
            <button
              className="
              bg-emerald-500 p-1.5 text-white rounded-md
              hover:bg-emerald-400
            "
              onClick={() => _onClickEditBuoc("editBuoc")}
            >
              Thêm bước
            </button>
          </div>
        </div>

        {/* Phone */}
        <div
          className={`w-full h-max tableFixHead ${selectedBuocList === null || selectedBuocList === undefined
            ? "flex lg:hidden flex-col"
            : "flex lg:hidden flex-col"
            }`}
        >
          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300 w-[1rem]">
                  {/* <button
                    className="
                              flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                              rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                            "
                    onClick={() => _onClickEditBuoc("editBuoc")}
                  >
                    +
                  </button> */}
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null || selectedBuocList === undefined
                ? ""
                : selectedBuocList.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="flex flex-col border border-slate-300 space-y-2">
                      <p className="font-bold">
                        Bước&nbsp;{item?.SortOrder}:
                      </p>
                      <p>{item?.StepName}</p>
                      <select
                        className="lg:w-full sm:w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={item?.UserDo}
                        onChange={(e: any) => {
                          e.preventDefault();
                          _onChangeNguoiPhoiHopEachStep(
                            item?.SortOrder,
                            e.target.value
                          );
                        }}
                      >
                        <option className="text-gray-300" value={""} disabled>
                          --- Chọn người thực hiện ---
                        </option>
                        {selectedNguoiLamViecList === null
                          ? ""
                          : selectedNguoiLamViecList.map(
                            (item: any, index: any) => (
                              <option key={index} value={item?.Id}>
                                {item?.FullName}
                              </option>
                            )
                          )}
                      </select>
                      <DateTimePickerControl
                        className={"width-100-percent padding-right-0"}
                        value={item?.DeadLine_Step}
                        onChange={(e: any) => {
                          _onChangDeadLineStep(item?.SortOrder, e);
                        }}
                      />
                    </td>
                    <td className="border border-slate-300">
                      <button
                        className="text-red-500 w-1/6"
                        onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="my-4 w-full h-max justify-end items-end flex">
            <button
              className="
              bg-emerald-500 p-1.5 text-white rounded-md
              hover:bg-emerald-400
            "
              onClick={() => _onClickEditBuoc("editBuoc")}
            >
              Thêm bước
            </button>
          </div>
        </div>
      </>
    );
  };

  const _onChangeHoursSupporter = (e: any, id: any) => {
    const tempArray = useHourSupporter.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          value: parseInt(e.target.value),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setUseHourSupporter(tempArray);
  };

  const _onChangeMinutesSupporter = (e: any, id: any) => {
    const tempArray = useMinutesSupporter.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          value: parseInt(e.target.value),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setUseMinutesSupporter(tempArray);
  };

  const isMobile = DeviceUtils.isMobile();

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[40rem] h-[calc(var(--view-height) - 5.2rem)]
      rounded-md
    "
      style={{
        height: isMobile
          ? "calc(var(--view-height) - 2.7rem)"
          : "calc(var(--view-height) - 10rem)",
      }}
    >
      <SubModal
        bodyAdding={bodyAdding}
        _onChangeWorkerViaDepart={_onChangeWorkerViaDepart}
        resetFilter={resetFilter}
        _onCheckNguoiGopY={_onCheckNguoiGopY}
        open={openSub}
        handleClose={handleCloseSub}
        _onCheckNguoiPhoiHop={_onCheckNguoiLamViec}
        selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
        selectedNguoiLamViecList={selectedNguoiLamViecList}
        selectedNguoiGopYList={selectedNguoiGopYList}
        selectedBuocList={selectedBuocList}
        positionStep={positionStep}
        hourStep={hourStep}
        contentStep={contentStep}
        _onChangePositionStep={_onChangePositionStep}
        _onChangeHourStep={_onChangeHourStep}
        _onChangeContentStep={_onChangeContentStep}
        _onPushStep={_onPushStep}
        _onDeleteQuyTrinh={_onDeleteQuyTrinh}
        _onChangeNguoiPhoiHopEachStep={_onChangeNguoiPhoiHopEachStep}
        selectedKetQuaList={selectedKetQuaList}
        ketqua={ketqua}
        _onPushKetqua={_onPushKetqua}
        _onChangeKetqua={_onChangeKetqua}
        _onDeleteKetqua={_onDeleteKetqua}
        _onChangeStepList={_onChangeStepList}
        funcs={funcsSub}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tạo công việc cho bản thân</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </button>
      </div>
      {/* content */}
      {phongBanList === null ||
        userList === null ||
        loaiViecList === null ||
        nguonVanBanList === null ||
        uuTienList === null ||
        doMatList === null ||
        dauViecList === null ||
        quyTrinhList === null ||
        doKhoList === null ||
        isAutoSecurity === null ||
        isAutoForce === null ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tải biểu mẫu</p>
        </div>
      ) : isLoading === true ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tạo công việc mới</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          <div className="w-full h-max flex justify-between items-center gap-2">
            <p className="font-semibold text-red-500">
              Lưu ý: Việc đã được giao thì không được phép chỉnh sửa thông tin
            </p>
            <button
              className="w-[80px] h-max rounded-md p-2 bg-slate-300 font-bold hover:bg-slate-200"
              onClick={() => {
                localStorage.removeItem("tempTaskCreate");
                window.location.reload();
              }}
              style={{
                minWidth: "60px",
              }}
            >
              Tải lại
            </button>
          </div>

          <>
            {/* Web detail */}
            <div className="hidden lg:flex w-full h-max flex-col mt-4 text-sm space-y-2 z-[70]">
              {/* <div className="w-full h-max">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>

                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="--- Chọn đầu mục ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempDauViecList?.find(
                    (item) => item?.Id === selectedDauViec?.Id
                  )}
                />
              </div> */}

              <DauMucViecJSX />

              {/* <div className="w-full h-max relative pb-16 z-[60]">
                <div className="w-full h-max absolute">
                  <label>
                    Gợi ý&nbsp;<span className="text-red-500">(*)</span>
                  </label>

                  <div className="w-full h-max inline-block space-y-1.5">
                    <input
                      className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập gợi ý"
                      value={suggest}
                      onChange={(e: any) => {
                        e.preventDefault()
                        setSuggest(e.target.value)
                      }}
                      onClick={() => {
                        setIsShowSuggest(true)
                      }}
                    />

                    <div
                      className={`
                      w-full h-max max-h-96
                      ${isShowSuggest ? "block" : "hidden"} overflow-y-auto
                      p-2 rounded-sm bg-stone-50 drop-shadow-md
                    `}
                    >
                      {
                        tempSuggestList?.map((item: any, index: number) => (
                          <button key={index} className="w-full h-[30px] flex justify-start items-center hover:bg-slate-200 px-2"
                            onClick={() => {
                              setSuggest(item?.NameSuggest)
                              setIsShowSuggest(false)
                            }}
                          >
                            {item?.NameSuggest}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="w-full h-max">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <textarea
                  className="w-full h-[2.5rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="flex w-full space-x-4">
                <div className="w-full space-y-2">
                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Loại công việc/dịch vụ/tư vấn&nbsp;
                        {isAutoLoaiViec && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedLoaiViec === -1
                            ? -1
                            : selectedLoaiViec.ParameterId
                        }
                        onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn loại công việc ---
                        </option>
                        {loaiViecList === null ? (
                          <></>
                        ) : (
                          loaiViecList.map((item: any, index: any) => (
                            <option key={index} value={item?.ParameterId}>
                              {item?.ParameterValue}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Cơ sở căn cứ bắt đầu công việc&nbsp;
                        {isAutoOrigin && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      {/* <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedNguonVanBan === -1
                            ? -1
                            : selectedNguonVanBan?.ParameterId
                        }
                        onChange={(e: any) => _onChangeNguonVanBan(e)}
                      >
                        <option value={-1} disabled>
                          --- Chọn căn cứ ---
                        </option>
                        {nguonVanBanList === null ? (
                          <></>
                        ) : (
                          nguonVanBanList.map((item: any, index: any) => (
                            <option key={index} value={item?.ParameterId}>
                              {item?.ParameterValue}
                            </option>
                          ))
                        )}
                      </select> */}
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập căn cứ"
                        value={selectedNguonVanBan}
                        onChange={(e: any) => _onChangeNewNguonVanBan(e)}
                      />
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Độ mật&nbsp;
                        {isAutoSecurity && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : "font-bold"
                          }
                  `}
                        value={
                          selectedDoMat === -1 || selectedDoMat === null
                            ? -1
                            : selectedDoMat?.Id
                        }
                        onChange={(e: any) => _onChangeDoMat(e)}
                        style={{
                          color: `${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : selectedDoMat?.Color
                            }`,
                          borderColor: `${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : selectedDoMat?.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ mật ---
                        </option>
                        {doMatList === null ? (
                          <></>
                        ) : (
                          doMatList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.SecurityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Mức độ ưu tiên&nbsp;
                        {isAutoPriority && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                        value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                        onChange={(e: any) => _onChangeSelectedUuTien(e)}
                        style={{
                          color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                            }`,
                          borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn mức độ ---
                        </option>
                        {uuTienList === null ? (
                          <></>
                        ) : (
                          uuTienList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.PriorityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    {/* <div className="w-full space-y-1">
                      <label>
                        Thời hạn hoàn thành&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập tên công việc"
                        type="datetime-local"
                        value={deadLine}
                        onChange={(e: any) => _onChangeDeadLine(e)}
                      />
                    </div> */}

                    {/* <DateTimePickerControl
                      className={"width-100-percent padding-right-0"}
                      label={"Thời hạn hoàn thành (*)"}
                      value={deadLine}
                      onChange={(e: any) => {
                        const value = e;
                        _onChangeDeadLine2(value);
                      }}
                    /> */}
                    <AssignmentDateJSX />
                    <DeadLineJSX />
                  </div>
                  <div className="flex w-full">
                    <div className="w-full space-y-1">
                      <label>
                        Độ khó&nbsp;
                        {isAutoDifference && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                        value={selectedDoKho === -1 ? -1 : selectedDoKho.Id}
                        onChange={(e: any) => _onChangeDoKho(e)}
                        style={{
                          color: `${selectedDoKho === -1 ? "" : selectedDoKho.Color
                            }`,
                          borderColor: `${selectedDoKho === -1 ? "" : selectedDoKho.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ khó ---
                        </option>
                        {doKhoList === null ? (
                          <></>
                        ) : (
                          doKhoList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.DifficultLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="w-full h-max">
                    <div className="w-full h-max">
                      {/* <label>Mã tham chiếu công việc</label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập mã"
                        value={refCode}
                        onChange={(e: any) => _onChangeRefCode(e)}
                      /> */}
                      <label className="w-full">
                        Thời gian hoàn thành&nbsp;
                        {isAutoRequiredUseHours && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                        &nbsp;
                        {isProtractedTask && !isOKTotalTimeByIndex(0) ? (
                          <span className="font-medium text-red-500 text-xs">
                            Tổng thời hoàn thành phải bằng tổng thời hoàn thành
                            theo mỗi tháng.
                          </span>
                        ) : null}
                      </label>
                    </div>

                    <div className="w-full h-max flex gap-3">
                      {/* <div className="w-1/3 h-max">
                        <label>
                          Số ngày
                        </label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số ngày"
                          type="number"
                          value={useHours ? useHours : ""}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div> */}
                      <div className="w-full h-max ">
                        <label>Số giờ hoàn thành</label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số giờ"
                          type="number"
                          value={useHours ? useHours : ""}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div>
                      <div className="w-full h-max">
                        <label>Số phút hoàn thành</label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số phút"
                          type="number"
                          value={useMinutes ? useMinutes : ""}
                          onChange={(e: any) => _onChangeMinutes(e)}
                        />
                      </div>
                    </div>
                    {isProtractedTask && (
                      <div className="w-full h-max flex gap-3 mt-2">
                        <table className="w-full">
                          <thead className="text-white font-semibold text-center w-full h-[2rem]">
                            <tr>
                              <th className="border border-slate-300 text-left">
                                Tháng/Năm
                              </th>
                              <th className="border border-slate-300">
                                Số giờ hoàn thành
                              </th>
                              <th className="border border-slate-300">
                                Số phút hoàn thành
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                            {taskTimeUsed?.map(
                              (item: any, index: any) =>
                                item.index === 0 && (
                                  <tr
                                    key={index}
                                    className="w-max h-[3rem] bg-white"
                                  >
                                    <td className="text-left border border-slate-300">
                                      <div className="flex w-max h-max space-x-1 font-medium mr-2">
                                        <p>
                                          {item?.Month}/{item?.Year}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="text-center border border-slate-300">
                                      <input
                                        className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                        placeholder="Nhập số giờ"
                                        type="number"
                                        // value={item?.HoursUsed}
                                        value={getValueTime(0, true, index)}
                                        onChange={(e) =>
                                          _onChangeTaskTimeUsed(
                                            e,
                                            0,
                                            true,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="text-center border border-slate-300">
                                      <input
                                        className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                        placeholder="Nhập số phút"
                                        type="number"
                                        value={getValueTime(0, false, index)}
                                        onChange={(e) =>
                                          _onChangeTaskTimeUsed(
                                            e,
                                            0,
                                            false,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full space-y-2">
                  <div className="flex w-full items-center">
                    <input
                      id="evalutionChoose"
                      className="mr-2 w-5 h-5"
                      type="checkbox"
                      checked={isAskingBoss}
                      onChange={_onChangeIsChooseTaskReviewer}
                    />
                    <label
                      className="hover:cursor-pointer text-lg"
                      htmlFor="evalutionChoose"
                    >
                      Xin ý kiến cấp trên
                    </label>
                  </div>
                  {isAskingBoss && (
                    <>
                      <div className="flex w-full space-x-3">
                        <div className="w-full space-y-1">
                          <label>
                            Cấp trên&nbsp;
                            <span className="text-red-500">(*)</span>
                          </label>
                          <Select
                            className="z-[5]"
                            options={listBoss}
                            onChange={(e: any) => _onChangeBoss(e)}
                            value={bossAsking}
                            isClearable
                            placeholder="--- Chọn cấp trên ---"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />

                        </div>
                      </div>
                      <div className="flex flex-col w-full h-max">
                        <label>Nội dung</label>
                        <TextareaControl
                          placeholder="Nhập nội dung"
                          value={askingContent}
                          onChange={(e: any) => _onChangeAskingContent(e)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full h-max space-y-2">
                <QuytrinhJSX />
              </div>
              <div className="flex w-full h-max">
                {/* buoc */}
                <StepListJSX />

                {/* ket qua */}
                <div
                  className={`
              w-1/3 h-max pl-3
              ${selectedKetQuaList === null ? "flex flex-col" : "flex flex-col"}
            `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">STT</th>
                        <th className="border border-slate-300 ">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          {/* <button
                            className="
                              flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                              rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                            "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item?.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button
                                className="text-red-500"
                                onClick={() => _onDeleteKetqua(item?.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <div className="my-4 w-full h-max justify-end items-end flex">
                    <button
                      className="
                        bg-emerald-500 p-1.5 text-white rounded-md
                        hover:bg-emerald-400
                      "
                      onClick={() => _onClickEditKetqua("editKetqua")}
                    >
                      Thêm kết quả
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Phone detail */}
            <div className="lg:hidden space-y-1.5">
              {/* <div className="w-full h-max space-y-0.5">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>
                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="--- Chọn đầu mục ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempDauViecList?.find(
                    (item) => item?.Id === selectedDauViec?.Id
                  )}
                />
              </div> */}
              <DauMucViecJSX />
              <div className="w-full h-max space-y-0.5">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="w-full h-max space-y-0.5">
                <label>
                  Loại công việc/dịch vụ/tư vấn&nbsp;
                  {isAutoLoaiViec && <span className="text-red-500">(*)</span>}
                </label>
                <select
                  className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200"
                  value={
                    selectedLoaiViec === -1 ? -1 : selectedLoaiViec.ParameterId
                  }
                  onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    Chọn loại công việc
                  </option>
                  {loaiViecList === null ? (
                    <></>
                  ) : (
                    loaiViecList.map((item: any, index: any) => (
                      <option key={index} value={item?.ParameterId}>
                        {item?.ParameterValue}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full h-max space-x-1 core-control-group">
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Mức độ ưu tiên&nbsp;
                    {isAutoPriority && (
                      <span className={`text-red-500`}>(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                      w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                    value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                    onChange={(e: any) => _onChangeSelectedUuTien(e)}
                    style={{
                      color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                      borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn mức độ
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option
                          key={index}
                          value={item?.Id}
                          style={{ color: `${item?.Color}` }}
                        >
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Cơ sở căn cứ bắt đầu công việc&nbsp;
                    {isAutoOrigin && <span className="text-red-500">(*)</span>}
                  </label>
                  {/* <select
                    className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500"
                    value={
                      selectedNguonVanBan === -1
                        ? -1
                        : selectedNguonVanBan?.ParameterId
                    }
                    onChange={(e: any) => _onChangeNguonVanBan(e)}
                  >
                    <option value={-1} disabled>
                      Chọn căn cứ
                    </option>
                    {nguonVanBanList === null ? (
                      <></>
                    ) : (
                      nguonVanBanList.map((item: any, index: any) => (
                        <option key={index} value={item?.ParameterId}>
                          {item?.ParameterValue}
                        </option>
                      ))
                    )}
                  </select> */}
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập căn cứ"
                    value={selectedNguonVanBan}
                    onChange={(e: any) => _onChangeNewNguonVanBan(e)}
                  />
                </div>
              </div>

              <div className="flex w-full h-max space-x-1 core-control-group">
                <div className={`w-full flex-col h-max space-y-0.5`}>
                  <label>
                    Độ mật&nbsp;
                    {isAutoSecurity && (
                      <span className={`text-red-500`}>(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : "font-bold"
                      }
                  `}
                    value={
                      selectedDoMat === -1 || selectedDoMat === null
                        ? -1
                        : selectedDoMat.Id
                    }
                    onChange={(e: any) => _onChangeDoMat(e)}
                    style={{
                      color: `${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : selectedDoMat.Color
                        }`,
                      borderColor: `${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : selectedDoMat.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ mật
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item?.Color}` }}
                          key={index}
                          value={item?.Id}
                        >
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Độ khó&nbsp;
                    {isAutoDifference && (
                      <span className="text-red-500">(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoKho === -1 ||
                        selectedDoKho === null ||
                        selectedDoKho === 0 ||
                        selectedDoKho === undefined
                        ? -1
                        : selectedDoKho.Id
                    }
                    onChange={(e: any) => _onChangeDoKho(e)}
                    style={{
                      color: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                      borderColor: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ khó
                    </option>
                    {doKhoList === null ? (
                      <></>
                    ) : (
                      doKhoList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item?.Color}` }}
                          key={index}
                          value={item?.Id}
                        >
                          {item?.DifficultLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="w-full h-max">
                <div className="w-full h-max">
                  {/* <label>Mã tham chiếu công việc</label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập mã"
                        value={refCode}
                        onChange={(e: any) => _onChangeRefCode(e)}
                      /> */}
                  <label className="w-full">
                    Thời gian hoàn thành&nbsp;
                    {isAutoRequiredUseHours && (
                      <span className={`text-red-500`}>(*)</span>
                    )}
                    {isProtractedTask && !isOKTotalTimeByIndex(0) ? (
                      <span className="font-medium text-red-500 text-xs">
                        Tổng thời hoàn thành phải bằng tổng thời hoàn thành theo
                        mỗi tháng.
                      </span>
                    ) : null}
                  </label>
                </div>

                <div className="w-full h-max flex gap-3">
                  {/* <div className="w-1/3 h-max">
                        <label>
                          Số ngày
                        </label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số ngày"
                          type="number"
                          value={useHours ? useHours : ""}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div> */}
                  <div className="w-full h-max ">
                    <label>Số giờ hoàn thành</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập số giờ"
                      type="number"
                      value={useHours ? useHours : ""}
                      onChange={(e: any) => _onChangeHours(e)}
                    />
                  </div>
                  <div className="w-full h-max">
                    <label>Số phút hoàn thành</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập số phút"
                      type="number"
                      value={useMinutes ? useMinutes : ""}
                      onChange={(e: any) => _onChangeMinutes(e)}
                    />
                  </div>
                </div>
                {isProtractedTask && (
                  <div className="w-full h-max flex gap-3 mt-2">
                    <table className="w-full">
                      <thead className="text-white font-semibold text-center w-full h-[2rem]">
                        <tr>
                          <th className="border border-slate-300 text-left">
                            Tháng/Năm
                          </th>
                          <th className="border border-slate-300">
                            Số giờ hoàn thành
                          </th>
                          <th className="border border-slate-300">
                            Số phút hoàn thành
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                        {taskTimeUsed?.map(
                          (item: any, index: any) =>
                            item.index === 0 && (
                              <tr
                                key={index}
                                className="w-max h-[3rem] bg-white"
                              >
                                <td className="text-left border border-slate-300">
                                  <div className="flex w-max h-max space-x-1 font-medium mr-2">
                                    <p>
                                      {item?.Month}/{item?.Year}
                                    </p>
                                  </div>
                                </td>
                                <td className="text-center border border-slate-300">
                                  <input
                                    className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                    placeholder="Nhập số giờ"
                                    type="number"
                                    // value={item?.HoursUsed}
                                    value={getValueTime(0, true, index)}
                                    onChange={(e) =>
                                      _onChangeTaskTimeUsed(e, 0, true, index)
                                    }
                                  />
                                </td>
                                <td className="text-center border border-slate-300">
                                  <input
                                    className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                    placeholder="Nhập số phút"
                                    type="number"
                                    value={getValueTime(0, false, index)}
                                    onChange={(e) =>
                                      _onChangeTaskTimeUsed(e, 0, false, index)
                                    }
                                  />
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {/* <DateTimePickerControl
                className={"width-100-percent padding-right-0"}
                label={"Thời hạn hoàn thành (*)"}
                value={deadLine}
                onChange={(e: any) => {
                  const value = e;
                  _onChangeDeadLine2(value);
                }}
              /> */}
              <AssignmentDateJSX />
              <DeadLineJSX />

              {/* <div className="flex w-full h-max">
                <div className="flex w-full h-max space-x-3">
                  <div className="w-full h-max">
                    <label>
                      Thời hạn hoàn thành&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      type="datetime-local"
                      value={deadLine}
                      onChange={(e: any) => _onChangeDeadLine(e)}
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="flex w-full items-center">
                <input
                  className="mr-2 w-5 h-5"
                  type="checkbox"
                  checked={isAskingBoss}
                  onChange={_onChangeIsChooseTaskReviewer}
                />
                <label
                  className="hover:cursor-pointer"
                  htmlFor="evalutionChoose"
                >
                  Xin ý kiến cấp trên
                </label>
              </div>
              {isAskingBoss && (
                <>

                  <div className="w-full space-y-0.5">
                    <label>
                      Cấp trên&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      value={bossAsking?.Id}
                      onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                    >
                      <option value={bossAsking?.Id} disabled>
                        {bossAsking?.FullName}
                      </option>
                      {selectedNguoiDanhGiaList === null ? (
                        <></>
                      ) : (
                        selectedNguoiDanhGiaList.map(
                          (item: any, index: any) => (
                            <option key={index} value={item?.Id}>
                              {item?.FullName}
                            </option>
                          )
                        )
                      )}
                    </select>
                  </div>
                  <div className="flex flex-col w-full h-max">
                    <label>Nội dung</label>

                    <TextareaControl
                      placeholder="Nhập nội dung"
                      value={askingContent}
                      onChange={(e: any) => _onChangeAskingContent(e)}
                    />
                  </div>
                </>
              )} */}

              {/* <div className="w-full h-max space-y-0.5">
                <label>Mã tham chiếu công việc</label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mã"
                  value={refCode}
                  onChange={(e: any) => _onChangeRefCode(e)}
                />
              </div> */}

              <div className="flex flex-col w-full h-max space-y-0.5">
                <QuytrinhJSX />
              </div>

              <div className="flex w-full h-max my-1.5">
                {/* buoc */}
                <StepListJSX />
              </div>
              <div className="flex w-full h-max my-1.5">
                {/* ket qua */}
                <div
                  className={` w-full h-max ${selectedKetQuaList === null
                    ? "flex flex-col"
                    : "flex flex-col"
                    }`}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">STT</th>
                        <th className="border border-slate-300 w-full">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          {/* <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item?.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button
                                className="text-red-500"
                                onClick={() => _onDeleteKetqua(item?.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <div className="my-4 w-full h-max justify-end items-end flex">
                    <button
                      className="
                        bg-emerald-500 p-1.5 text-white rounded-md
                        hover:bg-emerald-400
                      "
                      onClick={() => _onClickEditKetqua("editKetqua")}
                    >
                      Thêm kết quả
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>

          <div className="w-full h-max space-y-4">
            {/* elements */}
            <div className="flex flex-col w-full h-max">
              <label>Mô tả/ Hướng dẫn cách làm</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập mô tả"
                value={description}
                onChange={(e: any) => _onChangeDescription(e)}
              />
              {/* <TextareaControl
                placeholder="Nhập mô tả"
                value={description}
                onChange={(e: any) => _onChangeDescription(e)}
              /> */}
            </div>
            <div className="flex flex-col w-full h-max">
              <label>Ghi chú</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập ghi chú"
                value={notes}
                onChange={(e: any) => _onChangeNotes(e)}
              />
              {/* <TextareaControl
                placeholder="Nhập ghi chú"
                value={notes}
                onChange={(e: any) => _onChangeNotes(e)}
              /> */}
            </div>
            <div className="flex w-full h-max">
              {/* elements */}
              <div className="flex flex-col w-1/2 h-max">
                <div className="flex w-max h-full space-x-2 text-xs">
                  <button
                    className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                    onClick={_onClickChooseFileChat}
                  >
                    <AttachFileIcon fontSize="small" />
                    {/* <p>Chọn tệp tin</p> */}
                    <p>Chọn tệp tin đính kèm</p>
                  </button>
                </div>
                <input
                  ref={uploadFileChat}
                  className={`w-max h-max`}
                  multiple
                  type="file"
                  onChange={(e: any) => _onChangeFileImage(e)}
                  hidden
                />
                {/* fields of file */}
                <div className="flex flex-col w-full h-max">
                  {/* element */}
                  <div className="flex w-full h-max text-primary items-center justify-between">
                    <div className="flex flex-col w-full h-max">
                      {filesList.length === 0 ||
                        typeof filesList === "undefined" ? (
                        <></>
                      ) : (
                        filesList.map((item: any, index: any) => (
                          <div key={index} className="flex w-full h-max">
                            <div className="flex w-full h-max space-x-1">
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item?.name}</p>

                              <div className="w-max h-max flex space-x-6">
                                <button
                                  className="text-green-500 w-1/6 flex"
                                  onClick={() =>
                                    _onClickEditFile(
                                      index,
                                      filesList,
                                      setFilesList
                                    )
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </button>

                                <button
                                  className="text-red-500 w-1/6 flex"
                                  onClick={() => _onDeleteElements(index)}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div
        style={{
          width: "100%",
          // overflowX: "auto",
        }}
        className={`footer
        flex w-full p-12-16 rounded-b-md pl-4 pr-4 text-sm space-x-4
        ${selectedPhongBan === -1 ||
            selectedNguoiNhan === -1 ||
            (isAutoLoaiViec && selectedLoaiViec === -1) ||
            selectedNguonVanBan === -1 ||
            selectedUuTien === -1 ||
            selectedDoMat === -1 ||
            selectedDauViec === -1 ||
            // selectedQuyTrinh === -1 ||
            selectedDoKho === -1 ||
            deadLine === "" ||
            isLoading === true
            ? "justify-between"
            : "justify-end"
          }
        items-center
      `}
      >
        <div
          className={`
          items-center space-x-1 w-full h-max text-white rounded-md
          ${selectedPhongBan === -1 ||
              selectedNguoiNhan === -1 ||
              (isAutoLoaiViec && selectedLoaiViec === -1) ||
              selectedNguonVanBan === -1 ||
              selectedUuTien === -1 ||
              selectedDoMat === -1 ||
              selectedDauViec === -1 ||
              // selectedQuyTrinh === -1 ||
              selectedDoKho === -1 ||
              deadLine === "" ||
              isLoading === true
              ? "flex"
              : "hidden"
            }
        `}
        >
          {/* <p className="text-red-500 font-semibold text-xs">
            Vui lòng nhập đầy đủ thông tin công việc
          </p> */}
        </div>

        <div className="flex w-max h-max space-x-3">
          <div className="w-max h-max multiple">
            {/* button options */}
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md core-button
              ${(isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  selectedDauViec === -1 ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  deadLine === "" ||
                  taskName === "" ||
                  (isAutoRequiredUseHours &&
                    (useHours
                      ? useHours === 0 || !useHours
                      : useMinutes === 0 || !useMinutes)) ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  (isAskingBoss && (!bossAsking || bossAsking == null)) ||
                  (isProtractedTask && isOKTotalTimeByIndex(0) === false)
                  ? "bg-slate-300"
                  : "bg-primary hover:bg-sky-400"
                }
            `}
              onClick={() => _onClickSubmit(false)}
              disabled={
                (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  selectedDauViec === -1 ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  deadLine === "" ||
                  taskName === "" ||
                  (isAutoRequiredUseHours &&
                    (useHours
                      ? useHours === 0 || !useHours
                      : useMinutes === 0 || !useMinutes)) ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  (isAskingBoss && (!bossAsking || bossAsking == null)) ||
                  (isProtractedTask && isOKTotalTimeByIndex(0) === false)
                  ? true
                  : false
              }
            >
              <AssignmentIcon fontSize="small" />
              <p>Xác nhận</p>
            </button>
          </div>

          <button
            onClick={() => handleClose()}
            className="
          hidden lg:flex md:flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200 core"
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTaskMyself;
