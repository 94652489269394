import React, { useContext } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { ModalController } from '../../../ui/homeUI/_index';

const ShowStaffOrganization = (props: any) => {
    const context = useContext(ModalController);

    const users = (context.dataModal || {}).users || [];
    const item = (context.dataModal || {}).item || {};

    console.log(props);
    console.log(context);

    const onCloseModal = () => {
        if (props.handleClose) {
            props.handleClose();
        }
    }

    const renderUser = () => {
        return users.map((item: any, index: any) => {
            return <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">{index + 1}</td>
                <td className="border border-slate-300">{item.FullName}</td>
            </tr>
        });
    }

    return <div className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen h-[35rem]
        rounded-md
    ">
        {/* header */}
        <div
            className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
      "
        >
            <p>Danh sách nhân viên - Phòng ban {item.ParameterValue}</p>
            <button
                className="hover:bg-slate-300 p-0.5 rounded-md"
                onClick={onCloseModal}
            >
                <CloseIcon />
            </button>
        </div>
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
            {/* {renderUser()} */}
            <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                        <th className="border-l-[1px] border-slate-300">STT</th>
                        <th className="border-l-[1px] border-slate-300">
                            Tên nhân viên
                        </th>
                    </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {renderUser()}
                </tbody>
            </table>
        </div>
        <div
            className="footer
        flex w-full p-12-16 rounded-b-md pl-4 pr-4 text-sm space-x-4 items-center justify-end">
            <div className="flex w-max h-max space-x-3">
                <button
                    className="
          hidden lg:flex md:flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
                    onClick={onCloseModal}
                >
                    <ExitToAppTwoToneIcon fontSize="small" />
                    <p>Đóng</p>
                </button>
            </div>
        </div>
    </div>;
}

export default ShowStaffOrganization;