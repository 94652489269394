import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";

import {
  ONCHANGE_QUYTRINH_KETQUA,
  ONCHANGE_DELETE_QUYTRINH_KETQUA,
  ONPUSH_KETQUA,
} from "../../../redux/reducers/workingReducer/_index";

import { onTouchForm } from "../../../funcs/onTochForm";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const EditKetqua = (props: any) => {
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  const selectedKetQuaList = props.selectedKetQuaList;
  const _onChangeKetqua = props._onChangeKetqua;
  const _onPushKetqua = props._onPushKetqua;
  const _onDeleteKetqua = props._onDeleteKetqua;
  const _onChangeStepList = props._onChangeStepList;
  const ketqua = props.ketqua;

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [quytrinhName, setQuytrinhName] = useState<any>("");
  const [stepList, setStepList] = useState<any[] | null>(null);
  const [resultList, setResultList] = useState<any[] | null>(null);

  const [newNameKetqua, setNewNameStep] = useState<string | null>(null);

  const _onClickPushKetqua = () => {
    const body = {
      tenketqua: newNameKetqua,
      idquytrinh: taskId,
    };

    dispatch(ONPUSH_KETQUA(body));
  };

  // funcs

  return (
    <div
      className="
      lg:w-[40rem] w-screen h-[37rem] bg-white rounded-md
      flex flex-col
    "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

    "
      >
        <p>Sửa kết quả</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div
        className="
        flex flex-col p-4 w-full h-[calc(100%-6rem)]
        text-sm
        space-y-3
      "
      >
        <div className="flex flex-col w-full h-max">
          <label>Nội dung kết quả</label>
          <div className="flex w-full h-max justify-between mt-1 gap-5">
            <textarea
              className="w-full h-[4rem] rounded-md px-2 py-1 border-2 border-slate-200"
              placeholder="Nhập nội dung kết quả"
              value={ketqua}
              onChange={(e: any) => _onChangeKetqua(e)}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
            <div className="flex items-center justify-end w-max h-max">
              {/* <label className="text-white">Cập nhật</label> */}
              <button
                className="flex p-2  bg-green-500 text-white rounded-md hover:bg-green-400"
                onClick={() => {
                  if (ketqua) _onPushKetqua();
                  else {
                    toast.warning("Vui lòng nhập nội dung kết quả!");
                  }
                }}
              >
                <AddIcon fontSize="small" />
                <p>Thêm</p>
              </button>
            </div>
          </div>
        </div>

        {/* here */}
        <div className="tableModal styled-scrollbars w-full h-[20rem] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">
                  Nội dung kết quả
                </th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">
                  Xoá
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedKetQuaList === null ? (
                <></>
              ) : (
                selectedKetQuaList.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[2rem] bg-white">
                    <td className="text-center border border-slate-300">
                      <p>{index + 1}</p>
                    </td>
                    <td className="text-center border border-slate-300">
                      <textarea
                        className="w-full h-max rounded-md pl-2 pt-1 pb-1 pr-2 border-2 border-slate-200 text-black"
                        value={item.tenketqua}
                        onChange={(e: any) => {
                          _onChangeStepList("result", e.target.value, index);
                        }}
                        onTouchStart={(e: any) => onTouchForm(e)}
                      />
                    </td>
                    <td className="text-center border border-slate-300">
                      <div className="flex justify-center items-center w-full h-full">
                        <button
                          className="text-red-500 w-max h-max flex text-center hover:text-red-300 p-2"
                          onClick={() => _onDeleteKetqua(item.tenketqua)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
        flex items-center justify-end w-full p-12-16 footer rounded-b-md space-x-4 text-sm
      "
      >
        <button
          className="flex bg-green-500 text-white p-2 rounded-md hover:bg-green-400"
          onClick={handleClose}
        >
          <DoneIcon fontSize="small" />
          <p>Xác nhận</p>
        </button>
        <button
          className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
          onClick={handleClose}
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

export default EditKetqua;
