import React from "react";

// import components
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";
import ContentBottom from "../../../../components/contentBottom";
import Table from "../../../../components/table";

import "../../_index.css";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Line } from 'react-chartjs-2';

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const TaskGeneralDeliverUI = () => {
  return (
    <div className="flex flex-col w-full h-full">
      {/* title */}
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] pl-5 pr-5 text-sm">
        {/* top */}
        <div className="flex w-full h-1/3">
          {/* element */}
          <div className="flex lg:w-1/3 w-full lg:h-full h-[20rem] p-3">
            <div
              className="
                  flex flex-col w-full h-full shadow-md rounded-sm bg-white
                "
            >
              <div className="w-full h-full flex flex-col">
                <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                  TÌNH HÌNH CÔNG VIỆC TRONG THÁNG
                </div>
                <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                  <Line
                    className="w-full h-full"
                    data={{
                      labels: ['Chưa nhận', 'Mới tiếp nhận', 'Đang xử lý', "Đúng hạn", "Trễ hạn"],
                      datasets: [{
                        label: 'Tỉ lệ',
                        data: [30, 30, 15, 15, 10],
                        backgroundColor: [
                          '#E2E2E2',
                          '#76CCF8',
                          '#00A516',
                          '#0794FF',
                          '#FF0000'
                        ]
                      }]
                    }}
                    options={{
                      plugins: {
                        datalabels: {
                          color: 'black',
                          font: {
                            size: 12,
                            weight: 'bold',
                          },
                          formatter: (value: any) => `${value}%`,
                          clamp: true,
                          anchor: "center",
                          align: "top",
                        },
                        // legend: {
                        //   position: "right",
                        //   labels: {
                        //     usePointStyle: true,
                        //     font: {
                        //       size: 10
                        //     }
                        //   },
                        // },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex lg:w-1/3 w-full lg:h-full h-[20rem] p-3">
            <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
              <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                TÌNH HÌNH CÔNG VIỆC TRONG THÁNG
              </div>
              <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                <Bar
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: ['Nguyễn A', 'Lê B', 'Trần C', "Cao D"],
                    datasets: [
                      {
                        label: 'Hoàn thành',
                        data: [10, 12, 15, 9],
                        backgroundColor: [
                          '#0ea5e9',
                        ]
                      },
                      {
                        label: 'Tổng',
                        data: [15, 20, 20, 30],
                        backgroundColor: [
                          '#fb923c',
                        ]
                      },
                    ]
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: 'black',
                        font: {
                          size: 13,
                          weight: 'bold',
                        },
                        formatter: (value: any) => `${value}`,
                        anchor: "end",
                        align: "start",
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex lg:w-1/3 w-full lg:h-full h-[20rem] p-3">
            <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white overflow-auto">
              <div className="flex items-center font-semibold pl-2 rounded-t-sm pr-2 w-full h-[2rem] bg-primary text-white">
                CÔNG VIỆC XỬ LÝ THÁNG HIỆN TẠI
              </div>

              <div className="
                  flex flex-col w-full h-[calc(100%-2rem)] overflow-y-auto divide-y-2 styled-scrollbars
                  text-sm
                ">
                <div className="flex justify-between items-center p-2 w-full h-max ">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
                <div className="flex justify-between items-center p-2 w-full h-max">
                  <p>Tên công việc</p>
                  <p>30 việc</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* bottom */}
        <div className="flex flex-col w-full h-2/3 pt-3 pb-3 border-t-[1px] border-primary">
          <SubmitHeader />

          <div className="flex flex-col w-full lg:h-[8rem] h-max space-y-3">
            {/* top */}
            <ContentTop />

            {/* bottom */}
            <ContentBottom />
          </div>

          <Table />
        </div>
      </div>
    </div>
  )
}

export default TaskGeneralDeliverUI;
