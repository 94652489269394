import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  ON_LEAVE_UPDATE,
  ON_HOLIDAY_DETAIL,
  ON_HOLIDAY_UPDATE,
  ON_HOLIDAY_DELETE,
} from "../../../apis/_index";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../_index";
import { toast } from "react-toastify";

import Select from "react-select";

import SubModal from "../_subModal/_index";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const DetailOnHoliday = (props: any) => {
  console.log("123123, ", props.taskId);
  // Props
  const workingData = useSelector((state: any) => state.workingReducer);
  const shouldLog = useRef(true);

  const taskId = props.taskId;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  // States
  const [id, setId] = useState<any>(undefined);
  const [dayStart, setDayStart] = useState<any>("");
  const [dayEnd, setDayEnd] = useState<any>("");
  const [timeOff, setTimeOff] = useState<any>("");
  const [reason, setReason] = useState<any>("");
  const [userAdd, setUserAdd] = useState<any | null>(null);

  // Const
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  // Funcs
  const loadDetail = () => {
    const API = ON_HOLIDAY_DETAIL + `?id=${taskId}`;

    const fetchData = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setId(res.data.Data.ID);
            setTimeOff(res.data.Data.HoursOff);
            setDayStart(res.data.Data.StartDate);
            setDayEnd(res.data.Data.EndDate);
            setReason(res.data.Data.Title);
            setUserAdd(res.data.Data.UserAdd);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData();
  };

  const _onClickDelete = async (id: string) => {
    setIsLoading(true);
    const API = ON_HOLIDAY_DELETE + `${id}`;

    try {
      await ServiceUtils.getV2(API, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.data.StatusCode === 200) {
          // toast.success("Xoá thành công");
          // context.dataModal.tempFuncs();
          // handleClose();
          // context.handleClose();
        } else {
          // toast.error(res.data.Message);
          // handleClose();
        }
      });
    } catch (error: any) {
      // toast.error(
      //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      // );
    } finally {
      setIsLoading(false);
    }
  };

  const _onClickUpdateLeaving = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (
      dayStart === "" ||
      dayEnd === "" ||
      timeOff === "" ||
      reason === ""
      // userAdd == null
    ) {
      toast.warning("Vui lòng nhập đầy đủ thông tin.");
      return;
    }

    const body: any = {
      ID: taskId,
      StartDate: dayStart,
      EndDate: dayEnd,
      HoursOff: timeOff,
      Title: reason,
      // UseId: userAdd,
    };

    const fetchData = async () => {
      setIsLoading(true);
      try {
        await ServiceUtils.postV2(ON_HOLIDAY_UPDATE, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            _onClickDelete(taskId);
            setIsLoading(false);
            context.dataModal.tempFuncs();
            toast.success("Cập nhật nghỉ phép thành công");
            handleClose();
            context.handleClose();
          } else {
            toast.warning(res.data.Message || "Lỗi không xác định.");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
        setIsLoading(false);
      }
    };

    fetchData();
  };

  // CRUD
  const _onClickConfirmDelete = () => {
    setFuncsSub("deleteHoliday");
    handleOpenSub();
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      loadDetail();
    }
  });

  const tempPerson = useMemo(() => {
    return tempReceiverList?.find((item: any) => item.Id === userAdd);
  }, [userAdd]);

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      <SubModal
        taskId={taskId}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />

      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết nghỉ lễ</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>
      {/* content */}
      {id === undefined || isloading === true ? (
        // <div className="relative flex justify-center items-center flex-col w-full h-[25rem] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="relative flex flex-col w-full h-max overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          <div className="space-y-4">
            <div className="flex items-center">
              <label className="w-1/4">Tiêu đề</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="text"
                value={reason}
                onChange={(e: any) => {
                  e.preventDefault();
                  setReason(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="w-1/4">Nghỉ từ</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="datetime-local"
                value={dayStart}
                onChange={(e: any) => {
                  e.preventDefault();
                  setDayStart(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="w-1/4">Nghỉ đến</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="datetime-local"
                value={dayEnd}
                onChange={(e: any) => {
                  e.preventDefault();
                  setDayEnd(e.target.value);
                }}
              />
            </div>

            <div className="flex items-center">
              <label className="w-1/4">Giờ nghỉ</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="number"
                placeholder="Nhập số giờ nghỉ"
                value={timeOff}
                onChange={(e: any) => {
                  e.preventDefault();
                  setTimeOff(e.target.value);
                }}
              />
            </div>

            <div className="flex items-center">
              <label className="w-1/4">Người tạo</label>
              <Select
                className="z-30 w-full"
                options={tempReceiverList}
                isDisabled
                isClearable
                placeholder="--- Chọn người nghỉ ---"
                value={tempPerson}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                // onChange={(data: any) => {
                //   if (data === null) {
                //     setUserAdd(null);
                //   } else {
                //     setUserAdd(data?.Id);
                //   }
                // }}
              />
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="w-full h-max flex justify-between items-center">
          <div className="w-max h-max flex">
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${
                isResult === false && result === ""
                  ? "bg-slate-300"
                  : "bg-red-500 hover:bg-red-400"
              }`}
              disabled={isResult === false && result === ""}
              onClick={_onClickConfirmDelete}
            >
              <DeleteIcon fontSize="small" />
              <p>Xoá</p>
            </button>
          </div>

          <div className="flex w-max h-max space-x-3">
            {isloading ? (
              <LoaderSpinner h={25} w={25} />
            ) : (
              <button
                className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${
                isResult === false && result === ""
                  ? "bg-slate-300"
                  : "bg-sky-500 hover:bg-sky-400"
              }`}
                disabled={isResult === false && result === ""}
                onClick={_onClickUpdateLeaving}
              >
                <EditIcon fontSize="small" />
                <p>Cập nhật</p>
              </button>
            )}

            <button
              className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailOnHoliday;
