import React from "react";

import NotificationsIcon from '@mui/icons-material/Notifications';

const AlertTask = (props: any) => {

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  return (
    <div className="
      flex bg-amber-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    ">
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <NotificationsIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý nhắc nhở công việc này?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button className="w-[5rem] h-max pt-1 pb-1 bg-slate-500 rounded-md hover:bg-slate-400">
            Có
          </button>
          <button className="w-[5rem] h-max pt-1 pb-1 bg-red-500 rounded-md hover:bg-red-400"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  )
}

export default AlertTask;
