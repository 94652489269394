import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const userReducer = createSlice({
  name: "userReducer",

  initialState: {
    groupList: null,
    groupListDropdown: null,

    functionList: null,
    functionChoose: 0,

    taskList: null,
    taskChoose: 0,

    screenList: null,
    screenChoose: 0,
  },

  reducers: {
    ONCHANGE_FUNCTIONCHOOSE: (state: any, actions: PayloadAction<any>) => {
      state.functionChoose = actions.payload;
    },

    ONLOAD_FUNCTIONLIST: (state: any, actions: PayloadAction<any>) => {
      state.functionList = actions.payload;
    },

    ONCHANGE_SCREENCHOOSE: (state: any, actions: PayloadAction<any>) => {
      state.screenChoose = actions.payload;
    },

    ONLOAD_SCREENLIST: (state: any, actions: PayloadAction<any>) => {
      state.screenList = actions.payload;
    },

    ONLOAD_GROUPLIST: (state: any, actions: PayloadAction<any>) => {
      state.groupList = actions.payload;
    },

    ONLOAD_GROUPLIST_DROPDOWN: (state: any, actions: PayloadAction<any>) => {
      state.groupListDropdown = actions.payload;
    }
  }
});

export const {
  ONCHANGE_FUNCTIONCHOOSE,
  ONLOAD_GROUPLIST_DROPDOWN,
  ONLOAD_FUNCTIONLIST,
  ONCHANGE_SCREENCHOOSE,
  ONLOAD_SCREENLIST,
  ONLOAD_GROUPLIST
} = userReducer.actions

export default userReducer.reducer;
