import { useState, useContext } from "react";
import { useSelector } from "react-redux";

import {
  ON_MEETING_CREATE,
} from "../../../apis/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AddIcon from "@mui/icons-material/Add";

import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../_index";
import { toast } from "react-toastify";

import Select from "react-select";
import ServiceUtils from "../../../utils/services";

const CreateMeeting = (props: any) => {
  console.log("hahahhaha");
  // Props
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  console.log(context);

  // States
  const [dayStart, setDayStart] = useState<any>("");
  const [dayEnd, setDayEnd] = useState<any>("");
  const [timeOff, setTimeOff] = useState<any>("");
  const [reason, setReason] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [person, setPerson] = useState<any | null>(null);

  const workingData = useSelector((state: any) => state.workingReducer);
  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  // Funcs
  const _onClickCreateMeeting = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (
      dayStart === "" ||
      dayEnd === "" ||
      timeOff === "" ||
      reason === "" ||
      !person
    ) {
      toast.warning("Vui lòng nhập đầy đủ thông tin.");
      return;
    }

    const body: any = {
      StartDate: dayStart,
      EndDate: dayEnd,
      HoursUsed: timeOff,
      Title: reason,
      Content: description,
      UserMeetings: person,
    };

    const fetchData = async () => {
      setIsLoading(true);

      try {
        await ServiceUtils.postV2(ON_MEETING_CREATE, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Tạo lịch họp thành công");
            context.dataModal?.tempFuncs();
            handleClose();
          } else {
            toast.error(res.data.Message || "Lỗi không xác định");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  };

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tạo mới lịch họp</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-max max-h-[80vh] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="space-y-4">
          <div className="flex items-center">
            <label className="w-1/4">Tiêu đề</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="text"
              value={reason}
              placeholder="Nhập tiêu đề"
              onChange={(e: any) => {
                e.preventDefault();
                setReason(e.target.value);
              }}
            />
          </div>
          <div className="flex items-center">
            <label className="w-1/4">Số giờ</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="number"
              placeholder="Nhập số giờ sử dụng"
              value={timeOff}
              onChange={(e: any) => {
                e.preventDefault();
                setTimeOff(e.target.value);
              }}
            />
          </div>
          <div className="flex items-center">
            <label className="w-1/4">Người tham gia</label>
            <Select
              className="z-30 w-full"
              options={tempReceiverList}
              isClearable
              isMulti
              placeholder="--- Chọn người tham gia ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onChange={(data: any) => {
                if (data === null) {
                  setPerson(null);
                } else {
                  const selectedIds = data.map((item: any) => item?.Id);
                  const personString = selectedIds.join(", ");
                  console.log("personString, ", personString);
                  setPerson(personString);
                }
              }}
            />
          </div>
          <div className="flex items-center">
            <label className="w-1/4">Từ ngày</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="datetime-local"
              value={dayStart}
              onChange={(e: any) => {
                e.preventDefault();
                setDayStart(e.target.value);
              }}
            />
          </div>
          <div className="flex items-center">
            <label className="w-1/4">Đến ngày</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="datetime-local"
              value={dayEnd}
              onChange={(e: any) => {
                e.preventDefault();
                setDayEnd(e.target.value);
              }}
            />
          </div>

          <div className="flex items-center">
            <label className="w-1/4">Nội dung</label>
            <textarea
              className="w-full h-[5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={description}
              placeholder="Nhập nội dung"
              onChange={(e: any) => {
                e.preventDefault();
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${
                isResult === false && result === ""
                  ? "bg-slate-300"
                  : "bg-green-500 hover:bg-green-400"
              }`}
              disabled={isResult === false && result === ""}
              onClick={_onClickCreateMeeting}
            >
              <AddIcon fontSize="small" />
              <p>Tạo mới</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateMeeting;
