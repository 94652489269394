import { Component } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { viVN } from "@mui/x-date-pickers/locales";
import "dayjs/locale/vi";
import { red } from "@mui/material/colors";

// import "react-widgets/styles.css";

// import DatePicker from "react-widgets/DatePicker";

let LOCAL_DEFAULT = "vi-vn";

class Configs {
  static format: string = "DD/MM/YYYY HH:mm";
}

const LABEL_MONTHS = [
  {
    values: ["Jan"],
    label: "1",
  },
  {
    values: ["Feb"],
    label: "2",
  },
  {
    values: ["Mar"],
    label: "3",
  },
  {
    values: ["Apr"],
    label: "4",
  },
  {
    values: ["May"],
    label: "5",
  },
  {
    values: ["Jun"],
    label: "6",
  },
  {
    values: ["Jul"],
    label: "7",
  },
  {
    values: ["Aug"],
    label: "8",
  },
  {
    values: ["Sep"],
    label: "9",
  },
  {
    values: ["Oct", "October"],
    label: "10",
  },
  {
    values: ["Nov"],
    label: "11",
  },
  {
    values: ["Dec"],
    label: "12",
  },
];

type Props = {
  className?: String;
  style?: {};
  locale?: String;
  label?: String;
  customLabelHTML?: any;
  value?: String;
  onChange?: Function;
  format?: string;
};
interface States {
  value?: Dayjs;
}

class DateTimePickerControl extends Component<Props, States> {
  constructor(props: any) {
    super(props);

    this.state = {
      value: undefined,
    };
  }

  public static defaultProps = {
    locale: LOCAL_DEFAULT,
    format: Configs.format,
  };

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      const handleValue = dayjs(moment(value.toString()).toDate());

      this.setState({
        value: handleValue,
      });

      // const handleValue = moment(value.toString()).toDate();

      // this.setState({
      //   value: handleValue,
      // });
    }
  }

  getLabelMonth = (value: any) => {
    const labelMonth = LABEL_MONTHS.find((p) =>
      p.values.find((p) => p == value)
    );

    return labelMonth ? labelMonth.label : value;
  };

  handleUIMonth = () => {
    const months = Array.from(
      document.querySelectorAll(
        ".MuiMonthCalendar-root .MuiPickersMonth-root button"
      )
    );

    for (let i = 0; i < months.length; i++) {
      months[i].textContent = this.getLabelMonth(months[i].textContent);
    }
  };

  handleUIHeader = () => {
    const header = document.querySelector(
      ".MuiPickersCalendarHeader-labelContainer .MuiPickersCalendarHeader-label"
    );

    if (header) {
      const headerTitle = header.textContent;

      const headerTitleSplit = headerTitle?.split(" ") || [];

      const headerTitleMonth = headerTitleSplit[0] || "";

      const headerTitleYear = headerTitleSplit[1] || "";

      const headerTitleLabelMonth = this.getLabelMonth(headerTitleMonth);

      if (headerTitleLabelMonth) {
        header.textContent = headerTitleLabelMonth + " " + headerTitleYear;
      }
    }
  };

  onOpen = () => {
    setTimeout(() => {
      this.handleUIHeader();

      const buttonSelectMonthAndYear = document.querySelector(
        ".MuiPickersCalendarHeader-switchViewButton"
      );

      if (buttonSelectMonthAndYear) {
        buttonSelectMonthAndYear.removeEventListener("click", () => {});
        buttonSelectMonthAndYear.addEventListener("click", () => {
          setTimeout(() => {
            this.handleUIMonth();
          }, 50);
        });
      }

      const header = document.querySelector(
        ".MuiPickersCalendarHeader-labelContainer .MuiPickersCalendarHeader-label"
      );

      if (header) {
        header.removeEventListener("click", () => {});
        header.addEventListener("click", () => {
          setTimeout(() => {
            this.handleUIMonth();
          }, 50);
        });
      }
    }, 50);
  };

  onBlur = () => {};

  handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle key press events for the input element
    console.log("Key pressed in input:", event.key);
  };

  onChange = (value: any) => {
    // let dateTime = undefined;

    // if (value) {
    //   dateTime = moment(value).toDate();
    // }

    // this.setState({
    //   value: dateTime
    // });

    // const { onChange } = this.props;

    // if (onChange) {
    //   let dateTime = null;

    //   if (value) {
    //     dateTime = moment(value)
    //       .format("YYYY-MM-DD HH:mm");
    //   }

    //   onChange(dateTime);
    // }

    // const { onChange } = this.props;

    // if (onChange) {
    //   let dateTime = undefined;

    //   if (value) {
    //     // dateTime = moment(value.$d)
    //     //   .utcOffset("+07:00")
    //     //   .format("YYYY-MM-DDTHH:mm");

    //     dateTime = dayjs(moment(value.$d)
    //       .utcOffset("+07:00").toDate());
    //   }

    //   this.setState({
    //     value: dateTime
    //   });

    //   // onChange(dateTime);
    // }
    let dateTime = undefined;

    if (value) {
      dateTime = dayjs(moment(value.$d).utcOffset("+07:00").toDate());
    }

    this.setState({
      value: dateTime,
    });
  };

  onAccept = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (onChange) {
      let dateTime = undefined;

      if (value) {
        const _value = value.toDate() || value;

        dateTime = dayjs(moment(_value).utcOffset("+07:00").toDate());
      }

      onChange(dateTime);
    }
  };

  onClose = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (onChange) {
      let dateTime = undefined;

      if (value) {
        const _value = value.toDate() || value;

        dateTime = dayjs(moment(_value).utcOffset("+07:00").toDate());
      }
      onChange(dateTime);
    }
  };

  render() {
    const { locale, label, className, style, customLabelHTML, format } =
      this.props;
    const { value } = this.state;

    const _format = format || Configs.format;

    // return <div
    //   style={style}
    //   className={`flex flex-col lg:w-1/3 w-full bg-white h-max core-datetimepicker-container ${className || ""
    //     }`}
    // >
    //   {label && <label className="bg-white">{label}</label>}
    //   {customLabelHTML && <div dangerouslySetInnerHTML={{ __html: customLabelHTML }} />}
    //   <DatePicker className="w-full h-[2.4rem] rounded-[4px] border-[1px] bg-white border-gray-400 core-datetimepicker-container-box" onChange={this.onChange} onBlur={this.onBlur} value={value} includeTime />
    // </div>
    // return <div
    //   style={style}
    //   className={`flex flex-col lg:w-1/3 w-full bg-white h-max core-datetimepicker-container ${className || ""
    //     }`}
    // >
    //   {label && <label className="bg-white">{label}</label>}
    //   {customLabelHTML && <div dangerouslySetInnerHTML={{ __html: customLabelHTML }} />}
    //   <DatePicker pop block placement="bottomStart" preventOverflow={true} value={value} onChange={this.onChange} className="core-datetimepicker-container-input" size={_size} format={_format} />
    // </div>

    return (
      <LocalizationProvider
        localeText={
          viVN.components.MuiLocalizationProvider.defaultProps.localeText
        }
        dateAdapter={AdapterDayjs}
        adapterLocale={locale || LOCAL_DEFAULT}
      >
        <div
          style={style}
          className={`flex flex-col  w-full bg-white h-max core-datetimepicker-container ${
            className || ""
          }`}
        >
          {label && <label className="bg-white">{label}</label>}
          {customLabelHTML && (
            <div dangerouslySetInnerHTML={{ __html: customLabelHTML }} />
          )}
          {/* <DatePicker className="core-datetimepicker-container-input" size={_size} format={_format} /> */}
          <DateTimePicker
            closeOnSelect={false}
            onOpen={this.onOpen}
            localeText={
              viVN.components.MuiLocalizationProvider.defaultProps.localeText
            }
            views={["month", "year", "day", "hours", "minutes"]}
            value={value}
            onChange={this.onChange}
            // onKeyPress={this.handleInputKeyPress}
            onAccept={this.onAccept}
            onClose={this.onClose}
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] bg-white border-gray-400 core-datetimepicker-container-box"
            format={_format}
          />
        </div>
      </LocalizationProvider>
    );
  }
}

export { LOCAL_DEFAULT };

export default DateTimePickerControl;
