import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import SubModal from "../_subModal/_index";

import {
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_READ_ALL,
} from "../../../apis/_index";
import { ONLOAD_GROUPLIST } from "../../../redux/reducers/userReducer/_index";
import { toast } from "react-toastify";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import { onTouchForm } from "../../../funcs/onTochForm";
import ServiceUtils from "../../../utils/services";

const CreateUser = (props: any) => {
  const dispatch = useDispatch();

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // state
  const [groupName, setGroupName] = useState<string>("");
  const [groupDescription, setGroupDescription] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);

  // funcs
  const _onChangeGroupName = (e: any) => {
    e.preventDefault();
    setGroupName(e.target.value);
  };

  const _onChangeGroupDescription = (e: any) => {
    e.preventDefault();
    setGroupDescription(e.target.value);
  };

  const _onChangeIsActive = () => {
    setIsActive((value: any) => !value);
  };

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      GroupName: groupName,
      GroupDescription: groupDescription,
      IsActive: isActive,
    };

    try {
      await ServiceUtils.postV2(GROUP_CREATE, body, {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          if (handleClose) {
            handleClose();
          }
          const takeData = async () => {
            await ServiceUtils.getV2(GROUP_READ_ALL, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_GROUPLIST(res.data.Data));
              }
            });
          };
          takeData();
          toast.success("Tạo mới nhóm quyền thành công.");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết quyền hạn</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên nhóm quyền&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={groupName}
              onChange={(e: any) => {
                _onChangeGroupName(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Mô tả&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
              value={groupDescription}
              onChange={(e: any) => {
                _onChangeGroupDescription(e);
              }}
            />
          </div>
        </div>

        <div className="flex items-center w-full space-x-6 h-max">
          <p className="font-bold text-primary">Hoạt động</p>
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={isActive}
            onChange={_onChangeIsActive}
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Tạo mới</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
