/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../components/title";
import Table from "../../../components/table";
import { GROUP_READ_ALL_DROPDOWN } from "../../../apis/_index";
import {
  ONLOAD_GROUPLIST_DROPDOWN,
  ONLOAD_FUNCTIONLIST,
  ONCHANGE_FUNCTIONCHOOSE,
} from "../../../redux/reducers/userReducer/_index";
import { SYS_PERMISSION, SYS_PERMISSION_LOADFUNC } from "../../../apis/_index";
import { toast } from "react-toastify";

import { ModalController } from "../../homeUI/_index";
import axios from "axios";

import Select from "react-select";
import ServiceUtils from "../../../utils/services";

const FunctionalListUI = () => {
  const data = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  const context = useContext(ModalController);

  const [selectedValue, setSelectedValue] = useState<any>(0);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(GROUP_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_GROUPLIST_DROPDOWN(res.data.Data));
            }
          });
        };

        takeData();
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  });

  useEffect(() => {
    if (shouldLog2.current) {
      shouldLog2.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(SYS_PERMISSION, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_FUNCTIONLIST(res.data.Data));
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  useEffect(() => {
    if (selectedValue === 0) {
      return;
    } else {
      const API = SYS_PERMISSION_LOADFUNC + data.functionChoose.toString();

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_FUNCTIONLIST(res.data.Data));
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  }, [selectedValue, dispatch]);

  const tempArray = data.groupListDropdown?.map((item: any) => {
    return { ...item, label: item.GroupName, value: item.GroupName };
  });

  // funcs
  const _onChangeData = (data: any) => {
    if (data === null) {
      setSelectedValue(0);
      dispatch(ONCHANGE_FUNCTIONCHOOSE(0));
    } else {
      setSelectedValue(data.ID);
      dispatch(ONCHANGE_FUNCTIONCHOOSE(data.ID));
    }
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-[100vh-3.5rem] lg:w-full h-[calc(100%-1.8rem)] text-sm p-3 lg:px-5 lg:py-3">
        <div className="flex w-full h-max justify-between space-x-5">
          <div className="flex flex-col w-full lg:w-1/3 pr-0 h-max">
            <label className="text-xs mb-1">Nhóm quyền</label>

            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={data.functionChoose}
              onChange={(e: any) => {
                e.preventDefault();
                setSelectedValue(parseInt(e.target.value))
                dispatch(ONCHANGE_FUNCTIONCHOOSE(parseInt(e.target.value)))
              }}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn nhóm quyền ---</option>
              {data.groupListDropdown === null ? ""
                : data.groupListDropdown.map((item: any, index: any) => (
                  <option key={index} value={item.ID}>{item.GroupName}</option>
                ))
              }
            </select> */}

            <Select
              className="z-[5]"
              options={tempArray}
              onChange={(data: any) => _onChangeData(data)}
              isClearable
              placeholder="--- Chọn người giao ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="w-2/3 h-max flex flex-col">
            {/* <label className="text-xs">Tìm kiếm</label>
            <input
              className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Tìm kiếm chức năng"
            /> */}
          </div>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default FunctionalListUI;
