import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
  ONCHANGE_TASK_WAITING,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";

import {
  NGUOI_NHAN_CHO_DUYET_TASK,
  USER_READ_ALL_DROPDOWN,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import { Checkcolor } from "../../../funcs/checkColor";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import HandleUI from "../../../utils/handleUI";

const WorkingTaskWaiting = () => {
  _onClickOther();

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const appData = useSelector((state: any) => state.appReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const shouldLog = useRef(true);

  const link = window.location.pathname;

  const _fetchData = (page: any, limit: any) => {
    readList(
      NGUOI_NHAN_CHO_DUYET_TASK,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      { current: true },
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      }
    );
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = e.target.value;
    dispatch(ONCHANGE_CHOOSEPAGE(value));
    dispatch(ONCHANGE_PAGINATION_TASKLIST(1));
  };

  const [first, setFirst] = useState(false);
  useEffect(() => {
    if (first) {
      _fetchData(workingData.pageCurrent, workingData.choosePage);
    } else {
      setFirst(true);
    }
  }, [workingData.choosePage, workingData.pageCurrent]);

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("readTotalTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickHistoryExtendWithId = (id: any) => {
    context.setFuncs("readHistoryExtend");
    context.setTaskId(id);
    context.handleOpen();
  };

  useEffect(() => {
    if (appData.userList === null && shouldLog.current) {
      shouldLog.current = false;

      // const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };
      takeData();
    }
  });

  return (
    <>
      {workingData.taskWaiting === null || appData.userList === null ? (
        // <div className="flex justify-center items-center flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.taskWaiting.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.taskWaiting.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div
            className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md"
            id="table"
          >
            <table className="">
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Loại</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "isRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.taskWaiting === null ||
                appData.userList === null ? (
                  <tr></tr>
                ) : (
                  workingData.taskWaiting.data?.map((item: any, index: any) => (
                    <tr className="w-max h-[3rem] bg-white">
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.IsRecipient ? (
                          <p>Thực hiện chính</p>
                        ) : (
                          <p>Hỗ trợ</p>
                        )}
                      </td>

                      {/* Mobile */}
                      <td className="border border-slate-300 space-y-0.5">
                        <p className="lg:hidden font-bold">
                          <span className="lg:hidden">
                            {index +
                              1 +
                              workingData.pageCurrent * workingData.choosePage}
                            .&nbsp;
                          </span>
                          {item.TaskName}
                        </p>
                        <p className="hidden lg:flex">{item.TaskName}</p>
                        <p className="lg:hidden">
                          <span className="text-xs"> Người giao: </span>
                          <span className="font-semibold">
                            {item.TaskOwnerName}
                          </span>
                        </p>
                        <p className="lg:hidden">
                          <span className="text-xs">Người nhận: </span>
                          <span className="font-semibold">
                            {item.RecipientName}
                          </span>
                        </p>
                        {item.SupporterName !== null && (
                          <p className="lg:hidden">
                            <span className="text-xs">Người hỗ trợ:&nbsp;</span>
                            <span className="text-slate-600">
                              {item.SupporterName}
                            </span>
                          </p>
                        )}
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">
                              Số giờ thực hiện:{" "}
                              <span className="italic">
                                {item.HoursUsed} giờ {item.MinutesUsed} phút
                              </span>
                            </span>
                          </p>
                        </div>
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">
                              Số giờ còn lại:{" "}
                              <span className="italic">
                                {item.Hours_in_month_remaining} giờ
                              </span>
                            </span>
                          </p>
                        </div>
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">Thời hạn: </span>
                            <span className="italic">
                              {moment(item.AssignmentDate).format(
                                "HH:mm DD/MM"
                              )}
                            </span>
                            &nbsp;-&nbsp;
                            <span className="italic">
                              {moment(item.DeadLine).format("HH:mm DD/MM")}
                            </span>
                          </p>
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold lg:hidden ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                        </div>

                        {item.TaskStatus === 3 ? (
                          <>
                            <p className="text-sky-500 font-semibold">
                              Chưa xử lý
                            </p>
                          </>
                        ) : null}

                        {/* mobile */}
                        <div className="lg:hidden flex justify-end items-center">
                          <div className="dropdown2">
                            {/* button options */}
                            <button
                              className="dropbtn2"
                              onClick={() => _onClickFuncs(index.toString())}
                            >
                              ...
                            </button>

                            {/* options fields */}
                            <div
                              id={index.toString()}
                              className="dropdown-content2 text-xs font-semibold"
                            >
                              {/* detail */}
                              <button
                                className="flex w-full h-full"
                                onClick={() => _onClickEditTaskWithId(item.ID)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickHistoryExtendWithId(item.ID)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <MoreTimeIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Lịch sử gia hạn</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      {/*End Mobile */}

                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <p
                          style={{
                            color: Checkcolor(item.PriorityLevelID, appData),
                          }}
                          className={`font-bold ${Checkcolor(
                            item.PriorityLevelID,
                            appData
                          )}`}
                        >
                          {item.PriorityLevelName}
                        </p>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-left border border-slate-300">
                        {item.TaskOwnerName}
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.RecipientName}
                        {item.SupporterName && (
                          <p className="text-xs">
                            <span className="text-slate-600">Hỗ trợ:</span>{" "}
                            {item.SupporterName}
                          </p>
                        )}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button
                            className="dropbtn"
                            onMouseEnter={() => {
                              HandleUI.hoverMenuContextScrollTable(
                                index,
                                workingData.taskWaiting.data.length
                              );
                            }}
                          >
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full"
                              onClick={() => _onClickEditTaskWithId(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                            <button
                              className="flex w-full h-full"
                              onClick={() =>
                                _onClickHistoryExtendWithId(item.ID)
                              }
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <MoreTimeIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Lịch sử gia hạn</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingTaskWaiting;
