import React, { memo, useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import {
  ONADD_SPREADRUM,
  ONCHANGE_NAVBAR_CLOSE_RESPONSIVE,
  ONSET_NAVBAR_BUTTON_AT_FIRST,
  ONINIT_SPREADRUM,
  ONMOVE_SPREADRUM,
} from "../../../redux/reducers/systemReducer/index";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { it } from "node:test";
import { group, log } from "console";
import { getUserID } from "../../../utils/users/userUtils";
import axios from "axios";
import { toast } from "react-toastify";
import DeviceUtils from "../../../utils/devices/deviceUtils";
import { _onClickFuncs } from "../../../funcs/onClickShowBtn";
import { ContextMenu } from "../../../components/fileComponent/components";
import { searchData } from "../../../components/fileComponent/utils/helper";
import {
  ONLOAD_FILELIST,
  ONLOAD_FOLDERLIST,
  ONSEARCHSTR,
} from "../../../redux/reducers/driveReducer/_index";
import SearchIcon from "@mui/icons-material/Search";
import { FOLDER_IDS } from "../../../constants/dataConstants";
import {
  ONCHANGE_RESET_FILTER,
  ONLOAD_RECEIVERLIST,
  ONSET_RETURN_PAGE_CURRENT,
} from "../../../redux/reducers/workingReducer/_index";
import { ONCHANGE_RESET_FILTER_PROPOSE1 } from "../../../redux/reducers/proposeReducer/_index";
import { ModalController } from "../../homeUI/_index";
import ServiceUtils from "../../../utils/services";
import {
  GET_CALENDAR_DATA,
  USER_READ_ALL_DROPDOWN,
} from "../../../apis/_index";
import {
  ONCHANGE_OPTIONLIST,
  ONLOAD_EVENTLIST,
  ONSELECT_USER,
} from "../../../redux/reducers/ScheduleReducer/_index";
import { loadReceiver } from "../../../funcs/taskNguoiGiaoViec";
const OptionList = () => {
  // redux
  const data = useSelector((state: any) => state.appReducer);
  const fafafa = useSelector((state: any) => state.systemReducer);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const urlLink = window.location.pathname;

  const [_groupName, set_groupName] = useState<string | "">("MenuNone");

  const searchInputRef = useRef<any>(null);

  const _onClickSearch = (e: any) => {
    e.preventDefault();
    const searchValue = searchInputRef.current?.value;
    if (searchValue.length >= 3) {
      navigate(`/drives/search`);
      // searchData(searchValue).then((res: any) => {
      //   dispatch(ONLOAD_FILELIST(res?.FileClouds));
      //   dispatch(ONLOAD_FOLDERLIST(res?.FolderClouds));
      // });
      dispatch(
        ONINIT_SPREADRUM({
          name: "Tìm kiếm",
          id: FOLDER_IDS.search,
          callBack: () => {
            navigate(`/drives/${FOLDER_IDS.search}`);
            dispatch(ONMOVE_SPREADRUM(FOLDER_IDS.search));
          },
        })
      );
      dispatch(ONSEARCHSTR(searchValue));
      dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
    } else {
      toast.error("Vui lòng nhập dài hơn 3 kí tự!");
    }
  };

  // funcs
  const _onChangeNavbarCloseResponsive = () => {
    dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
  };


  // tsx
  const WorkingTableOptions = () => {
    return <></>;
  };
  const CalendarNavSide = () => {
    // const dataRedux = useSelector((state: any) => state.scheduleReducer);
    const dropdownAddRef = useRef<any>(null);
    const [openDropdownAdd, setOpenDropdownAdd] = useState<boolean>(false);
    const context = useContext(ModalController);

    const [userList, setUserList] = useState<any>([]);
    // const [selectedUser, setSelectedUser] = useState<any>(null);
    const { fromDate, toDate, optionList, selectedUser } = useSelector(
      (state: any) => state.scheduleReducer
    );
    // funcs

    const processedEvents = (event: any) => {
      return {
        event_id: event.eventId,
        title: event.title,
        start: new Date(event.start),
        end: new Date(event.end),
        type: event.type,
      };
    };

    const isCheckAll = () => {
      return optionList.every((option: any) => option.checked);
    };

    const fetchScheduleData = async () => {
      let text = "&";

      if (isCheckAll()) {
        text += "type=0";
      } else {
        optionList?.forEach((item: any) => {
          if (item.checked) {
            text += "type=" + item.value + "&";
          }
        });
        text = text.slice(0, -1);
      }

      let text2 = "";
      if (selectedUser?.value) {
        text2 = "&employee=" + selectedUser.value;
      } else {
        text2 = "";
      }

      let url = `${GET_CALENDAR_DATA}?start=${fromDate}&end=${toDate}${text}${text2}`;

      const res = await ServiceUtils.getV2(url);
      try {
        if (res.data.StatusCode === 200) {
          const data = res.data.Data;
          const processedEventsArray = data.map((event: any) =>
            processedEvents(event)
          );
          dispatch(ONLOAD_EVENTLIST(processedEventsArray));
        }
      } catch { }
    };
    const _handleClickOutside = (event: any) => {
      if (
        dropdownAddRef.current &&
        !dropdownAddRef.current.contains(event.target)
      ) {
        setOpenDropdownAdd(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", _handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", _handleClickOutside);
      };
    }, []);

    const _onClickCreateNewTask = () => {
      context.setFuncs("createNewTask");
      context.setDataModal({
        tempFuncs: fetchScheduleData,
      });
      context.handleOpen();
    };
    const _onClickDayOff = () => {
      context.setFuncs("createNewLeaving");
      context.setDataModal({
        tempFuncs: fetchScheduleData,
      });
      context.handleOpen();
    };
    const _onClickHoliday = () => {
      context.setFuncs("createNewHoliday");
      context.setDataModal({
        tempFuncs: fetchScheduleData,
      });
      context.handleOpen();
    };
    const _onClickCreateBusinessTrip = () => {
      context.setFuncs("createBusinessTrip");
      context.setDataModal({
        tempFuncs: fetchScheduleData,
      });
      context.handleOpen();
    };
    const _onClickCreateMeeting = () => {
      context.setFuncs("createMetting");
      context.setDataModal({
        tempFuncs: fetchScheduleData,
      });
      context.handleOpen();
    };
    const shouldLog1 = useRef(true);
    useEffect(() => {
      loadReceiver(
        (res: any) => dispatch(ONLOAD_RECEIVERLIST(res.data.Data)),
        shouldLog1
      );
    }, []);
    useEffect(() => {
      const fetchUser = async () => {
        try {
          const res = await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN);
          if (res.data.StatusCode === 200) {
            const users = res.data.Data;
            const convertedUserOptions = users.map((user: any) => ({
              label: user.FullName,
              value: user.Id,
            }));
            setUserList(convertedUserOptions);
          }
        } catch {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      fetchUser();
    }, []);

    // const [options, setOptions] = useState();

    const handleChange = (value: any) => {
      const newOptions = optionList.map((option: any) => {
        if (option.value === value) {
          return { ...option, checked: !option.checked };
        }
        return option;
      });
      dispatch(ONCHANGE_OPTIONLIST(newOptions));
      // setOptions(newOptions);
      // navigate("/leave/calendar");
    };

    const checkAll = () => {
      const newOptions = optionList.map((option: any) => ({
        ...option,
        checked: true,
      }));
      dispatch(ONCHANGE_OPTIONLIST(newOptions));
      // setOptions(newOptions);
      // navigate("/leave/calendar");
    };

    return (
      <>
        <div
          className="w-full h-max p-3 "
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "#3498db",
          }}
        >
          LỊCH
        </div>
        <div className="py-1 font-semibold relative " ref={dropdownAddRef}>
          {/* <button
            className="bg-primary text-base text-white px-8 py-1.5 rounded-full"
            onClick={() => {
              setOpenDropdownAdd(!openDropdownAdd);
              // navigate("/leave/calendar");
            }}
          >
            Thêm mới
          </button> */}
          <button
            className="
              flex py-1.5 px-6 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center text-base
              "
            onClick={() => {
              setOpenDropdownAdd(!openDropdownAdd);
              // navigate("/leave/calendar");
            }}
          >
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="AddIcon"
            >
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"></path>
            </svg>
            <p className="">Thêm mới</p>
          </button>
          {openDropdownAdd && (
            <div className="absolute w-[200px] top-10 left-0 z-[102] text-center font-normal border border-gray-300  shadow-xl rounded-md bg-gray-50 flex flex-col">
              <button
                className="block px-4 py-2 text-sm capitalize text-gray-800 hover:bg-gray-200 border-b border-gray-300"
                onClick={() => {
                  _onClickCreateNewTask();
                  // navigate("/leave/calendar");
                }}
              >
                Thêm công việc
              </button>
              <button
                className="block px-4 py-2 text-sm capitalize text-gray-800 hover:bg-gray-200 border-b border-gray-300"
                onClick={() => {
                  _onClickCreateMeeting();
                  // navigate("/leave/calendar");
                }}
              >
                Thêm lịch họp
              </button>
              <button
                className="block px-4 py-2 text-sm capitalize text-gray-800 hover:bg-gray-200 border-b border-gray-300"
                onClick={() => {
                  _onClickCreateBusinessTrip();
                  // navigate("/leave/calendar");
                }}
              >
                Thêm lịch công tác
              </button>
              <button
                className="block px-4 py-2 text-sm capitalize text-gray-800 hover:bg-gray-200 border-b border-gray-300"
                onClick={() => {
                  _onClickHoliday();
                  // navigate("/leave/calendar");
                }}
              >
                Thêm ngày lễ
              </button>
              <button
                className="block px-4 py-2 text-sm capitalize text-gray-800 hover:bg-gray-200"
                onClick={() => {
                  _onClickDayOff();
                  // navigate("/leave/calendar");
                }}
              >
                Thêm nghỉ phép
              </button>
            </div>
          )}
        </div>
        <div className="py-2 font-semibold relative">
          <label className="text-sm">Chọn cán bộ:</label>
          <Select
            className="z-[101] w-full mt-1 text-sm"
            options={userList}
            onChange={(data: any) => {
              dispatch(ONSELECT_USER(data));
            }}
            value={selectedUser}
            isClearable
            placeholder="--- Chọn Cán bộ ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="py-2 font-semibold relative">
          {/* <div className="inline-flex items-center mt-3">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-gray-600"
            />
            <span className="ml-2 text-gray-700 text-sm">Chọn tất cả</span>
          </div> */}
          <div className="flex justify-between">
            <label className="text-sm">Chọn danh mục:</label>
            <p
              className="text-gray-700 text-sm font-normal hover:text-primary cursor-pointer"
              onClick={checkAll}
            >
              Chọn tất cả
            </p>
          </div>

          {optionList.map((item: any) => (
            <div className="mt-1 px-3 flex gap-3" key={item.value}>
              <input
                type="checkbox"
                className="h-4 w-4"
                style={{ accentColor: item.color }}
                checked={item.checked}
                onChange={() => handleChange(item.value)}
              />
              <p className="my-auto text-sm">{item.label}</p>
            </div>
          ))}
        </div>
        <div className="py-2 font-semibold relative">
          <div className="flex justify-between">
            <label className="text-sm">Ghi chú:</label>
          </div>

          <div className="flex flex-col gap-1.5 mt-2">
            <div className="mt-1 px-3 flex gap-3">
              <TaskAltIcon className="text-white rounded-full bg-teal-500" />
              <p className="my-auto text-sm">Công việc đã hoàn tất</p>
            </div>
            <div className="mt-1 px-3 flex gap-3">
              <TimelapseIcon className="text-white rounded-full bg-amber-500" />
              <p className="my-auto text-sm">Công việc đang xử lý</p>
            </div>
            <div className="mt-1 px-3 flex gap-3">
              <NotStartedIcon className="bg-white rounded-full text-sky-500" />
              <p className="my-auto text-sm">Công việc chưa xử lý</p>
            </div>
            <div className="mt-1 px-3 flex gap-3">
              <HighlightOffIcon className="text-white rounded-full bg-red-500" />
              <p className="my-auto text-sm">Công việc chưa nhận</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const WorkingOptions = () => {
    return (
      <>
        <div
          className="
          w-full h-[2.25rem]
          flex pl-3 pr-3 items-center
          text-sm font-semibold
        "
        >
          <span className="w-max h-max flex">
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Người giao việc</p>
          </span>
        </div>

        {/* list of task */}
        <div className="w-full h-max flex flex-col pl-7 pr-3 text-sm space-y-2">
          <button
            className={`w-max h-max flex hover:text-primary
             ${urlLink == "/working/management-workflow" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/management-workflow");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Quản lý quy trình</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
             ${urlLink == "/working/new-task" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/new-task");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Tạo công việc mới</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-need-give" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-need-give");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc cần giao</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-finish" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-finish");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc hoàn tất đang chờ duyệt</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-delay" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-delay");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc đang bị trễ hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-change-deadline" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-change-deadline");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc chờ duyệt</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "working/task-proccessing" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("working/task-proccessing");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc đã giao đang xử lý</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-end-soon" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-end-soon");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc sắp hết hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-end-today" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-end-today");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc tới hạn hôm nay</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-total" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-total");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Danh sách tổng việc đã giao</p>
          </button>
          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/task-total-my-seft" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-total-my-seft");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Danh sách tổng việc tự tạo</p>
          </button>
        </div>

        <div
          className="
          w-full h-[2.25rem]
          flex pl-3 pr-3 items-center
          text-sm font-semibold
        "
        >
          <button className="w-max h-max flex hover:text-primary">
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Người nhận việc</p>
          </button>
        </div>

        {/* list of task receiving */}
        <div className="w-full h-max flex flex-col pl-7 pr-3 text-sm space-y-2">
          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-new" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/receiver/task-new");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc mới</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/receiver/task-received" ? "text-sky-400" : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-received");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc đã nhận</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/receiver/task-processing"
                ? "text-sky-400"
                : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-processing");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc đang xử lý</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/receiver/task-delay" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/receiver/task-delay");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc trễ hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/receiver/task-end-soon" ? "text-sky-400" : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-end-soon");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc sắp trễ hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/receiver/all-task" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/receiver/all-task");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Danh sách tổng hợp công việc</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink == "/working/receiver/task-end-today"
                ? "text-sky-400"
                : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-end-today");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc tới hạn hôm nay</p>
          </button>
        </div>
      </>
    );
  };

  const renderDrive = () => {
    const drives = (fafafa.drives || []).map((item: any, index: any) => {
      return (
        <div
          id={item.FormLink}
          className="w-full h-max flex flex-col pl-3 pr-3 text-sm p-2"
          key={index}
          style={{
            display:
              item.FolderName != "" &&
                typeof item.FolderName != "undefined" &&
                item.FolderName != null
                ? "block"
                : "none",
          }}
        >
          <button
            key={index}
            className={`w-max h-max flex hover:text-primary
            ${urlLink == `/drives/${item.FolderId}` ? "text-primary" : ""}`}
            onClick={() => {
              navigate(`/drives/${item.FolderId}`);
              dispatch(
                ONINIT_SPREADRUM({
                  name: item.FolderName,
                  id: item.FolderId,
                  callBack: () => {
                    navigate(`/drives/${item.FolderId}`);
                    dispatch(ONMOVE_SPREADRUM(item.FolderId));
                  },
                })
              );
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              // window.alert("haha");
            }}
          >
            <ArrowRightIcon fontSize="small" />
            <div className="w-[234px] text-left text-base">
              <p>{item.FolderName}&nbsp;</p>
            </div>
          </button>
        </div>
      );
    });

    return (
      <>
        <div className=" flex lg:hidden justify-end w-full h-[2.5rem] items-center">
          <button
            className="w-[2rem] h-[2rem] bg-slate-300 rounded-full shadow-md shadow-gray-500  font-semibold active:bg-slate-200
          active:shadow-inner active:shadow-gray-500 X active:text-slate-400"
            onClick={() => dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE())}
          >
            <CloseRoundedIcon />
          </button>
        </div>
        <div
          className="w-full h-max flex p-3 "
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "#3498db",
          }}
        >
          Laco Drive
        </div>

        <form className="flex w-full text-sm mt-2">
          <input
            type="text"
            className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-l-lg rounded-r-none border border-gray-300 focus:ring-[#00b1ff] focus:border-[#00b1ff]"
            placeholder="Tìm kiếm..."
            required
            ref={searchInputRef}
          />
          <button
            type="submit"
            onClick={_onClickSearch}
            className="px-2 text-white bg-[#00b1ff] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-r-lg text-sm"
          >
            <SearchIcon />
          </button>
        </form>

        {drives}
      </>
    );
  };

  // render menu
  const RenderMenuWeb = () => {
    let groupName: string = "MenuNone";

    const XulyMang = (e: any) => {
      if (e != groupName) {
        groupName = e;
        return (
          <div
            className="w-full h-max flex p-3 "
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#3498db",
            }}
          >
            {groupName}
          </div>
        );
      } else {
        return <></>;
      }
    };

    if (fafafa.isDrive) {
      return renderDrive();
    }

    const resetDispatch = async () => {
      dispatch(ONCHANGE_RESET_FILTER({}));
      dispatch(ONCHANGE_RESET_FILTER_PROPOSE1({}));
      dispatch(ONSET_RETURN_PAGE_CURRENT());
    };

    return (
      <>
        {fafafa.showingMenu == "0" ? (
          <WorkingTableOptions />
        ) : fafafa.showingMenu == "85" ? (
          <CalendarNavSide />
        ) : (
          fafafa?.subMenu.map((item: any, index: number) => (
            <>
              {item.ParentFormId.toString() == fafafa.showingMenu &&
                item.PerActive == true ? (
                <>
                  <>{XulyMang(item.FormGroup)}</>

                  <div
                    id={item.FormLink}
                    className="w-full h-max flex flex-col pl-3 pr-3 text-sm p-2"
                    key={index}
                    style={{
                      display:
                        item.FormTitle != "" &&
                          typeof item.FormTitle != "undefined" &&
                          item.FormTitle != null
                          ? "block"
                          : "none",
                    }}
                  >
                    <button
                      key={index}
                      className={`w-max h-max flex hover:text-primary
                      ${urlLink == `/${item.FormLink}` ? "text-primary" : ""}
                        `}
                      onClick={() => {
                        navigate(`/${item.FormLink}`);
                        dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
                        resetDispatch();
                      }}
                    >
                      <ArrowRightIcon fontSize="small" />
                      <div
                        style={{
                          width: "234px",
                          textAlign: "left",
                          fontSize: "12pt",
                        }}
                      >
                        <p>
                          {item.FormTitle}&nbsp;
                          {item.FormId == 98 ||
                            item.FormId == 99 ||
                            item.FormId == 101 ||
                            item.FormId == 20 ||
                            item.FormId == 21 ||
                            item.FormId == 22 ||
                            item.FormId == 41 ||
                            item.FormId == 23 ||
                            item.FormId == 26 ||
                            item.FormId == 62 ||
                            item.FormId == 63 ||
                            item.FormId == 76 ||
                            item.FormId == 72 ||
                            item.FormId == 78 ||
                            item.FormId == 71 ||
                            item.FormId == 70 ||
                            item.FormId == 75 ||
                            item.FormId == 67 ||
                            item.FormId == 68 ||
                            item.FormId == 69 ||
                            item.FormId == 64 ||
                            item.FormId == 27 ||
                            item.FormId == 74 ||
                            item.FormId == 28 ||
                            item.FormId == 83 ||
                            item.FormId == 29 ||
                            item.FormId == 30 ||
                            item.FormId == 88 ||
                            item.FormId == 89 ||
                            item.FormId == 33 ? (
                            <span className="w-max h-max text-md font-semibold p-1 justify-center items-center">
                              {item.FormId == 98 ? (
                                `${data.loadNoti2?.countListTaskMySelf == 0 ||
                                  data.loadNoti2?.countListTaskMySelf == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListTaskMySelf})`
                                }`
                              ) : item.FormId == 99 ? (
                                `${data.loadNoti?.countListTaskMySelf == 0 ||
                                  data.loadNoti?.countListTaskMySelf == undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListTaskMySelf})`
                                }`
                              ) : item.FormId == 101 ? (
                                `${data.loadNoti?.countListAllHasDelivery_All_TaskMySelf == 0 ||
                                  data.loadNoti?.countListAllHasDelivery_All_TaskMySelf == undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery_All_TaskMySelf})`
                                }`
                              ) : item.FormId == 20 ? (
                                `${data.loadNoti?.countListNew == 0 ||
                                  data.loadNoti?.countListNew == undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListNew})`
                                }`
                              ) : item.FormId == 22 ? (
                                `${data.loadNoti?.countListCompleted == 0 ||
                                  data.loadNoti?.countListCompleted == undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListCompleted})`
                                }`
                              ) : item.FormId == 41 ? (
                                `${data.loadNoti?.countListProccessing == 0 ||
                                  data.loadNoti?.countListProccessing ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListProccessing})`
                                }`
                              ) : item.FormId == 21 ? (
                                `${data.loadNoti?.countListNeedDelivery == 0 ||
                                  data.loadNoti?.countListNeedDelivery ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListNeedDelivery})`
                                }`
                              ) : item.FormId == 23 ? (
                                `${data.loadNoti?.countListLate == 0 ||
                                  data.loadNoti?.countListLate == undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListLate})`
                                }`
                              ) : item.FormId == 26 ? (
                                `${data.loadNoti?.countListAllHasDelivery_All ==
                                  0 ||
                                  data.loadNoti?.countListAllHasDelivery_All ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery_All})`
                                }`
                              ) : item.FormId == 76 ? (
                                `${data.loadNoti?.countListAllHasDelivery == 0 ||
                                  data.loadNoti?.countListAllHasDelivery ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery})`
                                }`
                              ) : item.FormId == 62 ? (
                                `${data.loadNoti?.countListLateComplete == 0 ||
                                  data.loadNoti?.countListLateComplete ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListLateComplete})`
                                }`
                              ) : item.FormId == 72 ? (
                                `${data.loadNoti?.countListReturnWork == 0 ||
                                  data.loadNoti?.countListReturnWork ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListReturnWork})`
                                }`
                              ) : item.FormId == 78 ? (
                                `${data.loadNoti?.countListChangeDeadLine == 0 ||
                                  data.loadNoti?.countListChangeDeadLine ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListChangeDeadLine})`
                                }`
                              ) : item.FormId == 63 ? (
                                `${data.loadNoti?.countListEarlyComplete == 0 ||
                                  data.loadNoti?.countListEarlyComplete ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListEarlyComplete})`
                                }`
                              ) : item.FormId == 64 ? (
                                `${data.loadNoti?.countListPunctualCompleted ==
                                  0 ||
                                  data.loadNoti?.countListPunctualCompleted ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListPunctualCompleted})`
                                }`
                              ) : item.FormId == 27 ? (
                                `${data.loadNoti2?.countListNew == 0 ||
                                  data.loadNoti2?.countListNew == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListNew})`
                                }`
                              ) : item.FormId == 75 ? (
                                `${data.loadNoti?.countListAllNeedToJoin == 0 ||
                                  data.loadNoti?.countListAllNeedToJoin ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllNeedToJoin})`
                                }`
                              ) : item.FormId == 74 ? (
                                `${data.loadNoti?.countListAllRequest_Voting ==
                                  0 ||
                                  data.loadNoti?.countListAllRequest_Voting ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllRequest_Voting})`
                                }`
                              ) : item.FormId == 28 ? (
                                `${data.loadNoti2?.countListReveced == 0 ||
                                  data.loadNoti2?.countListReveced == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListReveced})`
                                }`
                              ) : item.FormId == 70 ? (
                                `${data.loadNoti2?.countListCompleted == 0 ||
                                  data.loadNoti2?.countListCompleted ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListCompleted})`
                                }`
                              ) : item.FormId == 29 ? (
                                `${data.loadNoti2?.countListWorking == 0 ||
                                  data.loadNoti2?.countListWorking == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListWorking})`
                                }`
                              ) : item.FormId == 67 ? (
                                `${data.loadNoti2?.countListLateComplete == 0 ||
                                  data.loadNoti2?.countListLateComplete ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListLateComplete})`
                                }`
                              ) : item.FormId == 71 ? (
                                `${data.loadNoti2?.countReturnWork == 0 ||
                                  data.loadNoti2?.countReturnWork == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countReturnWork})`
                                }`
                              ) : item.FormId == 68 ? (
                                `${data.loadNoti2?.countListEarlyComplete == 0 ||
                                  data.loadNoti2?.countListEarlyComplete ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListEarlyComplete})`
                                }`
                              ) : item.FormId == 83 ? (
                                `${data.loadNoti2?.countListShared == 0 ||
                                  data.loadNoti2?.countListShared == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListShared})`
                                }`
                              ) : item.FormId == 69 ? (
                                `${data.loadNoti2?.countListPunctualCompleted ==
                                  0 ||
                                  data.loadNoti2?.countListPunctualCompleted ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListPunctualCompleted})`
                                }`
                              ) : item.FormId == 30 ? (
                                `${data.loadNoti2?.countListLated == 0 ||
                                  data.loadNoti2?.countListLated == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListLated})`
                                }`
                              ) : item.FormId == 33 ? (
                                `${data.loadNoti2?.countListAll == 0 ||
                                  data.loadNoti2?.countListAll == undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListAll})`
                                }`
                              ) : item.FormId == 88 ? (
                                `${data.loadNoti?.countListRecoveryTask == 0 ||
                                  data.loadNoti?.countListRecoveryTask ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListRecoveryTask})`
                                }`
                              ) : item.FormId == 89 ? (
                                `${data.loadNoti2?.countListRecoveryTask == 0 ||
                                  data.loadNoti2?.countListRecoveryTask ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti2?.countListRecoveryTask})`
                                }`
                              ) : (
                                <></>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ))
        )}
      </>
    );
  };

  const RenderMenuMobile = () => {
    let groupName: string = "MenuNone";

    const XulyMang = (e: any) => {
      if (e != groupName) {
        groupName = e;
        return (
          <div
            className="w-max h-max flex p-3 "
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#3498db",
            }}
          >
            {groupName}
          </div>
        );
      } else {
        return <></>;
      }
    };

    if (fafafa.isDrive) {
      return renderDrive();
    }
    const resetDispatch = async () => {
      dispatch(ONCHANGE_RESET_FILTER({}));
      dispatch(ONCHANGE_RESET_FILTER_PROPOSE1({}));
      dispatch(ONSET_RETURN_PAGE_CURRENT());
    };

    return (
      <>
        {/* close optionList */}
        <div className="flex justify-end w-full h-[2.5rem] items-center">
          <button
            className="w-[2rem] h-[2rem] bg-slate-300 rounded-full shadow-md shadow-gray-500  font-semibold active:bg-slate-200
          active:shadow-inner active:shadow-gray-500 X active:text-slate-400"
            onClick={() => dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE())}
          >
            <CloseRoundedIcon />
          </button>
        </div>
        {fafafa.showingMenu == "0" ? (
          <WorkingTableOptions />
        ) : fafafa.showingMenu == "85" ? (
          <CalendarNavSide />
        ) : (
          fafafa.subMenu.map((item: any, index: number) => (
            <>
              {item.ParentFormId.toString() == fafafa.showingMenu &&
                item.PerActive == true ? (
                <>
                  {/* {
                    item.FormGroup != _groupName ?
                      (
                        <div className="w-full h-max flex p-2" style={{ textTransform: "uppercase", fontWeight: "bold" }}>{item.FormGroup}</div>
                      ) : null
                  } */}
                  <>{XulyMang(item.FormGroup)}</>

                  <div
                    className="w-max h-max flex flex-col pl-3 pr-3 text-sm p-2"
                    key={index}
                    style={{
                      display:
                        item.FormTitle != "" &&
                          typeof item.FormTitle != "undefined" &&
                          item.FormTitle != null
                          ? "block"
                          : "none",
                    }}
                  >
                    <button
                      key={item.FormId}
                      className={`w-max h-max flex hover:text-primary
                      ${urlLink == `/${item.FormLink}` ? "text-sky-400" : ""}
                        `}
                      onClick={() => {
                        navigate(`/${item.FormLink}`);
                        dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
                        resetDispatch();
                      }}
                    >
                      <ArrowRightIcon fontSize="small" />
                      <div
                        style={{
                          width: "234px",
                          textAlign: "left",
                          fontSize: "12pt",
                        }}
                      >
                        <p>
                          {item.FormTitle}&nbsp;
                          {item.FormId == 98 ||
                            item.FormId == 99 ||
                            item.FormId == 101 ||
                            item.FormId == 20 ||
                            item.FormId == 21 ||
                            item.FormId == 22 ||
                            item.FormId == 41 ||
                            item.FormId == 23 ||
                            item.FormId == 26 ||
                            item.FormId == 62 ||
                            item.FormId == 63 ||
                            item.FormId == 72 ||
                            item.FormId == 78 ||
                            item.FormId == 71 ||
                            item.FormId == 70 ||
                            item.FormId == 69 ||
                            item.FormId == 64 ||
                            item.FormId == 27 ||
                            item.FormId == 75 ||
                            item.FormId == 83 ||
                            item.FormId == 74 ||
                            item.FormId == 28 ||
                            item.FormId == 67 ||
                            item.FormId == 29 ||
                            item.FormId == 68 ||
                            item.FormId == 30 ||
                            item.FormId == 88 ||
                            item.FormId == 89 ||
                            item.FormId == 33 ? (
                            <span className="w-max h-max text-md font-semibold p-1 justify-center items-center">
                              {item.FormId == 98 ? (
                                `${data.loadNoti2?.countListTaskMySelf == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListTaskMySelf})`
                                }`
                              ) : item.FormId == 99 ? (
                                `${data.loadNoti?.countListTaskMySelf == 0 ||
                                  data.loadNoti?.countListTaskMySelf ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListTaskMySelf})`
                                }`
                              ) : item.FormId == 101 ? (
                                `${data.loadNoti?.countListAllHasDelivery_All_TaskMySelf == 0 ||
                                  data.loadNoti?.countListAllHasDelivery_All_TaskMySelf ==
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery_All_TaskMySelf})`
                                }`
                              ) : item.FormId == 20 ? (
                                `${data.loadNoti?.countListNew == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListNew})`
                                }`
                              ) : item.FormId == 22 ? (
                                `${data.loadNoti?.countListCompleted == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListCompleted})`
                                }`
                              ) : item.FormId == 41 ? (
                                `${data.loadNoti?.countListProccessing == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListProccessing})`
                                }`
                              ) : item.FormId == 23 ? (
                                `${data.loadNoti?.countListLate == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListLate})`
                                }`
                              ) : item.FormId == 26 ? (
                                `${data.loadNoti?.countListAllHasDelivery_All ==
                                  0
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery_All})`
                                }`
                              ) : item.FormId == 76 ? (
                                `${data.loadNoti?.countListAllHasDelivery == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery})`
                                }`
                              ) : item.FormId == 21 ? (
                                `${data.loadNoti?.countListNeedDelivery == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListNeedDelivery})`
                                }`
                              ) : item.FormId == 62 ? (
                                `${data.loadNoti?.countListLateComplete == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListLateComplete})`
                                }`
                              ) : item.FormId == 78 ? (
                                `${data.loadNoti?.countListChangeDeadLine == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListChangeDeadLine})`
                                }`
                              ) : item.FormId == 72 ? (
                                `${data.loadNoti?.countListReturnWork == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListReturnWork})`
                                }`
                              ) : item.FormId == 63 ? (
                                `${data.loadNoti?.countListEarlyComplete == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListEarlyComplete})`
                                }`
                              ) : item.FormId == 64 ? (
                                `${data.loadNoti?.countListPunctualCompleted == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListPunctualCompleted})`
                                }`
                              ) : item.FormId == 27 ? (
                                `${data.loadNoti2?.countListNew == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListNew})`
                                }`
                              ) : item.FormId == 70 ? (
                                `${data.loadNoti2?.countListCompleted == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListCompleted})`
                                }`
                              ) : item.FormId == 74 ? (
                                `${data.loadNoti?.countListAllRequest_Voting == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListAllRequest_Voting})`
                                }`
                              ) : item.FormId == 75 ? (
                                `${data.loadNoti?.countListAllNeedToJoin == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListAllNeedToJoin})`
                                }`
                              ) : item.FormId == 28 ? (
                                `${data.loadNoti2?.countListReveced == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListReveced})`
                                }`
                              ) : item.FormId == 83 ? (
                                `${data.loadNoti2?.countListShared == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListShared})`
                                }`
                              ) : item.FormId == 68 ? (
                                `${data.loadNoti2?.countListEarlyComplete == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListEarlyComplete})`
                                }`
                              ) : item.FormId == 29 ? (
                                `${data.loadNoti2?.countListWorking == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListWorking})`
                                }`
                              ) : item.FormId == 67 ? (
                                `${data.loadNoti2?.countListLateComplete == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListLateComplete})`
                                }`
                              ) : item.FormId == 30 ? (
                                `${data.loadNoti2?.countListLated == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListLated})`
                                }`
                              ) : item.FormId == 69 ? (
                                `${data.loadNoti2?.countListPunctualCompleted ==
                                  0
                                  ? ""
                                  : `(${data.loadNoti2?.countListPunctualCompleted})`
                                }`
                              ) : item.FormId == 71 ? (
                                `${data.loadNoti2?.countReturnWork == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countReturnWork})`
                                }`
                              ) : item.FormId == 33 ? (
                                `${data.loadNoti2?.countListAllcountListAll == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListAll})`
                                }`
                              ) : item.FormId == 88 ? (
                                `${data.loadNoti?.countListRecoveryTask == 0
                                  ? ""
                                  : `(${data.loadNoti?.countListRecoveryTask})`
                                }`
                              ) : item.FormId == 89 ? (
                                `${data.loadNoti2?.countListRecoveryTask == 0
                                  ? ""
                                  : `(${data.loadNoti2?.countListRecoveryTask})`
                                }`
                              ) : (
                                <></>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ))
        )}
      </>
    );
  };

  // TODO: responsive
  const OptionListWebJSX = () => {
    // const [a, setA] = useState('');

    // useEffect(() => {
    //   const optionListScroll = document.getElementById('optionListScroll');

    //   setA('a');

    //     if (optionListScroll) {
    //       let pathName = window.location.pathname;

    //       if (pathName) {
    //         if (pathName[0] == "/") {
    //           pathName = pathName.substring(1);

    //           const itemLink = document.getElementById(`${pathName}`);

    //           if (itemLink) {
    //             const top = itemLink.getBoundingClientRect().top;
    //             optionListScroll.classList.add('hide-scroll');

    //             optionListScroll.scrollTo({
    //               top: top - 50
    //             });

    //             //optionListScroll.classList.remove('hide-scroll');
    //           }
    //         }
    //       }
    //     }
    // }, []);

    const isMobile = DeviceUtils.isMobile();

    return (
      <div className="lg:flex hidden w-max h-full text-xs">
        <div
          id="optionListScroll"
          className={`
          w-[18rem] h-full bg-[#fbfbfd] shadow-sm pl-3 pr-3 text-slate-500 border-r-2 overflow-y-auto overflow-x-hidden styled-scrollbars
          
        `}
          style={{
            display: fafafa.navBarButton == "true" ? "block" : "none",
            height: `calc(var(--view-height) - ${isMobile ? 2.7 : 5.2}rem)`,
          }}
        >
          <RenderMenuWeb />
        </div>
      </div>
    );
  };

  const OptionListMobileJSX = () => {
    const isMobile = DeviceUtils.isMobile();

    const isIOS = DeviceUtils.isIOS();

    const header = document.getElementById("header");

    const heightHeader = header?.clientHeight ?? 42;

    return (
      <div
        className={`lg:hidden z-[101] ${fafafa.navBarButtonResponsive == true ? "flex" : "hidden"
          } w-max lg:h-full absolute left-[3rem]`}
        style={{
          height: `calc(var(--view-height) - ${isMobile ? 2.7 : 5.2}rem)`,
          minHeight: `calc(var(--view-height) - ${isMobile ? 2.7 : 5.2}rem)`,
        }}
      >
        <div
          className={`
          w-[18rem] h-full bg-[#fbfbfd] shadow-sm pl-3 pr-3 text-slate-500 border-r-2 relative overflow-x-hidden overflow-y-auto styled-scrollbars
        `}
        >
          <RenderMenuMobile />
        </div>
      </div>
    );
  };

  return (
    <>
      <OptionListMobileJSX />
      <OptionListWebJSX />
    </>
  );
};

export default memo(OptionList);
