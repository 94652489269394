import { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import {
  PROPOSEGETLIST,
  PROPOSE_TYPE_LIST,
  PRIOR_LIST_DROPDOWN,
  USER_READ_ALL_DROPDOWN,
  LOAD_SEP,
} from "../../../apis/_index";

import MobileReceiverPropose from "./mobileReceiverPropose";
import FilterProposeWeb from "./filterProposeWeb";
import FilterProposeMobile from "./filterProposeMobile";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";
import { ModalController } from "../../homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import LoaderSpinner from "../../../components/loader-spinner/_index";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Pagination from "@mui/material/Pagination";
import {
  PROCEDUCE_STEP_TYPES,
  PROCEDUCE_STEP_TYPE_OBJECTS,
} from "../../../constants/dataConstants";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { MODAL_KEYS } from "../../../constants/constants";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import HandleUI from "../../../utils/handleUI";
import {
  ONCHANGE_CURRENT_PAGE,
  ONCHANGE_ITEM_PER_PAGE,
  ONLOAD_DATA_LIST,
  ONLOAD_PRIOR_LIST,
  ONLOAD_RECEIVER_LIST,
  ONLOAD_SENDER_LIST,
  ONLOAD_STATUS_LIST,
  ONLOAD_TOTAL_ITEM,
  ONLOAD_TYPE_LIST,
} from "../../../redux/reducers/proposeReducer/_index";
import { AnyAaaaRecord } from "dns";

const ProposeReceiverListUI = (props: any) => {
  // for mobile clicking detail
  _onClickOther();

  const shouldLog = useRef(true);
  const dispatch = useDispatch();
  const proposeData = useSelector((state: any) => state.proposeReducer);
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const [taskId, setTaskId] = useState<string>("");
  const [_proposeHistoryId, set_proposeHistoryId] = useState<string | null>(
    null
  );
  const [_taskContentHistoryId, set_taskContentHistoryId] = useState<
    string | null
  >(null);
  const context = useContext(ModalController);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);

  const [isLoading, setIsLoading] = useState(true);

  const statusList = [
    { Id: "0", value: "Chưa duyệt", label: "Chưa duyệt" },
    { Id: "1", value: "Đã duyệt", label: "Đã duyệt" },
    { Id: "2", value: "Không duyệt", label: "Không duyệt" },
  ];

  // load all data
  const shouldLogLoadData = useRef(true);

  useEffect(() => {
    if (shouldLogLoadData.current === true) {
      shouldLogLoadData.current = false;

      const takeStatusList = async () => {
        setIsLoading(true);
        try {
          dispatch(ONLOAD_STATUS_LIST(statusList));
          setIsLoading(false);
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeProposeTypeList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(PROPOSE_TYPE_LIST, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_TYPE_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takePriorList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_PRIOR_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeUserList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_SENDER_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeBossList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(LOAD_SEP, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_RECEIVER_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeProposeTypeList();
      takePriorList();
      takeUserList();
      takeBossList();
      takeStatusList();
    }
  }, []);

  const _handleCheckedColor = (status: any) => {
    if (status === 2) {
      return "#ff0000";
    } else if (status === 1) {
      return "#00ff00";
    } else {
      return "#FFAA00";
    }
  };

  const _handleCheckedStatus = (item: any) => {
    if (item.StatusName) {
      return item.StatusName;
    }

    const status = item.Status;

    if (status === 2) {
      return "Không duyệt";
    } else if (status === 1) {
      return "Đã duyệt";
    } else {
      return "Chưa duyệt";
    }
  };
  // onClick detail
  const _onClickDetail = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeDetail");
    context.setTaskId(id);
  };

  // onClick confirm
  const _onClickConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeConfirm");
    context.setTaskId(id);
  };

  // onClick unConfirm
  const _onClickUnConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeUnConfirm");
    context.setTaskId(id);
  };

  const _loadData = () => {
    let body: any = {
      Page: proposeData.pagination.currentPage,
      Limit: proposeData.pagination.itemPerPage,
      Name: proposeData.searchFilter.name,
      Status: proposeData.searchFilter.selectedStatus,
      PriorityLevelID: proposeData.searchFilter.selectedPrior,
      UserAdd: proposeData.searchFilter.selectedSender,
      UserCheck: proposeData.searchFilter.selectedReceiver,
      DateAdd_From: proposeData.searchFilter.fromDate,
      DateAdd_To: proposeData.searchFilter.toDate,
      ProposeTypeID: proposeData.searchFilter.selectedType,
    };

    if (proposeData.searchFilter.selectedPrior === "") {
      delete body.PriorityLevelID;
    }
    if (proposeData.searchFilter.name === "") {
      delete body.Name;
    }
    if (proposeData.searchFilter.selectedStatus === "") {
      delete body.Status;
    }
    if (proposeData.searchFilter.selectedSender === "") {
      delete body.UserAdd;
    }
    if (proposeData.searchFilter.selectedReceiver === "") {
      delete body.UserCheck;
    }
    if (proposeData.searchFilter.selectedType === "") {
      delete body.ProposeTypeID;
    }
    if (proposeData.searchFilter.fromDate === "") {
      delete body.DateAdd_From;
    }
    if (proposeData.searchFilter.toDate === "") {
      delete body.DateAdd_To;
    }

    const takeData = async () => {
      try {
        await ServiceUtils.postV2(PROPOSEGETLIST, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (((res || {}).data || {}).StatusCode === 200) {
            dispatch(
              ONLOAD_DATA_LIST((((res || {}).data || {}).Data || {}).data || [])
            );
            dispatch(
              ONLOAD_TOTAL_ITEM((((res || {}).data || {}).Data || {}).count)
            );
          } else {
            toast.error(res.data.Message || "Tải dữ liệu thất bại.");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };
    takeData();
  };

  // const _loadDataStart = () => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   const body: any = {
  //     Page: page,
  //     Limit: limitPerPage,
  //   };

  //   if (shouldLog.current) {
  //     shouldLog.current = false;

  //     const takeData = async () => {
  //       try {
  //         await ServiceUtils.postV2(PROPOSEGETLIST, body, {
  //           headers: {
  //             Accept: "text/plain",
  //           },
  //         }).then((res: any) => {
  //           if (((res || {}).data || {}).StatusCode === 200) {
  //             // dispatch(ONLOAD_PROPOSE_GUI_LIST(res.data.Data));
  //             setData((((res || {}).data || {}).Data || {}).data || []);
  //             setTotalItems((((res || {}).data || {}).Data || {}).count);
  //           } else {
  //             toast.error(res.data.Message || "Tải dữ liệu thất bại.");
  //           }
  //         });
  //       } catch (error: any) {
  //         toast.error(error);
  //       }
  //     };
  //     takeData();
  //   }
  // };

  // // start loading
  // useEffect(() => {
  //   _loadDataStart();
  // });

  // calc total page

  useEffect(() => {
    _loadData();
  }, [proposeData.pagination.currentPage, proposeData.pagination.itemPerPage]);

  // onChange page
  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_CURRENT_PAGE(value - 1));
  };
  const _onChangeItemPerPage = (e: any) => {
    dispatch(ONCHANGE_ITEM_PER_PAGE(e.target.value));
  };

  const onHandleProcedureStep = (id: any) => {
    context.setFuncs(MODAL_KEYS.detailProcedureStep);
    context.setTaskId(id);
    context.handleOpen();
  };

  const renderFunctionProcedureStep = (item: any) => {
    if (item.ProcedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.transferStep) {
      return (
        item.ProcedureStepLabelFunction1 && (
          <button
            className="flex w-full h-full hover:text-green-500 p-2"
            onClick={() => onHandleProcedureStep(item.ID)}
          >
            <div className="flex w-full h-full space-x-1">
              <SkipNextIcon
                className="text-green-600"
                sx={{ fontSize: 18 }}
                fontSize="small"
              />
              <div>{item.ProcedureStepLabelFunction1}</div>
            </div>
          </button>
        )
      );
    } else if (
      item.ProcedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.confirmNotConfirm
    ) {
      return (
        <>
          {item.ProcedureStepLabelFunction1 && (
            <button
              className="flex w-full h-full hover:text-green-500 p-2"
              onClick={() => onHandleProcedureStep(item.ID)}
            >
              <div className="flex w-full h-full space-x-1">
                <CheckIcon
                  className="text-green-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />
                <div>{item.ProcedureStepLabelFunction1}</div>
              </div>
            </button>
          )}
          {item.ProcedureStepLabelFunction2 && (
            <button
              className="flex w-full h-full hover:text-green-500 p-2"
              onClick={() => onHandleProcedureStep(item.ID)}
            >
              <div className="flex w-full h-full space-x-1">
                <CancelIcon
                  className="text-green-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />
                <div>{item.ProcedureStepLabelFunction2}</div>
              </div>
            </button>
          )}
        </>
      );
    } else if (
      item.ProcedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.confirmNotConfirm
    ) {
      return (
        <>
          {item.ProcedureStepLabelFunction1 && (
            <button
              className="flex w-full h-full hover:text-green-500 p-2"
              onClick={() => _onClickConfirm(item.ID)}
            >
              <div className="flex w-full h-full space-x-1">
                <CheckIcon
                  className="text-green-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />
                <div>{item.ProcedureStepLabelFunction1}</div>
              </div>
            </button>
          )}
          {item.ProcedureStepLabelFunction2 && (
            <button
              className="flex w-full h-full hover:text-green-500 p-2"
              onClick={() => _onClickConfirm(item.ID)}
            >
              <div className="flex w-full h-full space-x-1">
                <SkipNextIcon
                  className="text-green-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />
                <div>{item.ProcedureStepLabelFunction2}</div>
              </div>
            </button>
          )}
          {item.ProcedureStepLabelFunction3 && (
            <button
              className="flex w-full h-full hover:text-green-500 p-2"
              onClick={() => _onClickConfirm(item.ID)}
            >
              <div className="flex w-full h-full space-x-1">
                <CancelIcon
                  className="text-green-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />
                <div>{item.ProcedureStepLabelFunction3}</div>
              </div>
            </button>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <>
      {isLoading ? (
        // <div className="flex justify-center items-center flex-col text-sm space-y-3 w-full h-[calc(100%-7rem)] lg:mt-0 pt-5 px-5">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col text-sm space-y-3 w-full h-[calc(100%-7rem)] lg:mt-0 pt-5 px-5">
          {/* <ModalBase
            open={openSub}
            handleClose={handleCloseSub}
            funcs={funcsSub}
            taskId={taskId}
            _loadData={_loadData}
          /> */}
          <div className="w-full lg:flex h-max space-y-3 hidden ">
            <FilterProposeWeb _loadData={_loadData} />
          </div>

          <div className="hidden lg:flex flex-col space-y-3 ">
            {/* page selection */}
            <div className="flex w-max h-max">
              <p>Xem</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={proposeData.pagination.itemPerPage}
                onChange={(e: any) => _onChangeItemPerPage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>

            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <th className="border border-slate-300 ">STT</th>
                <th className="border border-slate-300 ">Trạng thái</th>
                <th className="border border-slate-300 ">Tên đề xuất</th>
                <th className="border border-slate-300 ">Nội dung</th>
                <th className="border border-slate-300 ">Thời gian đề xuất</th>
                <th className="border border-slate-300 ">Ngày gửi</th>
                <th className="border border-slate-300 ">Người gửi</th>
                <th className="border border-slate-300 ">Người nhận</th>
                <th className="border border-slate-300 "></th>
              </thead>
              <tbody
                className="w-full h-[calc(100%-2rem)] bg-white overflow-auto"
                id="table"
              >
                {proposeData.data === null || proposeData.data.length === 0 ? (
                  <></>
                ) : (
                  proposeData.data?.map((item: any, index: any) => {
                    const checkHaveProcedureStepType =
                      PROCEDUCE_STEP_TYPES.find(
                        (p) => p.value == item.ProcedureStepType
                      );

                    return (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="stt text-center border border-slate-300">
                          {index +
                            1 +
                            proposeData.pagination.currentPage *
                              proposeData.pagination.itemPerPage}
                        </td>
                        <td className="trang-thai items-center border border-slate-300">
                          <div
                            style={{
                              backgroundColor: _handleCheckedColor(item.Status),
                              color: "white",
                              padding: 5,
                              textAlign: "center",
                              borderRadius: 13,
                              width: "auto",
                            }}
                          >
                            {_handleCheckedStatus(item)}
                          </div>
                        </td>
                        <td className="ten text-center border border-slate-300">
                          {item.Name}
                        </td>
                        <td className="noi-dung text-center border border-slate-300">
                          {item.Content}
                        </td>
                        {item.IsUseTime === true ? (
                          <td className="thoi-han text-center border border-slate-300">
                            {moment(item.StartTime).format("DD/MM/YYYY")}
                            &nbsp;-&nbsp;
                            {moment(item.EndTime).format("DD/MM/YYYY")}
                          </td>
                        ) : (
                          <td className="text-center border border-slate-300">
                            Không thời hạn
                          </td>
                        )}
                        <td className="ngay-gui text-center border border-slate-300">
                          {moment(item.DateAdd).format("DD/MM/YYYY")}
                          <br />
                          {moment(item.DateAdd).format("hh:mm")}
                        </td>
                        <td className="nguoi-nhan text-center border border-slate-300">
                          <p>{item.UserAddName}</p>
                          {item.ListNamePropose && (
                            <p className="text-xs">
                              <span className="text-slate-600">
                                Hỗ trợ:&nbsp;
                              </span>
                              {item.ListNamePropose}
                            </p>
                          )}
                        </td>
                        <td className="nguoi-nhan text-center border border-slate-300">
                          {item.UserCheckName}
                        </td>
                        <td className="nguoi-nhan text-center border border-slate-300">
                          <div className="justify-center items-center w-full h-full lg:flex hidden">
                            <div className="dropdown">
                              <button
                                className="dropbtn"
                                onMouseEnter={() => {
                                  HandleUI.hoverMenuContextScrollTable(
                                    index,
                                    proposeData.data?.length
                                  );
                                }}
                              >
                                <MoreHorizIcon fontSize="small" />
                              </button>

                              <div className="dropdown-content text-xs font-semibold">
                                <button
                                  className="flex w-full h-full hover:text-sky-500 p-2"
                                  onClick={() => _onClickDetail(item.ID)}
                                >
                                  <div className="flex w-full h-full space-x-1">
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                                {checkHaveProcedureStepType &&
                                  item.IsPermissionHandleProcedureStep &&
                                  renderFunctionProcedureStep(item)}
                                {item?.Status === 0 &&
                                !checkHaveProcedureStepType ? (
                                  // {item?.Status === 0 ? (
                                  <>
                                    <button
                                      className="flex w-full h-full hover:text-green-500 p-2"
                                      onClick={() => _onClickConfirm(item.ID)}
                                    >
                                      <div className="flex w-full h-full space-x-1">
                                        <CheckIcon
                                          className="text-green-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />
                                        <div>Duyệt</div>
                                      </div>
                                    </button>
                                    <button
                                      className="flex w-full h-full hover:text-red-500 p-2"
                                      onClick={() => _onClickUnConfirm(item.ID)}
                                    >
                                      <div className="flex w-full h-full space-x-1">
                                        <CancelIcon
                                          className="text-red-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />
                                        <div>Không duyệt</div>
                                      </div>
                                    </button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {/* pagination */}
            <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
              <Pagination
                count={proposeData.pagination.totalPage}
                variant="outlined"
                color="primary"
                size="small"
                page={proposeData.pagination.currentPage + 1}
                onChange={_onChangePaginate}
              />
            </div>
          </div>

          <div className="lg:hidden">
            {/* {data?.length !== 0 ? ( */}
            <>
              <FilterProposeMobile _loadData={_loadData} />
              <MobileReceiverPropose _loadData={_loadData} />
            </>
            {/* ) : null} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProposeReceiverListUI;
