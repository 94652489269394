import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
  ONCHANGE_NGUOI_GIAO_TONG_HOP,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";
import HistoryIcon from "@mui/icons-material/History";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import LoaderSpinner from "../../loader-spinner/_index";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";
import { Checkcolor } from "../../../funcs/checkColor";
import { error } from "console";
import {
  CONFIG_IS_FORCE,
  NGUOI_GIAO_VIEC_DETAIL,
  NGUOI_GIAO_VIEC_LIST_TOTAL,
} from "../../../apis/_index";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import SubModal from "../_subModal/_index";
import { FuncClickDetailsTask } from "../../../funcs/FuncClickDetailsTask";

// const ItemTSX = ({
//   item,
//   index,
//   workingData,
//   appData,
//   _renderStatus,
//   _onClickCompleteTaskWithId,
//   _onClickEditTaskWithId,
//   _onClickRemindTaskWithId,
// }: any) => {
//   const [openSub, setOpenSub] = useState(false);
//   const [funcsSub, setFuncsSub] = useState<string>("");
//   const handleOpenSub = () => setOpenSub(true);
//   const handleCloseSub = () => setOpenSub(false);
//   const [open, setOpen] = useState<boolean>(false);
//   const [isLoading, setIsLoading] = useState<boolean>(false);

//   const [taskContents, setTaskContents] = useState<any[]>([]);
//   const [task, setTask] = useState<any>(null);
//   const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);
//   const [_taskContents, set_TaskContents] = useState<any[]>([]);
//   const [isViewHistoty, setIsViewHistoty] = useState<any>(null);
//   const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);
//   const [taskResults, setTaskResults] = useState<any>(null);
//   const [taskHistory, setTaskHistory] = useState<any[]>([]);
//   const [fileListAPI, setFileListAPI] = useState<any>(null);
//   const [fileListAPI2, setFileListAPI2] = useState<any[]>([]);
//   const [taskInfoText, setTaskInfoText] = useState<any[]>([]);

//   const _onChangeMultipleChoicePartner = (
//     text: string,
//     item: any = null,
//     isViewHistoty: any = null
//   ) => {
//     handleOpenSub();
//     if (
//       text === "completedTaskContent" ||
//       text === "multipleUploadFileContent"
//     ) {
//       setTaskContentHistoryId(item);
//     }
//     if (isViewHistoty === 1) {
//       setIsViewHistoty(1);
//     } else {
//       setIsViewHistoty(0);
//     }
//     setFuncsSub(text);
//   };

//   useEffect(() => {
//     setOpen(false);
//   }, [workingData.pageCurrent]);

//   const takeData = async (taskId: any) => {
//     const API = NGUOI_GIAO_VIEC_DETAIL(taskId);
//     setIsLoading(true);
//     try {
//       await ServiceUtils.getV2(API, {
//         headers: {
//           Accept: "text/plain",
//         },
//       }).then((res: any) => {
//         if (res.status === 200) {
//           setTaskContents(res.data.Data.taskContents);
//         }
//       });
//     } catch (error: any) {
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const _openDropDown = () => {
//     if (open) {
//       setOpen(false);
//     } else {
//       console.log("item, ", item);
//       setOpen(true);
//       takeData(item.ID);
//     }
//   };
//   const _handleChecked = (statusCheck: any, ownerChecked: any) => {
//     if (statusCheck === false && ownerChecked === null) {
//       return "Người thực hiện chính không duyệt";
//     } else if (statusCheck === true && ownerChecked === null) {
//       return "Người giao việc chưa duyệt";
//     } else if (statusCheck === null && ownerChecked === null) {
//       return "Người thực hiện chính chưa duyệt";
//     } else if (statusCheck === true && ownerChecked === false) {
//       return "Người giao việc không duyệt";
//     } else if (statusCheck === true && ownerChecked === true) {
//       return "Người giao việc đã duyệt";
//     }
//   };

//   const _handleColor = (statusCheck: any, ownerChecked: any) => {
//     if (statusCheck === false && ownerChecked === null) {
//       return "#a855f7";
//     } else if (statusCheck === true && ownerChecked === null) {
//       return "#FFAA00";
//     } else if (statusCheck === null && ownerChecked === null) {
//       return "#78716c";
//     } else if (statusCheck === true && ownerChecked === false) {
//       return "#ff0000";
//     } else if (statusCheck === true && ownerChecked === true) {
//       return "#00ff00";
//     }
//   };
//   const getDetail = (isStart: any) => {
//     const accessToken = localStorage.getItem("accessToken");
//     const API = NGUOI_GIAO_VIEC_DETAIL(item.ID);

//     if (isStart === 1) {
//     } else {
//       const takeData = async () => {
//         try {
//           await ServiceUtils.getV2(API, {
//             headers: {
//               Accept: "text/plain",
//             },
//           }).then((res: any) => {
//             if (res.status === 200) {
//               setTask(res.data.Data.task);
//               setTaskContents(res.data.Data.taskContents);
//               setTaskContentsHistory(res.data.Data.taskContentHistory);
//               setTaskResults(res.data.Data.taskResults);

//               setTaskHistory(res.data.Data.taskHistory);

//               let fileListResponse: any[] = [];
//               let fileListNameResponse: any[];
//               if (
//                 res.data.Data.task.ListFiles &&
//                 res.data.Data.task.ListFilesName
//               ) {
//                 fileListResponse = res.data.Data.task.ListFiles.split(";");
//                 fileListNameResponse =
//                   res.data.Data.task.ListFilesName.split(";");

//                 let tempFileArray: any[] = [];
//                 if (
//                   fileListResponse.length > 0 &&
//                   fileListNameResponse.length > 0
//                 ) {
//                   fileListNameResponse.forEach((item: any, index: any) => {
//                     let tempObject: any;
//                     if (item) {
//                       tempObject = {
//                         listFile: fileListResponse[index],
//                         listFileName: item,
//                       };
//                     }
//                     tempFileArray.push(tempObject);
//                   });
//                   setFileListAPI(tempFileArray);
//                 }
//               }

//               let fileListResponse2: any[] = [];
//               let fileListNameResponse2: any[] = [];
//               if (
//                 res.data.Data.task.ResultFiles &&
//                 res.data.Data.task.ResultFilesName
//               ) {
//                 fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
//                 fileListNameResponse2 =
//                   res.data.Data.task.ResultFilesName.split(";");
//                 let tempFileArray2: any[] = [];
//                 if (
//                   fileListNameResponse2.length > 0 &&
//                   fileListResponse2.length > 0
//                 ) {
//                   fileListNameResponse2.map((item: any, index: any) => {
//                     let tempObject: any;
//                     if (
//                       item !== "" &&
//                       item !== null &&
//                       typeof item !== "undefined"
//                     ) {
//                       tempObject = {
//                         listFile: fileListResponse2[index],
//                         listFileName: item,
//                       };
//                     }
//                     if (tempObject) {
//                       tempFileArray2.push(tempObject);
//                     }
//                   });
//                   setFileListAPI2(tempFileArray2);
//                 }
//               }

//               let _taskInfoText: any[] = res.data.Data.taskInfoText;
//               // debugger

//               let fileListResponseChat: any[] = [];
//               let fileListResponseNameChat: any[] = [];
//               if (_taskInfoText.length > 0) {
//                 let tempFileArrFileChat: any[] = [];
//                 _taskInfoText.map((item: any) => {
//                   if (item?.ListFile && item?.ListFileName) {
//                     fileListResponseChat = item?.ListFile.split(";");
//                     fileListResponseNameChat = item?.ListFileName.split(";");

//                     let arr: any[] = [];
//                     if (
//                       fileListResponseNameChat.length > 0 &&
//                       fileListResponseChat.length > 0
//                     ) {
//                       fileListResponseNameChat.map((itemC: any, index: any) => {
//                         let tempObject: any;

//                         if (
//                           itemC !== "" &&
//                           itemC !== null &&
//                           typeof itemC !== "undefined"
//                         ) {
//                           tempObject = {
//                             listFile: fileListResponseChat[index],
//                             listFileName: itemC,
//                           };
//                         }
//                         if (tempObject) {
//                           arr.push(tempObject);
//                         }
//                       });

//                       if (arr) {
//                         tempFileArrFileChat.push({
//                           ...item,
//                           listFileInChat: arr,
//                         });
//                       } else {
//                         tempFileArrFileChat.push({
//                           ...item,
//                           listFileInChat: [],
//                         });
//                       }
//                     }
//                   } else {
//                     tempFileArrFileChat.push({ ...item, listFileInChat: [] });
//                   }
//                 });
//                 setTaskInfoText(tempFileArrFileChat);
//               }
//             }
//           });
//         } catch (error: any) {
//           // ERROR: here
//         }
//       };

//       takeData();
//     }
//   };

//   const _handleCheckCompled = (data: any) => {
//     if (data === 1) {
//       getDetail(0);
//     }
//   };

//   return (
//     <React.Fragment key={index}>
//       <SubModal
//         open={openSub}
//         handleClose={handleCloseSub}
//         funcs={funcsSub}
//         isViewHistoty={isViewHistoty}
//         taskContentHistoryId={taskContentHistoryId}
//         task={task === null ? " " : task}
//         taskContentsHistory={taskContentsHistory}
//         taskId={item.ID}
//         _handleCheckCompled={_handleCheckCompled}
//         _taskContents={_taskContents}
//       />
//       <tr
//         key={index}
//         className="w-max h-[3rem] bg-white hover:bg-slate-100 cursor-pointer"
//         onClick={_openDropDown}
//       >
//         <td className="hidden lg:table-cell text-center border border-slate-300">
//           {index + 1 + workingData.pageCurrent * workingData.choosePage}
//         </td>
//         <td className="border border-slate-300 space-y-0.5">
//           <p className="lg:hidden font-bold">
//             <span className="lg:hidden">
//               {index + 1 + workingData.pageCurrent * workingData.choosePage}
//               .&nbsp;
//             </span>
//             {item.TaskName}
//           </p>
//           <p className="hidden lg:flex">{item.TaskName}</p>
//           <p className="lg:hidden">
//             <span className="text-xs"> Người giao: </span>
//             <span className="font-semibold">{item.TaskOwnerName}</span>
//           </p>
//           <p className="lg:hidden">
//             <span className="text-xs">Người nhận: </span>
//             <span className="font-semibold">{item.RecipientName}</span>
//           </p>
//           {item.SupporterName !== null && (
//             <p className="lg:hidden">
//               <span className="text-xs">Người hỗ trợ:&nbsp;</span>
//               <span className="text-slate-600">{item.SupporterName}</span>
//             </p>
//           )}
//           <div className="lg:hidden w-full h-max flex flex-col justify-center">
//             <p>
//               <span className="text-xs">
//                 Số giờ thực hiện:{" "}
//                 <span className="italic">
//                   {item.HoursUsed} giờ {item.MinutesUsed} phút
//                 </span>
//               </span>
//             </p>
//           </div>
//           <div className="lg:hidden w-full h-max flex flex-col justify-center">
//             <p>
//               <span className="text-xs">
//                 Số giờ còn lại:{" "}
//                 <span className="italic">
//                   {item.Hours_in_month_remaining} giờ
//                 </span>
//               </span>
//             </p>
//           </div>

//           <div className="lg:hidden w-full h-max flex flex-col justify-center">
//             <p>
//               <span className="text-xs">
//                 Thời hạn:{" "}
//                 <span className="italic">
//                   {moment(item.AssignmentDate).format("HH:mm")}
//                   &nbsp;
//                   {moment(item.AssignmentDate).format("DD/MM")}
//                 </span>
//               </span>
//               <span className="italic">
//                 {moment(item.AssignmentDate).format("HH:mm")}
//                 &nbsp;
//                 {moment(item.AssignmentDate).format("DD/MM")}
//               </span>
//               &nbsp;-&nbsp;
//               <span className="italic">
//                 {moment(item.DeadLine).format("HH:mm")}&nbsp;
//                 {moment(item.DeadLine).format("DD/MM")}
//               </span>
//               <p
//                 style={{
//                   color: Checkcolor(item.PriorityLevelID, appData),
//                 }}
//                 className={`font-bold mr-2 ${Checkcolor(
//                   item.PriorityLevelID,
//                   appData
//                 )}`}
//               >
//                 {item.PriorityLevelName}
//               </p>
//             </p>
//           </div>

//           <div className="flex justify-between items-center">
//             <div className="work-status flex gap-1 flex-wrap">
//               {item.TaskStatus === 2 ? (
//                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
//                   Chưa nhận
//                 </p>
//               ) : null}
//               {item.TaskStatus === 3 ? (
//                 <p className="bg-sky-500 text-white w-max p-1 rounded-md text-xs font-semibold">
//                   Chưa xử lý
//                 </p>
//               ) : null}
//               {item?.TaskStatus === 4 ? (
//                 <>
//                   {!item?.IsCompleted_History ? (
//                     <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
//                       Đang xử lý
//                     </p>
//                   ) : (
//                     <p className="bg-orange-300 text-white w-max p-1 rounded-md text-xs font-semibold">
//                       Đã báo cáo chờ duyệt
//                     </p>
//                   )}
//                 </>
//               ) : null}
//               {item.TaskStatus === 5 ? (
//                 <>
//                   <p className="bg-teal-500 text-white w-max p-1 rounded-md text-xs font-semibold">
//                     Hoàn tất
//                   </p>
//                 </>
//               ) : null}
//               {<>{_renderStatus(item)}</>}
//               {item.NumberReturnWork > 0 && (
//                 <p className="text-red-500 text-xs lg:text-sm font-semibold">
//                   Bị trả {item.NumberReturnWork} lần
//                 </p>
//               )}
//             </div>

//             <div className="lg:hidden ml-3 ">
//               <div className="dropdown2">
//                 {/* button options */}
//                 <button
//                   className="dropbtn2"
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     _onClickFuncs(index.toString());
//                   }}
//                 >
//                   ...
//                 </button>

//                 {/* options fields */}
//                 {item.TaskStatus === 5 ? (
//                   <div
//                     id={index.toString()}
//                     className="dropdown-content2 text-xs font-semibold"
//                   >
//                     {/* detail */}
//                     <button
//                       className="flex w-full h-full hover:text-sky-500"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         _onClickCompleteTaskWithId(item.ID);
//                       }}
//                     >
//                       {/* detail - contain */}
//                       <div className="flex w-full h-full space-x-1">
//                         {/* icon */}
//                         <DescriptionTwoToneIcon
//                           className="text-sky-600"
//                           sx={{ fontSize: 18 }}
//                           fontSize="small"
//                         />

//                         {/* title */}
//                         <div>Chi tiết</div>
//                       </div>
//                     </button>
//                   </div>
//                 ) : (
//                   <div
//                     id={index.toString()}
//                     className="dropdown-content2 text-xs font-semibold"
//                   >
//                     {/* detail */}
//                     <button
//                       className="flex w-full h-full hover:text-sky-500"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         _onClickEditTaskWithId(item.ID);
//                       }}
//                     >
//                       {/* detail - contain */}
//                       <div className="flex w-full h-full space-x-1">
//                         {/* icon */}
//                         <DescriptionTwoToneIcon
//                           className="text-sky-600"
//                           sx={{ fontSize: 18 }}
//                           fontSize="small"
//                         />

//                         {/* title */}
//                         <div>Chi tiết</div>
//                       </div>
//                     </button>

//                     <button
//                       className="flex w-full h-full hover:text-sky-500"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         _onClickRemindTaskWithId(item.ID);
//                       }}
//                     >
//                       {/* detail - contain */}
//                       <div className="flex w-full h-full space-x-1">
//                         {/* icon */}
//                         <NotificationsActiveIcon
//                           className="text-sky-600"
//                           sx={{ fontSize: 18 }}
//                           fontSize="small"
//                         />

//                         {/* title */}
//                         <div>Nhắc việc</div>
//                       </div>
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </td>
//         <td className="hidden lg:table-cell text-center border border-slate-300">
//           <p
//             style={{
//               color: Checkcolor(item.PriorityLevelID, appData),
//             }}
//             className={`font-bold ${Checkcolor(item.PriorityLevelID, appData)}`}
//           >
//             {item.PriorityLevelName}
//           </p>
//         </td>
//         <td className="hidden lg:table-cell text-center border border-slate-300">
//           <div className="w-full h-max flex flex-col justify-center items-center">
//             <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
//             <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
//           </div>
//         </td>
//         <td className="hidden lg:table-cell text-center border border-slate-300">
//           <div className="w-full h-max flex flex-col justify-center items-center">
//             <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
//             <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
//           </div>
//         </td>
//         <td className="hidden lg:table-cell border border-slate-300">
//           {item.TaskOwnerName}
//         </td>
//         <td className="hidden lg:table-cell border border-slate-300">
//           {item.RecipientName}
//           {item.SupporterName && (
//             <p className="text-xs">
//               <span className="text-slate-600">Hỗ trợ:</span>{" "}
//               {item.SupporterName}
//             </p>
//           )}
//         </td>
//         <td className="hidden lg:table-cell border border-slate-300">
//           {item.TaskScorecardName}
//         </td>

//         <td className="hidden lg:table-cell text-center border border-slate-300">
//           <div className="dropdown">
//             {/* button options */}
//             <button className="dropbtn">
//               <MoreHorizIcon fontSize="small" />
//             </button>

//             {/* options fields */}
//             {item.TaskStatus === 5 ? (
//               <div className="dropdown-content text-xs font-semibold">
//                 {/* detail */}
//                 <button
//                   className="flex w-full h-full hover:text-sky-500"
//                   onClick={
//                     (e) => {
//                       e.stopPropagation();
//                       _onClickCompleteTaskWithId(item.ID);
                      
//                     }
//                     // FuncClickDetailsTask(context, item.ID)
//                   }
//                 >
//                   {/* detail - contain */}
//                   <div className="flex w-full h-full space-x-1">
//                     {/* icon */}
//                     <DescriptionTwoToneIcon
//                       className="text-sky-600"
//                       sx={{ fontSize: 18 }}
//                       fontSize="small"
//                     />

//                     {/* title */}
//                     <div>Chi tiết</div>
//                   </div>
//                 </button>
//               </div>
//             ) : (
//               <div className="dropdown-content text-xs font-semibold">
//                 {/* detail */}
//                 <button
//                   className="flex w-full h-full hover:text-sky-500"
//                   onClick={
//                     (e) => {
//                       e.stopPropagation();
//                       _onClickEditTaskWithId(item.ID);
//                     }
//                     // FuncClickDetailsTask(context, item.ID)
//                   }
//                 >
//                   {/* detail - contain */}
//                   <div className="flex w-full h-full space-x-1">
//                     {/* icon */}
//                     <DescriptionTwoToneIcon
//                       className="text-sky-600"
//                       sx={{ fontSize: 18 }}
//                       fontSize="small"
//                     />

//                     {/* title */}
//                     <div>Chi tiết</div>
//                   </div>
//                 </button>

//                 <button
//                   className="flex w-full h-full hover:text-sky-500"
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     _onClickRemindTaskWithId(item.ID);
//                   }}
//                 >
//                   {/* detail - contain */}
//                   <div className="flex w-full h-full space-x-1">
//                     {/* icon */}
//                     <NotificationsActiveIcon
//                       className="text-sky-600"
//                       sx={{ fontSize: 18 }}
//                       fontSize="small"
//                     />

//                     {/* title */}
//                     <div>Nhắc việc</div>
//                   </div>
//                 </button>
//               </div>
//             )}
//           </div>
//         </td>
//       </tr>
//       {open && (
//         <tr>
//           <td colSpan={9} className="p-0 border-0 ">
//             {isLoading ? (
//               <>
//                 <div className="flex items-center justify-center w-full h-full my-2">
//                   <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
//                     <LoaderSpinner w={20} h={20} />
//                     <div>Dang tải ...</div>
//                   </div>
//                 </div>
//               </>
//             ) : (
//               <div className="border border-t-0 border-slate-300 mb-2">
//                 <div className="flex justify-between bg-slate-100 py-2">
//                   <div className="max-lg:hidden px-2 w-[10%]">
//                     <span>Bước</span>
//                   </div>
//                   <div className="max-lg:hidden px-2 w-[30%]">
//                     <span>Trạng thái</span>
//                   </div>
//                   <div className="max-lg:hidden px-2 w-[45%]">
//                     <span>Nội dung</span>
//                   </div>
//                   <div className="max-lg:hidden px-2 w-[15%]">
//                     <span>Thời hạn</span>
//                   </div>
//                   {/* <div className="max-lg:hidden px-2 w-[10%]">
//                     <span></span>
//                   </div> */}
//                   <div className="lg:hidden px-2 w-full text-center">
//                     <span>Bước thực hiện</span>
//                   </div>
//                 </div>
//                 <div>
//                   {taskContents.map((item: any, index: any) => (
//                     <>
//                       <div className="max-lg:hidden flex justify-between border-t-2 text-sm font-normal py-2">
//                         <div className="px-2 my-auto w-[5%]">
//                           <span>{item?.SortOrder}</span>
//                         </div>
//                         <div className="px-2 my-auto w-[30%]">
//                           <>
//                             {item?.taskContentsHistory &&
//                               item?.taskContentsHistory.IsCompleted === true ? (
//                               <>
//                                 <div
//                                   style={{
//                                     backgroundColor: _handleColor(
//                                       item?.taskContentsHistory.Checked,
//                                       item?.taskContentsHistory.OwnerChecked
//                                     ),
//                                     color: "white",
//                                     padding: 5,
//                                     textAlign: "center",
//                                     borderRadius: 13,
//                                     width: "fit-content",
//                                   }}
//                                 >
//                                   {_handleChecked(
//                                     item?.taskContentsHistory.Checked,
//                                     item?.taskContentsHistory.OwnerChecked
//                                   )}
//                                 </div>
//                               </>
//                             ) : (
//                               <div
//                                 style={{
//                                   backgroundColor: "#00B1FF",
//                                   color: "white",
//                                   padding: 5,
//                                   textAlign: "center",
//                                   borderRadius: 13,
//                                   width: "fit-content",
//                                 }}
//                               >
//                                 Chưa có kết quả
//                               </div>
//                             )}
//                           </>
//                         </div>
//                         <div className="px-2 my-auto w-[45%]">
//                           <p>{item?.StepName}</p>
//                           <p>
//                             <i>
//                               Người thực hiện: <b>{item?.UserDoName}</b>
//                             </i>
//                           </p>
//                         </div>
//                         <div className="px-2 my-auto w-[15%]">
//                           <p>{moment(item?.DeadLine).format("DD/MM/YYYY")}</p>
//                           <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>
//                         </div>
//                         {/* <div className="px-2 my-auto w-[10%]">
//                           <button
//                             className="flex w-full h-full hover:text-sky-500"
//                             onClick={() =>
//                               _onChangeMultipleChoicePartner(
//                                 "multipleUploadFileContent",
//                                 item?.ID,
//                                 1
//                               )
//                             }
//                           >
//                             <div>Xem lịch sử</div>
//                           </button>
//                         </div> */}
//                       </div>
//                       <div className="lg:hidden border-t-2 text-sm font-normal py-2 flex flex-col gap-2">
//                         <div className="px-2 my-auto w-full font-semibold flex gap-2">
//                           <p className="w-1/4">
//                             Bước <span>{item?.SortOrder}:</span>
//                           </p>
//                           <div className="px-2 my-auto">
//                             <p>{item?.StepName}</p>
//                             <p>
//                               <i>
//                                 Người thực hiện: <b>{item?.UserDoName}</b>
//                               </i>
//                             </p>
//                           </div>
//                         </div>

//                         <div className="px-2 my-auto w-full flex gap-2">
//                           <p className="w-1/4">Thời hạn</p>
//                           <p>
//                             {moment(item?.DeadLine).format("DD/MM/YYYY")}{" "}
//                             {moment(item?.DeadLine).format("HH:mm:ss")}
//                           </p>
//                         </div>
//                         {/* <div className="px-2 my-auto w-full flex justify-between gap-4">
//                           <>
//                             {item?.taskContentsHistory &&
//                             item?.taskContentsHistory.IsCompleted === true ? (
//                               <>
//                                 <div
//                                   style={{
//                                     backgroundColor: _handleColor(
//                                       item?.taskContentsHistory.Checked,
//                                       item?.taskContentsHistory.OwnerChecked
//                                     ),
//                                     color: "white",
//                                     padding: 5,
//                                     textAlign: "center",
//                                     borderRadius: 10,
//                                     width: "fit-content",
//                                   }}
//                                 >
//                                   {_handleChecked(
//                                     item?.taskContentsHistory.Checked,
//                                     item?.taskContentsHistory.OwnerChecked
//                                   )}
//                                 </div>
//                               </>
//                             ) : (
//                               <div
//                                 style={{
//                                   backgroundColor: "#00B1FF",
//                                   color: "white",
//                                   padding: 5,
//                                   textAlign: "center",
//                                   borderRadius: 10,
//                                   width: "fit-content",
//                                 }}
//                               >
//                                 Chưa có kết quả
//                               </div>
//                             )}
//                           </>
//                           <button
//                             className="flex hover:text-sky-500 mt-auto"
//                             onClick={() =>
//                               _onChangeMultipleChoicePartner(
//                                 "multipleUploadFileContent",
//                                 item?.ID,
//                                 1
//                               )
//                             }
//                           >
//                             <div>Xem lịch sử</div>
//                           </button>
//                         </div> */}
//                       </div>
//                     </>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </td>
//         </tr>
//       )}
//     </React.Fragment>
//   );
// };

const WorkingTaskTotalJSX = () => {
  _onClickOther();

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const appData = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);
  const [bienDoTreHan, setbienDoTreHan] = useState<any>(null);
  const [completedEarly, setCompletedEarly] = useState<any>(null);

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const link = window.location.pathname;

  const shouldLog = useRef(true);

  const _fetchData = (page: any, limit: any) => {
    readList(
      NGUOI_GIAO_VIEC_LIST_TOTAL,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
      },
      workingData.searchFilter.status,
      null,
      null,
      null,
      workingData.searchFilter.searchMonth,
      workingData.searchFilter.searchYear
    );
  };

  const _onChangeChoosePage = (e: any) => {
    const value = e.target.value;
    dispatch(ONCHANGE_CHOOSEPAGE(value));
    dispatch(ONCHANGE_PAGINATION_TASKLIST(1));
  };

  const [first, setFirst] = useState(false);
  useEffect(() => {
    if (first) {
      _fetchData(workingData.pageCurrent, workingData.choosePage);
    } else {
      setFirst(true);
    }
  }, [workingData.choosePage, workingData.pageCurrent]);

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("readTotalTask_nguoigiao");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickCompleteTaskWithId = (id: any) => {
    context.setFuncs("taskNeedConfirm");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickRemindTaskWithId = (id: any) => {
    context.setFuncs("notiTask_Model");
    context.setTaskId(id);
    context.handleOpen();
  };

  useEffect(() => {
    fetchBienDoTreHan();
  }, []);
  // Ví dụ sử dụng JavaScript
  // const responseData = { "Data": [{"BienDoTreHan": 10 }]};

  // Trích xuất giá trị BienDoTreHan từ dữ liệu API
  //const bienDoTreHan = responseData.Data[0].BienDoTreHan;
  async function fetchBienDoTreHan() {
    try {
      await ServiceUtils.getV2(CONFIG_IS_FORCE, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          setbienDoTreHan(res?.data?.Data[0]?.BienDoTreHan);
          setCompletedEarly(res?.data?.Data[0]?.CompletedEarly);
        } else {
          toast.error(res.data.Message);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  }
  const _renderStatus = (item: any) => {
    const currentTime = new Date().getTime();
    const deadlineTime = new Date(item?.DeadLine).getTime();
    const newDeadline = new Date(item?.NewDeadline).getTime();
    const assignmentDate = new Date(item?.AssignmentDate).getTime();
    const finishedDate = new Date(item?.FinishedDate).getTime();
    const isInProgres =
      assignmentDate <= currentTime && currentTime <= deadlineTime;
    const isWithinExtraTime = currentTime <= newDeadline;
    const isWithinExtraTime_ =
      deadlineTime <= finishedDate && finishedDate <= newDeadline;

    if (item?.TaskStatus === 4) {
      if (!item?.IsCompleted_History) {
        if (isInProgres) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      } else {
        if (assignmentDate <= finishedDate && finishedDate <= deadlineTime) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime_) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      }
    } else if (item?.TaskStatus === 5) {
      const timeEarlyCompleted = new Date(
        deadlineTime - ((deadlineTime - assignmentDate) / 100) * completedEarly
      ).getTime();
      if (timeEarlyCompleted >= finishedDate && finishedDate <= deadlineTime) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Sớm hạn
          </p>
        );
      } else if (
        timeEarlyCompleted < finishedDate &&
        finishedDate <= deadlineTime
      ) {
        return (
          <p className="bg-pink-700 text-white w-max p-1 rounded-md text-xs font-semibold">
            Đúng hạn
          </p>
        );
      } else if (isWithinExtraTime_) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    } else if (item?.TaskStatus === 2 || item?.TaskStatus === 3) {
      if (isInProgres) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong tiến độ
          </p>
        );
      } else if (isWithinExtraTime) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    }
  };

  return (
    <>
      {workingData.taskTotal === null ? (
        // <div className="flex flex-col justify-center items-center w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <>
          <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
            {/* warm */}
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
              <div className="font-bold">
                <p>
                  Tổng:&nbsp;
                  {workingData.taskTotal.count} việc
                </p>
                <p>
                  Trang hiện tại:&nbsp;
                  {workingData.taskTotal.data?.length} việc
                </p>
              </div>

              <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
                <p>Hiển thị</p>
                <select
                  className="border-2 border-slate-200 rounded-md"
                  value={workingData.choosePage}
                  onChange={(e: any) => _onChangeChoosePage(e)}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
                <p>dòng</p>
              </div>
            </div>
            <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="hidden lg:table-cell border border-slate-300 ">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>STT</p>
                        {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Tên công việc</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Mức độ</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Ngày giao</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-start items-center">
                        <p>Thời hạn</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Người giao</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Người nhận</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Kết quả</p>
                        {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border border-slate-300"></th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {workingData.taskTotal === null ? (
                    <tr></tr>
                  ) : (
                    workingData.taskTotal.data?.map((item: any, index: any) => (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          {index +
                            1 +
                            workingData.pageCurrent * workingData.choosePage}
                        </td>
                        <td className="border border-slate-300 space-y-0.5">
                          <p className="lg:hidden font-bold">
                            <span className="lg:hidden">
                              {index +
                                1 +
                                workingData.pageCurrent *
                                workingData.choosePage}
                              .&nbsp;
                            </span>
                            {item.TaskName}
                          </p>
                          <p className="hidden lg:flex">{item.TaskName}</p>
                          <p className="lg:hidden">
                            <span className="text-xs"> Người giao: </span>
                            <span className="font-semibold">
                              {item.TaskOwnerName}
                            </span>
                          </p>
                          <p className="lg:hidden">
                            <span className="text-xs">Người nhận: </span>
                            <span className="font-semibold">
                              {item.RecipientName}
                            </span>
                          </p>
                          {item.SupporterName !== null && (
                            <p className="lg:hidden">
                              <span className="text-xs">
                                Người hỗ trợ:&nbsp;
                              </span>
                              <span className="text-slate-600">
                                {item.SupporterName}
                              </span>
                            </p>
                          )}
                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">
                                Số giờ thực hiện:{" "}
                                <span className="italic">
                                  {item.HoursUsed} giờ {item.MinutesUsed} phút
                                </span>
                              </span>
                            </p>
                          </div>
                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">
                                Số giờ còn lại:{" "}
                                <span className="italic">
                                  {item.Hours_in_month_remaining} giờ
                                </span>
                              </span>
                            </p>
                          </div>

                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">
                                Thời hạn:{" "}
                                <span className="italic">
                                  {moment(item.AssignmentDate).format("HH:mm")}
                                  &nbsp;
                                  {moment(item.AssignmentDate).format("DD/MM")}
                                </span>
                              </span>
                              <span className="italic">
                                {moment(item.AssignmentDate).format("HH:mm")}
                                &nbsp;
                                {moment(item.AssignmentDate).format("DD/MM")}
                              </span>
                              &nbsp;-&nbsp;
                              <span className="italic">
                                {moment(item.DeadLine).format("HH:mm")}&nbsp;
                                {moment(item.DeadLine).format("DD/MM")}
                              </span>
                              <p
                                style={{
                                  color: Checkcolor(
                                    item.PriorityLevelID,
                                    appData
                                  ),
                                }}
                                className={`font-bold mr-2 ${Checkcolor(
                                  item.PriorityLevelID,
                                  appData
                                )}`}
                              >
                                {item.PriorityLevelName}
                              </p>
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="work-status flex gap-1 flex-wrap">
                              {item.TaskStatus === 2 ? (
                                <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Chưa nhận
                                </p>
                              ) : null}
                              {item.TaskStatus === 3 ? (
                                <p className="bg-sky-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Chưa xử lý
                                </p>
                              ) : null}
                              {item?.TaskStatus === 4 ? (
                                <>
                                  {!item?.IsCompleted_History ? (
                                    <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                      Đang xử lý
                                    </p>
                                  ) : (
                                    <p className="bg-orange-300 text-white w-max p-1 rounded-md text-xs font-semibold">
                                      Đã báo cáo chờ duyệt
                                    </p>
                                  )}
                                </>
                              ) : null}
                              {item.TaskStatus === 5 ? (
                                <>
                                  <p className="bg-teal-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                    Hoàn tất
                                  </p>
                                </>
                              ) : null}

                              {<>{_renderStatus(item)}</>}

                              {item.NumberReturnWork > 0 && (
                                <p className="text-red-500 text-xs lg:text-sm font-semibold">
                                  Bị trả {item.NumberReturnWork} lần
                                </p>
                              )}
                            </div>

                            <div className="lg:hidden ml-3 ">
                              <div className="dropdown2">
                                {/* button options */}
                                <button
                                  className="dropbtn2"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    _onClickFuncs(index.toString());
                                  }}
                                >
                                  ...
                                </button>

                                {/* options fields */}
                                {item.TaskStatus === 5 ? (
                                  <div
                                    id={index.toString()}
                                    className="dropdown-content2 text-xs font-semibold"
                                  >
                                    {/* detail */}
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        _onClickCompleteTaskWithId(item.ID);
                                      }}
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <DescriptionTwoToneIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Chi tiết</div>
                                      </div>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    id={index.toString()}
                                    className="dropdown-content2 text-xs font-semibold"
                                  >
                                    {/* detail */}
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // _onClickEditTaskWithId(item.ID);
                                        FuncClickDetailsTask(context, item?.ID)
                                      }}
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <DescriptionTwoToneIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Chi tiết</div>
                                      </div>
                                    </button>

                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        _onClickRemindTaskWithId(item.ID);
                                      }}
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <NotificationsActiveIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Nhắc việc</div>
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.TaskOwnerName}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.RecipientName}
                          {item.SupporterName && (
                            <p className="text-xs">
                              <span className="text-slate-600">Hỗ trợ:</span>{" "}
                              {item.SupporterName}
                            </p>
                          )}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.TaskScorecardName}
                        </td>

                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="dropdown">
                            {/* button options */}
                            <button className="dropbtn">
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            {/* options fields */}
                            {item.TaskStatus === 5 ? (
                              <div className="dropdown-content text-xs font-semibold">
                                {/* detail */}
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={
                                    (e) => {
                                      e.stopPropagation();
                                      // _onClickCompleteTaskWithId(item.ID);
                                      FuncClickDetailsTask(context, item?.ID)
                                    }
                                    // FuncClickDetailsTask(context, item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div className="dropdown-content text-xs font-semibold">
                                {/* detail */}
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={
                                    (e) => {
                                      e.stopPropagation();
                                      // _onClickEditTaskWithId(item.ID);
                                      FuncClickDetailsTask(context, item?.ID)
                                    }
                                    // FuncClickDetailsTask(context, item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>

                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    _onClickRemindTaskWithId(item.ID);
                                  }}
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <NotificationsActiveIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Nhắc việc</div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      // <ItemTSX
                      //   item={item}
                      //   index={index}
                      //   workingData={workingData}
                      //   appData={appData}
                      //   _renderStatus={_renderStatus}
                      //   _onClickCompleteTaskWithId={_onClickCompleteTaskWithId}
                      //   _onClickEditTaskWithId={_onClickEditTaskWithId}
                      //   _onClickRemindTaskWithId={_onClickRemindTaskWithId}
                      // />
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
              <Pagination
                count={
                  workingData.paginateTaskList === null
                    ? 0
                    : workingData.paginateTaskList
                }
                page={workingData.pageCurrent + 1}
                variant="outlined"
                color="primary"
                size="small"
                onChange={_onChangePaginate}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WorkingTaskTotalJSX;
