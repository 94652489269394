import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const ListOfGeneralPage = () => {
  return(
    <HomeUI />
  )
}

export default ListOfGeneralPage;
