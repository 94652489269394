import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import {
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  USER_READ_ALL_DROPDOWN,
  LOAD_ALL_NOTI,
  YEAR_LIST,
  GET_LIST_USER_GLOBAL,
} from "../../apis/_index";

import generalTask from "../../assets/images/GeneralManager.png";
import individualTask from "../../assets/images/HeadManagement.png";

import { Badge } from "@mui/material";

import { toast } from "react-toastify";
import axios from "axios";

// icon components
import { AccessTimeFilledOutlined } from "@mui/icons-material";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import BalanceRoundedIcon from "@mui/icons-material/BalanceRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import { DoneRounded } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";

// icon
import WorkIcon from "@mui/icons-material/Work";
import CloseIcon from "@mui/icons-material/Close";

// working
// -- sender
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";

import ThumbsUpDownRoundedIcon from "@mui/icons-material/ThumbsUpDownRounded";
import AlarmOnRoundedIcon from "@mui/icons-material/AlarmOnRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import TopicRoundedIcon from "@mui/icons-material/TopicRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  ONCHANGE_SEARCH_MONTH,
  ONCHANGE_SEARCH_YEAR,
  ONCHANGE_CLEAR_FILTER,
  ONCHANGE_SEARCH_RESULT,
  ONCHANGE_FROM_HOME_TO_PROCESSING,
  ONCHANGE_SEARCH_SELECTED_NGUOI_NHAN,
  ONCHAGE_FILTER_RESULT_WITH_NUMBER,
  ONLOAD_SELECTED_PRIOR,
  ONCHANGE_SELECTED_PRIOR,
  ONCHANGE_SELECTED_DIFF,
  ONLOAD_SELECTED_ISNOW_TASKCHANGEDEADLINE,
  ONCHANGE_SELECTED_BOSS_WITHID
  // ONCHANG_HIGHMANGER_STORAGE2,
} from "../../redux/reducers/workingReducer/_index";
import LoaderSpinner from "../../components/loader-spinner/_index";
import DeviceUtils from "../../utils/devices/deviceUtils";
import ServiceUtils from "../../utils/services";
import OrganizationRepository from "../../repositories/organizations/organizationRepository";
import OrganizationUtils from "../../utils/organizations/organizationUtils";
import LevelComponent from "../../components/organizations/levels/levelComponent";
import LoadingControl from "../../controls/loading/loadingControl";
import {
  ONCHANGE_FILTER,
  ONCHANGE_RESET_FILTER_PROPOSE2
} from "../../redux/reducers/proposeReducer/_index";
import MediumManagerUI from "../../temp/MediumManager/MediumManagerUI";
import NormalStaffUI from "../../temp/Normal/NormalStaff";

const HighManagerUI = () => {
  // const history = useHistory();
  const navigate = useNavigate();

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [phongBanList, setPhongBanList] = useState<any[] | undefined>(
    undefined
  );
  const [userFollowPhongBanList, setUserFollowPhongBanList] = useState<
    any[] | undefined
  >(undefined);
  const [notiList, setNotiList] = useState<any | null>(null);
  const [countPriority, setCountPriority] = useState<[]>([]);
  const [countDifficult, setCountDifficult] = useState<[]>([]);
  const [countPropose, setCountPropose] = useState<any | null>(null);
  const [countPropose_priority, setCountPropose_priority] = useState<[]>([]);

  const [selectedPhongBan, setSelectedPhongBan] = useState<any | undefined>(
    null
  );
  const [selectedUserFollowPhongBan, setSelectedUserFollowPhongBan] = useState<
    any | undefined
  >(null);
  const [selectedUserFollowPhongBanList, setSelectedUserFollowPhongBanList] =
    useState<any[] | undefined>([]);

  const [subShowing, setSubShowing] = useState<string>("");

  const [monthSearch, setMonthSearch] = useState<any | "">(
    new Date().getMonth() + 1
  );
  const [yearSearch, setYearSearch] = useState<any | "">(
    new Date().getFullYear()
  );
  const [yearList, setYearList] = useState<any[]>([]);
  const [listUser, setListUser] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<any | undefined>(null);

  const [highManagerStorage, setHighManagerStorage] = useState<string>("");
  const [highManagerStorage2, setHighManagerStorage2] = useState<string>("");

  const [organizations, setOrganization] = useState<any>([]);
  const [datalevel, setDatalevel] = useState<number | null>(null);

  const convertMonthToList = (month: any) => {
    if (month) {
      let a: any;
      tempMonthList.map((x: any) => {
        if (x.id === month) {
          a = x;
        }
      });

      return a;
    }
  };

  const tempMonthList = [
    {
      id: 1,
      value: 1,
      label: 1,
    },
    {
      id: 2,
      value: 2,
      label: 2,
    },
    {
      id: 3,
      value: 3,
      label: 3,
    },
    {
      id: 4,
      value: 4,
      label: 4,
    },
    {
      id: 5,
      value: 5,
      label: 5,
    },
    {
      id: 6,
      value: 6,
      label: 6,
    },
    {
      id: 7,
      value: 7,
      label: 7,
    },
    {
      id: 8,
      value: 8,
      label: 8,
    },
    {
      id: 9,
      value: 9,
      label: 9,
    },
    {
      id: 10,
      value: 10,
      label: 10,
    },
    {
      id: 11,
      value: 11,
      label: 11,
    },
    {
      id: 12,
      value: 12,
      label: 12,
    },
  ];

  // onMount
  useEffect(() => {
    const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const API_Noti =
        LOAD_ALL_NOTI + "?month=" + monthSearch + "&year=" + yearSearch;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              const tempArray = res.data.Data.map((item: any) => {
                return {
                  ...item,
                  value: item.ParameterValue,
                  label: item.ParameterValue,
                };
              });
              setPhongBanList(tempArray);
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeYear = async () => {
        try {
          await ServiceUtils.getV2(YEAR_LIST, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res?.data?.StatusCode === 200) {
              setYearList(convertYearToSelect(res?.data?.Data?.data || []));
            } else {
              toast.error(res?.data?.Message || "Tải dữ liệu năm thất bại");
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeYear();
      takeData();
      loadDataCount(API_Noti);

      getListOrganization();
    }
  }, []);

  const convertYearToSelect = (year: any[] = []) => {
    let lstYear: any[] = [];
    if (year && year?.length > 0) {
      year.map((x: any) => {
        lstYear.push({
          id: x.Year,
          value: x.Year,
          label: x.Year,
        });
      });
      return lstYear;
    } else {
      return [];
    }
  };

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      if (res.status) {
        const data = res.data || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: "PhongBanId",
          parentId: "RefID",
        });

        const organizationTreeValueLabels =
          OrganizationUtils.convertToListValueLabel(organizationTrees, {
            value: "PhongBanId",
            label: "PhongBan",
          });

        setOrganization(organizationTreeValueLabels);
      }
    });
  };

  const loadDataCount = (api: any) => {
    const takeNoti = async () => {
      try {
        await ServiceUtils.getV2(api, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setNotiList(res.data.Data?.data);
            setCountPriority(res.data.Data?.priority);
            setCountDifficult(res.data.Data?.difficult);
            setCountPropose(res.data.Data?.propose);
            setCountPropose_priority(res.data.Data?.propose_priority);
          } else {
            toast.error("Tải dữ liệu thất bại");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeNoti();
  };

  // onLoadWithSelectedPhongBan
  useEffect(() => {
    let _userFollowPhongBanList;

    if (selectedPhongBan === null || selectedPhongBan === undefined) {
      setUserFollowPhongBanList(listUser);
    } else {
      _userFollowPhongBanList = listUser.filter((item: any) =>
        (item.PhongBan || "")
          .split(",")
          .find((p: any) => p == selectedPhongBan) != null
          ? true
          : false
      );
      setUserFollowPhongBanList(_userFollowPhongBanList);

    }
  }, [listUser, selectedPhongBan, selectedUserFollowPhongBan]);

  const getListUserGlobal = (month: any = null, year: any = null) => {
    try {
      const _month = month ? month.toString() : null;
      const _year = year ? year.toString() : null;

      const url = GET_LIST_USER_GLOBAL + "?month=" + _month + "&year=" + _year;

      ServiceUtils.getV2(url, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.data.StatusCode === 200) {
          setListUser(res?.data?.Data);
        } else {
          toast.error(res.data.Message);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const tempSetColor = (total: number, used: number, type: string): string => {
    if (type === "bg") {
      if (total - used > 30) {
        return "#107c4120";
      } else {
        return "#ff000020";
      }
    } else {
      if (total - used > 30) {
        return "#107c41";
      } else {
        return "#ff0000";
      }
    }
  };

  useEffect(() => {
    getListUserGlobal(monthSearch, yearSearch);
  }, [monthSearch, yearSearch]);

  // funcs
  // const _onChangePhongBan = (data: any, id: any | null = null) => {
  //   if (data === null) {
  //     setSelectedPhongBan(null);
  //   } else {
  //     setSelectedPhongBan(data.ParameterId);
  //   }

  //   if (id) {
  //     setSelectedPhongBan(id);
  //   }
  // };

  const onChangeOrganization = (item: any) => {
    // setSelectedUserFollowPhongBan(null);
    setSelectedPhongBan(item?.PhongBanId);
    setSelectedUserFollowPhongBan(null);
  };
  const onChangeSelectedUser = (item: any) => {
    setSelectedUserFollowPhongBan(item?.UserId);
  };

  const _onClickNavigate = (url: string) => {
    navigate(url);
  };

  const _onClickSubShowing = (title: string) => {
    // dispatch(ONCHANG_HIGHMANGER_STORAGE(title))
    setHighManagerStorage(title);
  };

  const _onClickSubShowing2 = (title: string) => {
    // dispatch(ONCHANG_HIGHMANGER_STORAGE2(title))
    setHighManagerStorage2(title);
  };

  // Funcs
  const _onClickNavigateTaskResult = async (id: number) => {
    let year = yearSearch;
    let month = monthSearch;

    dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(ONCHANGE_SEARCH_RESULT(id));

    navigate("/working/task-totalall", {
      state: { month: month.toString(), year: year.toString() },
    });
    // dispatch(ONCHANGE_SEARCH_MONTH(month.toString()));
    // dispatch(ONCHANGE_SEARCH_YEAR(year.toString()));

    // history.push({
    //   pathname: "/working/task-totalall",
    //   state: { id, month: month.toString(), year: year.toString() },
    // });
    // try {
    //   dispatch(
    //     ONCHAGE_FILTER_RESULT_WITH_NUMBER({
    //       id: id,
    //     })
    //   );
    // } catch (error: any) {}
  };

  const _onClickNavigateTaskChangeDeadline = async (id: number) => {
    let year = yearSearch;
    let month = monthSearch;

    dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(ONLOAD_SELECTED_ISNOW_TASKCHANGEDEADLINE(id));

    navigate("/working/task-change-deadline", {
      state: { month: month.toString(), year: year.toString() },
    });
  };

  const _onChangeSelect = (e: any, type: any) => {
    let _year = yearSearch;
    let _month = monthSearch;
    let _phongbanid = selectedPhongBan;
    let _userid = selectedUserFollowPhongBan;

    let API_Noti;

    if (type === "year" && e?.id) {
      _year = e?.id;
      setYearSearch(e?.id);
      setSelectedYear(e);
      API_Noti = LOAD_ALL_NOTI + "?month=" + _month + "&year=" + _year
      loadDataCount(API_Noti);
    } else if (type === "month") {
      _month = e?.id;
      setMonthSearch(e?.id);
      API_Noti = LOAD_ALL_NOTI + "?month=" + _month + "&year=" + _year
      loadDataCount(API_Noti);
    } else if (type === "phongbanid") {
      _phongbanid = e?.PhongBanId ?? null;
      setSelectedPhongBan(_phongbanid);
      setSelectedUserFollowPhongBan(null);
      _userid = null;
      API_Noti = LOAD_ALL_NOTI + "?month=" + _month + "&year=" + _year + "&users=" + _userid + "&phongban_search=" + _phongbanid;
      loadDataCount(API_Noti);
    } else if (type === "userid") {
      setDatalevel(e?.DataLevel)
      _userid = e?.value ?? null;
      setSelectedUserFollowPhongBan(e ?? null)
      API_Noti = LOAD_ALL_NOTI + "?month=" + _month + "&year=" + _year + "&users=" + _userid;
      loadDataCount(API_Noti);
    }

    getListOrganization();

    getListUserGlobal(_month, _year);
  };

  // funcs
  const _onClickToChangeNavigate = (title: string) => {
    let year = yearSearch;
    let month = monthSearch;
    let titleFinal = title;

    // Promise.all([
    //   dispatch(
    //     ONCHANGE_FROM_HOME_TO_PROCESSING({
    //       month: month,
    //       year: year,
    //       title: titleFinal,
    //     })
    //   ),
    //   navigate(titleFinal),
    // ]);
    dispatch(ONCHANGE_CLEAR_FILTER({}));
    if (
      titleFinal === "/working/task-proccessing" ||
      titleFinal === "/working/task-totalall" ||
      titleFinal === "/working/task-finish-early" ||
      titleFinal === "/working/task-delay" ||
      titleFinal === "/working/task-finish-punctual" ||
      titleFinal === "/working/task-finish-late" ||
      titleFinal === "/working/task-return"
    ) {
      // window.history.pushState(
      //   { month: month.toString(), year: year.toString() },
      //   "",
      //   titleFinal
      // );
      navigate(titleFinal, {
        state: { month: month.toString(), year: year.toString() },
      });
    } else {
      // window.history.pushState({ month: "", year: "" }, "", titleFinal);
      navigate(titleFinal, { state: { month: "", year: "" } });
    }

    // if (titleFinal === "/working/task-change-deadline") {
    //   dispatch(ONCHANGE_SEARCH_MONTH(""));
    //   dispatch(ONCHANGE_SEARCH_YEAR(""));
    // } else {
    //   dispatch(ONCHANGE_SEARCH_MONTH(month.toString()));
    //   dispatch(ONCHANGE_SEARCH_YEAR(year.toString()));
    // }
    // navigate(titleFinal);
  };

  const _onClickTaskProccessing = (id: number) => {
    let year = yearSearch;
    let month = monthSearch;

    dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(ONCHANGE_SELECTED_PRIOR(id));

    navigate("/working/task-proccessing", {
      state: { month: month.toString(), year: year.toString() },
    });
  };

  const _onClickTaskFinish = (id: number) => {
    dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(ONCHANGE_SELECTED_PRIOR(id));

    navigate("/working/task-finish", {
      state: { month: "", year: "" },
    });
  };
  const _onClickTaskReturned = (id: number) => {
    let year = yearSearch;
    let month = monthSearch;

    dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(ONCHANGE_SELECTED_DIFF(id));

    navigate("/working/task-return", {
      state: { month: month.toString(), year: year.toString() },
    });
  };

  const _onClickTaskLated = (id: number) => {
    let year = yearSearch;
    let month = monthSearch;

    dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(ONCHANGE_SELECTED_PRIOR(id));

    navigate("/working/task-delay", {
      state: { month: month.toString(), year: year.toString() },
    });
  };
  const _onClickProposePending = (id: number) => {

    dispatch(ONCHANGE_RESET_FILTER_PROPOSE2({}));
    dispatch(ONCHANGE_FILTER({ type: "prior", data: id }));

    navigate("/propose/receiver/list");
  };

  const _onClickToChangeNavigate2 = (title: string) => {
    let year = yearSearch;
    let month = monthSearch;
    let titleFinal = title;

    dispatch(ONCHANGE_CLEAR_FILTER({}));
    if (titleFinal === "/working/task-finish") {
      navigate(titleFinal, { state: { month: "", year: "" } });
      // window.history.pushState({ month: "", year: "" }, "", titleFinal);
    } else {
      navigate(titleFinal, {
        state: { month: month.toString(), year: year.toString() },
      });
      // window.history.pushState(
      //   { month: month.toString(), year: year.toString() },
      //   "",
      //   titleFinal
      // );
    }

    // if (titleFinal === "/working/task-finish") {
    //   dispatch(ONCHANGE_SEARCH_MONTH(""));
    //   dispatch(ONCHANGE_SEARCH_YEAR(""));
    // } else {
    //   dispatch(ONCHANGE_SEARCH_MONTH(month.toString()));
    //   dispatch(ONCHANGE_SEARCH_YEAR(year.toString()));
    // }

    // navigate(titleFinal);
    // let titleFinal = title;

    // dispatch(
    //   ONCHANGE_FROM_HOME_TO_PROCESSING({
    //     title: titleFinal,
    //   })
    // );
    // navigate(titleFinal);
  };

  const _onClickToChangeNavigateWithPhongBan = (title: string) => {
    let year = yearSearch;
    let month = monthSearch;
    let titleFinal = title;

    try {
      Promise.all([
        dispatch(
          ONCHANGE_FROM_HOME_TO_PROCESSING({
            month: month,
            year: year,
            title: titleFinal,
            selectedPhongBan: selectedPhongBan,
          })
        ),
        navigate(titleFinal),
      ]);
    } catch (error: any) { }
  };

  const isMobile = DeviceUtils.isMobile();

  let classResponsiveGrid = "grid-cols-3";

  if (isMobile) {
    classResponsiveGrid = "grid-cols-2";
  }

  const _onNavigateTaskOfUser = (id: string) => {
    let year = yearSearch;
    let month = monthSearch;
    dispatch(ONCHANGE_CLEAR_FILTER({}));

    dispatch(ONCHANGE_SEARCH_SELECTED_NGUOI_NHAN(id));
    navigate("/working/task-total", {
      state: { month: month.toString(), year: year.toString() },
    });
  };

  return (
    <div className="w-full h-full flex lg:flex-column flex-col p-2 text-sm lg:space-y-0 space-y-20">
      <div className="w-full h-full flex" style={{ flexDirection: 'column' }}>
        <div className="w-full h-max flex justify-center items-center">
          <img
            className="pointer-events-none "
            style={{ width: "var(--px-180)" }}
            src={individualTask}
            alt=""
          />
        </div>
        <div className="items-center gap-4 grid grid-cols-1 lg:grid-cols-2">
          <div className="w-full h-max flex items-center gap-2">
            <p className="w-2/6 font-bold">Chọn tháng</p>
            <Select
              className="w-4/6 z-[30]"
              options={tempMonthList}
              defaultValue={convertMonthToList(monthSearch)}
              onChange={(e: any) => _onChangeSelect(e, "month")}
              isClearable
              placeholder="--- Chọn tháng  ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="w-full h-max flex items-center gap-2">
            <p className="w-2/6 font-bold">Chọn năm</p>
            <Select
              className="w-4/6 z-[29]"
              options={yearList}
              value={
                selectedYear
                  ? selectedYear
                  : yearList.find((x: any) => x?.value == yearSearch)
              }
              onChange={(e: any) => _onChangeSelect(e, "year")}
              isClearable
              placeholder="--- Chọn năm  ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="w-full h-max flex items-center gap-2">
            <p className="w-2/6 font-bold">Chọn phòng ban</p>
            <Select
              className="w-4/6 z-[28]"
              options={organizations}
              onChange={(e: any) => _onChangeSelect(e, 'phongbanid')}
              isClearable
              placeholder="--- Chọn phòng ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: (props: any, a: any) => {
                  return (
                    <div
                      {...props.innerProps}
                      className="core-select-item"
                    >
                      <LevelComponent item={props.data} />
                      {props.label}
                    </div>
                  );
                },
              }}
            />
          </div>
          <div className="w-full h-max flex items-center gap-2">
            <p className="w-2/6 font-bold">Chọn cán bộ</p>
            <Select
              className="w-4/6 z-[27]"
              options={userFollowPhongBanList?.map((user) => ({
                ...user,
                value: user.Id,
                label: user.FullName,
              }))}
              value={selectedUserFollowPhongBan}
              onChange={(e: any) => _onChangeSelect(e, 'userid')}
              isClearable
              placeholder="--- Chọn cán bộ ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: (props: any, a: any) => {
                  return (
                    <div
                      {...props.innerProps}
                      className="core-select-item"
                    >
                      <LevelComponent item={props.data} />
                      {props.label}
                    </div>
                  );
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-full lg:flex">
        {datalevel === 2 ?
          (<MediumManagerUI userId={selectedUserFollowPhongBan?.value} />) :
          (datalevel === 1 ?
            (<NormalStaffUI userId={selectedUserFollowPhongBan?.value} />) :
            (
              <>
                {/* Leftside */}
                <div className="lg:w-1/2 w-full h-max flex flex-col space-y-5 lg:pr-6 pr-0 border-slate-200">
                  {/* <div className="w-full bg-amber-500 text-cyan-800 h-max flex justify-center items-center rounded-xl text-center" style={{ padding: 5 }}>
          <p className="font-semibold text-2xl">QUẢN LÝ CÔNG VIỆC CÁ NHÂN</p>
        </div> */}

                  {/* <div className="w-full h-max flex justify-center items-center">
          <img
            className="pointer-events-none "
            style={{ width: "var(--px-180)" }}
            src={individualTask}
            alt=""
          />
        </div> */}

                  {/* Options fields */}
                  {notiList === null ? (
                    // <div className="flex flex-col w-full h-max text-sm justify-center items-center space-y-6">
                    //   <LoaderSpinner w={32} h={32} />
                    //   <p className="text-primary">Đang tải dữ liệu</p>
                    // </div>
                    <LoadingControl />
                  ) : (
                    <div className="flex flex-col w-full h-max text-sm space-y-6">
                      {/* Elements with sub */}
                      <div className="w-full h-max flex flex-col space-y-3">
                        {/* Main */}

                        {/* <div className="items-center gap-4 grid grid-cols-1 lg:grid-cols-2">
                <div className="w-full h-max flex items-center gap-2">
                  <p className="w-2/6 font-bold">Chọn tháng</p>
                  <Select
                    className="w-4/6 z-[20]"
                    options={tempMonthList}
                    defaultValue={convertMonthToList(monthSearch)}
                    onChange={(e: any) => _onChangeSelect(e, "month")}
                    isClearable
                    placeholder="--- Chọn tháng  ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="w-full h-max flex items-center gap-2">
                  <p className="w-2/6 font-bold">Chọn năm</p>
                  <Select
                    className="w-4/6 z-[19]"
                    options={yearList}
                    value={
                      selectedYear
                        ? selectedYear
                        : yearList.find((x: any) => x?.value == yearSearch)
                    }
                    onChange={(e: any) => _onChangeSelect(e, "year")}
                    isClearable
                    placeholder="--- Chọn năm  ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="w-full h-max flex items-center gap-2">
                  <p className="w-2/6 font-bold">Chọn phòng ban</p>
                  <Select
                    className="w-4/6 z-[18]"
                    options={organizations}
                    onChange={(e: any) => _onChangeSelect(e, 'phongbanid')}
                    isClearable
                    placeholder="--- Chọn phòng ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      Option: (props: any, a: any) => {
                        return (
                          <div
                            {...props.innerProps}
                            className="core-select-item"
                          >
                            <LevelComponent item={props.data} />
                            {props.label}
                          </div>
                        );
                      },
                    }}
                  />
                </div>
                <div className="w-full h-max flex items-center gap-2">
                  <p className="w-2/6 font-bold">Chọn cán bộ</p>
                  <Select
                    className="w-4/6 z-[17]"
                    options={userFollowPhongBanList?.map((user) => ({
                      value: user.Id,
                      label: user.FullName,
                    }))}
                    value={selectedUserFollowPhongBan}
                    onChange={(e: any) => _onChangeSelect(e, 'userid')}
                    isClearable
                    placeholder="--- Chọn cán bộ ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      Option: (props: any, a: any) => {
                        return (
                          <div
                            {...props.innerProps}
                            className="core-select-item"
                          >
                            <LevelComponent item={props.data} />
                            {props.label}
                          </div>
                        );
                      },
                    }}
                  />
                </div>
              </div> */}

                        <div
                          className="
                  w-full h-[45px] flex border-2 border-slate-300 rounded-md mt-2
                  hover:cursor-pointer hover:bg-white
                "
                          onClick={() =>
                            _onClickToChangeNavigate("/working/task-totalall")
                          }
                        >
                          <div className="w-[calc(100%-45px)] h-full flex items-center px-3 text-amber-500 rounded-md space-x-3 transition-all">
                            <div className="w-max h-max">
                              <TopicRoundedIcon />
                            </div>

                            <p className="lg:text-lg text-xs font-bold">
                              CÁC VIỆC ĐÃ GIAO TRONG THÁNG {monthSearch}/{yearSearch}
                            </p>
                          </div>
                          <div className="w-[45px] h-full flex justify-center items-center border-l-2 border-slate-300">
                            <p className="p-1 bg-red-500 text-white font-semibold rounded-full text-xs">
                              {notiList?.countListAllHasDelivery}
                            </p>
                          </div>
                        </div>

                        {/* Back button */}
                        {highManagerStorage === "" ? (
                          <></>
                        ) : (
                          <div className="pl-2">
                            <button
                              className="flex space-x-1 p-2 items-center text-primary hover:text-sky-600"
                              onClick={() => _onClickSubShowing("")}
                            >
                              <ArrowBackIosIcon sx={{ fontSize: 14 }} />
                              <p>Quay lại</p>
                            </button>
                          </div>
                        )}

                        {/* Sub */}
                        {highManagerStorage !== "" ? (
                          <></>
                        ) : (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {/* Element */}
                            <button
                              className="w-full text-primary p-2 font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("working")}
                            // onClick={() =>
                            //   _onClickToChangeNavigate("/working/task-proccessing")
                            // }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListProccessing}
                                  color="error"
                                >
                                  <WorkIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Các việc đang làm</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("pending")}
                            // onClick={() =>
                            //   _onClickToChangeNavigate2("/working/task-finish")
                            // }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListCompleted}
                                  color="error"
                                >
                                  <HourglassFullRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Các việc chờ duyệt</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("complete")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={
                                    notiList?.countListLateComplete +
                                    notiList?.countListEarlyComplete +
                                    notiList?.countListPunctualCompleted
                                  }
                                  color="error"
                                >
                                  <DoneRounded sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-full h-max max-w-full">
                                <p className="">Các việc đã hoàn thành</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full 
                    justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("returned")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListReturnWork}
                                  color="error"
                                >
                                  <KeyboardReturnRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-full h-max max-w-full">
                                <p className="">Các việc bị trả về</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("extend")}
                            // onClick={() =>
                            //   _onClickToChangeNavigate("/working/task-change-deadline")
                            // }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListChangeDeadLine}
                                  color="error"
                                >
                                  <CalendarMonthRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-full h-max max-w-full">
                                <p className="">Các việc xin gia hạn</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("lated")}
                            // onClick={() =>
                            //   _onClickToChangeNavigate("/working/task-delay")
                            // }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListLate}
                                  color="error"
                                >
                                  <AccessTimeFilledOutlined sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Các việc đang bị trễ</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigate("/voting/voting_work")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListAllNeedToJoin}
                                  color="error"
                                >
                                  <ThumbsUpDownRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Các việc cần biểu quyết</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("propose-pending")}
                            // onClick={() => _onClickNavigate("/propose/receiver/list")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={countPropose?.countListWait}
                                  color="error"
                                >
                                  <BalanceRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Đề xuất chờ duyệt</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("result")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={
                                    notiList?.countNumberNumberGood +
                                    notiList?.countNumberNumberMedium +
                                    notiList?.countNumberFail +
                                    notiList?.countNumberExcellent
                                  }
                                  color="error"
                                >
                                  <ContentPasteRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Kết quả công việc đã hoàn thành</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickSubShowing("report-pending")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge color="error">
                                  <ContentPasteRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Báo cáo cần đánh giá</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigate("/working/task-total-my-seft")}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge color="error"
                                  badgeContent={notiList?.countListAllHasDelivery_All_TaskMySelf}>
                                  <ContentPasteRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Việc tự tạo toàn cơ quan</p>
                              </div>
                            </button>

                            {/* <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                        onClick={() => _onClickNavigate("/working/task-totalall")}
                      >
                        <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                          <Badge badgeContent={notiList?.countListAllHasDelivery} color="error">
                            <BorderColorIcon sx={{ fontSize: "45px" }} />
                          </Badge>
                        </div>

                        <div className="w-max h-max max-w-xs">
                          <p className="">Tổng hợp công việc đã giao</p>
                        </div>
                      </button> */}

                            {/* <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                        onClick={() => _onClickNavigate("/working/task-total")}
                      >
                        <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                          <Badge badgeContent={notiList?.countListAllHasDelivery_All} color="error">
                            <FindInPageIcon sx={{ fontSize: "45px" }} />
                          </Badge>
                        </div>

                        <div className="w-max h-max max-w-xs">
                          <p className="">Tổng hợp công việc toàn công ty</p>
                        </div>
                      </button> */}
                          </div>
                        )}

                        {/* working */}
                        {highManagerStorage === "working" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {countPriority.map((x: any) => (
                              <button
                                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                                onClick={() => _onClickTaskProccessing(x?.Id)}
                              >
                                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                  <Badge
                                    badgeContent={x?.WorkingNumber}
                                    color="error"
                                  >
                                    <TaskRoundedIcon sx={{ fontSize: "45px", color: x?.Color }} />
                                  </Badge>
                                </div>

                                <div className="w-max h-max max-w-full">
                                  <p className=""> Công việc {x?.PriorityLevelName}</p>
                                </div>
                              </button>
                            ))}

                            {/* <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => _onClickTaskProccessing(1)}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge
                        // badgeContent={notiList?.countListEarlyComplete}
                        color="error"
                      >
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc ưu tiên</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => _onClickTaskProccessing(17)}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge
                        // badgeContent={notiList?.countListEarlyComplete}
                        color="error"
                      >
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc bình thường</p>
                    </div>
                  </button> */}
                          </div>
                        ) : highManagerStorage === "pending" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {countPriority.map((x: any) => (
                              <button
                                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                                onClick={() => _onClickTaskFinish(x?.Id)}
                              >
                                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                  <Badge
                                    badgeContent={x?.TaskCompletedNumber}
                                    color="error"
                                  >
                                    <TaskRoundedIcon sx={{ fontSize: "45px", color: x?.Color }} />
                                  </Badge>
                                </div>

                                <div className="w-max h-max max-w-full">
                                  <p className=""> Công việc {x?.PriorityLevelName}</p>
                                </div>
                              </button>
                            ))}
                            {/* <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskFinish(2);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc khẩn cấp</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskFinish(1);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc ưu tiên</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskFinish(17);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc bình thường</p>
                    </div>
                  </button> */}
                          </div>
                        ) : highManagerStorage === "returned" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {countDifficult.map((x: any) => (
                              <button
                                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                                onClick={() => _onClickTaskReturned(x?.Id)}
                              >
                                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                  <Badge
                                    badgeContent={x?.TaskReturnNumber}
                                    color="error"
                                  >
                                    <TaskRoundedIcon sx={{ fontSize: "45px", color: x?.Color }} />
                                  </Badge>
                                </div>

                                <div className="w-max h-max max-w-full">
                                  <p className=""> Công việc {x?.DifficultLevelName}</p>
                                </div>
                              </button>
                            ))}
                            {/* <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskReturned(6);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc khó</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskReturned(1);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc dễ</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskReturned(2);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc bình thường</p>
                    </div>
                  </button> */}
                          </div>
                        ) : highManagerStorage === "extend" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigateTaskChangeDeadline(0)}>
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge color="error"
                                  badgeContent={notiList?.countListChangeDeadLine_Past}
                                >
                                  <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Công việc các tháng trước</p>
                              </div>
                            </button>
                            <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigateTaskChangeDeadline(1)}>
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge color="error"
                                  badgeContent={notiList?.countListChangeDeadLine_Now}
                                >
                                  <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Công việc tháng hiện tại</p>
                              </div>
                            </button>
                            {/* <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all">
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc tương lai</p>
                    </div>
                  </button> */}
                          </div>
                        ) : highManagerStorage === "lated" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {countPriority.map((x: any) => (
                              <button
                                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                                onClick={() => _onClickTaskLated(x?.Id)}
                              >
                                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                  <Badge
                                    badgeContent={x?.WorkingLateNumber}
                                    color="error"
                                  >
                                    <TaskRoundedIcon sx={{ fontSize: "45px", color: x?.Color }} />
                                  </Badge>
                                </div>

                                <div className="w-max h-max max-w-full">
                                  <p className=""> Công việc {x?.PriorityLevelName}</p>
                                </div>
                              </button>
                            ))}
                            {/* <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskLated(2);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc khẩn cấp</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskLated(1);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc ưu tiên</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickTaskLated(17);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Công việc bình thường</p>
                    </div>
                  </button> */}
                          </div>
                        ) : highManagerStorage === "propose-pending" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {countPropose_priority.map((x: any) => (
                              <button
                                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                                onClick={() => _onClickProposePending(x?.Id)}
                              >
                                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                  <Badge
                                    badgeContent={x?.ProposeWaitNumber}
                                    color="error"
                                  >
                                    <TaskRoundedIcon sx={{ fontSize: "45px", color: x?.Color }} />
                                  </Badge>
                                </div>

                                <div className="w-max h-max max-w-full">
                                  <p className=""> Đề xuất {x?.PriorityLevelName}</p>
                                </div>
                              </button>
                            ))}
                            {/* <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickProposePending(2);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Đề xuất khẩn cấp</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickProposePending(1);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Đề xuất ưu tiên</p>
                    </div>
                  </button>
                  <button
                    className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                    onClick={() => {
                      _onClickProposePending(17);
                    }}
                  >
                    <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      <Badge color="error">
                        <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                      </Badge>
                    </div>

                    <div className="w-max h-max max-w-full">
                      <p className="">Đề xuất bình thường</p>
                    </div>
                  </button> */}
                          </div>
                        ) : highManagerStorage === "report-pending" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all">
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge color="error">
                                  <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Báo cáo chủ động</p>
                              </div>
                            </button>
                            <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all">
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge color="error">
                                  <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Báo cáo bị động</p>
                              </div>
                            </button>
                          </div>
                        ) : highManagerStorage === "complete" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {/* Elements */}
                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() =>
                                _onClickToChangeNavigate("/working/task-finish-early")
                              }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListEarlyComplete}
                                  color="error"
                                >
                                  <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Hoàn thành sớm</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() =>
                                _onClickToChangeNavigate("/working/task-finish-punctual")
                              }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListPunctualCompleted}
                                  color="error"
                                >
                                  <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Hoàn thành đúng tiến độ</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() =>
                                _onClickToChangeNavigate("/working/task-finish-late")
                              }
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countListLateComplete}
                                  color="error"
                                >
                                  <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Hoàn thành trễ</p>
                              </div>
                            </button>
                          </div>
                        ) : highManagerStorage === "result" ? (
                          <div
                            className={`w-full h-max grid ${classResponsiveGrid} gap-6`}
                          >
                            {/* Elements */}
                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigateTaskResult(1)}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countNumberExcellent}
                                  color="error"
                                >
                                  <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Kết quả xuất sắc</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigateTaskResult(2)}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countNumberNumberGood}
                                  color="error"
                                >
                                  <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Kết quả tốt</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigateTaskResult(3)}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countNumberNumberMedium}
                                  color="error"
                                >
                                  <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Kết quả trung bình</p>
                              </div>
                            </button>

                            <button
                              className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                              onClick={() => _onClickNavigateTaskResult(4)}
                            >
                              <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                                <Badge
                                  badgeContent={notiList?.countNumberFail}
                                  color="error"
                                >
                                  <CloseIcon sx={{ fontSize: "45px" }} />
                                </Badge>
                              </div>

                              <div className="w-max h-max max-w-full">
                                <p className="">Kết quả kém</p>
                              </div>
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* Rightside */}
                <div className="flex flex-col lg:w-1/2 w-full h-full lg:border-l-[1px] border-slate-200 px-3 lg:pl-6 pl-0 lg:pb-0 pb-5">
                  {/* <div className="w-full bg-amber-500 h-max text-cyan-800  flex justify-center items-center rounded-xl text-center" style={{ padding: 5 }}>
          <p className="font-semibold text-2xl">QUẢN LÝ CÔNG VIỆC CHUNG</p>
        </div> */}

                  {/* <div className="w-full h-max flex justify-center items-center">
          <img
            className="pointer-events-none "
            style={{ width: "var(--px-180)" }}
            src={generalTask}
            alt=""
          />
        </div> */}

                  {/* Fields */}
                  <div className="flex flex-col w-full h-max text-sm space-y-8 mt-3">
                    {/* PhongBanList */}
                    {/* <div className=" w-full h-max flex flex-col">
            <label>Chọn phòng ban</label>
            <Select
              className="z-[5]"
              options={phongBanList === undefined ? [] : phongBanList}
              onChange={(data: any) => _onChangePhongBan(data)}
              isClearable
              placeholder="--- Chọn phòng ban  ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div> */}

                    {/* <div className="w-full h-max flex items-center">
            <p className="w-2/6 font-bold">Chọn phòng ban</p>
            <Select
              className="z-[5] w-4/6"
              options={organizations}
              onChange={(e: any) => onChangeOrganization(e)}
              isClearable
              placeholder="--- Chọn phòng ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: (props: any, a: any) => {
                  return (
                    <div {...props.innerProps} className="core-select-item">
                      <LevelComponent item={props.data} />
                      {props.label}
                    </div>
                  );
                },
              }}
            />
          </div> */}
                    {/* 
          <div className="w-full h-max grid grid-cols-3 gap-4">
            {notiList?.countByPhongBan !== undefined &&
              notiList?.countByPhongBan?.length > 0 &&
              notiList?.countByPhongBan.map((x: any) => (
                <div
                  className="bg-amber-500 core-cursor-pointer"
                  onClick={(data: any) =>
                    _onChangePhongBan(data, x.ParameterID)
                  }
                  style={{
                    padding: 5,
                    borderRadius: 15,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  <p>
                    {x?.ParameterValue} ({x?.NumberOfTasks})
                  </p>
                </div>
              ))}
          </div> */}

                    {/* Other options */}

                    {
                      // selectedPhongBan === null ? <></>
                      //   : userFollowPhongBanList === undefined ? <></>
                      //     :
                      //     <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                      //       {/* Element */}
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickToChangeNavigateWithPhongBan("/working/task-proccessing")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListProccessing} color="error">
                      //             <WorkIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-max h-max max-w-full">
                      //           <p className="">Các việc đang làm</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickToChangeNavigateWithPhongBan("/working/task-finish")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListCompleted} color="error">
                      //             <HourglassFullRoundedIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-max h-max max-w-full">
                      //           <p className="">Các việc chờ duyệt</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickSubShowing2("complete2")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListLateComplete + notiList?.countListEarlyComplete + notiList?.countListPunctualCompleted} color="error">
                      //             <DoneRounded sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-full h-max max-w-full">
                      //           <p className="">Các việc đã hoàn thành</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickToChangeNavigateWithPhongBan("/working/task-return")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListReturnWork} color="error">
                      //             <KeyboardReturnRoundedIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-full h-max max-w-full">
                      //           <p className="">Các việc bị trả về</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickToChangeNavigate("/working/task-change-deadline")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListChangeDeadLine} color="error">
                      //             <CalendarMonthRoundedIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-full h-max max-w-full">
                      //           <p className="">Các việc xin gia hạn</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickNavigate("/working/task-delay")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListLate} color="error">
                      //             <AccessTimeFilledOutlined sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-max h-max max-w-full">
                      //           <p className="">Các việc đang bị trễ</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickNavigate("/voting/voting_work")}>
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListAllNeedToJoin} color="error">
                      //             <ThumbsUpDownRoundedIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-max h-max max-w-full">
                      //           <p className="">Các việc cần biểu quyết</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickNavigate("/propose/receiver/list")}>
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={notiList?.countListProposerReceived} color="error">
                      //             <BalanceRoundedIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-max h-max max-w-full">
                      //           <p className="">Đề xuất chờ duyệt</p>
                      //         </div>
                      //       </button>
                      //       <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                      //         onClick={() => _onClickSubShowing2("result2")}
                      //       >
                      //         <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                      //           <Badge badgeContent={
                      //             notiList?.countNumberNumberGood + notiList?.countNumberNumberMedium +
                      //             notiList?.countNumberFail + notiList?.countNumberExcellent} color="error">
                      //             <ContentPasteRoundedIcon sx={{ fontSize: "45px" }} />
                      //           </Badge>
                      //         </div>
                      //         <div className="w-max h-max max-w-full">
                      //           <p className="">Kết quả công việc đã hoàn thành</p>
                      //         </div>
                      //       </button>
                      //     </div>
                    }

                    {/* Back button */}
                    {highManagerStorage2 === "" ? (
                      <></>
                    ) : (
                      <div className="pl-2">
                        <button
                          className="flex space-x-1 items-center text-primary hover:text-sky-600"
                          onClick={() => _onClickSubShowing2("")}
                        >
                          <ArrowBackIosIcon sx={{ fontSize: 14 }} />
                          <p>Quay lại</p>
                        </button>
                      </div>
                    )}

                    {/* Complete */}
                    {highManagerStorage2 !== "complete2" ? (
                      <></>
                    ) : (
                      <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                        {/* Elements */}
                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() =>
                            _onClickToChangeNavigate("/working/task-finish-early")
                          }
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge
                              badgeContent={notiList?.countListEarlyComplete}
                              color="error"
                            >
                              <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Hoàn thành sớm</p>
                          </div>
                        </button>

                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() =>
                            _onClickToChangeNavigate("/working/task-finish-punctual")
                          }
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge
                              badgeContent={notiList?.countListPunctualCompleted}
                              color="error"
                            >
                              <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Hoàn thành đúng tiến độ</p>
                          </div>
                        </button>

                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() =>
                            _onClickToChangeNavigate("/working/task-finish-late")
                          }
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge
                              badgeContent={notiList?.countListLateComplete}
                              color="error"
                            >
                              <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Hoàn thành trễ</p>
                          </div>
                        </button>
                      </div>
                    )}

                    {/* Result */}
                    {highManagerStorage2 !== "result2" ? (
                      <></>
                    ) : (
                      <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                        {/* Elements */}
                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() => _onClickNavigateTaskResult(1)}
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge
                              badgeContent={notiList?.countNumberExcellent}
                              color="error"
                            >
                              <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Kết quả xuất sắc</p>
                          </div>
                        </button>

                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() => _onClickNavigateTaskResult(2)}
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge
                              badgeContent={notiList?.countNumberNumberGood}
                              color="error"
                            >
                              <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Kết quả tốt</p>
                          </div>
                        </button>

                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() => _onClickNavigateTaskResult(3)}
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge
                              badgeContent={notiList?.countNumberNumberMedium}
                              color="error"
                            >
                              <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Kết quả trung bình</p>
                          </div>
                        </button>

                        <button
                          className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                          onClick={() => _onClickNavigateTaskResult(4)}
                        >
                          <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                            <Badge badgeContent={notiList?.countNumberFail} color="error">
                              <CloseIcon sx={{ fontSize: "45px" }} />
                            </Badge>
                          </div>

                          <div className="w-max h-max max-w-full">
                            <p className="">Kết quả kém</p>
                          </div>
                        </button>
                      </div>
                    )}
                    {/* User List */}
                    <div className="w-full h-max flex flex-col">
                      {/* <label className="text-xl">Danh sách nhân viên</label> */}
                      <div
                        className="
                  w-full h-[45px] flex border-2 border-slate-300 rounded-md mt-2
                  hover:cursor-pointer hover:bg-white
                "
                        onClick={() => _onClickToChangeNavigate("/working/task-totalall")}
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <div className="w-[calc(100%-45px)] h-full flex items-center px-3 text-amber-500 rounded-md space-x-3 transition-all">
                          <div className="w-max h-max">
                            <TopicRoundedIcon />
                          </div>

                          <p className="lg:text-lg text-xs font-bold">
                            DANH SÁCH NHÂN VIÊN
                          </p>
                        </div>
                      </div>
                      {/* {selectedPhongBan === null ? (
              <></>
            ) : userFollowPhongBanList === undefined ? (
              <></>
            ) : ( */}
                      <div
                        className="tableFixHead styled-scrollbars styled-scrollbars w-full h-max shadow-md"
                        style={{
                          overflow: isMobile ? "auto" : "none",
                          width: isMobile ? "calc(100vw - 5rem)" : "auto",
                        }}
                      >
                        <table className="pb-4">
                          <thead className="text-white font-semibold text-center w-full h-[2rem]">
                            <tr>
                              <th className="border-l-[1px] border-slate-300">STT</th>
                              <th className="border-l-[1px] border-slate-300">
                                Tên cán bộ
                              </th>
                              <th className="border-l-[1px] border-slate-300">Chức vụ</th>
                              <th className="border-l-[1px] border-slate-300">
                                Tổng số giờ
                              </th>
                              <th className="border-l-[1px] border-slate-300">
                                Số giờ được giao việc
                              </th>
                              <th className="border-l-[1px] border-slate-300">
                                Số giờ việc tự tạo
                              </th>
                              <th className="border-l-[1px] border-slate-300">
                                Số giờ còn lại
                              </th>
                              {/* <th className="border-l-[1px] border-slate-300"></th> */}
                            </tr>
                          </thead>

                          <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                            {userFollowPhongBanList?.map((item: any, index: number) => (
                              <tr
                                key={index}
                                className="w-max h-[3rem] bg-white"
                                style={{
                                  backgroundColor: tempSetColor(
                                    item.Hours_in_month,
                                    item.Hours_in_month_used,
                                    "bg"
                                  ),
                                }}
                              >
                                <td className="text-center border border-slate-300">
                                  {index + 1}
                                </td>
                                <td
                                  className="border border-slate-300 font-semibold"
                                  style={{
                                    color: tempSetColor(
                                      item.Hours_in_month,
                                      item.Hours_in_month_used,
                                      "text"
                                    ),
                                  }}
                                >
                                  {item.FullName}
                                </td>
                                <td className="text-left border border-slate-300">
                                  {item.TenChucVu}
                                </td>
                                <td className="border border-slate-300">
                                  {item.Hours_in_month ?? 0}
                                </td>
                                <td className="border border-slate-300">
                                  {item.Hours_in_month_used ?? 0}
                                </td>
                                <td className="border border-slate-300">
                                  {item.Hours_in_month_used_MySelf ?? 0}
                                </td>
                                <td className="border border-slate-300">
                                  {item.Hours_in_month_remaining ?? 0}
                                </td>
                                {/* <td className="text-center border border-slate-300">
                        <button
                          className="text-primary hover:underline"
                          onClick={() => {
                            _onNavigateTaskOfUser(item.Id);
                          }}
                        >
                          Xem
                        </button>
                      </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </>
            ))}
      </div>
    </div>
  );
};

export default HighManagerUI;
