import { useContext, useEffect, useRef, useState } from "react";
import { ModalController } from "../../../../ui/homeUI/_index";
import AddIcon from "@mui/icons-material/Add";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useParams } from "react-router-dom";
import { fetchData, uploadFile } from "../../utils/helper";
import { useDispatch } from "react-redux";
import {
  ONLOAD_FILELIST,
  ONLOAD_FOLDERLIST,
} from "../../../../redux/reducers/driveReducer/_index";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const Add = () => {
  const context = useContext(ModalController);
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const _onClickCreateFolder = () => {
    context.setFuncs("createFolder");
    context.handleOpen();
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAddFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const { id } = useParams();
  const [uploadPercent, setUploadPercent] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [isUpload, setIsUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(true);

  // const _onChangeInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files: FileList | null = event.target.files;

  //   if (files && files.length > 0) {
  //     setIsUpload(true);

  //     const fileArray: File[] = Array.from(files);
  //     setTotalFiles(fileArray.length);

  //     const updateProgress = (progress: number) => {
  //       setUploadPercent(progress);
  //     };

  //     for (let index = 0; index < fileArray.length; index++) {
  //       const file = fileArray[index];

  //       try {
  //         await uploadFile(id, file, updateProgress);
  //         const response = await fetchData(id);
  //         dispatch(ONLOAD_FILELIST(response.FileClouds));
  //         dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));

  //         setCompletedFiles((prevCompletedFiles) => prevCompletedFiles + 1);

  //         if (index === fileArray.length - 1) {
  //           setIsUploading(false);
  //         }
  //       } catch (error: any) {
  //         toast.error("Có lỗi xảy ra!");
  //       }
  //     }
  //   }
  // };

  const _onChangeInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    setCompletedFiles(0);
    if (files && files.length > 0) {
      setIsUpload(true);

      const fileArray: File[] = Array.from(files);
      setTotalFiles(fileArray.length);

      const updateProgress = (progress: number) => {
        setUploadPercent(progress);
      };

      for (let index = 0; index < fileArray.length; index++) {
        const file = fileArray[index];

        const fileName = file.name.toLowerCase();
        if (
          fileName.endsWith(".bat") ||
          fileName.endsWith(".exe") ||
          fileName.endsWith(".dll") ||
          fileName.endsWith(".dmg") ||
          fileName.endsWith(".msi") ||
          fileName.endsWith(".iso")
        ) {
          toast.warning(`Tệp ${fileName} không được phép.`);
          setCompletedFiles((prevCompletedFiles) => prevCompletedFiles + 1);

          if (index === fileArray.length - 1) {
            setIsUploading(false);
          }

          continue;
        }

        try {
          await uploadFile(id, file, updateProgress);
          const response = await fetchData(id);
          dispatch(ONLOAD_FILELIST(response.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));

          setCompletedFiles((prevCompletedFiles) => prevCompletedFiles + 1);

          if (index === fileArray.length - 1) {
            setIsUploading(false);
            setTimeout(() => {
              setIsUpload(false);
            }, 5000);
          }
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      }
    }
  };

  return (
    <>
      <div className="relative text-sm" ref={dropdownRef}>
        <button
          type="button"
          className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-xl bg-white px-3 py-1.5  font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
        >
          <AddIcon />
          Mới
        </button>
        {openDropdown && (
          <div
            className="absolute left-0 mt-2 z-10 w-52 rounded-md bg-white shadow-xl shadow-[#C0C0C0]"
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}
          >
            <ul className="list-none p-0 m-0 my-2">
              <li
                className="text-gray-700 py-2 flex items-center hover:cursor-pointer hover:bg-gray-200"
                onClick={handleAddFileClick}
              >
                <div className="px-4">
                  <NoteAddIcon className="mr-4" />
                  Thêm tệp
                </div>
              </li>
              <li
                className="text-gray-700 py-2 flex items-center hover:cursor-pointer hover:bg-gray-200"
                onClick={_onClickCreateFolder}
              >
                <div className="px-4">
                  <CreateNewFolderIcon className="mr-4" />
                  Thêm thư mục
                </div>
              </li>
            </ul>
          </div>
        )}
        {isUpload && (
          <div className="fixed bottom-10 right-0 z-[50] p-5">
            <div className="shadow-lg rounded-sm w-72 md:w-96 bg-white relative overflow-hidden">
              <div className="w-full flex justify-between items-center gap-5 px-4 py-2 bg-[#f5f5f7]">
                <p className="text-gray-800 text-lg font-medium pl-4">
                  {isUploading ? "Đang tải lên..." : "Tải lên thành công!"}
                </p>

                <div className="pr-4">
                  <CloseIcon
                    className="hover:bg-gray-200 p-1 rounded-full cursor-pointer"
                    onClick={() => setIsUpload(false)}
                  />
                </div>
              </div>
              <hr />
              <div className="">
                <div className="flex items-center justify-between my-2 px-4">
                  <p className="text-gray-600 text-sm ">{`${completedFiles}/${totalFiles} tệp hoàn thành.`}</p>
                </div>
                <div className="w-full h-2 bg-blue-200 rounded-full mx-4 mb-4">
                  <div
                    className="h-full text-center text-xs text-white bg-blue-600 rounded-full"
                    style={{ width: `${uploadPercent}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          _onChangeInput(e);
          e.target.value = "";
        }}
        multiple
      />
    </>
  );
};
export default Add;
