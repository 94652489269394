import React, { useEffect, useState } from "react";

import { HISTORY_TASK_RECOVERY_LIST } from "../../../apis/_index";

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CloseIcon from '@mui/icons-material/Close';

import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import moment from "moment";
import ServiceUtils from "../../../utils/services";

const HistoryTaskRecovery = (props: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const taskId = props.taskId;
    const timeExtend = props.timeExtend;
    const handleClose = props.handleClose;
    const _loadData = props._loadData;

    const [isloading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const getHistoryTaskRecovery = () => {
        const url = HISTORY_TASK_RECOVERY_LIST.replace('{taskId}', taskId);

        setIsLoading(true);

        ServiceUtils.getV2(url, {
            headers: {
                
                Accept: "text/plain"
            }
        }).then((res:any) => {
            setIsLoading(false);

            const data = (res.data || {}).Data || [];

            setData(data);
        }).catch((err:any) => {
            setIsLoading(false);

            toast.error('Lấy danh sách lịch sử thu hồi thất bại');
        });
    }

    const renderData = () => {
        return data.map((item: any, index) => {
            return <tr key={`history-task-recovery-item-${index}`} className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">
                    <p>{moment(item.DateAdd).format('DD/MM/YYYY HH:mm:ss')}</p>
                </td>
                <td className="text-center border border-slate-300">
                    <p>{item.Reason}</p>
                </td>
            </tr>;
        });
    }

    useEffect(() => {
        getHistoryTaskRecovery();
    }, []);

    return (
        <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
            {/* header */}
            <ModalBase _loadData={_loadData} />
            <div
                className="

                flex w-full bg-sky-500 rounded-t-md
                p-12-16 items-center font-semibold text-white
                text-lg justify-between
        
      "
            >
                <p>Lịch sử thu hồi</p>
                <button
                    className="hover:bg-slate-300 p-0.5 rounded-md"
                    onClick={handleClose}
                    style={{
                        height: '1.75rem'
                    }}
                >
                    <CloseIcon />
                </button>
            </div>
            {/* content */}
            <div className="relative flex flex-col w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
                <div className="space-y-2">
                    <div className="flex">
                        <table className="w-full">
                            <thead className="text-white font-semibold text-center w-full h-[2rem]">
                                <tr>
                                    <th className="border border-slate-300">Thời gian thu hồi</th>
                                    <th className="border border-slate-300">Lý do thu hồi</th>
                                </tr>
                            </thead>
                            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                                {data.length > 0 ? renderData() : <tr>
                                    <td colSpan={2} className="text-center">Không có dữ liệu</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* footer */}
            <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
                <div className="flex w-max h-max space-x-3">
                    <button
                        className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
                        onClick={handleClose}
                    >
                        <ExitToAppTwoToneIcon fontSize="small" />
                        <p>Đóng</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HistoryTaskRecovery;
