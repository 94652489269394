import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import NotStartedIcon from "@mui/icons-material/NotStarted";

const EventCustom = ({ event }: any) => {
  return (
    <div className="flex justify-between gap-1">
      <p className="truncate">
        {event.title}
      </p>

      {event.type == 1 &&
        (event.status === 2 ? (
          <p>
            <HighlightOffIcon
              data-tooltip-id="my-tooltip-1"
              className="text-white rounded-full bg-red-500"
            />
          </p>
        ) : event.status === 3 ? (
          <p>
            <NotStartedIcon
              data-tooltip-id="my-tooltip-2"
              className="bg-white rounded-full text-sky-500"
            />
          </p>
        ) : event.status === 4 ? (
          <p>
            <TimelapseIcon
              data-tooltip-id="my-tooltip-3"
              className="text-white rounded-full bg-amber-500"
            />
          </p>
        ) : event.status === 5 ? (
          <p>
            <TaskAltIcon
              data-tooltip-id="my-tooltip-4"
              className="text-white rounded-full bg-teal-500"
            />
          </p>
        ) : (
          // event.status === 0 ? (
          //   "0"
          // ) : event.status === 1 ? (
          //   "1"
          // ) : event.status === 7 ? (
          //   "7"
          // ) : event.status === 9 ? (
          //   "9"
          // ) : event.status === 10 ? (
          //   "10"
          // )
          ""
        ))}
    </div>
  );
};

export default EventCustom;
