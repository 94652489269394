import axios from "axios";
import { toast } from "react-toastify";
import { TAKE_PARAMETER_LISTKEY_DROPDOWN, USER_READ_ALL } from "../apis/_index";
import ServiceUtils from "../utils/services";

export const readReport = async (
  apiLink: string,
  body: any,
  callback: (res: any) => void
) => {
  try {
    await ServiceUtils.postV2(apiLink, body, {
      headers: {
        Accept: "text/plain",
      },
    }).then((res: any) => {
      if (res.data.StatusCode === 200) {
        callback(res);
      } else {
        toast.error(res.data.Message);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const readReport2Array = async (
  apiLink: string,
  body: any,
  callback1: (res: any) => void,
  callback2: (res: any) => void
) => {

  try {
    await ServiceUtils.postV2(apiLink, body, {
      headers: {
        Accept: "text/plain",
      },
    }).then((res: any) => {
      if (res.data.StatusCode === 200) {
        callback1(res);
        callback2(res);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};

export const randomColor = () => {
  const hexLetters = "0123456789ABCDEF";
  let hexColor = "#";
  for (let i = 0; i < 6; i++) {
    hexColor += hexLetters[Math.floor(Math.random() * 16)];
  }
  return hexColor;
};

// phongBan list
export const takePhongban = async (callback: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken");
  const API_PHONGBAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

  await ServiceUtils.getV2(API_PHONGBAN, {
    headers: {
      Accept: "text/plain",
    },
  }).then((res: any) => {
    if (res.status === 200) {
      // setPhongbanList(res.data.Data);
      callback(res);
    }
  });
};

// userList
export const takeUser = async (callback: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken");

  await ServiceUtils.getV2(USER_READ_ALL, {
    headers: {
      Accept: "text/plain",
    },
  }).then((res: any) => {
    if (res.status === 200) {
      // setPhongbanList(res.data.Data);
      callback(res);
    }
  });
};
