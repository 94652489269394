import React, { useEffect, useRef, useState, useContext } from "react";
import parse from "html-react-parser";
import { ModalController } from "../../../ui/homeUI/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { HTMLReactParserOptions, Element } from "html-react-parser";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import AddIcon from "@mui/icons-material/Add";
import reportView from "../../../temp/baocao";

import { toast } from "react-toastify";
import axios from "axios";

import {
  BAO_CAO_CONG_VIEC_BAO_CAO,
  BAO_CAO_CONG_VIEC_CHECK_REPORT,
  BAO_CAO_CONG_VIEC_CREATE_REPORT,
  BAO_CAO_TU_TAO,
} from "../../../apis/_index";
import LoaderSpinner from "../../loader-spinner/_index";
import SendIcon from "@mui/icons-material/Send";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import ReactToPrint from "react-to-print";

import { ReportView } from "../../../temp/baocao/ReportView";

import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";
import HandleDate from "../../../utils/date/handleDate";

// report Importing
const CheckViewPort = (props: any) => {
  // Take props
  const handleClose = props.handleClose;
  const bodyOfReport = props.bodyOfReport;

  const componentRef = useRef(null);

  const context = useContext(ModalController);

  const onLoad = context.funcsLoad?.onLoad;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultOfReport, setResultOfReport] = useState<any>(null);
  const [optionParser, setOptionParser] = useState<any>(null);
  const [reportNotFinish, setReportNotFinish] = useState<any[] | null>(null);

  // funcs onChangeProccessingTask
  const _onChangeProcessingTask = (id: string, type: string, text: string) => {
    setReportNotFinish((prevState: any) => {
      return prevState?.map((item: any) =>
        item.TaskID === id && type === "different"
          ? { ...item, Report_Hard: text }
          : item.TaskID === id && type === "solution"
          ? { ...item, Report_Solution: text }
          : item.TaskID === id && type === "plan"
          ? { ...item, Report_Plan: text }
          : item
      );
    });
  };

  // funcs onGiveBoss
  const _onClickGiveReportForBoss = () => {
    const paramObject: any =
      parseInt(bodyOfReport?.reportType.value) === 1
        ? {
            TypeReportJob: parseInt(bodyOfReport?.reportType.value),
            ReportTemplateID: "6a11de52-913a-441b-b9ef-3350f1129f453",
            TypeReport: true,
            TypeReportDetail:
              "," +
              `${bodyOfReport?.fromDate} 00:00:00.000` +
              "," +
              `${bodyOfReport?.toDate} 00:00:00.000`,
            ReportName: bodyOfReport?.taskName,
            UserReviewer: bodyOfReport?.taskOwner?.Id,

            // PERIODIC: parseInt(bodyOfReport?.reportType.value),
            // SEARCH_DATEADD_FROM: bodyOfReport?.fromDate,
            // SEARCH_DATEADD_TO: bodyOfReport?.toDate,
            // TaskOwner: bodyOfReport?.taskOwner?.Id,
            // TaskManager: bodyOfReport?.taskManager?.Id,
            // TaskName: bodyOfReport?.taskName,
            // Task_ReportText: reportNotFinish,
          }
        : parseInt(bodyOfReport?.reportType.value) === 2
        ? {
            TypeReportJob: parseInt(bodyOfReport?.reportType.value),
            ReportTemplateID: "6a11de52-913a-441b-b9ef-3350f1129f453",
            TypeReport: true,
            TypeReportDetail:
              bodyOfReport?.month.label.toString() +
              "," +
              `${HandleDate.formatDate(
                HandleDate.firstDayOfMonth(
                  bodyOfReport?.month.label,
                  bodyOfReport?.year.label
                )
              )} 00:00:00.000` +
              "," +
              `${HandleDate.formatDate(
                HandleDate.firstDayOfNextMonth(
                  bodyOfReport?.month.label,
                  bodyOfReport?.year.label
                )
              )} 00:00:00.000`,
            ReportName: bodyOfReport?.taskName,
            UserReviewer: bodyOfReport?.taskOwner?.Id,

            // PERIODIC: parseInt(bodyOfReport?.reportType.value),
            // SEARCH_MONTH: bodyOfReport?.month.label,
            // SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            // TaskOwner: bodyOfReport?.taskOwner?.Id,
            // TaskManager: bodyOfReport?.taskManager?.Id,
            // TaskName: bodyOfReport?.taskName,
            // Task_ReportText: reportNotFinish,
          }
        : parseInt(bodyOfReport?.reportType.value) === 3
        ? {
            TypeReportJob: parseInt(bodyOfReport?.reportType.value),
            ReportTemplateID: "6a11de52-913a-441b-b9ef-3350f1129f453",
            TypeReport: true,
            TypeReportDetail:
              bodyOfReport?.quarter.value == 31
                ? "1" +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterStartDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000` +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterEndDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000`
                : bodyOfReport?.quarter.value == 32
                ? "2" +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterStartDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000` +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterEndDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000`
                : bodyOfReport?.quarter.value == 33
                ? "3" +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterStartDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000` +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterEndDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000`
                : "4" +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterStartDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000` +
                  "," +
                  `${HandleDate.formatDate(
                    HandleDate.getQuarterEndDate(
                      bodyOfReport?.quarter.value == 31
                        ? 1
                        : bodyOfReport?.quarter.value == 32
                        ? 2
                        : bodyOfReport?.quarter.value == 33
                        ? 3
                        : 4,
                      bodyOfReport?.year.label
                    )
                  )} 00:00:00.000`,
            ReportName: bodyOfReport?.taskName,
            UserReviewer: bodyOfReport?.taskOwner?.Id,

            // PERIODIC: parseInt(bodyOfReport?.reportType.value),
            // SEARCH_QUARTER: bodyOfReport?.quarter.value.toString(),
            // SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            // TaskOwner: bodyOfReport?.taskOwner?.Id,
            // TaskManager: bodyOfReport?.taskManager?.Id,
            // TaskName: bodyOfReport?.taskName,
            // Task_ReportText: reportNotFinish,
          }
        : {
            ReportTemplateID: "6a11de52-913a-441b-b9ef-3350f1129f453",
            TypeReportJob: parseInt(bodyOfReport?.reportType.value),
            TypeReport: true,
            TypeReportDetail:
              bodyOfReport?.year.label.toString() +
              "," +
              `${HandleDate.formatDate(
                new Date(bodyOfReport?.year.label, 0, 1)
              )} 00:00:00.000` +
              "," +
              `${HandleDate.formatDate(
                new Date(bodyOfReport?.year.label + 1, 0, 1)
              )} 00:00:00.000`,
            ReportName: bodyOfReport?.taskName,
            UserReviewer: bodyOfReport?.taskOwner?.Id,
            // PERIODIC: parseInt(bodyOfReport?.reportType.value),
            // SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            // TaskOwner: bodyOfReport?.taskOwner?.Id,
            // TaskManager: bodyOfReport?.taskManager?.Id,
            // TaskName: bodyOfReport?.taskName,
            // Task_ReportText: reportNotFinish,
          };
    console.log("object: ", paramObject);
    const fetchData = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        setIsLoading(true);
        await ServiceUtils.postV2(BAO_CAO_TU_TAO, paramObject, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Tạo báo cáo thành công");
            onLoad();

            handleClose();
            context.handleClose();
            // window.location.reload()
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  };

  const _onClickCreateReport = () => {
    const paramObject: any =
      parseInt(bodyOfReport?.reportType.value) === 1
        ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_DATEADD_FROM: bodyOfReport?.fromDate,
            SEARCH_DATEADD_TO: bodyOfReport?.toDate,
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
            TaskName: bodyOfReport?.taskName,
            Task_ReportText: reportNotFinish,
          }
        : parseInt(bodyOfReport?.reportType.value) === 2
        ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_MONTH: bodyOfReport?.month.label,
            SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
            TaskName: bodyOfReport?.taskName,
            Task_ReportText: reportNotFinish,
          }
        : parseInt(bodyOfReport?.reportType.value) === 3
        ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_QUARTER: bodyOfReport?.quarter.value.toString(),
            SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
            TaskName: bodyOfReport?.taskName,
            Task_ReportText: reportNotFinish,
          }
        : {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
            TaskName: bodyOfReport?.taskName,
            Task_ReportText: reportNotFinish,
          };

    const fetchData = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        setIsLoading(true);
        await ServiceUtils.postV2(
          BAO_CAO_CONG_VIEC_CREATE_REPORT,
          paramObject,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          }
        ).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Gửi báo cáo thành công");
            handleClose();
            context.handleClose();
            window.location.reload();
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  };
  console.log(
    "bodyOfReport?.reportType.value, ",
    bodyOfReport?.reportType.value
  );

  const _readReport = async () => {
    const paramObject: any =
      parseInt(bodyOfReport?.reportType.value) === 1
        ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_DATEADD_FROM: bodyOfReport?.fromDate,
            SEARCH_DATEADD_TO: bodyOfReport?.toDate,
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
          }
        : parseInt(bodyOfReport?.reportType.value) === 2
        ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_MONTH: bodyOfReport?.month ? bodyOfReport?.month.label : "",
            SEARCH_YEAR: bodyOfReport?.year
              ? bodyOfReport?.year.value.toString()
              : "",
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
          }
        : parseInt(bodyOfReport?.reportType.value) === 3
        ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_QUARTER: bodyOfReport?.quarter
              ? bodyOfReport?.quarter.value.toString()
              : "",
            SEARCH_YEAR: bodyOfReport?.year
              ? bodyOfReport?.year.value.toString()
              : "",
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
          }
        : {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_YEAR: bodyOfReport?.year
              ? bodyOfReport?.year.value.toString()
              : "",
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
          };

    const accessToken = localStorage.getItem("accessToken");

    const takeData = async () => {
      try {
        await ServiceUtils.postV2(BAO_CAO_CONG_VIEC_BAO_CAO, paramObject, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            ////123
            setResultOfReport(res.data.Data);

            const setTaskProcessing = () => {
              let tempArray: any[] = res.data.Data.taskProccessing.map(
                (item: any) => {
                  return {
                    TaskID: item?.ID,
                    Report_Hard: "",
                    Report_Solution: "",
                    Report_Plan: "",
                  };
                }
              );
              setReportNotFinish(tempArray);
            };

            setTaskProcessing();
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };
    return takeData();
  };
  console.log("resultOfReport, ", resultOfReport);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return (
      <button
        id="print"
        className="
    flex items-center space-x-1 w-max h-max p-2 bg-blue-500 text-white rounded-md
    hover:bg-blue-400
  "
      >
        <PrintIcon fontSize="small" />
        <p>In báo cáo</p>
      </button>
    );
  }, []);
  // --- end using React to print

  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  useEffect(() => {
    _readReport();
  }, []);

  // console.log(bodyOfReport)

  // console.log(reportNotFinish)

  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;

        if (typedDomNode.attribs) {
          if (parseInt(bodyOfReport?.reportType.value) === 1) {
            switch (typedDomNode.attribs.id) {
              case "taskOwner":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].NameOwner}
                  </span>
                );
              case "levelBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].ChucVuOwner}
                  </span>
                );
              case "phongBanBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].PhongBanOwner}
                  </span>
                );
              case "nameStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].NameOwner}
                  </span>
                );
              case "levelStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].ChucVuOwner}
                  </span>
                );
              case "phongBanStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].PhongBanOwner}
                  </span>
                );
              case "reportDay":
                return (
                  <input className="w-3 h-3" type="checkbox" checked disabled />
                );
              case "lacoImage":
                return (
                  <img
                    style={{ width: "100%", height: "8rem" }}
                    src={lacoLogo}
                    alt=""
                  />
                );
              case "giverName":
                return (
                  <p className="w-2/4">
                    Tôi tên:{" "}
                    <span className="text-black">
                      {userData.Data[0].FullName}
                    </span>
                  </p>
                );
              case "fromDate":
                return (
                  <div>
                    &nbsp;{moment(bodyOfReport?.fromDate).format("DD/MM/YYYY")}
                  </div>
                );
              case "optionsMonth":
                return <></>;
              case "optionsQuarter":
                return <></>;
              case "optionsYear":
                return <></>;
              case "nameOfReport":
                return (
                  <h1
                    id="nameOfReport"
                    className="text-orange-400 font-bold text-3xl my-[3%]"
                  >
                    {bodyOfReport?.taskName.toUpperCase()}
                  </h1>
                );
              case "toDate":
                return (
                  <div>
                    &nbsp;{moment(bodyOfReport?.toDate).format("DD/MM/YYYY")}
                  </div>
                );
              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return (
                  <span>
                    {moment(bodyOfReport?.fromDate).format("DD/MM/YYYY")}
                  </span>
                );
              case "toDateOnTable":
                return (
                  <span>
                    {moment(bodyOfReport?.toDate).format("DD/MM/YYYY")}
                  </span>
                );
              case "bodyOfTaskResponsible":
                return (
                  <tbody>
                    {resultOfReport?.tasks_responsible.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <td
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <p className="font-semibold">{item.TaskName}</p>

                            <p>
                              Người giao: <span>{item.TaskOwnerName}</span>
                            </p>
                            <p>
                              Ngày tạo:{" "}
                              {moment(item.DateAdd).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Thời hạn:{" "}
                              {moment(item.DeadLine).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Trạng thái:{" "}
                              {item.TaskStatus === 3 ? (
                                <span className="text-gray-500 font-bold">
                                  Chưa xử lý
                                </span>
                              ) : item.TaskStatus === 4 ? (
                                item.FinishedDate !== null ? (
                                  <span className="text-green-500 font-bold">
                                    Chờ duyệt
                                  </span>
                                ) : (
                                  <span className="text-amber-500 font-bold">
                                    Đang xử lý
                                  </span>
                                )
                              ) : item.TaskStatus === 5 ? (
                                <p className="text-teal-500 font-bold">
                                  Hoàn tất
                                </p>
                              ) : item.TaskStatus === 2 ? (
                                <p className="text-gray-500 font-bold">
                                  Đã giao
                                </p>
                              ) : (
                                ""
                              )}
                            </p>
                          </td>
                          <td
                            className="w-2/5 h-full"
                            style={{ border: "2px solid black" }}
                          ></td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskComplete":
                return (
                  <tbody>
                    {resultOfReport?.taskCompleted.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            <p className="font-semibold">{item.TaskName}</p>
                            <p>
                              Ngày tạo:{" "}
                              {moment(item.DateAdd).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Thời hạn:{" "}
                              {moment(item.DeadLine).format("DD-MM-YYYY")}
                            </p>
                            <div className="flex w-full h-max space-x-1">
                              <p>Trạng thái:</p>
                              {item.TaskScorecardID === 1
                                ? "Xuất sắc"
                                : item.TaskScorecardID === 2
                                ? "Tốt"
                                : item.TaskScorecardID === 3
                                ? "Trung bình"
                                : item.TaskScorecardID === 4
                                ? "Kém"
                                : ""}
                            </div>
                          </td>
                          <td
                            className="w-1/2 h-full"
                            style={{ border: "2px solid black" }}
                          ></td>

                          {/* <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.AssignmentDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                {moment(item.AssignmentDate).format("HH:mm:ss")}
                              </p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="w-full h-full">
                              {item.TaskScorecardID === 1
                                ? "Xuất sắc"
                                : item.TaskScorecardID === 2
                                ? "Tốt"
                                : item.TaskScorecardID === 3
                                ? "Trung bình"
                                : item.TaskScorecardID === 4
                                ? "Kém"
                                : ""}
                            </div>
                          </td> */}
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskProcessing":
                return (
                  <div className="flex flex-col space-y-10">
                    {resultOfReport?.taskProccessing.map(
                      (item: any, index: number) => (
                        <div key={index}>
                          <p className="text-lg font-bold">
                            {index + 1}. Tên công việc: {item.TaskName}
                          </p>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Khó Khăn</label>
                            <textarea
                              value={
                                resultOfReport?.taskProccessing[index]
                                  .Report_Hard
                              }
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "different",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            />
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Giải pháp</label>
                            <textarea
                              wrap="soft"
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "solution",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">
                              Kế hoạch hành động tháng kế tiếp
                            </label>
                            <textarea
                              wrap="soft"
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "plan",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                );
              default:
                break;
            }
          } else if (parseInt(bodyOfReport?.reportType.value) === 2) {
            switch (typedDomNode.attribs.id) {
              case "reportMonth":
                return (
                  <input className="w-3 h-3" type="checkbox" checked disabled />
                );

              case "lacoImage":
                return (
                  <img
                    style={{ width: "100%", height: "8rem" }}
                    src={lacoLogo}
                    alt=""
                  />
                );
              case "giverName":
                return (
                  <p className="w-2/4">
                    Tôi tên:{" "}
                    <span className="text-black">
                      {userData.Data[0].FullName}
                    </span>
                  </p>
                );
              case "optionsMonth":
                return <div>Tháng: {bodyOfReport?.month.label}</div>;
              case "optionsYear":
                return <div>Năm: {bodyOfReport?.year.label}</div>;
              case "fromDate":
                return <></>;
              case "optionsQuarter":
                return <></>;
              case "fromTextDate":
                return <></>;
              case "toTextDate":
                return <></>;
              case "nameOfReport":
                return (
                  <h1
                    id="nameOfReport"
                    className="text-orange-400 font-bold text-3xl my-[3%]"
                  >
                    {bodyOfReport?.taskName.toUpperCase()}
                  </h1>
                );
              case "toDate":
                return <></>;

              case "taskOwner":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].NameOwner}
                  </span>
                );
              case "levelBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].ChucVuOwner}
                  </span>
                );
              case "phongBanBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].PhongBanOwner}
                  </span>
                );
              case "nameStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].NameOwner}
                  </span>
                );
              case "levelStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].ChucVuOwner}
                  </span>
                );
              case "phongBanStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].PhongBanOwner}
                  </span>
                );

              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return (
                  <tbody>
                    {resultOfReport?.tasks_responsible.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            <p className="font-semibold">{item.TaskName}</p>
                            <p>
                              Người giao: <span>{item.TaskOwnerName}</span>
                            </p>
                            <p>
                              Ngày tạo:{" "}
                              {moment(item.DateAdd).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Thời hạn:{" "}
                              {moment(item.DeadLine).format("DD-MM-YYYY")}
                            </p>
                            <div className="flex w-full h-max space-x-1">
                              Trạng thái:{" "}
                              {item.TaskStatus === 0
                                ? "Đang tạo"
                                : item.TaskStatus === 1
                                ? "Đang chờ giao "
                                : item.TaskStatus === 2
                                ? "Đã giao"
                                : item.TaskStatus === 3
                                ? "Đã nhận"
                                : item.TaskStatus === 4
                                ? "Đang xử lý"
                                : item.TaskStatus === 5
                                ? "Đã hoàn thành"
                                : item.TaskStatus === 7
                                ? "Đã trả việc"
                                : ""}
                            </div>
                          </td>
                          <td
                            className="w-2/5 h-full"
                            style={{ border: "2px solid black" }}
                          ></td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskComplete":
                return (
                  <tbody>
                    {resultOfReport?.taskCompleted.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            {item.TaskName}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            {item.TaskOwnerName}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.AssignmentDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                {moment(item.AssignmentDate).format("HH:mm:ss")}
                              </p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="w-full h-full">
                              {item.TaskScorecardID === 1
                                ? "Xuất sắc"
                                : item.TaskScorecardID === 2
                                ? "Tốt"
                                : item.TaskScorecardID === 3
                                ? "Trung bình"
                                : item.TaskScorecardID === 4
                                ? "Kém"
                                : ""}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskProcessing":
                return (
                  <div className="flex flex-col space-y-10">
                    {resultOfReport?.taskProccessing.map(
                      (item: any, index: number) => (
                        <div key={index}>
                          <p className="text-lg font-bold">
                            {index + 1}. Tên công việc: {item.TaskName}
                          </p>
                          <p>
                            {resultOfReport?.taskProccessing[index].Report_Hard}
                          </p>
                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Khó Khăn</label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "different",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Giải pháp</label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "solution",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">
                              Kế hoạch hành động tháng kế tiếp
                            </label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "plan",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                );
              default:
                break;
            }
          } else if (parseInt(bodyOfReport?.reportType.value) === 3) {
            switch (typedDomNode.attribs.id) {
              case "taskOwner":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].NameOwner}
                  </span>
                );
              case "levelBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].ChucVuOwner}
                  </span>
                );
              case "phongBanBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].PhongBanOwner}
                  </span>
                );
              case "nameStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].NameOwner}
                  </span>
                );
              case "levelStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].ChucVuOwner}
                  </span>
                );
              case "phongBanStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].PhongBanOwner}
                  </span>
                );
              case "reportQuarter":
                return (
                  <input className="w-3 h-3" type="checkbox" checked disabled />
                );
              case "lacoImage":
                return (
                  <img
                    style={{ width: "100%", height: "8rem" }}
                    src={lacoLogo}
                    alt=""
                  />
                );
              case "giverName":
                return (
                  <p className="w-2/4">
                    Tôi tên:{" "}
                    <span className="text-black">
                      {userData.Data[0].FullName}
                    </span>
                  </p>
                );
              case "optionsQuarter":
                return <div>{bodyOfReport?.quarter.label}</div>;
              case "optionsYear":
                return <div>Năm: {bodyOfReport?.year.label}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return (
                  <h1
                    id="nameOfReport"
                    className="text-orange-400 font-bold text-3xl my-[3%]"
                  >
                    {bodyOfReport?.taskName.toUpperCase()}
                  </h1>
                );

              case "toDate":
                return <></>;
              case "optionsMonth":
                return <></>;
              case "fromTextDate":
                return <></>;
              case "toTextDate":
                return <></>;

              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return (
                  <tbody>
                    {resultOfReport?.tasks_responsible.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            <p className="font-semibold">{item.TaskName}</p>
                            <p>
                              Người giao: <span>{item.TaskOwnerName}</span>
                            </p>
                            <p>
                              Ngày tạo:{" "}
                              {moment(item.DateAdd).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Thời hạn:{" "}
                              {moment(item.DeadLine).format("DD-MM-YYYY")}
                            </p>
                            <div className="flex w-full h-max space-x-1">
                              Trạng thái:{" "}
                              {item.TaskStatus === 0
                                ? "Đang tạo"
                                : item.TaskStatus === 1
                                ? "Đang chờ giao "
                                : item.TaskStatus === 2
                                ? "Đã giao"
                                : item.TaskStatus === 3
                                ? "Đã nhận"
                                : item.TaskStatus === 4
                                ? "Đang xử lý"
                                : item.TaskStatus === 5
                                ? "Đã hoàn thành"
                                : item.TaskStatus === 7
                                ? "Đã trả việc"
                                : ""}
                            </div>
                          </td>
                          <td
                            className="w-2/5 h-full"
                            style={{ border: "2px solid black" }}
                          ></td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskComplete":
                return (
                  <tbody>
                    {resultOfReport?.taskCompleted.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            {item.TaskName}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            {item.TaskOwnerName}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.AssignmentDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                {moment(item.AssignmentDate).format("HH:mm:ss")}
                              </p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="w-full h-full">
                              {item.TaskScorecardID === 1
                                ? "Xuất sắc"
                                : item.TaskScorecardID === 2
                                ? "Tốt"
                                : item.TaskScorecardID === 3
                                ? "Trung bình"
                                : item.TaskScorecardID === 4
                                ? "Kém"
                                : ""}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskProcessing":
                return (
                  <div className="flex flex-col space-y-10">
                    {resultOfReport?.taskProccessing.map(
                      (item: any, index: number) => (
                        <div key={index}>
                          <p className="text-lg font-bold">
                            {index + 1}. Tên công việc: {item.TaskName}
                          </p>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Khó Khăn</label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "different",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Giải pháp</label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "solution",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">
                              Kế hoạch hành động tháng kế tiếp
                            </label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "plan",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                );
              default:
                break;
            }
          } else if (parseInt(bodyOfReport?.reportType.value) === 4) {
            switch (typedDomNode.attribs.id) {
              case "taskOwner":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].NameOwner}
                  </span>
                );
              case "levelBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].ChucVuOwner}
                  </span>
                );
              case "phongBanBoss":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_owner[0].PhongBanOwner}
                  </span>
                );
              case "nameStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].NameOwner}
                  </span>
                );
              case "levelStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].ChucVuOwner}
                  </span>
                );
              case "phongBanStaff":
                return (
                  <span className="font-normal">
                    {resultOfReport?.tasks_users[0].PhongBanOwner}
                  </span>
                );
              case "reportYear":
                return (
                  <input className="w-3 h-3" type="checkbox" checked disabled />
                );
              case "lacoImage":
                return (
                  <img
                    style={{ width: "100%", height: "8rem" }}
                    src={lacoLogo}
                    alt=""
                  />
                );
              case "giverName":
                return (
                  <p className="w-2/4">
                    Tôi tên:{" "}
                    <span className="text-black">
                      {userData.Data[0].FullName}
                    </span>
                  </p>
                );
              case "optionsYear":
                return <div>Năm: {bodyOfReport?.year.label}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return (
                  <h1
                    id="nameOfReport"
                    className="text-orange-400 font-bold text-3xl my-[3%]"
                  >
                    {bodyOfReport?.taskName.toUpperCase()}
                  </h1>
                );
              case "toDate":
                return <></>;
              case "optionsMonth":
                return <></>;
              case "optionsQuarter":
                return <></>;
              case "fromTextDate":
                return <></>;
              case "toTextDate":
                return <></>;
              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return (
                  <tbody>
                    {resultOfReport?.tasks_responsible.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            <p className="font-semibold">{item.TaskName}</p>
                            <p>
                              Người giao: <span>{item.TaskOwnerName}</span>
                            </p>
                            <p>
                              Ngày tạo:{" "}
                              {moment(item.DateAdd).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Thời hạn:{" "}
                              {moment(item.DeadLine).format("DD-MM-YYYY")}
                            </p>
                            <div className="flex w-full h-max space-x-1">
                              {item.TaskStatus === 0
                                ? "Đang tạo"
                                : item.TaskStatus === 1
                                ? "Đang chờ giao "
                                : item.TaskStatus === 2
                                ? "Đã giao"
                                : item.TaskStatus === 3
                                ? "Đã nhận"
                                : item.TaskStatus === 4
                                ? "Đang xử lý"
                                : item.TaskStatus === 5
                                ? "Đã hoàn thành"
                                : item.TaskStatus === 7
                                ? "Đã trả việc"
                                : ""}
                            </div>
                          </td>
                          <td
                            className="w-2/5 h-full"
                            style={{ border: "2px solid black" }}
                          ></td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskComplete":
                return (
                  <tbody>
                    {resultOfReport?.taskCompleted.map(
                      (item: any, index: number) => (
                        <tr key={index}>
                          <th
                            style={{
                              border: "2px solid black",
                              height: "2rem",
                            }}
                            scope="row"
                          >
                            {index + 1}
                          </th>
                          <td style={{ border: "2px solid black" }}>
                            {item.TaskName}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            {item.TaskOwnerName}
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.AssignmentDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                              <p>
                                {moment(item.AssignmentDate).format("HH:mm:ss")}
                              </p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="flex flex-col text-center">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </div>
                          </td>
                          <td style={{ border: "2px solid black" }}>
                            <div className="w-full h-full"></div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                );
              case "bodyOfTaskProcessing":
                return (
                  <div className="flex flex-col space-y-10">
                    {resultOfReport?.taskProccessing.map(
                      (item: any, index: number) => (
                        <div key={index}>
                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Khó Khăn</label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "different",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">Giải pháp</label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "solution",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>

                          <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                            <label className="form-label">
                              Kế hoạch hành động tháng kế tiếp
                            </label>
                            <textarea
                              onChange={(e: any) => {
                                e.preventDefault();
                                _onChangeProcessingTask(
                                  item.ID,
                                  "plan",
                                  e.target.value
                                );
                              }}
                              className="form-control border-2 border-slate-200 p-2 h-40"
                              id="exampleFormControlTextarea1"
                            ></textarea>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                );
              default:
                break;
            }
          }
        }
      },
    };

    setOptionParser(options);
  }, [resultOfReport]);

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[580px] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
        "
      >
        <p>Biểu mẫu báo cáo</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {resultOfReport === null ||
      isLoading === true ||
      optionParser === null ? (
        <div className="w-full h-[calc(100%-6rem)] flex justify-center items-center flex-col p-3 text-sm overflow-scroll">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <div className="w-full h-[calc(100%-6rem)] flex text-sm overflow-y-scroll styled-scrollbars">
          <div className="w-full h-max flex">
            <div className="w-fullzz h-max flex mx-auto">
              {parse(reportView, optionParser)}
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex footer w-full p-12-16 rounded-b-md items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-between items-center">
          <div className="w-max h-max flex space-x-3">
            <div className="hidden">
              <div className="w-full h-max" ref={componentRef}>
                {parse(reportView, optionParser)}
              </div>
            </div>
          </div>

          {/* Create button */}
          <div className="w-max h-max flex space-x-3">
            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
              hover:bg-sky-400
            "
              onClick={_onClickGiveReportForBoss}
            >
              <AddIcon fontSize="small" />
              <p>Tạo báo cáo</p>
            </button>

            {/* Close button */}
            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckViewPort;
