import React from "react";

import HomeUI from "../../../../ui/homeUI/_index";


const FinishPunctual = () => {
  return (
    <HomeUI />
  )
}

export default FinishPunctual