import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import LoaderSpinner from "../../loader-spinner/_index";

import {
  NGUOI_NHAN_VIEC_DETAIL,
  NGUOI_GIAO_VIEC_DETAIL,
  VOTING_DETAILS,
} from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import ServiceUtils from "../../../utils/services";

const VotingResultDetail = (props: any) => {
  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [_taskContents, set_TaskContents] = useState<any[]>([]);

  const appData = useSelector((state: any) => state.appReducer);

  const [task, setTask] = useState<any>(null);
  const [taskName, setTaskName] = useState<any>(null);
  const [taskVoting, setTaskVoting] = useState<any[]>([]);
  const [taskContents, setTaskContents] = useState<any[]>([]);
  const [votingTrue, setVotingTrue] = useState<any>([]);
  const [votingFalse, setVotingFalse] = useState<any>([]);
  const [votingNull, setVotingNull] = useState<any>([]);
  const [taskInfoText, setTaskInfoText] = useState<any[]>([]);
  const [taskHistory, setTaskHistory] = useState<any>([]);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const urlLink = window.location.pathname;

  // funcs

  const getUnique = (arr: any[], index: any) => {
    if (arr.length == 0) {
      return [];
    }
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);

    return unique;
  };

  const getDetail = () => {
    // const accessToken = localStorage.getItem("accessToken");
    const API = VOTING_DETAILS + taskId;
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              let _totalVoting: any = [];
              res.data.Data.taskVoting.map((item: any) => {
                _totalVoting.push(item);
              });
              setTaskVoting(_totalVoting);
              let _votingTrue: any = [];
              let _votingFalse: any = [];
              let _votingNull: any = [];
              res.data.Data.taskVoting.map((item: any) => {
                if (item.IsResult === true) {
                  _votingTrue.push(item);
                } else if (item.IsResult === false) {
                  _votingFalse.push(item);
                } else {
                  _votingNull.push(item);
                }
              });
              let _taskName: any[] = getUnique(
                res?.data?.Data?.taskVoting,
                "TaskName"
              );

              setTaskName(_taskName[0]?.TaskName);
              setVotingTrue(_votingTrue);
              setVotingFalse(_votingFalse);
              setVotingNull(_votingNull);
            }
          });
        } catch (error: any) {
          // ERROR: here
        }
      };

      takeData();
    }
  };

  useEffect(() => {
    getDetail();
  });

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[50rem] md:w-[40rem] w-screen h-[90vh] lg:h-[calc(100vh-10rem)]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
        taskContentsHistory={taskContentsHistory}
        _taskContents={_taskContents}
        taskInfoText={taskInfoText}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Chi tiết biểu quyết</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {1 === null ? (
        <div className="flex justify-center items-center w-full h-[calc(100vh-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <>
          <div className="styled-scrollbars flex flex-col lg:h-[calc(100%-6rem)] sm:h-screen p-4 text-sm space-y-3 h-full pr-5 overflow-auto border-r-[1px] border-primary ">
            {/* elements */}
            <div className="flex flex-col w-full h-max font-medium">
              <label>Tên công việc : {taskName}</label>
            </div>
            <div className="w-full uppercase text-center text-lg font-bold rounded-md">
              <p>
                Tỉ lệ biểu quyết đồng ý kết quả công việc đạt:&nbsp;
                {votingTrue &&
                taskVoting &&
                votingNull &&
                ((votingTrue?.length /
                  (taskVoting.length - votingNull?.length)) *
                  100) %
                  2 ===
                  0 ? (
                  <span
                    className={`${
                      (votingTrue?.length /
                        (taskVoting.length - votingNull?.length)) *
                        100 >=
                      50
                        ? "text-green-500"
                        : "text-red-500"
                    } `}
                  >
                    {(
                      (votingTrue?.length /
                        (taskVoting.length - votingNull?.length)) *
                      100
                    ).toFixed(0)}
                    %
                  </span>
                ) : (
                  <span
                    className={`${
                      (votingTrue?.length /
                        (taskVoting.length - votingNull?.length)) *
                        100 >=
                      50
                        ? "text-green-500"
                        : "text-red-500"
                    } `}
                  >
                    {(
                      (votingTrue?.length /
                        (taskVoting.length - votingNull?.length)) *
                      100
                    ).toFixed(2)}
                    %
                  </span>
                )}
              </p>
            </div>
            <div className="flex flex-col w-full h-max space-y-2 justify-center">
              <p className="flex items-center">
                <span className=" ">Ban biểu quyết:&nbsp;</span>
                <span className="w-max font-bold text-white bg-primary  p-1 px-2 rounded-full text-center">
                  {taskVoting.length} người
                </span>
              </p>
              <p className="flex items-center">
                <span className="">Số người chưa biểu quyết:&nbsp;</span>
                <span className="w-max font-bold text-white bg-slate-400 p-1 px-2 rounded-full text-center">
                  {votingNull?.length} người
                </span>
              </p>
              <p className="flex items-center">
                <span className="">Số phiếu đồng ý:&nbsp;</span>
                <span className="w-max font-bold text-white bg-green-400 p-1 px-2 rounded-full text-center">
                  {votingTrue?.length} phiếu
                </span>
              </p>
              <p className="flex items-center">
                <span className="">Số phiếu không đồng ý:&nbsp;</span>
                <span className="w-max font-bold text-white bg-red-400 p-1 px-2 rounded-full text-center">
                  {votingFalse?.length} phiếu
                </span>
              </p>
            </div>

            <div className="styled-scrollbars styled-scrollbars w-full shadow-md">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">
                      Người biểu quyết
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Biểu quyết
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full bg-white overflow-auto">
                  {taskVoting?.map((item, index) => (
                    <tr className="w-max h-[3rem] bg-white">
                      <td className="border border-slate-300">
                        <p className="font-semibold">
                          {index + 1}.&nbsp;{item.UserCheck}
                        </p>
                      </td>
                      <td className="border border-slate-300 text-center">
                        {item.IsResult === true ? (
                          <p className="text-green-600 font-bold">Đồng ý</p>
                        ) : item.IsResult === false ? (
                          <p className="text-red-600 font-bold">Không đồng ý</p>
                        ) : (
                          <p className="text-slate-400">Chưa đánh giá</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <button
          className="
          flex items-center space-x-1 w-max h-max px-2 p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-400 active:bg-slate-500
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default VotingResultDetail;
