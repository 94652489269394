import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import LoaderSpinner from "../../loader-spinner/_index";

import {
    NGUOI_GIAO_VIEC_LIST,
    NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
} from "../../../apis/_index";
import {
    ONLOAD_TASKLIST_ALL,
    ONCHANGE_VIEC_CAN_GIAO,
} from "../../../redux/reducers/workingReducer/_index";

import SendIcon from "@mui/icons-material/Send";
import { APPROVE_TASK } from "../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";
import ServiceUtils from "../../../utils/services";

const AlertUploadFile = (props: any) => {
    const handleClose = props.handleClose;
    const callBack = props.callBack;

    const _onConfirm = () => {
        if (callBack) {
            callBack();
        }
    }

    return (
        <div
            className="
      flex bg-emerald-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
        >
            {/* icon */}
            <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
                <SendIcon sx={{ fontSize: 100 }} />
            </div>
            {/* content */}
            <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md pl-2 pr-2">
                <p>Bạn có tệp tin chưa tải lên, bạn vẫn muốn tiếp tục thực hiện?</p>
                <div className="w-full h-max flex justify-center items-center space-x-12">
                    <button
                        style={{
                            minWidth: '8rem'
                        }}
                        className={`
            w-[5rem] h-max p-2 rounded-md bg-slate-500  hover:bg-slate-40
            flex justify-center items-center
          `}
                        onClick={_onConfirm}
                    >
                        <p>Vẫn tiếp tục</p>
                    </button>
                    <button
                        className={`w-[5rem] h-max p-2 rounded-md bg-red-500 hover:bg-red-400`}
                        onClick={handleClose}
                    >
                        Đóng
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AlertUploadFile;