import React, { useEffect, useRef, useState, useContext } from "react";
import parse from "html-react-parser";
import { ModalController } from "../../../ui/homeUI/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { HTMLReactParserOptions, Element } from "html-react-parser";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import AddIcon from "@mui/icons-material/Add";
import reportView from "../../../temp/baocao";
import DeleteIcon from "@mui/icons-material/Delete";

import { toast } from "react-toastify";
import axios from "axios";

import {
  KE_HOACH_SEND_BOSS,
  KE_HOACH_GUI,
  BAO_CAO_CONG_VIEC_BAO_CAO,
  BAO_CAO_CONG_VIEC_CHECK_REPORT,
  BAO_CAO_CONG_VIEC_CREATE_REPORT,
  KE_HOACH_CAP_NHAT,
  KE_HOACH_CHI_TIET,
} from "../../../apis/_index";
import LoaderSpinner from "../../loader-spinner/_index";
import SendIcon from "@mui/icons-material/Send";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import ReactToPrint from "react-to-print";

import { ReportView } from "../../../temp/baocao/ReportView";

import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

// report Importing
const EditPlanning = (props: any) => {
  // Take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const bodyOfReport = props.bodyOfReport;

  const componentRef = useRef(null);

  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultOfReport, setResultOfReport] = useState<any>(null);
  const [optionParser, setOptionParser] = useState<any>(null);
  const [reportNotFinish, setReportNotFinish] = useState<any[] | null>(null);

  const [listOfAction, setListOfAction] = useState<any[]>([]);
  const [taskName, setTaskName] = useState<string | null>("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      ID: taskId,
    };
    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(KE_HOACH_CHI_TIET, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setResultOfReport(res.data.Data);
            setTaskName(
              res.data.Data.tasks_report[0].TaskName === null
                ? ""
                : res.data.Data.tasks_report[0].TaskName
            );
            setListOfAction(res.data.Data.taskProccessing);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData();
  }, []);

  // funcs onChangeProccessingTask
  const _onChangeProcessingTask = (
    _index: number,
    type: string,
    text: string
  ) => {
    setListOfAction((prevState: any) => {
      return prevState?.map((item: any, index: number) =>
        index === _index && type === "content"
          ? { ...item, Job_Content: text }
          : index === _index && type === "stepForward"
          ? { ...item, Job_Step: text }
          : index === _index && type === "compose"
          ? { ...item, Job_Purpose: text }
          : item
      );
    });
  };

  const _onClickAddingAction = () => {
    let tempArray = [...listOfAction];

    tempArray.push({
      Job_Content: "",
      Job_Step: "",
      Job_Purpose: "",
    });

    setListOfAction(tempArray);
  };

  const _onDeleteAction = (index: number) => {
    let tempArray = [...listOfAction];
    tempArray.splice(index, 1);
    setListOfAction(tempArray);
  };

  // funcs onGiveBoss
  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  // funcs
  const _onClickUpdateStep = () => {
    const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      PlanID: taskId,
      TaskName: taskName,
      Plan_Update: listOfAction,
    };

    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(KE_HOACH_CAP_NHAT, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.Data) {
            toast.success("Cập nhật thành công");
            handleClose();
            window.location.reload();
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData();
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        "
      >
        <p>Biểu mẫu kế hoạch</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {resultOfReport === null || taskName === null ? (
        <div className="w-full h-[calc(100%-6rem)] flex justify-center items-center flex-col p-3 text-sm overflow-scroll">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
          <div className="w-full h-max flex flex-col">
            {/* Body */}
            <div className="w-full h-max flex flex-col text-lg font-medium space-y-3 border-b-2 border-slate-300 mb-4 ">
              <p>
                Tên kế hoạch:
                <span>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={taskName}
                    onChange={(e: any) => {
                      e.preventDefault();
                      setTaskName(e.target.value);
                    }}
                  />
                </span>
              </p>

              <div className="w-full h-max flex justify-between items-center">
                <p>
                  Theo yêu câu của: {resultOfReport?.tasks_users[0].NameManager}
                </p>
                <p>Chức vụ: {resultOfReport?.tasks_users[0].ChucVuManager}</p>
              </div>

              <div className="w-full h-max flex justify-between items-center">
                <p>Kính gửi: {resultOfReport?.tasks_users[0].NameOwner}</p>
                <p>Chức vụ: {resultOfReport?.tasks_users[0].ChucVuOwner}</p>
              </div>

              <div className="w-full h-max flex justify-between items-center">
                <p>Tôi tên: {userData.Data[0].FullName}</p>
                <p>Chức vụ: {userData.Data[0].TenChucVu}</p>
                <p>Phòng ban: {userData.Data[0].TenPhongBan}</p>
              </div>
            </div>

            <div className="w-full h-max flex flex-col space-y-8">
              {listOfAction.map((item: any, index: number) => (
                <>
                  <div className="w-full h-max flex flex-col">
                    {/* Spaces */}
                    <div className="w-full h-max flex">
                      <div className="w-5/6 h-max flex flex-col">
                        {/* Elements */}
                        <div className="w-full h-max">
                          <label className="text-sm">Nội dung công việc</label>
                          <textarea
                            className="w-full h-40 border-2 border-slate-200 p-1"
                            placeholder="Nhập nội dung công việc"
                            value={item.Job_Content}
                            onChange={(e: any) => {
                              e.preventDefault();
                              _onChangeProcessingTask(
                                index,
                                "content",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div className="w-full h-max">
                          <label className="text-sm">Các bước triển khai</label>
                          <textarea
                            className="w-full h-40 border-2 border-slate-200 p-1"
                            placeholder="Nhập các bước triển khai"
                            value={item.Job_Step}
                            onChange={(e: any) => {
                              e.preventDefault();
                              _onChangeProcessingTask(
                                index,
                                "stepForward",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div className="w-full h-max">
                          <label className="text-sm">Mục đích</label>
                          <textarea
                            className="w-full h-40 border-2 border-slate-200 p-1"
                            placeholder="Nhập mục đích"
                            value={item.Job_Purpose}
                            onChange={(e: any) => {
                              e.preventDefault();
                              _onChangeProcessingTask(
                                index,
                                "compose",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-1/6 h-full flex justify-center items-center">
                        <button
                          className="w-full h-full text-red-600 hover:text-red-500 p-2"
                          onClick={() => _onDeleteAction(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <button
              className="
                bg-green-500 w-max h-max p-2 text-white mt-5
                rounded-md hover:bg-green-400 
              "
              onClick={_onClickAddingAction}
            >
              Thêm kế hoạch
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-between items-center">
          <div className="w-max h-max flex space-x-3">
            <div className="hidden">
              <div className="w-full h-max" ref={componentRef}>
                {parse(reportView, optionParser)}
              </div>
            </div>
          </div>

          {/* Create button */}
          <div className="w-max h-max flex space-x-3">
            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-amber-600 text-white rounded-md
              hover:bg-amber-500
            "
              onClick={_onClickUpdateStep}
            >
              <AddIcon fontSize="small" />
              <p>Lưu cập nhật</p>
            </button>

            {/* <button
              className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-green-500 text-white rounded-md
              hover:bg-green-400
            "
              onClick={_onClickGiveReportForBoss}
            >
              <SendIcon fontSize="small" />
              <p></p>
            </button> */}

            {/* Close button */}
            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPlanning;
