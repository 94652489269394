import { REFRESH_TOKEN } from "../../apis/_index";
import { STORAGE_KEYS, VARIABLES } from "../../constants/constants";
import { USER_DATALEVELS } from "../../constants/dataConstants";
import { bodyRefreshToken } from "../../data/_index";

const getUserDataLevel = async (): Promise<number> => {
  let dataLevel = -1;

  const currentUserString = await localStorage.getItem(
    STORAGE_KEYS.currentUser
  );

  if (!currentUserString) {
    return dataLevel;
  }

  try {
    const currentUser = JSON.parse(currentUserString);

    dataLevel = ((currentUser.Data || [])[0] || {}).DataLevel || -1;
  } catch {}

  return dataLevel;
};

const getUserID = async (): Promise<string> => {
  let userId = "";

  const currentUserString = await localStorage.getItem(
    STORAGE_KEYS.currentUser
  );

  if (!currentUserString) {
    return "";
  }

  try {
    const currentUser = JSON.parse(currentUserString);

    userId = ((currentUser.Data || [])[0] || {}).Id || "";
  } catch {}

  return userId;
};

const isHavePermissionAssignTask = async (): Promise<Boolean> => {
  const dataLevel = await getUserDataLevel();

  return (
    dataLevel == USER_DATALEVELS.manager || dataLevel == USER_DATALEVELS.boss
  );
};

const getHoursRemaining = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const hours = userData?.Data[0]?.Hours_in_month_remaining;
  return hours;
};
const getHoursUse = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const hours =
    (userData?.Data[0]?.Hours_in_month_used || 0) +
    (userData?.Data[0]?.Hours_in_month_used_MySelf || 0);
  return hours;
};
const getHoursWork = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const hours = userData?.Data[0]?.Hours_in_month;
  return hours;
};

const getAccessToken = () => {
  return localStorage.getItem(STORAGE_KEYS.accessToken);
};

const getRefreshToken = () => {
  return localStorage.getItem(STORAGE_KEYS.refreshToken);
};

const setAccessToken = (token: any) => {
  return localStorage.setItem(STORAGE_KEYS.accessToken, token);
};

const setRefreshToken = (token: any) => {
  return localStorage.setItem(STORAGE_KEYS.refreshToken, token);
};

const getCurrentUser = async (): Promise<any> => {
  const userDataString =
    (await localStorage.getItem(STORAGE_KEYS.currentUser)) || "{}";

  const userData = JSON.parse(userDataString);

  const data = (userData.Data || [])[0];

  return data;
};

const getOrganizationId = async (): Promise<any> => {
  const currentUser = await getCurrentUser();

  if (currentUser) {
    return currentUser.PhongBan;
  }

  return null;
};

const logout = async (): Promise<any> => {
  localStorage.clear();

  localStorage.removeItem(STORAGE_KEYS.accessToken);

  localStorage.removeItem(STORAGE_KEYS.currentUser);

  window.location.href = "/";
};

const refreshToken = async (): Promise<any> => {
  return new Promise((resolve) => {
    const refreshToken = getRefreshToken();

    if (
      !refreshToken ||
      refreshToken == "null" ||
      refreshToken == "undefined"
    ) {
      return resolve({
        status: false,
      });
    }

    const body = bodyRefreshToken(refreshToken ?? "");

    const urlSearchParams = new URLSearchParams();

    const keys = Object.keys(body);

    for (let i = 0; i < keys.length; i++) {
      urlSearchParams.append(keys[i], body[keys[i]]);
    }

    fetch(REFRESH_TOKEN, {
      method: "POST",
      body: urlSearchParams,
    })
      .then(async (res: any) => {
        const json = await res.json();

        if (json && json.access_token && json.refresh_token) {
          setAccessToken(json.access_token);
          setRefreshToken(json.refresh_token);
        }

        return resolve({
          status: true,
          res: json,
        });
      })
      .catch((err: any) => {
        return resolve({
          status: false,
          err,
        });
      });
  });
};

const getCurrentUserID = () => {
  let userId = "";

  const currentUserString = localStorage.getItem(STORAGE_KEYS.currentUser);

  if (!currentUserString) {
    return "";
  }

  const currentUser = JSON.parse(currentUserString);

  userId = ((currentUser.Data || [])[0] || {}).Id || "";

  return userId;
};

export {
  getUserDataLevel,
  isHavePermissionAssignTask,
  getUserID,
  getAccessToken,
  getCurrentUser,
  getOrganizationId,
  logout,
  refreshToken,
  getHoursRemaining,
  getHoursUse,
  getHoursWork,
  getCurrentUserID,
};
