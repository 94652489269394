import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ui importing
import LoginUI from "../../ui/loginUI/_index";

const LoginPage = () => {
  
  const navigate = useNavigate()

  // check token
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")

    if (accessToken === null) {
      // do nothing
    } else {
      navigate("/allPage", { replace: true })
    }
  })

  return (
    <div className="w-full h-full">
      <LoginUI />
    </div>
  )
}

export default LoginPage;
