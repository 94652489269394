
import { useContext } from 'react';
import { toast } from 'react-toastify';

import AccessTime from '@mui/icons-material/AccessTime';
import { ModalController } from '../../ui/homeUI/_index';

const ReviewHistoryTaskPreviousComponent = (props: any) => {
    const context = useContext(ModalController);

    const onOpenHistoryTaskPrevious = () => {
        const id = props.id;
        const onOpenHistoryTaskPrevious = props.onOpenHistoryTaskPrevious;

        if (!id) {
            toast.error('Công việc này hiện không tìm thấy !');

            return;
        }

        if (onOpenHistoryTaskPrevious && typeof onOpenHistoryTaskPrevious === 'function') {
            onOpenHistoryTaskPrevious();
        } else {
            context.setFuncs("readTotalTask");
            context.setTaskId(id);
            context.handleOpen();
        }
    }

    if (!props.id) {
        return null;
    }

    return (
        <div>
            <button
                className="
                  flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-rose-500 text-white rounded-md
                  hover:bg-rose-400
                "
                onClick={onOpenHistoryTaskPrevious} style={{
                    height: '36px'
                }}
            >
                <AccessTime fontSize="small" />
                <p>Xem lịch sử công việc trước</p>
            </button>
        </div>
    );
}

export default ReviewHistoryTaskPreviousComponent;