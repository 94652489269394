import React, { useEffect, useRef, useState } from "react";
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";
import { readReport, randomColor } from "../../../../funcs/reportCalling";
import { takePhongban } from "../../../../funcs/reportCalling";
import { REPORT_SUMREDWORK } from "../../../../apis/_index";

import moment from "moment";
import SumDifTask from "../../../../pages/reportPage/dificult/userDiftask";
import HandleDate from "../../../../utils/date/handleDate";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const AllDifTaskUI = (props: any) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Lấy năm hiện tại
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
  const day = currentDate.getDate().toString().padStart(2, "0"); // Lấy ngày hiện tại

  const [data, setData] = useState<any[] | null>(null);

  const [arraySoNgay, setArraySoNgay] = useState<any[] | null>(null);
  const [arrayThang, setArrayThang] = useState<any[] | undefined>(undefined);
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);

  // const [fromDate, setFromDate] = useState<any | null>(
  //   new Date().getFullYear()
  // );
  // const [toDate, setToDate] = useState<any | null>(new Date().getFullYear());
  const [fromDate, setFromDate] = useState<any | null>(
    HandleDate.firstDayOfYear
  );
  const [toDate, setToDate] = useState<any | null>(HandleDate.lastDayOfYear);

  const shouldLog = useRef(true);

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: false,
      };

      readReport(REPORT_SUMREDWORK, body, (res: any) => {
        setData(res.data.Data);
      });

      takePhongban((res: any) => setPhongBanList(res.data.Data));
    }
  });

  // follow first call
  useEffect(() => {
    if (data === null) {
    } else {
      let tempArraySoNgay: any[] = [];
      let tempArrayThang: any[] = [];
      let tempArrayChuaHoanThanh: any[] = [];

      data.forEach((item: any) => {
        let tempSoNgay = item.SoNgay;
        let tempThang = "Tháng " + item.Thang;
        tempArraySoNgay.push(tempSoNgay);
        tempArrayThang.push(tempThang);
      });

      setArraySoNgay(tempArraySoNgay);
      setArrayThang(tempArrayThang);
    }
  }, [data]);

  // filter
  const _onClickSubmit = () => {
    let body: any;

    if (selectedPhongban !== -1) {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        PhongBan: selectedPhongban,
        _isCurUser: false,
      };
    } else {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: false,
      };
    }

    readReport(REPORT_SUMREDWORK, body, (res: any) => {
      setData(res.data.Data);
    });

    takePhongban((res: any) => setPhongBanList(res.data.Data));
  };

  return (
    <>
      <br></br>
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label>Từ ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Từ Ngày"
                value={fromDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setFromDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label>Đến ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Đến Ngày"
                value={toDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setToDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
          <label>Phòng ban</label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            value={selectedPhongban}
            onChange={(e: any) => {
              e.preventDefault();
              setSelectedPhongban(parseInt(e.target.value));
            }}
          >
            <option className="text-gray-300" value={-1}>
              --- Xem tất cả ---
            </option>
            {phongBanList === null ? (
              <></>
            ) : (
              phongBanList.map((item: any, index: any) => (
                <option key={index} value={item.ParameterId}>
                  {item.ParameterValue}
                </option>
              ))
            )}
          </select>
        </div>
        <div
          className="flex flex-col lg:w-1/2 w-full lg:pr-7 pr-0 h-max"
          style={{ textAlign: "center" }}
        >
          <label>&nbsp; </label>
          <button
            className="
            flex items-center p-2 bg-primary rounded-md text-white transition-all
              hover:bg-sky-400 active:bg-sky-700
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>

      <hr style={{ margin: "20px" }}></hr>

      {/* chartjs */}
      <div className="lg:flex w-full lg:h-5/6 h-[20rem] p-3">
        {/* left side */}
        <div className="flex flex-col lg:w-1/2 sm:w-full h-full">
          <div className="flex w-full lg:h-1/2 sm:h-full">
            <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
              <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold lg:text-base text-xs">
                BIỂU ĐỒ SỐ LƯỢNG CÔNG VIỆC THEO ĐỘ KHÓ
              </div>
              <div className="flex justify-center items-center w-full h-[calc(100%-2rem)">
                <Bar
                  className="w-full h-full"
                  datasetIdKey="id"
                  data={{
                    labels: ["PA", "PB", "PC", "PD"],
                    datasets: [
                      {
                        label: "Dễ",
                        data: [3, 5, 7, 9],
                        backgroundColor: ["#0ea449"],
                      },

                      {
                        label: "TB",
                        data: [7, 8, 9, 9],
                        backgroundColor: ["#01023c"],
                      },
                      {
                        label: "Khó",
                        data: [7, 8, 9, 9],
                        backgroundColor: ["#f4423c"],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: "white",
                        font: {
                          size: 13,
                          weight: "bold",
                        },
                        //   formatter: (value: any) => `${value}`,
                        formatter: function (value: any, context) {
                          return value === 0 ? "" : value;
                        },
                        anchor: "end",
                        align: "start",
                      },
                      legend: {
                        display: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="lg:w-1/2 sm:w-full h-full">
          <div className="w-full h-full flex flex-col">
            <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
              BIỂU ĐỔ TỈ LỆ ĐỘ KHÓ TỔNG HỢP
            </div>
            <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
              <Pie
                className="w-full h-full"
                datasetIdKey="id"
                data={{
                  labels: ["Dễ", "Trung Bình", "Khó"],
                  datasets: [
                    {
                      label: "Tỉ lệ",
                      data: [1, 2, 3],
                      backgroundColor: ["#FFEBCD", "#5F9EA0", "#7FFF00"],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    datalabels: {
                      color: "white",
                      font: {
                        size: 15,
                        weight: "bold",
                      },
                      formatter: (value: any) => `${value}%`,
                      clamp: true,
                    },
                    legend: {
                      position: "bottom",
                      labels: {
                        usePointStyle: true,
                        font: {
                          size: 11,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllDifTaskUI;
