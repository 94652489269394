import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { FOLDER_IDS } from "../../../../constants/dataConstants";
import { useParams } from "react-router-dom";
import { FC } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { STORAGE_KEYS } from "../../../../constants/constants";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import { formatFileSize } from "../../utils/file";
import DownloadIcon from "@mui/icons-material/Download";

interface FileItemProps {
  activeRef: any;
  item: any;
  ChangeType: boolean;
  itemActive: any;

  setItemActive: any;
  setContextMenuItem: any;

  _handleDragStart: any;
  _handleDragEnd: any;
  _handleDrop: any;
  _handleContextMenu: any;

  _onClickRename: (item: any) => void;
  _onClickShare: (item: any) => void;
  _onClickDownload: (item: any) => void;
}

const FileItem: FC<FileItemProps> = ({
  activeRef,
  item,
  ChangeType,
  itemActive,

  setItemActive,
  setContextMenuItem,

  _handleDragStart,
  _handleDragEnd,
  _handleDrop,
  _handleContextMenu,

  _onClickRename,
  _onClickShare,
  _onClickDownload,
}) => {
  const { id } = useParams();
  const isDriveTrash = id == FOLDER_IDS.trash;
  const isDriveShare = id == FOLDER_IDS.share;

  const yourID =
    (
      (JSON.parse(localStorage.getItem(STORAGE_KEYS.currentUser || "") || "")
        .Data || [])[0] || {}
    ).Id || "";

  return (
    <>
      {ChangeType ? (
        <tr
          className={`file-item-class w-full h-[3rem] hover:cursor-pointer border-y border-slate-300 group ${
            itemActive === item
              ? "bg-blue-200 hover:bg-blue-200"
              : "hover:bg-gray-200"
          }`}
          draggable
          onDragStart={(e) => _handleDragStart(e, item)}
          onDragEnd={() => _handleDragEnd()}
          onDrop={(e) => _handleDrop(e, item, false)}
          onClick={() => setItemActive(item)}
          ref={activeRef}
          onContextMenu={(e) => {
            setItemActive(item);
            setContextMenuItem(item);
            _handleContextMenu(e);
            e.stopPropagation();
          }}
        >
          <td className="flex justify-start gap-2 items-center flex-1">
            <DescriptionIcon />
            <p className="truncate max-w-[200px] sm:max-w-[350px] md:max-w-[400px] lg:max-w-[200px] xl:max-w-[300px]">
              {item.FileName}
            </p>
          </td>

          {(isDriveTrash || isDriveShare) && (
            <td className="hidden lg:table-cell">
              {item.OwnerId == yourID ? "Tôi" : item.OwnerName}
            </td>
          )}
          <td className="hidden lg:table-cell">
            {moment(item.LastChangeDate).format("DD/MM/YYYY")}{" "}
            {moment(item.LastChangeDate).format("HH:mm:ss")}
          </td>
          <td className="hidden lg:table-cell">
            {formatFileSize(item.FileSize)}
          </td>
          <td className="table-cell">
            <div className="justify-end flex">
              {!isDriveTrash && (
                <>
                  <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => _onClickShare(item)}
                  >
                    <PersonAddAltIcon className="p-0.5" />
                  </div>
                  <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => _onClickDownload(item)}
                  >
                    <DownloadIcon className="p-0.5" />
                  </div>
                  <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => _onClickRename(item)}
                  >
                    <DriveFileRenameOutlineIcon className="p-0.5" />
                  </div>
                  {/* <div className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100">
                  <StarBorderIcon className="p-0.5" />
                </div> */}
                  {/* <div
                  key={index}
                  className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer relative"
                  ref={dropdownRefFile}
                  onClick={() => handleClick(item.FileId)}
                >
                  <MoreVertIcon className="p-0.5" />
                  {visibleItems.includes(item.FileId) && (
                    <div>{item.FileId}</div>
                  )}
                </div> */}
                </>
              )}

              <div>
                <button
                  className="openContextMenu hover:bg-gray-300 p-1 rounded-full cursor-pointer"
                  onClick={(e) => {
                    setItemActive(item);
                    setContextMenuItem(item);
                    _handleContextMenu(e);
                  }}
                >
                  <MoreVertIcon />
                </button>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <div
          className={`file-item-class p-3 rounded-lg cursor-pointer  ${
            itemActive === item
              ? "bg-blue-200 hover:bg-blue-200"
              : "bg-[#f5f5f7] hover:bg-gray-200"
          }`}
          draggable
          onDragStart={(e) => _handleDragStart(e, item)}
          onDragEnd={() => _handleDragEnd()}
          onDrop={(e) => _handleDrop(e, item, false)}
          onClick={() => setItemActive(item)}
          ref={activeRef}
          onContextMenu={(e) => {
            setItemActive(item);
            setContextMenuItem(item);
            _handleContextMenu(e);
            e.stopPropagation();
          }}
        >
          <div className="flex items-center justify-between gap-2">
            <div className="flex justify-start gap-2 items-center flex-1 overflow-hidden overflow-ellipsis">
              <DescriptionIcon />
              <p className="truncate">{item.FileName}</p>
            </div>

            <div>
              <button
                className="openContextMenu hover:bg-gray-300 p-1 rounded-full cursor-pointer"
                onClick={(e) => {
                  setItemActive(item);
                  setContextMenuItem(item);
                  _handleContextMenu(e);
                }}
              >
                <MoreVertIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FileItem;
