const hoverMenuContextScrollTable = (index: any, length: any): any => {
    if (length - 1 == index) {
        let timeOut: any = null;

        setTimeout(() => {
            const table = document.getElementById('table');

            if (table) {
                table.scrollTop = 99999;
            }

            clearTimeout(timeOut);

            timeOut = null;
        }, 100);
    }
}

const HandleUI = {
    hoverMenuContextScrollTable
}

export default HandleUI;