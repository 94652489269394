
import EditTask from './editTask';

const EditTaskTransfer = (props: any) => {
    const taskId = props.taskId;
    const handleClose = props.handleClose;

    return <EditTask isTransfer={true} typeTransfer={1} taskId={taskId} handleClose={handleClose} />;
}

export default EditTaskTransfer;