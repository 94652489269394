const getListQuery = (url: any) => {
    const objectUrl = new URL(url);

    let search = objectUrl.search ?? "";

    if (search.indexOf("?") >= 0) {
        search = search.substring(1);
    }

    const searchs = search.split("&").filter((p: any) => p);

    const queries = [];

    let item = '';
    let items = [];

    for (let i = 0; i < searchs.length; i++) {
        item = searchs[i];
        items = item.split('=');

        queries.push({
            key: items[0],
            value: items[1]
        });
    }

    return queries;
};

const getUrlOriginalAndParam = (url: any) => {
    const objectUrl = new URL(url);

    return objectUrl.origin + objectUrl.pathname;
}

const mapUrlWithQuery = (url: any, queries: any) => {
    const queryArray = [];

    for (let i = 0; i < queries.length; i++) {
        queryArray.push(queries[i].key + '=' + queries[i].value);
    }

    const queryString = queryArray.join('&');

    return url + (queryString ? ('?' + queryString) : '');
}

const URLHepers = {
    getListQuery,
    getUrlOriginalAndParam,
    mapUrlWithQuery
}

export default URLHepers;