import React from "react";

const Error404UI = () => {
  return (
    <div className="w-full h-full flex flex-col p-4">
      <p className="text-xl text-primary font-bold">Tính năng đang cập nhật</p>
      <p className="text-primary">Vui lòng quay trở lại sau.</p>
    </div>
  )
}

export default Error404UI