import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ONLOAD_SELECTED_RECEIVER,
  ONLOAD_SELECTED_PRIOR,
  ONCHAGE_FILTER_STATUS,
  ONCHAGE_FILTER_RESULT,
  ONCHANGE_TASK_NAME,
} from "../../redux/reducers/workingReducer/_index";

import { onTouchForm } from "../../funcs/onTochForm";

import { dataStatus, dataResult } from "../../data/_index";

import Select from "react-select";

const WorkingReceiverAllTaskJSX = () => {
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const urlLink = window.location.pathname;

  // edit
  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });
  const tempPriorList = workingData.priorList?.map((item: any) => {
    return {
      ...item,
      label: item.PriorityLevelName,
      value: item.PriorityLevelName,
    };
  });

  return (
    <div className="grid lg:flex w-full h-max gap-2 lg:gap-5 mb-2">
      <div className="flex flex-col lg:w-1/4 w-full  h-max">
        <label className="mb-1">Tên công việc</label>
        <input
          className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400 "
          placeholder="Nhập tên công việc"
          // value={workingData.searchFilter.taskName}
          // onChange={(e: any) => {
          //   e.preventDefault()
          //   dispatch(ONCHANGE_TASK_NAME(e.target.value))
          // }}
          value={workingData.searchFilter.taskName}
          onChange={(e: any) => {
            e.preventDefault();
            dispatch(ONCHANGE_TASK_NAME(e.target.value));
          }}
          onTouchStart={(e: any) => onTouchForm(e)}
        />
      </div>
      {urlLink === "/working/receiver/all-task" ? (
        <></>
      ) : (
        <div className="flex flex-col lg:w-1/4 w-full h-max">
          {urlLink === "/working/receiver/task-new" ||
          urlLink === "/working/receiver/task-received" ||
          urlLink === "/working/receiver/task-processing" ||
          urlLink === "/working/receiver/task-delay" ||
          urlLink === "/working/receiver/task-end-today" ||
          urlLink === "/working/receiver/task-end-soon" ? (
            <label className="mb-1">Người nhận</label>
          ) : (
            <label className="mb-1">Người nhận</label>
          )}
          {/* <select
          className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
          value={workingData.searchFilter.selectedReceiver}
          onChange={(e: any) => {
            e.preventDefault();

            if (e.target.value === "-1") {
              dispatch(ONLOAD_SELECTED_RECEIVER(parseInt(e.target.value)))
            } else {
              dispatch(ONLOAD_SELECTED_RECEIVER(e.target.value))
            }
          }}
        >
          {
            urlLink === "/working/receiver/task-new"
              || urlLink === "/working/receiver/task-received"
              || urlLink === "/working/receiver/task-processing"
              || urlLink === "/working/receiver/task-delay"
              || urlLink === "/working/receiver/task-end-today"
              || urlLink === "/working/receiver/task-end-soon"
              || urlLink === "/working/receiver/all-task" ?
              <option className="text-gray-300" value={-1}>--- Chọn người nhận ---</option>
              :
              <option className="text-gray-300" value={-1}>--- Chọn người nhận ---</option>
          }
          {workingData.receiverList === null ? ""
            : workingData.receiverList?.map((item: any, index: any) => (
              <option key={index} value={item.Id}>{item.FullName}</option>
            ))
          }
        </select> */}
          <Select
            className="z-30"
            options={tempReceiverList}
            value={tempReceiverList?.find(
              (item: any) =>
                item.Id === workingData.searchFilter.selectedReceiver
            )}
            onChange={(data: any) => dispatch(ONLOAD_SELECTED_RECEIVER(data))}
            isClearable
            placeholder="--- Chọn người nhận ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
      <div className="flex flex-col lg:w-1/4 w-full  h-max">
        <label className="mb-1">Mức độ</label>
        <Select
          className="z-[6]"
          options={tempPriorList}
          onChange={(data: any) => dispatch(ONLOAD_SELECTED_PRIOR(data))}
          isClearable
          placeholder="--- Chọn mức độ ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
        {/* <select
          className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
          value={workingData.searchFilter.selectedPrior}
          onChange={(e: any) => {
            dispatch(ONLOAD_SELECTED_PRIOR(parseInt(e.target.value)))
          }}
        >
          <option className="text-gray-300" value={-1}>--- Chọn mức độ ---</option>
          {workingData.priorList === null ? ""
            : workingData.priorList.map((item: any, index: any) => (
              <option key={index} value={item.Id}>{item.PriorityLevelName}</option>
            ))
          }
        </select> */}
      </div>

      <div className="flex flex-col lg:w-1/4 w-full  h-max">
        <label className="mb-1">Trạng thái</label>
        <Select
          className="z-[5]"
          options={dataStatus}
          onChange={(data: any) => dispatch(ONCHAGE_FILTER_STATUS(data))}
          isClearable
          placeholder="--- Chọn trạng thái ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
        {/* <select
          className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
          value={workingData.searchFilter.status}
          onChange={(e: any) => {
            dispatch(ONCHAGE_FILTER_STATUS(parseInt(e.target.value)))
          }}
        >
          <option className="text-gray-300" value={0}>--- Chọn trạng thái ---</option>
        
          <option className="text-gray-300" value={2}>Chưa giao</option>
          <option className="text-gray-300" value={3}>Đã nhận</option>
          <option className="text-gray-300" value={4}>Đang xử lý</option>
          <option className="text-gray-300" value={5}>Hoàn thành</option>
          <option className="text-gray-300" value={34}>Trễ hạn</option>
          <option className="text-gray-300" value={345}>Hoàn thành trễ hạn</option>
        </select> */}
      </div>

      <div className="flex flex-col lg:w-1/4 w-full  h-max">
        <label className="mb-1">Kết quả</label>
        <Select
          className="z-[4]"
          options={dataResult}
          value={dataResult?.find(
            (item) => item.Id === workingData.searchFilter.result
          )}
          onChange={(data: any) => dispatch(ONCHAGE_FILTER_RESULT(data))}
          isClearable
          placeholder="--- Chọn kết quả  ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
        {/* <select
          className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
          value={workingData.searchFilter.status}
          onChange={(e: any) => {
            dispatch(ONCHAGE_FILTER_STATUS(parseInt(e.target.value)))
          }}
        >
          <option className="text-gray-300" value={0}>--- Chọn trạng thái ---</option>
        
          <option className="text-gray-300" value={2}>Chưa giao</option>
          <option className="text-gray-300" value={3}>Đã nhận</option>
          <option className="text-gray-300" value={4}>Đang xử lý</option>
          <option className="text-gray-300" value={5}>Hoàn thành</option>
          <option className="text-gray-300" value={34}>Trễ hạn</option>
          <option className="text-gray-300" value={345}>Hoàn thành trễ hạn</option>
        </select> */}
      </div>
    </div>
  );
};

export default WorkingReceiverAllTaskJSX;
