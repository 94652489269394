import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const settingReducer = createSlice({
  name: "settingReducer",

  initialState: {
    pageList: 10,
    pageNumber: 0,
    pageTotal: 0,
    itemTotal: 0,

    parameterListKey: [],
    parameterList: null,
    parameterChoose: "1",

    taskList: null,
    showingTaskList: null,

    priorList: [],
    securityList: null,
    dificultList: null,

    targetRatingList: null,

    userList: null,
    filter: {}
  },

  reducers: {
    ONCHANGE_FILTER: (state: any, actions: PayloadAction<any>) => {
      const filter = {...state.filter, ...actions.payload};

      state.filter = filter;
    },
    ONCHANGE_TARGET_RATING: (state: any, actions: PayloadAction<any>) => {
      state.targetRatingList = actions.payload
    },
    ONLOAD_DIFFICULT_LIST: (state: any, actions: PayloadAction<any>) => {
      state.dificultList = actions.payload;
    },

    ONLOAD_SECURITY_LIST: (state: any, actions: PayloadAction<any>) => {
      state.securityList = actions.payload;
    },

    ONPUSH_SUBLIST: (state: any, actions: PayloadAction<any>) => {
      
      let tempArray: any[] = [];

      state.showingTaskList.forEach((item: any, index: any) => {
        if (item.ParentID === actions.payload.id) {
          tempArray.push(item);
        }
      })

      for (let i = 0; i < state.taskList.length; i++) {
        if (state.taskList[i].Id === actions.payload.id) {
          if (state.taskList[i].isShow === false) {
            state.taskList[i].isShow = true;
          } else {
            state.taskList[i].isShow = false;
          }

          if (state.taskList[i].subTask.length === 0) {
            tempArray.forEach((item: any) => {
              state.taskList[i].subTask.push(item)
            })
          }

          break;
        }
      }
    },

    ONLOAD_TASKLIST: (state: any, actions: PayloadAction<any>) => {

      let tempArray: any[] = [];

      actions.payload.sort((a: any, b: any) => { return a.ParentID - b.ParentID })

      actions.payload.forEach((item: any, index: any) => {
        if (item.ParentID === 0) {
          tempArray.push({ ...item, subTask: [], isShow: false });
        }
      })

      state.showingTaskList = actions.payload;
      state.taskList = tempArray;
    },

    ONCHANGE_PARAMETERCHOOSE: (state: any, actions: PayloadAction<any>) => {
      state.parameterChoose = actions.payload;
    },

    ONLOAD_USERLIST: (state: any, actions: PayloadAction<any>) => {
      state.userList = actions.payload;
      state.userListOriginals = actions.payload;
    },
    ONCHANGE_USERLIST: (state: any, actions: PayloadAction<any>) => {
      state.userList = actions.payload;
    },
    ONLOAD_PRIORLIST: (state: any, actions: PayloadAction<any>) => {
      state.priorList = actions.payload
    },

    ONLOAD_CATEGORY_SUGGEST: (state: any, actions: PayloadAction<any>) => {
      state.categorySuggest = {
        data: actions.payload
      };
    },

    ONCHANGE_PAGELIST: (state: any, actions: PayloadAction<any>) => {
      state.pageList = actions.payload;

      let tempList: any[] = [];
      let tempDetailList: any[] = [];
      let tempResultList: any[] = [];

      state.parameterList.forEach((item: any) => {
        item.forEach((item: any) => {
          tempList.push(item);
        })
      })

      tempList.forEach((item: any, index: number) => {
        tempDetailList.push(item);

        if ((index + 1) % state.pageList === 0) {
          tempResultList.push(tempDetailList);
          tempDetailList = []
        } else if ((index + 1) === tempList.length) {
          tempResultList.push(tempDetailList);
        }
      })

      state.parameterList = tempResultList;
      state.pageTotal = tempResultList.length;
      state.itemTotal = tempList.length;
    },

    ONCHANGE_PARAMETER_LISTKEY: (state: any) => {
      const paramKeyList = localStorage.getItem("parameterListKey");

      if (paramKeyList === null) {
        return;
      } else {
        state.parameterListKey = JSON.parse(paramKeyList);
      }
    },

    ONLOAD_PARAMETER_LIST: (state: any, actions: PayloadAction<any>) => {
      state.parameterList = actions.payload
      // if (actions.payload === null) {
      //   return;
      // } else {
      //   let tempList: any[] = [];
      //   let tempDetailList: any[] = [];
      //   let tempResultList: any[] = [];

      //   tempList = actions.payload;

      //   tempList.forEach((item: any, index: number) => {
      //     tempDetailList.push(item);

      //     if ((index + 1) % state.pageList === 0) {
      //       tempResultList.push(tempDetailList);
      //       tempDetailList = []
      //     } else if ((index + 1) === tempList.length) {
      //       tempResultList.push(tempDetailList);
      //     }
      //   })

      //   state.parameterList = tempResultList;
      //   state.pageTotal = tempResultList.length;
      //   state.itemTotal = tempList.length;

      //   console.log(
      //     state.parameterList,
      //     state.pageTotal,
      //     state.itemTotal
      //   )
      // }
    },

    ONCHANGE_PAGENUMBER: (state: any, actions: PayloadAction<number>) => {
      state.pageNumber = actions.payload;
    },

    UPDATE_PARAM_CACHE: (state: any, actions: PayloadAction<any>) => {
      const parameterList = localStorage.getItem("parameterList");

      if (parameterList === null) {
        return;
      } else {
        const tempParameterList: any[] = JSON.parse(parameterList);

        const index = tempParameterList.findIndex((e: any) => e.ParameterId === actions.payload.ParameterId)

        tempParameterList[index].ParameterKey = actions.payload.ParameterKey;
        tempParameterList[index].ParameterValue = actions.payload.ParameterValue;
        tempParameterList[index].IsActive = actions.payload.IsActive;

        localStorage.setItem("parameterList", JSON.stringify(tempParameterList));
      }
    }
  }
});

export const {
  ONCHANGE_TARGET_RATING,
  ONLOAD_DIFFICULT_LIST,
  ONLOAD_SECURITY_LIST,
  ONPUSH_SUBLIST,
  ONCHANGE_PARAMETERCHOOSE,
  ONLOAD_USERLIST,
  ONLOAD_PRIORLIST,
  ONLOAD_TASKLIST,
  UPDATE_PARAM_CACHE,
  ONCHANGE_PARAMETER_LISTKEY,
  ONLOAD_PARAMETER_LIST,
  ONCHANGE_PAGENUMBER,
  ONCHANGE_PAGELIST,
  ONLOAD_CATEGORY_SUGGEST,
  ONCHANGE_FILTER,
  ONCHANGE_USERLIST
} = settingReducer.actions

export default settingReducer.reducer;
