import { useSelector } from "react-redux";
import {
  ONCHAGE_FILTER_RESULT,
  ONCHAGE_FILTER_STATUS,
  ONCHANGE_CLEAR_FILTER,
  ONCHANGE_DATE,
  ONCHANGE_FILTER_SELECTED_PHONGBAN,
  ONCHANGE_FROMDATE,
  ONCHANGE_SEARCH_MONTH,
  ONCHANGE_SEARCH_YEAR,
  ONCHANGE_SELECTED_BOSS,
  ONCHANGE_SELECTED_DIFF,
  ONCHANGE_SELECTED_PRIOR,
  ONCHANGE_TASK_NAME,
  ONCHANGE_TODATE,
  ONLOAD_SELECTED_PRIOR,
  ONLOAD_SELECTED_RECEIVER,
  ONLOAD_SELECTED_STATUS_TASKMYSELF,
  ONLOAD_SELECTED_ISNOW_TASKCHANGEDEADLINE,
} from "../redux/reducers/workingReducer/_index";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useEffect, useState } from "react";
import { dataStatus, dataResult } from "../data/_index";
import DateTimePickerControl from "../controls/datetimePicker/datetimePickerControl";
import OrganizationRepository from "../repositories/organizations/organizationRepository";
import OrganizationUtils from "../utils/organizations/organizationUtils";
import { onTouchForm } from "../funcs/onTochForm";
import HandleDate from "../utils/date/handleDate";

interface FilterProps {
  fromDate: string;
  setFromDate: any;
  toDate: string;
  setToDate: any;
  // prior: string;
  // setPrior: any;
}

const Filter: React.FC<FilterProps> = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  // prior,
  // setPrior,
}) => {
  const urlLink = window.location.pathname;
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  // const [selectedReceiver, setSelectedReceiver] = useState("");
  const [organizations, setOrganization] = useState<any>([]);

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const templateStatusTaskMySelf = [
    {
      Id: 1,
      label: "Đã duyệt",
      value: 1,
    },
    {
      Id: 2,
      label: "Không duyệt",
      value: 0,
    },
    {
      Id: 3,
      label: "Chưa duyệt",
      value: 3,
    },
  ];

  const templateIsNowChangeDeadline = [
    {
      Id: 1,
      label: "Hiện tại",
      value: 1,
    },
    {
      Id: 2,
      label: "Quá khứ",
      value: 0,
    },
  ];
  const tempPriorList = workingData.priorList?.map((item: any) => {
    return {
      ...item,
      label: item.PriorityLevelName,
      value: item.PriorityLevelName,
    };
  });

  const tempDiffList = workingData.diffList?.map((item: any) => {
    return {
      ...item,
      label: item.DifficultLevelName,
      value: item.DifficultLevelName,
    };
  });

  const tempBossList = workingData.bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  // useEffect(() => {
  //   tempReceiverList.map((item: any) => {
  //     if (item.Id == workingData.searchFilter.selectedReceiver) {
  //       setSelectedReceiver(item);
  //     }
  //   });
  // }, []);

  // console.log("selectedReceiver: ", selectedReceiver);

  useEffect(() => {
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    let nextMonth;
    let nextYear;
    if (currentMonth + 1 === 12) {
      nextMonth = 0;
      nextYear = currentYear + 1;
    } else {
      nextMonth = currentMonth + 1;
      nextYear = currentYear;
    }
    // console.log("currentMonth, ",currentMonth);
    // console.log("currentYear, ",currentYear);
    // dispatch(ONCHANGE_CLEAR_FILTER({}));
    dispatch(
      ONCHANGE_FROMDATE(
        HandleDate.firstDayOfTheMonth(urlLink, currentMonth, currentYear)
      )
    );
    dispatch(
      ONCHANGE_TODATE(
        HandleDate.firstDayOfTheMonth(urlLink, nextMonth, nextYear)
      )
    );
    // dispatch(ONCHANGE_SELECTED_PRIOR(prior || -1));
  }, [urlLink]);

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      if (res.status) {
        const data = res.data || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: "PhongBanId",
          parentId: "RefID",
        });

        const organizationTreeValueLabels =
          OrganizationUtils.convertToListValueLabel(organizationTrees, {
            value: "PhongBanId",
            label: "PhongBan",
          });

        setOrganization(organizationTreeValueLabels);
      }
    });
  };

  useEffect(() => {
    getListOrganization();
  }, []);

  return (
    <div className="grid lg:grid-cols-5 grid-cols-1 gap-2 lg:gap-4 mt-2">
      {/* Tên công việc */}
      {(urlLink === "/voting/voting_work" ||
        urlLink === "/voting/voting_wait" ||
        urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-recovery" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Tên công việc</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Nhập tên công việc"
            value={workingData.searchFilter.taskName}
            onChange={(e: any) => {
              e.preventDefault();
              dispatch(ONCHANGE_TASK_NAME(e.target.value));
            }}
            // onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
      )}

      {/* Người nhận */}
      {(urlLink === "/voting/voting_work" ||
        urlLink === "/voting/voting_wait" ||
        urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Người nhận</label>
          {/* <Select
            className="z-[6]"
            options={tempReceiverList}
            onChange={(data: any) => {
              dispatch(ONLOAD_SELECTED_RECEIVER(data));
              setSelectedReceiver(data);
            }}
            value={selectedReceiver}
            isClearable
            placeholder="--- Chọn người nhận ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          /> */}
          <Select
            className="z-30"
            options={tempReceiverList}
            value={tempReceiverList?.find(
              (item: any) =>
                item.Id === workingData.searchFilter.selectedReceiver
            )}
            onChange={(data: any) => dispatch(ONLOAD_SELECTED_RECEIVER(data))}
            isClearable
            placeholder="--- Chọn người nhận ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Người giao */}
      {(urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-recovery" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Người giao</label>
          <Select
            className="z-[4]"
            options={tempBossList}
            onChange={(data: any) => dispatch(ONCHANGE_SELECTED_BOSS(data))}
            isClearable
            placeholder="--- Chọn người giao ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Phòng ban */}
      {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-recovery") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Phòng ban</label>
          <Select
            className="z-[2]"
            options={organizations}
            onChange={(data: any) =>
              dispatch(ONCHANGE_FILTER_SELECTED_PHONGBAN(data))
            }
            isClearable
            placeholder="--- Chọn phòng ban ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Mức độ */}
      {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-recovery" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Mức độ</label>
          <Select
            className="z-[5]"
            options={tempPriorList}
            value={tempPriorList?.find(
              (item: any) => item.Id === workingData.searchFilter.selectedPrior
            )}
            onChange={(data: any) =>
              dispatch(ONCHANGE_SELECTED_PRIOR(data?.Id || -1))
            }
            // value={tempPriorList?.find((item: any) => item.Id === prior)}
            // onChange={(data: any) => {
            //   setPrior(data);
            //   dispatch(ONLOAD_SELECTED_PRIOR(data));
            // }}
            isClearable
            placeholder="--- Chọn mức độ ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Độ khó */}
      {urlLink === "/working/task-return" && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Độ khó</label>
          <Select
            className="z-[5]"
            options={tempDiffList}
            value={tempDiffList?.find(
              (item: any) => item.Id === workingData.searchFilter.selectedDiff
            )}
            onChange={(data: any) =>
              dispatch(ONCHANGE_SELECTED_DIFF(data?.Id || -1))
            }
            // value={tempPriorList?.find((item: any) => item.Id === prior)}
            // onChange={(data: any) => {
            //   setPrior(data);
            //   dispatch(ONLOAD_SELECTED_PRIOR(data));
            // }}
            isClearable
            placeholder="--- Chọn độ khó ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Trạng thái */}
      {(urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/all-task") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Trạng thái</label>
          <Select
            className="z-[5]"
            options={dataStatus}
            onChange={(data: any) => dispatch(ONCHAGE_FILTER_STATUS(data))}
            isClearable
            placeholder="--- Chọn trạng thái ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Kết quả */}
      {(urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/all-task") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Kết quả</label>
          <Select
            className="z-[4]"
            options={dataResult}
            value={dataResult?.find(
              (item) => item.Id === workingData.searchFilter.result
            )}
            onChange={(data: any) => dispatch(ONCHAGE_FILTER_RESULT(data))}
            isClearable
            placeholder="--- Chọn kết quả  ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {/* Từ ngày */}
      {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-recovery" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <DateTimePickerControl
            label={"Từ ngày"}
            value={fromDate}
            onChange={(e: any) => {
              const value = e;
              setFromDate(value);
              dispatch(ONCHANGE_DATE({ type: "fromDate", value }));
            }}
          />
        </div>
      )}

      {/* Đến ngày */}
      {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-recovery" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
        <div className="flex flex-col w-full pr-0 h-max">
          <DateTimePickerControl
            label={"Đến ngày"}
            value={toDate}
            onChange={(e: any) => {
              const value = e;
              setToDate(value);
              dispatch(
                ONCHANGE_DATE({
                  type: "toDate",
                  value: value,
                })
              );
            }}
          />
        </div>
      )}

      {/* Thời hạn */}
      {/* {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/task-recovery" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-recovery" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
          <div className="flex flex-col w-full pr-0 h-max">
            <DateTimePickerControl
              label={"Thời hạn"}
              value={workingData.searchFilter.deadLine}
              onChange={(e: any) => {
                const value = e;

                dispatch(ONCHANGE_DATE({ type: "deadLine", value: value }));
              }}
            />
          </div>
        )} */}

      {/* Tháng */}
      {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
        <></>
        // <div className="flex flex-col w-full h-max">
        //   <label className="mb-1">Tháng</label>
        //   <input
        //     className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
        //     placeholder="Nhập tháng"
        //     type="number"
        //     value={searchMonth}
        //     onChange={(e: any) => {
        //       setSearchMonth(e.target.value);
        //       dispatch(ONCHANGE_SEARCH_MONTH(e.target.value));
        //     }}
        //     onTouchStart={(e: any) => onTouchForm(e)}
        //   />
        // </div>
      )}

      {/* Năm */}
      {(urlLink === "/working/task-proposed" ||
        urlLink === "/working/new-task" ||
        urlLink === "/working/task-change-deadline" ||
        urlLink === "/working/task-need-give" ||
        urlLink === "/working/task-proccessing" ||
        urlLink === "/working/task-delay" ||
        urlLink === "/working/task-return" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-finish-early" ||
        urlLink === "/working/task-finish-punctual" ||
        urlLink === "/working/task-finish-late" ||
        urlLink === "/working/task-totalall" ||
        urlLink === "/working/task-total" ||
        urlLink === "/working/task-total-my-seft" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ||
        urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-waiting" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ||
        urlLink === "/working/receiver/task-myself") && (
        // <div className="flex flex-col w-full h-max">
        //   <label className="mb-1">Năm</label>
        //   <input
        //     className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
        //     placeholder="Nhập năm"
        //     type="number"
        //     value={searchYear}
        //     onChange={(e: any) => {
        //       setSearchYear(e.target.value);
        //       dispatch(ONCHANGE_SEARCH_YEAR(e.target.value));
        //     }}
        //     onTouchStart={(e: any) => onTouchForm(e)}
        //   />
        // </div>
        <></>
      )}

      {urlLink === "/working/task-proposed" && (
        <div className="flex flex-col w-full h-max">
          <label className="mb-1">Trạng thái</label>
          <Select
            className="z-30"
            options={templateStatusTaskMySelf}
            value={templateStatusTaskMySelf?.find(
              (item: any) =>
                item.value === workingData.searchFilter.statusTaskMyself
            )}
            onChange={(data: any) =>
              dispatch(ONLOAD_SELECTED_STATUS_TASKMYSELF(data))
            }
            isClearable
            placeholder="--- Chọn trạng thái ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}

      {urlLink === "/working/task-change-deadline" && (
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Thời điểm</label>
          <Select
            className="z-30"
            options={templateIsNowChangeDeadline}
            value={templateIsNowChangeDeadline?.find(
              (item: any) => item.value === workingData.searchFilter.isNow
            )}
            onChange={(data: any) =>
              dispatch(ONLOAD_SELECTED_ISNOW_TASKCHANGEDEADLINE(data))
            }
            isClearable
            placeholder="--- Chọn thời điểm ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default Filter;
