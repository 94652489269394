import React from "react";

import ContentCore from "../../../core/workingContent";

const TaskWaitingUI = () => {
  return (
    <ContentCore />
  )
}

export default TaskWaitingUI