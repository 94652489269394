import React from "react";

import HomeUI from "../../ui/homeUI/_index";

const FunctionalListPage = () => {
  return (
    <HomeUI />
  )
}

export default FunctionalListPage;
