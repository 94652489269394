import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import SaveIcon from "@mui/icons-material/Save";

import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

import { onTouchForm } from "../../../funcs/onTochForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchData,
  fetchFolder,
  postFolder,
} from "../../fileComponent/utils/helper";
import { useDispatch } from "react-redux";
import {
  ONLOAD_FILELIST,
  ONLOAD_FOLDERLIST,
} from "../../../redux/reducers/driveReducer/_index";

const CreateFolder = (props: any) => {
  const handleClose = props.handleClose;

  const dispatch = useDispatch();

  // state
  const [groupName, setGroupName] = useState<string>("");

  const { id } = useParams();

  // funcs
  const _onChangeGroupName = (e: any) => {
    e.preventDefault();
    setGroupName(e.target.value);
  };

  const _onClickSubmit = (e: any) => {
    e.preventDefault();
    fetchFolder(id).then((res: any) => {
      const body = {
        FolderName: groupName,
        icon: "#616A6B",
        FolderRegionType: res.FolderRegionType,
        DerpartmentId: res.DerpartmentId,
        ParentId: res.FolderId,
      };
      postFolder(body).then(() => {
        fetchData(id).then((response: any) => {
          dispatch(ONLOAD_FILELIST(response.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));
        });
        if (handleClose) {
          handleClose();
        }
      });
    });
  };

  return (
    <form className="flex flex-col bg-white lg:w-[34rem] w-screen rounded-md">
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        py-[12px] px-[16px] items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Thêm thư mục</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label className="mb-1">
              Tên thư mục&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={groupName}
              onChange={(e: any) => {
                _onChangeGroupName(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
              required
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] footer rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
              flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
              hover:bg-sky-400
            "
            type="submit"
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Tạo mới</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateFolder;
