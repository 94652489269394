import React, { useState, useRef, useEffect, useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";

import {
  CHiTietTieuChiDanhGia,
  NGUOI_NHAN_VIEC_TASK_MY_SELF,
} from "../../../apis/_index";

import { useSelector, useDispatch } from "react-redux";
import { NGUOI_NHAN_TASK_COMPLETE } from "../../../apis/_index";

import SubModal from "../_subModal/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import LoaderSpinner from "../../loader-spinner/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import {
  NGUOI_NHAN_VIEC_LIST_PROCESSING,
  NGUOI_NHAN_VIEC_LIST_DELAY,
} from "../../../apis/_index";
import {
  ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN,
  ONCHANGE_NGUOI_NHAN_MOI,
  ONCHANGE_NGUOI_NHAN_TRE_HAN,
} from "../../../redux/reducers/workingReducer/_index";

import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

const FinishTask = (props: any) => {
  const taskId = props.taskId;
  const taskCode = props.taskCode;
  const handleClose = props.handleClose;
  const urlLink = window.location.pathname;

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const [notes, setNotes] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);

  const shouldLog = useRef(true);
  const link = window.location.pathname;
  // funcs
  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      TaskID: taskId,
      FinishedContent: notes,
      UserAdd: data.userData.Id,
    };

    try {
      setIsLoading(true);
      await ServiceUtils.postV2(NGUOI_NHAN_TASK_COMPLETE, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          toast.success("Đã xác nhận hoàn tất");
          if (urlLink === "/working/receiver/task-delay") {
            readList(
              NGUOI_NHAN_VIEC_LIST_DELAY,
              workingData.pageCurrent,
              workingData.choosePage,
              workingData.searchFilter.taskName,
              workingData.searchFilter.selectedPrior,
              workingData.searchFilter.selectedReceiver,
              workingData.searchFilter.fromDate,
              workingData.searchFilter.toDate,
              workingData.searchFilter.deadLine,
              shouldLog,
              urlLink,
              (res: any) => {
                dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
              },
              null,
              null,
              null,
              null,
              workingData.searchFilter.toDate,
              workingData.searchFilter.deadLine
            );
          } else if (urlLink === "/working/receiver/task-myself") {
            readList(
              NGUOI_NHAN_VIEC_TASK_MY_SELF,
              workingData.pageCurrent,
              workingData.choosePage,
              workingData.searchFilter.taskName,
              workingData.searchFilter.selectedPrior,
              null,
              workingData.searchFilter.fromDate,
              workingData.searchFilter.toDate,
              workingData.searchFilter.deadLine,
              shouldLog,
              link,
              (res: any) => {
                dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
              },
              null,
              null,
              null,
              null,
              data.searchFilter.searchMonth,
              data.searchFilter.searchYear
            );
          } else {
            readList(
              NGUOI_NHAN_VIEC_LIST_PROCESSING,
              workingData.pageCurrent,
              workingData.choosePage,
              workingData.searchFilter.taskName,
              workingData.searchFilter.selectedPrior,
              workingData.searchFilter.selectedReceiver,
              workingData.searchFilter.fromDate,
              workingData.searchFilter.toDate,
              workingData.searchFilter.deadLine,
              shouldLog,
              urlLink,
              (res: any) => {
                dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
              },
              null,
              null,
              null,
              null,
              workingData.searchFilter.searchMonth,
              workingData.searchFilter.searchYear
            );
          }

          handleClose();
          context.handleClose();
        } else {
          handleClose();
          toast.error(res.data.Message || "Xác nhận hoàn tất thất bại");
        }
      });
    } catch (error: any) {
      handleClose();
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Xác nhận hoàn tất</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Mã công việc&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={taskCode}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>Ghi chú thêm (nếu có)</label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập nội dung ghi chú"
              onChange={(e: any) => {
                e.preventDefault();
                setNotes(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        {/* <div className="flex flex-col w-full h-max">
          <label>Nhóm tham chiếu (nếu có)&nbsp;<span className="text-red-500">(*)</span></label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
            defaultValue={0}
          >
            <option className="text-gray-300" value={0} disabled>--- Chọn nhóm tham chiếu ---</option>
            <option>Nhóm tham chiếu 1</option>
            <option>Nhóm tham chiếu 2</option>
            <option>Nhóm tham chiếu 3</option>
          </select>
        </div>

        <div className="flex items-center w-full space-x-6 h-max">
          <p className="font-bold text-primary">Hiển thị</p>
          <input
            type="checkbox"
          />
        </div> */}
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-green-500
          
        "
            disabled={isLoading === true ? true : false}
            onClick={_onClickSubmit}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <p>Xác nhận</p>
            )}
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinishTask;
