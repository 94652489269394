import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../components/title";
import Table from "../../../components/table";

import axios from "axios";
import { toast } from "react-toastify";
import {
  GROUP_READ_ALL_DROPDOWN,
  SYS_DASHBOARD,
  SYS_DASHBOARD_LOADFUNCS,
} from "../../../apis/_index";
import {
  ONLOAD_GROUPLIST_DROPDOWN,
  ONLOAD_SCREENLIST,
  ONCHANGE_SCREENCHOOSE,
} from "../../../redux/reducers/userReducer/_index";
import ServiceUtils from "../../../utils/services";

const ScreenListUI = () => {
  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.userReducer);

  // state
  const [selectedNhomquyen, setSelectedNhomQuyen] = useState<any>(0);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(GROUP_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_GROUPLIST_DROPDOWN(res.data.Data));
            }
          });
        };

        takeData();
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  });

  // load list
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog2.current) {
      shouldLog2.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(SYS_DASHBOARD, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_SCREENLIST(res.data.Data));
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  // see choose nhom quyen
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (selectedNhomquyen === 0) {
      return;
    } else {
      const takeData = async () => {
        const API = SYS_DASHBOARD_LOADFUNCS + selectedNhomquyen.toString();

        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SCREENLIST(res.data.Data));
          });
        } catch (error: any) {}
      };
      takeData();
    }
  }, [selectedNhomquyen, dispatch]);

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] text-sm pl-5 pr-5 pt-3 pb-3">
        <div className="flex w-full h-max justify-between">
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label className="text-xs">Nhóm quyền</label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={selectedNhomquyen}
              onChange={(e: any) => {
                e.preventDefault();
                dispatch(ONCHANGE_SCREENCHOOSE(e.target.value));
                setSelectedNhomQuyen(e.target.value);
              }}
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn nhóm quyền ---
              </option>
              {data.groupListDropdown === null
                ? ""
                : data.groupListDropdown.map((item: any, index: any) => (
                    <option key={index} value={item.ID}>
                      {item.GroupName}
                    </option>
                  ))}
            </select>
          </div>

          <input
            className="w-[20rem] h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            placeholder="Tìm kiếm màn hình"
          />
        </div>

        <Table />
      </div>
    </div>
  );
};

export default ScreenListUI;
