import React from "react"

// ui importing
import HomeUI from "../../../../ui/homeUI/_index";

const TaskReturnR = () => {
  return (
    <HomeUI />
  )
}

export default TaskReturnR