import { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FOLDER_IDS } from "../../../../constants/dataConstants";
import DeviceUtils from "../../../../utils/devices/deviceUtils";

interface ContextMenuProps {
  contextMenuRef: any;
  contextMenuItem: any;
  _onClickCopy: (item: any) => void;
  _onClickCut: (item: any) => void;
  _onClickPaste: (iD: any) => void;
  _onClickShare: (item: any) => void;
  _onClickDownload: (item: any) => void;
  _onClickRename: (item: any) => void;
  _onClickMoveToTrash: (item: any) => void;
  _onClickRestore: (item: any) => void;
  _onClickDeleteOnTrash: (item: any) => void;
}

const ContextMenu: FC<ContextMenuProps> = ({
  contextMenuRef,
  contextMenuItem,
  _onClickCopy,
  _onClickCut,
  _onClickPaste,
  _onClickShare,
  _onClickDownload,
  _onClickRename,
  _onClickMoveToTrash,
  _onClickRestore,
  _onClickDeleteOnTrash,
}) => {
  const { copiedItem, cutItem } = useSelector(
    (state: any) => state.driveReducer
  );
  const { id } = useParams();
  const isDriveTrash = id == FOLDER_IDS.trash;
  const isMobile = DeviceUtils.isMobile();
  console.log(isMobile);

  const closeContextMenu = () => {
    const contextMenu = contextMenuRef.current;
    if (contextMenu) {
      contextMenu.style.left = `0px`;
      contextMenu.style.top = `0px`;
      contextMenu.style.visibility = "hidden";
    }
  };
  return (
    <>
      {!isMobile ? (
        <div
          ref={contextMenuRef}
          className="absolute w-[300px] rounded-lg shadow-lg bg-[#f5f5f7] z-[100]"
          style={{ top: 0, left: 0, visibility: "hidden" }}
        >
          <div>
            {contextMenuItem ? (
              <>
                {!isDriveTrash ? (
                  <ul className="px-[12px] py-[10px]">
                    {/* <li
                  className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                  onClick={() => {
                    _onClickCopy(contextMenuItem);
                    handleCloseContextMenu(true);
                  }}
                >
                  <div className="font-medium">Sao chép</div>
                  <div className="font-normal text-sm text-gray-700">
                    Ctrl + C
                  </div>
                </li> */}
                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickCut(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Cắt</div>
                      <div className="font-normal text-sm text-gray-700">
                        Ctrl + X
                      </div>
                    </li>
                    {contextMenuItem && contextMenuItem.FileId ? (
                      ""
                    ) : (
                      <li
                        className={`text-base flex w-full rounded mb-[2px] p-2 justify-between items-center ${
                          copiedItem || cutItem
                            ? "hover:bg-gray-200 hover:text-red-500 cursor-pointer"
                            : "text-gray-500"
                        }`}
                        onClick={() => {
                          if (copiedItem || cutItem) {
                            _onClickPaste(contextMenuItem.FolderId);
                            closeContextMenu();
                          }
                        }}
                      >
                        <div className="font-medium">Dán</div>
                        <div
                          className={`font-normal text-sm  ${
                            copiedItem || cutItem
                              ? "text-gray-700"
                              : "text-gray-500"
                          }`}
                        >
                          Ctrl + V
                        </div>
                      </li>
                    )}

                    <hr />
                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickShare(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Chia sẻ</div>
                    </li>
                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickDownload(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Tải xuống</div>
                    </li>

                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickRename(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Đổi tên</div>
                    </li>
                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickMoveToTrash(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Chuyển vào thùng rác</div>
                    </li>
                  </ul>
                ) : (
                  <ul className="px-[12px] py-[10px]">
                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickRestore(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Khôi phục</div>
                    </li>
                    <li
                      className="text-base flex w-full cursor-pointer rounded mb-[2px] hover:bg-gray-200 hover:text-red-500 p-2 justify-between items-center"
                      onClick={() => {
                        _onClickDeleteOnTrash(contextMenuItem);
                        closeContextMenu();
                      }}
                    >
                      <div className="font-medium">Xóa vĩnh viễn</div>
                    </li>
                  </ul>
                )}
              </>
            ) : (
              <>
                <ul className="px-[12px] py-[10px]">
                  {contextMenuItem && contextMenuItem.FileId ? (
                    ""
                  ) : (
                    <li
                      className={`text-base flex w-full rounded mb-[2px] p-2 justify-between items-center ${
                        copiedItem || cutItem
                          ? "hover:bg-gray-200 hover:text-red-500 cursor-pointer"
                          : "text-gray-500"
                      }`}
                      onClick={() => {
                        if (copiedItem || cutItem) {
                          _onClickPaste(id);
                          closeContextMenu();
                        }
                      }}
                    >
                      <div className="font-medium">Dán</div>
                      <div
                        className={`font-normal text-sm  ${
                          copiedItem || cutItem
                            ? "text-gray-700"
                            : "text-gray-500"
                        }`}
                      >
                        Ctrl + V
                      </div>
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          ref={contextMenuRef}
          className="fixed w-full bg-black bg-opacity-40 z-[10000] bottom-0 left-0 flex items-end"
          style={{ visibility: "hidden" }}
          onClick={() => {
            closeContextMenu();
            console.log("!23123");
          }}
        >
          <div className="w-full  bottom-0 m-2">
            {contextMenuItem ? (
              <>
                {!isDriveTrash ? (
                  <ul className="bg-white bg-opacity-90 rounded-lg">
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickCut(contextMenuItem);
                        }}
                      >
                        Cắt
                      </button>
                    </li>
                    <hr className="bg-gray-300" />
                    {!contextMenuItem ||
                      (!contextMenuItem.FileId && (
                        <>
                          <li>
                            <button
                              className={`w-full text-center font-normal py-3 text-lg ${
                                !copiedItem && !cutItem
                                  ? "text-gray-500"
                                  : "text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                              }`}
                              onClick={(e) => {
                                if (copiedItem || cutItem) {
                                  _onClickPaste(contextMenuItem.FolderId);
                                } else {
                                  e.stopPropagation();
                                }
                              }}
                            >
                              Dán
                            </button>
                          </li>
                          <hr className="bg-gray-300" />
                        </>
                      ))}
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickShare(contextMenuItem);
                        }}
                      >
                        Chia sẻ
                      </button>
                    </li>
                    <hr className="bg-gray-300" />
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickDownload(contextMenuItem);
                        }}
                      >
                        Tải xuống
                      </button>
                    </li>
                    <hr className="bg-gray-300" />
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickRename(contextMenuItem);
                        }}
                      >
                        Đổi tên
                      </button>
                    </li>
                    <hr className="bg-gray-300" />
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickMoveToTrash(contextMenuItem);
                        }}
                      >
                        Chuyển vào thùng rác
                      </button>
                    </li>
                  </ul>
                ) : (
                  <ul className="bg-white bg-opacity-90 rounded-lg">
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickRestore(contextMenuItem);
                        }}
                      >
                        Khôi phục
                      </button>
                    </li>
                    <hr className="bg-gray-300" />
                    <li>
                      <button
                        className="w-full text-center font-normal py-3 text-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        onClick={() => {
                          _onClickDeleteOnTrash(contextMenuItem);
                        }}
                      >
                        Xóa vĩnh viễn
                      </button>
                    </li>
                  </ul>
                )}
              </>
            ) : (
              <>
                <ul className="bg-white bg-opacity-90 rounded-lg">
                  {contextMenuItem && contextMenuItem.FileId ? (
                    ""
                  ) : (
                    <li>
                      <button
                        className={`w-full text-center font-normal py-3 text-lg rounded-lg ${
                          !copiedItem && !cutItem
                            ? "text-gray-500"
                            : "text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
                        }`}
                        onClick={(e) => {
                          if (copiedItem || cutItem) {
                            _onClickPaste(id);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        Dán
                      </button>
                    </li>
                  )}
                </ul>
              </>
            )}
            <button
              className="w-full bg-white text-center font-semibold py-3 my-2 text-lg rounded-lg text-blue-500 focus:text-red-500 focus:bg-[#f5f5f7]"
              onClick={closeContextMenu}
            >
              Đóng
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ContextMenu;
