import React, { useEffect, useRef, useState, useContext } from "react";
import { ModalController } from "../../../ui/homeUI/_index";
import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

import CheckIcon from "@mui/icons-material/Check";

import SubModal from "../_subModal/_index";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import {
  BAO_CAO_CHI_TIET,
  BAO_CAO_UPDATE_REPORT,
  LOAD_ONE_REQUEST,
  LIST_OF_TEMPLATE,
  CREATE_REPORT,
  NGUOI_NHAN_VIEC_LIST_ALL,
} from "../../../apis/_index";

import moment from "moment";
import Select from "react-select";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import axios from "axios";

import reportView from "../../../temp/baocao";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import { getCurrentUserID, getUserID } from "../../../utils/users/userUtils";
import HandleDate from "../../../utils/date/handleDate";
import TextEditor from "../../TextEditor";
import { Pagination } from "@mui/material";

const ReportDetailOwnerAndManagerElectric3 = (props: any) => {
  // Take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const context = useContext(ModalController);

  const shouldLog = useRef(true);

  const [data, setData] = useState<any>(null);
  const [reportName, setReportName] = useState<string>("");
  const [isShowCreate, setIsShowCreate] = useState<boolean>(false);

  // State
  const [typeOfReport, setTypeOfReport] = useState<number>();
  const [reportHowTo, setReportHowTo] = useState<boolean | null>(null);

  const [listOfTemplate, setListOfTemplate] = useState<any[]>([]);
  const [selectedTemplateID, setSelectedTemplateID] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");

  // -- Day
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  // -- Month
  const [month, setMonth] = useState<string>("");

  // -- Quarter
  const [quarter, setQuarter] = useState<string>("");

  // -- Year
  const [year, setYear] = useState<string>("");

  // -- Temp of other
  const [isChooseDate, setIsChooseDate] = useState<boolean>(false);

  //------------
  const userId = getCurrentUserID();
  const [tasks, setTasks] = useState<any[] | null>(null);
  const [pageCurrent, setPageCurrent] = useState<number>(0);
  const pageLimit = 10;
  const [pageTotal, setPageTotal] = useState<number>(0);

  const [selectedTask, setSelectedTask] = useState<any[]>([]);
  const [planOfAction, setPlanOfAction] = useState<string>("<p><p/>");

  const _onChangePlanOfAction = (event: any, editor: any) => {
    const data = editor.getData();
    setPlanOfAction(data);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageCurrent(value - 1);
  };

  const _onClickAddingTaskOrRemove = (task: any) => {
    const taskId = task.ID;
    // Kiểm tra đã có trong danh sách
    const isTaskInList = selectedTask.some((item) => item.TaskID === taskId);

    if (isTaskInList) {
      // Nếu đã có, xóa nó khỏi danh sách
      const updatedList = selectedTask.filter((item) => item.TaskID !== taskId);
      setSelectedTask(updatedList);
    } else {
      // Nếu chưa có, thêm nó vào danh sách
      setSelectedTask((prevTasks) => [
        ...prevTasks,
        { TaskID: taskId, Status: task.TaskStatus, Description: "" },
      ]);
    }
  };

  useEffect(() => {
    const takeData = async () => {
      const body: any = {
        Page: pageCurrent,
        Limit: pageLimit,
        Recipient: userId,
      };

      try {
        await ServiceUtils.postV2(NGUOI_NHAN_VIEC_LIST_ALL, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setTasks(res.data.Data.data);

            if (res.data.Data.count % 10 === 0) {
              setPageTotal(Math.floor(res.data.Data.count / 10));
            } else {
              setPageTotal(Math.floor(res.data.Data.count / 10) + 1);
            }
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  }, [pageCurrent]);

  //------------

  // Funcs
  const _onClickSetIsShowCreate = () => {
    setIsShowCreate(!isShowCreate);
  };

  const resetData = () => {
    setFromDate("");
    setToDate("");
    setMonth("");
    setQuarter("");
    setYear("");
  };

  const _onChangeTypeOfReport = (data: any) => {
    if (data === null) {
      resetData();
      setTypeOfReport(0);
    } else {
      resetData();
      setTypeOfReport(data?.id);
    }
  };

  const currentYear: number = new Date().getFullYear();
  const yearList = [];

  // Lấy 5 năm trước
  for (let i: number = 5; i > 0; i--) {
    const year: number = currentYear - i;
    yearList.push({ label: year, value: year });
  }
  // Lấy năm hiện tại
  yearList.push({ label: currentYear, value: currentYear });
  // Lấy 5 năm sau
  for (let i: number = 1; i <= 5; i++) {
    const year: number = currentYear + i;
    yearList.push({ label: year, value: year });
  }

  function validateReport() {
    if (!reportName || !typeOfReport) {
      toast.warning("Vui lòng nhập đầy đủ thông tin");
      return false;
    }

    if (typeOfReport == 1 && (!fromDate || !toDate)) {
      toast.warning("Vui lòng nhập từ ngày và đến ngày");
      return false;
    }

    if (typeOfReport == 2 && (!month || !year)) {
      toast.warning("Vui lòng nhập tháng và năm");
      return false;
    }

    if (typeOfReport == 3 && (!quarter || !year)) {
      toast.warning("Vui lòng nhập quý và năm");
      return false;
    }

    if (typeOfReport == 4 && !year) {
      toast.warning("Vui lòng nhập năm");
      return false;
    }

    if (typeOfReport == 5 && selectedTask.length === 0) {
      toast.warning("Vui lòng chọn ít nhất một nhiệm vụ");
      return false;
    }

    if (typeOfReport == 6 && !planOfAction) {
      toast.warning("Vui lòng nhập kế hoạch hành động");
      return false;
    }

    return true;
  }

  const _onClickSubmit = () => {
    if (!validateReport()) return;

    let result = "";

    if (typeOfReport == 1) {
      result =
        "," + `${fromDate} 00:00:00.000` + "," + `${toDate} 00:00:00.000`;
    } else if (typeOfReport == 2) {
      result =
        month.toString() +
        "," +
        `${HandleDate.formatDate(
          HandleDate.firstDayOfMonth(month, year)
        )} 00:00:00.000` +
        "," +
        `${HandleDate.formatDate(
          HandleDate.firstDayOfNextMonth(month, year)
        )} 00:00:00.000`;
    } else if (typeOfReport == 3) {
      result = HandleDate.getQuarterString(quarter, year);
    } else if (typeOfReport == 4) {
      result =
        year.toString() +
        "," +
        `${HandleDate.formatDate(
          new Date(parseInt(year), 0, 1)
        )} 00:00:00.000` +
        "," +
        `${HandleDate.formatDate(
          new Date(parseInt(year + 1), 0, 1)
        )} 00:00:00.000`;
    } else if (typeOfReport == 5) {
      result = "";
    } else if (typeOfReport == 6) {
      result = "";
    }

    const takeData = async () => {
      const body: any = {
        RequestID: data?.report?.ID,
        // ReportTemplateID: selectedTemplateID,
        ReportTemplateID: "6a11de52-913a-441b-b9ef-3350f1129f453",
        ReportName: reportName,
        TypeReport: true,
        TypeReportJob: typeOfReport,
        TypeReportDetail: result,
        Content: selectedTemplate,
        PlanOfAction: planOfAction,
        Report_HistoryText: selectedTask,
      };
      if (typeOfReport != 6) {
        delete body.PlanOfAction;
      }
      if (typeOfReport != 5) {
        delete body.Report_HistoryText;
      }

      // console.log("body, ", body);
      try {
        await ServiceUtils.postV2(CREATE_REPORT, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data?.Data[0].RCode === 200) {
            toast.success("Tạo báo cáo thành công");
            _onClickDetail2(res.data?.Data[0].RId);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(LOAD_ONE_REQUEST + taskId + "&type=true", {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data);
              const draft = res.data.Data?.report_Users.filter(
                (item: any) => item?.ReportUserID == yourID
              );
              if (draft[0]?.TypeReportJob) {
                setTypeOfReport(draft[0].TypeReportJob);
              }
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeTemplate = async () => {
        try {
          await ServiceUtils.getV2(LIST_OF_TEMPLATE, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              const tempArray = res.data.Data.map((item: any) => {
                return {
                  ...item,
                  value: item.NameOfTemplate,
                  label: item.NameOfTemplate,
                };
              });
              setListOfTemplate(tempArray);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
      takeTemplate();
    }
  }, []);

  // onDidUpdate
  const yourID = getCurrentUserID();
  const yourReport = data?.report_Users.filter(
    (item: any) => item?.ReportUserID == yourID
  );
  console.log("yourReport, ", yourReport);

  // funcs
  const _onClickDetail2 = (id: string) => {
    context.setTaskId(id);
    // context.setDataModal({ periodic: periodic, taskName: taskName })

    // context.setFuncsLoad({
    //   onLoad: _onLoadReport
    // })

    context.setFuncs("editReportInputForm");
    context.handleOpen();
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen h-[90vh]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        "
      >
        <p>Chi tiết yêu cầu</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {data === null ? (
        // <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm justify-center items-center flex-col">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <>
          {isShowCreate === false ? (
            <div className="w-full h-[calc(100%-6rem)] flex flex-col p-3 text-sm space-y-4 overflow-y-auto styled-scrollbars">
              {/* Info */}
              <div className="w-full h-max flex flex-col space-y-1">
                <p className="text-primary font-bold">THÔNG TIN CHUNG</p>
                <div className="w-full h-max flex">
                  <p className="font-semibold">Người tạo yêu cầu: &nbsp;</p>
                  <div>{data.report?.NameUserRequest}</div>
                </div>

                <div className="w-full h-max flex">
                  <p className="font-semibold">Ngày tạo yêu cầu: &nbsp;</p>
                  <div>
                    {moment(data.report?.DateRequest).format("HH:mm:ss")} -{" "}
                    {moment(data.report?.DateRequest).format("DD/MM/YYYY")}
                  </div>
                </div>

                <div className="w-full h-max flex">
                  <p className="font-semibold">Thời hạn yêu cầu: &nbsp;</p>
                  <div>
                    {moment(data.report?.DeadLine).format("HH:mm:ss")} -{" "}
                    {moment(data.report?.DeadLine).format("DD/MM/YYYY")}
                  </div>
                </div>

                <div className="w-full h-max flex flex-col">
                  <p className="font-semibold">Nội dung yêu cầu: &nbsp;</p>
                  <div className="w-full h-max">
                    <textarea
                      className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                      value={data.report?.RequestContent}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* Detail */}
              <div className="w-full h-max flex flex-col space-y-1">
                <p className="font-bold text-primary">THÔNG TIN CHI TIẾT</p>

                {/* Table detail */}
                <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-max shadow-md">
                  <table>
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border-l-[1px] border-slate-300">STT</th>
                        <th className="border-l-[1px] border-slate-300">
                          Trạng thái
                        </th>
                        <th className="border-l-[1px] border-slate-300">
                          Nhân viên báo cáo
                        </th>
                        <th className="border-l-[1px] border-slate-300">
                          Lịch sử báo cáo
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-max bg-red-200 overflow-auto">
                      {data?.report_Users?.map((item: any, index: number) => (
                        <tr key={index} className="w-max h-max bg-white">
                          <td className="text-center border border-slate-300">
                            {index + 1}
                          </td>
                          <td className="text-center border border-slate-300 font-semibold">
                            {item.ReportStatus === 0 ? (
                              <p className="text-amber-500">Đang yêu cầu</p>
                            ) : item.ReportStatus === 1 ? (
                              <p className="text-primary">Mới tạo báo cáo</p>
                            ) : item.ReportStatus === 2 ? (
                              <p className="text-purple-500">Đã gửi báo cáo</p>
                            ) : item.ReportStatus === 5 ? (
                              <p className="text-red-500">Không duyệt</p>
                            ) : item.ReportStatus === 4 ? (
                              <p className="text-gray-500">
                                Trả lại bổ sung hoặc chỉnh sửa
                              </p>
                            ) : item.ReportStatus === 3 ? (
                              <p className="text-green-500">Đã duyệt</p>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-left border border-slate-300">
                            {item?.NameReportUser}
                          </td>
                          <td className="text-left border border-slate-300">
                            {item?.report_history.length != 0 && (
                              <>
                                <div>- Tên báo cáo: {item?.ReportName}</div>
                                <div>
                                  - File báo cáo:{" "}
                                  {item?.FileName && (
                                    <span
                                      className="text-blue-500 hover:text-blue-600 ml-1 cursor-pointer"
                                      onClick={() =>
                                        window.open(item?.FileName, "_blank")
                                      }
                                    >
                                      Xem chi tiết
                                    </span>
                                  )}
                                </div>
                                <div>
                                  - Người nhận báo cáo:{" "}
                                  {
                                    item?.report_history[0]
                                      ?.report_reviewer_details[0].NameReviewer
                                  }
                                </div>
                                <div>
                                  - Nội dung phản hồi:{" "}
                                  {(item.ReportStatus == 5 ||
                                    item.ReportStatus == 3) &&
                                    item?.report_history[0]
                                      ?.report_reviewer_details[0]
                                      ?.report_history_details[0]
                                      ?.Comment_UserAdd}
                                </div>
                              </>
                            )}
                            {/* {item?.report_history.map(
                              (_item: any, _index: number) => (
                                <div
                                  key={_index}
                                  className="w-full h-max flex flex-col"
                                >
                                  <p className="font-semibold">
                                    {_index + 1}. {_item?.ReportName}
                                  </p>
                                  {_item?.report_reviewer_details.map(
                                    (_item2: any, _index2: number) => (
                                      <div
                                        key={_index2}
                                        className="w-full h-max flex"
                                      >
                                        <p>- {_item2.NameReviewer} - &nbsp;</p>
                                        <div className="font-semibold">
                                          {_item2.IsCheck === null ? (
                                            <p className="text-gray-500">
                                              Chưa duyệt
                                            </p>
                                          ) : _item2.IsCheck === true ? (
                                            <p className="text-green-500">
                                              Đã duyệt
                                            </p>
                                          ) : _item2.IsCheck === false ? (
                                            <p className="text-red-500">
                                              Không duyệt
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-[calc(100%-6rem)] flex flex-col p-3 text-sm space-y-4 overflow-auto">
              {/* Back button */}
              <div className="w-full h-max">
                <button
                  className="flex items-center space-x-1 text-primary hover:text-sky-400"
                  onClick={_onClickSetIsShowCreate}
                >
                  <ArrowBackIcon fontSize="small" />
                  <p>Quay lại</p>
                </button>
              </div>

              <div className="w-full flex flex-col gap-3">
                {/* Report's name */}
                <div className="flex flex-col w-full h-max">
                  <label>
                    Tên báo cáo<span className="text-red-500">(*)</span>
                  </label>
                  <input
                    className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                    value={reportName}
                    onChange={(e: any) => {
                      e.preventDefault();
                      setReportName(e.target.value);
                    }}
                  />
                </div>

                {/* Type of report */}
                {/* <div className="flex flex-col w-full h-max">
                <label>Hình thức báo cáo</label>
                <Select
                  className="z-[10]"
                  options={[
                    {
                      id: 0,
                      value: "Báo cáo công việc",
                      label: "Báo cáo công việc",
                    },
                    {
                      id: 1,
                      value: "Báo cáo ngoài công việc",
                      label: "Báo cáo ngoài công việc",
                    },
                  ]}
                  isClearable
                  onChange={(data: any) => {
                    if (data === null) {
                      setReportHowTo(null);
                    } else {
                      if (data?.id === 0) {
                        setReportHowTo(false);
                      } else {
                        setReportHowTo(true);
                      }
                    }
                  }}
                  placeholder="--- Chọn hình thức báo cáo ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}

                <div className="flex flex-col w-full h-max">
                  <label>
                    Loại báo cáo <span className="text-red-500">(*)</span>
                  </label>
                  {/* Type report job */}
                  {yourReport[0].TypeReportJob == null ? (
                    <Select
                      className="z-[9]"
                      options={[
                        {
                          id: 1,
                          value: "Báo cáo ngày",
                          label: "Báo cáo ngày",
                        },
                        {
                          id: 2,
                          value: "Báo cáo tháng",
                          label: "Báo cáo tháng",
                        },
                        {
                          id: 3,
                          value: "Báo cáo quý",
                          label: "Báo cáo quý",
                        },
                        {
                          id: 4,
                          value: "Báo cáo năm",
                          label: "Báo cáo năm",
                        },
                        {
                          id: 5,
                          value: "Báo cáo tự chọn",
                          label: "Báo cáo tự chọn",
                        },
                        {
                          id: 6,
                          value: "Báo cáo ngoài công việc",
                          label: "Báo cáo ngoài công việc",
                        },
                      ]}
                      isClearable
                      onChange={(data: any) => _onChangeTypeOfReport(data)}
                      placeholder="--- Chọn loại báo cáo ---"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  ) : (
                    <>
                      <input
                        className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                        type="text"
                        value={
                          yourReport[0].TypeReportJob == 1
                            ? "Báo cáo ngày"
                            : yourReport[0].TypeReportJob == 2
                            ? "Báo cáo tháng"
                            : yourReport[0].TypeReportJob == 3
                            ? "Báo cáo quý"
                            : yourReport[0].TypeReportJob == 4
                            ? "Báo cáo năm"
                            : yourReport[0].TypeReportJob == 5
                            ? "Báo cáo tự chọn"
                            : yourReport[0].TypeReportJob == 6
                            ? "Báo cáo ngoài công việc"
                            : ""
                        }
                        disabled
                      />
                    </>
                  )}
                </div>

                {/* From Date - To Date */}
                <div className="flex w-full h-max space-x-4">
                  {yourReport[0].TypeReportJob == 1 || typeOfReport === 1 ? (
                    <>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Từ ngày<span className="text-red-500">(*)</span>
                        </label>
                        <input
                          className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                          type="date"
                          value={fromDate}
                          onChange={(e: any) => {
                            e.preventDefault();
                            setFromDate(e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Đến ngày<span className="text-red-500">(*)</span>
                        </label>
                        <input
                          className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                          type="date"
                          value={toDate}
                          onChange={(e: any) => {
                            e.preventDefault();
                            setToDate(e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  ) : yourReport[0].TypeReportJob == 2 || typeOfReport === 2 ? (
                    <>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Chọn tháng<span className="text-red-500">(*)</span>
                        </label>
                        <Select
                          className="z-[8]"
                          options={[
                            {
                              id: 1,
                              value: "1",
                              label: "1",
                            },
                            {
                              id: 2,
                              value: "2",
                              label: "2",
                            },
                            {
                              id: 3,
                              value: "3",
                              label: "3",
                            },
                            {
                              id: 4,
                              value: "4",
                              label: "4",
                            },
                            {
                              id: 5,
                              value: "5",
                              label: "5",
                            },
                            {
                              id: 6,
                              value: "6",
                              label: "6",
                            },
                            {
                              id: 7,
                              value: "7",
                              label: "7",
                            },
                            {
                              id: 8,
                              value: "8",
                              label: "8",
                            },
                            {
                              id: 9,
                              value: "9",
                              label: "9",
                            },
                            {
                              id: 10,
                              value: "10",
                              label: "10  ",
                            },
                            {
                              id: 11,
                              value: "11",
                              label: "11",
                            },
                            {
                              id: 12,
                              value: "12",
                              label: "12",
                            },
                          ]}
                          isClearable
                          placeholder="--- Chọn tháng ---"
                          onChange={(data: any) => {
                            if (data === null) {
                              setMonth("");
                            } else {
                              setMonth(data.value);
                            }
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Chọn năm<span className="text-red-500">(*)</span>
                        </label>
                        <Select
                          className="z-[8]"
                          options={yearList}
                          isClearable
                          placeholder="--- Chọn năm ---"
                          onChange={(data: any) => {
                            if (data === null) {
                              setYear("");
                            } else {
                              setYear(data.value);
                            }
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </>
                  ) : yourReport[0].TypeReportJob == 3 || typeOfReport === 3 ? (
                    <>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Chọn quý<span className="text-red-500">(*)</span>
                        </label>
                        <Select
                          className="z-[8]"
                          options={[
                            {
                              id: 1,
                              value: "1",
                              label: "1",
                            },
                            {
                              id: 2,
                              value: "2",
                              label: "2",
                            },
                            {
                              id: 3,
                              value: "3",
                              label: "3",
                            },
                            {
                              id: 4,
                              value: "4",
                              label: "4",
                            },
                          ]}
                          isClearable
                          placeholder="--- Chọn quý ---"
                          onChange={(data: any) => {
                            if (data === null) {
                              setQuarter("");
                            } else {
                              setQuarter(data.value);
                            }
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Chọn năm<span className="text-red-500">(*)</span>
                        </label>
                        <Select
                          className="z-[8]"
                          options={yearList}
                          isClearable
                          placeholder="--- Chọn năm ---"
                          onChange={(data: any) => {
                            if (data === null) {
                              setYear("");
                            } else {
                              setYear(data.value);
                            }
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </>
                  ) : yourReport[0].TypeReportJob == 4 || typeOfReport === 4 ? (
                    <>
                      <div className="flex flex-col w-full h-max">
                        <label>
                          Chọn năm<span className="text-red-500">(*)</span>
                        </label>
                        <Select
                          className="z-[8]"
                          options={yearList}
                          isClearable
                          placeholder="--- Chọn năm ---"
                          onChange={(data: any) => {
                            if (data === null) {
                              setYear("");
                            } else {
                              setYear(data.value);
                            }
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </>
                  ) : yourReport[0].TypeReportJob == 5 || typeOfReport === 5 ? (
                    <div className="w-full mt-5">
                      <div className="w-full shadow-md styled-scrollbars">
                        <table className="">
                          <thead className="text-white font-semibold text-center w-full h-[2rem]">
                            <tr>
                              <th className="hidden lg:table-cell border border-slate-300 ">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>STT</p>
                                  <button className="hidden lg:flex w-max h-max hover:text-slate-200"></button>
                                </div>
                              </th>
                              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>Loại</p>
                                </div>
                              </th>
                              <th className="border-l-[1px] border-slate-300">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>Tên công việc</p>
                                </div>
                              </th>
                              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>Mức độ</p>
                                </div>
                              </th>
                              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>Ngày giao</p>
                                </div>
                              </th>
                              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>Người nhận</p>
                                </div>
                              </th>
                              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                                <div className="w-full h-full flex space-x-1 justify-center items-center">
                                  <p>Thời hạn</p>
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="w-full bg-white overflow-auto text-black">
                            {tasks === null ? (
                              <tr></tr>
                            ) : (
                              tasks?.map((item: any, index: any) => (
                                <tr
                                  className={`
                                  w-max h-[3rem] cursor-pointer
                                  ${
                                    selectedTask.some(
                                      (_item) => _item.TaskID === item.ID
                                    )
                                      ? "bg-green-200"
                                      : "bg-white"
                                  }
                                `}
                                  onClick={() =>
                                    _onClickAddingTaskOrRemove(item)
                                  }
                                >
                                  <td className="hidden lg:table-cell text-center border border-slate-300">
                                    {index + 1 + pageCurrent * pageLimit}
                                  </td>
                                  <td className="hidden lg:table-cell border border-slate-300">
                                    {item.IsRecipient ? (
                                      <p>Thực hiện chính</p>
                                    ) : (
                                      <p>Hỗ trợ</p>
                                    )}
                                  </td>
                                  <td className="border border-slate-300 space-y-0.5">
                                    <p className="lg:hidden font-bold">
                                      <span className="lg:hidden">{index}</span>
                                      {item.TaskName}
                                    </p>
                                    <p className="hidden lg:flex">
                                      {item.TaskName}
                                    </p>
                                    <p className="lg:hidden">
                                      <span className="text-xs">
                                        Người nhận:{" "}
                                      </span>
                                      <span className="font-semibold">
                                        {item.RecipientName}
                                      </span>
                                    </p>
                                    {item.SupporterName !== null && (
                                      <p className="lg:hidden">
                                        <span className="text-xs">
                                          Người hỗ trợ:&nbsp;
                                        </span>
                                        <span className="text-slate-600">
                                          {item.SupporterName}
                                        </span>
                                      </p>
                                    )}
                                    <div className="lg:hidden w-full h-max flex flex-col justify-center">
                                      <p>
                                        <span className="text-xs">
                                          Thời hạn:{" "}
                                        </span>
                                        <span className="italic">
                                          {moment(item.AssignmentDate).format(
                                            "HH:mm"
                                          )}
                                          &nbsp;
                                          {moment(item.AssignmentDate).format(
                                            "DD/MM"
                                          )}
                                        </span>
                                        &nbsp;-&nbsp;
                                        <span className="italic">
                                          {moment(item.DeadLine).format(
                                            "HH:mm"
                                          )}
                                          &nbsp;
                                          {moment(item.DeadLine).format(
                                            "DD/MM"
                                          )}
                                        </span>
                                      </p>
                                      <p>{item.PriorityLevelName}</p>
                                    </div>
                                  </td>
                                  <td className="hidden lg:table-cell text-center border border-slate-300">
                                    <p>{item.PriorityLevelName}</p>
                                  </td>
                                  <td className="hidden lg:table-cell text-center border border-slate-300">
                                    <div className="w-full h-max flex flex-col justify-center items-center">
                                      <p>
                                        {moment(item.DateAdd).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                      <p>
                                        {moment(item.DateAdd).format(
                                          "HH:mm:ss"
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="hidden lg:table-cell border border-slate-300">
                                    {item.RecipientName}
                                    {item.SupporterName && (
                                      <p className="text-xs">
                                        <span className="text-slate-600">
                                          Hỗ trợ:
                                        </span>{" "}
                                        {item.SupporterName}
                                      </p>
                                    )}
                                  </td>
                                  <td className="hidden lg:table-cell text-center border border-slate-300">
                                    <div className="w-full h-max flex flex-col justify-center items-center">
                                      <p>
                                        {moment(item.DeadLine).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                      <p>
                                        {moment(item.DeadLine).format(
                                          "HH:mm:ss"
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full pt-2">
                        <Pagination
                          count={pageTotal}
                          page={pageCurrent + 1}
                          variant="outlined"
                          color="primary"
                          size="small"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  ) : yourReport[0].TypeReportJob == 6 || typeOfReport === 6 ? (
                    <div className="w-full mt-5">
                      <TextEditor
                        value={planOfAction}
                        onChange={_onChangePlanOfAction}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* <div className="flex flex-col w-full h-max">
                <label>Mẫu báo cáo</label>
                <Select
                  className="z-[7]"
                  options={listOfTemplate}
                  isClearable
                  onChange={(data: any) => {
                    if (data === null) {
                      setSelectedTemplate("");
                      setSelectedTemplateID("");
                    } else {
                      setSelectedTemplateID(data?.ID);
                      setSelectedTemplate(data?.Template);
                    }
                  }}
                  placeholder="--- Chọn mẫu báo cáo ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}
            </div>
          )}
        </>
      )}

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md  items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-end items-center">
          <div className="w-max h-max flex space-x-3">
            {yourReport && yourReport[0]?.ReportStatus == 0 && (
              <>
                {isShowCreate === true ? (
                  <button
                    className="
                    flex items-center space-x-1 w-max h-max p-2 bg-amber-500 text-white rounded-md
                    hover:bg-amber-400
                  "
                    onClick={_onClickSubmit}
                  >
                    <ExitToAppTwoToneIcon fontSize="small" />
                    <p>Tạo báo cáo</p>
                  </button>
                ) : (
                  <>
                    <button
                      className="text-xs bg-amber-500 hover:bg-amber-400 p-1 rounded-md text-white "
                      onClick={_onClickSetIsShowCreate}
                    >
                      Tạo báo cáo
                    </button>
                  </>
                )}
              </>
            )}

            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetailOwnerAndManagerElectric3;
