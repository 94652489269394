import React from "react";

import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie, Bar } from 'react-chartjs-2';

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const IndividualCapacityUI = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] pl-5 pr-5 text-sm">
        <SubmitHeader />

        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          {/* top */}
          {/* <ContentTop /> */}
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
              <label>Xem kỳ đánh giá</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập tên công việc"
                type="date"
              />
            </div>
            <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
              <label>Chọn nhân viên</label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={0}
              >
                <option className="text-gray-300" value={0} disabled>--- Chọn nhân viên ---</option>
                <option>Nhân viên 1</option>
                <option>Nhân viên 2</option>
                <option>Nhân viên 3</option>
              </select>
            </div>

          </div>
          <br />
        </div>

        <div className="flex w-full h-[calc(100%-4rem)] divide-x-[2px] pb-3">
          {/* left */}
          <div className="flex flex-col w-1/2 h-full pr-3">
            <div className="w-full h-1/2">
              <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
                <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                  TÌNH HÌNH CÔNG VIỆC TRONG THÁNG
                </div>
                <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                  <Bar
                    className="w-full h-full"
                    datasetIdKey='id'
                    data={{
                      labels: [
                        'Công việc đuợc giao trong thời gian này',
                        'Công việc chưa tiếp nhận',
                        'Công việc đang xử lý',
                        'Công việc đã hoàn thành',
                        'Công việc tồn đọng trước đã hoàn thành',
                        'Công việc đang trễ hạn',
                        'Công việc hoàn trả lại người giao',
                        'Công việc hỗ trợ'
                      ],
                      datasets: [
                        {
                          label: 'Tổng',
                          data: [100, 10, 20, 70, 5, 3, 16, 120],
                          backgroundColor: [
                            '#3b82f6',
                            '#0ea5e9',
                            '#10b981',
                            '#eab308',
                            '#d946ef',
                            '#f43f5e',
                            '#84cc16',
                            '#14b8a6'
                          ]
                        },
                      ]
                    }}
                    options={{
                      indexAxis: 'y',
                      plugins: {
                        datalabels: {
                          color: 'white',
                          font: {
                            size: 13,
                            weight: 'bold',
                          },
                          formatter: (value: any) => `${value}`,
                          anchor: "center",
                          align: "center",
                        },
                        legend: {
                          display: false
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full h-1/2 pt-2">
              <div
                className="
                  flex flex-col w-full h-full shadow-md rounded-sm bg-white
                "
              >
                <div className="w-full h-full flex flex-col">
                  <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                    TÌNH HÌNH CÔNG VIỆC TRONG THÁNG
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                    <Pie
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Chưa nhận', 'Mới tiếp nhận', 'Đang xử lý', "Đúng hạn", "Trễ hạn"],
                        datasets: [{
                          label: 'Tỉ lệ',
                          data: [30, 30, 15, 15, 10],
                          backgroundColor: [
                            '#E2E2E2',
                            '#76CCF8',
                            '#00A516',
                            '#0794FF',
                            '#FF0000'
                          ]
                        }]
                      }}
                      options={{
                        plugins: {
                          datalabels: {
                            color: 'white',
                            font: {
                              size: 15,
                              weight: 'bold',
                            },
                            formatter: (value: any) => `${value}%`,
                            clamp: true
                          },
                          legend: {
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 11
                              }
                            }
                          }
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="flex flex-col w-1/2 h-full space-y-4 pl-3">
            <div className="w-full h-full bg-red-500">
              <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
                <div className="flex items-center font-semibold pl-2 pr-2 w-full rounded-t-sm h-[2rem] bg-green-400 text-white">
                  DANH SÁCH CÔNG VIỆC TRONG KỲ
                </div>

                <div className="
                  flex flex-col w-full h-[calc(100%-2rem)] overflow-y-auto divide-y-2 styled-scrollbars
                  text-sm
                ">
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-red-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-green-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2 w-full h-max">
                    <div className="flex flex-col w-max h-max">
                      <p className="font-semibold">Tên công việc</p>
                      <p>Mức độ: <span className="text-yellow-500">Ưu tiên</span></p>
                      <p>Ngày giao: <span className="font-semibol">01/01/2023 17:00:12</span></p>
                    </div>
                    <div className="flex flex-col w-max h-max items-end justify-end">
                      <p>Tên người thực hiện</p>
                      <p>Người giao: <span className="font-semibold">Tên lãnh đạo</span></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndividualCapacityUI;
