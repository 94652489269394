import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { FOLDER_IDS } from "../../../../constants/dataConstants";
import { useNavigate, useParams } from "react-router-dom";
import { FC } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { STORAGE_KEYS } from "../../../../constants/constants";
import FolderIcon from "@mui/icons-material/Folder";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  ONADD_SPREADRUM,
  ONMOVE_SPREADRUM,
} from "../../../../redux/reducers/systemReducer";
import { useDispatch } from "react-redux";

interface FolderItemProps {
  activeRef: any;
  item: any;
  ChangeType: boolean;
  activeDropTarget: any;
  itemActive: any;

  setItemActive: any;
  setContextMenuItem: any;

  _handleDragStart: any;
  _handleDragEnd: any;
  _handleDragOver: any;
  _handleDrop: any;
  _handleDragLeave: any;
  _handleContextMenu: any;

  _onClickRename: (item: any) => void;
  _onClickShare: (item: any) => void;
}

const FolderItem: FC<FolderItemProps> = ({
  activeRef,
  item,
  ChangeType,
  activeDropTarget,
  itemActive,

  setItemActive,
  setContextMenuItem,

  _handleDragStart,
  _handleDragEnd,
  _handleDragOver,
  _handleDrop,
  _handleDragLeave,
  _handleContextMenu,

  _onClickRename,
  _onClickShare,
}) => {
  const { id } = useParams();
  const isDriveTrash = id == FOLDER_IDS.trash;
  const isDriveShare = id == FOLDER_IDS.share;

  const yourID =
    (
      (JSON.parse(localStorage.getItem(STORAGE_KEYS.currentUser || "") || "")
        .Data || [])[0] || {}
    ).Id || "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _onDoubleClickFolder = (item: any) => () => {
    navigate(`/drives/${item.FolderId}`);

    dispatch(
      ONADD_SPREADRUM({
        id: item.FolderId,
        name: item.FolderName,
        callBack: () => {
          navigate(`/drives/${item.FolderId}`);
          dispatch(ONMOVE_SPREADRUM(item.FolderId));
        },
      })
    );
    setItemActive(null);
  };

  return (
    <>
      {ChangeType ? (
        <tr
          className={`folder-item-class w-max h-[3rem] hover:cursor-pointer border-y group border-slate-300 ${
            activeDropTarget?.FolderId === item.FolderId && "bg-blue-200 "
          } ${
            itemActive === item
              ? "bg-blue-200 hover:bg-blue-200"
              : "hover:bg-gray-200"
          }`}
          draggable
          onDragStart={(e) => _handleDragStart(e, item)}
          onDragEnd={() => _handleDragEnd()}
          onDragOver={(e) => _handleDragOver(e, item)}
          onDrop={(e) => _handleDrop(e, item, true)}
          onDragLeave={() => _handleDragLeave()}
          onDoubleClick={_onDoubleClickFolder(item)}
          onClick={() => setItemActive(item)}
          ref={activeRef}
          onContextMenu={(e) => {
            setItemActive(item);
            setContextMenuItem(item);
            _handleContextMenu(e);
            e.stopPropagation();
          }}
        >
          <td className="flex items-center justify-start gap-2">
            <FolderIcon className={`text-[${item.Icon}]`} />
            <p className="">{item.FolderName}</p>
          </td>
          {(isDriveTrash || isDriveShare) && (
            <td className="hidden lg:table-cell">
              {item.OwnerId == yourID ? "Tôi" : item.OwnerName}
            </td>
          )}
          <td className="hidden lg:table-cell">
            {moment(item.LastChangeDate).format("DD/MM/YYYY")}{" "}
            {moment(item.LastChangeDate).format("HH:mm:ss")}
          </td>
          <td className="hidden lg:table-cell">-</td>
          <td className="table-cell">
            <div className="justify-end flex">
              {!isDriveTrash && (
                <>
                  <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => _onClickShare(item)}
                  >
                    <PersonAddAltIcon className="p-0.5" />
                  </div>

                  {/* <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => _onClickDownload(item, true)}
                    >
                    <DownloadIcon className="p-0.5" />
                    </div> */}

                  <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => _onClickRename(item)}
                  >
                    <DriveFileRenameOutlineIcon className="p-0.5" />
                  </div>
                  {/* <div
                    className="hidden lg:block p-1 hover:bg-gray-300 rounded-full cursor-pointer opacity-0 group-hover:opacity-100"
                    >
                    <StarBorderIcon className="p-0.5" />
                    </div> */}
                </>
              )}

              <div>
                <button
                  className="openContextMenu hover:bg-gray-300 p-1 rounded-full cursor-pointer"
                  onClick={(e) => {
                    setItemActive(item);
                    setContextMenuItem(item);
                    _handleContextMenu(e);
                  }}
                >
                  <MoreVertIcon />
                </button>
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <div
          className={`folder-item-class items-center rounded-lg px-4 py-3 flex justify-between gap-2 cursor-pointer ${
            activeDropTarget?.FolderId === item.FolderId
              ? "bg-blue-200"
              : "bg-[#f5f5f7]"
          } ${
            itemActive === item
              ? "bg-blue-200 hover:bg-blue-200"
              : "hover:bg-gray-200"
          }`}
          draggable
          onDragStart={(e) => _handleDragStart(e, item)}
          onDragEnd={() => _handleDragEnd()}
          onDragOver={(e) => _handleDragOver(e, item)}
          onDrop={(e) => _handleDrop(e, item, true)}
          onDragLeave={() => _handleDragLeave()}
          onDoubleClick={_onDoubleClickFolder(item)}
          onClick={() => setItemActive(item)}
          ref={activeRef}
          onContextMenu={(e) => {
            setItemActive(item);
            setContextMenuItem(item);
            _handleContextMenu(e);
            e.stopPropagation();
          }}
        >
          <div className="flex justify-start gap-2 items-center flex-1 overflow-hidden overflow-ellipsis">
            <FolderIcon className={`text-[${item.Icon}]`} />
            <p className="truncate">{item.FolderName}</p>
          </div>

          <div>
            <button
              className="openContextMenu hover:bg-gray-300 p-1 rounded-full cursor-pointer"
              onClick={(e) => {
                setItemActive(item);
                setContextMenuItem(item);
                _handleContextMenu(e);
              }}
            >
              <MoreVertIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default FolderItem;
