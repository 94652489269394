import { TAKE_PARAMETER_LISTKEY_DROPDOWN } from "../apis/_index";
import ServiceUtils from "../utils/services";

export const phongbanDropdown = (key: any) => {
    return new Promise(async resolve => {
        // dispatch({
        //     type: 'LISTNANGLUCNHANSU', data: initialState
        // });
        return await ServiceUtils.getV2(TAKE_PARAMETER_LISTKEY_DROPDOWN + key)
            .then((res: any) => {

                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {
                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

