import React, { useEffect, useRef, useState } from "react";
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";
import { readReport, randomColor } from "../../../../funcs/reportCalling";
import { takePhongban } from "../../../../funcs/reportCalling";
import { REPORT_SUMGREENWORK } from "../../../../apis/_index";

import moment from "moment";
import OrganizationRepository from "../../../../repositories/organizations/organizationRepository";
import OrganizationUtils from "../../../../utils/organizations/organizationUtils";
import LevelComponent from "../../../../components/organizations/levels/levelComponent";

import "../../../../styles/reportSumGreenworks/index.css";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const SumGreenWorkReportUI = (props: any) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Lấy năm hiện tại
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
  const day = currentDate.getDate().toString().padStart(2, "0"); // Lấy ngày hiện tại

  const [data, setData] = useState<any[] | null>(null);

  const [arraySoNgay, setArraySoNgay] = useState<any[] | null>(null);
  const [arrayThang, setArrayThang] = useState<any[] | undefined>(undefined);
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);

  const [fromDate, setFromDate] = useState<any | null>(
    new Date().getFullYear()
  );
  const [toDate, setToDate] = useState<any | null>(new Date().getFullYear());

  const shouldLog = useRef(true);

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      if (res.status) {
        const data = res.data || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: "PhongBanId",
          parentId: "RefID",
        });

        setPhongBanList(organizationTrees);
      }
    });
  };

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: false,
      };

      readReport(REPORT_SUMGREENWORK, body, (res: any) => {
        setData(res.data.Data);
      });

      // takePhongban((res: any) => setPhongBanList(res.data.Data));

      getListOrganization();
    }
  });

  // follow first call
  useEffect(() => {
    if (data === null) {
    } else {
      let tempArraySoNgay: any[] = [];
      let tempArrayThang: any[] = [];
      let tempArrayChuaHoanThanh: any[] = [];

      data.forEach((item: any) => {
        let tempSoNgay = item.SoNgay;
        let tempThang = "Tháng " + item.Thang;
        tempArraySoNgay.push(tempSoNgay);
        tempArrayThang.push(tempThang);
      });

      setArraySoNgay(tempArraySoNgay);
      setArrayThang(tempArrayThang);
    }
  }, [data]);

  // filter
  const _onClickSubmit = () => {
    let body: any;

    if (selectedPhongban !== -1) {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        PhongBan: selectedPhongban,
        _isCurUser: false,
      };
    } else {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: false,
      };
    }

    readReport(REPORT_SUMGREENWORK, body, (res: any) => {
      setData(res.data.Data);
    });

    // takePhongban((res: any) => setPhongBanList(res.data.Data));

    getListOrganization();
  };
  return (
    <>
      <br></br>
      <div className="flex lg:flex-row flex-col w-full h-max px-3 gap-2 pl-2 core-report-sum-greenwork-filter">
        <div className="flex flex-col h-max">
          <label className="mb-1">Năm</label>
          <input
            className="h-[1.9rem] rounded-md border-2 border-slate-200 core-input-resize"
            placeholder="Năm"
            value={fromDate}
            type="number"
            id="year"
            name="year"
            min="1900"
            max="2099"
            onChange={(e: any) => {
              e.preventDefault();
              setFromDate(e.target.value);
              setToDate(e.target.value);
            }}
          />
        </div>

        <div className="flex flex-col">
          <label className="mb-1">Phòng ban</label>
          <select
            className="h-[1.9rem] rounded-md border-2 border-slate-200 core-select-resize"
            value={selectedPhongban}
            onChange={(e: any) => {
              e.preventDefault();
              setSelectedPhongban(parseInt(e.target.value));
            }}
          >
            <option className="text-gray-300" value={-1}>
              --- Xem tất cả ---
            </option>
            {phongBanList === null ? (
              <></>
            ) : (
              phongBanList.map((item: any, index: any) => (
                <option key={index} value={item.PhongBanId}>
                  <LevelComponent item={item} />
                  {item.PhongBan}
                </option>
              ))
            )}
          </select>
        </div>

        <div
          className="flex flex-col lg:w-1/3 lg:mt-1"
          style={{ textAlign: "center" }}
        >
          <label className="hidden lg:block">&nbsp; </label>
          <button
            className="
            flex items-center p-2 bg-primary rounded-md text-white
            hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 core-button-resize
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>
      <hr style={{ margin: "20px" }}></hr>
      <div className="flex w-full lg:h-4/5 h-[20rem] p-3">
        <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
          <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
            BIỂU ĐỒ TỔNG SỐ NGÀY VƯỢT TIẾN ĐỘ
          </div>
          <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
            <Bar
              className="w-full h-full"
              datasetIdKey="id"
              data={{
                labels: arrayThang,
                datasets: [
                  {
                    label: "Tổng ngày vượt tiến độ",
                    data: arraySoNgay,
                    backgroundColor: ["#0ea449"],
                  },
                ],
              }}
              options={{
                plugins: {
                  datalabels: {
                    color: "black",
                    font: {
                      size: 13,
                      weight: "bold",
                    },
                    formatter: function (value: any, context) {
                      return value === 0 ? "" : value;
                    },
                    anchor: "end",
                    align: "end",
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SumGreenWorkReportUI;
