import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const IndividualCapacity = () => {
  return (
    <HomeUI />
  )
}

export default IndividualCapacity;
