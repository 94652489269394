import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import HandleDate from "../../../utils/date/handleDate";

export const proposeReducer = createSlice({
  name: "proposeReducer",

  initialState: {
    statusList: null,
    typeList: null,
    priorList: null,
    senderList: null,
    receiverList: null,

    data: null,

    pagination: {
      currentPage: 0,
      itemPerPage: 10,
      totalItem: 0,
      totalPage: 0,
    },

    searchFilter: {
      name: "",
      selectedStatus: "",
      selectedType: "",
      selectedPrior: "",
      selectedSender: "",
      selectedReceiver: "",
      fromDate: "",
      toDate: "",
    },
  },

  reducers: {
    ONLOAD_DATA_LIST: (state: any, actions: PayloadAction<any>) => {
      state.data = actions.payload;
    },

    ONLOAD_TOTAL_ITEM: (state: any, actions: PayloadAction<any>) => {
      state.pagination.totalItem = actions.payload;

      if (actions.payload > 0) {
        if (actions.payload % state.pagination.itemPerPage === 0) {
          state.pagination.totalPage =
            actions.payload / state.pagination.itemPerPage;
        } else {
          state.pagination.totalPage =
            Math.floor(actions.payload / state.pagination.itemPerPage) + 1;
        }
      }
    },

    ONLOAD_TOTAL_PAGE: (state: any, actions: PayloadAction<any>) => {
      state.pagination.totalItem = actions.payload;
    },

    ONCHANGE_CURRENT_PAGE: (state: any, actions: PayloadAction<any>) => {
      state.pagination.currentPage = actions.payload;
    },

    ONCHANGE_ITEM_PER_PAGE: (state: any, actions: PayloadAction<any>) => {
      state.pagination.itemPerPage = actions.payload;
      state.pagination.currentPage = 0;
    },

    ONCHANGE_RESET_FILTER_PROPOSE1: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      const urlLink = actions.payload.url || window.location.pathname;

      state.searchFilter.name = "";
      state.searchFilter.selectedStatus = "";
      state.searchFilter.selectedType = "";
      state.searchFilter.selectedSender = "";
      state.searchFilter.selectedReceiver = "";
      state.searchFilter.selectedPrior = "";
      state.searchFilter.fromDate = "";
      state.searchFilter.toDate = "";
      // state.searchFilter.fromDate = HandleDate.firstDayOfMonth(urlLink);
      // state.searchFilter.toDate = HandleDate.currentDate(urlLink);

      state.pagination.currentPage = 0;
    },

    ONCHANGE_RESET_FILTER_PROPOSE2: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.searchFilter.name = "";
      state.searchFilter.selectedStatus = "0";
      state.searchFilter.selectedType = "";
      state.searchFilter.selectedSender = "";
      state.searchFilter.selectedReceiver = "";
      state.searchFilter.selectedPrior = "";
      state.searchFilter.fromDate = "";
      state.searchFilter.toDate = "";

      state.pagination.currentPage = 0;
    },

    ONLOAD_STATUS_LIST: (state: any, actions: PayloadAction<any>) => {
      state.statusList = actions.payload;
    },
    ONLOAD_TYPE_LIST: (state: any, actions: PayloadAction<any>) => {
      state.typeList = actions.payload;
    },
    ONLOAD_PRIOR_LIST: (state: any, actions: PayloadAction<any>) => {
      state.priorList = actions.payload;
    },
    ONLOAD_SENDER_LIST: (state: any, actions: PayloadAction<any>) => {
      state.senderList = actions.payload;
    },
    ONLOAD_RECEIVER_LIST: (state: any, actions: PayloadAction<any>) => {
      state.receiverList = actions.payload;
    },

    ONCHANGE_FILTER: (state: any, actions: PayloadAction<any>) => {

      switch (actions.payload.type) {
        case "name":
          state.searchFilter.name = actions.payload.data;
          break;

        case "status":
          state.searchFilter.selectedStatus = actions.payload.data;
          break;

        case "prior":
          state.searchFilter.selectedPrior = actions.payload.data;
          break;

        case "sender":
          state.searchFilter.selectedSender = actions.payload.data;
          break;

        case "receiver":
          state.searchFilter.selectedReceiver = actions.payload.data;
          break;

        case "type":
          state.searchFilter.selectedType = actions.payload.data;
          break;

        case "fromDate":
          state.searchFilter.fromDate = actions.payload.data;
          break;

        case "toDate":
          state.searchFilter.toDate = actions.payload.data;
          break;

        default:
          break;
      }
    },
  },
});

export const {
  ONCHANGE_RESET_FILTER_PROPOSE1,
  ONCHANGE_RESET_FILTER_PROPOSE2,
  ONLOAD_TYPE_LIST,
  ONLOAD_STATUS_LIST,
  ONLOAD_SENDER_LIST,
  ONLOAD_RECEIVER_LIST,
  ONLOAD_PRIOR_LIST,
  ONCHANGE_FILTER,
  ONLOAD_DATA_LIST,
  ONLOAD_TOTAL_ITEM,
  ONCHANGE_CURRENT_PAGE,
  ONCHANGE_ITEM_PER_PAGE,
  ONLOAD_TOTAL_PAGE,
} = proposeReducer.actions;

export default proposeReducer.reducer;
