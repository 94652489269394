import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

import { onTouchForm } from "../../../funcs/onTochForm";

import LoaderSpinner from "../../loader-spinner/_index";
import { toast } from "react-toastify";

import {
  DETAIL_PARAMETER,
  UPDATE_PARAMETER,
  READ_PARAMETER,
  GET_LIST_POSITION_BY_ORGANIZATION_DROPDOWN,
  GET_LIST_POSITION_DROPDOWN,
  GET_LIST_ORGANIZATION_DROPDOWN,
} from "../../../apis/_index";
import { UPDATE_PARAM_CACHE } from "../../../redux/reducers/settingReducer/_index";
import { ONLOAD_PARAMETER_LIST } from "../../../redux/reducers/settingReducer/_index";

import SubModal from "../_subModal/_index";
import { convertNameParameter } from "../../../helperText/index";
import {
  DIAGRAM_TYPES,
  ORGANIZATION_PARAMETER_TYPES,
  PARAMETER_KEYS,
  SETTING_ORGANIZATION_TYPES,
} from "../../../constants/dataConstants";
import { ModalController } from "../../../ui/homeUI/_index";

import Select, { StylesConfig } from "react-select";
import ServiceUtils from "../../../utils/services";

import "../../../styles/createListOfOrganizations/index.css";

const DetailListOfOrganization = (props: any) => {
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _onDeleteGeneral = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const data = useSelector((state: any) => state.settingReducer);

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  const dispatch = useDispatch();

  const [parameterName, setParameterName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [parameterKey, setParameterKey] = useState<any>("");
  const [parameterValue, setParameterValue] = useState<any>("");
  const [type, setType] = useState<any>(-1);
  const [organizations, setOrganization] = useState<any>([]);
  const [positions, setPosition] = useState<any>([]);
  const [positionUpperLevels, setPositionUpperLevel] = useState<any>([]);
  const [organizationParent, setOrganizationParent] = useState<any>(-1);
  const [upperLevelManager, setUpperLevelManager] = useState<any>(-1);
  const [mainManager, setMainManager] = useState<any>(-1);
  const [subManager, setSubManager] = useState<any>([]);

  const getListOrganization = () => {
    // const accessToken = localStorage.getItem("accessToken");

    try {
      ServiceUtils.getV2(GET_LIST_ORGANIZATION_DROPDOWN, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if ((res.data || {}).StatusCode === 200) {
          const organizations =
            ((res.data || {}).Data || {}).organizations || [];

          setOrganization(organizations);
        } else {
          toast.error("Lấy dữ liệu phòng ban thất bại!");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const getListPosition = () => {
    return new Promise((resolve) => {
      // const accessToken = localStorage.getItem("accessToken");

      try {
        ServiceUtils.getV2(GET_LIST_POSITION_DROPDOWN, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if ((res.data || {}).StatusCode === 200) {
            const positions = (
              ((res.data || {}).Data || {}).positions || []
            ).map((p: any) => {
              return {
                label: p.ParameterValue,
                value: p.ParameterId,
              };
            });

            setPosition(positions);

            resolve(positions);
          } else {
            toast.error("Lấy dữ liệu chức vụ thất bại!");

            resolve([]);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );

        resolve([]);
      }
    });
  };

  const getListPositionByOrganization = (organizationId: any) => {
    // const accessToken = localStorage.getItem("accessToken");

    try {
      ServiceUtils.getV2(
        GET_LIST_POSITION_BY_ORGANIZATION_DROPDOWN.replace(
          "{organizationId}",
          organizationId
        ),
        {
          headers: {
            Accept: "text/plain",
          },
        }
      ).then((res: any) => {
        if ((res.data || {}).StatusCode === 200) {
          const positionUpperLevels =
            ((res.data || {}).Data || {}).positions || [];

          setPositionUpperLevel(positionUpperLevels);
        } else {
          toast.error("Lấy dữ liệu chức vụ thất bại!");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const onChangeOrganizationParent = (e: any) => {
    const value = e.target.value;

    setOrganizationParent(value);

    getListPositionByOrganization(value);
  };

  const onChangeUpperLevelManager = (e: any) => {
    const value = e.target.value;

    setUpperLevelManager(value);
  };

  const onChangeSubManager = (data: any) => {
    // const value = e.target.value;

    // setSubManager(value);

    setSubManager(data);
  };

  const onChangeMainManager = (e: any) => {
    const value = e.target.value;

    setMainManager(value);
  };

  const _onChangeType = (e: any) => {
    const value = e.target.value;

    setType(value);
  };

  useEffect(() => {
    getListOrganization();

    getListPosition().then((positions: any) => {
      if (parameterKey === "" && parameterValue === "" && shouldLog.current) {
        shouldLog.current = false;
        const fetchData = async () => {
          try {
            const API = DETAIL_PARAMETER + taskId.toString();
            await ServiceUtils.getV2(API, {
              headers: {},
            }).then((res: any) => {
              if ((res.data || {}).StatusCode === 200) {
                const data = ((res.data || {}).Data || {}).data[0];
                const diagrams = ((res.data || {}).Data || {}).diagrams || [];

                setParameterKey(data.ParameterKey);
                setParameterName(data.ParameterValue);
                setIsActive(data.IsActive);
                setType(data.ParameterType);
                setOrganizationParent(data.RefId);

                const diagramUpperLevelManager = diagrams.find(
                  (p: any) => p.DiagramType == DIAGRAM_TYPES.upperLevelManaer
                );

                if (diagramUpperLevelManager) {
                  setUpperLevelManager(diagramUpperLevelManager.ChucVu);
                }

                if (data.RefId) {
                  getListPositionByOrganization(data.RefId);
                }

                const diagramMainManager = diagrams.find(
                  (p: any) => p.DiagramType == DIAGRAM_TYPES.mainManager
                );

                if (diagramMainManager) {
                  setMainManager(diagramMainManager.ChucVu);
                }

                const diagramSubManager = diagrams.filter(
                  (p: any) => p.DiagramType == DIAGRAM_TYPES.subManager
                );

                if (diagramSubManager) {
                  setSubManager(
                    diagramSubManager.map((p: any) => {
                      return {
                        label:
                          (
                            positions.find((m: any) => m.value == p.ChucVu) ||
                            {}
                          ).label || "",
                        value: p.ChucVu,
                      };
                    })
                  );
                }
              } else {
                toast.error("Tải dữ liệu thất bại.");
              }
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };
        fetchData();
      } else {
        shouldLog.current = false;
        return;
      }
    });
  }, []);

  // funcs
  const _onClickUpdateParameter = async () => {
    if (
      !parameterName ||
      (ORGANIZATION_PARAMETER_TYPES.department != type &&
        ORGANIZATION_PARAMETER_TYPES.organization != type &&
        ORGANIZATION_PARAMETER_TYPES.partAndTeam != type)
    ) {
      return;
    }

    setIsLoading(true);

    const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      ParameterId: taskId,
      ParameterKey: PARAMETER_KEYS.organization,
      ParameterValue: parameterName,
      IsActive: isActive,
      RefID: organizationParent,
      ParameterType: type,
      UpperLevelManager: upperLevelManager,
      MainManager: mainManager,
      SubManager: subManager.map((p: any) => p.value),
    };

    try {
      ServiceUtils.postV2(UPDATE_PARAMETER, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          if (res.status === 200) {
            setIsLoading(false);

            const callBack = (context.dataModal || {}).callBack;

            if (callBack) {
              callBack();
            }

            setParameterName("");
            setType(-1);
            setOrganizationParent(-1);
            setUpperLevelManager(-1);
            setMainManager(-1);
            setSubManager(-1);

            toast.success("Cập nhật thành công");

            context.handleClose();
          } else {
            setIsLoading(false);

            toast.error("Thêm danh mục phòng ban thất bại!");
          }
        } else {
          toast.error("Cập nhật danh mục phòng ban thất bại!");
        }
      });
    } catch (error: any) {
      setIsLoading(false);

      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const _onChangeParameterName = (e: any) => {
    e.preventDefault();
    setParameterName(e.target.value);
  };

  const _onChangeIsActive = () => {
    setIsActive((data: any) => !data);
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[50rem] w-screen h-[41rem]
    rounded-md
  "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Chi tiết / Chỉnh sửa</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {(parameterKey === "" && parameterValue === "") || isLoading == true ? (
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang xử lý dữ liệu</p>
        </div>
      ) : (
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
          {/* lines */}
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full pr-0 h-max">
              <label>
                Tên danh mục phòng ban&nbsp;
                <span className="text-red-500">(*)</span>
              </label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={parameterName}
                onChange={(e: any) => {
                  _onChangeParameterName(e);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Loại tổ chức&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={type}
                onChange={(e: any) => _onChangeType(e)}
              >
                <option className="text-gray-300" value={-1} disabled>
                  --- Chọn loại tổ chức ---
                </option>
                {SETTING_ORGANIZATION_TYPES.map((item: any, index: any) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Thuộc tổ chức&nbsp;<span className="text-red-500"></span>
              </label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={organizationParent}
                onChange={(e: any) => onChangeOrganizationParent(e)}
              >
                <option className="text-gray-300" value={-1}>
                  --- Thuộc tổ chức ---
                </option>
                {organizations.map((item: any, index: any) => (
                  <option key={index} value={item.ParameterId}>
                    {item.ParameterValue}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Người quản lý từ cấp trên&nbsp;
                <span className="text-red-500"></span>
              </label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={upperLevelManager}
                onChange={(e: any) => onChangeUpperLevelManager(e)}
              >
                <option className="text-gray-300" value={0}>
                  --- Người quản lý từ cấp trên---
                </option>
                {positionUpperLevels.map((item: any, index: any) => (
                  <option key={index} value={item.ParameterId}>
                    {item.ParameterValue}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Người quản lý chính&nbsp;<span className="text-red-500"></span>
              </label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={mainManager}
                onChange={(e: any) => onChangeMainManager(e)}
              >
                <option className="text-gray-300" value={0}>
                  --- Người quản lý chính ---
                </option>
                {positions.map((item: any, index: any) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Người quản lý phụ&nbsp;<span className="text-red-500"></span>
              </label>
              {/* <select
                                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                value={subManager}

                                onChange={(e: any) => onChangeSubManager(e)}
                            >
                                <option className="text-gray-300" value={0}>--- Người quản lý phụ ---</option>
                                {positions.map((item: any, index: any) => (
                                    <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                                ))
                                }
                            </select> */}
              <Select
                // ref={selectDauViec}
                className="z-[5]"
                options={positions}
                value={subManager}
                onChange={(data: any) => onChangeSubManager(data)}
                isClearable
                isMulti
                placeholder="--- Người quản lý phụ ---"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                // defaultValue={tempDauViecList?.find(
                //   (item) => item?.Id === selectedDauViec?.Id
                // )}
              />
            </div>
          </div>
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Hiển thị&nbsp;<span className="text-red-500"></span>
              </label>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={isActive}
                onChange={_onChangeIsActive}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>
        </div>
      )}
      {parameterKey === "" && parameterValue === "" ? (
        <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center  text-sm space-x-4"></div>
      ) : (
        <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center text-sm space-x-4">
          <button
            className={`
        flex items-center space-x-1 w-max h-max p-2  text-white rounded-md
        ${
          isLoading === true
            ? "bg-slate-300"
            : "bg-slate-500 hover:bg-slate-400"
        }
      `}
            disabled={isLoading}
            onClick={() => _onDeleteGeneral("deleteGeneral")}
          >
            <DeleteIcon fontSize="small" />
            <p>Xoá</p>
          </button>
          <div className="flex w-max h-max space-x-3">
            <button
              className={`
        flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
        ${isLoading === true ? "bg-slate-300" : "bg-primary hover:bg-sky-400"}
      `}
              onClick={_onClickUpdateParameter}
              disabled={isLoading}
            >
              <SaveIcon fontSize="small" />
              <p>Cập nhật</p>
            </button>

            <button
              className="
        flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
        hover:bg-slate-200 core-create-list-of-organization-button-close
      "
              onClick={handleClose}
              disabled={isLoading}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailListOfOrganization;
