import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ONCHANGE_DATE,
  ONCHANGE_FILTER_SELECTED_PHONGBAN,
  ONCHANGE_FROM_HOME_TO_PROCESSING,
} from "../../redux/reducers/workingReducer/_index";

import Select from "react-select";
import { onTouchForm } from "../../funcs/onTochForm";
import DateTimePickerControl from "../../controls/datetimePicker/datetimePickerControl";
import OrganizationRepository from "../../repositories/organizations/organizationRepository";
import OrganizationUtils from "../../utils/organizations/organizationUtils";
import LevelComponent from "../organizations/levels/levelComponent";

const WorkingUpdateDelivery = () => {
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const [organizations, setOrganization] = useState<any>([]);

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      if (res.status) {
        const data = res.data || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: "PhongBanId",
          parentId: "RefID",
        });

        const organizationTreeValueLabels =
          OrganizationUtils.convertToListValueLabel(organizationTrees, {
            value: "PhongBanId",
            label: "PhongBan",
          });

        setOrganization(organizationTreeValueLabels);
      }
    });
  };

  // useEffect(() => {
  //   // handleFormat();
  // }, []);

  useEffect(() => {
    getListOrganization();
  }, []);

  //
  const [fromDate, setFromDate] = useState<string>(
    workingData.searchFilter.fromDate === ""
      ? new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
          .toISOString()
          .slice(0, 16)
      : workingData.searchFilter.fromDate
  );

  const [toDate, setToDate] = useState<string>(
    workingData.searchFilter.toDate === ""
      ? new Date(
          new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
        )
          .toISOString()
          .slice(0, 16)
      : workingData.searchFilter.toDate
  );

  // useEffect(() => {
  //   setFromDate(moment(workingData.searchFilter.fromDate).format("DD/MM/YYYY"))
  // }, [workingData.searchFilter.fromDate])

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });
  const tempBossList = workingData.bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });
  const tempPhongBan = workingData.listPhongBan?.map((item: any) => {
    return { ...item, label: item.ParameterValue, value: item.ParameterValue };
  });

  let tempSelectedPhongBan = tempPhongBan?.find(
    (item: any) => item.ParameterId === workingData.searchFilter.selectPhongBan
  );

  return (
    <div className="flex lg:flex-row flex-col w-full h-max">
      <div className="flex lg:flex-row flex-col w-full h-max gap-5">
        <DateTimePickerControl
          label={"Ngày giao (Từ ngày)"}
          value={workingData.searchFilter.fromDate}
          onChange={(e: any) => {
            const value = e;
            dispatch(ONCHANGE_DATE({ type: "fromDate", value }));
          }}
        />

        {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Ngày giao (Từ ngày)</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            type="datetime-local"
            value={workingData.searchFilter.fromDate}
            onChange={(e: any) => {
              dispatch(
                ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
              );
            }}
          // onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div> */}

        <DateTimePickerControl
          label={"Ngày giao (Đến ngày)"}
          value={workingData.searchFilter.toDate}
          onChange={(e: any) => {
            const value = e;
            dispatch(ONCHANGE_DATE({ type: "toDate", value }));
          }}
        />

        {/* 
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Ngày giao (Đến ngày)</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            type="datetime-local"
            value={workingData.searchFilter.toDate}
            onChange={(e: any) => {
              setToDate(e.target.value);
              dispatch(
                ONCHANGE_DATE({
                  type: "toDate",
                  value: e.target.value || toDate,
                })
              );
            }}
          />
        </div> */}

        {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Thời hạn</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Thời hạn"
            type="datetime-local"
            value={workingData.searchFilter.deadLine}
            onChange={(e: any) =>
              dispatch(
                ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
              )
            }
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div> */}

        <DateTimePickerControl
          label={"Thời hạn"}
          value={workingData.searchFilter.fromDate}
          onChange={(e: any) => {
            const value = e;

            dispatch(ONCHANGE_DATE({ type: "deadLine", value }));
          }}
        />

        <div className="flex flex-col lg:w-1/3 w-full h-max">
          <label className="mb-1">Phòng ban</label>
          <Select
            className="z-[2]"
            options={organizations}
            onChange={(data: any) =>
              dispatch(ONCHANGE_FILTER_SELECTED_PHONGBAN(data))
            }
            // value={tempSelectedPhongBan}
            isClearable
            placeholder="--- Chọn phòng ban ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Option: (props: any, a: any) => {
                return (
                  <div {...props.innerProps} className="core-select-item">
                    <LevelComponent item={props.data} />
                    {props.label}
                  </div>
                );
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkingUpdateDelivery;
