const STORAGE_KEYS = {
  currentUser: "userData",
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  hoursRemaining: "Hours_in_month_remaining",
};

const MODAL_KEYS = {
  reviewSupporter: "reviewSupporter",
  recoveryTask: "recoveryTask",
  editTaskRecoveryAndTransfer: "editTaskRecoveryAndTransfer",
  editTaskTransfer: "editTaskTransfer",
  detailTaskRecovery: "detailTaskRecovery",
  historyTaskRecovery: "historyTaskRecovery",
  createOrUpdateCategorySuggest: "createOrUpdateCategorySuggest",
  deleteCategorySuggest: "deleteCategorySuggest",
  createOrUpdateSettingProceduce: "createOrUpdateSettingProceduce",
  receiveTaskAndStart: "receiveTaskAndStart",
  chooseBossNoti: "chooseBossNoti",
  reviewHistoryTaskPrevious: "reviewHistoryTaskPrevious",
  detailProcedureStep: "detailProcedureStep",
  createSettingOrganization: "createSettingOrganization",
  detailSettingOrganization: "detailSettingOrganization",
  showStaffOrganization: "showStaffOrganization",
  deleteProcedure: "deleteProcedure",
  alertUploadFile: "alertUploadFile",
};

const NAVIGATION_KEYS = {
  recoveryTask: "/working/task-recovery",
  revokeTask: "/working/receiver/task-recovery",
  catgegorySuggest: "/setting/listof-category/suggest",
  settingProceduce: "/setting/proceduce",
  reportWaitingVerify: "/report/confirm",
  settingOrganization: "/setting/listof-category/list-of-organization",
  folderShare: "/drives/share",
  driveTrash: "/drives/trash",
};

const SERVER_STATUSES = {
  unAuthorized: 401,
};

const VARIABLES = {
  isRefeshToken: false,
  isCancel: false,
  viewHeight: "100vh",
  viewWidth: window.innerWidth,
};

const REQUESTS = {
  countRetry: 2,
  timeOut: 10000,
  countRetryRefreshToken: 2,
};

const STATUS_CODE_RESPONSES = {
  serverError: {
    code: 500,
    message: "Lỗi hệ thống. Xin vui lòng thử lại sau",
  },
  forbidden: {
    code: 403,
    message: "Bạn không có quyền truy cập vào trang này",
  },
  authorize: {
    code: 401,
    message: "Phiên đăng nhập của bạn đã hết hạn. Xin vui lòng đăng nhập lại",
  },
  notFound: {
    code: 404,
    message: "Không tìm thấy trang này",
  },
  invalidUrl: {
    code: 400,
    message: "Đường dẫn không hợp lệ",
  },
  somethingWrong: {
    code: 404,
    message: "Có trục trặc trong quá trình xử lý. Xin vui lòng thử lại sau",
  },
  notFoundInstanceRequest: {
    code: 501,
    message: "Không tìm thấy request này",
  },
};

const API_REQUESTS = {
  cmsAPI: "cmsAPI",
};

export {
  STORAGE_KEYS,
  MODAL_KEYS,
  NAVIGATION_KEYS,
  SERVER_STATUSES,
  VARIABLES,
  REQUESTS,
  STATUS_CODE_RESPONSES,
  API_REQUESTS,
};
