import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HistoryIcon from "@mui/icons-material/History";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import { USER_READ_ALL_DROPDOWN } from "../../../apis/_index";

import MoreTimeIcon from "@mui/icons-material/MoreTime";

import { CHECK_RESULT } from "../../../apis/_index";
import { LICH_SU_GIA_HAN } from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import ServiceUtils from "../../../utils/services";

const ReadHistoryExtend = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const [isResult, setIsResult] = useState<any>(null);
  const [timeExtend, setTimeExtend] = useState<any>(null);
  const [extendId, setExtendId] = useState<any>(null);
  const [task, setTask] = useState<any>(null);
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [_taskContents, set_TaskContents] = useState<any[]>([]);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);
  const [propoceID, setPropoceID] = useState<any>(null);
  const urlLink = window.location.pathname;
  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;
  // funcs

  const _onClickSubModal = (
    text: string,
    isResult: any,
    timeExtend: any,
    extendId: any
  ) => {
    handleOpenSub();
    setFuncsSub(text);
    setIsResult(isResult);
    setExtendId(extendId);
    setTimeExtend(timeExtend);
  };

  useEffect(() => {
    // const accessToken = localStorage.getItem("accessToken");
    const API = LICH_SU_GIA_HAN(taskId);
    const takeData = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (((res || {}).data || {}).StatusCode === 200) {
            setTaskContentsHistory(((res || {}).data || {}).Data || []);
          } else {
            toast.error(res.data.Message || "Lỗi không xác định");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  }, []);

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[40rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        isResult={isResult}
        taskContentsHistory={taskContentsHistory}
        taskId={taskId}
        timeExtend={timeExtend}
        extendId={extendId}
        _taskContents={_taskContents}
        task={task === null ? " " : task}
      />
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Lịch sử gia hạn</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {taskContentsHistory.length === 0 ? (
        <div className="flex justify-center items-center h-full overflow-auto p-4 text-lg">
          <p className="text-primary">Không có gia hạn</p>
        </div>
      ) : (
        <div className="tableFixHead styled-scrollbars styled-scrollbars h-full overflow-hidden p-4 text-sm">
          {taskContentsHistory.length > 0 ? (
            <>
              <table>
                <tbody className="h-[calc(100%-2rem)] bg-white overflow-auto">
                  {taskContentsHistory.map((item: any, index: any) => (
                    <tr key={index} className="h-[3rem] bg-white">
                      <td className="border border-slate-300">
                        <p className="font-bold"> Gia hạn lần {index + 1}:</p>
                        <p>
                          Thời gian đề xuất gia hạn:&nbsp;
                          {moment(item.NewDate).format("DD/MM/YYYY hh:mm:ss")}
                        </p>
                        <p>Lý do xin gia hạn: {item.Reason}</p>

                        {item.IsResult === true && item.TimeExtend && (
                          <div>
                            <p>
                              Thời gian được duyệt:&nbsp;
                              {moment(item.TimeExtend).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}
                            </p>
                          </div>
                        )}

                        {item.IsResult === false ? (
                          <p>Lý do không duyệt: {item.Result}</p>
                        ) : (
                          item.IsResult === true &&
                          item.TimeExtend && (
                            <p>Lý do đổi ngày: {item.Result}</p>
                          )
                        )}

                        <p>
                          {item?.HoursExtend == null
                            ? "Không xin gia hạn về số giờ"
                            : `Thời gian dự kiến hoàn thành: ${item?.HoursExtend} giờ`}
                        </p>

                        {item.IsResult === true ? (
                          <div className="flex justify-end mt-2">
                            <p className="items-center pl-2 pr-2 pt-1 pb-1 border-green-500 border-2 text-green-500 rounded-md">
                              Đã duyệt
                            </p>
                          </div>
                        ) : item.IsResult === false ? (
                          <div className="flex justify-end mt-2">
                            <p className="items-center pl-2 pr-2 pt-1 pb-1 border-red-500 border-2 text-red-500 rounded-md">
                              Không duyệt
                            </p>
                          </div>
                        ) : item.IsResult === null ? (
                          <>
                            {/* {urlLink === "/working/task-proccessing" ||
                              urlLink === "/working/task-delay" ||
                              urlLink === "/working/task-return" ||
                              urlLink === "/working/task-change-deadline" ? (
                              <div className="flex justify-between mt-2">
                                <button
                                  className="flex items-center space-x-1 pl-2 pr-2 pt-1 pb-1 bg-green-500 text-white rounded-md
          hover:bg-green-400"
                                  onClick={() => {
                                    _onClickSubModal(
                                      "confirmExtend",
                                      2,
                                      item.NewDate,
                                      item.ID
                                    );

                                  }}
                                >
                                  <DoneIcon fontSize="small" />
                                  <p>Duyệt</p>
                                </button>

                                <button
                                  className="flex items-center space-x-1 pl-2 pr-2 pt-1 pb-1 bg-green-500 text-white rounded-md
          hover:bg-green-400"
                                  onClick={() => {
                                    _onClickSubModal(
                                      "confirmExtend",
                                      1,
                                      item.NewDate,
                                      item.ID
                                    );

                                  }}
                                >
                                  <DoneIcon fontSize="small" />
                                  <p>Duyệt thời hạn khác</p>
                                </button>
                                <button
                                  className="
          flex items-center space-x-1 h-max pl-2 pr-2 pt-1 pb-1 bg-red-500 text-white rounded-md
          hover:bg-red-400
        "
                                  onClick={() =>
                                    _onClickSubModal(
                                      "confirmExtend",
                                      0,
                                      null,
                                      item.ID
                                    )
                                  }
                                >
                                  <CloseIcon fontSize="small" />
                                  <p>Không duyệt</p>
                                </button>
                              </div>
                            ) : ( */}
                            <div className="flex justify-end mt-2">
                              <p className="items-center pl-2 pr-2 pt-1 pb-1 border-yellow-500 border-2 text-yellow-500 rounded-md">
                                Chưa duyệt
                              </p>
                            </div>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : null}
        </div>
      )}

      {/* footer */}
      <div className="flex p-12-16 footer rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
          hover:bg-slate-300
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReadHistoryExtend;
