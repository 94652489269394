import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const DetailWorkerCapacity = () => {
  return (
    <HomeUI />
  )
}

export default DetailWorkerCapacity;
