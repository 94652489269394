const convertData = (data: any): any => {
    let newData = data;

    if (newData == '-1') {
        newData = null;
    } else if (newData) {
        if (Array.isArray(newData)) {
            for (let i = 0; i < newData.length; i++) {
                newData[i] = convertData(newData[i]);
            }
        } else if (typeof newData == 'object') {
            const keys = Object.keys(newData);

            for (let i = 0; i < keys.length; i++) {
                newData[keys[i]] = convertData(newData[keys[i]]);
            }
        }
    }

    return newData;
}

const HandleData = {
    convertData
}

export default HandleData;