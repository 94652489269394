

const LevelComponent = (props: any) => {
    const item = props.item || {};

    if (!item.level) {
        return null;
    }

    return (
        <>
            {''.padStart((item.level || 0) * 2, '-')}{" "}
        </>
    );
}

export default LevelComponent;