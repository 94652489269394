import React, { useState, useRef, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";

import { useDispatch } from "react-redux";
import { tieuchidanhgia_list } from "../../../action/tieuchidanhgiaAction";

import { ONCHANGE_TARGET_RATING } from "../../../redux/reducers/settingReducer/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import { CHiTietTieuChiDanhGia } from "../../../apis/_index";

import {
  nhomThamChieu,
  tieuchidanhgia_update,
} from "../../../action/tieuchidanhgiaAction";

import SubModal from "../_subModal/_index";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

const DetailTargetRatingCapacity = (props: any) => {
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [color, setColor] = useState<string>("#aabbcc");
  const [data, setData] = useState<any>(null);

  const taskId = props.taskId;

  const dispatch = useDispatch();

  const [tentieuchi, setTentieuchi] = useState<string>("");
  const [moTa, setMoTa] = useState<string>("");
  const [maTieuChi, setMaTieuChi] = useState<string>("");
  const [nhomtieuchi, setNhomtieuchi] = useState<string>("");
  const [selectedNhomThamChieu, setSelectedNhomThamChieu] = useState<
    any | null
  >(-1);
  const [nhomThamChieuList, setNhomThamChieuList] = useState<any | null>(null);
  const [IsActive, setIsActive] = useState<boolean>(false);

  const handleClose = props.handleClose;
  const shouldLog = useRef(true);

  useEffect(() => {
    if (shouldLog.current) {
      // const accessToken = localStorage.getItem("accessToken");
      const API = CHiTietTieuChiDanhGia + taskId.toString();
      shouldLog.current = false;

      getNhomThamChieu();

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setData(res.data.Data[0]);
            setSelectedNhomThamChieu(res.data.Data[0].Query);
            setIsActive(res.data.Data[0].IsActive);
            setTentieuchi(res.data.Data[0].tentieuchi);
            setMaTieuChi(res.data.Data[0].matieuchi);
            setNhomtieuchi(res.data.Data[0].nhomtieuchi);
            setMoTa(res.data.Data[0].noidung);
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  // funcs
  const getNhomThamChieu = () => {
    nhomThamChieu().then((res: any) => {
      setNhomThamChieuList(res.data);
    });
  };

  const _onChangeTenTieuChi = (e: any) => {
    e.preventDefault();
    setTentieuchi(e.target.value);
  };
  const _onChangeNhomThamChieu = (e: any) => {
    e.preventDefault();
    setSelectedNhomThamChieu(e.target.value);
  };

  const _onChangeMaTieuChi = (e: any) => {
    e.preventDefault();
    setMaTieuChi(e.target.value);
  };

  const _onChangeNhomTieuChi = (e: any) => {
    e.preventDefault();
    setNhomtieuchi(e.target.value);
  };

  const _onChangeMota = (e: any) => {
    e.preventDefault();
    setMoTa(e.target.value);
  };
  const _onChangeIsActive = (e: any) => {
    e.preventDefault();
    setIsActive(e.target.checked);
  };

  const handleUpdateTieuChi = () => {
    const uptadeData = {
      id: taskId,
      nhomtieuchi: nhomtieuchi,
      matieuchi: maTieuChi,
      tentieuchi: tentieuchi,
      noidung: moTa,
      IsActive: IsActive,
      Query: selectedNhomThamChieu,
    };

    tieuchidanhgia_update(uptadeData).then((res: any) => {
      if (res.data) {
        tieuchidanhgia_list().then((res: any) => {
          dispatch(ONCHANGE_TARGET_RATING(res.data));
        });
        toast.success("Cập nhật tiêu chí thành công.");
        handleClose();
      } else {
        toast.error("Cập nhật tiêu chí thất bại.");
      }
    });
  };

  const _onDeleteTieuChi = () => {
    handleOpenSub();
    setFuncsSub("deleteTieuChi");
  };

  return (
    <div
      className="
    flex flex-col bg-white responsive-container
    lg:w-[34rem] w-screen lg:h-[28rem]
    rounded-md justify-between
  "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Thông tin tiêu chí</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full lg:h-[calc(100%-6rem)] h-auto overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="lg:flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max lg:pr-1">
            <label>
              Tên tiêu chí&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={tentieuchi}
              onChange={(e: any) => {
                _onChangeTenTieuChi(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>

          <div className="flex flex-col w-full pr-0 h-max lg:pl-1">
            <label>
              Mã tiêu chí&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={maTieuChi}
              onChange={(e: any) => {
                _onChangeMaTieuChi(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="lg:flex w-full h-max ">
          <div className="flex flex-col w-full pr-0 h-max lg:pr-1">
            <label>
              Nhóm tiêu chí&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={nhomtieuchi}
              onChange={(e: any) => {
                _onChangeNhomTieuChi(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>

          <div className="flex flex-col w-full pr-0 h-max lg:pl-1">
            <label>
              Nhóm tham chiếu&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 border-2 border-slate-200"
              // defaultValue={
              //   data === null ? "" : nhomThamChieuList[data.Query].QueryName
              // }
              value={selectedNhomThamChieu === -1 ? -1 : selectedNhomThamChieu}
              onChange={(e: any) => {
                _onChangeNhomThamChieu(e);
              }}
            >
              <option className="text-gray-300" value={-1} disabled>
                --- Chọn nhóm tham chiếu ---
              </option>
              {nhomThamChieuList !== null
                ? nhomThamChieuList.map((item: any, index: any) => (
                    <option key={index} value={item.ID}>
                      {item.QueryName}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-row w-1/2 space-x-2 h-max">
            <p className="font-bold text-primary">Hiển thị</p>
            <input
              type="checkbox"
              className="w-5 h-5"
              checked={IsActive}
              onChange={(e: any) => {
                _onChangeIsActive(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Mô tả&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
              defaultValue={moTa}
              onChange={(e: any) => {
                _onChangeMota(e);
              }}
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center text-sm space-x-4">
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-500 text-white rounded-md
          hover:bg-slate-400
        "
          onClick={_onDeleteTieuChi}
        >
          <DeleteIcon fontSize="small" />
          <p>Xoá</p>
        </button>

        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
          >
            <SaveIcon fontSize="small" />
            <p onClick={handleUpdateTieuChi}>Lưu chỉnh sửa</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailTargetRatingCapacity;
