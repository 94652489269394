import React, { useEffect, useState } from "react";

import { listNanglucnhasuAction } from "../../../action/reportNangLucNhanSuAction";

import { phongbanDropdown } from "../../../action/phongbanAction"

import PrintIcon from '@mui/icons-material/Print';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";

import { onTouchForm } from "../../../funcs/onTochForm";

const ReportWorkerCapacity = () => {

	const [list, setList] = useState<any[] | null>(null);
	const [listPhongBan, setListPhongBan] = useState<any[] | null>(null);
	const [phongban, setPhongban] = useState<any[] | null>(null);
	const [fromDate, setFromDate] = useState<any>(moment(new Date()).format('yyyy-MM-DD'));
	const [toDate, setToDate] = useState<any>(moment(new Date()).format('yyyy-MM-DD'));

	useEffect(() => {
		getPhongBan();
	}, []);

	const getList = (body: any) => {
		listNanglucnhasuAction(JSON.stringify(body)).then((res: any) => {
			const data: any[] = (res || {}).data
			setList(data)
		})
	}
	const getPhongBan = () => {
		console.log('getPhongBan');

		var key = 'LI_PHONGBAN';
		phongbanDropdown(key).then((res: any) => {
			const data: any[] = (res || {}).data
			setListPhongBan(data)

		})
	}

	const _onChangePhongban = (e: any) => {
		if (e?.target?.value) {
			setPhongban(e?.target?.value)
		}
	}

	const _onChangeFrom = (e: any) => {
		setFromDate(e?.target?.value)

	}

	const _onChangeTo = (e: any) => {
		setToDate(e?.target?.value)
	}

	const _submitSearch = () => {
		const body: any = {
			from: fromDate,
			to: toDate,
			phongban: phongban,
		}
		getList(body);
	}

	return (
		<>
			<div className="flex flex-col w-full h-[calc(100%-3rem)] pl-5 pr-5 text-sm">

				<div className="flex justify-end space-x-4 w-full h-[2rem]">
					<button className="
            flex items-center p-2 bg-amber-500 rounded-md text-white
              hover:bg-amber-400 space-x-1
            ">
						<PrintIcon fontSize="small" />
						<p>In ấn</p>
					</button>
					<button className="
            flex items-center p-2 bg-emerald-500 rounded-md text-white
              hover:bg-emerald-400 space-x-1
            ">
						<InsertDriveFileIcon fontSize="small" />
						<p>Xuất Excel</p>
					</button>
					<button className="
            flex items-center p-2 bg-orange-500 rounded-md text-white
              hover:bg-orange-400 space-x-1
            ">
						<PictureAsPdfIcon fontSize="small" />
						<p>Xuất PDF</p>
					</button>
					<button className="
            flex items-center p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 space-x-1
            " onClick={() => {
							_submitSearch()
						}}>
						<SearchIcon fontSize="small" />
						<p>Tìm kiếm</p>
					</button>
				</div>

				<div className="flex flex-col w-full lg:h-[5rem] h-max space-y-3">

					<div className="flex lg:flex-row flex-col w-full h-max">
						<div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
							<label>Từ ngày</label>
							<input
								className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
								placeholder="Nhập tên công việc"
								type="date"
								value={fromDate}
								onChange={(e: any) => _onChangeFrom(e)}
								onTouchStart={(e: any) => onTouchForm(e)}
							/>
						</div>
						<div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
							<label>Đến ngày</label>
							<input
								className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
								placeholder="Nhập tên công việc"
								type="date"
								value={toDate}
								onChange={(e: any) => _onChangeTo(e)}
								onTouchStart={(e: any) => onTouchForm(e)}
							/>
						</div>
						<div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
							<label>Chọn phòng ban</label>
							<select
								className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
								defaultValue={0}
								onChange={(e: any) => {
									_onChangePhongban(e);
								}}
							>
								<option className="text-gray-300" value={0} disabled>--- Chọn phòng ban ---</option>
								{
									listPhongBan === null ? <></>
										: listPhongBan.map((item: any, index: any) => (
											<option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
										))
								}
							</select>
						</div>

					</div>

				</div>

				<div className="flex flex-col w-full h-[calc(100%-7rem)] lg:mt-0 mt-3"> {/* warm */}


					<div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
						<table>
							<thead className="text-white font-semibold text-center w-full h-[2rem]">
								<tr>
									<th className="border-l-[1px] border-slate-300">STT</th>
									<th className="border-l-[1px] border-slate-300">Danh mục</th>
									<th className="border-l-[1px] border-slate-300">Nhân viên</th>
									<th className="border-l-[1px] border-slate-300">SL công việc</th>
									<th className="border-l-[1px] border-slate-300">Chưa hoàn tất</th>
									<th className="border-l-[1px] border-slate-300">Hoàn tất</th>
									<th className="border-l-[1px] border-slate-300">Đúng hạn</th>
									<th className="border-l-[1px] border-slate-300">Trễ hạn</th>
									{/* <th className="border-l-[1px] border-r-[1px] border-slate-300">Xếp loại</th> */}
								</tr>
							</thead>
							<tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
								{list === null ? <tr></tr> :
									list.map((item: any, index: any) => (
										<tr className="w-max h-[3rem] bg-white">
											<td className="text-center border border-slate-300">{index + 1}</td>
											<td className="border border-slate-300">{item.PhongBan}</td>
											<td className="text-left border border-slate-300">{item.FullName}</td>
											<td className="text-center border border-slate-300">{parseInt(item.Recipient) + parseInt(item.Supporter)}</td>
											<td className="text-center border border-slate-300">{item.ChuaHoanTat}</td>
											<td className="text-center border border-slate-300">{item.HoanTat}</td>
											<td className="text-center border border-slate-300">{item.DungHan}</td>
											<td className="text-center border border-slate-300">{item.TreHan}</td>
											{/* <td className="text-center border border-slate-300">
											Xuất sắc
										</td> */}
										</tr>
									))}
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</>
	)
}

export default ReportWorkerCapacity