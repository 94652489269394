import React from "react";

import HomeUI from "../../../../ui/homeUI/_index";


const FinishEarly = () => {
  return (
    <HomeUI />
  )
}

export default FinishEarly

