import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

import { ModalController } from "../../../ui/homeUI/_index";
import {
  PRIOR_DELETE,
  PRIOR_LIST,
  SECURITY_DELETE,
  SECURITY_READ_ALL,
} from "../../../apis/_index";
import {
  ONLOAD_PRIORLIST,
  ONLOAD_SECURITY_LIST,
} from "../../../redux/reducers/settingReducer/_index";
import { DIFICULT_DELETE, DIFFICULT_LEVEL } from "../../../apis/_index";
import { ONLOAD_DIFFICULT_LIST } from "../../../redux/reducers/settingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const DeleteDificult = (props: any) => {
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // funcs
  const _onClickDelete = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const API = DIFICULT_DELETE + taskId.toString();

    try {
      await ServiceUtils.getV2(API, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Xoá thành công.");
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(DIFFICULT_LEVEL, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_DIFFICULT_LIST(res.data.Data));
                }
                // else {
                //   toast.error("Tải dữ liệu thất bại.");
                // }
              });
            } catch (error: any) {
              toast.error(
                "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              );
            }
          };

          takeData();
          handleClose();
          context.handleClose();
        } else {
          toast.error("Xoá thất bại.");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>
          Bạn có đồng ý <span className="font-semibold">XOÁ</span> độ khó này
          không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className="w-[5rem] h-max p-2 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickDelete}
          >
            Có
          </button>
          <button
            className="w-[5rem] h-max p-2 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDificult;
