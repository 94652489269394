import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import SendIcon from "@mui/icons-material/Send";
import Popover from "@mui/material/Popover";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import { USER_READ_ALL_DROPDOWN } from "../../../apis/_index";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { CHECK_RESULT } from "../../../apis/_index";
import {
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  SENT_MESSAGE,
  NGUOI_NHAN_FILE_STEP_UPLOAD,
  LICH_SU_GIA_HAN,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { NGUOI_GIAO_VIEC_DETAIL } from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { truncateParagraph } from "../../../funcs/truncateParagraph";
import Button from "@mui/material/Button";
import { ModalController } from "../../../ui/homeUI/_index";
import { _onClickFuncs } from "../../../funcs/onClickShowBtn";
import { IndeterminateCheckBoxSharp } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import RecoveryTaskComponent from "../../recoveryTasks/recoveryTaskComponent";
import { MODAL_KEYS } from "../../../constants/constants";
import ServiceUtils from "../../../utils/services";

const StepList = (props: any) => {
  const taskId = props.id;
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const handleOpenSub = () => {
    setOpenSub(true);
  };
  const handleCloseSub = () => {
    setOpenSub(false);
  };

  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);

  const appData = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  const [task, setTask] = useState<any>(null);
  const [taskContents, setTaskContents] = useState<any[]>([]);

  const [taskInfoText, setTaskInfoText] = useState<any[]>([]);
  const [taskDeduct, setTaskDeduct] = useState<any[]>([]);

  const [_taskContents, set_TaskContents] = useState<any[]>([]);
  const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);

  const [isViewHistoty, setIsViewHistoty] = useState<any>(null);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);

  const [taskHistory, setTaskHistory] = useState<any[]>([]);

  // funcs
  const _onChangeMultipleChoicePartner = (
    text: string,
    item: any = null,
    isViewHistoty: any = null
  ) => {
    handleOpenSub();
    if (
      text === "completedTaskContent" ||
      text === "multipleUploadFileContent"
    ) {
      setTaskContentHistoryId(item);
    }
    if (isViewHistoty === 1) {
      setIsViewHistoty(1);
    } else {
      setIsViewHistoty(0);
    }
    setFuncsSub(text);
  };

  const getDetail = (isStart: any) => {
    const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

    if (isStart === 1) {
      if (uuTienList === null || doMatList === null) {
      } else {
        if (shouldLog.current) {
          shouldLog.current = false;

          const takeData = async () => {
            try {
              await ServiceUtils.getV2(API, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  setTask(res.data.Data.task);
                  setTaskContents(res.data.Data.taskContents);
                  setTaskContentsHistory(res.data.Data.taskContentHistory);
                  //setTaskInfoText(res.data.Data.taskInfoText)
                  setTaskHistory(res.data.Data.taskHistory);
                  setTaskDeduct(res.data.Data.taskDeduct);

                  let fileListResponse: any[] = [];
                  let fileListNameResponse: any[];
                  if (
                    res.data.Data.task.ListFiles &&
                    res.data.Data.task.ListFilesName
                  ) {
                    fileListResponse = res.data.Data.task.ListFiles.split(";");
                    fileListNameResponse =
                      res.data.Data.task.ListFilesName.split(";");

                    let tempFileArray: any[] = [];
                    if (
                      fileListResponse.length > 0 &&
                      fileListNameResponse.length > 0
                    ) {
                      fileListNameResponse.forEach((item: any, index: any) => {
                        let tempObject: any;
                        if (item) {
                          tempObject = {
                            listFile: fileListResponse[index],
                            listFileName: item,
                          };
                        }
                        tempFileArray.push(tempObject);
                      });
                    }
                  }

                  let fileListResponse2: any[] = [];
                  let fileListNameResponse2: any[] = [];
                  if (
                    res.data.Data.task.ResultFiles &&
                    res.data.Data.task.ResultFilesName
                  ) {
                    fileListResponse2 =
                      res.data.Data.task.ResultFiles.split(";");
                    fileListNameResponse2 =
                      res.data.Data.task.ResultFilesName.split(";");
                    let tempFileArray2: any[] = [];
                    if (
                      fileListNameResponse2.length > 0 &&
                      fileListResponse2.length > 0
                    ) {
                      fileListNameResponse2.map((item: any, index: any) => {
                        let tempObject: any;
                        if (
                          item !== "" &&
                          item !== null &&
                          typeof item !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponse2[index],
                            listFileName: item,
                          };
                        }
                        if (tempObject) {
                          tempFileArray2.push(tempObject);
                        }
                      });
                    }
                  }

                  let _taskInfoText: any[] = res.data.Data.taskInfoText;
                  // debugger

                  let fileListResponseChat: any[] = [];
                  let fileListResponseNameChat: any[] = [];
                  if (_taskInfoText.length > 0) {
                    let tempFileArrFileChat: any[] = [];
                    _taskInfoText.map((item: any) => {
                      if (item?.ListFile && item?.ListFileName) {
                        fileListResponseChat = item?.ListFile.split(";");
                        fileListResponseNameChat =
                          item?.ListFileName.split(";");

                        let arr: any[] = [];
                        if (
                          fileListResponseNameChat.length > 0 &&
                          fileListResponseChat.length > 0
                        ) {
                          fileListResponseNameChat.map(
                            (itemC: any, index: any) => {
                              let tempObject: any;

                              if (
                                itemC !== "" &&
                                itemC !== null &&
                                typeof itemC !== "undefined"
                              ) {
                                tempObject = {
                                  listFile: fileListResponseChat[index],
                                  listFileName: itemC,
                                };
                              }
                              if (tempObject) {
                                arr.push(tempObject);
                              }
                            }
                          );

                          if (arr) {
                            tempFileArrFileChat.push({
                              ...item,
                              listFileInChat: arr,
                            });
                          } else {
                            tempFileArrFileChat.push({
                              ...item,
                              listFileInChat: [],
                            });
                          }
                        }
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    });
                    setTaskInfoText(tempFileArrFileChat);
                  }
                }
              });
            } catch (error: any) {
              // ERROR: here
            }
          };

          // const API = LICH_SU_GIA_HAN(taskId);
          // const fetchData = async () => {
          //   try {
          //     await axios
          //       .get(API, {
          //         headers: {
          //           Authorization: "Bearer " + accessToken,
          //           Accept: "text/plain",
          //         },
          //       })
          //       .then((res: any) => {
          //         if (((res || {}).data || {}).StatusCode === 200) {
          //           setListConfirmHistory(((res || {}).data || {}).Data || []);
          //         } else {
          //           toast.error(res.data.Message || "Lỗi không xác định");
          //         }
          //       });
          //   } catch (error: any) {
          //     toast.error(error || "Tải dữ liệu thất bại");
          //   }
          // };

          // fetchData();

          takeData();
        }
      }
    } else {
      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setTask(res.data.Data.task);
              setTaskContents(res.data.Data.taskContents);
              setTaskContentsHistory(res.data.Data.taskContentHistory);
              setTaskHistory(res.data.Data.taskHistory);

              let fileListResponse: any[] = [];
              let fileListNameResponse: any[];
              if (
                res.data.Data.task.ListFiles &&
                res.data.Data.task.ListFilesName
              ) {
                fileListResponse = res.data.Data.task.ListFiles.split(";");
                fileListNameResponse =
                  res.data.Data.task.ListFilesName.split(";");

                let tempFileArray: any[] = [];
                if (
                  fileListResponse.length > 0 &&
                  fileListNameResponse.length > 0
                ) {
                  fileListNameResponse.forEach((item: any, index: any) => {
                    let tempObject: any;
                    if (item) {
                      tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };
                    }
                    tempFileArray.push(tempObject);
                  });
                }
              }

              let fileListResponse2: any[] = [];
              let fileListNameResponse2: any[] = [];
              if (
                res.data.Data.task.ResultFiles &&
                res.data.Data.task.ResultFilesName
              ) {
                fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
                fileListNameResponse2 =
                  res.data.Data.task.ResultFilesName.split(";");
                let tempFileArray2: any[] = [];
                if (
                  fileListNameResponse2.length > 0 &&
                  fileListResponse2.length > 0
                ) {
                  fileListNameResponse2.map((item: any, index: any) => {
                    let tempObject: any;
                    if (
                      item !== "" &&
                      item !== null &&
                      typeof item !== "undefined"
                    ) {
                      tempObject = {
                        listFile: fileListResponse2[index],
                        listFileName: item,
                      };
                    }
                    if (tempObject) {
                      tempFileArray2.push(tempObject);
                    }
                  });
                }
              }

              let _taskInfoText: any[] = res.data.Data.taskInfoText;
              // debugger

              let fileListResponseChat: any[] = [];
              let fileListResponseNameChat: any[] = [];
              if (_taskInfoText.length > 0) {
                let tempFileArrFileChat: any[] = [];
                _taskInfoText.map((item: any) => {
                  if (item?.ListFile && item?.ListFileName) {
                    fileListResponseChat = item?.ListFile.split(";");
                    fileListResponseNameChat = item?.ListFileName.split(";");

                    let arr: any[] = [];
                    if (
                      fileListResponseNameChat.length > 0 &&
                      fileListResponseChat.length > 0
                    ) {
                      fileListResponseNameChat.map((itemC: any, index: any) => {
                        let tempObject: any;

                        if (
                          itemC !== "" &&
                          itemC !== null &&
                          typeof itemC !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponseChat[index],
                            listFileName: itemC,
                          };
                        }
                        if (tempObject) {
                          arr.push(tempObject);
                        }
                      });

                      if (arr) {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: arr,
                        });
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    }
                  } else {
                    tempFileArrFileChat.push({ ...item, listFileInChat: [] });
                  }
                });
                setTaskInfoText(tempFileArrFileChat);
              }
            }
          });
        } catch (error: any) {
          // ERROR: here
        }
      };

      takeData();
    }
  };

  useEffect(() => {
    getDetail(1);
  });

  useEffect(() => {
    if (uuTienList === null || doMatList === null) {
      if (shouldLog2.current) {
        shouldLog2.current = false;

        const takeUuTien = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setUuTienList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        const takeDoMat = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setDoMatList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        takeUuTien();
        takeDoMat();
      }
    }
  });

  useEffect(() => {
    if (appData.userList === null && shouldLog3.current) {
      shouldLog3.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };
      takeData();
    }
  });

  useEffect(() => {
    _handleTaskContentHistory();
  }, [taskContents, taskContentsHistory]);

  const _handleTaskContentHistory = () => {
    if (taskContents.length > 0 && taskContentsHistory.length > 0) {
      let _taskContent: any[] = [];
      let foundObject: any[] = [];
      let _foundObject: any;

      _taskContent = taskContents.map((item1: any) => {
        foundObject = taskContentsHistory.filter(
          (item2: any) => item1.ID === item2.Task_ContentID
        );
        if (foundObject) {
          _foundObject = foundObject.find(
            (x: any, index: any) =>
              x.Checked === null || index == foundObject.length - 1
          );

          return { ...item1, taskContentsHistory: _foundObject };
        } else {
          return { ...item1 };
        }
      });
      set_TaskContents(_taskContent);
    } else {
      set_TaskContents(taskContents);
    }
  };

  const _handleChecked = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "Người thực hiện chính không duyệt";
    } else if (statusCheck === true && ownerChecked === null) {
      return "Người giao việc chưa duyệt";
    } else if (statusCheck === null && ownerChecked === null) {
      return "Người thực hiện chính chưa duyệt";
    } else if (statusCheck === true && ownerChecked === false) {
      return "Người giao việc không duyệt";
    } else if (statusCheck === true && ownerChecked === true) {
      return "Người giao việc đã duyệt";
    }
  };

  const _handleColor = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "#a855f7";
    } else if (statusCheck === true && ownerChecked === null) {
      return "#FFAA00";
    } else if (statusCheck === null && ownerChecked === null) {
      return "#78716c";
    } else if (statusCheck === true && ownerChecked === false) {
      return "#ff0000";
    } else if (statusCheck === true && ownerChecked === true) {
      return "#00ff00";
    }
  };

  return (
    <>
      <SubModal
        open={openSub}
        timeExtend={task === null ? "" : task.DeadLine}
        hoursExtend={task === null ? "" : task?.HoursUsed}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskHistory={taskHistory}
        isViewHistoty={isViewHistoty}
        taskContentsHistory={taskContentsHistory}
        taskContentHistoryId={taskContentHistoryId}
        taskId={taskId}
        _taskContents={_taskContents}
        taskInfoText={taskInfoText}
        task={task === null ? " " : task}
        taskDeduct={taskDeduct}
        setOpen={props.setOpen}
      />
      <div className=" pt-0.5 lg:p-2 w-full lg:w-[500px] absolute left-0 shadow-lg bg-[#f5f5f7] z-[2] border border-slate-300 max-h-[300px] overflow-y-scroll styled-scrollbars">
        <p className="flex justify-start text-xs max-lg:pl-2">
          Bước thực hiện:
        </p>

        <div className="overflow-x-auto mt-0.5">
          <table className="table-auto border-collapse w-full">
            <thead>
              <tr className="rounded-lg text-xs font-medium text-white max-lg:hidden">
                <th className="px-2 py-2 bg-primary">Bước</th>
                <th className="px-2 py-2 bg-primary">Trạng thái</th>
                <th className="px-2 py-2 bg-primary">Nội dung</th>
                <th className="px-2 py-2 bg-primary">Thời hạn</th>
              </tr>
              <tr className="rounded-lg text-xs font-medium text-white lg:hidden">
                <th className="px-2 py-2 bg-primary">Bước</th>
              </tr>
            </thead>
            <tbody className="text-xs font-normal text-gray-700">
              {_taskContents.map((item: any, index: any) => (
                <>
                  <tr
                    key={index}
                    className="w-max h-[3rem] bg-white max-lg:hidden"
                  >
                    <td className="text-center border border-slate-300">
                      {item?.SortOrder}
                    </td>
                    <td className="border border-slate-300">
                      <>
                        {item?.taskContentsHistory &&
                        item?.taskContentsHistory.IsCompleted === true ? (
                          <>
                            <div
                              style={{
                                backgroundColor: _handleColor(
                                  item?.taskContentsHistory.Checked,
                                  item?.taskContentsHistory.OwnerChecked
                                ),
                                color: "white",
                                padding: 5,
                                textAlign: "center",
                                borderRadius: 13,
                                width: "fit-content",
                              }}
                            >
                              {_handleChecked(
                                item?.taskContentsHistory.Checked,
                                item?.taskContentsHistory.OwnerChecked
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#00B1FF",
                              color: "white",
                              padding: 5,
                              textAlign: "center",
                              borderRadius: 13,
                              width: "fit-content",
                            }}
                          >
                            Chưa có kết quả
                          </div>
                        )}
                      </>
                    </td>
                    <td className="border border-slate-300">
                      <p>{item?.StepName}</p>
                      <p>
                        <i>
                          Người thực hiện: <b>{item?.UserDoName}</b>
                        </i>
                      </p>
                    </td>
                    <td className="text-center border border-slate-300">
                      <p>{moment(item?.DeadLine).format("DD/MM/YYYY")}</p>
                      <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>

                      {/* <div className="dropdown my-2">
                          
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          
                          <div className="dropdown-content text-xs font-semibold">
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => {
                                _onChangeMultipleChoicePartner(
                                  "multipleUploadFileContent",
                                  item?.ID,
                                  1
                                );
                              }}
                            >
                              
                              <div className="flex w-full h-full space-x-1">
                                
                                <HistoryIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                
                                <div>Xem lịch sử</div>
                              </div>
                            </button>
                          </div>
                        </div> */}
                    </td>
                  </tr>
                  <tr
                    key={index}
                    className="w-max h-[3rem] bg-white lg:hidden border-b border-slate-300 "
                  >
                    <div className="flex flex-col gap-2 p-3">
                      <div className="flex gap-3">
                        {item?.SortOrder}. <b>{item?.StepName}</b>
                      </div>

                      <div className="flex gap-3">
                        Người thực hiện: <b>{item?.UserDoName}</b>
                      </div>
                      <div className="flex gap-3">
                        Thời hạn:
                        <p>
                          {moment(item?.DeadLine).format("DD/MM/YYYY")}{" "}
                          {moment(item?.DeadLine).format("HH:mm:ss")}
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <p className="my-auto">Trạng thái:</p>

                        {item?.taskContentsHistory &&
                        item?.taskContentsHistory.IsCompleted === true ? (
                          <>
                            <div
                              style={{
                                backgroundColor: _handleColor(
                                  item?.taskContentsHistory.Checked,
                                  item?.taskContentsHistory.OwnerChecked
                                ),
                                color: "white",
                                padding: 5,
                                textAlign: "center",
                                borderRadius: 13,
                                width: "fit-content",
                              }}
                            >
                              {_handleChecked(
                                item?.taskContentsHistory.Checked,
                                item?.taskContentsHistory.OwnerChecked
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#00B1FF",
                              color: "white",
                              padding: 5,
                              textAlign: "center",
                              borderRadius: 13,
                              width: "fit-content",
                            }}
                          >
                            Chưa có kết quả
                          </div>
                        )}
                      </div>
                    </div>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default StepList;
