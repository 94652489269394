import axios from "axios";
import { CREATE_TASK_LIST_NGUOI_GIAO, CREATE_TASK_RECOVERY_API, RECOVERY_TASK_API } from "../../apis/_index";
import { STORAGE_KEYS } from "../../constants/constants";
import ServiceUtils from "../../utils/services";

class TaskRepository {
    static async recoveryTask(body: any) {
        return new Promise(async resolve => {
            // const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);

            await ServiceUtils.postV2(RECOVERY_TASK_API, body, {
                    headers: {
                        Accept: "text/plain",
                        'Content-Type': "application/json",
                    },
                })
                .then((res: any) => {
                    if (((res || {}).data || {}).StatusCode === 200) {
                        resolve({
                            status: true,
                            message: ((res.data || {}).Data || {}).RMessage
                        });
                    } else {
                        resolve({
                            status: false,
                            message: ((res.data || {}).Data || {}).RMessage
                        });
                    }
                });
        });
    }

    static async transferTask(body: any) {
        debugger
        return new Promise(async resolve => {
            // const accessToken = localStorage.getItem(STORAGE_KEYS.accessToken);

            await ServiceUtils.postFormDataV2(CREATE_TASK_LIST_NGUOI_GIAO, body, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res: any) => {
                    if (res.data.StatusCode === 200) {
                        resolve({
                            status: true,
                            message: ((res.data || {}).Data || {}).RMessage
                        });
                    } else {
                        resolve({
                            status: false,
                            message: ((res.data || {}).Data || {}).RMessage
                        });
                    }
                });
        });
    }
}

export default TaskRepository;