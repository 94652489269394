import React from "react";

import HomeUI from "../../../../ui/homeUI/_index";

const ElectronicReport = () => {
  return (
    <>
      <HomeUI />
    </>
  );
};

export default ElectronicReport;
