import React from "react";

import HomeUI from "../../ui/homeUI/_index";

const WorkCategoryListPage = () => {
  return (
    <HomeUI />
  )
}

export default WorkCategoryListPage;
