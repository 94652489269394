import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const reportReducer = createSlice({
  name: "reportReducer",

  initialState: {
    pageList: 10,
    pageNumber: 0,
    pageTotal: 0,
    itemTotal: 0,

    parameterListKey: [],
    parameterList: null,
    parameterChoose: "1",

    taskList: null,
    showingTaskList: null,

    priorList: [],
    securityList: null,

    userList: null
  },

  reducers: {
    ONCHANGE_REPORT_NANG_LUC_NHAN_SU_NGUOI_GIAO: (state: any, actions: PayloadAction<any>) => {
      state.deliverWorkerCapacity = actions.payload
    },

    
    
  }
});

export const {
  ONCHANGE_REPORT_NANG_LUC_NHAN_SU_NGUOI_GIAO
} = reportReducer.actions

export default reportReducer.reducer;
