import React, { useContext } from "react";

import Title from "../../../../components/title";

import { Chart as ChartJS, CategoryScale } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, Doughnut, Bar, Radar, Bubble } from 'react-chartjs-2';

import { ModalController } from "../../../homeUI/_index";

ChartJS.register(CategoryScale);
ChartJS.register(ChartDataLabels);

const MissionProcessingUI = () => {

  const context = useContext(ModalController);

  // funcs
  const _onClickChart3Detail = () => {
    context.setFuncs("chart3Detail");
    context.handleOpen();
  }

  return (
    <div className="flex flex-col w-full h-full">
      <Title />

      <div className="styled-scrollbars flex flex-col w-full h-[calc(100%-3rem)] space-y-12 overflow-auto shadow-md pl-5 text-white">
        {/* chart 1 */}
        <div className="w-full h-max bg-sky-900">
          {/* fields */}
          <div className="flex w-full h-[calc(100vh-8rem)]">
            {/* element */}
            <div className="flex flex-col w-1/4 h-full">
              {/* element */}
              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold pl-2">
                    HS SỰ VỤ
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Doughnut
                      className="w-full h-full"
                      datasetIdKey='id'
                      plugins={[{
                        id: 'textCenter',
                        beforeDatasetDraw: (chart: any, args: any, pluginOptions: any) => {
                          const { ctx } = chart;
                          ctx.save();

                          ctx.font = '20px bold sans-serif';
                          ctx.fillStyle = '#e11d48';
                          ctx.textAlign = 'center';
                          ctx.textBaseline = 'middle';

                          ctx.fillText('TỔNG', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 12);

                          ctx.font = '20px bold sans-serif';
                          ctx.fillStyle = 'white';
                          ctx.textAlign = 'center';
                          ctx.textBaseline = 'end';

                          ctx.fillText('2187', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 12);
                        }
                      }]}
                      data={{
                        labels: ['Tổ quy hoạch', 'Tổ xây dưng', 'Tổ hạ tầng', "Tổ tổng hợp", "Đội QLTTĐT"],
                        datasets: [{
                          label: 'Công việc',
                          data: [684, 297, 701, 275, 230],
                          backgroundColor: [
                            '#fecdd3',
                            '#fda4af',
                            '#fb7185',
                            '#f43f5e',
                            '#e11d48'
                          ]
                        }],
                      }}
                      options={{
                        plugins: {
                          datalabels: {
                            color: 'white',
                            font: {
                              size: 10,
                              weight: "bolder"
                            },

                            formatter: (value: any, context: any) => {
                              return context.chart.data.labels[context.dataIndex] + `\n${value}`
                            },

                            clamp: true,
                            align: 'center',
                            anchor: 'center',
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 11
                              }
                            }
                          }
                        },
                      }}
                    />
                  </div>
                </div>

                {/* <div className="flex flex-col w-1/6 bg-sky-700 text-sm">
                  <div className="w-full h-1/2 flex flex-col">
                    <p className="text-rose-500 font-bold">TỔNG</p>
                    <p className="text-lg">2187</p>
                  </div>
                  <div className="w-full h-1/2 flex flex-col">
                    <p className="text-rose-500 font-bold">KPI</p>
                    <p className="text-lg">5.473</p>
                  </div>
                </div> */}
              </div>

              <div className="flex w-full h-1/3 bg-sky-900">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold pl-2">
                    HS HÀNH CHÍNH
                  </div>
                  <div className="justify-center items-center flex w-full h-[calc(100%-1rem)]">
                    <Doughnut
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Tổ quy hoạch', 'Tổ xây dưng'],
                        datasets: [{
                          label: 'Công việc',
                          data: [1904, 501],
                          backgroundColor: [
                            '#a5f3fc',
                            '#0891b2',
                          ]
                        }],
                      }}
                      plugins={[{
                        id: 'textCenter',
                        beforeDatasetDraw: (chart: any, args: any, pluginOptions: any) => {
                          const { ctx } = chart;
                          ctx.save();

                          ctx.font = '20px bold sans-serif';
                          ctx.fillStyle = '#0284c7';
                          ctx.textAlign = 'center';
                          ctx.textBaseline = 'middle';

                          ctx.fillText('TỔNG', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 12);

                          ctx.font = '20px bold sans-serif';
                          ctx.fillStyle = 'white';
                          ctx.textAlign = 'center';
                          ctx.textBaseline = 'end';

                          ctx.fillText('2187', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 12);
                        }
                      }]}
                      options={{
                        plugins: {
                          datalabels: {
                            color: 'white',
                            font: {
                              size: 10,
                              weight: "bolder"
                            },

                            formatter: (value: any, context: any) => {
                              return context.chart.data.labels[context.dataIndex] + `\n${value}`
                            },

                            clamp: true,
                            align: 'center',
                            anchor: 'center',
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 11
                              }
                            }
                          }
                        },
                      }}
                    />
                  </div>
                </div>

                {/* <div className="flex flex-col w-1/6 bg-sky-700 text-sm">
                  <div className="w-full h-1/2 flex flex-col">
                    <p className="text-sky-500 font-semibold">TỔNG</p>
                    <p className="text-lg">2045</p>
                  </div>
                  <div className="w-full h-1/2 flex flex-col">
                    <p className="text-sky-500 font-semibold">KPI</p>
                    <p className="text-lg">5.839</p>
                  </div>
                </div> */}
              </div>

              <div className="flex w-full h-1/3 bg-sky-900">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full pl-2 h-[1rem] text-xs text-white font-bold">
                    HS ISO
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Doughnut
                      className="w-full h-full"
                      datasetIdKey='id'
                      plugins={[{
                        id: 'textCenter',
                        beforeDatasetDraw: (chart: any, args: any, pluginOptions: any) => {
                          const { ctx } = chart;
                          ctx.save();

                          ctx.font = '20px bold sans-serif';
                          ctx.fillStyle = '#65a30d';
                          ctx.textAlign = 'center';
                          ctx.textBaseline = 'middle';

                          ctx.fillText('TỔNG', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 12);

                          ctx.font = '20px bold sans-serif';
                          ctx.fillStyle = 'white';
                          ctx.textAlign = 'center';
                          ctx.textBaseline = 'end';

                          ctx.fillText('2187', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 12);
                        }
                      }]}
                      data={{
                        labels: ['Tổ quy hoạch', 'Tổ xây dưng', 'Tổ hạ tầng', "Tổ tổng hợp"],
                        datasets: [{
                          label: 'Công việc',
                          data: [993, 497, 232, 167],
                          backgroundColor: [
                            '#bbf7d0',
                            '#bef264',
                            '#a3e635',
                            '#65a30d',
                          ]
                        }],
                      }}
                      options={{
                        plugins: {
                          datalabels: {
                            color: 'white',
                            font: {
                              size: 10,
                              weight: "bolder"
                            },

                            formatter: (value: any, context: any) => {
                              return context.chart.data.labels[context.dataIndex] + `\n${value}`
                            },

                            clamp: true,
                            align: 'center',
                            anchor: 'center',
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                              font: {
                                size: 11
                              }
                            }
                          }
                        },
                      }}
                    />
                  </div>

                </div>

                {/* <div className="flex flex-col w-1/6 bg-sky-700 text-sm">
                  <div className="w-full h-1/2 flex flex-col">
                    <p className="text-lime-500 font-semibold">TỔNG</p>
                    <p className="text-lg">1887</p>
                  </div>
                  <div className="w-full h-1/2 flex flex-col">
                    <p className="text-lime-500 font-semibold">KPI</p>
                    <p className="text-lg">4.473</p>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="flex flex-col w-1/4 h-full">
              {/* element */}
              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    HOÀN THÀNH ĐÚNG HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Hoàn thành',
                            data: [171, 180, 417, 103, 262],
                            backgroundColor: [
                              'white',
                            ]
                          },
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {

                          datalabels: {
                            color: '#e11d48',
                            labels: {
                              title: {
                                color: 'red',
                                font: {
                                  weight: 'bold'
                                }
                              },
                              value: {
                                color: '#f43f5e'
                              }
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}`,
                            anchor: "end",
                            align: "start",
                          },

                          legend: {
                            display: false
                          },
                        },
                        scales: {
                          x: {
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            ticks: {

                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    HOÀN THÀNH ĐÚNG HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Hoàn thành',
                            data: ['', 380, '', 1386, ''],
                            backgroundColor: [
                              'white',
                            ]
                          },
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {

                          datalabels: {
                            color: '#e11d48',
                            labels: {
                              title: {
                                color: 'red',
                                font: {
                                  weight: 'bold'
                                }
                              },
                              value: {
                                color: '#0ea5e9'
                              }
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}`,
                            anchor: "end",
                            align: "start",
                          },

                          legend: {
                            display: false
                          },
                        },
                        scales: {
                          x: {
                            ticks: {

                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            ticks: {

                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>

              </div>

              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    HOÀN THÀNH ĐÚNG HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Hoàn thành',
                            data: ['', 140, 187, 400, 733],
                            backgroundColor: [
                              'white',
                            ]
                          },
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {

                          datalabels: {
                            color: '#e11d48',
                            labels: {
                              title: {
                                color: 'red',
                                font: {
                                  weight: 'bold'
                                }
                              },
                              value: {
                                color: '#84cc16'
                              }
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}`,
                            anchor: "end",
                            align: "start",
                          },

                          legend: {
                            display: false
                          },
                        },
                        scales: {
                          x: {
                            ticks: {

                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            ticks: {

                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className="flex flex-col w-1/4 h-full">
              {/* element */}
              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    TRỄ HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Đã hoàn thành',
                            data: [53, 37, 156, 89, 276],
                            backgroundColor: [
                              '#7f1d1d',
                            ]
                          },
                          {
                            label: 'Chưa hoàn thành',
                            data: [2, 46, 121, 101, 140],
                            backgroundColor: [
                              '#dc2626',
                            ]
                          },
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            color: '#e11d48',
                            labels: {
                              title: {

                                font: {
                                  weight: 'bold'
                                }
                              },
                              value: {
                                color: 'white'
                              }
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}`,
                            anchor: "start",
                            align: "top",
                          },
                          legend: {
                            labels: {
                              color: 'white'
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            stacked: true,
                            ticks: {
                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    TRỄ HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Đã hoàn thành',
                            data: [0, 113, 0, 518, 0],
                            backgroundColor: [
                              '#7f1d1d',
                            ]
                          },
                          {
                            label: 'Chưa hoàn thành',
                            data: [0, 0, 0, 0, 0],
                            backgroundColor: [
                              '#dc2626',
                            ]
                          },
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            color: '#e11d48',
                            labels: {
                              title: {

                                font: {
                                  weight: 'bold'
                                }
                              },
                              value: {
                                color: 'white'
                              }
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}`,
                            anchor: "start",
                            align: "top",
                          },
                          legend: {
                            labels: {
                              color: 'white'
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            stacked: true,
                            ticks: {
                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    TRỄ HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Đã hoàn thành',
                            data: [0, 27, 45, 97, 260],
                            backgroundColor: [
                              '#7f1d1d',
                            ]
                          },
                          {
                            label: 'Chưa hoàn thành',
                            data: [0, 0, 0, 0, 0],
                            backgroundColor: [
                              '#dc2626',
                            ]
                          },
                        ]
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            color: '#e11d48',
                            labels: {
                              title: {

                                font: {
                                  weight: 'bold'
                                }
                              },
                              value: {
                                color: 'white'
                              }
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}`,
                            anchor: "start",
                            align: "top",
                          },
                          legend: {
                            labels: {
                              color: 'white'
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            stacked: true,
                            ticks: {
                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-1/4 h-full">
              {/* element */}
              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    TỈ LỆ ĐÚNG / TRỄ HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Phòng QLTTĐT', 'Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Đúng hạn',
                            data: [53.32, 76.09, 69.82, 60.49, 36.03, 39.18],
                            backgroundColor: 'white',
                            datalabels: {
                              color: '#e11d48'
                            }
                          },
                          {
                            label: 'Trễ hạn',
                            data: [46.68, 23.91, 30.18, 39.51, 63.97, 60.82],
                            backgroundColor: '#dc2626',
                            datalabels: {
                              color: 'black'
                            }
                          },
                        ],
                      }}

                      options={{
                        indexAxis: 'y',
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            labels: {
                              title: {
                                font: {
                                  weight: 'bold'
                                },
                              },
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}%`,
                            anchor: "center",
                            align: "center",
                          },
                          legend: {
                            labels: {
                              color: 'white'
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            stacked: true,
                            ticks: {
                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    TỈ LỆ ĐÚNG / TRỄ HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Phòng QLTTĐT', 'Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Đúng hạn',
                            data: [73.76, 100.00, 77.45, 100.00, 72.79, 100.00],
                            backgroundColor: 'white',
                            datalabels: {
                              color: '#0284c7'
                            }
                          },
                          {
                            label: 'Trễ hạn',
                            data: [26.24, 0.00, 22.55, 0.00, 27.21, 0.00],
                            backgroundColor: '#dc2626',
                            datalabels: {
                              color: 'black'
                            }
                          },
                        ],
                      }}

                      options={{
                        indexAxis: 'y',
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            labels: {
                              title: {
                                font: {
                                  weight: 'bold'
                                },
                              },
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}%`,
                            anchor: "center",
                            align: "center",
                          },
                          legend: {
                            labels: {
                              color: 'white'
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            stacked: true,
                            ticks: {
                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-1/3">
                <div className="flex flex-col w-full h-full">
                  <div className="w-full h-[1rem] text-xs text-white font-bold flex justify-center items-center">
                    TỈ LỆ ĐÚNG / TRỄ HẠN
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['Phòng QLTTĐT', 'Đội QLTTĐT', 'Tổ Tổng Hợp', 'Tổ Hạ Tầng', "Tổ Xây dựng", "Tổ quy hoạch"],
                        datasets: [
                          {
                            label: 'Đúng hạn',
                            data: [77.29, 100.00, 83.83, 80.60, 80.48, 73.82],
                            datalabels: {
                              color: '#65a30d'
                            },
                            backgroundColor: 'white'
                          },
                          {
                            label: 'Trễ hạn',
                            data: [22.71, 0.00, 16.17, 19.40, 19.52, 26.18],
                            datalabels: {
                              color: 'black'
                            },
                            backgroundColor: '#dc2626',
                          },
                        ],
                      }}

                      options={{
                        indexAxis: 'y',
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            labels: {
                              title: {
                                font: {
                                  weight: 'bold'
                                },
                              },
                            },

                            font: {
                              size: 13,
                              weight: 'bold',
                            },

                            formatter: (value: any) => `${value}%`,
                            anchor: "center",
                            align: "center",
                          },
                          legend: {
                            labels: {
                              color: 'white'
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: true,
                            ticks: {
                              maxTicksLimit: 6,
                              color: 'white'
                            }
                          },
                          y: {
                            stacked: true,
                            ticks: {
                              color: 'white'
                            },
                            beginAtZero: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* chart 2 */}
        <div className="w-full h-max bg-sky-900">
          {/* fields */}
          <div className="flex w-full h-[calc(100vh-8rem)]">
            <div className="flex flex-col w-2/5 h-full bg-sky-800">
              {/* elements */}
              <div className="flex flex-col w-full h-1/2">
                <div className="w-full h-[2rem] pl-2 pr-2 items-center flex justify-between">
                  <p className="text-xl font-bold">TỔ QUY HOẠCH</p>

                  <div className="flex w-max h-max space-x-3">
                    <select
                      className="
                        w-full h-[1.7rem] rounded-md pl-2 pr-2 border-2 border-sky-600
                        bg-sky-800
                      "
                      defaultValue={0}
                    >
                      <option className="text-black" value={0} disabled>--- Chọn phòng ban ---</option>
                      <option>Tổ quy hoạch</option>
                      <option>Phòng ban 2</option>
                      <option>Phòng ban 3</option>
                    </select>
                  </div>
                </div>

                <div className="flex justify-center items-center w-full h-[calc(100%-3rem)]">
                  <Doughnut
                    className="w-full h-full"
                    datasetIdKey='id'
                    plugins={[{
                      id: 'textCenter',
                    }]}
                    data={{
                      labels: ['HS SỰ VỤ', 'HS HÀNH CHÍNH', 'HS ISO'],
                      datasets: [{
                        label: 'Công việc',
                        data: [40.4, 0.2, 59.4],
                        backgroundColor: [
                          '#f43f5e',
                          '#0ea5e9',
                          '#84cc16'
                        ],
                      }],
                    }}
                    options={{
                      cutout: '80%',
                      plugins: {
                        datalabels: {
                          color: 'black',
                          font: {
                            size: 11,
                            weight: "bolder"
                          },

                          formatter(value, context) {
                            return `${value}%`
                          },

                          clamp: true,
                          align: 'center',
                          anchor: 'center',
                        },
                        legend: {
                          labels: {
                            color: 'white',
                            usePointStyle: true,
                            font: {
                              size: 11,
                              weight: 'bold'
                            }
                          }
                        }
                      },
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col w-full h-1/2">
                <div className="w-full h-[3rem] items-center flex">
                  {/* elements */}
                  <div className="flex flex-col font-bold justify-center items-center w-1/6 h-full bg-sky-700">
                    <p className="text-[6px] text-slate-300 font-bold">TỔNG CÔNG VIỆC</p>
                    <p className="text-xl font-bold">2015</p>
                  </div>

                  <div className="flex flex-col justify-center items-center w-1/6 h-full bg-sky-700">
                    <p className="text-xs text-yellow-500 font-bold">KPI</p>
                    <p className="text-xl font-bold">2.535</p>
                  </div>

                  <div className="flex flex-col justify-center items-center w-1/6 h-full bg-sky-700">
                    <p className="text-xs text-slate-300 font-bold">ĐÚNG HẠN</p>
                    <p className="text-lg font-bold">50.97%</p>
                  </div>

                  <div className="flex flex-col justify-center items-center w-1/6 h-full bg-sky-700">
                    <p className="text-xs text-yellow-500 font-bold">TRỄ HẠN</p>
                    <p className="text-lg font-bold">49.03%</p>
                  </div>

                  <div className="flex flex-col justify-center items-center w-2/6 h-full bg-sky-700">
                  </div>
                </div>

                <div className="flex justify-center items-center w-full h-[calc(100%-3rem)]">
                  <Radar
                    className="w-full h-full"
                    datasetIdKey='id'
                    data={{
                      labels: ['Nguyễn Lê Phong', 'Đặng Mậu Tài', 'Trần Minh Phúc', "Nguyễn Kim Thoại", "Võ Thị Kim Ngân"],
                      datasets: [{
                        label: 'Công việc',
                        data: [150, 210, 623, 800, 321],
                        backgroundColor: 'rgba(255, 99, 132, 0.8)',
                        borderColor: 'rgb(255, 99, 132)',
                        pointBackgroundColor: 'rgb(255, 99, 132)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        datalabels: {
                          color: '#f43f5e',
                          font: {
                            size: 10,
                            weight: "bolder"
                          },

                          formatter: (value: any, context: any) => {
                            return `\n${value}`
                          },

                          clamp: true,
                          align: 'top',
                          anchor: 'center',
                          display: false
                        },
                        legend: {
                          display: false,
                          position: "bottom",
                          labels: {
                            usePointStyle: true,
                            font: {
                              size: 11
                            }
                          }
                        },

                      },
                      scales: {
                        r: {
                          pointLabels: {
                            color: 'white',
                            font: {
                              weight: 'bolder'
                            }
                          },
                          angleLines: {
                            color: 'white'
                          },
                          grid: {
                            color: "white",
                          },
                          ticks: {
                            backdropColor: '#075985',
                            color: 'black'
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-3/5 h-full">
              {/* elements */}
              <div className="flex items-center justify-center w-full h-1/3">
                <Chart
                  type="bar"
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: ['Nguyễn Lê Phong', 'Đặng Mậu Tài', 'Trần Minh Phúc', "Nguyễn Thị Kim Thoại", "Võ Thị Kim Ngân", "Trần Ngọc Hoài Trinh"],
                    datasets: [
                      {
                        type: 'bar',
                        label: 'TỔNG CÔNG VIỆC',
                        data: [453, 484, 339, 402, 155, 170],
                        backgroundColor: 'rgba(2, 132, 199, 0.6)',
                        datalabels: {
                          color: 'white',
                          align: 'end',
                          anchor: 'start',
                          font: {
                            size: 13,
                            weight: 'bold'
                          }
                        }
                      },
                      {
                        type: 'line',
                        label: 'TỔNG KPI',
                        data: [319.75, 773.50, 718.65, 694.88, 180.48, -73.60],
                        backgroundColor: '#be123c',
                        borderColor: 'rgba(190,18,60)',
                        tension: 0.5,
                        datalabels: {
                          color: '#e11d48',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 11,
                            weight: 'bold'
                          }
                        }
                      },
                      {
                        type: 'line',
                        label: 'KPI SỰ VỤ',
                        data: [150.65, 400, 100, 300, 140, 120],
                        backgroundColor: '#e11d48',
                        borderColor: 'rgb(225,29,72, 0.8)',
                        segment: {
                          borderDash: [3, 3]
                        },
                        tension: 0.5,
                        datalabels: {
                          display: false,
                          color: '#e11d48',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 11,
                            weight: 'bold'
                          },
                        }
                      },
                      {
                        type: 'line',
                        label: 'KPI HSHC',
                        data: [0, 0, 10, 20, 0, 0],
                        backgroundColor: 'rgb(56,189,248)',
                        borderColor: 'rgba(56,189,248, 08)',
                        segment: {
                          borderDash: [3, 3]
                        },
                        tension: 0.5,
                        datalabels: {
                          display: false,
                          color: '#e11d48',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 11,
                            weight: 'bold'
                          },
                        }
                      },
                      {
                        type: 'line',
                        label: 'KPI ISO',
                        data: [200.65, 300, 300, 450, 0, 0],
                        backgroundColor: 'rgb(132, 204, 22)',
                        borderColor: 'rgba(132, 204, 22, 0.8)',
                        segment: {
                          borderDash: [3, 3]
                        },
                        tension: 0.5,
                        datalabels: {
                          display: false,
                          color: '#e11d48',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 11,
                            weight: 'bold'
                          },
                        }
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      // datalabels: {
                      //   color: 'white',
                      //   font: {
                      //     size: 10,
                      //     weight: "bolder"
                      //   },

                      //   formatter: (value: any, context: any) => {
                      //     return `\n${value}`
                      //   },

                      //   clamp: true,
                      //   align: 'top',
                      //   anchor: 'center',
                      // },
                      legend: {
                        display: true,
                        position: "top",
                        labels: {
                          color: 'white',
                          usePointStyle: true,
                          font: {
                            size: 11,
                            weight: 'bolder'
                          }
                        }
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: 'white',
                          font: {
                            weight: "bold"
                          }
                        }
                      },
                      y: {
                        ticks: {
                          color: 'white',
                          font: {
                            weight: "bold"
                          }
                        }
                      }
                    }
                  }}
                />
              </div>

              <div className="flex items-center justify-center w-full h-1/3">
                <Chart
                  type="bar"
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    datasets: [
                      {
                        type: 'bar',
                        label: 'SỐ SỰ VỤ',
                        data: [15, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgba(251,113,133,0.5)',
                        datalabels: {
                          color: '#fb7185',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 13,
                            weight: 'bold'
                          }
                        }
                      },
                      {
                        type: 'bar',
                        label: 'TỔNG SỐ NHIỆM VỤ',
                        data: [15, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgba(202,138,4,0.5)',
                        datalabels: {
                          color: '#ca8a04',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 13,
                            weight: 'bold'
                          }
                        }
                      },
                      {
                        type: 'line',
                        label: 'TỔNG KPI',
                        data: [5.20, 9.20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgba(190,18,60,0.5)',
                        borderColor: 'rgba(190,18,60,5)',
                        datalabels: {
                          color: '#be123c',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 13,
                            weight: 'bold'
                          }
                        }
                      },
                      {
                        type: 'bar',
                        label: 'SỐ HS ISO',
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgb(132,204,22,0.5)',
                        borderColor: 'rgb(132,204,22,0.5)',
                        // segment: {
                        //   borderDash: [3,3]
                        // },
                        datalabels: {
                          color: 'rgb(132,204,22)',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 13,
                            weight: 'bold'
                          }
                        }
                      },
                      {
                        type: 'bar',
                        label: 'SỐ HS ISO',
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgba(14,165,233,0.5)',
                        borderColor: 'rgb(14,165,233,0.5)',
                        datalabels: {
                          color: 'rgb(14,165,233,0.5)',
                          align: 'end',
                          anchor: 'end',
                          font: {
                            size: 13,
                            weight: 'bold'
                          }
                        }
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      // datalabels: {
                      //   color: 'white',
                      //   font: {
                      //     size: 10,
                      //     weight: "bolder"
                      //   },

                      //   formatter: (value: any, context: any) => {
                      //     return `\n${value}`
                      //   },

                      //   clamp: true,
                      //   align: 'top',
                      //   anchor: 'center',
                      // },
                      legend: {
                        display: true,
                        position: "top",
                        labels: {
                          color: 'white',
                          usePointStyle: true,
                          font: {
                            size: 11,
                            weight: 'bolder'
                          }
                        }
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: 'white',
                          font: {
                            weight: "bold"
                          }
                        }
                      },
                      y: {
                        ticks: {
                          color: 'white',
                          font: {
                            weight: "bold"
                          }
                        }
                      }
                    }
                  }}
                />
              </div>

              <div className="flex items-center justify-center w-full h-1/3">
                <Bar
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: ["Võ Thị Kim Ngân", "Nguyễn Thị Kim Thoại", "Trần Ngọc Hoài Trinh", "Trần Minh Phúc", "Đặng Mậu Tài", "Nguyễn Lê Phong"],
                    datasets: [
                      {
                        label: 'Đúng hạn',
                        data: [56.29, 68.80, 68.73, 74.63, 46.45, 27.65],
                        backgroundColor: 'white',
                        datalabels: {
                          color: '#075985'
                        }
                      },
                      {
                        label: 'Trễ hạn',
                        data: [43.71, 31.20, 31.27, 25.37, 53.55, 72.35],
                        backgroundColor: '#dc2626',
                        datalabels: {
                          color: 'white'
                        }
                      },
                    ],
                  }}

                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              weight: 'bold'
                            },
                          },
                        },

                        font: {
                          size: 13,
                          weight: 'bold',
                        },

                        formatter: (value: any) => `${value}%`,
                        anchor: "center",
                        align: "center",
                      },
                      legend: {
                        labels: {
                          color: 'white'
                        }
                      }
                    },
                    scales: {
                      x: {
                        stacked: true,
                        ticks: {
                          maxTicksLimit: 6,
                          color: 'white',
                          font: {
                            weight: 'bolder'
                          }
                        }
                      },
                      y: {
                        stacked: true,
                        ticks: {
                          color: 'white'
                        },
                        beginAtZero: true
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* chart 3 */}
        <div className="w-full h-max bg-sky-900">
          {/* fields */}
          <div className="flex w-full h-[calc(100vh-8rem)]">
            <Bubble
              className="w-full h-full"
              datasetIdKey='id'
              data={{
                datasets: [
                  {
                    label: '0.00',
                    data: [{
                      label: 'Trần Ngọc Minh Châu',
                      x: 0,
                      y: 0,
                      r: 15
                    }],
                    backgroundColor: [
                      'rgba(148,163,184, 0.7)',
                    ]
                  },
                  {
                    label: '2.30',
                    data: [{
                      label: 'Võ Thị Kim Ngân',
                      x: 1.5,
                      y: 1.75,
                      r: 15
                    }],
                    backgroundColor: [
                      'rgba(3,105,161, 0.7)',
                    ]
                  },
                  {
                    label: '5.00',
                    data: [{
                      label: 'Huỳnh Quốc Tuấn',
                      x: 3,
                      y: 4,
                      r: 15
                    }],
                    backgroundColor: [
                      'rgb(5,150,105, 0.7)',
                    ]
                  },
                  {
                    label: '2.80',
                    data: [{
                      label: 'Đoàn Mạnh Hà',
                      x: 1.6,
                      y: 2.3,
                      r: 15
                    }],
                    backgroundColor: [
                      'rgba(220,38,38, 0.7)',
                    ]
                  },
                  {
                    label: '-3.00',
                    data: [{
                      label: 'Đặng Mậu Tài',
                      x: -1.2,
                      y: 2.75,
                      r: 15
                    }],
                    backgroundColor: [
                      'rgba(234,88,12, 0.7)',
                    ]
                  },
                  {
                    label: '1.00',
                    data: [{
                      label: 'Nguyễn Thị Kim Thoại',
                      x: 1.2,
                      y: 1.2,
                      r: 15
                    }],
                    backgroundColor: [
                      'rgba(202,138,4, 0.7)',
                    ]
                  },
                  {
                    label: '2.80',
                    data: [{
                      label: 'Tăng Tấn Đức',
                      x: 1.85,
                      y: 2.1,
                      r: 15,
                      id: 1
                    }],
                    backgroundColor: [
                      'rgba(101,163,13, 0.7)',
                    ],
                  },
                ]
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    listeners: {
                      click: (context: any, event: any) => {
                        localStorage.setItem("nameOfChart3", context.dataset.data[0].label);
                        localStorage.setItem("valueOfChart3", context.dataset.label);
                        _onClickChart3Detail();
                      }
                    },
                    color: 'white',
                    labels: {
                      title: {
                        color: 'white',
                        font: {
                          weight: 'bold'
                        }
                      },
                      value: {
                        color: 'white'
                      }
                    },

                    font: {
                      size: 13,
                      weight: 'bold',
                    },

                    formatter: (value: any) => `${value.label}`,
                    anchor: "end",
                    align: "start",
                  },

                  legend: {
                    display: true,
                    position: "top",
                    labels: {
                      color: 'white',
                      usePointStyle: true,
                      font: {
                        size: 11,
                        weight: 'bolder'
                      }
                    }
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      maxTicksLimit: 6,
                      color: 'white'
                    },
                    grid: {
                      color: function (context) {
                        if (context.tick.value === 0) {
                          return '#ffffff';
                        } else {
                          return '#64748b';
                        }
                      },
                    }
                  },
                  y: {
                    ticks: {
                      color: 'white'
                    },
                    beginAtZero: true,
                    grid: {
                      color: function (context) {
                        if (context.tick.value === 0) {
                          return '#ffffff';
                        } else {
                          return '#64748b';
                        }
                      },
                    },
                  }
                }
              }}
            />
          </div>
        </div>

        {/* chart 4 */}
        <div className="w-full h-max bg-sky-900">
          {/* fields */}
          <div className="flex w-full h-[calc(100vh-8rem)]">
            {/* elements */}
            <div className="flex flex-col w-4/12 h-full">
              {/* elements */}
              <div className="flex w-full h-1/5">
                <div className="flex justify-center items-center flex-col w-1/2 h-full">
                  <p className="font-semibold">TỔNG</p>
                  <p className="font-bold text-4xl">5882</p>
                </div>

                <div className="flex justify-center items-center flex-col w-1/2 h-full">
                  <p className="text-rose-600 font-semibold">KPI</p>
                  <p className="font-bold text-4xl">15.852</p>
                </div>
              </div>

              <div className="flex justify-center items-center w-full h-3/5 pt-2 pb-2">
                <Doughnut
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: ['HS SỰ VỤ', 'HS HÀNH CHÍNH', 'HS ISO'],
                    datasets: [{
                      label: 'Công việc',
                      data: [2187, 2405, 1889],
                      backgroundColor: [
                        '#f43f5e',
                        '#0ea5e9',
                        '#84cc16'
                      ]
                    }],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: 'white',
                        font: {
                          size: 15,
                          weight: "bolder"
                        },

                        formatter: (value: any, context: any) => {
                          return context.chart.data.labels[context.dataIndex] + `\n${value}`
                        },

                        clamp: true,
                        align: 'center',
                        anchor: 'center',
                      },
                      legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                          font: {
                            size: 11
                          }
                        }
                      }
                    },
                  }}
                />
              </div>

              <div className="flex w-full h-1/5">
                <div className="flex justify-center items-center flex-col w-1/2 h-full">
                  <p className="font-semibold">ĐÚNG HẠN</p>
                  <p className="font-bold text-4xl">64.62%</p>
                </div>

                <div className="flex justify-center items-center flex-col w-1/2 h-full">
                  <p className="text-rose-600 font-semibold">TRỄ HẠN</p>
                  <p className="font-bold text-4xl">35.38%</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-3/12 h-full bg-sky-800">
              {/* elements */}
              <div className="flex justify-center items-center w-full h-1/2">
                <Radar
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: ["HS SỰ VỤ", "HS HC", "HS ISO"],
                    datasets: [{
                      label: 'KPI bởi HS',
                      data: [2100, 4500, 600],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      borderColor: 'rgb(255, 99, 132)',
                      tension: 0.2
                    }],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        color: '#f43f5e',
                        font: {
                          size: 10,
                          weight: "bolder"
                        },

                        formatter: (value: any, context: any) => {
                          return `\n${value}`
                        },

                        clamp: true,
                        align: 'top',
                        anchor: 'center',
                        display: false
                      },
                      legend: {
                        position: "top",
                        labels: {
                          color: 'white',
                          usePointStyle: true,
                          font: {
                            weight: 'bolder',
                            size: 12
                          }
                        }
                      },

                    },
                    scales: {
                      r: {
                        pointLabels: {
                          display: true,
                          color: 'white',
                          font: {
                            weight: 'bolder'
                          }
                        },
                        angleLines: {
                          color: 'white'
                        },
                        grid: {
                          color: "white",
                        },
                        ticks: {
                          backdropColor: '#075985',
                          color: 'black'
                        }
                      }
                    }
                  }}
                />
              </div>

              <div className="flex justify-center items-center w-full h-1/2">
                <Radar
                  className="w-full h-full"
                  datasetIdKey='id'
                  data={{
                    labels: ["Tổ quy hoạch", "Tổ xây dựng", "Tổ hạ tầng", "Tổ tổng hợp", "Đội QLĐT"],
                    datasets: [{
                      label: 'KPI bởi phòng ban',
                      data: [2100, 7000, 4000, 1500, 1500],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      borderColor: 'rgb(255, 99, 132)',
                      tension: 0.2
                    }],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        color: '#f43f5e',
                        font: {
                          size: 10,
                          weight: "bolder"
                        },

                        formatter: (value: any, context: any) => {
                          return `\n${value}`
                        },

                        clamp: true,
                        align: 'top',
                        anchor: 'center',
                        display: false
                      },
                      legend: {
                        position: "top",
                        labels: {
                          color: 'white',
                          usePointStyle: true,
                          font: {
                            weight: 'bolder',
                            size: 12
                          }
                        }
                      },

                    },
                    scales: {
                      r: {
                        pointLabels: {
                          display: true,
                          color: 'white',
                          font: {
                            weight: 'bolder'
                          }
                        },
                        angleLines: {
                          color: 'white'
                        },
                        grid: {
                          color: "white",
                        },
                        ticks: {
                          backdropColor: '#075985',
                          color: 'black'
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col w-5/12 h-full">
              {/* elements */}
              <div className="w-full h-3/5 flex flex-col">
                <div className="w-full h-[2rem] flex items-center pl-2">
                  <p className="font-bold">HIỆU XUẤT LÀM VIỆC</p>
                </div>

                <div className="w-full h-[calc(100%-2rem)] flex flex-col items-center justify-center">
                  {/* elements */}
                  <div className="flex w-full h-1/2">
                    <Chart
                      type="bar"
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: ['SỰ VỤ', 'GIẤY PHÉP XÂY DỰNG', 'THÔNG TIN QUY HOẠCH', "CẤP SỐ NHÀ", "LĨNH VỰC ĐƯỜNG BỘ", "THẨM ĐỊNH HỒ SƠ XÂY DỰNG CƠ BẢN"],
                        datasets: [
                          {
                            type: 'bar',
                            label: 'TỔNG HỒ SƠ',
                            data: [2187, 1939, 997, 526, 190, 43],
                            backgroundColor: 'rgba(2, 132, 199, 0.6)',
                            datalabels: {
                              color: 'white',
                              align: 'end',
                              anchor: 'start',
                              font: {
                                size: 13,
                                weight: 'bold'
                              }
                            }
                          },
                          {
                            type: 'line',
                            label: 'TỔNG KPI',
                            data: [6000, 2150, 1283.10, 1190, 539.68, 1460.98],
                            backgroundColor: '#be123c',
                            borderColor: 'rgba(190,18,60)',
                            tension: 0.5,
                            datalabels: {
                              color: '#e11d48',
                              align: 'end',
                              anchor: 'end',
                              font: {
                                size: 11,
                                weight: 'bold'
                              }
                            }
                          }
                        ],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: true,
                            position: "top",
                            labels: {
                              color: 'white',
                              usePointStyle: true,
                              font: {
                                size: 11,
                                weight: 'bolder'
                              }
                            }
                          },
                        },
                        scales: {
                          x: {
                            ticks: {
                              color: 'white',
                              font: {
                                weight: "bold"
                              }
                            }
                          },
                          y: {
                            ticks: {
                              color: 'white',
                              font: {
                                weight: "bold"
                              }
                            }
                          }
                        }
                      }}
                    />
                  </div>

                  <div className="flex  w-full h-1/2">
                    <Chart
                      type="bar"
                      className="w-full h-full"
                      datasetIdKey='id'
                      data={{
                        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        datasets: [
                          {
                            type: 'bar',
                            label: 'SỐ SỰ VỤ',
                            data: [115, 94, 209, 206, 213, 230, 177, 212, 171, 194, 91, 0],
                            backgroundColor: 'rgba(251,113,133,0.5)',
                            datalabels: {
                              color: '#fb7185',
                              align: 'end',
                              anchor: 'end',
                              font: {
                                size: 8,
                                weight: 'bold'
                              }
                            }
                          },
                          {
                            type: 'line',
                            label: 'TỔNG KPI',
                            data: [330.65, 313.45, 318.70, 606.80, 654.75, 665.95, 488.95, 640.80, 663.90, 546.75, 229.15, 0.00],
                            backgroundColor: 'rgba(190,18,60,0.5)',
                            borderColor: 'rgba(190,18,60,5)',
                            datalabels: {
                              color: '#be123c',
                              align: 'end',
                              anchor: 'end',
                              font: {
                                size: 8,
                                weight: 'bold'
                              }
                            }
                          },
                          {
                            type: 'bar',
                            label: 'SỐ HS ISO',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            backgroundColor: 'rgb(132,204,22,0.5)',
                            borderColor: 'rgb(132,204,22,0.5)',
                            // segment: {
                            //   borderDash: [3,3]
                            // },
                            datalabels: {
                              color: 'rgb(132,204,22)',
                              align: 'end',
                              anchor: 'end',
                              font: {
                                size: 8,
                                weight: 'bold'
                              }
                            }
                          },
                          {
                            type: 'bar',
                            label: 'SỐ HS ISO',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            backgroundColor: 'rgba(14,165,233,0.5)',
                            borderColor: 'rgb(14,165,233,0.5)',
                            datalabels: {
                              color: 'rgb(14,165,233,0.5)',
                              align: 'end',
                              anchor: 'end',
                              font: {
                                size: 8,
                                weight: 'bold'
                              }
                            }
                          },
                          {
                            type: 'bar',
                            label: 'TỔNG SỐ NHIỆM VỤ',
                            data: [115, 94, 209, 206, 213, 230, 177, 212, 171, 194, 91, 0],
                            backgroundColor: 'rgba(202,138,4,0.5)',
                            datalabels: {
                              color: '#ca8a04',
                              align: 'end',
                              anchor: 'end',
                              font: {
                                size: 8,
                                weight: 'bold'
                              }
                            }
                          },
                        ],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          // datalabels: {
                          //   color: 'white',
                          //   font: {
                          //     size: 10,
                          //     weight: "bolder"
                          //   },

                          //   formatter: (value: any, context: any) => {
                          //     return `\n${value}`
                          //   },

                          //   clamp: true,
                          //   align: 'top',
                          //   anchor: 'center',
                          // },
                          legend: {
                            display: true,
                            position: "top",
                            labels: {
                              color: 'white',
                              usePointStyle: true,
                              font: {
                                size: 11,
                                weight: 'bolder'
                              }
                            }
                          },
                        },
                        scales: {
                          x: {
                            ticks: {
                              color: 'white',
                              font: {
                                weight: "bold"
                              }
                            }
                          },
                          y: {
                            ticks: {
                              color: 'white',
                              font: {
                                weight: "bold"
                              }
                            }
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-2/5 flex flex-col">
                <div className="w-full h-[2rem] flex items-center pl-2">
                  <p className="font-bold">HIỆU XUẤT TRỄ HẸN</p>
                </div>

                <div className="w-full h-[calc(100%-2rem)] flex flex-col items-center justify-center">
                  <Bar
                    className="w-full h-full"
                    datasetIdKey='id'
                    data={{
                      labels: ['SỰ VỤ', 'GIẤY PHÉP XÂY DỰNG', 'THÔNG TIN QUY HOẠCH', "CẤP SỐ NHÀ", "LĨNH VỰC ĐƯỜNG BỘ", "THẨM ĐỊNH HỒ SƠ XÂY DỰNG CƠ BẢN", "PHÒNG QLĐT"],
                      datasets: [
                        {
                          label: 'Đúng hạn',
                          data: [53.32, 71.79, 73.72, 79.66, 78.42, 88.37, 64.62],
                          datalabels: {
                            color: 'black'
                          },
                          backgroundColor: 'white'
                        },
                        {
                          label: 'Trễ hạn',
                          data: [46.68, 28.21, 26.28, 20.34, 21.58, 11.63, 35.38],
                          datalabels: {
                            color: 'white'
                          },
                          backgroundColor: '#dc2626',
                        },
                      ],
                    }}

                    options={{
                      indexAxis: 'y',
                      maintainAspectRatio: false,
                      plugins: {
                        datalabels: {
                          labels: {
                            title: {
                              font: {
                                weight: 'bold'
                              },
                            },
                          },

                          font: {
                            size: 13,
                            weight: 'bold',
                          },

                          formatter: (value: any) => `${value}%`,
                          anchor: "center",
                          align: "center",
                        },
                        legend: {
                          labels: {
                            color: 'white'
                          }
                        }
                      },
                      scales: {
                        x: {
                          stacked: true,
                          ticks: {
                            maxTicksLimit: 6,
                            color: 'white'
                          }
                        },
                        y: {
                          stacked: true,
                          ticks: {
                            font: {
                              size: 9,
                              weight: 'bold'
                            },
                            color: 'white'
                          },
                          beginAtZero: true
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MissionProcessingUI;
