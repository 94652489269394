import React, { useEffect, useRef, useState } from "react";

import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";
import { readReport, randomColor } from "../../../../funcs/reportCalling";

import { takePhongban } from "../../../../funcs/reportCalling";

import { REPORT_SUMWORK } from "../../../../apis/_index";
import moment from "moment";
import DateTimePickerControl from "../../../../controls/datetimePicker/datetimePickerControl";
import OrganizationRepository from "../../../../repositories/organizations/organizationRepository";
import OrganizationUtils from "../../../../utils/organizations/organizationUtils";
import LevelComponent from "../../../../components/organizations/levels/levelComponent";

import "../../../../styles/reportSumworks/index.css";
import HandleDate from "../../../../utils/date/handleDate";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const SumWorkReportUI = (props: any) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Lấy năm hiện tại
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
  const day = currentDate.getDate().toString().padStart(2, "0"); // Lấy ngày hiện tại

  const [data, setData] = useState<any[] | null>(null);

  const [arrayName, setArrayName] = useState<any[] | null>(null);
  const [arraySoluong, setArraySoluong] = useState<any[] | null>(null);
  const [arrayColor, setArrayColor] = useState<any[] | null>(null);
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);

  const [fromDate, setFromDate] = useState<any | null>(
    HandleDate.firstDayOfYear
  );
  const [toDate, setToDate] = useState<any | null>(HandleDate.lastDayOfYear);

  const shouldLog = useRef(true);

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      if (res.status) {
        const data = res.data || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: "PhongBanId",
          parentId: "RefID",
        });

        const organizationTreeValueLabels =
          OrganizationUtils.convertToListValueLabel(organizationTrees, {
            value: "PhongBanId",
            label: "PhongBan",
          });

        setPhongBanList(organizationTreeValueLabels);
      }
    });
  };

  useEffect(() => {
    getListOrganization();
  }, []);

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate,
        DenNgay: toDate,
        _isCurUser: false,
      };

      readReport(REPORT_SUMWORK, body, (res: any) => {
        setData(res.data.Data);
      });

      // takePhongban((res: any) => setPhongBanList(res.data.Data));
    }
  });

  // follow first call
  useEffect(() => {
    if (data === null) {
    } else {
      let tempArray: any[] = [];
      let tempSoluong: any[] = [];
      let tempMau: any[] = [];

      data.forEach((item: any) => {
        let tempValue = `[${item._TenPhongBan}] ${item._recipentName}`;
        let tempSoLuongValue = item.SoLuong;
        let tempColor = randomColor();

        tempArray.push(tempValue);
        tempSoluong.push(tempSoLuongValue);
        tempMau.push(tempColor);
      });

      setArrayName(tempArray);
      setArraySoluong(tempSoluong);

      setArrayColor(tempMau);
    }
  }, [data]);

  // filter
  const _onClickSubmit = () => {
    let body: any;

    if (selectedPhongban !== -1) {
      body = {
        TuNgay: fromDate,
        DenNgay: toDate,
        PhongBan: selectedPhongban,
        _isCurUser: false,
      };
    } else {
      body = {
        TuNgay: fromDate,
        DenNgay: toDate,
        _isCurUser: false,
      };
    }

    readReport(REPORT_SUMWORK, body, (res: any) => {
      setData(res.data.Data);
    });

    // takePhongban((res: any) => setPhongBanList(res.data.Data));

    getListOrganization();
  };

  return (
    <>
      <br></br>
      <div className="pl-2 flex lg:flex-row flex-col w-full h-max px-3 gap-2 core-report-sumwork-filter">
        <DateTimePickerControl
          className={"min-width-input-container core-datetime-picker-resize"}
          label={"Từ ngày"}
          value={fromDate}
          onChange={(e: any) => {
            const value = e;

            // e.preventDefault();
            setFromDate(value);
          }}
        />

        <DateTimePickerControl
          className={"min-width-input-container core-datetime-picker-resize"}
          label={"Đến ngày"}
          value={toDate}
          onChange={(e: any) => {
            const value = e;

            // e.preventDefault();
            setToDate(value);
          }}
        />

        {/* <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label>Từ ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Từ Ngày"
                value={fromDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setFromDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label>Đến ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Đến Ngày"
                value={toDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setToDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div> */}
        <div className="flex flex-col h-max">
          <label className="mb-1">Phòng ban</label>
          <select
            className="h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 core-select-resize"
            value={selectedPhongban}
            onChange={(e: any) => {
              e.preventDefault();
              setSelectedPhongban(parseInt(e.target.value));
            }}
          >
            <option className="text-gray-300" value={-1}>
              --- Xem tất cả ---
            </option>
            {phongBanList === null ? (
              <></>
            ) : (
              phongBanList.map((item: any, index: any) => (
                <option key={index} value={item.PhongBanId}>
                  <LevelComponent item={item} />
                  {item.PhongBan}
                </option>
              ))
            )}
          </select>
        </div>

        <div
          className="flex space-x-2 items-end mt-2 w-full lg:w-[10%] lg:mb-1"
          style={{ textAlign: "center" }}
        >
          {/* <label>&nbsp; </label> */}
          <button
            className="
            flex items-center p-2 bg-primary rounded-md text-white
            hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 w-full justify-center core-buttonr-resize
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>
      <hr style={{ margin: "20px" }}></hr>

      {data === null ||
      arrayName === null ||
      arraySoluong === null ||
      arrayColor === null ? (
        <></>
      ) : (
        <div className="flex flex-col w-full h-4/5 ">
          <div className="flex w-full h-[calc(100%-4rem)] divide-x-[2px] pb-3 ">
            <div className="flex flex-col w-full h-full pr-3">
              <div className="w-full h-full">
                <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white drop-shadow-lg">
                  <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold lg:text-base text-xs">
                    BIỂU ĐỒ TỔNG HỢP SỐ LƯỢNG CÔNG VIỆC THEO NHÂN SỰ
                  </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                    <Bar
                      className="w-full h-full"
                      datasetIdKey="id"
                      data={{
                        labels: arrayName,
                        datasets: [
                          {
                            label: "Tổng",
                            data: arraySoluong,
                            backgroundColor: arrayColor,
                            datalabels: {
                              color: "black",
                            },
                          },
                        ],
                      }}
                      options={{
                        indexAxis: "y",
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            color: "white",
                            font: {
                              size: 13,
                              weight: "bold",
                            },
                            formatter: (value: any) => `${value}`,
                            anchor: "end",
                            align: "end",
                          },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SumWorkReportUI;
