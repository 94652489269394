import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import { takePermission } from "../../../funcs";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import { CREATE_TASK_LIST, READ_ALL_TASK_LIST } from "../../../apis/_index";
import { ONLOAD_TASKLIST } from "../../../redux/reducers/settingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

import "../../../styles/listOfTask/index.css";

const ListOfTask = (props: any) => {
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const data = useSelector((state: any) => state.settingReducer);
  const dispatch = useDispatch();

  // state
  const [taskName, setTaskName] = useState<string>("");
  const [parentID, setParentID] = useState<any>(-1);
  const [groupList, setGroupList] = useState<any>("");

  // funcs
  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  };

  const _onChangeGroupList = (e: any) => {
    e.preventDefault();
    setParentID(e.target.value);
  };

  const _onChangeParentID = (e: any) => {
    e.preventDefault();
    setParentID(e.target.value);
  };

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      Tendauviec: taskName,
      ParentID: parseInt(parentID),
      GroupList: groupList,
    };

    try {
      await ServiceUtils.postV2(CREATE_TASK_LIST, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Tạo mới thành công.");
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(READ_ALL_TASK_LIST, {
                headers: {},
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_TASKLIST(res.data.Data));
                  // setIsLoading(false);
                } else {
                  // setIsLoading(false);
                }
              });
            } catch (error: any) {
              // toast.error(
              //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              // );
              // setIsLoading(false);
              return;
            }
          };
          takeData();
        } else {
          toast.error("Tạo mới thất bại.");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[40rem] w-screen h-[30rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tạo đầu mục công việc</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên đầu mục công việc&nbsp;
              <span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              onChange={(e: any) => {
                _onChangeTaskName(e);
              }}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>Mô tả</label>
            <textarea
              className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Thuộc đầu mục&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
              value={parentID}
              onChange={(e: any) => {
                _onChangeParentID(e);
              }}
            >
              <option className="text-gray-300" value={-1} disabled>
                --- Chọn đầu mục ---
              </option>
              <option value={0}>KHÔNG</option>
              {data.taskList.length === 0
                ? ""
                : data.taskList.map((item: any, index: any) => (
                    <option key={index} value={item.Id}>
                      {item.Tendauviec}
                    </option>
                  ))}
            </select>
          </div>
        </div>

        <div className="absolute left-4 bottom-4 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max core-list-of-task-display-use">
          <p className="font-bold text-primary">Hiển thị sử dụng</p>
          <input type="checkbox" className="w-5 h-5" />
        </div>

        <div className="absolute right-4 bottom-4">
          <p
            className={`text-red-500 ${
              taskName === "" || parentID === -1 ? "flex" : "hidden"
            }`}
          >
            Vui lòng nhập tên đầu mục
          </p>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${
            taskName === "" || parentID === -1
              ? "bg-slate-300"
              : "bg-primary hover:bg-sky-400"
          }
        `}
            disabled={taskName === "" || parentID === -1 ? true : false}
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p className="hidden lg:flex">Tạo đầu việc</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListOfTask;
