const planView = `
<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- <link rel="stylesheet" href="reset.css">
        <link rel="stylesheet" href="style.css"> -->
        <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"> -->
        <script src="https://cdn.tailwindcss.com"></script>
        <title>Document</title>
    </head>
    <body>
    <style>
        blockquote {
        border-radius: 5px;
        }

        blockquote p {
        padding: 15px;
        }
    
    </style>
        <div class="mx-[10%] my-[5%]">
            <form action="#" method="GET">
                <div class="mx-[5%] my-[3%]">
                    <header class="h-[9rem] flex space-x-4  border-b-[1rem] border-b-orange-400"
                    style="margin-left: -5.5%; margin-right: -5.5%;">
                        <article>
                            <img id="lacoImage" style="width: 8rem; height: 6rem; margin-left: 20%; padding-right: 10%; border-right: 1px solid gray;" src="Asset1.png" alt="">
                        </article>
                        <article class="my-0">
                            <div class="ml-5">
                            <h2 class="text-blue-400 font-bold" style="font-size: 26px;">
                                LACO TRADE SERVICE CORPORATION
                            </h2>
                            <p class="text-xs">Địa chỉ: BT Anna, Khu nhà ở Minh Ân, X. Xuân Thới Đông, H. Hóc Môn, TP.HCM <br>
                                MST: 0307287200 <br>
                                Website: <a href="www.lacocorp.vn">www.lacocorp.vn</a>
                            </p>
                            </div>
                        </article>   
                    
                    </header>
                    <div>                  
                        <div class="flex justify-center " >
                            <h1 id="planName" class="text-orange-400 font-bold text-3xl my-[3%] ">KẾ HOẠCH HÀNH ĐỘNG</h1>
                            <!-- <h1 style="font-weight: bold;">CÔNG VIỆC VÀ KẾ HOẠCH HÀNH ĐỘNG</h1> -->
                        </div>
                        <div class="slect-item" >
                            <div class="">
                                <div class="date-time" style="font-weight: bold;" >
                                    <div class="flex space-x-4 justify-center">
                                        <div class="flex">
                                            <p>Từ ngày</p>
                                            <input id="fromDate" style="border: 0cap; margin-right: 20px;" type="date" name="bday" max="2030-12-31">
                                        </div>
                                        <div class="flex">    
                                            <p>Đến ngày</p>
                                            <input id="toDate"  style="margin-right: 20px; border: 0cap;" type="date" name="bday" min="2030-01-02">      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end items-stretch ">
                        </div>
                        <br>


                        <br>
                        <div class="" style="font-size: 15px;">
                                <span style="margin-right: 40px;font-weight: normal;" >Theo yêu cầu của: <span id="taskManager"></span></span>
                                <!-- <select style="background-color: white; border: 0cap;" aria-label="Ông/Bà">
                                    <option selected>Ông</option>
                                    <option value="1">Bà</option>
                                    </select> -->
                                <!-- <input style="height: 1.3rem; width: 7rem; border: 0cap"  type="text" value="" id="input"> -->
                                <div class="recipient_subttl">Chức vụ: <span id="taskManagerLevel"></span></div>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                    <option selected>Tổng Giám Đốc</option>
                                    <option value="1">One</option>  
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </p>
                            <br />
                            <p class="">
                                <span style="margin-right: 42px;" >Kính gửi: <span id="taskOwner"></span></span>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Ông/Bà">
                                    <option selected>Ông</option>
                                    <option value="1">Bà</option>
                                    </select>
                                    <input style="height: 1.3rem; width: 10rem;border: 0cap;" type="text" value="" id="input"> -->
                                <div class="recipient_subttl">Chức vụ: <span id="taskOwnerLevel"></span></div>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                    <option selected>Giám đốc</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </p>
                            
                            <br />
                            <p class="recipient_line">
                                <span id="giverName" style="margin-right: 50px;" >Tôi tên : Lý Trung Thành Lợi</span>
                                <!-- <input style="height: 1.3rem; width: 13.9rem; border: 0cap;"  type="text" value="" id="input">   
                                    <i class="recipient_subttl">Chức vụ : </i>
                                    <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                        <option selected>Nhân viên</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select> -->
                                <i style="margin-right: 20px;">Chức vụ: <span id="chucVuUser"></span></i>
                                <i> Phòng Ban: <span id="phongBanUser"></span></i>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Phòng Ban">
                                    <option selected>Phần mềm</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </p>
                        </div>
                        <br>
                    </div>
                
                    <br>
                    <h5 style="font-weight: bold;font-size: 15px;padding-bottom: 10px;">KẾ HOẠCH HÀNH ĐỘNG</h5>
                    <div class="flex space-x-10">
                        <div class="flex">
                            <p>Từ ngày</p>
                            <input id="fromDate" style="border: 0cap; margin-right: 20px;" type="date" name="bday" max="2030-12-31">
                        </div>
                        <div class="flex">    
                            <p>Đến ngày</p>
                            <input id="toDate" style="border: 0cap;" type="date" name="bday" min="2030-01-02">      
                        </div>
                    </div>
                    <br>
                    <div class="" >
                        <table style="font-size: 12px; width: 100%;  text-align: center; align-items: center;">
                            <thead style="background-color: blue;">
                                <tr style="font-weight: bold; height: 2rem; font-size: 15px;">
                                    <th style="color:white; border: 2px solid black;" scope="col">STT</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Nội dung công việc</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Các bước triển khai</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Mục đích</th>
                                </tr>
                            </thead>
                            <tbody id="bodyOfTaskResponsible">
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">1</th>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa mollitia voluptas odit laboriosam fugit eos harum veritatis a libero, cum esse incidunt? Accusamus vel saepe quam unde! Adipisci, quia nisi!</p>
                                          </blockquote></td>
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed maxime eligendi cupiditate tempore. Perferendis voluptas dolorem suscipit. Natus fugiat exercitationem eaque accusamus tempore, iure accusantium vitae sed nam placeat eum?</p>
                                          </blockquote></td>
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse ad, eaque, beatae veritatis placeat possimus maiores soluta, perspiciatis incidunt architecto vel nihil deserunt minus totam voluptate. Dolorum eius hic assumenda.</p>
                                          </blockquote></td>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">2</th>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left"></p>
                                          </blockquote></td>
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left"></p>
                                          </blockquote></td>
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left"></p>
                                          </blockquote></td>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">3</th>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left"></p>
                                          </blockquote></td>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left"></p>
                                          </blockquote>
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <blockquote contenteditable="true">
                                            <p class="w-full h-full text-left"></p>
                                          </blockquote>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="flex justify-ends space-x-3" style="margin: 15px;">
                        <div>
                            <input class="" type="checkbox" value="" id="defaultCheck1">
                            <label class="" for="defaultCheck1">
                            Chủ động
                            </label>
                        </div>
                        <div>
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                            <label class="form-check-label" for="defaultCheck2">
                            Bị động
                            </label>
                        </div>  
                        <div>
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                            <label class="form-check-label" for="defaultCheck2">
                            Cần bổ sung
                            </label>
                        </div> 
                    </div>
                
                    <div class="" style="font-size: 15px;font-weight: bold;">
                        <span style="margin-left: 12.5px;">KẾT QUẢ ĐÁNH GIÁ</span>
                        <blockquote contenteditable="true" >
                            <p class="w-full h-full text-left">Nhập nội dung đánh giá</p>
                          </blockquote>
                    </div>
                </div>
            </form>
            <footer>
                <img style=" width:100%;" src="Asset1.svg" alt="">
            </footer>
        </div>

        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>        
    </body>
</html>
`

export default planView;