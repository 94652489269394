import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../components/title";

import AddIcon from "@mui/icons-material/Add";
import SourceTwoToneIcon from "@mui/icons-material/SourceTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { takePermission } from "../../../funcs";

import {
  READ_ALL_LIST_QUYTRINH,
  READ_STEP_BY_ID_QUYTRINH,
} from "../../../apis/_index";
import {
  ONLOAD_QUYTRINH_LIST,
  ONCHANGE_SHOW_STEPLIST_OF_QUYTRINH,
} from "../../../redux/reducers/workingReducer/_index";

import { ModalController } from "../../homeUI/_index";
import { toast } from "react-toastify";
import axios from "axios";
import ServiceUtils from "../../../utils/services";

const ManagementWorkflow = () => {
  // redux
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const menu = takePermission(19);

  const [quytrinhList, setQuytrinhList] = useState<any>(null);

  const shouldLog = useRef(true);

  const context = useContext(ModalController);

  // funcs
  const _onClickCreateWorkFlow = () => {
    context.setFuncs("createWorkflow");
    context.handleOpen();
  };

  const _onClickLoadStep = async (id: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const API = READ_STEP_BY_ID_QUYTRINH + id.toString();

    try {
      await ServiceUtils.getV2(API, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          dispatch(
            ONCHANGE_SHOW_STEPLIST_OF_QUYTRINH({
              id: id,
              value: res.data.Data,
            })
          );
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const _onClickDetailWorkflow = (id: number) => {
    context.setFuncs("detailWorkflow");
    context.setTaskId(id);
    context.handleOpen();
  };

  // load quy trinh && step
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (data.quytrinhList === null && shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(READ_ALL_LIST_QUYTRINH, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_QUYTRINH_LIST(res.data.Data));
          });
        };

        takeData();
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  });

  return (
    <div className="flex flex-col w-full h-full ">
      <Title />

      <div className="flex flex-col w-full lg:h-[calc(100%-1.8rem)] pl-5 pr-5 pb-5 text-sm">
        <div className="pt-5">
          <button
            className={`
          ${
            menu.PerInsert === true ? "flex" : "hidden"
          } w-max h-max bg-green-600 text-white p-1
           rounded-md hover:bg-green-500 active:bg-green-700
        `}
            onClick={_onClickCreateWorkFlow}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Tạo quy trình</p>
          </button>
        </div>
        <div className="flex flex-col w-full h-max pt-3">
          {data.quytrinhList === null
            ? ""
            : data.quytrinhList.map((item: any, index: any) => (
                <div key={index} className="flex flex-col w-full h-max">
                  <div className="flex items-center w-full h-max space-x-5">
                    <div className="w-max h-max flex space-x-1">
                      <button
                        className="w-max h-max hover:text-slate-400"
                        onClick={() => _onClickLoadStep(item.id)}
                      >
                        {item.showStepList === false ? (
                          <ArrowRightIcon fontSize="small" />
                        ) : (
                          <ArrowDropDownIcon fontSize="small" />
                        )}
                      </button>
                      <button
                        className="w-max h-max flex space-x-1 hover:text-primary hover:underline"
                        onClick={() => _onClickDetailWorkflow(item.id)}
                      >
                        <SourceTwoToneIcon
                          className="text-primary font-medium"
                          fontSize="small"
                        />
                        <p className="pl-1 font-medium">{item.tenquytrinh}</p>
                      </button>
                    </div>
                  </div>

                  {item.showStepList === false ? (
                    ""
                  ) : (
                    <div className="flex flex-col w-full h-max pl-12">
                      {item.stepList.length === 0
                        ? ""
                        : item.stepList.map((subItem: any, subIndex: any) => (
                            <div
                              key={subIndex}
                              className="flex w-full h-max space-x-1.5"
                            >
                              <p className="italic">{subIndex + 1}.</p>
                              <p className="italic">{subItem.tenbuoc}</p>
                            </div>
                          ))}
                    </div>
                  )}
                </div>
              ))}

          {/* <div className="flex flex-col w-full h-max">

            <div className="flex w-full h-max space-x-1">
              <p className="font-medium">&gt;</p>
              <SourceTwoToneIcon className="text-primary font-medium" fontSize="small" />
              <p className="pl-1 font-medium">QUY TRÌNH 1</p>
            </div>

            <div className="flex flex-col w-full h-max pl-6">
              <div className="flex w-full h-max space-x-1">
                <ModeTwoToneIcon className="text-primary font-medium" fontSize="small" />
                <p>Bước 1</p>
              </div>
              <div className="flex w-full h-max space-x-1">
                <ModeTwoToneIcon className="text-primary font-medium" fontSize="small" />
                <p>Bước 2</p>
              </div>
              <div className="flex w-full h-max space-x-1">
                <ModeTwoToneIcon className="text-primary font-medium" fontSize="small" />
                <p>Bước 3</p>
              </div>
              <div className="flex w-full h-max space-x-1">
                <ModeTwoToneIcon className="text-primary font-medium" fontSize="small" />
                <p>Bước 4</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ManagementWorkflow;
