import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalController } from "../../../ui/homeUI/_index";
import axios from "axios";
import { REPORT_TASK_TOTAL_LIST } from "../../../apis/_index";
import Select from "react-select";

import { loadReceiver, loadPhongBan } from "../../../funcs/taskNguoiGiaoViec";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

import TriangleIcon from "@mui/icons-material/ChangeHistory";

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const monthList = [
  {
    id: 1,
    label: 1,
    value: 1,
  },
  {
    id: 2,
    label: 2,
    value: 2,
  },
  {
    id: 3,
    label: 3,
    value: 3,
  },
  {
    id: 4,
    label: 4,
    value: 4,
  },
  {
    id: 5,
    label: 5,
    value: 5,
  },
  {
    id: 6,
    label: 6,
    value: 6,
  },
  {
    id: 7,
    label: 7,
    value: 7,
  },
  {
    id: 8,
    label: 8,
    value: 8,
  },
  {
    id: 9,
    label: 9,
    value: 9,
  },
  {
    id: 10,
    label: 10,
    value: 10,
  },
  {
    id: 11,
    label: 11,
    value: 11,
  },
  {
    id: 12,
    label: 12,
    value: 12,
  },
];

const CompareStaff = (props: any) => {
  // Take props
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  const [userList, setUserList] = useState<any>();
  const [phongBanList, setPhongBanList] = useState<any>();

  // State
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);

  const [user1, setUser1] = useState(context.dataModal.selectedUser);
  const [user2, setUser2] = useState("");
  const [phongBanUser1, setPhongBanUser1] = useState(
    context.dataModal.selectedPhongBan
  );
  const [phongBanUser2, setPhongBanUser2] = useState("");

  const [month, setMonth] = useState(context.dataModal.month);
  const [year, setYear] = useState(context.dataModal.year);

  const [resultFilterUser1, setResultFilterUser1] = useState<any>();
  const [resultFilterUser2, setResultFilterUser2] = useState<any>();

  useEffect(() => {
    loadReceiver((res: any) => setUserList(res.data.Data), shouldLog2);
    loadPhongBan((res: any) => setPhongBanList(res.data.Data), shouldLog3);
  });

  const tempReceiverList = useMemo(() => {
    const tempArray = userList?.map((item: any) => {
      return { ...item, label: item.FullName, value: item.FullName };
    });

    return tempArray;
  }, [userList]);

  const tempPhongBanList = useMemo(() => {
    const tempArray = phongBanList?.map((item: any) => {
      return {
        ...item,
        label: item.ParameterValue,
        value: item.ParameterValue,
      };
    });

    return tempArray;
  }, [phongBanList]);

  // Funcs onChange
  const _onChangePhongBan1 = (data: any) => {
    if (data === null) {
      setPhongBanUser1("");
      setUser1("");
    } else {
      setPhongBanUser1(data.ParameterId.toString());
    }
  };

  const _onChangeUser1 = (data: any) => {
    if (data === null) {
      setUser1("");
    } else {
      setUser1(data.Id);
    }
  };

  const _onChangePhongBan2 = (data: any) => {
    if (data === null) {
      setPhongBanUser2("");
      setUser2("");
    } else {
      setPhongBanUser2(data.ParameterId.toString());
      setUser2("");
    }
  };

  const employeeList1 = userList
    ?.filter(
      (item: any) =>
        (item.PhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == phongBanUser1.toString()) != null
    )
    .map((item: any) => {
      return { ...item, value: item.FullName, label: item.FullName };
    });

  const employeeList2 = userList
    ?.filter(
      (item: any) =>
        (item.PhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == phongBanUser2.toString()) != null
    )
    .map((item: any) => {
      return { ...item, value: item.FullName, label: item.FullName };
    });

  const _onChangeUser2 = (data: any) => {
    if (data === null) {
      setUser2("");
    } else {
      setUser2(data.Id);
    }
  };

  const _onClickSubmit = () => {
    const accessToken = localStorage.getItem("accessToken");

    const fetchData1 = async () => {
      const body: any = {
        SEARCH_MONTH: month === 0 ? null : month,
        SEARCH_YEAR: year,
        SERACH_PHONGBAN: phongBanUser1,
        USER_ID: user1,
      };

      try {
        await ServiceUtils.postV2(REPORT_TASK_TOTAL_LIST, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setResultFilterUser1(res.data.Data.data[0]);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    const fetchData2 = async () => {
      const body: any = {
        SEARCH_MONTH: month === 0 ? null : month,
        SEARCH_YEAR: year,
        SERACH_PHONGBAN: phongBanUser2,
        USER_ID: user2,
      };

      try {
        await ServiceUtils.postV2(REPORT_TASK_TOTAL_LIST, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setResultFilterUser2(res.data.Data.data[0]);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData1();
    fetchData2();
  };

  const hightlightCompare = (number1: any, number2: any): any => {
    return number1 > number2 ? "font-bold text-red-600" : "";
  };

  const iconCompare = (number1: any, number2: any): any => {
    if (isNaN(number1) || isNaN(number2)) {
      return null;
    }

    return number1 > number2 ? (
      <div
        style={{
          transform: "translateY(-50%)",
          left: "10px",
          position: "absolute",
          top: "50%",
        }}
      >
        <TriangleIcon />
      </div>
    ) : (
      <div
        style={{
          transform: "translateY(-50%)",
          right: "10px",
          position: "absolute",
          top: "50%",
        }}
      >
        <TriangleIcon />
      </div>
    );
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[80rem] w-screen lg:h-[50rem] h-screen
      rounded-md
    "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>So sánh nhân sự</p>
        <button
          className="hover:bg-slate-300 p-1 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div
        className="
        flex flex-col w-full h-[calc(100%-6rem)] overflow-auto 
        p-4 text-sm styled-scrollbars
      "
      >
        {/* Options */}
        <div
          className="
          w-full h-max flex flex-col space-y-4
        "
        >
          {/* Time fields */}
          <div
            className="lg:w-1/2 h-max flex lg:flex-row 
            flex-col lg:space-x-4 space-x-0
          "
          >
            <div className="lg:w-1/2 h-max">
              <p className="text-xs">Tháng</p>
              <Select
                className="z-[6]"
                isClearable
                options={monthList}
                value={monthList.find((item: any) => item?.id === month)}
                onChange={(data: any) => {
                  if (data === null) {
                    setMonth(0);
                  } else {
                    setMonth(data.id);
                  }
                }}
                placeholder="--- Chọn tháng ---"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="lg:w-1/2 h-max">
              <p className="text-xs">Năm</p>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập năm"
                type="number"
                value={year}
                onChange={(e: any) => {
                  e.preventDefault();
                  setYear(e.target.value);
                }}
              />
            </div>
          </div>
          {/* Options staff */}
          <div
            className="          
              w-full h-max flex lg:flex-row flex-col
              lg:space-x-4 lg:space-y-0 space-x-0 space-y-4"
          >
            {/* First user */}
            <div className="flex flex-col lg:w-1/2 w-full h-max">
              <p className="text-xs">Chọn nhân viên thứ 1</p>
              <div className="w-full h-max flex space-x-3">
                <div className="w-2/5 h-max">
                  <Select
                    className="z-[5]"
                    isClearable
                    onChange={(data: any) => _onChangePhongBan1(data)}
                    value={tempPhongBanList?.find(
                      (item: any) =>
                        item?.ParameterId.toString() === phongBanUser1
                    )}
                    options={tempPhongBanList}
                    placeholder="--- Chọn phòng ban ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="w-3/5 h-max">
                  <Select
                    className="z-[5]"
                    isClearable
                    options={employeeList1}
                    onChange={(data: any) => _onChangeUser1(data)}
                    value={tempReceiverList?.find(
                      (item: any) => item?.Id === user1
                    )}
                    placeholder="--- Chọn nhân viên ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Second user */}
            <div className="flex flex-col lg:w-1/2 w-full h-max">
              <p className="text-xs">Chọn nhân viên thứ 2</p>
              <div className="w-full h-max flex space-x-3">
                <div className="w-2/5 h-max">
                  <Select
                    className="z-[5]"
                    isClearable
                    options={tempPhongBanList}
                    onChange={(data: any) => _onChangePhongBan2(data)}
                    value={tempPhongBanList?.find(
                      (item: any) =>
                        item?.ParameterId.toString() === phongBanUser2
                    )}
                    placeholder="--- Chọn phòng ban ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="w-3/5 h-max">
                  <Select
                    className="z-[5]"
                    isClearable
                    onChange={(data: any) => _onChangeUser2(data)}
                    value={tempReceiverList?.find(
                      (item: any) => item?.Id === user2
                    )}
                    options={employeeList2}
                    placeholder="--- Chọn nhân viên ---"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Search staff */}
          <div className="w-full h-max flex justify-center items-center">
            <button
              className="bg-primary rounded-md font-semibold text-white hover:bg-cyan-400 w-max h-max flex justify-center items-center p-2"
              onClick={_onClickSubmit}
            >
              So sánh
            </button>
          </div>

          {/* Result fields */}
          <div className="w-full h-max flex flex-col justify-center items-center space-y-10">
            {/* Main fields */}
            <div className="w-full h-max flex flex-col justify-center items-center space-y-1">
              {/* Title */}
              <div className="w-full h-max flex justify-center items-center">
                <p className="text-lg font-semibold text-blue-600">Công việc</p>
              </div>

              {/* Elements */}
              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.TotalListResponsible +
                        resultFilterUser1?.TotalListResponsible_SUP,
                      resultFilterUser2?.TotalListResponsible +
                        resultFilterUser2?.TotalListResponsible_SUP
                    )}`}
                  >
                    {resultFilterUser1?.TotalListResponsible +
                      resultFilterUser1?.TotalListResponsible_SUP}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center relative">
                  <p>Tổng hợp công việc đã giao</p>
                  {iconCompare(
                    resultFilterUser1?.TotalListResponsible +
                      resultFilterUser1?.TotalListResponsible_SUP,
                    resultFilterUser2?.TotalListResponsible +
                      resultFilterUser2?.TotalListResponsible_SUP
                  )}
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5 relative">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.TotalListResponsible +
                        resultFilterUser2?.TotalListResponsible_SUP,
                      resultFilterUser1?.TotalListResponsible +
                        resultFilterUser1?.TotalListResponsible_SUP
                    )}`}
                  >
                    {resultFilterUser2?.TotalListResponsible +
                      resultFilterUser2?.TotalListResponsible_SUP}
                  </p>
                  {iconCompare(
                    resultFilterUser2?.TotalListResponsible +
                      resultFilterUser2?.TotalListResponsible_SUP,
                    resultFilterUser1?.TotalListResponsible +
                      resultFilterUser1?.TotalListResponsible_SUP
                  )}
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5 relative">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.TotalListNew +
                        resultFilterUser1?.TotalListNew_SUP,
                      resultFilterUser2?.TotalListNew +
                        resultFilterUser2?.TotalListNew_SUP
                    )}`}
                  >
                    {resultFilterUser1?.TotalListNew +
                      resultFilterUser1?.TotalListNew_SUP}
                  </p>
                  {iconCompare(
                    resultFilterUser1?.TotalListNew +
                      resultFilterUser1?.TotalListNew_SUP,
                    resultFilterUser2?.TotalListNew +
                      resultFilterUser2?.TotalListNew_SUP
                  )}
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Tổng việc mới được giao</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5  ">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.TotalListNew +
                        resultFilterUser2?.TotalListNew_SUP,
                      resultFilterUser1?.TotalListNew +
                        resultFilterUser1?.TotalListNew_SUP
                    )}`}
                  >
                    {resultFilterUser2?.TotalListNew +
                      resultFilterUser2?.TotalListNew_SUP}
                  </p>
                  {iconCompare(
                    resultFilterUser2?.TotalListNew +
                      resultFilterUser2?.TotalListNew_SUP,
                    resultFilterUser1?.TotalListNew +
                      resultFilterUser1?.TotalListNew_SUP
                  )}
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5 relative">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.TotalListReveced +
                        resultFilterUser1?.TotalListReveced_SUP,
                      resultFilterUser2?.TotalListReveced +
                        resultFilterUser2?.TotalListReveced_SUP
                    )}`}
                  >
                    {resultFilterUser1?.TotalListReveced +
                      resultFilterUser1?.TotalListReveced_SUP}
                  </p>
                  {iconCompare(
                    resultFilterUser1?.TotalListReveced +
                      resultFilterUser1?.TotalListReveced_SUP,
                    resultFilterUser2?.TotalListReveced +
                      resultFilterUser2?.TotalListReveced_SUP
                  )}
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Tổng việc chưa xử lý</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.TotalListReveced +
                        resultFilterUser2?.TotalListReveced_SUP,
                      resultFilterUser1?.TotalListReveced +
                        resultFilterUser1?.TotalListReveced_SUP
                    )}`}
                  >
                    {resultFilterUser2?.TotalListReveced +
                      resultFilterUser2?.TotalListReveced_SUP}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.TotalListProccessing +
                        resultFilterUser1?.TotalListProccessing_SUP,
                      resultFilterUser2?.TotalListProccessing +
                        resultFilterUser2?.TotalListProccessing_SUP
                    )}`}
                  >
                    {resultFilterUser1?.TotalListProccessing +
                      resultFilterUser1?.TotalListProccessing_SUP}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Tổng việc đang xử lý</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.TotalListProccessing +
                        resultFilterUser2?.TotalListProccessing_SUP,
                      resultFilterUser1?.TotalListProccessing +
                        resultFilterUser1?.TotalListProccessing_SUP
                    )}`}
                  >
                    {resultFilterUser2?.TotalListProccessing +
                      resultFilterUser2?.TotalListProccessing_SUP}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.TotalListCompleted +
                        resultFilterUser1?.TotalListCompleted_SUP,
                      resultFilterUser2?.TotalListCompleted +
                        resultFilterUser2?.TotalListCompleted_SUP
                    )}`}
                  >
                    {resultFilterUser1?.TotalListCompleted +
                      resultFilterUser1?.TotalListCompleted_SUP}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Tổng việc hoàn tất</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.TotalListCompleted +
                        resultFilterUser2?.TotalListCompleted_SUP,
                      resultFilterUser1?.TotalListCompleted +
                        resultFilterUser1?.TotalListCompleted_SUP
                    )}`}
                  >
                    {resultFilterUser2?.TotalListCompleted +
                      resultFilterUser2?.TotalListCompleted_SUP}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full h-max flex flex-col justify-center items-center space-y-1">
              {/* Title */}
              <div className="w-full h-max flex justify-center items-center">
                <p className="text-lg font-semibold text-amber-600">Kết quả</p>
              </div>

              {/* Elements */}
              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Recipient_NumberExcellent +
                        resultFilterUser1?.Supporter_NumberExcellent,
                      resultFilterUser2?.Recipient_NumberExcellent +
                        resultFilterUser2?.Supporter_NumberExcellent
                    )}`}
                  >
                    {resultFilterUser1?.Recipient_NumberExcellent +
                      resultFilterUser1?.Supporter_NumberExcellent}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Xuất sắc</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Recipient_NumberExcellent +
                        resultFilterUser2?.Supporter_NumberExcellent,
                      resultFilterUser1?.Recipient_NumberExcellent +
                        resultFilterUser1?.Supporter_NumberExcellent
                    )}`}
                  >
                    {resultFilterUser2?.Recipient_NumberExcellent +
                      resultFilterUser2?.Supporter_NumberExcellent}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Recipient_NumberGood +
                        resultFilterUser1?.Supporter_NumberGood,
                      resultFilterUser2?.Recipient_NumberGood +
                        resultFilterUser2?.Supporter_NumberGood
                    )}`}
                  >
                    {resultFilterUser1?.Recipient_NumberGood +
                      resultFilterUser1?.Supporter_NumberGood}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Tốt</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Recipient_NumberGood +
                        resultFilterUser2?.Supporter_NumberGood,
                      resultFilterUser1?.Recipient_NumberGood +
                        resultFilterUser1?.Supporter_NumberGood
                    )}`}
                  >
                    {resultFilterUser2?.Recipient_NumberGood +
                      resultFilterUser2?.Supporter_NumberGood}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Recipient_NumberMedium +
                        resultFilterUser1?.Supporter_NumberMedium,
                      resultFilterUser2?.Recipient_NumberMedium +
                        resultFilterUser2?.Supporter_NumberMedium
                    )}`}
                  >
                    {resultFilterUser1?.Recipient_NumberMedium +
                      resultFilterUser1?.Supporter_NumberMedium}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Trung bình</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Recipient_NumberMedium +
                        resultFilterUser2?.Supporter_NumberMedium,
                      resultFilterUser1?.Recipient_NumberMedium +
                        resultFilterUser1?.Supporter_NumberMedium
                    )}`}
                  >
                    {resultFilterUser2?.Recipient_NumberMedium +
                      resultFilterUser2?.Supporter_NumberMedium}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Recipient_NumberBad +
                        resultFilterUser1?.Supporter_NumberFail,
                      resultFilterUser2?.Recipient_NumberBad +
                        resultFilterUser2?.Supporter_NumberFail
                    )}`}
                  >
                    {resultFilterUser1?.Recipient_NumberBad +
                      resultFilterUser1?.Supporter_NumberFail}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Kém</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Recipient_NumberBad +
                        resultFilterUser2?.Supporter_NumberFail,
                      resultFilterUser1?.Recipient_NumberBad +
                        resultFilterUser1?.Supporter_NumberFail
                    )}`}
                  >
                    {resultFilterUser2?.Recipient_NumberBad +
                      resultFilterUser2?.Supporter_NumberFail}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full h-max flex flex-col justify-center items-center space-y-1">
              {/* Title */}
              <div className="w-full h-max flex justify-center items-center">
                <p className="text-lg font-semibold text-emerald-600">
                  Đánh giá năng lực
                </p>
              </div>

              {/* Elements */}
              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.NANGSUAT,
                      resultFilterUser2?.NANGSUAT
                    )}`}
                  >
                    {resultFilterUser1?.NANGSUAT}%
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Năng suất lao động</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.NANGSUAT,
                      resultFilterUser1?.NANGSUAT
                    )}`}
                  >
                    {resultFilterUser2?.NANGSUAT}%
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.NANGLUC,
                      resultFilterUser2?.NANGLUC
                    )}`}
                  >
                    {resultFilterUser1?.NANGLUC}%
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Năng lực hoàn thành</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.NANGLUC,
                      resultFilterUser1?.NANGLUC
                    )}`}
                  >
                    {resultFilterUser2?.NANGLUC}%
                  </p>
                </div>
              </div>
              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.NANGSUATPHOIHOP,
                      resultFilterUser2?.NANGSUATPHOIHOP
                    )}`}
                  >
                    {resultFilterUser1?.NANGSUATPHOIHOP}%
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Năng suất phối hợp</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.NANGSUATPHOIHOP,
                      resultFilterUser1?.NANGSUATPHOIHOP
                    )}`}
                  >
                    {resultFilterUser2?.NANGSUATPHOIHOP}%
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.NANGLUCPHOIHOP,
                      resultFilterUser2?.NANGLUCPHOIHOP
                    )}`}
                  >
                    {resultFilterUser1?.NANGLUCPHOIHOP}%
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Năng lực phối hợp</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.NANGLUCPHOIHOP,
                      resultFilterUser1?.NANGLUCPHOIHOP
                    )}`}
                  >
                    {resultFilterUser2?.NANGLUCPHOIHOP}%
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full h-max flex flex-col justify-center items-center space-y-1">
              {/* Title */}
              <div className="w-full h-max flex justify-center items-center">
                <p className="text-lg font-semibold text-purple-600">
                  Thái độ hỗ trợ
                </p>
              </div>

              {/* Elements */}
              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Supporter_Ardent,
                      resultFilterUser2?.Supporter_Ardent
                    )}`}
                  >
                    {resultFilterUser1?.Supporter_Ardent}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Nhiệt tình</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Supporter_Ardent,
                      resultFilterUser1?.Supporter_Ardent
                    )}`}
                  >
                    {resultFilterUser2?.Supporter_Ardent}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Supporter_NoArdent,
                      resultFilterUser2?.Supporter_NoArdent
                    )}`}
                  >
                    {resultFilterUser1?.Supporter_NoArdent}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Không nhiệt tình</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Supporter_NoArdent,
                      resultFilterUser1?.Supporter_NoArdent
                    )}`}
                  >
                    {resultFilterUser2?.Supporter_NoArdent}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Supporter_Ardent_NoExpertise,
                      resultFilterUser2?.Supporter_Ardent_NoExpertise
                    )}`}
                  >
                    {resultFilterUser1?.Supporter_Ardent_NoExpertise}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Nhiệt tình không chuyên môn</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Supporter_Ardent_NoExpertise,
                      resultFilterUser1?.Supporter_Ardent_NoExpertise
                    )}`}
                  >
                    {resultFilterUser2?.Supporter_Ardent_NoExpertise}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Supporter_Not_Engaged,
                      resultFilterUser2?.Supporter_Not_Engaged
                    )}`}
                  >
                    {resultFilterUser1?.Supporter_Not_Engaged}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Không nhiệt tình không chuyên môn</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Supporter_Not_Engaged,
                      resultFilterUser1?.Supporter_Not_Engaged
                    )}`}
                  >
                    {resultFilterUser2?.Supporter_Not_Engaged}
                  </p>
                </div>
              </div>

              <div className="w-full h-[4rem] flex justify-center items-center space-x-2">
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-end items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser1?.Supporter_No_Joining,
                      resultFilterUser2?.Supporter_No_Joining
                    )}`}
                  >
                    {resultFilterUser1?.Supporter_No_Joining}
                  </p>
                </div>
                <div className="min-w-[20rem] max-w-[20rem] h-full font-semibold bg-slate-200 flex justify-center items-center">
                  <p>Không tham gia</p>
                </div>
                <div className="w-[calc((100%-20rem)/2)] h-full flex justify-start items-center px-5">
                  <p
                    className={`text-lg ${hightlightCompare(
                      resultFilterUser2?.Supporter_No_Joining,
                      resultFilterUser1?.Supporter_No_Joining
                    )}`}
                  >
                    {resultFilterUser2?.Supporter_No_Joining}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div
        className={`
          flex w-full p-12-16 footer rounded-b-md text-sm space-x-4
          items-center justify-end
        `}
      >
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompareStaff;
