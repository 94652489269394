import React, { useState, useEffect, useRef, useContext } from "react";
import { ModalController } from "../../../homeUI/_index";

import {
  GET_LIST_REPORT_WAITING_VERIFY,
  LIST_PROACTIVE_NGUOINHAN,
  USER_READ_ALL_DROPDOWN,
} from "../../../../apis/_index";

import { Pagination } from "@mui/material";

import { toast } from "react-toastify";
import moment from "moment";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import LoaderSpinner from "../../../../components/loader-spinner/_index";
import DateTimePickerControl from "../../../../controls/datetimePicker/datetimePickerControl";
import ServiceUtils from "../../../../utils/services";
import LoadingControl from "../../../../controls/loading/loadingControl";

import "../../../../styles/reportConfirms/index.css";
import HandleUI from "../../../../utils/handleUI";
import HandleDate from "../../../../utils/date/handleDate";
import { _onClickFuncs, _onClickOther } from "../../../../funcs/onClickShowBtn";
import Select from "react-select";
import { useSelector } from "react-redux";
import SubModal from "../../../../components/modal/_subModal/_index";

const ReportProactive = (props: any) => {
  const workingData = useSelector((state: any) => state.workingReducer);
  const [dataList, setDataList] = useState<any | null>(null);

  // page Info
  const [selectedPage, setSelectedPage] = useState<any | null>(0);
  const [totalPage, setTotalPage] = useState<any | null>(null);
  const [pageShowing, setPageShowing] = useState<any | null>(10);

  const currentDate = new Date();
  const [fromDate, setFromDate] = useState<any | null>(
    HandleDate.formatDate(new Date(currentDate.getFullYear(), 0, 1)) +
      "T00:00:00.000"
  );
  const [toDate, setToDate] = useState<any | null>(
    HandleDate.formatDate(new Date(currentDate.getFullYear() + 1, 0, 1)) +
      "T00:00:00.000"
  );
  const [name, setName] = useState<any | null>("");
  const [user, setUser] = useState<any | null>(null);

  const [userIdList, setUserIdList] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setUserIdList(
              res?.data?.Data?.map((item: any) => {
                return { label: item?.FullName, value: item?.Id };
              })
            );
          }
        });
      } catch (error: any) {}
    };
    fetchData();
  }, []);

  const _onLoadData = () => {
    const fetchData = async () => {
      try {
        const body: any = {
          Page: selectedPage,
          Limit: pageShowing,
          FromDate: fromDate,
          ToDate: toDate,
          Search: name,
          ReportUserId: user?.value || null,
        };

        await ServiceUtils.postV2(LIST_PROACTIVE_NGUOINHAN, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setDataList(res.data.Data);

            if (res.data.Data.count % pageShowing === 0) {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing));
            } else {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing) + 1);
            }
          }
        });
      } catch (_error) {
        toast.error("Tải danh sách báo cáo thất bại.");
      }
    };

    fetchData();
  };

  // onMount data
  useEffect(() => {
    _onLoadData();
  }, [selectedPage, pageShowing]);

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [reportID, setReportID] = useState<any | null>(null);
  const [reportHistoryID, setReportHistoryID] = useState<any | null>(null);
  const [fileName, setFileName] = useState<any | null>(null);
  const [reportStatus, setReportStatus] = useState<any | null>(null);
  const [result, setResult] = useState<any | null>(null);
  const [content, setContent] = useState<any | null>(null);
  const _onClickDetail = (
    id: string,
    reportHistoryID: string,
    fileName: string,
    reportStatus: string,
    result: string,
    content: string
  ) => {
    setReportID(id);
    setReportHistoryID(reportHistoryID);
    setFileName(fileName);
    setReportStatus(reportStatus);
    setResult(setResult);
    setContent(content);
    setFuncsSub("detailReportProactive");
    handleOpenSub();
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setSelectedPage(value - 1);
  };

  const context = useContext(ModalController);

  const _onClickSubmit = () => {
    _onLoadData();
  };
  _onClickOther();
  return (
    <>
      <SubModal
        ReportHistoryID={reportHistoryID}
        ReportID={reportID}
        FileName={fileName}
        ReportStatus={reportStatus}
        Result={result}
        Content={content}
        _onFetchData={_onLoadData}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />
      <br></br>
      <div className="w-full h-max p-3">
        <div className="grid lg:grid-cols-5 grid-cols-1 gap-2 lg:gap-4 mt-2">
          <div className="flex flex-col w-full pr-0 h-max">
            <label className="mb-1">Tên nội dung cáo</label>
            <input
              className="w-full rounded-[4px] px-2 py-1.5 border-[1px] border-gray-400 text-base"
              placeholder="Nhập nội dung báo cáo"
              value={name}
              onChange={(e: any) => {
                e.preventDefault();
                setName(e.target.value);
              }}
            />
          </div>

          <div className="flex flex-col w-full pr-0 h-max">
            <label className="mb-1">Người báo cáo</label>

            <Select
              className="z-[2] text-base"
              options={userIdList}
              value={user}
              onChange={(data: any) => {
                if (data == null) setUser(null);
                else setUser(data);
              }}
              isClearable
              placeholder="---Chọn người báo cáo---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <DateTimePickerControl
            className=""
            label={"Từ ngày"}
            value={fromDate}
            onChange={(e: any) => {
              const value = e;

              setFromDate(value);
            }}
          />

          <DateTimePickerControl
            className=""
            label={"Đến ngày"}
            value={toDate}
            onChange={(e: any) => {
              const value = e;

              setToDate(value);
            }}
          />

          {/* <div className="flex space-x-2 items-end justify-end lg:pl-3 md:pl-3 pt-3">
            <button
              className="
            flex items-center h-max p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 justify-center core-button-resize
            "
              onClick={_onClickSubmit}
            >
              <p>Xem</p>
            </button>
          </div> */}
        </div>
        <div className="flex justify-end gap-2  mt-5">
          <button
            className="
            flex items-center h-max p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 w-full justify-center core-button-resize
            "
            onClick={_onClickSubmit}
          >
            <p>Xem</p>
          </button>
        </div>
      </div>
      {dataList === null ? (
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 pb-10">
          <div className="text-sm grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {dataList === null ? "" : dataList?.count} báo cáo
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {dataList === null ? "" : dataList?.data?.length} báo cáo
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={pageShowing}
                onChange={(e: any) => {
                  e.preventDefault();
                  setPageShowing(e.target.value);
                  setSelectedPage(0);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>

          <div
            className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md"
            id="table"
          >
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Nội dung</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Loại báo cáo</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày tạo</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người gửi</p>
                    </div>
                  </th>
                  {/* <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                    </div>
                  </th> */}
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {dataList === null ? (
                  <></>
                ) : (
                  dataList.data?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="hidden lg:table-cell border border-slate-300 text-center">
                        {index + 1 + selectedPage * pageShowing}
                      </td>
                      <td className="text-left border border-slate-300">
                        <p className="hidden lg:flex">{item.ReportName}</p>
                        <p className="lg:hidden font-bold">
                          <span className="lg:hidden">
                            {index + 1 + selectedPage * pageShowing}
                            .&nbsp;
                          </span>
                          {item.ReportName}
                        </p>

                        <p className="lg:hidden">
                          <span className="text-xs">Loại báo cáo: </span>
                          <span className="font-semibold">
                            {item.TypeReportJob == 1
                              ? "Báo cáo ngày"
                              : item.TypeReportJob == 2
                              ? "Báo cáo tháng"
                              : item.TypeReportJob == 3
                              ? "Báo cáo quý"
                              : item.TypeReportJob == 4
                              ? "Báo cáo năm"
                              : item.TypeReportJob === 5
                              ? "Báo cáo tự chọn"
                              : item.TypeReportJob == 6
                              ? "Báo cáo ngoài công việc"
                              : ""}
                          </span>
                        </p>

                        <p className="lg:hidden">
                          <span className="text-xs">Người gửi: </span>
                          <span className="font-semibold">
                            {item.NameReportUser}
                          </span>
                        </p>

                        <div className="lg:hidden flex items-center justify-between">
                          <p className="lg:hidden">
                            <span className="text-xs">Ngày tạo: </span>
                            <span className="font-semibold">
                              {moment(item.DateAdd).format("DD/MM/YYYY")}{" "}
                              {moment(item.DateAdd).format("HH:mm:ss")}
                            </span>
                          </p>
                          <div className="dropdown2">
                            {/* button options */}
                            <button
                              className="dropbtn2"
                              onClick={() => _onClickFuncs(index.toString())}
                            >
                              ...
                            </button>

                            {/* options fields */}
                            <div
                              id={index.toString()}
                              className="dropdown-content2 text-xs font-semibold"
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickDetail(
                                    item.ID,
                                    item.ReportHistoryID,
                                    item.FileName,
                                    item.ReportStatus,
                                    item.Result,
                                    item.Comment_UserAdd
                                  )
                                }
                              >
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  <div>Chi tiết / Đánh giá</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-left border border-slate-300">
                        {item.TypeReportJob == 1
                          ? "Báo cáo ngày"
                          : item.TypeReportJob == 2
                          ? "Báo cáo tháng"
                          : item.TypeReportJob == 3
                          ? "Báo cáo quý"
                          : item.TypeReportJob == 4
                          ? "Báo cáo năm"
                          : item.TypeReportJob == 5
                          ? "Báo cáo tự chọn"
                          : item.TypeReportJob == 6
                          ? "Báo cáo ngoài công việc"
                          : ""}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-full flex flex-col justify-center items-center">
                          <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-left border border-slate-300">
                        {item.NameReportUser}
                      </td>

                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="ml-1 dropdown">
                          <div className="flex">
                            <button
                              className="dropbtn"
                              onMouseEnter={() => {
                                HandleUI.hoverMenuContextScrollTable(
                                  index,
                                  dataList.reports?.length
                                );
                              }}
                            >
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            <div className="dropdown-content text-xs font-semibold">
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickDetail(
                                    item.ID,
                                    item.ReportHistoryID,
                                    item.FileName,
                                    item.ReportStatus,
                                    item.Result,
                                    item.Comment_UserAdd
                                  )
                                }
                              >
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  <div>Chi tiết</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={totalPage === null ? 0 : totalPage}
              page={selectedPage + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportProactive;
