import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const ListOfSecurity = () => {
  return (
    <HomeUI />
  )
}

export default ListOfSecurity;
