import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import ReactDOMServer, { renderToString } from "react-dom/server";

import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

// import autosize from "autosize";
import TextareaAutosize from "react-textarea-autosize";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import SubModal from "../_subModal/_index";
import moment from "moment";

import reportView from "../../../temp/baocao";
// import { reportView2 } from "../../../temp/baocao/index2";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

import {
  BAO_CAO_CHI_TIET,
  BAO_CAO_UPDATE_REPORT,
  BAO_CAO_SENT,
  READ_REPORT_NGUOINHAN,
  UPDATE_BAO_CAO,
  CREATE_REPORT_CONTENT,
} from "../../../apis/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import axios from "axios";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import reportView3 from "../../../temp/baocao/index3";

const SubViewReport = (props: any) => {
  // Take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const handleClose2 = props.handleClose2;

  const _onClickUpdateReport = props._onClickUpdateReport;
  const planOfAction = props.planOfAction;
  const bonusOrPayoff = props.bonusOrPayoff;
  const dataHis = props.dataHistory;
  const dataHistoryID = props.ReportHistoryID;

  // alert(dataHistoryID);

  // alert(dataHistoryID);
  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  // state submodal
  const [data, setData] = useState<any>(null);
  const [template, setTemplate] = useState<string | null>(null);
  const [dataHistory, setDataHistory] = useState<any>(null);

  const [optionsParser, setOptionParser] = useState<any>();

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const elementRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const FinalJSX = () => {
    return (
      <div
        id="pdf"
        ref={elementRef}
        style={{ fontFamily: "Roboto sans-serif", width: "446px" }}
      >
        {data.report_details.TypeReportJob == "6" ? (
          <>{parse(reportView3, optionsParser)}</>
        ) : (
          <>{parse(reportView, optionsParser)}</>
        )}
      </div>
    );
  };

  const _onClickReportDelay = () => {
    setFuncsSub("reportDelay");
    handleOpenSub();
  };

  // const [pdf, setPdf] = useState<jsPDF | null>(null);

  const _onClickSendReport = async () => {
    const createPDF = async (): Promise<jsPDF> => {
      return new Promise(async (resolve, reject) => {
        const doc = new jsPDF("p", "px", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        const topMargin = 0;
        const bottomMargin = 0;
        const leftMargin = 0;
        const rightMargin = 0;
        const usableWidth = width - leftMargin - rightMargin;
        const usableHeight = height - topMargin - bottomMargin;

        try {
          if (elementRef.current) {
            let position = topMargin;
            // Chia nội dung thành nhiều trang nếu cần
            while (position < elementRef.current.clientHeight) {
              const canvas = await html2canvas(elementRef.current, {
                width: usableWidth,
                x: leftMargin,
                y: position,
                height: usableHeight,
                scale: 1,
              });

              const imgData = canvas.toDataURL("image/png");
              if (position !== topMargin) {
                doc.addPage(); // Thêm trang mới
              }
              doc.addImage(
                imgData,
                "PNG",
                leftMargin,
                topMargin,
                usableWidth,
                usableHeight
              );
              position += usableHeight; // Di chuyển đến vị trí kế tiếp
            }
          }
          resolve(doc);
        } catch (error) {
          reject("Quá trình chuyển đổi PDF không thành công!");
        }
      });
    };

    const sendPDFToServer = async (pdf: jsPDF) => {
      const accessToken = localStorage.getItem("accessToken");
      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      // pdf.save();
      formData.append("File", pdfBlob, "report.pdf");
      // formData.append("ContentType", "application/pdf");
      // formData.append("ContentDisposition", pdfBlob);
      // formData.append("Headers", "");
      // formData.append("Length", pdfBlob.size.toString());
      // formData.append("Name", "Report");
      // formData.append("FileName", "report.pdf");
      try {
        await ServiceUtils.postFormDataV2(
          `${BAO_CAO_SENT}?ID=${taskId}&ReportHistoryID=${dataHistoryID}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "text/plain",
            },
          }
        ).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Gửi báo cáo thành công");
            handleClose();
            handleClose2();
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error) {
        toast.error(
          "Không kết nối được máy chủ. Vui lòng kiểm tra lại đường truyền."
        );
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    _onClickUpdateReport(false);
    const pdf = await createPDF();
    if (pdf) {
      await sendPDFToServer(pdf);
    } else {
      setIsLoading(false);
    }
    // Sau đó gửi lên máy chủ

    // const pdf = new jsPDF("p", "px", "a4");
    // var width = pdf.internal.pageSize.getWidth();
    // var height = pdf.internal.pageSize.getHeight();
    // const topMargin = 0;
    // const bottomMargin = 0;
    // const leftMargin = 0;
    // const rightMargin = 0;
    // const usableWidth = width - leftMargin - rightMargin;
    // const usableHeight = height - topMargin - bottomMargin;
    // if (elementRef.current) {
    //   let position = topMargin;
    //   alert(elementRef.current?.clientHeight);
    //   // Chia nội dung thành nhiều trang nếu cần
    //   while (position < elementRef.current.clientHeight) {
    //     const canvas = await html2canvas(elementRef.current, {
    //       width: usableWidth,
    //       x: leftMargin,
    //       y: position,
    //       height: usableHeight,
    //     });

    //     const imgData = canvas.toDataURL("image/png");
    //     if (position !== topMargin) {
    //       pdf.addPage(); // Thêm trang mới
    //     }
    //     pdf.addImage(
    //       imgData,
    //       "PNG",
    //       leftMargin,
    //       topMargin,
    //       usableWidth,
    //       usableHeight
    //     );
    //     position += usableHeight; // Di chuyển đến vị trí kế tiếp
    //   }
    // }
    // pdf.save("document.pdf");
    // } catch (error) {
    //   let errorMessage =
    //     "Không kết nối được máy chủ. Vui lòng kiểm tra lại đường truyền.";

    //   if (error instanceof Error) {
    //     errorMessage = error.message;
    //   } else if (typeof error === "string") {
    //     errorMessage = error;
    //   }

    //   toast.error(errorMessage);
    // } finally {
    //   setIsLoading(false);
    // }

    // const pdf = new jsPDF("p", "px", "a4");
    // var width = pdf.internal.pageSize.getWidth();
    // var height = pdf.internal.pageSize.getHeight();
    // const topMargin = 0;
    // const bottomMargin = 0;
    // const leftMargin = 0;
    // const rightMargin = 0;
    // const usableWidth = width - leftMargin - rightMargin;
    // const usableHeight = height - topMargin - bottomMargin;
    // if (elementRef.current) {
    //   let position = topMargin;
    //   alert(elementRef.current?.clientHeight);
    //   // Chia nội dung thành nhiều trang nếu cần
    //   while (position < elementRef.current.clientHeight) {
    //     const canvas = await html2canvas(elementRef.current, {
    //       width: usableWidth,
    //       x: leftMargin,
    //       y: position,
    //       height: usableHeight,
    //     });

    //     const imgData = canvas.toDataURL("image/png");
    //     if (position !== topMargin) {
    //       pdf.addPage(); // Thêm trang mới
    //     }
    //     pdf.addImage(
    //       imgData,
    //       "PNG",
    //       leftMargin,
    //       topMargin,
    //       usableWidth,
    //       usableHeight
    //     );
    //     position += usableHeight; // Di chuyển đến vị trí kế tiếp
    //   }
    // }
    // pdf.save("document.pdf");

    // if (pdf) {
    //   const accessToken = localStorage.getItem("accessToken");
    //   const pdfBlob = pdf.output("blob");
    //   const formData = new FormData();
    //   formData.append("FileName", pdfBlob, "report.pdf");

    //   try {
    //     await ServiceUtils.postFormDataV2(
    //       `${BAO_CAO_SENT}?ID=${taskId}&ReportHistoryID=${dataHistoryID}`,
    //       formData,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + accessToken,
    //           Accept: "text/plain",
    //         },
    //       }
    //     ).then((res: any) => {
    //       if (res.data.StatusCode === 200) {
    //         toast.success("Gửi báo cáo thành công");
    //         handleClose();
    //       } else {
    //         toast.error(res.data.Message);
    //       }
    //     });
    //   } catch (error) {
    //     toast.error(
    //       "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền."
    //     );
    //   }
    // } else {
    //   toast.error("Quá trình chuyển đổi PDF không thành công!");
    // }

    //   const accessToken = localStorage.getItem("accessToken");
    //   const pdfBlob = pdf.output("blob");
    //   const formData = new FormData();
    //   formData.append("FileName", pdfBlob, "report.pdf");
    //   try {
    //     await ServiceUtils.postFormDataV2(
    //       `${BAO_CAO_SENT}?ID=${taskId}&ReportHistoryID=${dataHistoryID}`,
    //       formData,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + accessToken,
    //           Accept: "text/plain",
    //         },
    //       }
    //     ).then((res: any) => {
    //       if (res.data.StatusCode === 200) {
    //         toast.success("Gửi báo cáo thành công");
    //         handleClose();
    //       } else {
    //         toast.error(res.data.Message);
    //       }
    //     });
    //   } catch (error: any) {
    //     toast.error(
    //       "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    //     );
    //   }
    // }

    // var doc = new jsPDF();
    // var elementHTML = document.querySelector("#pdf");
    // doc.addFont("Roboto", "Comic Sans", "normal");
    // doc.setFont("Roboto");
    // doc.html(elementHTML, {
    //   callback: function (doc) {
    //     doc.save("document.pdf");
    //   },
    //   margin: [0, 0, 0, 0],
    //   autoPaging: "text",
    //   x: 0,
    //   y: 0,
    //   width: 190,
    //   windowWidth: 675,
    // });
  };

  // funcs
  const _onChangeReportDescriptionFuncs = (e: any, TaskID: string) => {
    const tempValue = dataHistory;
    const tempArray = [...dataHistory?.report_history];
    const tempObject = tempArray.find((item) => item.TaskID === TaskID);

    tempObject.Description = e.target.value;

    setDataHistory({ ...tempValue, report_history: tempArray });

    // setDataHistory((prevState: any) => {
    //   return prevState?.map((item: any) => {
    //     return {
    //       ...item,
    //       report_history: tempArray
    //     }
    //   })
    // })
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(READ_REPORT_NGUOINHAN + taskId, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setDataHistory(
                res.data.Data.report_history?.find(
                  (item: any) => item.ReportID === taskId
                )
              );
              setTemplate(res.data.Data.report_details?.ReportTemplateID);
              setData(res.data.Data);

              // console.log("res Data", data)
              // console.log("res history", dataHistory);
              // console.log("res template", template);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  // onUpdate
  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;

        if (typedDomNode.attribs) {
          if (data?.report_details?.TypeReportJob === "1") {
            if (typedDomNode.attribs.id === "reportDay") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "2") {
            if (typedDomNode.attribs.id === "reportMonth") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "3") {
            if (typedDomNode.attribs.id === "reportQuarter") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "4") {
            if (typedDomNode.attribs.id === "reportYear") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "5") {
            if (typedDomNode.attribs.id === "reportCreateOwnwer") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          }
        }

        switch (typedDomNode?.attribs?.id) {
          case "nameStaff":
            return (
              <span className="font-normal">
                {data?.report_details?.NameReportUser}
              </span>
            );
          case "levelStaff":
            return (
              <span className="font-normal">
                {data?.report_details?.CHUCVU}
              </span>
            );
          case "phongBanStaff":
            return (
              <span className="font-normal">
                {data?.report_details?.PHONGBAN}
              </span>
            );

          case "workingCapacity":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGSUAT}%
              </span>
            );
          case "supportCapacity":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGSUATPHOIHOP}%
              </span>
            );
          case "workingLevel":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGLUC}%
              </span>
            );
          case "supportLevel":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGLUCPHOIHOP}%
              </span>
            );

          case "perfectResult":
            return (
              <span>{data?.report_result[0]?.Recipient_NumberExcellent}</span>
            );
          case "goodResult":
            return <span>{data?.report_result[0]?.Recipient_NumberGood}</span>;
          case "aveResult":
            return (
              <span>{data?.report_result[0]?.Recipient_NumberMedium}</span>
            );
          case "badResult":
            return <span>{data?.report_result[0]?.Recipient_NumberFail}</span>;
            
          case "perfectResult1":
            return (
              <span>{data?.report_result[0]?.Supporter_NumberExcellent}</span>
            );
          case "goodResult1":
            return <span>{data?.report_result[0]?.Supporter_NumberGood}</span>;
          case "aveResult1":
            return (
              <span>{data?.report_result[0]?.Supporter_NumberMedium}</span>
            );
          case "badResult1":
            return <span>{data?.report_result[0]?.Supporter_NumberFail}</span>;

          case "receiverTask":
            return <span>{data?.report_result[0]?.DaNhan}</span>;
          case "completeTask":
            return <span>{data?.report_result[0]?.HoanTat}</span>;
          case "supportTask":
            return <span>{data?.report_result[0]?.HoTro}</span>;

          case "bonusOrPayoff":
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: bonusOrPayoff,
                }}
              />
            );
          case "planOfAction":
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: planOfAction,
                }}
              />
            );

          case "phongBanBoss":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0]?.PHONGBAN}
              </span>
            );
          case "levelBoss":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0]?.CHUCVU}
              </span>
            );
          case "taskOwner":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0]?.NameReviewer}
              </span>
            );
          case "bodyOfTaskResponsible":
            return (
              <>
                <tbody>
                  {data?.report_task?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td style={{ border: "2px solid black", height: "2rem" }}>
                        {index + 1}
                      </td>
                      <td style={{ border: "2px solid black" }}>
                        <p className="font-semibold">{item.TaskName}</p>
                        <p>
                          {item.IsRecipient ? " Thực hiện chính" : " Hỗ trợ"}
                        </p>
                        <p>
                          Người giao: <span>{item.TaskOwnerName}</span>
                        </p>
                        <p>
                          Ngày tạo: {moment(item.DateAdd).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          Thời hạn: {moment(item.DeadLine).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          Trạng thái:{" "}
                          {item.TaskStatus === 3 ? (
                            <span className="text-gray-500 font-bold">
                              Chưa xử lý
                            </span>
                          ) : item.TaskStatus === 4 ? (
                            item.FinishedDate !== null ? (
                              <span className="text-green-500 font-bold">
                                Chờ duyệt
                              </span>
                            ) : (
                              <span className="text-amber-500 font-bold">
                                Đang xử lý
                              </span>
                            )
                          ) : item.TaskStatus === 5 ? (
                            <span className="text-teal-500 font-bold">
                              Hoàn tất
                            </span>
                          ) : item.TaskStatus === 2 ? (
                            <span className="text-gray-500 font-bold">
                              Đã giao
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>
                          Kết quả: <span>{item.TaskScorecardName}</span>
                        </p>
                      </td>
                      <td
                        className="w-1/2 h-full"
                        style={{ border: "2px solid black" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dataHis?.report_history[index]?.Description,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            );
        }
      },
    };

    setOptionParser(options);
  }, [data]);

  // memo
  const dataBodyReader = useMemo(() => {
    const tempData = data?.report_history?.find(
      (item: any) => item.ReportID === taskId
    );
    return tempData;
  }, [data, taskId]);

  // Funcs
  const _onClickCreateContent = () => {
    console.log("object, ", renderToString(<FinalJSX />));
    const accessToken = localStorage.getItem("accessToken");

    const takeData = async () => {
      const body: any = {
        ReportID: taskId,
        Content: renderToString(<FinalJSX />),
      };
      try {
        await ServiceUtils.postV2(UPDATE_BAO_CAO, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Lưu nội dung thành công");
          } else {
            toast.success("Lưu nội dung thất bại");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };

  return (
    <>
      <div
        className="
          flex flex-col bg-white
          lg:w-[590px] w-screen h-[95vh] 
          rounded-md overflow-x-auto
        "
      >
        <SubModal
          taskId={taskId}
          open={openSub}
          handleClose={handleCloseSub}
          funcs={funcsSub}
        />
        {/* Headers */}
        <div
          className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
    "
        >
          <p>Biểu mẫu báo cáo</p>
          <button
            className="hover:bg-slate-300 p-0.5 rounded-md"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Content */}
        {data === null ? (
          <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
            <LoadingControl />
          </div>
        ) : (
          <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-y-scroll styled-scrollbars relative">
            <div className="w-full h-max flex">
              <div className="h-max w-max flex justify-center lg:mx-auto">
                {data === null || dataHistory === null || template === null ? (
                  <></>
                ) : (
                  <FinalJSX />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="flex w-full p-12-16 footer rounded-b-md items-center text-sm space-x-4">
          <div className="flex flex-wrap w-full h-max gap-2 justify-end items-center core-edit-report-input-form-group-button">
            {/* Close button */}

            {isLoading ? (
              <p className="text-primary">
                Quá trình chuyển đổi PDF và gửi báo cáo có thể mất vài phút.
              </p>
            ) : (
              <>
                {/* <button
                  className={`flex font-bold items-center space-x-1 w-max h-max p-2 border rounded-md ${
                    isLoading
                      ? "border-primary/50 text-primary/50 hover:bg-white/50 "
                      : "hover:text-white border-primary hover:bg-primary text-primary transition-all delay-65 drop-shadow-lg"
                  }`}
                  onClick={_onClickReportDelay}
                  disabled={isLoading}
                >
                  <MoreTimeIcon fontSize="small" />
                  <p>Xin gia hạn</p>
                </button> */}
                <button
                  className={`
              flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
              hover:bg-sky-400 ${
                isLoading && "bg-primary/50 hover:bg-primary/50"
              }`}
                  onClick={() => _onClickUpdateReport(true)}
                  disabled={isLoading}
                >
                  <SaveIcon fontSize="small" />
                  <p>Lưu báo cáo</p>
                </button>
              </>
            )}
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
                hover:bg-green-400 ${
                  isLoading && "bg-green-500/50 hover:bg-green-500/50"
                }`}
              onClick={_onClickSendReport}
              disabled={isLoading}
            >
              {isLoading ? (
                <LoaderSpinner w={20} h={20} />
              ) : (
                <>
                  <SendIcon fontSize="small" />
                  <p>Gửi báo cáo</p>
                </>
              )}
            </button>
            <button
              className="
                flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
                hover:bg-slate-200
              "
              onClick={handleClose}
              // onClick={() => console.log(dataHistory)}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
        {/* Footer */}
      </div>
    </>
  );
};
export default SubViewReport;
