import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ONCHANGE_NGUOI_NHAN_DA_NHAN } from "../../../redux/reducers/workingReducer/_index";

import FlagIcon from '@mui/icons-material/Flag';
import { NGUOI_NHAN_VIEC_BAT_DAU, NGUOI_NHAN_VIEC_LIST_RECEIVED, REMIND_TASK } from "../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

import NotificationsIcon from '@mui/icons-material/Notifications';

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import { Task_Remind } from "../../../action/taskOptionsAction"

import LoaderSpinner from "../../loader-spinner/_index";


const NotiTask = (props: any) => {

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const urlLink = window.location.pathname
  const shouldLog = useRef(true)

  const data = useSelector((state: any) => state.workingReducer)

  const _onClickNotiTask = async () => {

    setIsLoading(true)

    Task_Remind(taskId).then((res: any) => {
      const data = (res || {}).data || {}
      if (data.RCode === 200) {
        if (handleClose) {
          handleClose();
        }
        setIsLoading(false)
        toast.success("Đã nhắc nhở công việc")
      } else {
        if (handleClose) {
          handleClose();
        }
        setIsLoading(false)
        toast.error(data.RMessage || 'Nhắc nhở công việc thất bại!')
      }

    }).catch((err: any) => {
      if (handleClose) {
        handleClose();
      }
      setIsLoading(false)
      toast.error(err || 'Nhắc nhở công việc thất bại!')
    })

  }

  return (

    <div className="
      flex bg-amber-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    ">

      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <NotificationsIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý nhắc nhở công việc này?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button className={`w-[5rem] h-max p-2 rounded-md ${isLoading === true ? "bg-slate-300" : "bg-slate-500  hover:bg-slate-400"}`}
            onClick={_onClickNotiTask}
            disabled={isLoading}
          >
            {
              isLoading === true ?
              <div className="flex w-full h-full justify-center items-center">
                <LoaderSpinner w={20} h={20} />
              </div>
              :
              <p>Có</p>
            }

          </button>
          <button className={`w-[5rem] h-max p-2 rounded-md ${isLoading === true ? "bg-red-300" : "bg-red-500 hover:bg-red-400"}`}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotiTask;
