import React, { memo, useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ONSET_NAVBAR_BUTTON_AT_FIRST } from "../../../redux/reducers/_appReducer/_index";
import {
  ONCHANGE_DRIVE,
  ONCHANGE_NAVBAR_OPTIONS,
  ONCHANGE_NAVBAR_RESPONSIVE,
  ONCLOSE_DRIVE,
  ONOPEN_DRIVE,
} from "../../../redux/reducers/systemReducer/index";
// menu images
import home from "../../../assets/menu/Home.svg";
import report from "../../../assets/menu/BAOCAO.svg";
import setting from "../../../assets/menu/CAIDAT.svg";
import propose from "../../../assets/menu/DEXUAT.svg";
import leaveImg from "../../../assets/menu/leave.png";
import permission from "../../../assets/menu/PHANQUYEN.svg";
import adding from "../../../assets/menu/THEMVIEC.svg";
import working from "../../../assets/menu/CONGVIEC.svg";
import folderImg from "../../../assets/menu/folder.png";
import financeImg from "../../../assets/menu/finance.png";
import createTaskMyself from "../../../assets/menu/createTaskMyself.png";

import { ModalController } from "../../homeUI/_index";

// import { takePermission } from "../../../funcs";

// // icon bàn làm viẹc
// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// // icon phân quyền
// import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
// // icon thiết lập
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// // icon công việc
// import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
// // icon báo cáo
// import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
// // icon đề xuất
// import LightbulbIcon from "@mui/icons-material/Lightbulb";

// import NoteAddIcon from '@mui/icons-material/NoteAdd';

import LoaderSpinner from "../../../components/loader-spinner/_index";
import { getUserID } from "../../../utils/users/userUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { fetchMenuFolder } from "../../../components/fileComponent/utils/helper";
import { FOLDER_IDS } from "../../../constants/dataConstants";
import DeviceUtils from "../../../utils/devices/deviceUtils";
import ServiceUtils from "../../../utils/services";
import { CONFIG_IS_FORCE } from "../../../apis/_index";

const Navbar = () => {
  const context = useContext(ModalController);

  const urlLink = window.location.pathname;

  const [isCreateTask, setIsCreateTask] = useState<any | null>(null);
  const [isCreateTaskMySelf, setIsCreateTaskMySelf] = useState<boolean | null>(
    null
  );

  const navigate = useNavigate();

  const shouldLog = useRef(true);

  // const menu = takePermission(20)

  // redux
  // const data = useSelector((state: any) => state.appReducer);
  const systemData = useSelector((state: any) => state.systemReducer);
  const dispatch = useDispatch();

  const [drives, setDrives] = useState<any[]>([]);

  const [posts, setPosts] = useState([]);
  // funcs

  useEffect(() => {
    if (posts.length) {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition, 10));
        sessionStorage.removeItem("scrollPosition");
      }
    }
  }, [posts]);

  useEffect(() => {
    const takeData = async () => {
      try {
        await ServiceUtils.getV2(CONFIG_IS_FORCE, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.status === 200) {
            setIsCreateTaskMySelf(res?.data?.Data[0]?.IsCreateTask);
          }
        });
      } catch (error: any) {}
    };

    takeData();
  }, []);

  const _onChangeNavbarOptions = (id: string) => {
    dispatch(ONCHANGE_NAVBAR_OPTIONS(id));
  };

  const _onChangeNavbarOptionsResponsive = (id: string) => {
    dispatch(ONCHANGE_NAVBAR_OPTIONS(id));
    dispatch(ONCHANGE_NAVBAR_RESPONSIVE());

    dispatch(ONCHANGE_DRIVE([]));

    dispatch(ONCLOSE_DRIVE());
  };

  const _onClickCreateNewTask = () => {
    context.setFuncs("createNewTask");
    context.handleOpen();
  };

  const _onClickCreateTaskMyself = () => {
    context.setFuncs("createTaskMyself");
    context.handleOpen();
  };

  const RenderMenuWeb = (props: any) => {
    const title = props.title === "Administrator" ? "Phân quyền" : props.title;
    const id = props.id;
    const jsx = props.jsx;
    const url = props.url;
    const index = props.index;
    return (
      <button
        className={`
        flex flex-col justify-center items-center w-full 
      text-slate-500 border-l-4 py-2
      ${
        systemData.showingMenu === id.toString()
          ? "border-sky-400 text-sky-400 shadow-md shadow-slate-400 transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500"
          : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
      }
      `}
        onClick={() => {
          if (id == "85") navigate("/leave/calendar");
          _onChangeNavbarOptions(id.toString());

          dispatch(ONCHANGE_DRIVE([]));

          dispatch(ONCLOSE_DRIVE());
        }}
      >
        {jsx}
        <p
          className={`${
            systemData.showingMenu === id.toString() ? "text-primary" : ""
          } text-sm`}
        >
          {title}
        </p>
      </button>
    );
  };

  const RenderMenuMobile = (props: any) => {
    const title = props.title === "Administrator" ? "Phân quyền" : props.title;
    const id = props.id;
    const jsx = props.jsx;
    const url = props.url;
    const index = props.index;

    return (
      <button
        className={`
      flex flex-col justify-center items-center w-full h-[3.5rem]
    text-slate-500 border-l-4 
    ${
      systemData.showingMenu === id.toString()
        ? "border-sky-400 text-primary shadow-lg  active:border-sky-600 active:shadow-inner active:shadow-slate-500"
        : "border-slate-100  active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner"
    }
    `}
        onClick={() => _onChangeNavbarOptionsResponsive(id.toString())}
      >
        {jsx}
      </button>
    );
  };
  const accessToken = localStorage.getItem("accessToken");
  const stringUrl = "https://isolaco-hd.isopro.vn/?access_token=" + accessToken;

  useEffect(() => {
    if (systemData.subMenu.length === 0) {
      // do nothing
    } else {
      if (shouldLog.current === true) {
        shouldLog.current = false;

        setIsCreateTask(
          systemData.subMenu.find((item: any) => item.FormId === 20)?.PerInsert
        );
      }
    }
  }, [systemData.subMenu]);

  const onOpenDrive = () => {
    dispatch(ONOPEN_DRIVE());

    dispatch(ONCHANGE_NAVBAR_OPTIONS("true"));

    dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST("true"));

    dispatch(ONCHANGE_NAVBAR_RESPONSIVE());

    fetchMenuFolder().then((res: any) => {
      const drives = [
        ...res,
        {
          FolderName: "Thư mục được chia sẻ",
          FolderId: FOLDER_IDS.share,
        },
        {
          FolderName: "Thùng rác",
          FolderId: FOLDER_IDS.trash,
        },
      ];

      dispatch(ONCHANGE_DRIVE(drives));
    });
  };

  const NavbarWebList = () => {
    const header = document.getElementById("header");

    const heightHeader = header?.clientHeight ?? 42; // làm 1 thôi chuyển qua flex rồi
    const IsMobile = DeviceUtils.isMobile();
    return (
      <div
        style={{
          height: `calc(var(--view-height) - ${IsMobile ? 2.7 : 5.2}rem)`,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          maxHeight: "1000px",
        }}
      >
        <button
          className={`
            flex flex-col justify-center items-center w-full
          text-slate-500 border-l-4 py-2
          ${
            systemData.showingMenu === "0"
              ? "border-sky-400 text-sky-400  transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
              : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
          `}
          onClick={() => {
            navigate("/allPage");

            dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST("false"));
          }}
        >
          {" "}
          <div className="w-full h-full flex flex-col justify-center items-center">
            {/* <HomeRoundedIcon
            className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
            fontSize="large"
          /> */}

            <img className="" style={styles.imgNavbar} src={home} alt="" />

            <p
              className={`${
                systemData.showingMenu === "0" ? "text-primary" : ""
              } text-sm`}
            >
              Bàn làm việc
            </p>
          </div>
        </button>
        {isCreateTaskMySelf && (
          <button
            className={`
                    flex flex-col justify-center items-center w-full 
                  text-slate-500 border-l-4 py-2
                  ${
                    systemData.showingMenu === "0"
                      ? "border-sky-400 text-sky-400 transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
                      : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
                  }
            `}
            onClick={() => _onClickCreateTaskMyself()}
          >
            {" "}
            <div className="w-full h-full flex flex-col justify-center items-center">
              <img
                className=""
                style={styles.imgNavbar}
                src={createTaskMyself}
                alt=""
              />

              <p
                className={`${
                  systemData.showingMenu === "0" ? "text-primary" : ""
                } text-sm`}
              >
                Tự tạo công việc
              </p>
            </div>
          </button>
        )}

        {isCreateTask === null ? (
          <></>
        ) : isCreateTask === undefined || isCreateTask === false ? (
          <></>
        ) : (
          <button
            className={`
                    flex flex-col justify-center items-center w-full 
                  text-slate-500 border-l-4 py-2
                  ${
                    systemData.showingMenu === "0"
                      ? "border-sky-400 text-sky-400 transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
                      : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
                  }
      `}
            onClick={() => _onClickCreateNewTask()}
          >
            {" "}
            <div className="w-full h-full flex flex-col justify-center items-center">
              {/* <HomeRoundedIcon
            className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
            fontSize="large"
          /> */}

              <img className="" style={styles.imgNavbar} src={adding} alt="" />

              <p
                className={`${
                  systemData.showingMenu === "0" ? "text-primary" : ""
                } text-sm`}
              >
                Tạo công việc
              </p>
            </div>
          </button>
        )}

        {systemData.mainMenu.map((item: any, index: any) => {
          return (
            <RenderMenuWeb
              index={index}
              key={index}
              title={item.FormTitle}
              id={item.FormId}
              jsx={
                item.FormId === 1 ? (
                  <img
                    className=""
                    style={styles.imgNavbar}
                    src={permission}
                    alt=""
                  />
                ) : item.FormId === 2 ? (
                  <img
                    className=""
                    style={styles.imgNavbar}
                    src={setting}
                    alt=""
                  />
                ) : item.FormId === 3 ? (
                  <img
                    className=""
                    style={styles.imgNavbar}
                    src={working}
                    alt=""
                  />
                ) : item.FormId === 4 ? (
                  <img
                    className=""
                    style={styles.imgNavbar}
                    src={report}
                    alt=""
                  />
                ) : item.FormId === 59 ? (
                  <img
                    className=""
                    style={styles.imgNavbar}
                    src={propose}
                    alt=""
                  />
                ) : item.FormId === 85 ? (
                  <img
                    className=""
                    style={styles.imgNavbar}
                    src={leaveImg}
                    alt=""
                  />
                ) : (
                  ""
                )
              }
              url={
                item.FormId === 1
                  ? "user"
                  : item.FormId === 2
                  ? "setting"
                  : item.FormId === 3
                  ? "working"
                  : item.FormId === 4
                  ? "report"
                  : item.FormId === 59
                  ? "propose"
                  : item.FormId === 85
                  ? "leave"
                  : item.FormId === 92
                  ? "folder"
                  : ""
              }
            />
          );
        })}
        <button
          className={`
            flex flex-col justify-center items-center w-full 
          text-slate-500 border-l-4 py-2
          ${
            systemData.showingMenu === "0"
              ? "border-sky-400 text-sky-400  transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
              : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
          `}
          onClick={() => {
            dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST("false"));
            window.location.href = stringUrl;
          }}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img
              className=""
              style={styles.imgNavbar}
              src={financeImg}
              alt=""
            />

            <p
              className={`${
                systemData.showingMenu === "0" ? "text-primary" : ""
              } text-sm`}
            >
              Tài chính
            </p>
          </div>
        </button>
        <button
          className={`
            flex flex-col justify-center items-center w-full 
          text-slate-500 border-l-4 py-2
          ${
            systemData.showingMenu === "0"
              ? "border-sky-400 text-sky-400  transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
              : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
          `}
          onClick={() => {
            onOpenDrive();
          }}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img className="" style={styles.imgNavbar} src={folderImg} alt="" />

            <p
              className={`${
                systemData.showingMenu === "0" ? "text-primary" : ""
              } text-sm`}
            >
              Laco Drive
            </p>
          </div>
        </button>
      </div>
    );
  };

  const NavbarMobileList = () => {
    const header = document.getElementById("header");

    const heightHeader = header?.clientHeight ?? 42; // làm 1 thôi chuyển qua flex r

    // flex flex-col
    return (
      <div
        className=""
        style={{
          height: `calc(100% - 1px)`,
          overflowY: "auto",
          paddingRight: "5px",
        }}
      >
        <button
          className={`
          flex flex-col justify-center items-center w-full h-[3.5rem]
        text-slate-500 border-l-4 
        ${
          systemData.showingMenu === "0"
            ? "border-sky-400 text-primary"
            : "border-slate-100 active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner"
        }
        `}
          onClick={() => {
            navigate("/allPage");
          }}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            {/* <HomeRoundedIcon
              className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
              fontSize="large"
            />  */}
            <img className="w-[2rem] h-[2rem]" src={home} alt="" />
          </div>
        </button>

        <button
          className={`
            flex flex-col justify-center items-center w-full h-[3.5rem]
            text-slate-500 border-l-4
            ${
              systemData.showingMenu === "0"
                ? "border-sky-400 text-primary"
                : "border-slate-100 active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner "
            }
            `}
          onClick={() => _onClickCreateTaskMyself()}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img className="w-[2rem] h-[2rem]" src={createTaskMyself} alt="" />
          </div>
        </button>

        {isCreateTask === null ? (
          <></>
        ) : isCreateTask === undefined || isCreateTask === false ? (
          <></>
        ) : (
          <button
            className={`
            flex flex-col justify-center items-center w-full h-[3.5rem]
            text-slate-500 border-l-4
            ${
              systemData.showingMenu === "0"
                ? "border-sky-400 text-primary"
                : "border-slate-100 active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner "
            }
            `}
            onClick={() => _onClickCreateNewTask()}
          >
            <div className="w-full h-full flex flex-col justify-center items-center">
              {/* <HomeRoundedIcon
                  className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
                  fontSize="large"
                />  */}
              <img className="w-[2rem] h-[2rem]" src={adding} alt="" />
            </div>
          </button>
        )}

        {systemData.mainMenu.map((item: any, index: any) => (
          <RenderMenuMobile
            index={index}
            key={index}
            title={item.FormTitle}
            id={item.FormId}
            jsx={
              item.FormId === 1 ? (
                <img className="w-[2rem] h-[2rem]" src={permission} alt="" />
              ) : item.FormId === 2 ? (
                <img className="w-[2rem] h-[2rem]" src={setting} alt="" />
              ) : item.FormId === 3 ? (
                <img className="w-[2rem] h-[2rem]" src={working} alt="" />
              ) : item.FormId === 4 ? (
                <img className="w-[2rem] h-[2rem]" src={report} alt="" />
              ) : item.FormId === 59 ? (
                <img className="w-[2rem] h-[2rem]" src={propose} alt="" />
              ) : item.FormId === 85 ? (
                <img className="w-[2rem] h-[2rem]" src={leaveImg} alt="" />
              ) : (
                ""
              )
            }
            url={
              item.FormId === 1
                ? "user"
                : item.FormId === 2
                ? "setting"
                : item.FormId === 3
                ? "working"
                : item.FormId === 4
                ? "report"
                : item.FormId === 59
                ? "propose"
                : item.FormId === 85
                ? "leave"
                : ""
            }
          />
        ))}
        <button
          className={`
            flex flex-col justify-center items-center w-full h-[3.5rem]
          text-slate-500 border-l-4
          ${
            systemData.showingMenu === "0"
              ? "border-sky-400 text-sky-400  transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
              : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
          `}
          onClick={() => {
            window.alert("Comming soon...");

            dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST("false"));
          }}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img className="w-[2rem] h-[2rem]" src={financeImg} alt="" />
          </div>
        </button>
        <button
          className={`
            flex flex-col justify-center items-center w-full h-[3.5rem]
          text-slate-500 border-l-4
          ${
            systemData.showingMenu === "0"
              ? "border-sky-400 text-sky-400  transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
              : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
          `}
          onClick={() => {
            onOpenDrive();
          }}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img className="w-[2rem] h-[2rem]" src={folderImg} alt="" />
          </div>
        </button>
      </div>
    );
  };

  // TODO: responsive
  const NavbarWebJSX = () => {
    return (
      <div
        className="lg:flex hidden flex-col w-[6rem] h-full bg-[#fbfbfd] border-r-2"
        id="navbar"
      >
        {systemData.mainMenu.length === 0 || isCreateTask === null ? (
          <div className="w-full h-full flex flex-col pt-3 items-center">
            <LoaderSpinner h={32} w={32} />
            <p className="text-xs text-primary">Đang tải</p>
          </div>
        ) : (
          <NavbarWebList />
        )}
      </div>
    );
  };

  const NavbarMobileJSX = () => {
    return (
      <div
        className="lg:hidden flex flex-col w-[3.5rem] h-full bg-[#fbfbfd] border-r-2"
        id="navbar2"
      >
        {systemData.mainMenu.length === 0 || isCreateTask === null ? (
          <div className="w-full h-full flex flex-col pt-3 items-center">
            <LoaderSpinner h={32} w={32} />
            <p className="text-xs text-primary">Đang tải</p>
          </div>
        ) : (
          <NavbarMobileList />
        )}
      </div>
    );
  };

  return (
    <>
      <NavbarWebJSX />
      <NavbarMobileJSX />
    </>
  );
};

export default memo(Navbar);

const styles = {
  imgNavbar: {
    width: "var(--px-36)",
    height: "var(--px-36)",
  },
};
