import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const UserSumWorkReport = () => {
  return (
    <>
      <HomeUI />
    </>
  )
}

export default UserSumWorkReport
