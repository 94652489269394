import { useContext, useState, useRef } from "react";
import moment from "moment";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";
import { ModalController } from "../../homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import Pagination from "@mui/material/Pagination";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import BlockIcon from "@mui/icons-material/Block";
import { useDispatch, useSelector } from "react-redux";
import {
  ONCHANGE_CURRENT_PAGE,
  ONCHANGE_ITEM_PER_PAGE,
} from "../../../redux/reducers/proposeReducer/_index";

const MobileReceiverPropose = (props: any) => {
  const dispatch = useDispatch();
  const proposeData = useSelector((state: any) => state.proposeReducer);

  const _loadData = props._loadData;

  const context = useContext(ModalController);

  // load all data
  const shouldLogLoadData = useRef(true);
  const [taskId, setTaskId] = useState<string>("");
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const handleCloseSub = () => setOpenSub(false);

  // onClick detail
  const _onClickDetail = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeDetail");
    context.setTaskId(id);
  };

  // onClick confirm
  const _onClickConfirm = (id: string) => {
    context.handleOpen();
    context.setTaskId(id);
    context.setFuncs("proposeConfirm");
  };

  // onClick unConfirm
  const _onClickUnConfirm = (id: string) => {
    context.handleOpen();
    context.setTaskId(id);
    context.setFuncs("proposeUnConfirm");
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_CURRENT_PAGE(value - 1));
  };

  return (
    <div className="mobile-table-propose">
      <ModalBase
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
        _loadData={_loadData}
      />

      {/* page selection */}
      <div className="flex w-max h-max pb-2">
        <p>Xem</p>
        <select
          className="border-2 border-slate-200 rounded-md"
          value={proposeData.pagination.itemPerPage}
          onChange={(e: any) =>
            dispatch(ONCHANGE_ITEM_PER_PAGE(e.target.value))
          }
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
        <p>dòng</p>
      </div>
      <table className="w-full">
        <thead className="text-white font-semibold text-center w-full h-[2rem]">
          <tr>
            <th className="w-1/12">Danh sách đề xuất</th>
          </tr>
        </thead>
        <tbody>
          {proposeData.data?.length !== 0 ? (
            <>
              {proposeData.data?.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="trang-thai-moi border border-slate-300">
                    <p className="font-bold">
                      {index +
                        1 +
                        proposeData.pagination.currentPage *
                          proposeData.pagination.itemPerPage}
                      .&nbsp;{item.Name}
                    </p>
                    <div>
                      <span className="text-xs">Người nhận: </span>
                      <span className="font-semibold">
                        {item.UserCheckName}
                      </span>
                    </div>
                    <div>
                      <span className="text-xs">Người gửi: </span>
                      <span className="font-semibold">{item.UserAddName}</span>
                    </div>

                    {item.ListNamePropose && (
                      <p>
                        <span className="text-xs">Hỗ trợ: </span>
                        <span className="text-slate-600">
                          {item.ListNamePropose}
                        </span>
                      </p>
                    )}
                    <div>
                      <span className="text-xs">Ngày gửi:&nbsp;</span>
                      <span className="italic">
                        {moment(item.DateAdd).format("DD/MM/YYYY HH:mm")}
                      </span>
                    </div>
                    <div>
                      {item.IsUseTime === true ? (
                        <>
                          <span className="text-xs">Thời gian thực hiện: </span>
                          <span className="italic">
                            {moment(item.StartTime).format("DD/MM/YYYY hh:mm")}
                            &nbsp;-&nbsp;
                            {moment(item.EndTime).format("DD/MM/YYYY hh:mm")}
                          </span>
                        </>
                      ) : (
                        <>Đề xuất không thời hạn</>
                      )}
                    </div>
                    <div className="flex justify-between items-center pt-1.5">
                      <p className="text-xs">
                        {item?.Status === 0 ? (
                          <span className="p-1 text-yellow-500 rounded-md border border-yellow-500">
                            Chưa duyệt
                          </span>
                        ) : item?.Status === 1 ? (
                          <span className="p-1 text-green-500 rounded-md border border-green-500">
                            Đã duyệt
                          </span>
                        ) : (
                          <span className="p-1 text-red-500 rounded-md border border-red-500">
                            Không duyệt
                          </span>
                        )}
                      </p>

                      <div className="items-center h-full lg:hidden flex pl-3">
                        <div className="dropdown2">
                          <button
                            className="dropbtn2 none-outline"
                            onClick={() => _onClickFuncs(index.toString())}
                          >
                            ...
                          </button>

                          <div
                            id={index.toString()}
                            className="dropdown-content2 text-xs font-semibold"
                          >
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickDetail(item.ID)}
                            >
                              <div className="flex w-full h-full space-x-1">
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />
                                <div>Chi tiết</div>
                              </div>
                            </button>
                            {item?.Status === 0 && (
                              <>
                                <button
                                  className="flex w-full h-full hover:text-green-500"
                                  onClick={() => _onClickConfirm(item.ID)}
                                >
                                  <div className="flex w-full h-full space-x-1">
                                    <CheckIcon
                                      className="text-green-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />
                                    <div>Duyệt</div>
                                  </div>
                                </button>
                                <button
                                  className="flex w-full h-full hover:text-red-500"
                                  onClick={() => _onClickUnConfirm(item.ID)}
                                >
                                  <div className="flex w-full h-full space-x-1">
                                    <CancelIcon
                                      className="text-red-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />
                                    <div>Không duyệt</div>
                                  </div>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <></>
          )}
        </tbody>
      </table>

      {/* pagination */}
      <div className="flex py-2 lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
        <Pagination
          count={proposeData.pagination.totalPage}
          variant="outlined"
          color="primary"
          size="small"
          page={proposeData.pagination.currentPage + 1}
          onChange={_onChangePaginate}
        />
      </div>
    </div>
  );
};

export default MobileReceiverPropose;
