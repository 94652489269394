import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import HistoryIcon from "@mui/icons-material/History";
import SendIcon from "@mui/icons-material/Send";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import { USER_READ_ALL_DROPDOWN } from "../../../apis/_index";

import { CHECK_RESULT } from "../../../apis/_index";
import {
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  SENT_MESSAGE,
  NGUOI_NHAN_FILE_STEP_UPLOAD,
} from "../../../apis/_index";
import CloseIcon from "@mui/icons-material/Close";
import LoaderSpinner from "../../loader-spinner/_index";

import { NGUOI_GIAO_VIEC_DETAIL } from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ServiceUtils from "../../../utils/services";

const CompleteTask = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);

  const appData = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  const [task, setTask] = useState<any>(null);
  const [taskContents, setTaskContents] = useState<any>(null);
  const [taskResults, setTaskResults] = useState<any>(null);
  const [taskInfoText, setTaskInfoText] = useState<any>(null);

  const [text, setText] = useState<string>("");

  const [fileListAPI, setFileListAPI] = useState<any>(null);
  const [fileListAPI2, setFileListAPI2] = useState<any[]>([]);
  const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);

  const [filesList, setFilesList] = useState<any>([]);
  const [filesList2, setFilesList2] = useState<any>([]);
  const [selectedTypeOf, setSelectedTypeOf] = useState<number>(0);
  const [isViewHistoty, setIsViewHistoty] = useState<any>(null);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any>(null);
  const [_taskContents, set_TaskContents] = useState<any[]>([]);

  const urlLink = window.location.pathname;

  // funcs
  const _onChangeMultipleChoicePartner = (
    text: string,
    item: any = null,
    isViewHistoty: any = null
  ) => {
    handleOpenSub();
    if (
      text === "completedTaskContent" ||
      text === "multipleUploadFileContent"
    ) {
      setTaskContentHistoryId(item);
    }
    if (isViewHistoty === 1) {
      setIsViewHistoty(1);
    } else {
      setIsViewHistoty(0);
    }
    setFuncsSub(text);
  };

  const _onClickSubModal = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onCheckResult = (id: any, taskId: any, checked: boolean) => {
    let resultChecking: boolean;

    if (checked === true) {
      resultChecking = false;
    } else {
      resultChecking = true;
    }
    const checkResult = async () => {
      const accessToken = localStorage.getItem("accessToken");

      const body: any = {
        ID: id,
        TaskID: taskId,
        Checked: resultChecking,
      };

      try {
        await ServiceUtils.postV2(CHECK_RESULT, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.status === 200) {
            const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

            const takeData = async () => {
              try {
                await ServiceUtils.getV2(API, {
                  headers: {
                    Accept: "text/plain",
                  },
                }).then((res: any) => {
                  if (res.status === 200) {
                    setTask(res.data.Data.task);
                    setTaskContents(res.data.Data.taskContents);
                    setTaskResults(res.data.Data.taskResults);
                    setTaskInfoText(res.data.Data.taskInfoText);

                    const fileListResponse =
                      res.data.Data.task.ListFiles.split(";");
                    const fileListNameResponse =
                      res.data.Data.task.ListFilesName.split(",");

                    let tempFileArray: any[] = [];

                    fileListNameResponse.forEach((item: any, index: any) => {
                      let tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };

                      tempFileArray.push(tempObject);
                    });

                    setFileListAPI(tempFileArray);

                    const fileListResponse2 =
                      res.data.Data.task.ResultFiles.split(";");
                    const fileListNameResponse2 =
                      res.data.Data.task.ResultFilesName.split(",");

                    let tempFileArray2: any[] = [];

                    fileListNameResponse2.forEach((item: any, index: any) => {
                      let tempObject = {
                        listFile: fileListResponse2[index],
                        listFileName: item,
                      };

                      tempFileArray2.push(tempObject);
                    });

                    setFileListAPI2(tempFileArray2);
                  }
                });
              } catch (error: any) {}
            };

            takeData();
          }
        });
      } catch (error: any) {}
    };

    checkResult();
  };

  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onChangeFileImage2 = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList2] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList2(tempArray);
  };

  const _onClickSentChat = () => {
    if (text === "" && filesList.length) {
      // do nothing
    } else {
      const accessToken = localStorage.getItem("accessToken");

      const formData = new FormData();

      formData.append("TaskID", taskId);
      formData.append("Contents", text);
      formData.append("typeofNote", selectedTypeOf.toString());

      if (filesList) {
        filesList.forEach((item: any, index: any) => {
          formData.append(`File_ListFile`, item);
        });
      }

      const sentCode = async () => {
        try {
          await ServiceUtils.postFormDataV2(SENT_MESSAGE, formData, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "multipart/form-data",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              const API = NGUOI_GIAO_VIEC_DETAIL(taskId);
              const takeData = async () => {
                try {
                  await ServiceUtils.getV2(API, {
                    headers: {
                      Accept: "text/plain",
                    },
                  }).then((res: any) => {
                    if (res.status === 200) {
                      setTask(res.data.Data.task);
                      setTaskContents(res.data.Data.taskContents);
                      setTaskResults(res.data.Data.taskResults);
                      setTaskInfoText(res.data.Data.taskInfoText);

                      const fileListResponse =
                        res.data.Data.task.ListFiles.split(";");
                      const fileListNameResponse =
                        res.data.Data.task.ListFilesName.split(",");

                      let tempFileArray: any[] = [];

                      fileListNameResponse.forEach((item: any, index: any) => {
                        let tempObject = {
                          listFile: fileListResponse[index],
                          listFileName: item,
                        };

                        tempFileArray.push(tempObject);
                      });

                      setFileListAPI(tempFileArray);
                    }
                  });
                } catch (error: any) {}
              };

              takeData();

              setSelectedTypeOf(0);
              setText("");
              setFilesList([]);
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {}
      };

      sentCode();
    }
  };

  const getDetail = () => {
    // const accessToken = localStorage.getItem("accessToken");
    const API = NGUOI_GIAO_VIEC_DETAIL(taskId);
    if (uuTienList === null || doMatList === null) {
    } else {
      if (shouldLog.current) {
        shouldLog.current = false;

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setTask(res.data.Data.task);
                setTaskContents(res.data.Data.taskContents);
                setTaskContentsHistory(res.data.Data.taskContentHistory);
                setTaskResults(res.data.Data.taskResults);
                setTaskInfoText(res.data.Data.taskInfoText);

                const fileListResponse =
                  res.data.Data.task.ListFiles.split(";");
                const fileListNameResponse =
                  res.data.Data.task.ListFilesName.split(";");

                let tempFileArray: any[] = [];

                fileListNameResponse.forEach((item: any, index: any) => {
                  let tempObject = {
                    listFile: fileListResponse[index],
                    listFileName: item,
                  };

                  tempFileArray.push(tempObject);
                });

                setFileListAPI(tempFileArray);

                let fileListResponse2: any[] = [];
                let fileListNameResponse2: any[] = [];
                fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
                fileListNameResponse2 =
                  res.data.Data.task.ResultFilesName.split(";");
                let tempFileArray2: any[] = [];

                fileListNameResponse2.map((item: any, index: any) => {
                  let tempObject: any;
                  if (
                    item !== "" &&
                    item !== null &&
                    typeof item !== "undefined"
                  ) {
                    tempObject = {
                      listFile: fileListResponse2[index],
                      listFileName: item,
                    };
                  }
                  if (tempObject) {
                    tempFileArray2.push(tempObject);
                  }
                });

                setFileListAPI2(tempFileArray2);
              }
            });
          } catch (error: any) {
            // ERROR: here
          }
        };

        takeData();
      }
    }
  };

  const chatContainerRef = useRef<any>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [taskInfoText]);

  useEffect(() => {
    getDetail();
  });

  useEffect(() => {
    if (uuTienList === null || doMatList === null) {
      if (shouldLog2.current) {
        const accessToken = localStorage.getItem("accessToken");
        shouldLog2.current = false;

        const takeUuTien = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setUuTienList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        const takeDoMat = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setDoMatList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        takeUuTien();
        takeDoMat();
      }
    }
  });

  useEffect(() => {
    if (appData.userList === null && shouldLog3.current) {
      shouldLog3.current = false;

      // const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };
      takeData();
    }
  });

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[80rem] w-screen lg:h-[40rem] h-screen
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        isViewHistoty={isViewHistoty}
        taskContentsHistory={taskContentsHistory}
        taskContentHistoryId={taskContentHistoryId}
        taskId={taskId}
        _taskContents={_taskContents}
        taskInfoText={taskInfoText}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Chi tiết</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {task === null ||
      taskContents === null ||
      taskResults === null ||
      taskInfoText === null ||
      appData.userList === null ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <>
          <div className="lg:flex hidden w-full h-[calc(100%-6rem)] overflow-hidden p-4 text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 w-3/5 h-full pr-5 overflow-auto border-r-[1px] border-primary ">
              <p className="font-semibold text-black bg-slate-200 pl-1 pr-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm">
                Mã công việc - {task === null ? "" : task.TaskCode}
              </p>

              {/* elements */}
              <div className="flex flex-col w-full h-max">
                <label>Tên công việc</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={task === null ? "" : task.TaskName}
                  disabled
                />
              </div>

              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.PriorityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Nhập tên công việc"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.SecurityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
              </div>
              {/*  Nguoi phoi hop */}
              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md pt-1 px-1 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}

              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>

              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>
              {taskContents.length !== 0 ? (
                <div className="flex flex-col w-full h-max">
                  <p>Bước thực hiện</p>
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">Bước</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">Thời hạn</th>
                        {/* {
                        task === null ? <></>
                          : task.Recipient === appData.userData.Id ?
                            <th className="border border-slate-300"></th>
                            : <></>
                      } */}
                        <th className="border border-slate-300">Kết quả</th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {taskContents.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white">
                          <td className="text-center border border-slate-300">
                            {item.SortOrder}
                          </td>
                          <td className="border border-slate-300">
                            <p>{item.StepName}</p>
                            <p>Người thực hiện: {item.UserDoName}</p>
                          </td>

                          <td className="text-center border border-slate-300">
                            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                          </td>

                          <td className="text-center border border-slate-300">
                            <div className="dropdown">
                              {/* button options */}
                              <button className="dropbtn">
                                <MoreHorizIcon fontSize="small" />
                              </button>

                              {/* options fields */}
                              <div className="dropdown-content text-xs font-semibold">
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onChangeMultipleChoicePartner(
                                      "multipleUploadFileContent",
                                      item.ID,
                                      1
                                    )
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <FileUploadIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Xem lịch sử</div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 h-max">
                  {fileListAPI === null ? <></> : <label>File đính kèm</label>}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}

                    {fileListAPI === null ? (
                      <></>
                    ) : (
                      fileListAPI.map((item: any, index: any) => (
                        <div
                          key={index}
                          className="flex w-full h-max text-primary items-center justify-between"
                        >
                          <a
                            href={item.listFile}
                            target="_blank"
                            rel="noreferrer"
                            className="flex w-5/6 h-max space-x-1"
                          >
                            <InsertDriveFileTwoToneIcon fontSize="small" />
                            <p>{item.listFileName}</p>
                          </a>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full h-max">
                {/* elements */}
                <div className="flex flex-col w-max h-max space-y-3">
                  <label>Kết quả cần đạt</label>
                  {/* element */}
                  {taskResults === null ? (
                    <></>
                  ) : (
                    taskResults.map((item: any, index: any) => (
                      <div className="h-max flex flex-col">
                        <div
                          key={index}
                          className="flex w-full h-max text-primary items-center justify-between"
                        >
                          <div className="flex w-max h-max space-x-1">
                            {/* <DoneIcon fontSize="small" /> */}

                            <input
                              type="checkbox"
                              className="w-5 h-5"
                              checked={item.Checked}
                              onClick={() =>
                                _onCheckResult(
                                  item.ID,
                                  item.TaskID,
                                  item.Checked
                                )
                              }
                              disabled={item.Checked ? true : false}
                            />
                            <p>{item.ResultText}</p>
                          </div>

                          {/* <div className="w-max flex justify-center">
                            <input
                              type="checkbox"
                              checked={item.Checked}
                              onClick={() =>
                                _onCheckResult(
                                  item.ID,
                                  item.TaskID,
                                  item.Checked
                                )
                              }
                              disabled={item.Checked ? true : false}
                            />
                          </div> */}
                        </div>
                      </div>
                    ))
                  )}

                  <label>File kết quả</label>

                  {/* {
                    fileListAPI2 === null ? <></>
                      : fileListAPI2.map((item: any, index: any) => (
                        <div key={index} className="flex w-full h-max text-primary items-center justify-between">
                          <a href={item.listFile} target="_blank" rel="noreferrer" className="flex w-5/6 h-max space-x-1">
                            <InsertDriveFileTwoToneIcon fontSize="small" />
                            <p>{item.listFileName}</p>
                          </a>
                        </div>
                      ))
                  }

                  {
                    filesList2 === null ? <></>
                      : filesList2.map((item: any, index: any) => (
                        <div key={index} className="flex w-full h-max text-primary items-center justify-between">
                          <a href={item.listFile} target="_blank" rel="noreferrer" className="flex w-5/6 h-max space-x-1">
                            <InsertDriveFileTwoToneIcon fontSize="small" />
                            <p>{item.name}</p>
                          </a>
                        </div>
                      ))
                  } */}

                  {/* fields of file */}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}
                    <div className="flex w-full h-max text-primary items-center justify-between">
                      <div className="flex flex-col w-full h-max">
                        {fileListAPI2.length === 0 ? (
                          <></>
                        ) : (
                          fileListAPI2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <a
                                href={item.listFile}
                                target="_blank"
                                rel="noreferrer"
                                className="flex w-5/6 h-max space-x-1"
                              >
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item.listFileName}</p>
                              </a>

                              {/* <button className="text-red-500 w-1/6 flex justify-end"
                                  onClick={() => _onDeleteFileReady2(item)}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button> */}
                            </div>
                          ))
                        )}
                        {filesList2.length === 0 ||
                        typeof filesList === "undefined" ? (
                          <></>
                        ) : (
                          filesList2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <div className="flex w-5/6 h-max space-x-1">
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item.name}</p>
                              </div>

                              {/* <button className="text-red-500 w-1/6 flex justify-end"
                                  onClick={() => _onDeleteElements2(index)}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button> */}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-1/2 h-max"></div>
              </div>

              {/* <div className="flex flex-col w-full h-max">
                <label>Nội dung hoàn thành</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập nội dung hoàn thành"
                  disabled
                />
              </div> */}
            </div>

            <div className="styled-scrollbars w-2/5 h-full pl-5 flex flex-col space-y-3 overflow-hidden">
              <div className="styled-scrollbars relative flex flex-col w-full h-full">
                <div className="flex absolute top-0 w-full h-[1.5rem] bg-white font-bold mb-2">
                  <p>THÔNG TIN TRAO ĐỔI</p>
                </div>

                <div
                  className="styled-scrollbars absolute top-[1.5rem] bottom-[6.5rem] w-full h-[calc(100%-8rem)] overflow-auto flex flex-col space-y-2"
                  ref={chatContainerRef}
                >
                  {taskInfoText === null ? (
                    <></>
                  ) : (
                    taskInfoText.map((item: any, index: any) => (
                      <>
                        {item.UserAdd === appData.userData.Id ? (
                          <div className="flex flex-col w-full h-max justify-end items-end">
                            <p className="text-xs text-slate-500">
                              {appData.userList === null
                                ? ""
                                : appData.userList.find(
                                    (item2: any) => item.UserAdd === item2.Id
                                  ).FullName}
                            </p>
                            <div className="flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-primary pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-white">
                              <div className="w-full h-full flex flex-col">
                                <p>{item.Contents}</p>
                                <a
                                  className="text-white text-xs hover:underline"
                                  href={item.ListFile}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.ListFile}
                                </a>
                              </div>
                              <p className="text-left text-[12px] text-white-500">
                                {moment(item.DateAdd).format("DD/MM/YYYY")} -{" "}
                                {moment(item.DateAdd).format("HH:mm:ss")}
                              </p>
                            </div>
                          </div>
                        ) : item.typeofNote === 0 ? (
                          <div className="flex flex-col w-full h-max justify-start items-start">
                            <p className="text-xs text-slate-500">
                              {appData.userList === null
                                ? ""
                                : appData.userList.find(
                                    (item2: any) => item.UserAdd === item2.Id
                                  ).FullName}
                            </p>
                            <div className="flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-slate-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black">
                              <div className="w-full h-full flex flex-col">
                                <p>{item.Contents}</p>
                                <a
                                  className="text-primary text-xs hover:underline"
                                  href={item.ListFile}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.ListFile}
                                </a>
                              </div>
                              <p className="text-right text-[12px] text-slate-500">
                                {moment(item.DateAdd).format("DD/MM/YYYY")} -{" "}
                                {moment(item.DateAdd).format("HH:mm:ss")}
                              </p>
                            </div>
                          </div>
                        ) : item.typeofNote === 1 ? (
                          <div className="flex flex-col w-full h-max justify-start items-start">
                            <p className="text-xs text-slate-500">
                              {appData.userList === null
                                ? ""
                                : appData.userList.find(
                                    (item2: any) => item.UserAdd === item2.Id
                                  ).FullName}
                            </p>
                            <div className="flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-green-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black">
                              <div className="w-full h-full flex flex-col">
                                <p>{item.Contents}</p>
                                <a
                                  className="text-primary text-xs hover:underline"
                                  href={item.ListFile}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.ListFile}
                                </a>
                              </div>
                              <p className="text-right text-[12px] text-slate-500">
                                {moment(item.DateAdd).format("DD/MM/YYYY")} -{" "}
                                {moment(item.DateAdd).format("HH:mm:ss")}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col w-full h-max justify-start items-start">
                            <p className="text-xs text-slate-500">
                              {appData.userList === null
                                ? ""
                                : appData.userList.find(
                                    (item2: any) => item.UserAdd === item2.Id
                                  ).FullName}
                            </p>
                            <div className="flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-red-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black">
                              <div className="w-full h-full flex flex-col">
                                <p>{item.Contents}</p>
                                <a
                                  className="text-primary text-xs hover:underline"
                                  href={item.ListFile}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.ListFile}
                                </a>
                              </div>
                              <p className="text-right text-[12px] text-slate-500">
                                {moment(item.DateAdd).format("DD/MM/YYYY")} -{" "}
                                {moment(item.DateAdd).format("HH:mm:ss")}
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  )}
                </div>

                <div className="flex flex-col absolute bottom-0 w-full h-[6.5rem] bg-slate-100 mt-2 rounded-sm">
                  <div className="flex items-center w-full h-[2rem]">
                    {/* elements */}
                    {/* <button className="flex justify-center items-center w-[2rem] h-full text-slate-500 hover:text-slate-400">
                      <input
                        className={`w-max h-max`}
                        multiple type='file'
                      />
                      <UploadFileTwoToneIcon fontSize="small" />
                    </button> */}

                    <input
                      className={`w-max h-max`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage(e)}
                    />

                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-slate-500 hover:text-slate-400
                        ${selectedTypeOf === 0 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(0)}
                      >
                        <p>Bình thường</p>
                      </button>
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-green-500 hover:text-green-400
                        ${selectedTypeOf === 1 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(1)}
                      >
                        <p>Ưu tiên</p>
                      </button>
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-red-500 hover:text-red-400
                        ${selectedTypeOf === 2 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(2)}
                      >
                        <p>Khẩn cấp</p>
                      </button>
                    </div>
                  </div>

                  <div className="flex w-full h-[4.5rem]">
                    <div className="w-4/5 flex h-full">
                      <textarea
                        className="w-full h-full rounded-md p-2 border-2 border-slate-200"
                        placeholder="Nhập nội dung"
                        value={text}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setText(e.target.value);
                        }}
                      />
                    </div>

                    <div className="w-1/5 h-full flex justify-center items-center">
                      <button
                        className="bg-primary hover:bg-green-500 w-max h-max pl-3 pr-3 pt-1.5 pb-1.5 rounded-md font-semibold"
                        onClick={_onClickSentChat}
                      >
                        <div className="flex text-white">
                          <span style={{ paddingRight: 3 }}>Gửi</span>
                          <SendIcon fontSize="small" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  phone */}
          <div className="lg:hidden flex w-full h-[calc(100%-6rem)] p-0 overflow-hidden text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 p-4 w-full h-full overflow-auto border-r-[1px] border-primary ">
              {/*  TaskName - TaskCode */}
              <div>
                <p className="flex w-full font-semibold">
                  {task === null ? "" : task.TaskName} -{" "}
                  {task === null ? "" : task.TaskCode}
                </p>
              </div>
              {/*  Uu tien */}
              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.PriorityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Nhập tên công việc"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>
              {/*  Do mat */}
              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.SecurityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
              </div>
              {/*  Nguoi phoi hop */}
              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md px-1 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}
              {/*Description*/}
              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>
              {/*Noted*/}
              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>
              {/*Step Table*/}
              {taskContents.length !== 0 ? (
                <div className="flex flex-col w-full h-max">
                  <p>Bước thực hiện</p>
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">Bước</th>
                        <th className="border border-slate-300">Trạng thái</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">Thời hạn</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {_taskContents.map((item: any, index: any) => (
                        <>
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {item.SortOrder}
                            </td>
                            <td className="border border-slate-300">
                              <>
                                {item.taskContentsHistory &&
                                item.taskContentsHistory.IsCompleted ===
                                  true ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor:
                                          item.taskContentsHistory.Checked ===
                                          null
                                            ? "#FFAA00"
                                            : item.taskContentsHistory
                                                .Checked === true
                                            ? "#00ff00"
                                            : "#ff0000",
                                        color: "white",
                                        padding: 5,
                                        textAlign: "center",
                                        borderRadius: 13,
                                        width: "fit-content",
                                      }}
                                    >
                                      {/* {_handleChecked(
                                        item.taskContentsHistory.Checked
                                      )} */}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: "#00B1FF",
                                      color: "white",
                                      padding: 5,
                                      textAlign: "center",
                                      borderRadius: 13,
                                      width: "fit-content",
                                    }}
                                  >
                                    Chưa có kết quả
                                  </div>
                                )}
                              </>
                            </td>
                            <td className="border border-slate-300">
                              <p>{item.StepName}</p>
                              <p>
                                <i>
                                  Người thực hiện: <b>{item.UserDoName}</b>
                                </i>
                              </p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <>
                                <div className="dropdown">
                                  {/* button options */}
                                  <button className="dropbtn">
                                    <MoreHorizIcon fontSize="small" />
                                  </button>

                                  {/* options fields */}
                                  <div className="dropdown-content text-xs font-semibold">
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onChangeMultipleChoicePartner(
                                          "multipleUploadFileContent",
                                          item.ID,
                                          1
                                        )
                                      }
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <HistoryIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Xem lịch sử</div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              {/* Result */}
              <div className="flex w-full h-max">
                <div className="flex flex-col w-max h-max space-y-3">
                  <label>Kết quả cần đạt</label>
                  {taskResults === null ? (
                    <></>
                  ) : (
                    taskResults.map((item: any, index: any) => (
                      <div className="h-max flex flex-col">
                        <div
                          key={index}
                          className="flex w-full h-max text-primary items-center justify-between"
                        >
                          <div className="flex w-max h-max space-x-1">
                            {/* <DoneIcon fontSize="small" /> */}

                            <input
                              type="checkbox"
                              className="w-5 h-5"
                              checked={item.Checked}
                              onClick={() =>
                                _onCheckResult(
                                  item.ID,
                                  item.TaskID,
                                  item.Checked
                                )
                              }
                              disabled={item.Checked ? true : false}
                            />
                            <p>{item.ResultText}</p>
                          </div>

                          {/* <div className="w-max flex justify-center">
                            <input
                              type="checkbox"
                              checked={item.Checked}
                              onClick={() =>
                                _onCheckResult(
                                  item.ID,
                                  item.TaskID,
                                  item.Checked
                                )
                              }
                              disabled={item.Checked ? true : false}
                            />
                          </div> */}
                        </div>
                      </div>
                    ))
                  )}

                  <label>File kết quả</label>

                  {/* fields of file */}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}
                    <div className="flex w-full h-max text-primary items-center justify-between">
                      <div className="flex flex-col w-full h-max">
                        {fileListAPI2.length === 0 ? (
                          <></>
                        ) : (
                          fileListAPI2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <a
                                href={item.listFile}
                                target="_blank"
                                rel="noreferrer"
                                className="flex w-5/6 h-max space-x-1"
                              >
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item.listFileName}</p>
                              </a>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-1/2 h-max"></div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer bg-slate-100 rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          {task.TaskStatus === 5 ? null : (
            <button
              className="lg:hidden flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md"
              onClick={() =>
                _onChangeMultipleChoicePartner("chatPopup", null, null)
              }
            >
              <SendIcon fontSize="small" />
              <p>Trao đổi</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteTask;
