import React from "react";

import { onTouchForm } from "../../../funcs/onTochForm";
import CloseIcon from '@mui/icons-material/Close';

const MultipleChoiceStep = (props: any) => {

  const handleClose = props.handleClose;

  return (
    <div
      className="
        w-[40rem] h-[37rem] bg-white rounded-md
        flex flex-col
      "
    >
      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <p>Chọn quy trình</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
      <div
        className="
          flex flex-col p-4 w-full h-[calc(100%-6rem)]
          text-sm
        "
      >
        <div className="flex w-full h-[5rem]">
          <div className="flex flex-col w-4/5 pr-3 h-max">
            <label>Tên quy trình</label>
            <input
              className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên quy trình"
              onTouchStart={(e: any) => onTouchForm(e)}
              
            />
          </div>
          <div className="flex flex-col w-1/5 pl-3">
            <label className="text-white">Tìm kiếm</label>
            <button
              className="
                bg-slate-300 text-black w-full h-max rounded-md
                hover:bg-slate-200 p-2
              "
            >
              Tìm kiếm
            </button>
          </div>
        </div>

        <div className="tableFixHead w-full h-[calc(100%-5rem)] shadow-md border-t-2">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300"></th>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">Tên quy trình</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">(thuộc tính)</th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">1</td>
                <td className="border border-slate-300">Quy trình A</td>
                <td className="border border-slate-300">(giá trị thuộc tính)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">2</td>
                <td className="border border-slate-300">Quy trình B</td>
                <td className="border border-slate-300">(giá trị thuộc tính)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">3</td>
                <td className="border border-slate-300">Quy trình C</td>
                <td className="border border-slate-300">(giá trị thuộc tính)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">4</td>
                <td className="border border-slate-300">Quy trình D</td>
                <td className="border border-slate-300">(giá trị thuộc tính)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">n</td>
                <td className="border border-slate-300">n</td>
                <td className="border border-slate-300">(n)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">n</td>
                <td className="border border-slate-300">n</td>
                <td className="border border-slate-300">(n)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">n</td>
                <td className="border border-slate-300">n</td>
                <td className="border border-slate-300">(n)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">n</td>
                <td className="border border-slate-300">n</td>
                <td className="border border-slate-300">(n)</td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="border border-slate-300 text-center">
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                  />
                </td>
                <td className="border border-slate-300 text-center">n</td>
                <td className="border border-slate-300">n</td>
                <td className="border border-slate-300">(n)</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
          flex items-center justify-end w-full p-12-16 footer rounded-b-md space-x-4 text-sm
        "
      >
        <button className="bg-green-500 text-white p-2 rounded-md hover:bg-green-400">
          Xác nhận
        </button>
        <button className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
          onClick={handleClose}
        >
          Đóng
        </button>
      </div>
    </div>
  )
}

export default MultipleChoiceStep;
