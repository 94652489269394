import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const ListOfEmployeePage = () => {
  return(
    <HomeUI />
  )
}

export default ListOfEmployeePage;
