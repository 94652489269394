import DeleteIcon from "@mui/icons-material/Delete";
import LoaderSpinner from "../../loader-spinner/_index";
import { useState } from "react";
import { fetchData, moveToTrash } from "../../fileComponent/utils/helper";
import {
  ONLOAD_FILELIST,
  ONLOAD_FOLDERLIST,
} from "../../../redux/reducers/driveReducer/_index";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const MoveToTrash = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  // take props
  const item = props.folderItem;
  const handleClose = props.handleClose;

  const dispatch = useDispatch();
  // funcs
  const _onClickDelete = async () => {
    setIsLoading(true);

    moveToTrash(item).then(() => {
      fetchData(id).then((response: any) => {
        dispatch(ONLOAD_FILELIST(response.FileClouds));
        dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));
      });
    });
    handleClose();
  };

  return (
    <div
      className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md text-center px-5">
        <p>
          Bạn có đồng ý <span className="font-semibold">DI CHUYỂN</span>{" "}
          {item.FileId ? "tệp" : "thư mục"} này vào thùng rác không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className={`
            w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === true
                ? "bg-slate-300"
                : "bg-slate-500 hover:bg-slate-400"
            }
          `}
            onClick={_onClickDelete}
            disabled={isLoading}
          >
            {isLoading === true ? (
              <div className="flex w-full h-full justify-center items-center">
                <LoaderSpinner w={20} h={20} />
              </div>
            ) : (
              <p>Có</p>
            )}
          </button>
          <button
            className={`
            w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === true
                ? "bg-slate-300"
                : "bg-red-500 hover:bg-red-300"
            }
          `}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoveToTrash;
