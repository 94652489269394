import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from '@mui/icons-material/Close';
import {
  ONPUSH_QUYTRINH,
  ONDELETE_QUYTRINH,
  ONCHANGE_QUYTRINH_BUOC,
} from "../../../redux/reducers/workingReducer/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

const EditBuoc = (props: any) => {
  // take props
  const positionStep = props.positionStep;
  const hourStep = props.hourStep;
  const contentStep = props.contentStep;
  const _onChangePositionStep = props._onChangePositionStep;
  const _onChangeHourStep = props._onChangeHourStep;
  const _onChangeContentStep = props._onChangeContentStep;
  const _onPushStep = props._onPushStep;
  const _onDeleteQuyTrinh = props._onDeleteQuyTrinh;
  const _onChangeStepList = props._onChangeStepList;

  const handleClose = props.handleClose;
  const selectedBuocList = props.selectedBuocList;
  const taskId = props.taskId;

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [quytrinhName, setQuytrinhName] = useState<any>("");
  const [stepList, setStepList] = useState<any[] | null>(null);
  const [resultList, setResultList] = useState<any[] | null>(null);

  const [newPositionStep, setNewPositionStep] = useState<number | null>(null);
  const [newHourStep, setNewHourStep] = useState<number | null>(null);
  const [newNameStep, setNewNameStep] = useState<string | null>(null);

  // funcs
  const _onChangeQuytrinhBuoc = (e: any, id: number) => {
    e.preventDefault();
    dispatch(
      ONCHANGE_QUYTRINH_BUOC({ id: id, value: e.target.value, type: "buoc" })
    );
  };

  const _onChangeQuytrinhTen = (e: any, id: number) => {
    e.preventDefault();
    dispatch(
      ONCHANGE_QUYTRINH_BUOC({ id: id, value: e.target.value, type: "ten" })
    );
  };

  const _onChangeQuytrinhGio = (e: any, id: number) => {
    e.preventDefault();
    dispatch(
      ONCHANGE_QUYTRINH_BUOC({ id: id, value: e.target.value, type: "gio" })
    );
  };

  return (
    <div
      className="
      lg:w-[40rem] w-screen h-[37rem] bg-white rounded-md
      flex flex-col
    "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

    "
      >
        <p>Sửa quy trình</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
      <div
        className="
        flex flex-col p-4 w-full h-[calc(100%-6rem)]
        text-sm
        space-y-3
      "
      >
        {/* <div className="flex w-full h-max"> */}
        <div className="flex flex-col w-full h-max">
          <label>Nội dung bước thực hiện</label>
          <div className="flex w-full h-max justify-between">
            <input
              className="w-1/4 lg:w-1/6 h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Thứ tự"
              type="number"
              value={positionStep === null ? "" : positionStep}
              onChange={(e: any) => {
                _onChangePositionStep(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
            <textarea
              className="w-1/2 lg:w-3/6 h-full rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập nội dung bước"
              rows={4}
              value={contentStep === null ? "" : contentStep}
              onChange={(e: any) => {
                _onChangeContentStep(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
            <div className="flex pl-3 items-center justify-end w-1/5 h-max">
              {/* <label className="text-white">Cập nhật</label> */}
              <button
                className="flex bg-green-500 h-[1.9rem] text-white p-2 rounded-md hover:bg-green-400"
                onClick={_onPushStep}
              >
                <AddIcon fontSize="small" />
                <p>Thêm</p>
              </button>
            </div>
          </div>
        </div>

        {/* here */}
        <div className="tableModal styled-scrollbars w-full h-[20rem] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">Bước</th>
                <th className="border-l-[1px] border-slate-300">Nội dung</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">
                  Xoá
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null
                ? ""
                : selectedBuocList.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[2rem] bg-white">
                      <td className="text-center border border-slate-300">
                        <input
                          className="w-[3rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black text-center"
                          value={item.SortOrder}
                          type="number"
                          onChange={(e: any) => {
                            _onChangeStepList("orderBy", e.target.value, index);
                          }}
                          onTouchStart={(e: any) => onTouchForm(e)}
                        />
                      </td>
                      <td className="text-center border border-slate-300">
                        <textarea
                          className="w-full h-max rounded-md pl-2 pt-1 pb-1 pr-2 border-2 border-slate-200 text-black"
                          value={item.StepName}
                          onChange={(e: any) => {
                            _onChangeStepList(
                              "stepName",
                              e.target.value,
                              index
                            );
                          }}
                          onTouchStart={(e: any) => onTouchForm(e)}
                        />
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="flex justify-center items-center w-full h-full">
                          <button
                            className="text-red-500 w-max h-max flex text-center hover:text-red-300 p-2"
                            onClick={() => _onDeleteQuyTrinh(item.SortOrder)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
        flex items-center  justify-end w-full p-12-16 rounded-b-md space-x-4 text-sm
        footer
      "
      >
        <button
          className="flex bg-green-500 text-white p-2 rounded-md hover:bg-green-400"
          onClick={handleClose}
        >
          <DoneIcon fontSize="small" />
          <p>Xác nhận</p>
        </button>
        <button
          className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
          onClick={handleClose}
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

export default EditBuoc;
