/* eslint-disable no-restricted-globals */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import HandleDate from "../../../utils/date/handleDate";

export const scheduleReducer = createSlice({
  name: "scheduleReducer",

  initialState: {
    selectedUser: {},
    eventList: [],
    optionList: [
      { value: "1", label: "Công việc", color: "#00B1FF", checked: true },
      { value: "5", label: "Lịch họp", color: "#ffcc00", checked: true },
      { value: "6", label: "Lịch công tác", color: "#00cc00", checked: true },
      { value: "3", label: "Ngày nghỉ lễ", color: "#cc00cc", checked: true },
      { value: "4", label: "Ngày nghỉ phép", color: "#ff0000", checked: true },
    ],
    fromDate: HandleDate.firstDayMonthSchedule(),
    toDate: HandleDate.lastDayMonthSchedule(),
    // fromDate: new Date(
    //   new Date().getFullYear(),
    //   new Date().getMonth(),
    //   1,
    //   7,
    //   0,
    //   0
    // )
    //   .toISOString()
    //   .slice(0, 24),
    // toDate: new Date(
    //   new Date().getFullYear(),
    //   new Date().getMonth() + 1,
    //   1,
    //   7,
    //   0,
    //   0
    // )
    //   .toISOString()
    //   .slice(0, 24),
    view: "month",
  },

  reducers: {
    ONSELECT_USER: (state: any, actions: PayloadAction<any>) => {
      state.selectedUser = actions.payload;
    },
    ONCHANGE_OPTIONLIST: (state: any, actions: PayloadAction<any>) => {
      state.optionList = actions.payload;
    },
    ONCHANGE_FROMDATE: (state: any, actions: PayloadAction<any>) => {
      state.fromDate = actions.payload;
    },
    ONCHANGE_TODATE: (state: any, actions: PayloadAction<any>) => {
      state.toDate = actions.payload;
    },
    ONCHANGE_VIEW: (state: any, actions: PayloadAction<any>) => {
      state.view = actions.payload;
    },
    ONLOAD_EVENTLIST: (state: any, actions: PayloadAction<any>) => {
      state.eventList = actions.payload;
    },
    ON_ADD_EVENT: (state: any, action: PayloadAction<any>) => {
      state.eventList.push(action.payload);
    },
  },
});

export const {
  ONLOAD_EVENTLIST,
  ON_ADD_EVENT,
  ONSELECT_USER,
  ONCHANGE_OPTIONLIST,
  ONCHANGE_FROMDATE,
  ONCHANGE_TODATE,
  ONCHANGE_VIEW,
} = scheduleReducer.actions;

export default scheduleReducer.reducer;
