import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const TaskGeneralDeliver = () => {
  return (
    <HomeUI />
  )
}

export default TaskGeneralDeliver;
