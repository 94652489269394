import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

import { ModalController } from "../../../ui/homeUI/_index";
import {
  PRIOR_DELETE,
  PRIOR_LIST,
  USER_DELETE,
  USER_READ_ALL,
  ON_LEAVE_DELETE,
  ON_MEETING_DELETE,
  ON_BUSINESSTRIP_DELETE,
} from "../../../apis/_index";
import { ONLOAD_USERLIST } from "../../../redux/reducers/settingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";

const DeleteBusinessTrip = (props: any) => {
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  console.log(context);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // funcs
  const _onClickDelete = async () => {
    setIsLoading(true);
    const API = ON_BUSINESSTRIP_DELETE + `${taskId}`;

    try {
      await ServiceUtils.getV2(API, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.data.StatusCode === 200) {
          toast.success("Xoá thành công");
          context.dataModal.tempFuncs();
          handleClose();
          context.handleClose();
        } else {
          toast.error(res.data.Message);
          handleClose();
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>
          Bạn có đồng ý <span className="font-semibold">XOÁ</span> lịch công tác
          này không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className={`w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === true
                ? "bg-slate-300"
                : "bg-slate-500 hover:bg-slate-400"
            }
          `}
            onClick={_onClickDelete}
            disabled={isLoading}
          >
            {isLoading === true ? (
              <div className="flex w-full h-full justify-center items-center">
                <LoaderSpinner w={20} h={20} />
              </div>
            ) : (
              <p>Có</p>
            )}
          </button>
          <button
            className={`w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === true ? "bg-red-300" : " bg-red-500 hover:bg-red-300"
            }
          `}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBusinessTrip;
