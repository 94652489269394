import React from "react";

import ContentCore from "../../../core/workingContent";

import "../_index.css";

const TaskTotal = () => {
  return (
    <>
      <ContentCore />
    </>
  )
}

export default TaskTotal;
