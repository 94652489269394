export const convertNameParameter = (ParameterKey: any) => {
    switch (ParameterKey) {
        case 'LI_CHUCVU':
            return 'Chức vụ';
        case 'LI_BOPHAN':
            return 'Bộ phận';
        case 'LI_NGUONVANBAN':
            return 'Nguồn văn bản';
        case 'LI_LOAIVIEC':
            return 'Loại việc';
        case 'LI_PHONGBAN':
            return 'Phòng ban';
        default:
            break
    }
}

export const getUniqueTags = (arrayData: any = [], name: any) => {
    const uniqueTags: any = [];
    arrayData?.map((item: any) => {
        var findItem = uniqueTags.find((x: any) => x[name] === item[name]);
        if (!findItem) uniqueTags.push(item);
    });
    return uniqueTags;
}

// export const _getUniqueTags = (arrayData: any = [], name: any) => {
//     const uniqueTags: any = [];
//     arrayData.map((item: any) => {
//         if (uniqueTags.indexOf(item[name]) === -1) {
//             uniqueTags.push(item[name])
//         }
//     });
// }