import { combineReducers, configureStore } from "@reduxjs/toolkit";

// reducers importing
import appReducer from "../reducers/_appReducer/_index";
import workingReducer from "../reducers/workingReducer/_index";
import userReducer from "../reducers/userReducer/_index";
import settingReducer from "../reducers/settingReducer/_index";
import reportReducer from "../reducers/reportReducer/_index";
import systemReducer from "../reducers/systemReducer/index";
import driveReducer from "../reducers/driveReducer/_index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import proposeReducer from "../reducers/proposeReducer/_index";
import scheduleReducer from "../reducers/ScheduleReducer/_index";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["driveReducer", "systemReducer"],
};

const rootReducer = combineReducers({
  appReducer: appReducer,
  workingReducer: workingReducer,
  userReducer: userReducer,
  settingReducer: settingReducer,
  reportReducer: reportReducer,
  systemReducer: systemReducer,
  driveReducer: driveReducer,
  proposeReducer: proposeReducer,
  scheduleReducer: scheduleReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;

export const persistor = persistStore(store);
