import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HistoryIcon from "@mui/icons-material/History";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SendIcon from "@mui/icons-material/Send";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import { USER_READ_ALL_DROPDOWN } from "../../../apis/_index";
import CloseIcon from "@mui/icons-material/Close";

import EditIcon from "@mui/icons-material/Edit";

import MoreTimeIcon from "@mui/icons-material/MoreTime";

import { CHECK_RESULT } from "../../../apis/_index";
import {
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  SENT_MESSAGE,
  NGUOI_NHAN_FILE_STEP_UPLOAD,
  NGUOI_NHAN_UPLOAD_RESULT_FILE,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { NGUOI_NHAN_VIEC_DETAIL } from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

import {
  UploadFileTaskResult,
  CheckCompletedTaskContent,
} from "../../../action/taskOptionsAction";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import { truncateParagraph } from "../../../funcs/truncateParagraph";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import ModalBase from "../_index";
import { TASK_STATUSES } from "../../../constants/dataConstants";
import { MODAL_KEYS } from "../../../constants/constants";
import ReviewHistoryTaskPreviousComponent from "../../reviewHistoryTaskPreviouses/reviewHistoryTaskPreviousComponent";
import ServiceUtils from "../../../utils/services";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";

const ReadTotalTask = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  //state modal
  const [openModal, setOpenModal] = useState(false);
  const [funcsModal, setFuncsModal] = useState<string>("");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [bodyAdding, setBodyAdding] = useState<any>({});

  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);

  const [isSendingChat, setIsSendingChat] = useState<boolean>(false);

  const appData = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  const [task, setTask] = useState<any>(null);
  const [taskContents, setTaskContents] = useState<any[]>([]);
  const [_taskContents, set_TaskContents] = useState<any[]>([]);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);
  const [taskResults, setTaskResults] = useState<any>(null);
  const [taskInfoText, setTaskInfoText] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);
  const [isViewHistoty, setIsViewHistoty] = useState<boolean>(false);
  const [taskHistory, setTaskHistory] = useState<any[]>([]);

  const [text, setText] = useState<string>("");
  const [fileListAPI, setFileListAPI] = useState<any[]>([]);
  const [fileListAPI2, setFileListAPI2] = useState<any[]>([]);
  const [isShowConfirmtaskForUser, setIsShowConfirmtaskForUser] =
    useState<boolean>(false);
  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);

  const uploadFileResult = useRef<any>(null);
  const uploadFileChat = useRef<any>(null);
  const uploadFileStep = useRef<any>(null);

  const [filesList, setFilesList] = useState<any[]>([]);
  const [filesList2, setFilesList2] = useState<any[]>([]);
  const [filesList3, setFilesList3] = useState<any[]>([]);
  const [selectedTypeOf, setSelectedTypeOf] = useState<number>(0);
  const [idHistory, setIdHistory] = useState<string>("");
  const urlLink = window.location.pathname;
  const [takeHistoryDetail, setTakeHistoryDetail] = useState<any[]>();
  const [idClone, setIDClone] = useState<string>("");
  const [isUploadFile, setIsUploadFile] = useState<boolean>(true);

  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [taskStatus, setTaskStatus] = useState<number | null>(null);
  const [supporter, setSupporter] = useState<string | null>(null);
  const [isCheckSupporter, setIsCheckSupporter] = useState<boolean>(true);
  const [isHaveSupport, setIsHaveSupport] = useState<boolean>(true);
  const [taskDeduct, setTaskDeduct] = useState<any[]>([]);

  console.log("------------");
  console.log("supporter, ", supporter);
  console.log("isCheckSupporter, ", isCheckSupporter);
  console.log("------------");
  // funcs

  const _onChangeMultipleChoicePartner = (
    text: string,
    item: any = null,
    isViewHistoty: boolean = false
  ) => {
    handleOpenSub();
    if (
      text === "completedTaskContent" ||
      text === "multipleUploadFileContent"
    ) {
      setTaskContentHistoryId(item);
    }

    setIsViewHistoty(isViewHistoty);
    setFuncsSub(text);
  };

  const _onClickSubModal = (text: string) => {
    if (!isUploadFile && text == "finishTask") {
      setFuncsSub(MODAL_KEYS.alertUploadFile);
      handleOpenSub();

      return;
    }

    handleOpenSub();
    setFuncsSub(text);
  };

  const _onFinishTask = () => {
    handleOpenSub();
    setFuncsSub("finishTask");
  };

  const _onClickModal = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const saveEditFile = (_index: number, fileName: string) => {
    const tempArray = [...filesList2];

    const updateArray = tempArray.map((item: any, index: any) => {
      if (index === _index) {
        const renamedFile = new Blob([item], {
          type: item?.type,
        });

        const fileWithNewName = new File([renamedFile], fileName, {
          type: item?.type,
          lastModified: item?.lastModified,
        });

        return fileWithNewName;
      } else {
        return item;
      }
    });

    setFilesList2(updateArray);
  };

  const saveEditFileAlready = (_index: number, fileName: string) => {
    const tempArray = [...fileListAPI2];

    const updateArray = tempArray.map((item: any, index: any) => {
      if (index === _index) {
        item.listFileName = fileName;
        return item;
      } else {
        return item;
      }
    });

    setFileListAPI2(updateArray);
  };
  const _onClickDetailHistory = (id: any, index: number) => {
    setFuncsSub("watchTaskHistory");
    setIdHistory(id);
    setTakeHistoryDetail(taskHistory.find((item) => item?.ID === id));
    handleOpenSub();
  };
  const _onClickEditFile = (index: string, file: any, setFile: any) => {
    setFuncsSub("openEditFileSub");
    setBodyAdding({
      filesList2: file,
      setFilesList2: setFile,
      saveEditFile,
      index,
    });
    handleOpenSub();
  };

  const _onClickEditFileAlready = (index: string, file: any, setFile: any) => {
    setFuncsSub("openEditFileSubAlready");
    setBodyAdding({
      filesList2: file,
      setFilesList2: setFile,
      saveEditFile: saveEditFileAlready,
      index,
    });
    handleOpenSub();
  };

  const _onClickChooseFileResult = () => {
    uploadFileResult.current.click();
  };

  const _onClickChooseFileChat = () => {
    if (!props.isReviewHistoryTaskPrevious) {
      uploadFileChat.current.click();
    }
  };

  const _onClickChooseFileStep = () => {
    uploadFileStep.current.click();
  };

  const _onDeleteElements2 = (index: number) => {
    const tempArray: any[] = [...filesList2];
    tempArray.splice(index, 1);
    setFilesList2(tempArray);
  };

  const [isShowUpload, setIsShowUpload] = useState<boolean>(false);

  const _onDeleteFileReady2 = (linkFile: string) => {
    const tempArray: any[] = [...fileListAPI2];

    var index = tempArray.indexOf(linkFile);

    if (index !== -1) {
      tempArray.splice(index, 1);
    }

    setFileListAPI2(tempArray);
    if (index == 0) {
      setIsShowUpload(true);
    }
  };

  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onChangeFileImage2 = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList2] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList2(tempArray);
    setIsUploadFile(false);
  };

  const _conClickComplateTaskContent = (id: any) => {
    if (id) {
      CheckCompletedTaskContent(id).then((res: any) => {
        setIsLoading(true);
        try {
          const data = (res || {}).data || {};
          if (data.RCode == 200) {
            setIsLoading(false);
            if (handleClose) {
              handleClose();
            }
            toast.success("Xác nhận hoàn thành bước thành công");
          } else {
            setIsLoading(false);
            if (handleClose) {
              handleClose();
            }
            toast.error(data.RMessage || "Xác nhận hoàn thành bước thất bại");
          }
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      });
    }
  };

  const _onClickSentChat = () => {
    if (props.isReviewHistoryTaskPrevious) {
      return;
    }

    if (text === "" && filesList.length <= 0) {
      // do nothing
    } else {
      setIsSendingChat(true);

      const formData = new FormData();
      let fileNameString: string = "";

      formData.append("TaskID", taskId);
      formData.append("Contents", text);
      formData.append("typeofNote", selectedTypeOf.toString());

      if (filesList) {
        for (let i = 0; i < filesList.length; i++) {
          formData.append(`File_ListFile`, filesList[i]);
          if (i === filesList.length - 1) {
            fileNameString += `${filesList[i].name}`;
          } else {
            fileNameString += `${filesList[i].name};`;
          }
        }
        formData.append("ListFileName", fileNameString);
      }

      const sentCode = async () => {
        try {
          await ServiceUtils.postFormDataV2(SENT_MESSAGE, formData, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res: any) => {
              const data = res.data.Data[0];
              if (data.RCode === 200) {
                getDetail(0);

                setSelectedTypeOf(0);
                setText("");
                setFilesList([]);

                toast.success("Gửi trao đổi thành công");
                setIsSendingChat(false);
              } else {
                toast.error(data.RMessage || "Gửi trao đổi thất bại");
              }
            })
            .catch((err: any) => {
              toast.error("Gửi trao đổi thất bại");
              setIsSendingChat(false);
            });
        } catch (error: any) {}
      };

      sentCode();
    }
  };

  const _onClickUploadFileResult = () => {
    setIsLoading(true);
    let stringResultFiles: string = "";
    let formData = new FormData();
    let fileNameString: string = "";

    if (fileListAPI2) {
      fileListAPI2.map((item: any) => {
        if (item === fileListAPI2.length - 1) {
          stringResultFiles += item?.listFile;
          if (item?.listFileName) {
            fileNameString += item?.listFileName;
          }
        } else {
          stringResultFiles += item?.listFile + ";";
          if (item?.listFileName) {
            fileNameString += item?.listFileName + ";";
          }
        }
      });
    }

    formData.append("ID", task.ID ? task.ID : "");
    formData.append("ResultFiles", stringResultFiles ? stringResultFiles : "");
    if (filesList2) {
      for (let i = 0; i < filesList2.length; i++) {
        formData.append(`ResultFiles_Files`, filesList2[i]);

        if (i === filesList2.length - 1) {
          fileNameString += `${filesList2[i].name}`;
        } else {
          fileNameString += `${filesList2[i].name};`;
        }
      }
      formData.append("ResultFilesName", fileNameString);
    }

    ServiceUtils.postFormDataV2(NGUOI_NHAN_UPLOAD_RESULT_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res: any) => {
      const data = res?.data?.Data || {};
      if (data?.RCode == 200) {
        getDetail(0);
        setIsLoading(false);
        toast.success("Tải tệp tin thành công");
      } else {
        setIsLoading(false);
        toast.error(data.RMessage || "Tải tệp tin thất bại");
      }

      setIsUploadFile(true);
    });

    setFilesList2([]);
  };

  const _handeleShowConfirmTask = () => {
    if (taskHistory.length > 0) {
      taskHistory.map((x: any) => {
        if (x.Checked === true) {
          setIsShowConfirmtaskForUser(false);
        } else if (x.Checked === false) {
          setIsShowConfirmtaskForUser(true);
        } else {
          setIsShowConfirmtaskForUser(false);
        }
      });
    } else {
      setIsShowConfirmtaskForUser(true);
    }
  };

  useEffect(() => {
    _handeleShowConfirmTask();
  }, [taskHistory]);

  const getDetail = async (isStart: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const API = NGUOI_NHAN_VIEC_DETAIL(taskId);
    //setFileListAPI2([]);
    if (isStart === 1) {
      // if (uuTienList === null || doMatList === null) {
      // } else {
      if (shouldLog.current) {
        shouldLog.current = false;

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                var _task = res.data.Data.task;
                setTask(res.data.Data.task);
                setTaskDeduct(res.data.Data.taskDeduct);
                setTaskContents(res.data.Data.taskContents);

                setTaskContentsHistory(res.data.Data.taskContentHistory);
                setTaskResults(res.data.Data.taskResults);
                //setTaskInfoText(res.data.Data.taskInfoText)
                setTaskHistory(res.data.Data.taskHistory);

                setTaskStatus(_task.TaskStatus);
                setSupporter(_task.Supporter);
                setIsProcessing(
                  _task.TaskStatus == TASK_STATUSES.processing ? true : false
                );
                setIsHaveSupport(_task.Supporter ? true : false);
                setIsCheckSupporter(_task.IsCheckSupporter ?? true);

                let fileListResponse: any[] = [];
                let fileListNameResponse: any[];
                if (
                  res.data.Data.task.ListFiles &&
                  res.data.Data.task.ListFilesName
                ) {
                  fileListResponse = res.data.Data.task.ListFiles.split(";");
                  fileListNameResponse =
                    res.data.Data.task.ListFilesName.split(";");

                  let tempFileArray: any[] = [];
                  if (
                    fileListResponse.length > 0 &&
                    fileListNameResponse.length > 0
                  ) {
                    fileListNameResponse.forEach((item: any, index: any) => {
                      let tempObject: any;
                      if (item) {
                        tempObject = {
                          listFile: fileListResponse[index],
                          listFileName: item,
                        };
                      }
                      tempFileArray.push(tempObject);
                    });
                    setFileListAPI(tempFileArray);
                  }
                }

                let fileListResponse2: any[] = [];
                let fileListNameResponse2: any[] = [];
                if (
                  res.data.Data.task.ResultFiles &&
                  res.data.Data.task.ResultFilesName
                ) {
                  fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
                  fileListNameResponse2 =
                    res.data.Data.task.ResultFilesName.split(";");
                  let tempFileArray2: any[] = [];
                  if (
                    fileListNameResponse2.length > 0 &&
                    fileListResponse2.length > 0
                  ) {
                    fileListNameResponse2.map((item: any, index: any) => {
                      let tempObject: any;
                      if (
                        item !== "" &&
                        item !== null &&
                        typeof item !== "undefined"
                      ) {
                        tempObject = {
                          listFile: fileListResponse2[index],
                          listFileName: item,
                        };
                      }
                      if (tempObject) {
                        tempFileArray2.push(tempObject);
                      }
                    });
                    setFileListAPI2(tempFileArray2);
                  }
                }

                let _taskInfoText: any[] = res.data.Data.taskInfoText;

                let fileListResponseChat: any[] = [];
                let fileListResponseNameChat: any[] = [];
                if (_taskInfoText.length > 0) {
                  let tempFileArrFileChat: any[] = [];
                  _taskInfoText.map((item: any) => {
                    if (item?.ListFile && item?.ListFileName) {
                      fileListResponseChat = item?.ListFile.split(";");
                      fileListResponseNameChat = item?.ListFileName.split(";");

                      let arr: any[] = [];
                      if (
                        fileListResponseNameChat.length > 0 &&
                        fileListResponseChat.length > 0
                      ) {
                        fileListResponseNameChat.map(
                          (itemC: any, index: any) => {
                            let tempObject: any;

                            if (
                              itemC !== "" &&
                              itemC !== null &&
                              typeof itemC !== "undefined"
                            ) {
                              tempObject = {
                                listFile: fileListResponseChat[index],
                                listFileName: itemC,
                              };
                            }
                            if (tempObject) {
                              arr.push(tempObject);
                            }
                          }
                        );

                        if (arr) {
                          tempFileArrFileChat.push({
                            ...item,
                            listFileInChat: arr,
                          });
                        } else {
                          tempFileArrFileChat.push({
                            ...item,
                            listFileInChat: [],
                          });
                        }
                      }
                    } else {
                      tempFileArrFileChat.push({
                        ...item,
                        listFileInChat: [],
                      });
                    }
                  });
                  setTaskInfoText(tempFileArrFileChat);
                }

                setIDClone(res.data.Data.task.IDClone);
              }
            });
          } catch (error: any) {
            // ERROR: here
          }
        };

        await takeData();
      }
      // }
    } else {
      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              var _task = res.data.Data.task;
              setTask(res.data.Data.task);
              setTaskContents(res.data.Data.taskContents);
              setTaskContentsHistory(res.data.Data.taskContentHistory);
              setTaskResults(res.data.Data.taskResults);
              //setTaskInfoText(res.data.Data.taskInfoText)
              setTaskHistory(res.data.Data.taskHistory);
              setTaskStatus(_task.TaskStatus);
              setSupporter(_task.Supporter);
              setIsProcessing(
                _task.TaskStatus == TASK_STATUSES.processing ? true : false
              );
              setIsHaveSupport(_task.Supporter ? true : false);
              setIsCheckSupporter(_task.IsCheckSupporter || true);

              let fileListResponse: any[] = [];
              let fileListNameResponse: any[];
              if (
                res.data.Data.task.ListFiles &&
                res.data.Data.task.ListFilesName
              ) {
                fileListResponse = res.data.Data.task.ListFiles.split(";");
                fileListNameResponse =
                  res.data.Data.task.ListFilesName.split(";");

                let tempFileArray: any[] = [];
                if (
                  fileListResponse.length > 0 &&
                  fileListNameResponse.length > 0
                ) {
                  fileListNameResponse.forEach((item: any, index: any) => {
                    let tempObject: any;
                    if (item) {
                      tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };
                    }
                    tempFileArray.push(tempObject);
                  });
                  setFileListAPI(tempFileArray);
                }
              }

              let fileListResponse2: any[] = [];
              let fileListNameResponse2: any[] = [];
              if (
                res.data.Data.task.ResultFiles &&
                res.data.Data.task.ResultFilesName
              ) {
                fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
                fileListNameResponse2 =
                  res.data.Data.task.ResultFilesName.split(";");
                let tempFileArray2: any[] = [];
                if (
                  fileListNameResponse2.length > 0 &&
                  fileListResponse2.length > 0
                ) {
                  fileListNameResponse2.map((item: any, index: any) => {
                    let tempObject: any;
                    if (
                      item !== "" &&
                      item !== null &&
                      typeof item !== "undefined"
                    ) {
                      tempObject = {
                        listFile: fileListResponse2[index],
                        listFileName: item,
                      };
                    }
                    if (tempObject) {
                      tempFileArray2.push(tempObject);
                    }
                  });
                  setFileListAPI2(tempFileArray2);
                }
              }

              let _taskInfoText: any[] = res.data.Data.taskInfoText;

              let fileListResponseChat: any[] = [];
              let fileListResponseNameChat: any[] = [];
              if (_taskInfoText.length > 0) {
                let tempFileArrFileChat: any[] = [];
                _taskInfoText.map((item: any) => {
                  if (item?.ListFile && item?.ListFileName) {
                    fileListResponseChat = item?.ListFile.split(";");
                    fileListResponseNameChat = item?.ListFileName.split(";");

                    let arr: any[] = [];
                    if (
                      fileListResponseNameChat.length > 0 &&
                      fileListResponseChat.length > 0
                    ) {
                      fileListResponseNameChat.map((itemC: any, index: any) => {
                        let tempObject: any;

                        if (
                          itemC !== "" &&
                          itemC !== null &&
                          typeof itemC !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponseChat[index],
                            listFileName: itemC,
                          };
                        }
                        if (tempObject) {
                          arr.push(tempObject);
                        }
                      });

                      if (arr) {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: arr,
                        });
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    }
                  } else {
                    tempFileArrFileChat.push({ ...item, listFileInChat: [] });
                  }
                });
                setTaskInfoText(tempFileArrFileChat);
              }
            }
          });
        } catch (error: any) {
          // ERROR: here
        }
      };

      await takeData();
    }
  };

  useEffect(() => {
    getDetail(1);
  });

  useEffect(() => {
    _handleTaskContentHistory();
  }, [taskContents, taskContentsHistory]);

  useEffect(() => {
    if (uuTienList === null || doMatList === null) {
      if (shouldLog2.current) {
        const accessToken = localStorage.getItem("accessToken");
        shouldLog2.current = false;

        const takeUuTien = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setUuTienList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        const takeDoMat = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setDoMatList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        takeUuTien();
        takeDoMat();
      }
    }
  });

  useEffect(() => {
    if (appData.userList === null && shouldLog3.current) {
      shouldLog3.current = false;

      // const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };
      takeData();
    }
  });

  const _handleCheckCompled = (data: any) => {
    if (data === 1) {
      getDetail(0);
    }
  };

  const _handleChecked = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "Người thực hiện chính không duyệt";
    } else if (statusCheck === true && ownerChecked === null) {
      return "Người giao việc chưa duyệt";
    } else if (statusCheck === null && ownerChecked === null) {
      return "Người thực hiện chính chưa duyệt";
    } else if (statusCheck === true && ownerChecked === false) {
      return "Người giao việc không duyệt";
    } else if (statusCheck === true && ownerChecked === true) {
      return "Người giao việc đã duyệt";
    }
  };

  const _handleColor = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "#a855f7";
    } else if (statusCheck === true && ownerChecked === null) {
      return "#FFAA00";
    } else if (statusCheck === null && ownerChecked === null) {
      return "#78716c";
    } else if (statusCheck === true && ownerChecked === false) {
      return "#ff0000";
    } else if (statusCheck === true && ownerChecked === true) {
      return "#00ff00";
    }
  };

  const _handleCheckedHistory = (taskReasons: any, checked: any) => {
    // if (statusCheck === false) {
    //   return "Không duyệt";
    // } else if (statusCheck === true) {
    //   return "Đã duyệt";
    // } else {
    //   return "Chưa duyệt";
    // }
    if (checked === false) {
      return "Bị trả về";
    } else if (checked === true) {
      return "Đã duyệt";
    } else {
      return "Chờ duyệt";
    }
  };

  const _handleCheckedColor = (taskReasons: any, checked: any) => {
    // if (taskReasons === "Không duyệt") {
    //   return "#ff0000";
    // } else if (taskReasons === "Chưa duyệt" || taskReasons === null) {
    //   return "#FFAA00";
    // } else {
    //   return "#00ff00";
    // }

    if (checked === false) {
      return "#ff0000";
    } else if (checked === true) {
      return "#00ff00";
    } else {
      return "#FFAA00";
    }
  };

  const _handleTaskContentHistory = () => {
    if (taskContents.length > 0 && taskContentsHistory.length > 0) {
      let _taskContent: any[] = [];
      let foundObject: any[] = [];
      let _foundObject: any[] = [];

      _taskContent = taskContents.map((item1: any) => {
        foundObject = taskContentsHistory.filter(
          (item2: any) => item1.ID === item2.Task_ContentID
        );
        if (foundObject.length > 0) {
          _foundObject = foundObject.find(
            (x: any, index: any) =>
              x.Checked === null || index == foundObject.length - 1
          );

          return { ...item1, taskContentsHistory: _foundObject };
        } else {
          return { ...item1 };
        }
      });
      set_TaskContents(_taskContent);
    } else {
      set_TaskContents(taskContents);
    }
  };
  const [content, setContent] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const openContent = Boolean(anchorEl);

  const handleShowContent = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseShowContent = (e: any) => {
    setAnchorEl(null);
  };

  const onOpenHistoryTaskPrevious = () => {
    setFuncsSub(MODAL_KEYS.reviewHistoryTaskPrevious);
    handleOpenSub();
  };

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;
  // console.log(appData);

  // const taskStatus = (task || {}).TaskStatus;
  // const supporter = (task || {}).Supporter;

  // const isProcessing = taskStatus == TASK_STATUSES.processing;

  // const isHaveSupport = supporter ? true : false;
  // const isCheckSupporter = (task || {}).IsCheckSupporter || true;
  const chatContainerRef = useRef<any>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [taskInfoText]);

  const _onClickListTaskDeduct = () => {
    handleOpenSub();
    setFuncsSub("listTaskDeduct");
  };
  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[80rem] w-screen h-[90vh]
      rounded-md
    "
    >
      {funcsSub === "completedTaskContent" ? (
        taskContentHistoryId ? (
          <SubModal
            open={openSub}
            handleClose={handleCloseSub}
            funcs={funcsSub}
            taskHistory={taskHistory}
            takeHistoryDetail={takeHistoryDetail}
            idHistory={idHistory}
            bodyAdding={bodyAdding}
            taskContentHistoryId={taskContentHistoryId}
            taskId={
              funcsSub == MODAL_KEYS.reviewHistoryTaskPrevious
                ? idClone
                : taskId
            }
            taskCode={task === null ? " " : task.TaskCode}
            task={task === null ? " " : task}
            _taskContents={_taskContents}
            callBack={_onFinishTask}
          />
        ) : null
      ) : (
        <SubModal
          open={openSub}
          handleClose={handleCloseSub}
          funcs={funcsSub}
          taskHistory={taskHistory}
          takeHistoryDetail={takeHistoryDetail}
          idHistory={idHistory}
          bodyAdding={bodyAdding}
          task={task === null ? " " : task}
          isViewHistoty={isViewHistoty}
          taskContentHistoryId={taskContentHistoryId}
          taskId={
            funcsSub == MODAL_KEYS.reviewHistoryTaskPrevious ? idClone : taskId
          }
          taskCode={task === null ? " " : task.TaskCode}
          taskContentsHistory={taskContentsHistory}
          _handleCheckCompled={_handleCheckCompled}
          _taskContents={_taskContents}
          taskInfoText={taskInfoText}
          callBack={_onFinishTask}
          taskDeduct={taskDeduct}
        />
      )}

      <ModalBase
        open={openModal}
        handleClose={handleCloseModal}
        funcs={funcsModal}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Chi tiết</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full drop-shadow-lg transition-all delay-75"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {task === null ||
      taskContents === null ||
      taskResults === null ||
      taskInfoText === null ||
      appData.userList === null ||
      isloading ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <>
          <div className="lg:flex hidden w-full h-[calc(100%-6rem)] overflow-hidden p-4 text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 w-3/5 h-full pr-5 overflow-auto border-r-[1px] border-primary ">
              <p className="font-semibold text-black bg-slate-200 pl-1 pr-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm ">
                Mã công việc - {task === null ? "" : task.TaskCode}
              </p>

              {/* elements */}
              <div className="flex flex-col w-full h-max">
                <label>Tên công việc</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={task === null ? "" : task.TaskName}
                  disabled
                />
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.PriorityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.SecurityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                {/* <div className="w-1/2 h-max flex flex-col">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div> */}
              </div>
              <div className="flex w-full h-max space-x-2">
                <div className="w-full h-max flex flex-col">
                  <label>Cơ sở căn cứ</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder=""
                    value={task === null ? "" : task.NguonViecID}
                    disabled
                  />
                </div>
              </div>
              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Thời gian bắt đầu</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.AssignmentDate}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
              </div>
              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người giao</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskOwnerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Nhập tên công việc"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="w-full h-max">
                <label>Thời gian thực hiện</label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.HoursUsed}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.MinutesUsed}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người đánh giá</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task?.TaskReviewerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Kết quả</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Chưa đánh giá"
                    value={task === null ? "" : task.TaskScorecardName}
                    disabled
                  />
                </div>
              </div>

              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md pt-2 px-2 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}

              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>

              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>
              {_taskContents.length !== 0 ? (
                <div className="flex flex-col w-full h-max">
                  <p>Bước thực hiện</p>
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">Bước</th>
                        <th className="border border-slate-300">Trạng thái</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">Thời hạn</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto drop-shadow-lg">
                      {_taskContents.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white">
                          <td className="text-center border border-slate-300">
                            {item?.SortOrder}
                          </td>
                          <td className="border border-slate-300">
                            <>
                              {(item?.taskContentsHistory || {}).UserAdd !==
                                null &&
                              typeof (item?.taskContentsHistory || {})
                                .UserAdd !== "undefined" &&
                              (item?.taskContentsHistory || {}).UserAdd !==
                                "" ? (
                                <>
                                  {item?.taskContentsHistory.IsCompleted ===
                                  true ? (
                                    <>
                                      <div
                                        style={{
                                          backgroundColor: _handleColor(
                                            item?.taskContentsHistory.Checked,
                                            item?.taskContentsHistory
                                              .OwnerChecked
                                          ),
                                          color: "white",
                                          padding: 5,
                                          textAlign: "center",
                                          borderRadius: 13,
                                          width: "fit-content",
                                        }}
                                      >
                                        {_handleChecked(
                                          item?.taskContentsHistory.Checked,
                                          item?.taskContentsHistory.OwnerChecked
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          backgroundColor: "white",
                                          color: "#00B1FF",
                                          padding: 5,
                                          textAlign: "center",
                                          border: "#00B1FF solid 1px",
                                          borderRadius: 13,
                                          width: "fit-content",
                                        }}
                                      >
                                        Đang cập nhật
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: "#00B1FF",
                                    color: "white",
                                    padding: 5,
                                    textAlign: "center",
                                    borderRadius: 13,
                                    width: "fit-content",
                                  }}
                                >
                                  Chưa có kết quả
                                </div>
                              )}
                            </>
                          </td>
                          <td className="border border-slate-300">
                            <p>{item?.StepName}</p>
                            <p>
                              <i>
                                Người thực hiện: <b>{item?.UserDoName}</b>
                              </i>
                            </p>
                          </td>

                          <td className="text-center border border-slate-300">
                            <p>{moment(item?.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>
                          </td>

                          <td className="text-center border border-slate-300">
                            {/* {task.IsRecipient ? (
                                <>
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <MoreHorizIcon fontSize="small" />
                                    </button>

                                    <div className="dropdown-content text-xs font-semibold">
                                      {item?.UserDo === appData?.userData?.Id ? (
                                        <button
                                          className="flex w-full h-full hover:text-sky-500"
                                          onClick={() =>
                                            _onChangeMultipleChoicePartner(
                                              "multipleUploadFileContent",
                                              item?.ID
                                            )
                                          }
                                        >
                                          <div className="flex w-full h-full space-x-1">
                                            <TaskAltIcon
                                              className="text-sky-600"
                                              sx={{ fontSize: 18 }}
                                              fontSize="small"
                                            />

                                            <div>Cập nhật kết quả</div>
                                          </div>
                                        </button>
                                      ) : null}
                                      <button
                                        className="flex w-full h-full hover:text-sky-500"
                                        onClick={() =>
                                          _onChangeMultipleChoicePartner(
                                            "multipleUploadFileContent",
                                            item?.ID,
                                            1
                                          )
                                        }
                                      >
                                        <div className="flex w-full h-full space-x-1">
                                          <HistoryIcon
                                            className="text-sky-600"
                                            sx={{ fontSize: 18 }}
                                            fontSize="small"
                                          />

                                          <div>Xem lịch sử</div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : ( */}
                            <>
                              {/* {item?.UserDo === appData?.userData?.Id ? ( */}
                              <div className="dropdown">
                                <button className="dropbtn">
                                  <MoreHorizIcon fontSize="small" />
                                </button>

                                <div className="dropdown-content text-xs font-semibold">
                                  {item?.UserDo === appData?.userData?.Id ? (
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onChangeMultipleChoicePartner(
                                          "multipleUploadFileContent",
                                          item?.ID
                                        )
                                      }
                                    >
                                      <div className="flex w-full h-full space-x-1">
                                        <TaskAltIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        <div>Cập nhật kết quả</div>
                                      </div>
                                    </button>
                                  ) : null}
                                  <button
                                    className="flex w-full h-full hover:text-sky-500"
                                    onClick={() =>
                                      _onChangeMultipleChoicePartner(
                                        "multipleUploadFileContent",
                                        item?.ID,
                                        true
                                      )
                                    }
                                  >
                                    <div className="flex w-full h-full space-x-1">
                                      <HistoryIcon
                                        className="text-sky-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      <div>Xem lịch sử</div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                              {/* ) : null} */}
                            </>
                            {/* )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Popover
                    id="simple-popover"
                    open={openContent}
                    anchorEl={anchorEl}
                    onClose={handleCloseShowContent}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                  >
                    {/* <Typography sx={{ p: 2 }}> {content}</Typography> */}
                    <div className="break-word w-max max-w-xl h-max p-2">
                      {content}
                    </div>
                  </Popover>
                </div>
              ) : null}

              {fileListAPI.length !== 0 && fileListAPI !== null ? (
                <div className="flex w-full h-max">
                  <div className="flex flex-col w-1/2 h-max">
                    <label className="font-bold drop-shadow-lg">
                      File đính kèm
                    </label>
                    <div className="flex flex-col w-full h-max">
                      {/* element */}

                      {fileListAPI.map((item: any, index: any) => (
                        <>
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between transition-all drop-shadow-lg hover:text-black"
                          >
                            <a
                              href={item?.listFile}
                              target="_blank"
                              rel="noreferrer"
                              className="flex w-5/6 h-max space-x-1"
                            >
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item?.listFileName}</p>
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 h-max space-y-3">
                  {taskResults.length !== 0 ? (
                    <>
                      <label>Kết quả cần đạt</label>
                      {taskResults.map((item: any, index: any) => (
                        <div className="w-max h-max flex flex-col">
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div className="flex w-max h-max space-x-1">
                              {/* <DoneIcon fontSize="small" /> */}
                            </div>
                            <div className="w-max flex justify-center">
                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={item?.Checked}
                                disabled
                              />
                              <p>{item?.ResultText}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>

              {task === null ? (
                <></>
              ) : task?.TaskStatus === 5 ? (
                <></>
              ) : task?.IsRecipient &&
                task?.TaskStatus !== 5 &&
                !task?.IsCompleted_History ? (
                <div className="flex w-full h-max">
                  <div className="flex justify-start space-x-3 w-full h-[2rem]">
                    <button
                      className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 drop-shadow-lg transition-all delay-75 "
                      onClick={_onClickChooseFileResult}
                    >
                      <AttachFileIcon fontSize="small" />
                      <p>Chọn tệp tin kết quả</p>
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="flex w-full h-max">
                {/* elements */}

                <div className="flex flex-col w-full h-max pr-3">
                  <div className="flex w-full h-max items-center space-x-2">
                    <input
                      ref={uploadFileResult}
                      className={`w-max h-max hidden`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage2(e)}
                    />
                  </div>
                  {/* fields of file */}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}
                    <div className="flex w-full h-max text-primary items-center justify-between">
                      <div className="flex flex-col w-full h-max">
                        {fileListAPI2.length === 0 ? (
                          <></>
                        ) : (
                          fileListAPI2.map((item: any, index: any) => (
                            <div
                              key={index}
                              className="flex w-max h-max transition-all hover:text-black"
                            >
                              <a
                                href={item?.listFile}
                                target="_blank"
                                rel="noreferrer"
                                className="flex h-max space-x-1"
                              >
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item?.listFileName}</p>
                              </a>
                              {task?.IsRecipient &&
                                task?.TaskStatus !== 5 &&
                                !task?.IsCompleted_History && (
                                  <>
                                    <div className="w-max h-max flex space-x-6">
                                      <button
                                        className="text-green-500 w-1/6 flex"
                                        onClick={() =>
                                          _onClickEditFileAlready(
                                            index,
                                            fileListAPI2,
                                            setFileListAPI2
                                          )
                                        }
                                      >
                                        <EditIcon fontSize="small" />
                                      </button>

                                      <button
                                        className="text-red-500 w-1/6 flex"
                                        onClick={() =>
                                          _onDeleteFileReady2(item)
                                        }
                                      >
                                        <DeleteTwoToneIcon fontSize="small" />
                                      </button>
                                    </div>
                                  </>
                                )}
                            </div>
                          ))
                        )}
                        {filesList2.length === 0 ||
                        typeof filesList === "undefined" ? (
                          <></>
                        ) : (
                          filesList2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <div className="flex w-full h-max space-x-1">
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item?.name}</p>

                                <div className="w-max h-max flex space-x-6">
                                  <button
                                    className="text-green-500 w-1/6 flex"
                                    onClick={() =>
                                      _onClickEditFile(
                                        index,
                                        filesList2,
                                        setFilesList2
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </button>

                                  <button
                                    className="text-red-500 w-1/6 flex"
                                    onClick={() => _onDeleteElements2(index)}
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isShowUpload &&
              fileListAPI2.length === 0 &&
              filesList2.length === 0 ? null : (
                <>
                  {task?.IsRecipient &&
                    task?.TaskStatus !== 5 &&
                    !task?.IsCompleted_History && (
                      <div className="flex justify-start space-x-3 w-full h-[2rem] drop-shadow-lg">
                        <button
                          className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 transition-all delay-75"
                          onClick={_onClickUploadFileResult}
                        >
                          <FileUploadIcon fontSize="small" />
                          <p>Tải lên</p>
                        </button>
                      </div>
                    )}
                </>
              )}

              <div className="flex flex-col w-full h-max drop-shadow-lg ">
                <p className="">Lịch sử xử lý công việc</p>
                <table className="w-full">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="border border-slate-300 ">Lần</th>
                      <th className="border border-slate-300">Trạng thái</th>
                      <th className="border border-slate-300">Thời gian</th>
                      <th className="border border-slate-300">
                        Nội dung báo cáo
                      </th>
                      <th className="border border-slate-300">Nhận xét</th>
                      {/* <th className="border border-slate-300">Người duyệt</th> */}
                      <th className="border border-slate-300">Ngày duyệt</th>
                      {urlLink === "/working/receiver/task-waiting" ? (
                        <></>
                      ) : (
                        <th className="border border-slate-300"></th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {taskHistory === null ? (
                      <></>
                    ) : (
                      taskHistory.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white">
                          <td className="text-center border border-slate-300">
                            {index + 1}
                          </td>
                          <td className="border border-slate-300">
                            <div
                              style={{
                                backgroundColor: _handleCheckedColor(
                                  item?.Reason,
                                  item?.Checked
                                ),
                                color: "white",
                                padding: 5,
                                textAlign: "center",
                                borderRadius: 13,
                                width: "fit-content",
                              }}
                            >
                              {_handleCheckedHistory(
                                item?.Reason,
                                item?.Checked
                              )}
                            </div>
                          </td>
                          <td className="border border-slate-300">
                            <div className="w-full h-max flex flex-col justify-center items-center">
                              <p>
                                {moment(item?.DateAdd).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item?.DateAdd).format("HH:mm:ss")}</p>
                            </div>
                          </td>

                          <td className="text-center border border-slate-300">
                            <>
                              <p>
                                {truncateParagraph(
                                  item?.FinishedContent || " ",
                                  4
                                )}
                              </p>
                              {item?.FinishedContent?.split(" ").length > 4 ? (
                                <Button
                                  onClick={(e) => (
                                    handleShowContent(e),
                                    setContent(item?.FinishedContent)
                                  )}
                                  aria-describedby="simple-popover"
                                  title="Xem chi tiết nội dung"
                                >
                                  <MoreHorizIcon />
                                </Button>
                              ) : null}
                            </>
                          </td>
                          <td className="text-center border border-slate-300">
                            <>
                              <p>{truncateParagraph(item?.Reason || " ", 4)}</p>

                              {item?.Reason?.split(" ").length > 4 ? (
                                <Button
                                  onClick={(e) => (
                                    handleShowContent(e),
                                    setContent(item?.Reason)
                                  )}
                                  aria-describedby="simple-popover"
                                  title="Xem chi tiết lý do"
                                >
                                  <MoreHorizIcon />
                                </Button>
                              ) : null}
                            </>
                          </td>
                          {/* <td className="text-center border border-slate-300">
                            <p>{item?.UserCheckedName}</p>
                          </td> */}
                          <td className="text-center border border-slate-300">
                            {item?.CheckedDate ? (
                              <>
                                <p>
                                  {moment(item?.CheckedDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p>
                                  {moment(item?.CheckedDate).format("HH:mm:ss")}
                                </p>
                              </>
                            ) : null}
                          </td>
                          {urlLink === "/working/receiver/task-waiting" ? (
                            <></>
                          ) : (
                            <td className="text-center border border-slate-300">
                              <>
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickDetailHistory(item?.ID, index)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex items-center space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div className="hidden lg:block whitespace-nowrap">
                                      <span className="block">Chi tiết</span>
                                      <span className="block">đánh giá</span>
                                    </div>
                                    <div className="block lg:hidden">
                                      <span className="block">
                                        Chi tiết đánh giá
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <Popover
                  id="simple-popover"
                  open={openContent}
                  anchorEl={anchorEl}
                  onClose={handleCloseShowContent}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                >
                  {/* <Typography sx={{ p: 2 }}> {content}</Typography> */}
                  <div className="break-word w-max max-w-xl h-max p-2">
                    {content}
                  </div>
                </Popover>
              </div>
            </div>

            <div className="styled-scrollbars w-2/5 h-full pl-5 flex flex-col space-y-3 overflow-hidden">
              <div className="styled-scrollbars relative flex flex-col w-full h-full">
                <div className="flex absolute top-0 w-full h-[1.5rem] bg-white font-bold mb-2">
                  <p className="drop-shadow-lg">THÔNG TIN TRAO ĐỔI</p>
                </div>

                <div
                  className="styled-scrollbars absolute top-[1.5rem] bottom-[6.5rem] w-full h-[calc(100%-8rem)] overflow-auto flex flex-col space-y-2"
                  ref={chatContainerRef}
                >
                  {taskInfoText.length <= 0 ? (
                    <></>
                  ) : (
                    taskInfoText.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          className={
                            item?.UserAdd === appData.userData.Id
                              ? "flex flex-col w-full h-max justify-end items-end"
                              : "flex flex-col w-full h-max justify-start items-start"
                          }
                        >
                          <p className="text-xs text-slate-500 drop-shadow-lg">
                            <b>{item?.UserAddName ? item?.UserAddName : ""}</b>
                          </p>
                          <div
                            className={
                              item?.typeofNote === 0
                                ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-slate-400 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black drop-shadow-lg transition-all hover:bg-slate-400"
                                : item?.typeofNote === 1
                                ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-green-400 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black drop-shadow-lg transition-all hover:bg-green-400"
                                : "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-red-400 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black drop-shadow-lg transition-all hover:bg-red-400"
                            }
                          >
                            <div className="w-full h-full flex flex-col">
                              <p>{item?.Contents}</p>
                              {item?.listFileInChat.length > 0 ? (
                                <>
                                  {item?.listFileInChat.map(
                                    (itemFChat: any, indexFChat: any) => (
                                      <a
                                        key={indexFChat}
                                        className={
                                          item?.UserAdd === appData.userData.Id
                                            ? `text-white text-xs hover:underline`
                                            : `text-primary text-xs hover:underline`
                                        }
                                        href={itemFChat.listFile}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <FindInPageIcon fontSize="small" />
                                        {itemFChat.listFileName}
                                      </a>
                                    )
                                  )}
                                </>
                              ) : null}
                            </div>
                            <p className="text-left text-[12px] text-white-500">
                              {moment(item?.DateAdd).format("HH:mm:ss")} -{" "}
                              {moment(item?.DateAdd).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </div>
                {task?.TaskStatus === 5 ? null : (
                  <div className="flex flex-col absolute bottom-0 w-full h-[6.5rem] bg-slate-100 mt-2 rounded-sm">
                    <div className="flex items-center w-full h-[2rem]">
                      {/* elements */}

                      <div className="flex w-max h-full space-x-2 text-xs">
                        <button
                          className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 drop-shadow-lg transition-all delay-75 "
                          onClick={_onClickChooseFileChat}
                        >
                          <AttachFileIcon fontSize="small" />
                          <p>Chọn tệp tin</p>
                        </button>
                      </div>
                      <input
                        ref={uploadFileChat}
                        className={`w-max h-max`}
                        multiple
                        type="file"
                        onChange={(e: any) => _onChangeFileImage(e)}
                        hidden
                      />
                      <p
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        {filesList.length > 0
                          ? filesList.length + " tệp tin được chọn"
                          : ""}
                      </p>

                      <div className="flex w-max h-full space-x-2 text-xs">
                        <button
                          className={`
            flex justify-center pl-1 pr-1 items-center w-max h-full text-slate-500 hover:text-slate-400 drop-shadow-lg transition-all delay-75
            ${selectedTypeOf === 0 ? "bg-slate-200" : ""}
          `}
                          onClick={() => setSelectedTypeOf(0)}
                        >
                          <p>Bình thường</p>
                        </button>
                        <button
                          className={`
            flex justify-center pl-1 pr-1 items-center w-max h-full text-green-500 hover:text-green-400 drop-shadow-lg transition-all delay-75
            ${selectedTypeOf === 1 ? "bg-slate-200" : ""}
          `}
                          onClick={() => setSelectedTypeOf(1)}
                        >
                          <p>Ưu tiên</p>
                        </button>
                        <button
                          className={`
            flex justify-center pl-1 pr-1 items-center w-max h-full text-red-500 hover:text-red-400 drop-shadow-lg transition-all delay-75
            ${selectedTypeOf === 2 ? "bg-slate-200" : ""}
          `}
                          onClick={() => setSelectedTypeOf(2)}
                        >
                          <p>Khẩn cấp</p>
                        </button>
                      </div>
                    </div>

                    <div className="flex w-full h-[4.5rem]">
                      <div className="w-4/5 flex h-full">
                        <textarea
                          className="w-full h-full rounded-md p-2 border-2 border-slate-200"
                          placeholder="Nhập nội dung"
                          value={text}
                          onChange={(e: any) => {
                            e.preventDefault();
                            setText(e.target.value);
                          }}
                        />
                      </div>

                      <div className="w-1/5 h-full flex justify-center items-center">
                        <button
                          className="bg-primary hover:bg-green-500 w-max h-max pl-3 pr-3 pt-1.5 pb-1.5 rounded-md font-semibold drop-shadow-lg transition-all delay-75"
                          onClick={_onClickSentChat}
                        >
                          <div className="flex text-white">
                            {isSendingChat === false ? (
                              <>
                                <span style={{ paddingRight: 3 }}>Gửi</span>
                                <SendIcon fontSize="small" />
                              </>
                            ) : (
                              <div className="w-full h-full flex justify-center items-center">
                                <LoaderSpinner w={20} h={20} />
                              </div>
                            )}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/*  phone */}
          <div className="lg:hidden flex w-full h-[calc(100%-6rem)] p-0 overflow-hidden text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 p-4 w-full h-full overflow-auto border-r-[1px] border-primary ">
              {/*  TaskName - TaskCode */}
              <div>
                <p className="flex w-full font-semibold drop-shadow-lg">
                  {task === null ? "" : task.TaskName} -{" "}
                  {task === null ? "" : task.TaskCode}
                </p>
              </div>
              {/*  Uu tien */}
              <div className="flex w-full h-max drop-shadow-lg gap-3">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Mức độ</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.PriorityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.SecurityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max gap-3">
                <div className="w-1/2 h-max flex flex-col">
                  <label className="drop-shadow-lg">Người giao</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskOwnerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label className="drop-shadow-lg">Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max gap-3">
                <div className="w-full h-max flex flex-col">
                  <label className="drop-shadow-lg">Thời gian bắt đầu</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.AssignmentDate}
                    disabled
                  />
                </div>
              </div>
              <div className="flex w-full h-max gap-3">
                <div className="w-full h-max flex flex-col">
                  <label className="drop-shadow-lg">Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max gap-3">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người đánh giá</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task?.TaskReviewerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Kết quả</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Chưa đánh giá"
                    value={task === null ? "" : task.TaskScorecardName}
                    disabled
                  />
                </div>
              </div>

              <div className="w-full h-max">
                <label>Thời gian thực hiện</label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.HoursUsed}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.MinutesUsed}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label className="drop-shadow-lg">Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md px-2 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}
              {/*Description*/}
              <div className="flex flex-col w-full h-max">
                <label className="drop-shadow-lg">Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>
              {/*Noted*/}
              <div className="flex flex-col w-full h-max">
                <label className="drop-shadow-lg">Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>

              {fileListAPI.length !== 0 && fileListAPI !== null ? (
                <div className="flex w-full h-max">
                  <div className="flex flex-col w-1/2 h-max">
                    <label className="font-bold drop-shadow-lg">
                      File đính kèm
                    </label>
                    <div className="flex flex-col w-full h-max">
                      {/* element */}

                      {fileListAPI.map((item: any, index: any) => (
                        <>
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between drop-shadow-lg active:text-black"
                          >
                            <a
                              href={item?.listFile}
                              target="_blank"
                              rel="noreferrer"
                              className="flex w-5/6 h-max space-x-1"
                            >
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item?.listFileName}</p>
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {/*Step Table*/}
              {_taskContents.length !== 0 ? (
                <div className="flex flex-col w-full h-max">
                  <p>Bước thực hiện</p>
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">Bước</th>
                        <th className="border border-slate-300">Trạng thái</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">Thời hạn</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {_taskContents.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white">
                          <td className="text-center border border-slate-300">
                            {item?.SortOrder}
                          </td>
                          <td className="border border-slate-300">
                            <>
                              {item?.taskContentsHistory &&
                              item?.taskContentsHistory.IsCompleted === true ? (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: _handleColor(
                                        item?.taskContentsHistory.Checked,
                                        item?.taskContentsHistory.OwnerChecked
                                      ),
                                      color: "white",
                                      padding: 5,
                                      textAlign: "center",
                                      borderRadius: 13,
                                      width: "fit-content",
                                    }}
                                  >
                                    {_handleChecked(
                                      item?.taskContentsHistory.Checked,
                                      item?.taskContentsHistory.OwnerChecked
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: "#00B1FF",
                                    color: "white",
                                    padding: 5,
                                    textAlign: "center",
                                    borderRadius: 13,
                                    width: "fit-content",
                                  }}
                                >
                                  Chưa có kết quả
                                </div>
                              )}
                            </>
                          </td>
                          <td className="border border-slate-300">
                            <p>{item?.StepName}</p>
                            <p>
                              <i>
                                Người thực hiện: <b>{item?.UserDoName}</b>
                              </i>
                            </p>
                          </td>

                          <td className="text-center border border-slate-300">
                            <p>{moment(item?.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>
                          </td>

                          <td className="text-center border border-slate-300">
                            <>
                              <div className="dropdown">
                                {/* button options */}
                                <button className="dropbtn">
                                  <MoreHorizIcon fontSize="small" />
                                </button>

                                {/* options fields */}
                                <div className="dropdown-content text-xs font-semibold">
                                  <button
                                    className="flex w-full h-full hover:text-sky-500"
                                    onClick={() =>
                                      _onChangeMultipleChoicePartner(
                                        "multipleUploadFileContent",
                                        item?.ID,
                                        true
                                      )
                                    }
                                  >
                                    {/* detail - contain */}
                                    <div className="flex w-full h-full space-x-1">
                                      {/* icon */}
                                      <HistoryIcon
                                        className="text-sky-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div>Xem lịch sử</div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
              {/* Result */}
              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 h-max space-y-3">
                  {taskResults.length !== 0 ? (
                    <>
                      <label>Kết quả cần đạt</label>
                      {taskResults.map((item: any, index: any) => (
                        <div className="w-max h-max flex flex-col">
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div className="flex w-max h-max space-x-1">
                              {/* <DoneIcon fontSize="small" /> */}

                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={item?.Checked}
                                disabled
                              />
                              <p>{item?.ResultText}</p>
                            </div>
                            {/* <div className="w-max flex justify-center">
                            <input
                              type="checkbox"
                              checked={item?.Checked}
                              disabled
                            />
                          </div> */}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
              {task === null ? (
                <></>
              ) : task?.TaskStatus === 5 ? (
                <></>
              ) : task?.IsRecipient &&
                task?.TaskStatus !== 5 &&
                !task?.IsCompleted_History ? (
                <div className="flex w-full h-max">
                  <div className="flex justify-start space-x-3 w-full h-[2rem]">
                    <button
                      className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 drop-shadow-lg "
                      onClick={_onClickChooseFileResult}
                    >
                      <AttachFileIcon fontSize="small" />
                      <p>Chọn tệp tin kết quả</p>
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="flex w-full h-max">
                {/* elements */}

                <div className="flex flex-col w-1/2 h-max pr-3">
                  <div className="flex w-full h-max items-center space-x-2">
                    <input
                      ref={uploadFileResult}
                      className={`w-max h-max hidden`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage2(e)}
                    />
                  </div>
                  {/* fields of file */}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}
                    <div className="flex w-full h-max text-primary items-center justify-between">
                      <div className="flex flex-col w-full h-max">
                        {fileListAPI2.length === 0 ? (
                          <></>
                        ) : (
                          fileListAPI2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <a
                                href={item?.listFile}
                                target="_blank"
                                rel="noreferrer"
                                className="flex h-max space-x-1"
                              >
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item?.listFileName}</p>
                              </a>
                              {task?.IsRecipient &&
                                task?.TaskStatus !== 5 &&
                                !task?.IsCompleted_History && (
                                  <>
                                    <div className="w-max h-max flex space-x-6">
                                      <button
                                        className="text-green-500 w-1/6 flex"
                                        onClick={() =>
                                          _onClickEditFileAlready(
                                            index,
                                            fileListAPI2,
                                            setFileListAPI2
                                          )
                                        }
                                      >
                                        <EditIcon fontSize="small" />
                                      </button>

                                      <button
                                        className="text-red-500 w-1/6 flex"
                                        onClick={() =>
                                          _onDeleteFileReady2(item)
                                        }
                                      >
                                        <DeleteTwoToneIcon fontSize="small" />
                                      </button>
                                    </div>
                                  </>
                                )}

                              {/* <button
                                className="text-red-500 w-1/6 flex justify-end"
                                onClick={() => _onDeleteFileReady2(item)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button> */}
                            </div>
                          ))
                        )}
                        {filesList2.length === 0 ||
                        typeof filesList === "undefined" ? (
                          <></>
                        ) : (
                          filesList2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <div className="flex w-5/6 h-max space-x-1">
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item?.name}</p>

                                <div className="w-max h-max flex space-x-6">
                                  <button
                                    className="text-green-500 w-1/6 flex"
                                    onClick={() =>
                                      _onClickEditFile(
                                        index,
                                        filesList2,
                                        setFilesList2
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </button>

                                  <button
                                    className="text-red-500 w-1/6 flex"
                                    onClick={() => _onDeleteElements2(index)}
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              </div>

                              {/* <button
                                className="text-red-500 w-1/6 flex justify-end"
                                onClick={() => _onDeleteElements2(index)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button> */}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {fileListAPI2.length === 0 && filesList2.length === 0 ? null : (
                <>
                  {task?.IsRecipient &&
                    task?.TaskStatus !== 5 &&
                    !task?.IsCompleted_History && (
                      <div className="flex justify-start space-x-3 w-full h-[2rem] drop-shadow-lg">
                        <button
                          className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                          onClick={_onClickUploadFileResult}
                        >
                          <FileUploadIcon fontSize="small" />
                          <p>Tải lên</p>
                        </button>
                      </div>
                    )}
                </>
              )}

              <div className="flex flex-col w-full h-max">
                <table className="w-full">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="border border-slate-300 drop-shadow-lg">
                        Lịch sử xử lý công việc
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {taskHistory === null ? (
                      <></>
                    ) : (
                      taskHistory.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white ">
                          <td className="border border-slate-300">
                            <div className="flex w-full justify-between">
                              <p className="font-bold">
                                Báo cáo lần {index + 1}:
                              </p>
                              <div
                                className={`drop-shadow-lg font-semibold`}
                                style={{
                                  backgroundColor: _handleCheckedColor(
                                    item?.Reason,
                                    item?.Checked
                                  ),
                                  color: "white",
                                  padding: 5,
                                  textAlign: "center",
                                  borderRadius: 13,
                                  width: "fit-content",
                                }}
                              >
                                {_handleCheckedHistory(
                                  item?.Reason,
                                  item?.Checked
                                )}
                              </div>
                            </div>
                            <p>
                              Thời gian:&nbsp;
                              <span className="italic">
                                {moment(item?.DateAdd).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )}
                              </span>
                            </p>

                            <p>Nội dung duyệt:&nbsp;{item?.FinishedContent}</p>

                            {item?.Checked === false ? (
                              <p>
                                Lý do bị trả về:&nbsp;
                                <span className="font-bold text-red-500">
                                  {item?.Reason}
                                </span>
                              </p>
                            ) : (
                              item?.Checked === true && (
                                <p>
                                  Nhận xét:&nbsp;
                                  <span className="font-bold text-green-500">
                                    {item?.Reason}
                                  </span>
                                </p>
                              )
                            )}

                            {item?.CheckedDate ? (
                              <>
                                <p>
                                  Ngày duyệt:&nbsp;
                                  <span className="italic">
                                    {moment(item?.CheckedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </span>
                                </p>
                              </>
                            ) : null}
                            {urlLink === "/working/receiver/task-waiting" ? (
                              <></>
                            ) : (
                              <td>
                                <>
                                  <button
                                    className="flex h-full hover:text-sky-500 ml-[-20px]"
                                    onClick={() =>
                                      _onClickDetailHistory(item?.ID, index)
                                    }
                                  >
                                    {/* detail - contain */}
                                    <div className="flex items-center space-x-1">
                                      {/* icon */}
                                      <DescriptionTwoToneIcon
                                        className="text-sky-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div className="hidden lg:block whitespace-nowrap">
                                        <span className="block">Chi tiết</span>
                                        <span className="block">đánh giá</span>
                                      </div>
                                      <div className="block lg:hidden">
                                        <span className="block">
                                          Chi tiết đánh giá
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                </>
                              </td>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm">
        {task === null ? (
          <div className="flex h-max w-max space-x-4"></div>
        ) : task.Recipient === appData.userData.Id ? (
          <div className="flex max-lg:flex-wrap max-lg:w-full justify-end h-max gap-3">
            {!props.isReviewHistoryTaskPrevious && (
              <>
                {/* {urlLink === "/working/receiver/task-processing" ||
                  urlLink === "/working/receiver/task-delay" ||
                  urlLink === "/working/receiver/all-task" ||
                  urlLink === "/working/receiver/task-end-today" ||
                  urlLink === "/working/receiver/task-end-soon" ||
                  urlLink === "/working/receiver/task-returnwork" ? (
                  <> */}
                {task?.TaskStatus === 4 ? (
                  <>
                    {isHaveSupport && !isCheckSupporter && (
                      <div>
                        <p className="text-red-500 font-bold">
                          Bạn vui lòng đánh giá người hỗ trợ trước khi Hoàn tất
                          công việc *
                        </p>
                      </div>
                    )}
                    <button
                      className="flex font-bold items-center w-max h-max p-2 border border-primary hover:bg-primary text-primary hover:text-white rounded-md drop-shadow-lg transition-all delay-65"
                      onClick={() => _onClickSubModal("askExtend")}
                    >
                      <MoreTimeIcon fontSize="small" />
                      <p>Xin gia hạn</p>
                    </button>

                    {isHaveSupport && !isCheckSupporter && (
                      <button
                        className="
                              flex items-center  w-max h-max p-2 border border-green-500 bg-green-500 text-white rounded-md drop-shadow-lg transition-all delay-75
                            hover:bg-green-400 
                            "
                        onClick={() =>
                          _onClickSubModal(MODAL_KEYS.reviewSupporter)
                        }
                      >
                        <DoneIcon fontSize="small" />
                        <p>Đánh giá người hỗ trợ</p>
                      </button>
                    )}
                    {isProcessing &&
                      isCheckSupporter &&
                      isShowConfirmtaskForUser && (
                        <button
                          className="
                              flex items-center w-max h-max p-2 border border-green-500 bg-green-500 text-white rounded-md drop-shadow-lg transition-all delay-75
                            hover:bg-green-400 
                            "
                          onClick={() => _onClickSubModal("finishTask")}
                        >
                          <DoneIcon fontSize="small" />
                          <p>Hoàn tất</p>
                        </button>
                      )}
                  </>
                ) : // ) :
                // </>
                task?.TaskStatus === 5 ? (
                  <></>
                ) : (
                  <>
                    {urlLink === "/working/receiver/task-waiting" ? (
                      <></>
                    ) : (
                      //   <button
                      //     className="
                      //   flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-amber-500 text-white rounded-md drop-shadow-lg transition-all delay-75
                      //   hover:bg-amber-400
                      // "
                      //     onClick={() => _onClickSubModal("notiTask")}
                      //   >
                      //     <NotificationsActiveIcon fontSize="small" />
                      //     <p>Nhắc việc</p>
                      //   </button>
                      <></>
                    )}
                  </>
                )}
                {task?.TaskStatus !== 5 ? (
                  <button
                    className="lg:hidden flex  items-center w-max h-max p-2 hover:bg-sky-400 bg-primary text-white rounded-md drop-shadow-lg transition-all delay-75 "
                    onClick={() =>
                      _onChangeMultipleChoicePartner("chatPopup", null, false)
                    }
                  >
                    <SendIcon fontSize="small" />
                    <p>Trao đổi</p>
                  </button>
                ) : null}
              </>
            )}
            {idClone && (
              <ReviewHistoryTaskPreviousComponent
                id={idClone}
                onOpenHistoryTaskPrevious={onOpenHistoryTaskPrevious}
              />
            )}
            {/* <button
              className="lg:hidden flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md"
              // onClick={() =>
              //   _onChangeMultipleChoicePartner("chatPopup", null, null)
              // }
            >
              <SendIcon fontSize="small" />
              <p>Trao đổi</p>
            </button> */}
            {taskDeduct.length > 0 && (
              <button
                className="
                  flex items-center space-x-1 w-max h-max p-2 bg-amber-400 text-white rounded-md
                  hover:bg-amber-300
                "
                onClick={() => _onClickListTaskDeduct()}
              >
                <DeviceHubIcon fontSize="small" />
                <p>Danh sách chia việc</p>
              </button>
            )}
            <button
              className="
                hidden lg:flex items-center space-x-1 w-max h-max p-2 bg-slate-500 text-white rounded-md drop-shadow-lg transition-all delay-75
              hover:bg-slate-400 
              "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        ) : (
          <div className="flex h-max w-max space-x-4">
            {/* {
                  urlLink === "/working/receiver/task-processing" ?
                    <button className="
            flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-green-500 text-white rounded-md
            hover:bg-green-400
          "
                      onClick={() => _onClickSubModal("finishTask")}
                    >
                      <DoneIcon fontSize="small" />
                      <p>Hoàn tất</p>
                    </button>
                    :
                    <button className="
            flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-amber-500 text-white rounded-md
            hover:bg-amber-400
          "
                      onClick={() => _onClickSubModal("notiTask")}
                    >
                      <NotificationsActiveIcon fontSize="small" />
                      <p>Nhắc việc</p>
                    </button>
                } */}

            {idClone && (
              <ReviewHistoryTaskPreviousComponent
                id={idClone}
                onOpenHistoryTaskPrevious={onOpenHistoryTaskPrevious}
              />
            )}
            <button
              className="
                flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
              "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadTotalTask;
