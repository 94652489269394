import React from "react";

// ui importing
import ForgetPasswordUI from "../../ui/forgetPasswordUI/_index";

const ForgetPasswordPage = () => {
  return (
    <div className="w-full h-full">
      <ForgetPasswordUI />
    </div>
  )
}

export default ForgetPasswordPage;
