import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ONCHANGE_DATE,
  ONCHANGE_SELECTED_BOSS,
  ONCHANGE_FILTER_SELECTED_PHONGBAN,
  ONCHANGE_FROM_HOME_TO_PROCESSING,
  ONCHANGE_RESET_FILTER,
} from "../../redux/reducers/workingReducer/_index";
import DateTimePickerControl from "../../controls/datetimePicker/datetimePickerControl";
import Select from "react-select";
import HandleDate from "../../utils/date/handleDate";

const WorkingReceiverAllJSX = () => {
  const dispatch = useDispatch();
  const workingData = useSelector((state: any) => state.workingReducer);
  const urlLink = window.location.pathname;

  const tempBossList = workingData.bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });
  // useEffect(() => {
  //   dispatch(ONCHANGE_RESET_FILTER({ url: urlLink }));
  // }, []);
  // const [fromDate, setFromDate] = useState<string>(
  //   workingData.searchFilter.fromDate === ""
  //     ? new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
  //         .toISOString()
  //         .slice(0, 16)
  //     : workingData.searchFilter.fromDate
  // );
  // const [toDate, setToDate] = useState<string>(
  //   workingData.searchFilter.toDate === ""
  //     ? new Date(
  //         new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
  //       )
  //         .toISOString()
  //         .slice(0, 16)
  //     : workingData.searchFilter.toDate
  // );
  return (
    <>
      <div className="grid md:grid-cols-2 lg:flex w-full h-max gap-3 xl:gap-5">
        {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                  <label>Ngày giao (Từ ngày)</label>
                  <input
                      className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                      type="datetime-local"
                      value={fromDate}
                      onChange={(e: any) => {
                          setFromDate(e.target.value);
                          dispatch(
                              ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
                          );
                      }}
                  // onTouchStart={(e: any) => onTouchForm(e)}
                  />
              </div> */}

        <DateTimePickerControl
          label={"Ngày giao (Từ ngày)"}
          value={workingData.searchFilter.fromDate}
          onChange={(e: any) => {
            const value = e;

            dispatch(ONCHANGE_DATE({ type: "fromDate", value }));
          }}
        />

        {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                  <label>Ngày giao (Đến ngày)</label>
                  <input
                      className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                      type="datetime-local"
                      value={toDate}
                      onChange={(e: any) => {
                          setToDate(e.target.value);
                          dispatch(
                              ONCHANGE_DATE({
                                  type: "toDate",
                                  value: e.target.value || toDate,
                              })
                          );
                      }}
                  />
              </div> */}

        <DateTimePickerControl
          label={"Ngày giao (Đến ngày)"}
          value={workingData.searchFilter.toDate}
          onChange={(e: any) => {
            const value = e;
            dispatch(ONCHANGE_DATE({ type: "toDate", value: value }));

            // dispatch(
            //   ONCHANGE_DATE({
            //     type: "toDate",
            //     value: value || toDate,
            //   })
            // );
          }}
        />

        {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                  <label>Thời hạn</label>
                  <input
                      className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                      placeholder="Thời hạn"
                      type="date"
                      value={workingData.searchFilter.deadLine}
                      onChange={(e: any) =>
                          dispatch(
                              ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
                          )
                      }
                      onTouchStart={(e: any) => onTouchForm(e)}
                  />
              </div> */}

        <DateTimePickerControl
          label={"Thời hạn"}
          value={workingData.searchFilter.deadLine}
          onChange={(e: any) => {
            const value = e;

            // setToDate(value);
            dispatch(
              dispatch(ONCHANGE_DATE({ type: "deadLine", value: value }))
            );
          }}
        />

        {urlLink === "/working/receiver/all-task" && (
          <div className="flex flex-col lg:w-1/3 w-full h-max">
            <label>Người giao</label>
            <Select
              className="z-[2] mt-1"
              options={tempBossList}
              onChange={(data: any) => dispatch(ONCHANGE_SELECTED_BOSS(data))}
              isClearable
              placeholder="--- Chọn người giao ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
      </div>

      {/* thangdong20230927 */}
      {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
              <label>Người giao</label>
              <Select
                  className="z-[5]"
                  options={tempBossList}
                  onChange={(data: any) => dispatch(ONCHANGE_SELECTED_BOSS(data))}
                  isClearable
                  placeholder="--- Chọn người giao ---"
                  components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                  }}
              />
          </div> */}
    </>
  );
};
export default WorkingReceiverAllJSX;
