import React, { useState, useEffect, useRef } from "react";
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";

import { REPORT_USERREDWORK } from "../../../../apis/_index";

import { readReport } from "../../../../funcs/reportCalling";
import { takePhongban } from "../../../../funcs/reportCalling";
import { takeUser } from "../../../../funcs/reportCalling";
import LevelComponent from "../../../../components/organizations/levels/levelComponent";
import OrganizationUtils from "../../../../utils/organizations/organizationUtils";
import OrganizationRepository from "../../../../repositories/organizations/organizationRepository";

import '../../../../styles/reportUserRedworks/index.css';

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const UserRedWorkReportUI = (props: any) => {
  const [data, setData] = useState<any[] | null>(null);

  const [arraySoNgay, setArraySoNgay] = useState<any[] | null>(null);
  const [arrayThang, setArrayThang] = useState<any[] | null>(null);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [userList, setUserList] = useState<any[] | null>(null);

  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);
  const [selectedUser, setSelectedUser] = useState<any>(-1);

  const [fromDate, setFromDate] = useState<any | null>(
    new Date().getFullYear()
  );
  const [toDate, setToDate] = useState<any | null>(new Date().getFullYear());

  const shouldLog = useRef(true);

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      if (res.status) {
        const data = res.data || [];

        const organizationTrees = OrganizationUtils.getListTree(data, {
          id: 'PhongBanId',
          parentId: 'RefID'
        });

        const organizationTreeValueLabels = OrganizationUtils.convertToListValueLabel(organizationTrees, {
          value: 'PhongBanId',
          label: 'PhongBan'
        });

        setPhongBanList(organizationTreeValueLabels);
      }
    });
  }

  useEffect(() => {
    getListOrganization();
  }, []);

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: false,
      };

      readReport(REPORT_USERREDWORK, body, (res: any) => {
        setData(res.data.Data);
      });

      // takePhongban((res: any) => setPhongBanList(res.data.Data));
      takeUser((res: any) => setUserList(res.data.Data));
    }
  });

  useEffect(() => {
    if (data === null) {
    } else {
      let tempArraySoNgay: any[] = [];
      let tempArrayThang: any[] = [];

      data.forEach((item: any) => {
        let tempSoNgay = item.SoNgay;
        let tempThang = "Tháng " + item.Thang;

        tempArraySoNgay.push(tempSoNgay);
        tempArrayThang.push(tempThang);
      });

      setArraySoNgay(tempArraySoNgay);
      setArrayThang(tempArrayThang);
    }
  }, [data]);

  // filter
  const _onClickSubmit = () => {
    let body: any;

    if (selectedPhongban !== -1 && selectedUser !== -1) {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        PhongBan: selectedPhongban,
        UserID: selectedUser.toString(),
        _isCurUser: false,
      };
    } else if (selectedPhongban === -1 && selectedUser !== -1) {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        UserID: selectedUser.toString(),
        _isCurUser: false,
      };
    } else if (selectedPhongban !== -1 && selectedUser === -1) {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        PhongBan: selectedPhongban,
        _isCurUser: false,
      };
    } else {
      body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: false,
      };
    }

    readReport(REPORT_USERREDWORK, body, (res: any) => {
      setData(res.data.Data);
    });
  };

  return (
    <>
      <br></br>
      <div className="pl-2 pr-3 flex lg:flex-row flex-col w-full h-max gap-2 core-report-user-redwork-filter">
        <div className="flex flex-col pr-0 h-max">
          <label>Năm</label>
          <input
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 core-input-resize"
            placeholder="Năm"
            value={fromDate}
            type="number"
            id="year"
            name="year"
            min="1900"
            max="2099"
            onChange={(e: any) => {
              e.preventDefault();
              setFromDate(e.target.value);
              setToDate(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-col lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col h-max">
            <div className="flex flex-col pr-0 h-max">
              <label>Phòng ban</label>
              <select
                className="h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 core-select-resize"
                value={selectedPhongban}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSelectedPhongban(parseInt(e.target.value));
                }}
              >
                <option className="text-gray-300" value={-1}>
                  --- Xem tất cả ---
                </option>
                {phongBanList === null ? (
                  <></>
                ) : (
                  phongBanList.map((item: any, index: any) => (
                    <option key={index} value={item.PhongBanId}>
                      <LevelComponent item={item} />{item.PhongBan}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-col pr-0 h-max">
          <label>Nhân viên</label>
          <select
            className="h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 core-select-resize"
            value={selectedUser}
            onChange={(e: any) => {
              e.preventDefault();
              setSelectedUser(e.target.value);
            }}
          >
            <option className="text-gray-300" value={-1}>
              --- Xem tất cả ---
            </option>
            {userList === null ? (
              <></>
            ) : (
              userList.map((item: any, index: any) => (
                <option key={index} value={item.Id}>
                  {item.FullName}
                </option>
              ))
            )}
          </select>
        </div>
        <div
          className="flex flex-col lg:w-1/2 w-full lg:pr-7 pr-0 h-max"
          style={{ textAlign: "center" }}
        >
          <label>&nbsp; </label>
          <button
            className="
            flex items-center p-2 bg-primary rounded-md text-white
            hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 core-button-resize
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>
      <hr style={{ margin: "20px" }}></hr>
      {data === null || arraySoNgay === null || arrayThang === null ? (
        <></>
      ) : (
        <div className="flex w-full lg:h-4/5 h-[20rem] p-3">
          <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
            <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold lg:text-base text-xs">
              BIỂU ĐỒ TỔNG SỐ NGÀY TRỄ TIẾN ĐỘ THEO NHÂN VIÊN
            </div>
            <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
              <Bar
                className="w-full h-full"
                datasetIdKey="id"
                data={{
                  labels: arrayThang,
                  datasets: [
                    {
                      label: "Tổng ngày trễ tiến độ",
                      data: arraySoNgay,
                      backgroundColor: ["#FF0000"],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    datalabels: {
                      color: "black",
                      font: {
                        size: 13,
                        weight: "bold",
                      },
                      // formatter: (value: any) => `${value}`,
                      formatter: function (value: any, context) {
                        return value !== 0 ? value : "";
                      },
                      anchor: "center",
                      align: "end",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserRedWorkReportUI;
