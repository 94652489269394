import React from "react";

import ContentCore from "../../../core/workingContent";

import "../_index.css";

const TaskEndSoon = () => {
  return (
    <>
      <ContentCore />
    </>
  )
}

export default TaskEndSoon;
