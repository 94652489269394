import React from "react";
import { Modal } from "@mui/material";

// components importing
import MultipleChoicePartner from "./multipleChoicePartner";
import ChooseTaskInsert from "./chooseTaskInsert";
import MultipleChoiceStep from "./multipleChoiceStep";
import DeleteTask from "./deleteTask";
import SwitchTaskToBoss from "./switchBoss";
import GiveTask from "./giveTask";
import ConfirmTaskMySelf from "./confirmTaskMySelf";

import SwitchTask from "../working/switchTask";

import PlanSend from "./planSend";
import PlanDelete from "./planDelete";

import DeleteLeaving from "./deleteLeaving";

import EditFileSubAlready from "./editFileAlready";

import EditDeadLineNguoiGiao from "./editDeadLineNguoiGiao";
import ReceiveAndStartTask from "./_receiveAndStart";

import EditFileSub from "./editFileSub";
import SubViewReport from "./_subViewReport";

import DeleteTaskList from "./deleteTaskList";
import DeletePrior from "./deletePrior";
import DeleteUser from "./deleteUser";
import DeleteEmployee from "./deleteEmployee";

import ReportDelay from "./_ReportDelay";

import DeleteGeneral from "./deleteGeneral";
import ChoosePermission from "./choosePermission";

import ConfirmReport from "./confirmReport";

import DeleteSecurity from "./deleteSecurity";
import DeleteQuytrinh from "./deleteQuytrinh";

import EditBuoc from "./editBuoc";
import EditKetqua from "./editKetqua";
import ShareTask from "./shareTask";

import ReceiveTask from "./receiveTask";

import DELETE_TIEUCHI from "./deleteTieuChi";
import StartTaskSub from "./startTaskSub";
import FinishTask from "./finishTask";
import AskExtend from "./askExtend";
import ConfirmExtend from "./confirmExtend";
import ReTasking from "./reTasking";
import ConfirmTask from "./confirmTask";

import CheckPlanning from "./checkPlanning";

import DetailHistory from "./detailHistory";

import DetailOfTask from "./_detailOfTask";

import DeleteDificult from "./deleteDificultRate";

import NotiTask from "./notiTask";
import CompletedTaskContent from "./completedTaskContent";

import ComfirmCompleteTask from "./confirmCompleteTask";
import SwitchBoss from "./switchBoss";

import EditPlanning from "./editPlan";

import MultipleUploadFileContent from "./multipleUploadFileContent";
import ConfirmTaskContents from "./confirmTaskContent";
import ChatPopup from "./chatPopup";
import VotingTask from "../working/votingTask";

import CreateWorkflowSub from "./createWorkFlowSub";

import CheckViewPort from "./checkViewport";

import ReportDelete from "./reportDelete";
import ReportSend from "./reportSend";

import ChangeTaskReviewer from "./changeTaskReviewer";
import ModalMinimizeControl, {
  ModalMinimizeHeaderControl,
} from "../../../controls/modalMinimize/modalMinimizeControl";
import { MODAL_KEYS } from "../../../constants/constants";
import RecoveryTaskModal from "./recoveryTaskModal";
import HistoryTaskRecovery from "./historyTaskRecovery";
import EditTaskRecoveryAndTransfer from "../working/editTaskRecoveryAndTransfer";
import ChooseBossNoti from "./chooseBossNoti";
import ReviewHistoryTaskPreviousSubModal from "./reviewHistoryTaskPreviousSubModal";
import AlertUploadFile from "../working/alertUploadFile";
import SaveAndGiveTask from "../working/saveAndGiveTask";
import DeleteHoliday from "./deleteHoliday";
import DeleteMeeting from "./deleteMeeting";
import CreateNewTask from "../working/createNewTask";
import ListTaskDeduct from "./listTaskDeduct";
import TaskNeedConfirm from "../working/taskNeedConfirm";
import ReadTotalTask_nguoigiao from "../working/readTotalTask_nguoigiao";
import DetailOnLeaving from "../onLeave/detailOnLeaving";
import DetailOnHoliday from "../onHoliday/detailOnHoliday";
import DetailMeeting from "../onMeeting/detailMeeting";
import DeleteBusinessTrip from "./deleteBusinessTrip";
import DetailBusinessTrip from "../onBusinessTrip/detailBusinessTrip";
import DetailReportWaiting from "../reportElectric/_detailReportWaiting";
import DetailReportProactive from "../reportElectric/_detailReportProactive";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const SubModal = (props: any) => {
  // take props
  const funcs = props.funcs;
  const open = props.open;
  const handleClose = props.handleClose;
  const handleClose2 = props.handleClose2;
  const _onChangeWorkerViaDepart = props._onChangeWorkerViaDepart;

  const _onClickSubmit = props._onClickSubmit;

  const hourExtend = props.hourExtend;

  const UserId = props.UserId;
  const taskId = props.taskId;
  const isResult = props.isResult;
  const timeExtend = props.timeExtend;
  const hoursExtend = props.hoursExtend;
  const extendId = props.extendId;
  const id = props.id;
  const taskOwner = props.taskOwner;
  const maTieuChi = props.maTieuChi;
  const bodyOfReport = props.bodyOfReport;

  const selectedNguoiGopYList = props.selectedNguoiGopYList;
  const bodyAdding = props.bodyAdding;

  // -- tofile
  const taskContentHistoryId = props.taskContentHistoryId;
  const taskContentID = props.taskContentID;
  const taskContentsHistory = props.taskContentsHistory;
  const _handleCheckCompled = props._handleCheckCompled;
  const isConfirm = props.isConfirm;

  // -- end tofile
  const nhomquyenList = props.nhomquyenList;
  const selectedNhomquyen = props.selectedNhomquyen;
  const _onChangeSelectedNhomquyen = props._onChangeSelectedNhomquyen;

  const selectedNguoiPhoiHopList = props.selectedNguoiPhoiHopList;
  const selectedNguoiLamViecList = props.selectedNguoiLamViecList;
  const selectedBuocList = props.selectedBuocList;
  const _onCheckNguoiGopY = props._onCheckNguoiGopY;

  const _onCheckNguoiPhoiHop = props._onCheckNguoiPhoiHop;

  const positionStep = props.positionStep;
  const hourStep = props.hourStep;
  const contentStep = props.contentStep;
  const _onChangePositionStep = props._onChangePositionStep;
  const _onChangeHourStep = props._onChangeHourStep;
  const _onChangeContentStep = props._onChangeContentStep;
  const _onPushStep = props._onPushStep;
  const _onDeleteQuyTrinh = props._onDeleteQuyTrinh;
  const _onChangeStepList = props._onChangeStepList;

  const selectedKetQuaList = props.selectedKetQuaList;
  const ketqua = props.ketqua;
  const _onPushKetqua = props._onPushKetqua;
  const _onChangeKetqua = props._onChangeKetqua;
  const _onDeleteKetqua = props._onDeleteKetqua;
  const isViewHistoty = props.isViewHistoty;
  const _taskContents = props._taskContents;

  const resetFilter = props.resetFilter;

  const taskCode = props.taskCode;
  const isOwner = props.isOwner;
  const idHistory = props.idHistory;

  const taskInfoText = props.taskInfoText;
  const task = props.task;

  const taskHistory = props.taskHistory;
  const takeHistoryDetail = props.takeHistoryDetail;
  const callBack = props.callBack;

  const _onClickUpdate = props._onClickUpdate;
  const _onClickSwitchToBoss = props._onClickSwitchToBoss;

  const taskDeduct = props.taskDeduct;

  const setIsDeleteSupport = props.setIsDeleteSupport;

  const optionsParser = props.optionsParser;

  const _onClickUpdateReport = props._onClickUpdateReport;
  const planOfAction = props.planOfAction;
  const bonusOrPayoff = props.bonusOrPayoff;
  const dataHistory = props.dataHistory;

  const ReportHistoryID = props.ReportHistoryID;
  const ReportID = props.ReportID;
  const FileName = props.FileName;
  const Content = props.Content;
  const ReportStatus = props.ReportStatus;
  const Result = props.Result;

  const _onFetchData = props._onFetchData;
  // TODO: Setting backdropClick
  const closeModal = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      handleClose(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="core-modal-minimize"
    >
      <div style={style} className="w-max h-max">
        {funcs === "multipleChoicePartner" ? (
          <MultipleChoicePartner
            resetFilter={resetFilter}
            _onCheckNguoiPhoiHop={_onCheckNguoiPhoiHop}
            selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
            selectedNguoiLamViecList={selectedNguoiLamViecList}
            _onChangeWorkerViaDepart={_onChangeWorkerViaDepart}
            selectedNguoiGopYList={selectedNguoiGopYList}
            _onCheckNguoiGopY={_onCheckNguoiGopY}
            handleClose={handleClose}
            setIsDeleteSupport={setIsDeleteSupport}
          />
        ) : funcs === "chooseTaskInsert" ? (
          <ChooseTaskInsert
            _handleSubmitChooseTaskToBeInsert={
              props._handleSubmitChooseTaskToBeInsert
            }
            UserId={UserId}
            handleClose={handleClose}
            taskToBeInsertID={props.taskToBeInsertID}
          />
        ) : funcs === "confirmTaskMyself" ? (
          <ConfirmTaskMySelf
            statusConfirmTaskMySelf={props.statusConfirmTaskMySelf}
            handleClose={handleClose}
            taskId={taskId}
            IDTaskMySelf={props.IDTaskMySelf}
          />
        ) : funcs === "shareTask" ? (
          <ShareTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "reportDelay" ? (
          <ReportDelay taskId={taskId} handleClose={handleClose} />
        ) : funcs === "watchTaskHistory" ? (
          <ModalMinimizeControl
            component={(props: any) => {
              return (
                <DetailHistory
                  coreModalMinimizeHeader={(prop2s: any) => {
                    return (
                      <ModalMinimizeHeaderControl
                        {...props}
                        title={prop2s.title}
                      />
                    );
                  }}
                  takeHistoryDetail={takeHistoryDetail}
                  taskHistory={taskHistory}
                  taskId={taskId}
                  task={task}
                  idHistory={idHistory}
                  handleClose={handleClose}
                />
              );
            }}
          />
        ) : funcs === "checkViewPort" ? (
          <CheckViewPort
            bodyOfReport={bodyOfReport}
            handleClose={handleClose}
          />
        ) : funcs === "openEditFileSubAlready" ? (
          <EditFileSubAlready
            bodyAdding={bodyAdding}
            handleClose={handleClose}
          />
        ) : funcs === "subViewReport" ? (
          <SubViewReport
            taskId={taskId}
            _onClickUpdateReport={_onClickUpdateReport}
            handleClose={handleClose}
            handleClose2={handleClose2}
            planOfAction={planOfAction}
            bonusOrPayoff={bonusOrPayoff}
            dataHistory={dataHistory}
            ReportHistoryID={ReportHistoryID}
          />
        ) : funcs === "deleteLeaving" ? (
          <DeleteLeaving taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteHoliday" ? (
          <DeleteHoliday taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteMeeting" ? (
          <DeleteMeeting taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteBusinessTrip" ? (
          <DeleteBusinessTrip taskId={taskId} handleClose={handleClose} />
        ) : funcs === "openEditFileSub" ? (
          <EditFileSub bodyAdding={bodyAdding} handleClose={handleClose} />
        ) : funcs === "checkViewPort" ? (
          <CheckViewPort
            bodyOfReport={bodyOfReport}
            handleClose={handleClose}
          />
        ) : funcs === "checkPlanning" ? (
          <CheckPlanning
            bodyOfReport={bodyOfReport}
            handleClose={handleClose}
          />
        ) : funcs === "reportDelete" ? (
          <ReportDelete taskId={taskId} handleClose={handleClose} />
        ) : funcs === "confirmReport" ? (
          <ConfirmReport taskId={taskId} handleClose={handleClose} />
        ) : funcs === "editDeadLine" ? (
          <EditDeadLineNguoiGiao
            timeExtend={timeExtend}
            hoursExtend={hoursExtend}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "receiveAndStart" ? (
          <ReceiveAndStartTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "planDelete" ? (
          <PlanDelete taskId={taskId} handlefClose={handleClose} />
        ) : funcs === "reportSend" ? (
          <ReportSend
            taskId={taskId}
            _onClickUpdateReport={_onClickUpdateReport}
            handleClose={handleClose}
            planOfAction={planOfAction}
            bonusOrPayoff={bonusOrPayoff}
            dataHistory={dataHistory}
          />
        ) : funcs === "editPlanning" ? (
          <EditPlanning taskId={taskId} handleClose={handleClose} />
        ) : funcs === "planSend" ? (
          <PlanSend taskId={taskId} handleClose={handleClose} />
        ) : funcs === "detailOfTask" ? (
          <DetailOfTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "switchTask" ? (
          <SwitchTask
            taskId={taskId}
            _onClickSubmit={_onClickSubmit}
            handleClose={handleClose}
          />
        ) : funcs === "multipleChoiceStep" ? (
          <MultipleChoiceStep handleClose={handleClose} />
        ) : funcs === "deleteTask" ? (
          <DeleteTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "giveTask" ? (
          <GiveTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "saveAndGiveTask" ? (
          <SaveAndGiveTask
            taskId={taskId}
            _onClickUpdate={() => _onClickUpdate(true)}
            handleClose={handleClose}
          />
        ) : funcs === "switchBoss" ? (
          <SwitchTaskToBoss
            taskOwner={taskOwner}
            taskId={taskId}
            handleClose={handleClose}
            _onClickSwitchToBoss={() => _onClickSwitchToBoss(true)}
          />
        ) : funcs === "votingTask" ? (
          <VotingTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "confirmCompleteTask" ||
          funcs === MODAL_KEYS.reviewSupporter ? (
          <ModalMinimizeControl
            component={(props: any) => {
              return (
                <ComfirmCompleteTask
                  coreModalMinimizeHeader={(prop2s: any) => {
                    return (
                      <ModalMinimizeHeaderControl
                        {...props}
                        title={prop2s.title}
                      />
                    );
                  }}
                  id={id}
                  taskId={taskId}
                  taskCode={taskCode}
                  task={task}
                  taskHistory={taskHistory}
                  funcs={funcs}
                  _handleCheckCompled={_handleCheckCompled}
                  handleClose={handleClose}
                />
              );
            }}
          />
        ) : funcs === "confirmTask" ? (
          <ConfirmTask taskId={taskId} handleClose={handleClose} />
        ) : // ) : funcs === "multipleUploadFileContent" ? (
        //   <MultipleUploadFileContent task={task} handleClose={handleClose} />
        funcs === "changeTaskReviewer" ? (
          <ChangeTaskReviewer taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteTieuChi" ? (
          <DELETE_TIEUCHI
            maTieuChi={maTieuChi}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "reTasking" ? (
          <ReTasking
            taskCode={taskCode}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "finishTask" ? (
          <FinishTask
            taskCode={taskCode}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "askExtend" ? (
          <AskExtend
            taskCode={taskCode}
            taskId={taskId}
            task={task}
            handleClose={handleClose}
          />
        ) : funcs === "confirmExtend" ? (
          <ConfirmExtend
            taskId={taskId}
            extendId={extendId}
            timeExtend={timeExtend}
            isResult={isResult}
            handleClose={handleClose}
            hourExtend={hourExtend}
          />
        ) : funcs === "receiveTaskSub" ? (
          <ReceiveTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "startTaskSub" ? (
          <StartTaskSub taskId={taskId} handleClose={handleClose} />
        ) : funcs === "notiTask" ? (
          <NotiTask taskId={taskId} handleClose={handleClose} isStep={props.isStep} stepId = {props.stepId}/>
        ) : funcs === "completedTaskContent" ? (
          <CompletedTaskContent
            taskContentHistoryId={taskContentHistoryId}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "deleteDificult" ? (
          <DeleteDificult taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteTaskList" ? (
          <DeleteTaskList taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deletePrior" ? (
          <DeletePrior taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteUser" ? (
          <DeleteUser taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteEmployee" ? (
          <DeleteEmployee taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteGeneral" ? (
          <DeleteGeneral taskId={taskId} handleClose={handleClose} />
        ) : // : funcs === "deleteEmployee" ? <DeleteEmployee taskId={taskId} handleClose={handleClose} />
        funcs === "deleteGeneral" ? (
          <DeleteGeneral taskId={taskId} handleClose={handleClose} />
        ) : funcs === "chatPopup" ? (
          <ChatPopup
            taskId={taskId}
            taskInfoText={taskInfoText}
            handleClose={handleClose}
            _handleCheckCompled={_handleCheckCompled}
          />
        ) : funcs === "votingTask" ? (
          <VotingTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "confirmCompleteTask" ||
          funcs === MODAL_KEYS.reviewSupporter ? (
          <ModalMinimizeControl
            component={(props: any) => {
              return (
                <ComfirmCompleteTask
                  coreModalMinimizeHeader={(prop2s: any) => {
                    return (
                      <ModalMinimizeHeaderControl
                        {...props}
                        title={prop2s.title}
                      />
                    );
                  }}
                  id={id}
                  taskId={taskId}
                  taskCode={taskCode}
                  task={task}
                  funcs={funcs}
                  _handleCheckCompled={_handleCheckCompled}
                  handleClose={handleClose}
                />
              );
            }}
          />
        ) : funcs === "confirmTask" ? (
          <ConfirmTask taskId={taskId} handleClose={handleClose} />
        ) : funcs === "multipleUploadFileContent" ? (
          <MultipleUploadFileContent
            task={task}
            handleClose={handleClose}
            taskContentsHistory={taskContentsHistory}
            taskContentHistoryId={taskContentHistoryId}
            taskId={taskId}
            isViewHistoty={isViewHistoty}
            _handleCheckCompled={_handleCheckCompled}
            _taskContents={_taskContents}
          />
        ) : funcs === "confirmTaskContents" ? (
          <ConfirmTaskContents
            taskContentID={taskContentID}
            taskContentHistoryId={taskContentHistoryId}
            isConfirm={isConfirm}
            isOwner={isOwner}
            _handleCheckCompled={_handleCheckCompled}
            handleClose={handleClose}
          />
        ) : funcs === "deleteQuytrinh" ? (
          <DeleteQuytrinh taskId={taskId} handleClose={handleClose} />
        ) : funcs === "deleteSecurity" ? (
          <DeleteSecurity taskId={taskId} handleClose={handleClose} />
        ) : funcs === "editBuoc" ? (
          <EditBuoc
            positionStep={positionStep}
            hourStep={hourStep}
            contentStep={contentStep}
            _onChangePositionStep={_onChangePositionStep}
            _onChangeHourStep={_onChangeHourStep}
            _onChangeContentStep={_onChangeContentStep}
            _onPushStep={_onPushStep}
            _onDeleteQuyTrinh={_onDeleteQuyTrinh}
            selectedBuocList={selectedBuocList}
            _onChangeStepList={_onChangeStepList}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "editKetqua" ? (
          <EditKetqua
            selectedKetQuaList={selectedKetQuaList}
            _onChangeKetqua={_onChangeKetqua}
            _onPushKetqua={_onPushKetqua}
            _onDeleteKetqua={_onDeleteKetqua}
            ketqua={ketqua}
            _onChangeStepList={_onChangeStepList}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === "choosePermission" ? (
          <ChoosePermission
            nhomquyenList={nhomquyenList}
            selectedNhomquyen={selectedNhomquyen}
            _onChangeSelectedNhomquyen={_onChangeSelectedNhomquyen}
            handleClose={handleClose}
          />
        ) : funcs === "createWorkflowSub" ? (
          <CreateWorkflowSub handleClose={handleClose} />
        ) : funcs === MODAL_KEYS.recoveryTask ? (
          <RecoveryTaskModal
            taskCode={taskCode}
            typeTransfer={props.typeTransfer}
            dataUpdate={props.dataUpdateRecovery}
            onHandleRecoveryTask={props.onHandleRecoveryTask || props.callBack}
            isTransfer={props.isTransfer}
            timeExtend={timeExtend}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === MODAL_KEYS.chooseBossNoti ? (
          <ChooseBossNoti
            onClickSubmitAndNoti={props.onClickSubmitAndNoti}
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === MODAL_KEYS.historyTaskRecovery ? (
          <HistoryTaskRecovery taskId={taskId} handleClose={handleClose} />
        ) : funcs === MODAL_KEYS.editTaskRecoveryAndTransfer ? (
          <EditTaskRecoveryAndTransfer
            taskId={taskId}
            handleClose={handleClose}
            callBack={props.callBack}
            taskCode={taskCode}
          />
        ) : funcs === MODAL_KEYS.reviewHistoryTaskPrevious ? (
          <ReviewHistoryTaskPreviousSubModal
            taskId={taskId}
            handleClose={handleClose}
          />
        ) : funcs === MODAL_KEYS.alertUploadFile ? (
          <AlertUploadFile
            taskId={taskId}
            handleClose={handleClose}
            callBack={callBack}
          />
        ) : funcs === "createNewTask" ? (
          <CreateNewTask
            taskId={taskId}
            handleClose={handleClose}
            callBack={callBack}
            isChiaViec={true}
            _onFetchData={_onFetchData}
          />
        ) : funcs === "listTaskDeduct" ? (
          <ListTaskDeduct taskDeduct={taskDeduct} handleClose={handleClose} />
        ) : funcs === "taskNeedConfirm" ? (
          <TaskNeedConfirm taskId={taskId} handleClose={handleClose} />
        ) : funcs === "readTotalTask_nguoigiao" ? (
          <ReadTotalTask_nguoigiao taskId={taskId} handleClose={handleClose} />
        ) : funcs === "detailOnLeaving" ? (
          <DetailOnLeaving taskId={taskId} handleClose={handleClose} />
        ) : funcs === "detailOnHoliday" ? (
          <DetailOnHoliday taskId={taskId} handleClose={handleClose} />
        ) : funcs === "detailMeeting" ? (
          <DetailMeeting taskId={taskId} handleClose={handleClose} />
        ) : funcs === "detailBusinessTrip" ? (
          <DetailBusinessTrip taskId={taskId} handleClose={handleClose} />
        ) : funcs === "detailReportWaiting" ? (
          <DetailReportWaiting
            ReportHistoryID={ReportHistoryID}
            ReportID={ReportID}
            FileName={FileName}
            _onFetchData={_onFetchData}
            handleClose={handleClose}
          />
        ) : funcs === "detailReportProactive" ? (
          <DetailReportProactive
            ReportHistoryID={ReportHistoryID}
            ReportID={ReportID}
            FileName={FileName}
            ReportStatus={ReportStatus}
            Result={Result}
            Content={Content}
            _onFetchData={_onFetchData}
            handleClose={handleClose}
          />
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
};

export default SubModal;
