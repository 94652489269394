import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { USER_READ_ALL } from "../../../apis/_index";
import { toast } from "react-toastify";
import { listShareDrive, shareDrive } from "../../fileComponent/utils/helper";
import { getUserID } from "../../../utils/users/userUtils";
import ServiceUtils from "../../../utils/services";
const ShareTSX = (props: any) => {
  const [users, setUsers] = useState<any>([]);

  const [selectAllView, setSelectAllView] = useState(true);
  const [selectAllEdit, setSelectAllEdit] = useState(true);
  const [selectAllDelete, setSelectAllDelete] = useState(true);

  const handleClose = props.handleClose;

  const [permission, setPermission] = useState(1);
  const [permissionRole, setPermissionRole] = useState(0);

  const item = props.folderItem;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await ServiceUtils.getV2(USER_READ_ALL);
        if (res.data.StatusCode === 200) {
          const usersData = res.data.Data;
          const yourID = await getUserID();
          const filteredUsersData = usersData.filter(
            (user: any) => user.Id !== yourID
          );
          const usersWithRoles = filteredUsersData.map((user: any) => ({
            ...user,
            view: false,
            edit: false,
            delete: false,
          }));

          const shareDriveData = await listShareDrive(item);

          shareDriveData.forEach((r: any) => {
            if (r.FullName === "everyone") {
              setPermission(0);
              setPermissionRole(r.FileMode);
            } else {
              setPermission(1);
              const userToUpdate = usersWithRoles.find(
                (u: any) => u.Id === r.UserId
              );
              if (userToUpdate) {
                if (r.FileMode === 0) {
                  userToUpdate.view = true;
                } else if (r.FileMode === 1) {
                  userToUpdate.view = true;
                  userToUpdate.edit = true;
                } else if (r.FileMode === 2) {
                  userToUpdate.view = true;
                  userToUpdate.edit = true;
                  userToUpdate.delete = true;
                }
              }
            }
          });

          setUsers(usersWithRoles);
        } else {
          toast.error(res.data.message);
        }
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchUser();
  }, []);

  const handleViewClick = (user: any) => {
    const updatedUsers = users.map((u: any) =>
      u === user ? { ...u, view: !u.view, edit: false, delete: false } : u
    );
    setUsers(updatedUsers);
    checkAll(users);
  };

  const handleEditClick = (user: any) => {
    const updatedUsers = users.map((u: any) =>
      u === user ? { ...u, view: true, edit: !u.edit, delete: false } : u
    );
    setUsers(updatedUsers);
    checkAll(users);
  };

  const handleDeleteClick = (user: any) => {
    const updatedUsers = users.map((u: any) =>
      u === user ? { ...u, view: true, edit: true, delete: !u.delete } : u
    );
    setUsers(updatedUsers);
    checkAll(users);
  };

  const handleSelectAllView = () => {
    const updatedUsers = users.map((u: any) => {
      if (selectAllView) {
        return {
          ...u,
          view: false,
          edit: false,
          delete: false,
        };
      } else {
        return {
          ...u,
          view: true,
          edit: u.edit,
          delete: u.delete,
        };
      }
    });

    checkAll(users);
    setUsers(updatedUsers);
  };

  const handleSelectAllEdit = () => {
    const updatedUsers = users.map((u: any) => {
      if (selectAllEdit) {
        return {
          ...u,
          view: true,
          edit: false,
          delete: false,
        };
      } else {
        return {
          ...u,
          view: true,
          edit: true,
          delete: u.delete,
        };
      }
    });
    checkAll(users);
    setUsers(updatedUsers);
  };

  const handleSelectAllDelete = () => {
    const updatedUsers = users.map((u: any) => ({
      ...u,
      view: true,
      edit: true,
      delete: !selectAllDelete,
    }));

    checkAll(users);
    setUsers(updatedUsers);
  };

  const _onClickSubmit = () => {
    const userShares: any = [];
    const userRoles: any = [];
    if (permission == 0) {
      if (permissionRole == 0) userRoles.push(0);
      else if (permissionRole == 1) userRoles.push(1);
      else userRoles.push(2);
    } else {
      users.forEach((user: any) => {
        if (user.delete) {
          userShares.push(user.Id);
          userRoles.push(2);
        } else if (user.edit) {
          userShares.push(user.Id);
          userRoles.push(1);
        } else if (user.view) {
          userShares.push(user.Id);
          userRoles.push(0);
        }
      });
    }
    shareDrive(item, userShares, userRoles).then(() => {
      if (handleClose) {
        handleClose();
      }
    });
  };

  const checkAll = (users: any) => {
    let allView = true;
    let allEdit = true;
    let allDelete = true;

    users.map((u: any) => {
      if (!u.view) allView = false;
      if (!u.edit) allEdit = false;
      if (!u.delete) allDelete = false;
    });

    setSelectAllView(allView);
    setSelectAllEdit(allEdit);
    setSelectAllDelete(allDelete);
  };
  useEffect(() => {
    checkAll(users);
  }, [users]);

  return (
    <div className="flex flex-col bg-white lg:w-[42rem] w-screen rounded-md">
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        py-[12px] px-[16px] items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>
          Chia sẻ "{item.FileId ? `${item.FileName}` : `${item.FolderName}`}"
        </p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="flex w-full h-max p-3 z-[1000]">
        <div className="flex flex-col w-full pr-0 h-max">
          <label className="mb-1">Chọn quyền:</label>
          <div className="flex gap-5 justify-between">
            {/* <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-[#0ea5e9] focus:border-[#0ea5e9] block w-full p-2.5"
              onChange={(e: any) => setPermission(e.target.value)}
              value={permission}
            >
              <option value="0"></option>
              <option value="1"></option>
            </select> */}
            <div>
              <div className="flex items-center mb-1">
                <input
                  type="radio"
                  name="default-radio"
                  checked={permission == 0}
                  onClick={() => setPermission(0)}
                  className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
                />
                <label className="ms-2 font-medium">Tất cả mọi người</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  checked={permission == 1}
                  onClick={() => setPermission(1)}
                  name="default-radio"
                  className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
                />
                <label className="ms-2 font-medium">
                  Chỉ những người được cấp quyền
                </label>
              </div>
            </div>

            {permission == 0 && (
              <select
                className="w-[25%] bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-[#0ea5e9] focus:border-[#0ea5e9] block"
                onChange={(e: any) => setPermissionRole(e.target.value)}
                value={permissionRole}
              >
                <option value="0">Xem</option>
                <option value="1">Sửa</option>
                <option value="2">Toàn quyền</option>
              </select>
            )}
          </div>
        </div>
      </div>

      {permission == 1 && (
        <div className="flex flex-col text-sm w-full h-[25rem] lg:h-[30rem]">
          <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="table-cell border-l-[1px] border-slate-300">
                    STT
                  </th>
                  <th className="table-cell border-l-[1px] border-slate-300">
                    Người dùng
                  </th>
                  <th className="table-cell border-l-[1px] border-slate-300">
                    <div className="flex gap-2 items-center justify-center">
                      <span>Xem</span>
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                        checked={selectAllView}
                        onClick={handleSelectAllView}
                      />
                    </div>
                  </th>
                  <th className="table-cell border-l-[1px] border-slate-300">
                    <div className="flex gap-2 items-center justify-center">
                      <span>Sửa</span>
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                        checked={selectAllEdit}
                        onClick={handleSelectAllEdit}
                      />
                    </div>
                  </th>
                  <th className="table-cell border-l-[1px] border-slate-300">
                    <div className="flex gap-2 items-center justify-center">
                      <span>Toàn quyền</span>
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                        checked={selectAllDelete}
                        onClick={handleSelectAllDelete}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {users.map((user: any, index: number) => (
                  <tr
                    className="hover:bg-gray-100 dark:hover:bg-gray-700"
                    key={index}
                  >
                    <td className="table-cell text-center border border-slate-300">
                      {index}
                    </td>
                    <td className="table-cell text-center border border-slate-300">
                      {user.FullName}
                    </td>
                    <td className="table-cell text-center border border-slate-300">
                      <div className="flex justify-center items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                          checked={user.view}
                          onClick={() => handleViewClick(user)}
                        />
                      </div>
                    </td>
                    <td className="table-cell text-center border border-slate-300">
                      <div className="flex justify-center items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                          checked={user.edit}
                          onClick={() => handleEditClick(user)}
                        />
                      </div>
                    </td>
                    <td className="table-cell text-center border border-slate-300">
                      <div className="flex justify-center items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                          checked={user.delete}
                          onClick={() => handleDeleteClick(user)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full h-[3rem] rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Chia sẻ</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareTSX;
