import UndoIcon from "@mui/icons-material/Undo";
import TaskUtils from "../../utils/tasks/taskUtils";

const RecoveryTaskComponent = (props: any) => {
  const task = props.task || {};

  const isHaveRecovery = TaskUtils.isHaveRecovery(task.TaskStatus);
  const isHaveTransfer = TaskUtils.isHaveTransfer(task.TaskStatus);

  const openRecoveryTask = (isTransfer: any) => () => {
    if (props.onOpenRecoveryTask) {
      props.onOpenRecoveryTask(isTransfer);
    }
  };

  const openRecoveryAndTransferTask = (isTransfer: any) => () => {
    if (props.onOpenRecoveryAndTransferTask) {
      props.onOpenRecoveryAndTransferTask(isTransfer);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      className="gap-3"
    >
      {isHaveRecovery && (
        <button
          className="
                  flex items-center w-max h-max pl-2 pr-2 pt-1 pb-1 bg-rose-500 text-white rounded-md
                  hover:bg-rose-400
                "
          onClick={openRecoveryTask(false)}
          style={{
            height: "36px",
          }}
        >
          <UndoIcon fontSize="small" />
          <p>Thu hồi</p>
        </button>
      )}
      {isHaveTransfer && (
        <button
          className="
                  flex items-center w-max h-max pl-2 pr-2 pt-1 pb-1 bg-sky-500 text-white rounded-md
                  hover:bg-sky-400
                "
          onClick={openRecoveryAndTransferTask(true)}
          style={{
            height: "36px",
          }}
        >
          <UndoIcon fontSize="small" />
          <p>Thu hồi và chuyển giao</p>
        </button>
      )}
      {/* <div style={{
            width: '15px'
        }}></div> */}
      {/* {isHaveTransfer &&
            <button
                className="
                  flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-rose-500 text-white rounded-md
                  hover:bg-rose-400
                "
                onClick={openRecoveryTask(true)}
            >
                <RedoIcon fontSize="small" />
                <p>Chuyển giao</p>
            </button>
        } */}
    </div>
  );
};

export default RecoveryTaskComponent;
