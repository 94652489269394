import React, { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NGUOI_GIAO_CONFIRM_TASK_MYSELF, NGUOI_GIAO_LOAD_TASK_MY_SELF } from "../../../apis/_index";
import { ModalController } from "../../../ui/homeUI/_index";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { ONLOAD_TASKMYSELF_NGUOIGIAO } from "../../../redux/reducers/workingReducer/_index";

const ConfirmTaskMySelf = (props: any) => {
  const taskId = props.taskId;
  const statusConfirmTaskMySelf = props.statusConfirmTaskMySelf;
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const [comment, setComment] = useState<string>("");
  const data = useSelector((state: any) => state.workingReducer);
  const shouldLog = useRef<any>(true);
  const urlLink = window.location.pathname;
  const dispatch = useDispatch();

  // funcs
  const _onClickSubmit = async () => {
    // const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      TaskID: taskId,
      BossComment: comment,
      ConfirmStatus: statusConfirmTaskMySelf,
      ID: props.IDTaskMySelf
    };

    try {
      await ServiceUtils.postV2(NGUOI_GIAO_CONFIRM_TASK_MYSELF, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          toast.success("Đã duyệt");
          handleClose();
          context.handleClose();
          // re-load
          readList(
            NGUOI_GIAO_LOAD_TASK_MY_SELF,
            data.pageCurrent,
            data.choosePage,
            data.searchFilter.taskName,
            data.searchFilter.selectedPrior,
            null,
            data.searchFilter.fromDate,
            data.searchFilter.toDate,
            data.searchFilter.deadLine,
            shouldLog,
            urlLink,
            (res: any) => {
              dispatch(ONLOAD_TASKMYSELF_NGUOIGIAO(res.data.Data));
            },
            null,
            null,
            null,
            null,
            null,
            null
          );

        }
      });
    } catch (error: any) {
      console.log(error);

      toast.error(error ||
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Xác nhận {statusConfirmTaskMySelf == 1 ? 'duyệt' : 'không duyệt'}</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Nội dung&nbsp;
            </label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập nội dung duyệt"
              onChange={(e: any) => {
                e.preventDefault();
                setComment(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex footer w-full p-12-16 rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={'flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-green-500 hover:bg-green-400'}
            onClick={_onClickSubmit}
          >
            <p>Xác nhận</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmTaskMySelf;
