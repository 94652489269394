import { useEffect, useRef, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ON_LEAVE_LIST } from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

import { ModalController } from "../../../ui/homeUI/_index";
import { loadReceiver } from "../../../funcs/taskNguoiGiaoViec";

import moment from "moment";

import DescriptionIcon from "@mui/icons-material/Description";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";

import { ONLOAD_RECEIVERLIST } from "../../../redux/reducers/workingReducer/_index";
import ServiceUtils from "../../../utils/services";
import { Link } from "react-router-dom";
import HandleUI from "../../../utils/handleUI";

const OnLeaveElement = () => {
  // States
  const workerData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [data, setData] = useState<any>(null);

  const shouldLog = useRef(true);
  const shouldLog1 = useRef(true);

  const context = useContext(ModalController);

  const loadAll = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const fetchData = async () => {
        try {
          await ServiceUtils.getV2(ON_LEAVE_LIST, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data);
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      fetchData();
    }
  };

  // onLoadReciverList
  useEffect(() => {
    if (workerData.receiverList === null) {
      loadReceiver(
        (res: any) => dispatch(ONLOAD_RECEIVERLIST(res.data.Data)),
        shouldLog1
      );
    }
  });

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      loadAll();
    }
  });

  // Funcs
  const _onClickCreateNewLeaving = () => {
    context.setFuncs("createNewLeaving");
    context.setDataModal({
      tempFuncs: loadAll,
    });
    context.handleOpen();
  };

  const _onClickDetailOnLeaving = (id: string) => {
    context.setFuncs("detailOnLeaving");
    context.setTaskId(id);
    context.setDataModal({
      tempFuncs: loadAll,
    });
    context.handleOpen();
  };

  return (
    <div className="w-full h-max p-4 flex flex-col space-y-5">
      <p className="text-primary">
        <strong>DANH SÁCH CÁC NGÀY NGHỈ PHÉP</strong>
      </p>

      <div className="w-full h-max flex">
        <button
          className="w-max h-max flex space-x-1 p-2 justify-center items-center
          bg-green-500 hover:bg-green-400 hover:text-white rounded-md font-semibold shadow-sm
          text-white
        "
          onClick={_onClickCreateNewLeaving}
        >
          <AddIcon fontSize="small" />
          <p>Thêm mới</p>
        </button>
      </div>

      {/* Table */}
      <div className="w-full h-max bg-red-200">
        {/* Web */}
        <div className="lg:flex hidden tableFixHead styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="text-left border-l-[1px] border-slate-300">
                  Tên nhân viên
                </th>
                <th className="border-l-[1px] border-slate-300">Ngày nghỉ</th>
                <th className="lg:table-cell border-l-[1px] border-slate-300">
                  Giờ nghỉ
                </th>
                <th className="text-left  lg:table-cell border-l-[1px] border-slate-300">
                  Lý do
                </th>
                <th className="lg:table-cell border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody
              className="w-full h-[calc(100%-2rem)] bg-white overflow-auto"
              id="table"
            >
              {data?.map((item: any, index: number) => (
                <tr key={index} className="w-max h-[3rem] bg-white">
                  <td className="text-center border border-slate-300">
                    {index + 1}
                  </td>
                  <td className="text-left border border-slate-300">
                    {item.UserName}
                  </td>
                  <td className="text-center border border-slate-300">
                    {moment(item.DateOff).format("DD/MM/YYYY")}
                  </td>
                  <td className="text-center border border-slate-300">
                    {item.HoursOff}
                  </td>
                  <td className="text-left border border-slate-300">
                    {item.Reason}
                  </td>
                  <td className="text-center border border-slate-300">
                    <div className="dropdown">
                      {/* button options */}
                      <button
                        className="dropbtn"
                        onMouseEnter={() => {
                          HandleUI.hoverMenuContextScrollTable(
                            index,
                            data?.length
                          );
                        }}
                      >
                        <MoreHorizIcon fontSize="small" />
                      </button>

                      {/* options fields */}
                      <div className="dropdown-content text-xs font-semibold">
                        {/* detail */}
                        <button
                          className="flex w-full h-full hover:text-sky-500 p-2"
                          onClick={() => _onClickDetailOnLeaving(item.ID)}
                        >
                          {/* detail - contain */}
                          <div className="flex w-full h-full space-x-1">
                            {/* icon */}
                            <DescriptionIcon
                              className="text-sky-600"
                              sx={{ fontSize: 18 }}
                              fontSize="small"
                            />

                            {/* title */}
                            <div>Chi tiết / Chỉnh sửa</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile */}
        <div className="lg:hidden flex tableFixHead styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="text-left border-l-[1px] border-slate-300">
                  Danh sách nghỉ phép
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto text-sm">
              {data?.map((item: any, index: number) => (
                <tr key={index} className="w-max h-[3rem] bg-white">
                  <td className="text-left border border-slate-300 w-full h-max">
                    <p className=" font-semibold">
                      <span>{index + 1}.</span> {item.UserName}
                    </p>
                    <p>
                      <span className="font-semibold">Ngày nghỉ:</span>{" "}
                      {moment(item.DateOff).format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <span className="font-semibold">Giờ nghỉ:</span>{" "}
                      {item.HoursOff}
                    </p>
                    <div className="w-full h-max">
                      <span className="font-semibold">Lý do: </span>
                      {item.Reason}
                    </div>
                    <button
                      className="flex w-max h-full hover:text-sky-500 mt-2 ring-1 px-2 ring-primary text-primary rounded-full bg-white focus:text-white focus:bg-primary"
                      onClick={() => _onClickDetailOnLeaving(item.ID)}
                    >
                      Chi tiết
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OnLeaveElement;
