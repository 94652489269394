import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../homeUI/_index";
import { onTouchForm } from "../../../funcs/onTochForm";

import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ModalBase from "../../../components/modal/_index";
import Select from "react-select";
import DateTimePickerControl from "../../../controls/datetimePicker/datetimePickerControl";
import { ONCHANGE_FILTER } from "../../../redux/reducers/proposeReducer/_index";

const FilterProposeWeb = (props: any) => {
  const urlLink = window.location.pathname;
  const _loadData = props._loadData;

  const [openSub, setOpenSub] = useState(false);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [funcsSub, setFuncsSub] = useState<string>("");

  // redux
  const dispatch = useDispatch();
  const proposeData = useSelector((state: any) => state.proposeReducer);

  const context = useContext(ModalController);

  const _onClickCreatePropose = () => {
    context.handleOpen();
    context.setFuncs("createPropose");
  };

  const onOpenSub = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // take props
  const statusList = proposeData.statusList;

  const proposeTypeList = proposeData.typeList
    ? proposeData.typeList.map((item: any) => ({
        Id: item.ID,
        value: item.Name,
        label: item.Name,
      }))
    : [];

  const priorList = proposeData.priorList
    ? proposeData.priorList.map((item: any) => ({
        Id: item.Id,
        value: item.PriorityLevelName,
        label: item.PriorityLevelName,
      }))
    : [];

  const senderList = proposeData.senderList
    ? proposeData.senderList.map((item: any) => ({
        Id: item.Id,
        value: item.FullName,
        label: item.FullName,
      }))
    : [];

  const receiverList = proposeData.receiverList
    ? proposeData.receiverList.map((item: any) => ({
        Id: item.Id,
        value: item.FullName,
        label: item.FullName,
      }))
    : [];

  function handleSelectStatus(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "status", data: data ? data.Id : "" }));
  }

  function handleSelectProposeType(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "type", data: data ? data.Id : "" }));
  }

  function handleSelectPrior(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "prior", data: data ? data.Id : "" }));
  }

  function handleSelectReceiver(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "receiver", data: data ? data.Id : "" }));
  }

  function handleSelectSender(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "sender", data: data ? data.Id : "" }));
  }

  return (
    <div className="w-full h-max">
      <ModalBase
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        _loadData={_loadData}
      />

      <div className="flex flex-col py-3 gap-2">
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="ten-de-xuat flex flex-col pr-0 h-max">
            <label className="mb-1">Tên đề xuất</label>
            <input
              className="h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400 core-input-resize"
              placeholder="Nhập tên đề xuất"
              value={proposeData.searchFilter.name}
              onChange={(e) => {
                dispatch(
                  ONCHANGE_FILTER({ type: "name", data: e.target.value })
                );
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>

          <div className="flex flex-col pr-0 h-max">
            <label className="mb-1">Trạng thái</label>
            <Select
              className="core-select-resize"
              options={statusList}
              onChange={handleSelectStatus}
              // value={proposeData.searchFilter.selectedStatus}
              value={statusList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedStatus
              )}
              isClearable
              placeholder="Chọn trạng thái"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="flex flex-col pr-0 h-max">
            <label className="mb-1">Loại đề xuất</label>
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={proposeTypeID}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setProposeTypeID("");
                } else {
                  setProposeTypeID(
                    proposeTypeList?.find(
                      (item: any) => item.ID === e.target.value
                    ).ID
                  );
                }
              }}
              placeholder="Chọn loại đề xuất"
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn loại đề xuất ---
              </option>

              {proposeTypeList === null
                ? ""
                : proposeTypeList.map((item: any, index: any) => (
                    <option key={index} value={item.ID}>
                      {item.Name}
                    </option>
                  ))}
            </select> */}
            <Select
              className="core-select-resize"
              options={proposeTypeList}
              value={proposeTypeList?.find(
                (item: any) => item.Id === proposeData.searchFilter.selectedType
              )}
              onChange={handleSelectProposeType}
              isClearable
              placeholder="Chọn loại đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="muc-do flex flex-col pr-0 h-max">
            <label className="mb-1">Mức độ</label>
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={priorId}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setPriorId(0);
                } else {
                  setPriorId(
                    priorList?.find(
                      (item: any) => item.Id === parseInt(e.target.value)
                    ).Id
                  );
                }
              }}
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn mức độ ---
              </option>
              {priorList &&
                priorList.map((item: any, index: any) => (
                  <option key={index} value={item.Id}>
                    {item.PriorityLevelName}
                  </option>
                ))}
            </select> */}
            <Select
              className="core-select-resize"
              options={priorList}
              value={priorList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedPrior
              )}
              onChange={handleSelectPrior}
              isClearable
              placeholder="Chọn mức độ"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="nguoi-gui flex flex-col pr-0 h-max">
            <label className="mb-1">Người đề xuất</label>
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={userAdd}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setUserAdd("");
                } else {
                  setUserAdd(e.target.value);
                }
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn người đề xuất ---
              </option>
              {senderList === null ? (
                <></>
              ) : (
                senderList.map((item: any, index: any) => (
                  <option key={item.Id} value={item.Id}>
                    {item.FullName}
                  </option>
                ))
              )}
            </select> */}
            <Select
              className="core-select-resize"
              options={senderList}
              value={senderList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedSender
              )}
              onChange={handleSelectSender}
              isClearable
              placeholder="Chọn người đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="nguoi-nhan flex flex-col pr-0 h-max">
            <label className="mb-1">Người nhận</label>
            <Select
              className="basic-single core-select-resize"
              classNamePrefix="select"
              options={receiverList}
              value={receiverList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedReceiver
              )}
              onChange={handleSelectReceiver}
              isClearable
              isSearchable
              placeholder="Chọn người nhận"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={userCheck}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setUserCheck("");
                } else {
                  setUserCheck(e.target.value);
                }
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn người nhận ---
              </option>
              {receiverList === null ? (
                <></>
              ) : (
                receiverList.map((item: any, index: any) => (
                  <option key={index} value={item.Id}>
                    {item.FullName}
                  </option>
                ))
              )}
            </select> */}
          </div>

          <DateTimePickerControl
            className="core-datetime-picker-resize"
            label={"Ngày gửi (Từ ngày)"}
            value={proposeData.searchFilter.fromDate}
            onChange={(e: any) => {
              const value = e;
              dispatch(ONCHANGE_FILTER({ type: "fromDate", data: value }));
            }}
          />

          {/* <div className="ngay-gui-from flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Ngày gửi (Từ ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Từ ngày)"
              type="datetime-local"
              value={dateAdd_From}
              onChange={(e: any) => {
                e.preventDefault();
                setDateAdd_From(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
            <input type="date" id="hidden-input" style={{ display: "none" }} />
          </div> */}

          <DateTimePickerControl
            className="core-datetime-picker-resize"
            label={"Ngày gửi (Đến ngày)"}
            value={proposeData.searchFilter.toDate}
            onChange={(e: any) => {
              const value = e;
              dispatch(ONCHANGE_FILTER({ type: "toDate", data: value }));
            }}
          />

          {/* <div className="ngay-gui-to flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Ngày gửi (Đến ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Đến ngày)"
              type="datetime-local"
              value={dateAdd_To}
              onChange={(e: any) => {
                e.preventDefault();
                setDateAdd_To(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div> */}
        </div>
      </div>
      <div className=" hidden lg:flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center p-2 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_loadData}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex md:flex">Tìm kiếm</p>
        </button>

        <button
          className="
            flex items-center p-2 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex md:flex">Tải lại</p>
        </button>
        {urlLink !== "/propose/receiver/list" ? (
          <button
            className="flex h-[1.9rem] p-2 bg-green-500 rounded-md text-white hover:bg-green-400 items-center "
            onClick={() => onOpenSub("createPropose")}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex md:flex">Thêm mới</p>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default FilterProposeWeb;
