import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { takePermission } from "../../../../funcs";

import AddIcon from "@mui/icons-material/Add";

import Select from "react-select";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";
import { convertNameParameter } from "../../../../helperText/index";

import {
  TAKE_PARAMETER_LISTKEY,
  READ_PARAMETER,
} from "../../../../apis/_index";
import {
  ONCHANGE_PARAMETER_LISTKEY,
  ONLOAD_PARAMETER_LIST,
  ONCHANGE_PARAMETERCHOOSE,
} from "../../../../redux/reducers/settingReducer/_index";

import axios from "axios";
import ServiceUtils from "../../../../utils/services";

const ListOfGeneralUI = () => {
  // // menuList
  // const menuList = useSelector((state: any) => state.appReducer.subMenu.filter((e: any) => e.FormId === 12));
  const menu = takePermission(12);

  const context = useContext(ModalController);
  const data = useSelector((state: any) => state.settingReducer);
  const [parameterChoose, setParameterChoose] = useState<any>(1);
  const dispatch = useDispatch();

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  // onload listkey
  useEffect(() => {
    const listKey = localStorage.getItem("parameterListKey");

    if (listKey === null && shouldLog.current) {
      shouldLog.current = false;

      const takeListKey = async () => {
        try {
          await ServiceUtils.getV2(TAKE_PARAMETER_LISTKEY, {
            headers: {},
          }).then((res: any) => {
            if (res.status === 200) {
              localStorage.setItem(
                "parameterListKey",
                JSON.stringify(res.data.Data)
              );
              dispatch(ONCHANGE_PARAMETER_LISTKEY());
            } else {
              toast.error("Tải dữ liệu danh mục thất bại.");
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeListKey();
    } else {
      if (data.parameterListKey.length === 0 && shouldLog.current) {
        shouldLog.current = false;
        dispatch(ONCHANGE_PARAMETER_LISTKEY());
      } else {
        return;
      }
    }
  });

  // onload parameterlist
  useEffect(() => {
    if (shouldLog2.current) {
      shouldLog2.current = false;
      const takeParameterList = async () => {
        try {
          await ServiceUtils.getV2(READ_PARAMETER, {
            headers: {},
          }).then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
            } else {
              return;
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };
      takeParameterList();
    }
  });

  // onload parameterlist via parameterChoose
  useEffect(() => {
    let API = "";

    if (parameterChoose.toString() === "1") {
      API = READ_PARAMETER;
    } else {
      API = READ_PARAMETER + `?key=${parameterChoose}`;
    }

    const takeParameterList = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {},
        }).then((res: any) => {
          if (res.status === 200) {
            dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
          } else {
            return;
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };
    takeParameterList();
  }, [parameterChoose, dispatch]);

  // funcs
  const _onChangeParameterKey = (e: any) => {
    e.preventDefault();
    setParameterChoose(e.target.value);
    dispatch(ONCHANGE_PARAMETERCHOOSE(e.target.value));
  };

  const _onClickCreateListOfGeneral = () => {
    context.setFuncs("createListOfGeneral");
    context.handleOpen();
  };

  // Preprocessing
  // state
  const _tempParameterList = data.parameterListKey?.map((item: any) => {
    return {
      ...item,
      value: item.ParameterKey,
      label: convertNameParameter(item.ParameterKey),
    };
  });

  const tempParameterList = _tempParameterList.filter(
    (item: any) =>
      item.ParameterKey !== "LI_BOPHAN" && item.ParameterKey !== "LI_PHONGBAN"
  );

  // funcs
  const _onChangeTempParameter = (data: any) => {
    if (data === null) {
      setParameterChoose(1);
      dispatch(ONCHANGE_PARAMETERCHOOSE(1));
    } else {
      setParameterChoose(data.ParameterKey);
      dispatch(ONCHANGE_PARAMETERCHOOSE(data.ParameterKey));
    }
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <div className="w-max h-max flex flex-col">
            <label className="text-xs mb-1">Chọn danh mục</label>
            <Select
              className="z-[5]"
              options={tempParameterList}
              onChange={(data: any) => _onChangeTempParameter(data)}
              isClearable
              placeholder="--- Chọn danh mục ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <button
            className={`
              p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateListOfGeneral}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>
        <Table />
      </div>
    </div>
  );
};

export default ListOfGeneralUI;
