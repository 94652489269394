import React from "react";
import { TotalNoti } from "../action/notificationAction";

export const Notification: any = (callback: any, token:any) => {

    TotalNoti(token).then((res: any) => {
        const data = ((res || {}).data || {}).data || '';
        
        if (data) {
            callback(data)
        }
    })
}
