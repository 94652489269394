import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {} from "../../../redux/reducers/_appReducer/_index";

import { ONCHANGE_NAVBAR_BUTTON } from "../../../redux/reducers/systemReducer/index";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeviceUtils from "../../../utils/devices/deviceUtils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const HiddenButton = () => {
  // redux
  // const data = useSelector((state: any) => state.appReducer);
  const dataSystem = useSelector((state: any) => state.systemReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // funcs
  const _onChangeShowingNavbar = () => {
    dispatch(ONCHANGE_NAVBAR_BUTTON());
    if (dataSystem.showingMenu == "85") {
      // navigate("/leave/calendar");
    }
  };

  if (DeviceUtils.isMobile()) {
    return null;
  }

  return (
    <div className="w-[1rem] h-full bg-white lg:flex hidden">
      <button
        className="
      lg:flex hidden
      w-full h-[7rem] mt-5 bg-slate-300 rounded-tr-md rounded-br-md
      hover:bg-slate-200 justify-center items-center
      hover:text-white
    "
        onClick={_onChangeShowingNavbar}
      >
        {dataSystem.navBarButton === "true" ? (
          <ArrowLeftIcon fontSize="small" />
        ) : (
          <ArrowRightIcon fontSize="small" />
        )}
      </button>
    </div>
  );
};

export default memo(HiddenButton);
