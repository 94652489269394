import React, { useState } from "react";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from '@mui/icons-material/Close';

import SubModal from "../_subModal/_index";

const ConfirmFinish = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  // funcs
  const _onChangeMultipleChoicePartner = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickSubModal = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[65rem] w-screen h-[40rem]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Chi tiết / Duyệt</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
      <div className="flex w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
        <div className="flex flex-col space-y-3 w-3/5 h-max pr-5 border-r-[1px] border-primary ">
          <p className="font-semibold text-black bg-slate-200 pl-1 pr-1 w-max rounded-sm shadow-sm">
            Mã công việc
          </p>

          {/* elements */}
          <div className="flex flex-col w-full h-max">
            <label>Tên công việc</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 bg-slate-100"
              placeholder="Nhập tên công việc"
              value="Xử lý hồ sơ FEYDK357502353"
              readOnly
            />
          </div>

          <div className="flex w-full h-max">
            <div className="w-1/2 h-max flex flex-col pr-2">
              <label>
                Độ mật&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={1}
              >
                <option className="text-gray-300" value={0} disabled>
                  --- Chọn độ mật ---
                </option>
                <option>Độ mật 1</option>
                <option>Độ mật 2</option>
                <option>Độ mật 3</option>
              </select>
            </div>

            <div className="w-1/2 h-max flex flex-col pl-2">
              <label>Người nhận</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 bg-slate-100 border-2 border-slate-200"
                placeholder="Nhập tên công việc"
                value="Quân"
                readOnly
              />
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="w-1/2 h-max flex flex-col pr-2">
              <label>Thời hạn</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="date"
              />
            </div>

            <div className="w-1/2 h-max flex flex-col pl-2">
              <label>Ngày hoàn thành</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="date"
              />
            </div>
          </div>

          <div className="flex flex-col w-full h-max">
            <label>Mô tả</label>
            <textarea
              className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
            />
          </div>

          <div className="flex flex-col w-full h-max">
            <label>Ghi chú</label>
            <textarea
              className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập ghi chú"
            />
          </div>

          <div className="flex flex-col w-full h-max">
            <p>Quy trình</p>
            <p className="text-primary">Quy trình giao việc số 1</p>
            <table className="w-full">
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="border border-slate-300 ">STT</th>
                  <th className="border border-slate-300">Nội dung</th>
                  <th className="border border-slate-300">Người thực hiện</th>
                  <th className="border border-slate-300">Thời hạn</th>
                  <th className="border border-slate-300">Ngày hoàn thành</th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                <tr className="w-max h-[3rem] bg-white">
                  <td className="text-center border border-slate-300">1</td>
                  <td className="border border-slate-300">
                    <p>Xử lý hồ sơ bước 1</p>
                  </td>
                  <td className="border border-slate-300">
                    <p>- Người thực hiện chính</p>
                    <p>- Người phối hợp 1</p>
                  </td>
                  <td className="text-center border border-slate-300">
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 bg-slate-100 border-2 border-slate-200"
                      type="date"
                      value="2023-03-29"
                      readOnly
                    />
                  </td>
                  <td className="text-center border border-slate-300">
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 bg-slate-100 border-2 border-slate-200"
                      type="date"
                      value="2023-03-29"
                      readOnly
                    />
                  </td>
                </tr>
                <tr className="w-max h-[3rem] bg-white">
                  <td className="text-center border border-slate-300">2</td>
                  <td className="border border-slate-300">
                    <p>Xử lý hồ sơ bước 2</p>
                  </td>
                  <td className="border border-slate-300">
                    <p>- Người thực hiện chính</p>
                    <p>- Người phối hợp 2</p>
                  </td>
                  <td className="text-center border border-slate-300">
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 bg-slate-100 border-2 border-slate-200"
                      type="date"
                      value="2023-03-29"
                      readOnly
                    />
                  </td>
                  <td className="text-center border border-slate-300">
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 bg-slate-100 border-2 border-slate-200"
                      type="date"
                      value="2023-03-29"
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex w-full h-max">
            <div className="flex flex-col w-1/2 h-max">
              <label>File đính kèm</label>
              <div className="flex flex-col w-full h-max">
                {/* element */}
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>File đính kèm 1</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full h-max">
            {/* elements */}
            <div className="flex flex-col w-1/2 h-max">
              <label>Kết quả</label>
              {/* element */}
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <DoneIcon fontSize="small" />
                  <p>Kết quả 1</p>
                </div>

                <div className="w-1/6 flex justify-center">
                  <input type="checkbox" className="w-5 h-5" />
                </div>
              </div>
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <DoneIcon fontSize="small" />
                  <p>Kết quả 2</p>
                </div>

                <div className="w-1/6 flex justify-center">
                  <input type="checkbox" className="w-5 h-5" />
                </div>
              </div>
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <DoneIcon fontSize="small" />
                  <p>Kết quả 3</p>
                </div>

                <div className="w-1/6 flex justify-center">
                  <input type="checkbox" className="w-5 h-5" />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-1/2 h-max">
              <div className="w-full h-max flex space-x-2 items-center">
                <p>Bằng chứng kết quả</p>

                <button
                  className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-xs
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                >
                  +
                </button>
              </div>

              {/* element */}
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <InsertDriveFileTwoToneIcon fontSize="small" />
                  <p>Báo cáo 1.docx</p>
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>

                {/* <div className="w-1/6 flex justify-center">
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div> */}
              </div>
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <InsertDriveFileTwoToneIcon fontSize="small" />
                  <p>Báo cáo 2.docx</p>
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>

                {/* <div className="w-1/6 flex justify-center">
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div> */}
              </div>
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <InsertDriveFileTwoToneIcon fontSize="small" />
                  <p>Báo cáo 3.docx</p>
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>

                {/* <div className="w-1/6 flex justify-center">
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full h-max">
            <label>Nội dung hoàn thành</label>
            <textarea
              className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập nội dung hoàn thành"
            />
          </div>
        </div>

        <div className="w-2/5 h-max pl-5 flex flex-col space-y-3">
          <p className="font-semibold">THÔNG TIN TRAO ĐỔI</p>

          <div className="flex flex-col w-full h-max pt-3 border-t-2 space-y-2">
            <div className="w-full h-max">
              <p className="bg-slate-200 pt-1 pb-1 pr-2 pl-2 rounded-md w-max max-w-full h-max">
                How about you today?
              </p>
            </div>
            <div className="w-full h-max">
              <p className="bg-slate-200 pt-1 pb-1 pr-2 pl-2 rounded-md w-max max-w-full h-max">
                You late your task!!!
              </p>
            </div>

            <div className=" w-full h-max flex justify-end">
              <p className="bg-primary pt-1 pb-1 pr-2 pl-2 text-white rounded-md w-max max-w-full h-max">
                I'm sorry. Can I delay it until few next days?
              </p>
            </div>

            <div className="w-full h-max">
              <p className="bg-slate-200 pt-1 pb-1 pr-2 pl-2 rounded-md w-max max-w-full h-max">
                OK. Please careful
              </p>
            </div>

            <div className=" w-full h-max flex justify-end">
              <p className="bg-primary pt-1 pb-1 pr-2 pl-2 text-white rounded-md w-max max-w-full h-max">
                Thank you
              </p>
            </div>

            <div className="w-full h-max">
              <p className="bg-slate-200 pt-1 pb-1 pr-2 pl-2 rounded-md w-max max-w-full h-max">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                eget nunc ut neque vestibulum pellentesque. Vivamus vel mauris a
                nibh pulvinar laoreet.
              </p>
            </div>
          </div>

          <div className="flex flex-col w-full h-max">
            <label>Trao đổi</label>

            <div className="flex w-full h-[5rem]">
              <textarea
                className="w-4/5 h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập thông tin trao đổi"
              />
              <div className="flex w-1/5 h-full justify-center items-center">
                <button className="w-max h-max flex pt-1 pb-1 pl-3 pr-3 rounded-md bg-slate-300 hover:bg-slate-200">
                  Gửi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-400
        "
          >
            <CheckBoxIcon fontSize="small" />
            <p>Duyệt</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmFinish;
