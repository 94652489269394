import React, { useEffect, useRef, useState } from "react";
import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

import CheckIcon from "@mui/icons-material/Check";

import SubModal from "../_subModal/_index";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import {
  BAO_CAO_CHI_TIET,
  BAO_CAO_UPDATE_REPORT,
  LOAD_ONE_REQUEST,
  UPDATE_BAO_CAO,
} from "../../../apis/_index";

import moment from "moment";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import axios from "axios";

import reportView from "../../../temp/baocao";
import { reportView2 } from "../../../temp/baocao/index2";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const ReportDetailOwnerAndManagerElectric2 = (props: any) => {
  // Take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const shouldLog = useRef(true);

  const [data, setData] = useState<any>(null);

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      // const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(LOAD_ONE_REQUEST + taskId, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data);
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  }, []);

  // funcs

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[80vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        "
      >
        <p>Chi tiết yêu cầu</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {data === null ? (
        // <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm justify-center items-center flex-col">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="w-full h-[calc(100%-6rem)] flex flex-col p-3 text-sm space-y-4 overflow-auto scrollbar-custom">
          {/* Info */}
          <div className="w-full h-max flex flex-col space-y-1">
            <p className="text-primary font-bold">THÔNG TIN CHUNG</p>
            <div className="w-full h-max flex">
              <p className="font-semibold">Người tạo yêu cầu: &nbsp;</p>
              <div>{data.report?.NameUserRequest}</div>
            </div>

            <div className="w-full h-max flex">
              <p className="font-semibold">Ngày tạo yêu cầu: &nbsp;</p>
              <div>
                {moment(data.report?.DateRequest).format("HH:mm:ss")} -{" "}
                {moment(data.report?.DateRequest).format("DD/MM/YYYY")}
              </div>
            </div>

            <div className="w-full h-max flex">
              <p className="font-semibold">Thời hạn yêu cầu: &nbsp;</p>
              <div>
                {moment(data.report?.DeadLine).format("HH:mm:ss")} -{" "}
                {moment(data.report?.DeadLine).format("DD/MM/YYYY")}
              </div>
            </div>

            <div className="w-full h-max flex flex-col">
              <p className="font-semibold">Nội dung yêu cầu: &nbsp;</p>
              <div className="w-full h-max">
                <textarea
                  className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                  value={data.report?.RequestContent}
                  disabled
                />
              </div>
            </div>
          </div>

          {/* Detail */}
          <div className="w-full h-max flex flex-col space-y-1">
            <p className="font-bold text-primary">THÔNG TIN CHI TIẾT</p>

            {/* Table detail */}
            <div className="tableFixHea styled-scrollbars styled-scrollbars w-full h-max shadow-md">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">
                      Trạng thái
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Nhân viên báo cáo
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Lịch sử báo cáo
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full h-max bg-red-200 overflow-auto">
                  {data?.report_Users?.map((item: any, index: number) => (
                    <tr key={index} className="w-max h-max bg-white">
                      <td className="text-center border border-slate-300">
                        {index + 1}
                      </td>
                      <td className="text-center border border-slate-300 font-semibold">
                        {item.ReportStatus === 0 ? (
                          <p className="text-amber-500">Đang yêu cầu</p>
                        ) : item.ReportStatus === 1 ? (
                          <p className="text-primary">Mới tạo báo cáo</p>
                        ) : item.ReportStatus === 2 ? (
                          <p className="text-purple-500">Đã gửi báo cáo</p>
                        ) : item.ReportStatus === 5 ? (
                          <p className="text-red-500">Không duyệt</p>
                        ) : item.ReportStatus === 4 ? (
                          <p className="text-gray-500">
                            Trả lại bổ sung hoặc chỉnh sửa
                          </p>
                        ) : item.ReportStatus === 3 ? (
                          <p className="text-green-500">Đã duyệt</p>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item?.NameReportUser}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item?.report_history.length != 0 && (
                          <>
                            <div>- Tên báo cáo: {item?.ReportName}</div>
                            <div>
                              - File báo cáo:{" "}
                              {item?.FileName && (
                                <span
                                  className="text-blue-500 hover:text-blue-600 ml-1 cursor-pointer"
                                  onClick={() =>
                                    window.open(item?.FileName, "_blank")
                                  }
                                >
                                  Xem chi tiết
                                </span>
                              )}
                            </div>
                            <div>
                              - Người nhận báo cáo:{" "}
                              {
                                item?.report_history[0]
                                  ?.report_reviewer_details[0].NameReviewer
                              }
                            </div>
                            <div>
                              - Nội dung phản hồi:{" "}
                              {(item.ReportStatus == 5 ||
                                item.ReportStatus == 3) &&
                                item?.report_history[0]
                                  ?.report_reviewer_details[0]
                                  ?.report_history_details[0]?.Comment_UserAdd}
                            </div>
                          </>
                        )}

                        {/* {item?.report_history.map(
                          (_item: any, _index: number) => (
                            <div
                              key={_index}
                              className="w-full h-max flex flex-col"
                            >
                              <p className="font-semibold">
                                {_index + 1}. {_item?.Content}
                              </p>
                              {_item?.report_reviewer_details.map(
                                (_item2: any, _index2: number) => (
                                  <>
                                    <div
                                      key={_index2}
                                      className="w-full h-max flex"
                                    >
                                      <p>- {_item2.NameReviewer} - &nbsp;</p>
                                      <div className="font-semibold">
                                        {_item2.IsCheck === null ? (
                                          <p className="text-gray-500">
                                            Chưa duyệt
                                          </p>
                                        ) : _item2.IsCheck === true ? (
                                          <p className="text-green-500">
                                            Đã duyệt
                                          </p>
                                        ) : _item2.IsCheck === false ? (
                                          <p className="text-red-500">
                                            Không duyệt
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      Nội dung:{" "}
                                      {
                                        _item2?.report_history_details[0]
                                          ?.Comment_UserAdd
                                      }
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )
                        )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md  items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-end items-center">
          <div className="w-max h-max flex space-x-3">
            <button
              className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetailOwnerAndManagerElectric2;
