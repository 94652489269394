import {
  TIEU_CHI_DANH_GIA,
  NHOM_THAM_CHIEU,
  THEM_TIEU_CHI_DANH_GIA,
  CAP_NHAT_TIEU_CHI_DANH_GIA,
} from "../apis/_index";
import ServiceUtils from "../utils/services";

export const tieuchidanhgia_list = () => {
  return new Promise(async (resolve) => {
    return await ServiceUtils.getV2(TIEU_CHI_DANH_GIA)
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const nhomThamChieu = () => {
  return new Promise(async (resolve) => {
    return await ServiceUtils.getV2(NHOM_THAM_CHIEU)
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const tieuchidanhgia_create = (data: any) => {
  return new Promise(async (resolve) => {
    // console.log("tieuchidanhgia_create", data);
    return await ServiceUtils.postV2(THEM_TIEU_CHI_DANH_GIA, data)
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const tieuchidanhgia_update = (data: any) => {
  return new Promise(async (resolve) => {
    // console.log("tieuchidanhgia_update", data);
    return await ServiceUtils.postV2(CAP_NHAT_TIEU_CHI_DANH_GIA, data)
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};
