import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import { onTouchForm } from "../../../funcs/onTochForm";

import {
  PRIOR_CREATE,
  PRIOR_LIST,
  SECURITY_CREATE,
  SECURITY_READ_ALL,
} from "../../../apis/_index";
import {
  ONLOAD_PRIORLIST,
  ONLOAD_SECURITY_LIST,
} from "../../../redux/reducers/settingReducer/_index";
import ServiceUtils from "../../../utils/services";

const CreateSecurity = (props: any) => {
  const dispatch = useDispatch();

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // state
  const [securityName, setSecurityName] = useState<any>("");
  const [securityDescription, setSecurityDescription] = useState<any>("");
  const [securityColor, setSecurityColor] = useState<any>("#aabbcc");
  const [securityLevel, setSecurityLevel] = useState<any>("");

  // funcs
  const _checkValid = (): boolean => {
    if (
      securityName === "" ||
      securityDescription === "" ||
      securityLevel === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const _onChangeSecurityName = (e: any) => {
    e.preventDefault();
    setSecurityName(e.target.value);
  };

  const _onChangeSecurityDescription = (e: any) => {
    e.preventDefault();
    setSecurityDescription(e.target.value);
  };

  const _onChangeSecurityLevel = (e: any) => {
    e.preventDefault();
    setSecurityLevel(e.target.value);
  };

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      SecurityLevelName: securityName,
      Color: securityColor,
      Description: securityDescription,
      LevelNumber: parseInt(securityLevel),
    };

    try {
      await ServiceUtils.postV2(SECURITY_CREATE, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Tạo mới thành công.");
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(SECURITY_READ_ALL, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_SECURITY_LIST(res.data.Data));
                }
                // else {
                //   toast.error("Tải dữ liệu thất bại.");
                // }
              });
            } catch (error: any) {
              // toast.error(
              //   "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              // );
            }
          };

          takeData();
        } else {
          toast.error("Tạo mới thất bại");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[52rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tạo độ mật mới</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên độ mật&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={securityName}
              onChange={(e: any) => {
                _onChangeSecurityName(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Mô tả&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
              value={securityDescription}
              onChange={(e: any) => {
                _onChangeSecurityDescription(e);
              }}
            />
          </div>
        </div>

        <div className="flex w-full h-max space-x-6">
          <div className="w-1/3 h-max flex flex-col space-y-6">
            <div className="flex items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Hiển thị </label>
              <input type="checkbox" className="w-5 h-5" />
            </div>

            <div className="flex lg:flex-row flex-col items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Mức độ</label>
              <input
                className="lg:w-1/3 w-full h-max rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={securityLevel}
                onChange={(e: any) => {
                  _onChangeSecurityLevel(e);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>

          <div className="flex flex-col w-2/5 h-max">
            <p>Màu sắc</p>

            <div className="w-full h-max ">
              <div className="w-[6rem] h-[6rem]">
                <HexColorPicker
                  color={securityColor}
                  onChange={setSecurityColor}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              _checkValid() === true ? "flex" : "hidden"
            } absolute right-4 bottom-4`}
          >
            <p className="text-red-500">Vui lòng điền đầy đủ thông tin</p>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${
            _checkValid() === true
              ? "bg-slate-200"
              : "bg-primary hover:bg-sky-400"
          }
        `}
            onClick={_onClickSubmit}
            disabled={_checkValid() === true ? true : false}
          >
            <SaveIcon fontSize="small" />
            <p>Tạo mới</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateSecurity;
