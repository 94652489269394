export const convertDayTime = (time: any) => {
  // 1 day = 24 hours * 60 minutes = 1440 minutes
  var days = Math.floor(time / 1440);
  var finalDays = days > 0 ? `${days} ngày ` : "";

  // Calculate the minutes
  var remainingMinutes = time % 1440;
  // Calculate the hours
  var hours = Math.floor(remainingMinutes / 60);
  var finalHours = hours > 0 ? `${hours}h` : "";
  // Calculate the minutes
  var minutes = Math.floor(remainingMinutes % 60);
  var finalMinutes = minutes > 0 ? `${minutes}' ` : "";

  return finalDays + finalHours + finalMinutes;
};
