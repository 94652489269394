import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONLOAD_TASKLIST_ALL,
  ONFILTER_DATA,
  ONLOAD_TASKRECOVERYLIST_ALL,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import LoaderSpinner from "../../loader-spinner/_index";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";
import { Checkcolor } from "../../../funcs/checkColor";
import { MODAL_KEYS } from "../../../constants/constants";

import RedoIcon from "@mui/icons-material/Redo";
import { TASK_RECOVERY_LIST } from "../../../apis/_index";
import LoadingControl from "../../../controls/loading/loadingControl";
import HandleUI from "../../../utils/handleUI";

const WorkingTaskRecoveryJSX = () => {
  _onClickOther();

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const appData = useSelector((state: any) => state.appReducer);

  const shouldLog = useRef(true);
  const link = window.location.pathname;

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const _fetchData = (page: any, limit: any) => {
    readList(
      TASK_RECOVERY_LIST,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKRECOVERYLIST_ALL(res.data.Data));
      }
    );
  };

  const _onClickEditTaskWithId = (id: any) => {
    if (!id) {
      toast.error("Công việc này không tồn tại");
      return;
    }

    context.setFuncs(MODAL_KEYS.detailTaskRecovery);
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = parseInt(e.target.value);

    dispatch(ONCHANGE_CHOOSEPAGE(value));

    _fetchData(0, value);
  };

  const _onClickGiveTask = (id: any) => {
    context.setFuncs("giveTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteTask = (id: any) => {
    context.setFuncs("deleteTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickSwitchToBoss = (id: any) => {
    context.setFuncs("switchTaskToBoss");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickTransfer = (id: any, taskCode: any) => {
    context.setFuncs(MODAL_KEYS.editTaskTransfer);
    context.setTaskId(id);
    context.setTaskCode(taskCode);
    context.handleOpen();
  };

  var permiss: any = localStorage.getItem("menuList");
  permiss = (permiss || "").replace(/'/g, '"'); //replacing all ' with "

  if (!permiss) {
    permiss = "[]";
  }

  permiss = JSON.parse(permiss);

  let checkPermiss = permiss.find((x: any) => x.FormId === 20);

  const list = (workingData.taskRecovery || {}).data || [];

  return (
    <>
      {!(workingData || {}).taskRecovery ? (
        // <div className="flex justify-center items-center flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.taskRecovery.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.taskRecovery.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          {/* Content */}
          <div
            className="tableFixHead styled-scrollbars w-full shadow-md"
            id="table"
          >
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người đánh giá</p> */}
                  {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                  {/* </div>
                  </th> */}
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-full bg-white overflow-auto">
                {!(workingData || {}).taskRecovery ? (
                  <tr></tr>
                ) : (
                  // workingData.taskRecovery.data?
                  list.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                      </td>
                      <td className="border border-slate-300">
                        <p className="lg:hidden font-bold ">
                          <span>
                            {index +
                              1 +
                              workingData.pageCurrent * workingData.choosePage}
                            .&nbsp;
                          </span>
                          {item.TaskName}
                        </p>
                        <p className="hidden lg:flex">{item.TaskName}</p>
                        <p className="lg:hidden">
                          <span className="text-xs">Người đánh giá: </span>
                          <span className="font-semibold">
                            {item.TaskReviewerName}
                          </span>
                        </p>
                        <p className="lg:hidden">
                          <span className="text-xs">Người nhận: </span>
                          <span className="font-semibold">
                            {item.RecipientName}
                          </span>
                        </p>
                        {item.SupporterName && (
                          <p className="lg:hidden">
                            <span className="text-xs">Người hỗ trợ:&nbsp;</span>
                            <span className="text-slate-600">
                              {item.SupporterName}
                            </span>
                          </p>
                        )}
                        {/* <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">
                              Số giờ thực hiện:{" "}
                              <span className="italic">
                                {item.HoursUsed} giờ {item.MinutesUsed} phút
                              </span>
                            </span>
                          </p>
                        </div>
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">
                              Số giờ còn lại:{" "}
                              <span className="italic">
                                {item.Hours_in_month_remaining} giờ
                              </span>
                            </span>
                          </p>
                        </div> */}
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">Thời hạn: </span>
                            <span className="italic">
                              {moment(item.DeadLine).format("DD/MM/YYYY HH:mm")}
                            </span>
                          </p>
                        </div>

                        <div className="lg:hidden flex justify-between items-center">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold mr-2 ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}
                            
                            `}
                          >
                            {item.PriorityLevelName}
                          </p>
                          <div className="dropdown2">
                            {/* button options */}
                            <button
                              className="dropbtn2"
                              onClick={() => _onClickFuncs(index.toString())}
                            >
                              ...
                            </button>

                            {/* options fields */}
                            <div
                              id={index.toString()}
                              className="dropdown-content2 text-xs font-semibold"
                            >
                              {/* detail */}
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() =>
                                  _onClickEditTaskWithId(item.TaskID)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                              {!item.IsTransfered && (
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickTransfer(item.TaskID, item.TaskCode)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <RedoIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chuyển giao</div>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <p
                          style={{
                            color: Checkcolor(item.PriorityLevelID, appData),
                          }}
                          className={`font-bold ${Checkcolor(
                            item.PriorityLevelID,
                            appData
                          )}`}
                        >
                          {item.PriorityLevelName}
                        </p>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>
                            {moment(item.AssignmentDate).format("DD/MM/YYYY")}
                          </p>
                          <p>
                            {moment(item.AssignmentDate).format("HH:mm:ss")}
                          </p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.RecipientName}
                        {item.SupporterName ? (
                          <p className="text-xs">
                            <span className="text-slate-600">Hỗ trợ:</span>{" "}
                            {item.SupporterName}
                          </p>
                        ) : null}
                      </td>
                      {/* <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.TaskReviewerName}
                      </td> */}
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button
                            className="dropbtn"
                            onMouseEnter={() => {
                              HandleUI.hoverMenuContextScrollTable(
                                index,
                                list.length
                              );
                            }}
                          >
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() =>
                                _onClickEditTaskWithId(item.TaskID)
                              }
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                            {!item.IsTransfered && (
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() =>
                                  _onClickTransfer(item.TaskID, item.TaskCode)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <RedoIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chuyển giao</div>
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingTaskRecoveryJSX;
