import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ModalController } from "../../ui/homeUI/_index";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  YEAR_LIST,
  USER_READ_ALL,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  LOAD_ALL_NOTI,
  REPORT_TASK_TOTAL_LIST,
  LOAD_ALL_NOTI_2,
  TAKE_USER_INFO,
} from "../../apis/_index";
import axios from "axios";

import receiverImage from "../../assets/images/SelfManagement.png";
import Select from "react-select";

import {
  ONCHANGE_CLEAR_FILTER,
  ONCHANGE_FROM_HOME_TO_PROCESSING,
  ONCHANGE_SEARCH_MONTH,
  ONCHANGE_SEARCH_YEAR,
} from "../../redux/reducers/workingReducer/_index";
import ContentPasteOffRoundedIcon from "@mui/icons-material/ContentPasteOffRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";

import moment from "moment";

import { toast } from "react-toastify";
// funcs
import Badge from "@mui/material/Badge";

// icon components
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";

// icon
import WorkIcon from "@mui/icons-material/Work";

import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

// working
// -- sender
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";

// -- receiver
import { AccessTimeFilledOutlined } from "@mui/icons-material";

import TopicRoundedIcon from "@mui/icons-material/TopicRounded";
import ServiceUtils from "../../utils/services";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import {
  getHoursRemaining,
  getHoursUse,
  getHoursWork,
} from "../../utils/users/userUtils";
//CÁC VIỆC CHỜ DUYỆT VÀ ĐÁNH GIÁ KẾT QUẢ

const NormalStaffUI = (props: any) => {
  const urlLink = window.location.pathname;
  const navigate = useNavigate();

  const context = useContext(ModalController);

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [phongBanList, setPhongBanList] = useState<any[] | undefined>(
    undefined
  );
  const [userFollowPhongBanList, setUserFollowPhongBanList] = useState<
    any[] | undefined
  >(undefined);
  const [notiList, setNotiList] = useState<any | null>(null);
  const [notiList_NguoiNhan, setNotiList_NguoiNhan] = useState<any | null>(
    null
  );

  let userData: any = localStorage.getItem("userData");
  if (userData) {
    userData = userData?.replace(/'/g, '"'); //replacing all ' with "
    userData = JSON.parse(userData!);
  }

  const [staData, setStaData] = useState<any[] | null>(null);

  const shouldLog = useRef(true);

  const [selectedPhongBan, setSelectedPhongBan] = useState<any | undefined>(
    null
  );
  const [selectedUserFollowPhongBanList, setSelectedUserFollowPhongBanList] =
    useState<any[] | undefined>();

  const [subShowing, setSubShowing] = useState<string>("");

  const hoursRemaining = getHoursRemaining();
  const [hoursUse, setHoursUse] = useState<any>("");
  const hoursWork = getHoursWork();

  // -- for year
  const [monthSearch, setMonthSearch] = useState<any | "">(
    new Date().getMonth() + 1
  );
  const [yearSearch, setYearSearch] = useState<any | "">(
    new Date().getFullYear()
  );
  const [yearList, setYearList] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<any | undefined>(null);

  function formatNumber(number: any) {
    if (number.toString().includes(".")) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    } else {
      return numberWithCommas(number);
    }
  }

  function numberWithCommas(number: any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const convertMonthToList = (month: any) => {
    if (month) {
      let a: any;
      tempMonthList.map((x: any) => {
        if (x.id === month) {
          a = x;
        }
      });

      return a;
    }
  };

  const tempMonthList = [
    {
      id: 1,
      value: 1,
      label: 1,
    },
    {
      id: 2,
      value: 2,
      label: 2,
    },
    {
      id: 3,
      value: 3,
      label: 3,
    },
    {
      id: 4,
      value: 4,
      label: 4,
    },
    {
      id: 5,
      value: 5,
      label: 5,
    },
    {
      id: 6,
      value: 6,
      label: 6,
    },
    {
      id: 7,
      value: 7,
      label: 7,
    },
    {
      id: 8,
      value: 8,
      label: 8,
    },
    {
      id: 9,
      value: 9,
      label: 9,
    },
    {
      id: 10,
      value: 10,
      label: 10,
    },
    {
      id: 11,
      value: 11,
      label: 11,
    },
    {
      id: 12,
      value: 12,
      label: 12,
    },
  ];

  const loadDataCount = (accessToken: any, api: any) => {
    const takeNoti = async () => {
      try {
        await ServiceUtils.getV2(api, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setNotiList(res.data.Data?.data);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeNoti();
  };

  const _onClickToChangeNavigate = (title: string) => {
    let year = yearSearch.toString();
    let month = monthSearch.toString();
    let titleFinal = title;
    dispatch(ONCHANGE_CLEAR_FILTER({}));
    if (
      titleFinal === "/working/receiver/all-task" ||
      titleFinal === "/working/receiver/task-returnwork"
    ) {
      // window.history.pushState(
      //   { month: month.toString(), year: year.toString() },
      //   "",
      //   titleFinal
      // );
      navigate(titleFinal, {
        state: { month: month.toString(), year: year.toString() },
      });
      // dispatch(ONCHANGE_SEARCH_MONTH(month));
      // dispatch(ONCHANGE_SEARCH_YEAR(year));
    } else {
      navigate(titleFinal, { state: { month: "", year: "" } });
      // window.history.pushState({ month: "", year: "" }, "", titleFinal);
      // dispatch(ONCHANGE_SEARCH_MONTH(""));
      // dispatch(ONCHANGE_SEARCH_YEAR(""));
    }
    // navigate(titleFinal);
    // try {
    // Promise.all([
    //   dispatch(
    //     ONCHANGE_FROM_HOME_TO_PROCESSING({
    //       month: month,
    //       year: year,
    //       url: titleFinal,
    //     })
    //   ),

    // ]);

    // } catch (error: any) {}
  };
  const _onClickToChangeNavigate2 = (title: string) => {
    let year = yearSearch;
    let month = monthSearch;
    let titleFinal = title;
    dispatch(ONCHANGE_CLEAR_FILTER({}));
    if (title === "/working/receiver/all-task") {
      navigate(titleFinal, {
        state: { month: month.toString(), year: year.toString() },
      });
    } else {
      navigate(titleFinal, { state: { month: "", year: "" } });
    }
    // dispatch(ONCHANGE_SEARCH_MONTH(""));
    // dispatch(ONCHANGE_SEARCH_YEAR(""));
    // navigate(titleFinal);
    // try {
    // Promise.all([
    //   dispatch(
    //     ONCHANGE_FROM_HOME_TO_PROCESSING({
    //       url: titleFinal,
    //     })
    //   ),
    //   navigate(titleFinal),
    // ]);

    // } catch (error: any) {}
  };

  const _onChangeSelect = (e: any, type: any) => {
    let _year = yearSearch;
    let _month = monthSearch;

    if (type === "year" && e?.id) {
      _year = e?.id;
      setYearSearch(e?.id);
      setSelectedYear(e);
    } else if (type === "month") {
      _month = e?.id;
      setMonthSearch(e?.id);
    }
  };
  // -- end year

  useEffect(() => {
    let _year = yearSearch;
    let _month = monthSearch;
    const accessToken = localStorage.getItem("accessToken");
    const API_Noti = LOAD_ALL_NOTI + "?month=" + _month + "&year=" + _year;
    loadDataCount(accessToken, API_Noti);
  }, [monthSearch, yearSearch]);

  // onMount
  useEffect(() => {
    const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

    const accessToken = localStorage.getItem("accessToken");

    const API_Noti =
      LOAD_ALL_NOTI + "?month=" + monthSearch + "&year=" + yearSearch;

    const takeData = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            const tempArray = res.data.Data.map((item: any) => {
              return {
                ...item,
                value: item.ParameterValue,
                label: item.ParameterValue,
              };
            });
            setPhongBanList(tempArray);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    const takeNoti = async () => {
      try {
        await ServiceUtils.getV2(LOAD_ALL_NOTI, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setNotiList(res.data.Data?.data);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    const takeNoti_Nguoinhan = async () => {
      try {
        await ServiceUtils.getV2(LOAD_ALL_NOTI_2, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setNotiList_NguoiNhan(res.data.Data);
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    const takeYear = async () => {
      try {
        await ServiceUtils.getV2(YEAR_LIST, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res?.data?.StatusCode === 200) {
            setYearList(convertYearToSelect(res?.data?.Data?.data || []));
          } else {
            toast.error(res?.data?.Message || "Tải dữ liệu năm thất bại");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    const takeData_ReloadUsers = async () => {

      try {
        await ServiceUtils.getV2(TAKE_USER_INFO, {
          headers: {},
        }).then((res: any) => {
          if (res?.data?.StatusCode == 200) {
            setHoursUse((res?.data?.Data[0]?.Hours_in_month_used + res?.data?.Data[0]?.Hours_in_month_used_MySelf) || 0)
          }

        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };
    takeData_ReloadUsers();

    takeYear();
    takeData();
    takeNoti();
    takeNoti_Nguoinhan();
    loadDataCount(accessToken, API_Noti);
  }, []);

  const convertYearToSelect = (year: any[] = []) => {
    let lstYear: any[] = [];
    if (year && year?.length > 0) {
      year.map((x: any) => {
        lstYear.push({
          id: x.Year,
          value: x.Year,
          label: x.Year,
        });
      });
      return lstYear;
    } else {
      return [];
    }
  };

  // onLoadWithSelectedPhongBan
  useEffect(() => {
    if (selectedPhongBan === null) {
      setUserFollowPhongBanList(undefined);
    } else {
      const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setUserFollowPhongBanList(
                res.data.Data.filter((item: any) =>
                  (item.PhongBan || "")
                    .split(",")
                    .find((p: any) => p == selectedPhongBan) != null
                    ? true
                    : false
                )
              );
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  }, [selectedPhongBan]);

  // funcs
  const _onChangePhongBan = (data: any) => {
    if (data === null) {
      setSelectedPhongBan(null);
    } else {
      setSelectedPhongBan(data.ParameterId);
    }
  };

  const _onClickNavigate = (url: string) => {
    navigate(url);
  };

  const _onLoadData = async (
    searchMonth: number | undefined,
    searchYear: number,
    searchPhongBan: string | number,
    userId: string | number
  ) => {
    // const accessToken = localStorage.getItem("accessToken");

    // let tempUser: string =
    //   userId === -1 || userId === "" ? "" : userData.Id;
    // let tempPhongBan: string =
    //   selectedPhongBan === -1 || selectedPhongBan === ""
    //     ? ""
    //     : selectedPhongBan?.ParameterId.toString();

    const body: any = {
      SEARCH_MONTH: searchMonth,
      SEARCH_YEAR: searchYear,
      SERACH_PHONGBAN: searchPhongBan,
      USER_ID: userId,
    };

    try {
      await ServiceUtils.postV2(REPORT_TASK_TOTAL_LIST, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.data.StatusCode === 200) {
          if (res.data.Data.data === null) {
            setStaData([]);
          } else {
            setStaData(res.data.Data.data);
          }
        }
      });
    } catch (error: any) {}
  };

  // Effect.
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      _onLoadData(monthSearch, yearSearch, userData.PhongBan, userData.Id);
    }
  }, []);

  // --- Main funcs:
  const tinhwidth = (maxWidth: any, currentWidth: any) => {
    if (maxWidth <= 0) {
      return 10;
    } else {
      return (currentWidth / maxWidth) * 100;
    }
  };

  const _onClickReportTasking = (
    type: string,
    userId: string,
    dataModal: any
  ) => {
    switch (type) {
      case "TotalListAll":
        context.setFuncs("TotalListAll");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListNew":
        context.setFuncs("TotalListNew");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;
      case "TotalListNew_Support":
        context.setFuncs("TotalListNew_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListReveced":
        context.setFuncs("TotalListReveced");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;
      case "TotalListReveced_Support":
        context.setFuncs("TotalListReveced_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListProccessing":
        context.setFuncs("TotalListProccessing");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;
      case "TotalListProccessing_Support":
        context.setFuncs("TotalListProccessing_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListCompletedWaiting":
        context.setFuncs("TotalListCompletedWaiting");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListCompleted":
        context.setFuncs("TotalListCompleted");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListCompleted_Support":
        context.setFuncs("TotalListCompleted_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      default:
        break;
    }
  };

  const _onClickCompareStaff = (userId: string, idPhongBan: string) => {
    // console.log({
    //   month,
    //   year,
    //   selectedPhongBan,
    //   selectedUser
    // })

    context.setFuncs("compareStaff");
    context.setDataModal({
      _onLoadData,
      monthSearch,
      yearSearch,
      selectedPhongBan: idPhongBan,
      selectedUser: userId,
    });
    context.handleOpen();
  };

  const _onClickToShowReport = () => {
    context.setFuncs("viewReport");

    context.setDataModal({
      monthSearch,
      yearSearch,
    });

    context.handleOpen();
  };

  return (
    <div className="w-full h-full flex lg:flex-row flex-col pl-3 pr-5 mt-5">
      {/* leftside */}

      {/* rightside */}
      <div className="w-full h-max flex flex-col space-y-5 lg:pl-6">
        {userData?.Data[0]?.DataLevel == 1 && (
          <div className="w-full h-[1rem] flex justify-end">
            <button
              className="flex p-1.5 w-max bg-primary h-max justify-center items-center rounded-md text-white hover:bg-cyan-400"
              onClick={_onClickToShowReport}
            >
              <AssessmentIcon />
              <p className="font-semibold">Thống kê</p>
            </button>
          </div>
        )}
        <div className="w-full h-max flex justify-center items-center">
          <img
            className="pointer-events-none "
            style={{ width: "var(--px-180)" }}
            src={receiverImage}
            alt=""
          />
        </div>
        {userData?.Data[0]?.DataLevel == 1 && (
          <div className="w-full h-max flex items-center justify-center">
            <p className="font-bold">
              Số giờ đã sử dụng {new Date().getMonth() + 1}/
              {new Date().getFullYear()}:&ensp;
            </p>

            <div className="h-full flex flex-col justify-center items-center rounded-md border  px-4 py-1.5">
              <p className="font-semibold">
                {formatNumber(hoursUse) ?? 0}
                <span className="font-normal">&ensp;/&ensp;</span>
                {formatNumber(hoursWork) ?? 0}
              </p>
            </div>
            <p>&ensp;(giờ)</p>
          </div>
        )}

        <div className="flex flex-col w-full h-max text-sm space-y-6">
          {/* Elements with sub */}
          <div className="w-full h-max flex flex-col space-y-5">
            {/* Main */}
            {yearList.length === 0 ? (
              <></>
            ) : (
              <>
                {userData?.Data[0]?.DataLevel == 1 && (
                  <div className="items-center grid grid-cols-1 lg:grid-cols-2 gap-5 my-2">
                    <div className="w-full h-max flex items-center">
                      <p className="w-2/6 font-bold">Chọn tháng</p>
                      <Select
                        className="z-[5] w-4/6 lg:mr-5"
                        options={tempMonthList}
                        defaultValue={convertMonthToList(monthSearch)}
                        onChange={(e: any) => _onChangeSelect(e, "month")}
                        isClearable
                        placeholder="--- Chọn tháng  ---"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="w-full h-max flex items-center">
                      <p className="w-2/6 font-bold">Chọn năm</p>
                      <Select
                        className="z-[5] w-4/6"
                        options={yearList}
                        value={
                          selectedYear
                            ? selectedYear
                            : yearList.find((x: any) => x?.value == yearSearch)
                        }
                        onChange={(e: any) => _onChangeSelect(e, "year")}
                        isClearable
                        placeholder="--- Chọn năm  ---"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <div
              className="w-full h-[45px] flex border-2 border-slate-300 rounded-md hover:cursor-pointer hover:bg-white"
              onClick={() =>
                _onClickToChangeNavigate("/working/receiver/all-task")
              }
            >
              <div className="w-[calc(100%-45px)] h-full flex items-center px-3 text-amber-500 rounded-md space-x-3 transition-all">
                <div className="w-max h-max">
                  <TopicRoundedIcon />
                </div>
                <p className="lg:text-lg text-xs font-bold">
                  CÁC VIỆC ĐÃ NHẬN TRONG THÁNG {monthSearch}/{yearSearch}
                </p>
              </div>
              <div className="w-[45px] h-full flex justify-center items-center border-l-2 border-slate-300">
                <p className="p-1 bg-red-500 text-white font-semibold rounded-full text-xs">
                  {notiList_NguoiNhan?.countListAll}
                </p>
              </div>
            </div>

            {/* Sub */}
            <div className="w-full h-max grid lg:grid-cols-4 grid-cols-3 gap-6">
              {/* Element */}
              <button
                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                onClick={() =>
                  _onClickToChangeNavigate("/working/receiver/task-new")
                }
              >
                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                  <Badge
                    badgeContent={notiList_NguoiNhan?.countListNew}
                    color="error"
                  >
                    <AddAlarmIcon sx={{ fontSize: "45px" }} />
                  </Badge>
                </div>

                <div className="w-max h-max max-w-full">
                  <p className="">Các việc mới được giao</p>
                </div>
              </button>

              <button
                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                onClick={() =>
                  _onClickToChangeNavigate("/working/receiver/task-processing")
                }
              >
                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                  <Badge
                    badgeContent={notiList_NguoiNhan?.countListWorking}
                    color="error"
                  >
                    <WorkIcon sx={{ fontSize: "45px" }} />
                  </Badge>
                </div>

                <div className="w-full h-max max-w-xs">
                  <p className="">Các việc đang làm</p>
                </div>
              </button>

              <button
                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                onClick={() =>
                  _onClickToChangeNavigate("/working/receiver/task-delay")
                }
              >
                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                  <Badge
                    badgeContent={notiList_NguoiNhan?.countListLated}
                    color="error"
                  >
                    <AccessTimeFilledOutlined sx={{ fontSize: "45px" }} />
                  </Badge>
                </div>

                <div className="w-full h-max max-w-xs">
                  <p className="">Các việc đang bị trễ</p>
                </div>
              </button>

              <button
                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                onClick={() =>
                  _onClickToChangeNavigate2("/working/receiver/task-waiting")
                }
              >
                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                  <Badge
                    badgeContent={notiList_NguoiNhan?.countListCompleted}
                    color="error"
                  >
                    <HourglassFullRoundedIcon sx={{ fontSize: "45px" }} />
                  </Badge>
                </div>

                <div className="w-full h-max max-w-xs">
                  <p className="">Các việc chờ duyệt</p>
                </div>
              </button>

              <button
                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                onClick={() =>
                  _onClickToChangeNavigate("/working/receiver/task-returnwork")
                }
              >
                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                  <Badge
                    badgeContent={notiList_NguoiNhan?.countReturnWork}
                    color="error"
                  >
                    <KeyboardReturnRoundedIcon sx={{ fontSize: "45px" }} />
                  </Badge>
                </div>

                <div className="w-full h-max max-w-xs">
                  <p className="">Các việc bị trả về</p>
                </div>
              </button>

              <button
                className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                onClick={() =>
                  _onClickToChangeNavigate2("/working/receiver/all-task")
                }
              >
                <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                  <Badge
                    badgeContent={notiList_NguoiNhan?.countListAll}
                    color="error"
                  >
                    <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                  </Badge>
                </div>

                <div className="w-full h-max max-w-xs">
                  <p className="">Tổng hợp các công việc đã nhận</p>
                </div>
              </button>
            </div>

            {/* Statisticfy */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NormalStaffUI;
