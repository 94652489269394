import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ONCHANGE_NGUOI_NHAN_DA_NHAN } from "../../../redux/reducers/workingReducer/_index";

import FlagIcon from "@mui/icons-material/Flag";
import { toast } from "react-toastify";
import axios from "axios";

import NotificationsIcon from "@mui/icons-material/Notifications";

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import {
  ConfirmTaskContent,
  ConfirmTaskContent_NguoiGiao,
} from "../../../action/taskOptionsAction";

import LoaderSpinner from "../../loader-spinner/_index";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CheckIcon from "@mui/icons-material/Check";

const ConfirmTaskContents = (props: any) => {
  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const taskContentHistoryId = props.taskContentHistoryId;
  const isConfirm = props.isConfirm;
  const taskContentID = props.taskContentID;
  const _handleCheckCompled = props._handleCheckCompled;
  const isOwner = props.isOwner;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const urlLink = window.location.pathname;
  const shouldLog = useRef(true);

  const data = useSelector((state: any) => state.workingReducer);

  const [reason, setReason] = useState<any>(null);

  const _onClickConfirmTask = async () => {
    setIsLoading(true);

    const body: any = {
      ID: taskContentID ? taskContentID : "",
      TaskContentHistoryId: taskContentHistoryId ? taskContentHistoryId : "",
      Checked: isConfirm === 1 ? true : false,
      Reason: reason ? reason : "",
    };
    if (isOwner === 1) {
      ConfirmTaskContent_NguoiGiao(JSON.stringify(body))
        .then((res: any) => {
          const _data = (res || {}).data || [];
          const data = _data[0];
          if (data.RCode === 200) {
            if (_handleCheckCompled) {
              _handleCheckCompled(1);
            }
            toast.success("Duyệt kết quả bước thành công");
          } else {
            toast.error(data.RMessage || "Duyệt kết quả bước thất bại!");
          }
        })
        .catch((err: any) => {
          toast.error(err || "Duyệt kết quả bước thất bại!");
        })
        .finally(() => {
          if (handleClose) {
            handleClose();
          }
          setIsLoading(false);
        });
    } else if (isOwner === 0) {
      ConfirmTaskContent(JSON.stringify(body))
        .then((res: any) => {
          const _data = (res || {}).data || [];
          const data = _data[0];
          if (data.RCode === 200) {
            if (_handleCheckCompled) {
              _handleCheckCompled(1);
            }
            toast.success("Duyệt kết quả bước thành công");
          } else {
            toast.error(data.RMessage || "Duyệt kết quả bước thất bại!");
          }
        })
        .catch((err: any) => {
          toast.error(err || "Duyệt kết quả bước thất bại!");
        })
        .finally(() => {
          if (handleClose) {
            handleClose();
          }
          setIsLoading(false);
        });
    }
  };

  const _onChangeText = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    if (value !== null) {
      setReason(value);
    }
  };

  return (
    <>
      {isConfirm === 1 ? (
        <div
          className="
      flex bg-green-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
        >
          {/* icon */}
          <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
            <NotificationsIcon sx={{ fontSize: 100 }} />
          </div>

          {/* content */}
          <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
            <p>Bạn có đồng ý duyệt kết quả bước này?</p>
            <div className="w-full h-max flex justify-center items-center space-x-12">
              <button
                className={`w-[5rem] h-max p-2 rounded-md ${
                  isLoading === true
                    ? "bg-slate-300"
                    : "bg-slate-500  hover:bg-slate-400"
                }`}
                onClick={_onClickConfirmTask}
                disabled={isLoading}
              >
                {isLoading === true ? (
                  <div className="flex w-full h-full justify-center items-center">
                    <LoaderSpinner w={20} h={20} />
                  </div>
                ) : (
                  <p>Có</p>
                )}
              </button>
              <button
                className={`w-[5rem] h-max p-2  rounded-md ${
                  isLoading === true
                    ? "bg-red-300"
                    : "bg-red-500 hover:bg-red-400"
                }`}
                onClick={handleClose}
                disabled={isLoading}
              >
                Không
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
          >
            {/* header */}
            <div
              className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
            >
              <p>Không duyệt </p>
              <button
                className="hover:bg-slate-300 p-0.5 rounded-md"
                onClick={handleClose}
              >
                X
              </button>
            </div>

            {/* content */}
            <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
              {/* lines */}

              <div className="flex w-full h-max">
                <div className="flex flex-col w-full h-max">
                  <label>
                    Lý do&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <textarea
                    className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
                    placeholder="Nhập nội dung duyệt"
                    onChange={(e: any) => {
                      _onChangeText(e);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* footer */}
            <div className="flex footer w-full p-12-16 rounded-b-md justify-end items-center text-sm space-x-4">
              <div className="flex w-max h-max space-x-3">
                {/* <button
                className={`
                  flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
                  ${reason === "" || reason == null ? "bg-slate-300" : "bg-green-500 hover:bg-green-400"}
                `}
                disabled={reason === "" || reason == null ? true : false}
                onClick={_onClickConfirmTask}
              >

                <CheckIcon fontSize="small" />
                <p>Xác nhận</p>
              </button> */}

                <button
                  className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md 
                ${
                  // (isLoading === true ? "bg-slate-300" : "bg-slate-500  hover:bg-slate-400") ||
                  reason === "" || reason == null
                    ? "bg-slate-300"
                    : isLoading === true
                    ? "bg-slate-300"
                    : "bg-green-500 hover:bg-green-400"
                }`}
                  onClick={_onClickConfirmTask}
                  disabled={
                    isLoading ||
                    (reason === "" || reason == null ? true : false)
                  }
                >
                  {isLoading === true ? (
                    <div className="flex w-full h-full justify-center items-center">
                      <LoaderSpinner w={20} h={20} />
                    </div>
                  ) : (
                    <>
                      <CheckIcon fontSize="small" />
                      <p>Xác nhận</p>
                    </>
                  )}
                </button>

                <button
                  className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
                  onClick={handleClose}
                >
                  <ExitToAppTwoToneIcon fontSize="small" />
                  <p>Đóng</p>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmTaskContents;
