export const takePermission = (id: number) => {
  const menuList = localStorage.getItem("menuList");

  if (menuList === null) {
    return;
  } else {
    const list = JSON.parse(menuList).find((e: any) => e.FormId === id);
    
    return list;
  }
}
