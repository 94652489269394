import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HistoryIcon from "@mui/icons-material/History";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import {
  NGUOI_GIAO_VIEC_DETAIL,
  USER_READ_ALL_DROPDOWN,
} from "../../../apis/_index";

import MoreTimeIcon from "@mui/icons-material/MoreTime";

import { CHECK_RESULT } from "../../../apis/_index";
import { LICH_SU_GIA_HAN } from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import ServiceUtils from "../../../utils/services";
import HandleDate from "../../../utils/date/handleDate";
interface Event {
  event_id?: string;
  start: Date;
  end: Date;
  title: string;
  type: number;
}
const ShowMoreSchedule = (props: any) => {
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const [taskId, setTaskId] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  // take props
  const handleClose = props.handleClose;
  const [data, setData] = useState<any>([]);
  // funcs

  useEffect(() => {
    setData(props.taskId);
  }, []);

  const _onSelectEvent = (event: Event) => {
    console.log("event.type, ", event.type);
    if (event.type === 1) {
      if (event.event_id) {
        const API = NGUOI_GIAO_VIEC_DETAIL(event.event_id);
        ServiceUtils.getV2(API)
          .then((res: any) => {
            if (res?.data && res?.data.StatusCode === 200) {
              const task = res.data.Data.task;
              handleOpenSub();
              if (task.TaskStatus === 5) {
                setFuncsSub("taskNeedConfirm");
              } else {
                setFuncsSub("readTotalTask_nguoigiao");
              }

              setTaskId(task.ID);
            } else {
              toast.error(res?.message);
            }
          })
          .catch((error: any) => {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          });
      }
    } else if (event.type === 4) {
      if (event.event_id) {
        handleOpenSub();
        setFuncsSub("detailOnLeaving");
        setTaskId(event.event_id);
      }
    } else if (event.type === 3) {
      if (event.event_id) {
        handleOpenSub();
        setFuncsSub("detailOnHoliday");
        setTaskId(event.event_id);
      }
    } else if (event.type === 5) {
      if (event.event_id) {
        handleOpenSub();
        setFuncsSub("detailMeeting");
        setTaskId(event.event_id);
      }
    } else if (event.type === 6) {
      if (event.event_id) {
        handleOpenSub();
        setFuncsSub("detailBusinessTrip");
        setTaskId(event.event_id);
      }
    }
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70vw] w-screen lg:h-max max-h-[90vh]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
        _onFetchData={() => {}}
      />
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Danh sách các sự kiện</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {data.length === 0 ? (
        <div className="flex justify-center items-center h-full overflow-auto p-4 text-lg">
          <p className="text-primary">Không có sự kiện nào</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <div className="tableFixHead styled-scrollbars w-full h-full shadow-md border-t-2  text-sm">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="border-l-[1px] border-slate-300">STT</th>
                  <th className="border-l-[1px] border-slate-300">
                    Tên sự kiện
                  </th>
                  <th className="border-l-[1px] border-slate-300">Danh mục</th>
                  <th className="border-l-[1px] border-slate-300">Từ ngày</th>
                  <th className="border-l-[1px] border-slate-300">Đến ngày</th>
                  <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {data === null ? (
                  <></>
                ) : (
                  data.map((item: Event, index: any) => (
                    <tr className={`w-max h-[3rem] bg-white`}>
                      <td className="border border-slate-300 text-center">
                        {index + 1}
                      </td>
                      <td className="border border-slate-300">{item.title}</td>
                      <td className="border border-slate-300 text-center">
                        {(() => {
                          let typeLabel;
                          switch (item.type) {
                            case 1:
                              typeLabel = "Công việc";
                              break;
                            case 6:
                              typeLabel = "Lịch công tác";
                              break;
                            case 3:
                              typeLabel = "Ngày nghỉ lễ";
                              break;
                            case 4:
                              typeLabel = "Ngày nghỉ phép";
                              break;
                            case 5:
                              typeLabel = "Lịch họp";
                              break;
                            default:
                              typeLabel = "";
                          }
                          return typeLabel;
                        })()}
                      </td>

                      <td className="border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>
                            {moment(
                              HandleDate.convertDateToISOString(item.start)
                            ).format("DD/MM/YYYY")}
                          </p>
                          <p>
                            {moment(
                              HandleDate.convertDateToISOString(item.start)
                            ).format("HH:mm:ss")}
                          </p>
                        </div>
                      </td>
                      <td className="border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>
                            {moment(
                              HandleDate.convertDateToISOString(item.end)
                            ).format("DD/MM/YYYY")}
                          </p>
                          <p>
                            {moment(
                              HandleDate.convertDateToISOString(item.end)
                            ).format("HH:mm:ss")}
                          </p>
                        </div>
                      </td>
                      <td className="border border-slate-300 text-center">
                        <button
                          className="
                            w-max h-max text-white bg-green-500 hover:bg-green-400
                            p-2 rounded-md text-xs
                          "
                          onClick={() => _onSelectEvent(item)}
                        >
                          Xem
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex p-12-16 footer rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
          hover:bg-slate-300
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowMoreSchedule;
