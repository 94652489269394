import React, { useEffect, useState } from "react";
import {
  NGUOI_GIAO_SEARCH_LIST_TASK,
  CONFIG_IS_FORCE,
} from "../../../apis/_index";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";
import moment from "moment";

const ChooseTaskInsert = (props: any) => {
  const handleClose = props.handleClose;
  const _handleSubmitChooseTaskToBeInsert =
    props._handleSubmitChooseTaskToBeInsert;

  // redux
  // const data = useSelector((state: any) => state.workingReducer);
  // const dispatch = useDispatch();
  const [_listrender, set_listrender] = useState<any | null>(null);
  const [countTask, setCountTask] = useState<number>(0);
  const [taskToBeInsertID, setTaskToBeInsertID] = useState<any>(
    props?.taskToBeInsertID
  );
  const [taskToBeInsertName, setTaskToBeInsertName] = useState<any>(null);
  const [bienDoTreHan, setbienDoTreHan] = useState<any>([]);
  const [completedEarly, setCompletedEarly] = useState<any>(null);

  const _renderStatus = (item: any) => {
    const currentTime = new Date().getTime();
    const deadlineTime = new Date(item?.DeadLine).getTime();
    const newDeadline = new Date(item?.NewDeadline).getTime();
    const assignmentDate = new Date(item?.AssignmentDate).getTime();
    const finishedDate = new Date(item?.FinishedDate).getTime();
    const isInProgres =
      assignmentDate <= currentTime && currentTime <= deadlineTime;
    const isWithinExtraTime = currentTime <= newDeadline;
    const isWithinExtraTime_ =
      deadlineTime <= finishedDate && finishedDate <= newDeadline;

    if (item?.TaskStatus === 4) {
      if (!item?.IsCompleted_History) {
        if (isInProgres) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      } else {
        if (assignmentDate <= finishedDate && finishedDate <= deadlineTime) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime_) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      }
    } else if (item?.TaskStatus === 5) {
      const timeEarlyCompleted = new Date(
        deadlineTime - ((deadlineTime - assignmentDate) / 100) * completedEarly
      ).getTime();
      if (timeEarlyCompleted >= finishedDate && finishedDate <= deadlineTime) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Sớm hạn
          </p>
        );
      } else if (
        timeEarlyCompleted < finishedDate &&
        finishedDate <= deadlineTime
      ) {
        return (
          <p className="bg-pink-700 text-white w-max p-1 rounded-md text-xs font-semibold">
            Đúng hạn
          </p>
        );
      } else if (isWithinExtraTime_) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    } else if (item?.TaskStatus === 2 || item?.TaskStatus === 3) {
      if (isInProgres) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong tiến độ
          </p>
        );
      } else if (isWithinExtraTime) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    }
  };

  const takeData = async () => {
    const currentdate = new Date();
    const getCurrentMonth = currentdate.getMonth() + 1;
    let MonthTo = currentdate.getMonth() + 2;
    const getYearFrom = currentdate.getFullYear();
    let getYearTo = currentdate.getFullYear();
    if (getCurrentMonth == 12) {
      getYearTo += 1;
      MonthTo = 1;
    }
    let body: any = {
      Page: 0,
      Limit: 0,
      DateAdd_From:
        getYearFrom.toString() +
        (getCurrentMonth < 10
          ? "-0" + getCurrentMonth.toString()
          : "-" + getCurrentMonth.toString()) +
        "-01",
      DateAdd_To:
        getYearTo.toString() +
        (MonthTo < 10 ? "-0" + MonthTo.toString() : "-" + MonthTo.toString()) +
        "-01",
    };
    try {
      await ServiceUtils.postV2(
        NGUOI_GIAO_SEARCH_LIST_TASK + props.UserId,
        body,
        {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        }
      ).then((res: any) => {
        const data = res?.data?.Data;
        set_listrender(data?.data);
        setCountTask(data?.count);
      });
    } catch (error: any) {}
  };

  const takeBienDoTreHan = async () => {
    try {
      await ServiceUtils.getV2(CONFIG_IS_FORCE, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          setbienDoTreHan(res?.data?.Data[0]?.BienDoTreHan);
          setCompletedEarly(res?.data?.Data[0]?.CompletedEarly);
        } else {
          toast.error(res.data.Message);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };
  useEffect(() => {
    takeBienDoTreHan();
    takeData();
  }, []);

  const _onChooseTask = (item: any) => {
    setTaskToBeInsertID(item?.ID);
    setTaskToBeInsertName(item?.TaskName);
  };

  return (
    <div
      className="
        lg:w-[90rem] w-screen  h-[calc(100vh-5rem)] bg-white rounded-md
        flex flex-col
      "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chọn công việc tạm dừng</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div
        className="
          flex flex-col gap-5 p-4 w-full h-[calc(100%-7rem)]
          text-sm
        "
      >
        <div className="flex w-full ">
          <p>
            Tổng: <b>{countTask} việc</b>
          </p>
        </div>

        <div className="tableFixHead w-full h-[calc(100%-7rem)] shadow-md border-t-2 styled-scrollbars">
          <table className="">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                {/* <th className="hidden lg:table-cell border border-slate-300 ">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Chọn</p>
                  </div>
                </th> */}
                <th className="hidden lg:table-cell border border-slate-300 ">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>STT</p>
                  </div>
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Loại</p>
                  </div>
                </th>
                <th className="border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Tên công việc</p>
                  </div>
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Mức độ</p>
                  </div>
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Ngày giao</p>
                  </div>
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Người giao</p>
                  </div>
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Người nhận</p>
                  </div>
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  <div className="w-full h-full flex space-x-1 justify-center items-center">
                    <p>Thời hạn</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-7rem)] bg-white overflow-auto">
              {_listrender === null ? (
                <tr></tr>
              ) : (
                _listrender?.map((item: any, index: any) => (
                  <tr
                    className={`w-max h-[3rem]  cursor-pointer ${
                      item?.ID === taskToBeInsertID
                        ? "bg-green-300"
                        : "bg-white"
                    }`}
                    onClick={() => {
                      _onChooseTask(item);
                    }}
                  >
                    {/* <td className="hidden lg:table-cell text-center border border-slate-300 w-max">
                      <div className="w-max h-max flex">
                        <input
                          key={index}
                          type="checkbox"
                          className="w-5 h-5"
                          value={item.Id}
                          checked={item?.ID == taskToBeInsertID ? true : false}
                          onChange={() => {
                            _onChooseTask(item);
                          }}
                        />
                      </div>
                    </td> */}
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      {index + 1}
                    </td>
                    <td className="hidden lg:table-cell border border-slate-300">
                      {item?.IsRecipient ? (
                        <p>Thực hiện chính</p>
                      ) : (
                        <p>Hỗ trợ</p>
                      )}
                    </td>
                    <td className="border border-slate-300 space-y-0.5">
                      <p className="lg:hidden font-bold">
                        <span className="lg:hidden">{index + 1}. </span>
                        {item?.TaskName}
                      </p>
                      <p className="hidden lg:flex">{item?.TaskName}</p>
                      <p className="lg:hidden">
                        <span className="text-xs"> Người giao: </span>
                        <span className="font-semibold">
                          {item?.TaskOwnerName}
                        </span>
                      </p>
                      <p className="lg:hidden">
                        <span className="text-xs">Người nhận: </span>
                        <span className="font-semibold">
                          {item?.RecipientName}
                        </span>
                      </p>
                      {item?.SupporterName !== null && (
                        <p className="lg:hidden">
                          <span className="text-xs">Người hỗ trợ:&nbsp;</span>
                          <span className="text-slate-600">
                            {item?.SupporterName}
                          </span>
                        </p>
                      )}
                      <div className="lg:hidden w-full h-max flex flex-col justify-center">
                        <p>
                          <span className="text-xs">
                            Số giờ thực hiện:{" "}
                            <span className="italic">
                              {item?.HoursUsed} giờ {item?.MinutesUsed} phút
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="lg:hidden w-full h-max flex flex-col justify-center">
                        <p>
                          <span className="text-xs">
                            Số giờ còn lại:{" "}
                            <span className="italic">
                              {item?.Hours_in_month_remaining} giờ
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="lg:hidden w-full h-max flex flex-col justify-center">
                        <p>
                          <span className="text-xs">Thời hạn: </span>
                          <span className="italic">
                            {moment(item?.AssignmentDate).format("HH:mm")}
                            &nbsp;
                            {moment(item?.AssignmentDate).format("DD/MM")}
                          </span>
                          &nbsp;-&nbsp;
                          <span className="italic">
                            {moment(item?.DeadLine).format("HH:mm")}&nbsp;
                            {moment(item?.DeadLine).format("DD/MM")}
                          </span>
                        </p>
                        <p
                          style={{ color: item?.PriorityLevelColor }}
                          className={`font-bold`}
                        >
                          {item?.PriorityLevelName}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="work-status flex gap-1 flex-wrap">
                          {item?.IsTaskMySelf ? (
                            <p className="bg-orange-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                              Việc tự tạo
                            </p>
                          ) : null}
                          {item?.TaskStatus === 3 ? (
                            <p className="bg-sky-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                              Chưa xử lý
                            </p>
                          ) : null}
                          {item?.TaskStatus === 4 ? (
                            <>
                              {!item?.IsCompleted_History ? (
                                <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Đang xử lý
                                </p>
                              ) : (
                                <p className="bg-orange-300 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Đã báo cáo chờ duyệt
                                </p>
                              )}
                            </>
                          ) : null}
                          {item?.TaskStatus === 5 ? (
                            <p className="bg-teal-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                              Hoàn tất
                            </p>
                          ) : null}
                          {<>{_renderStatus(item)}</>}
                          {item?.NumberReturnWork > 0 && (
                            <p className="text-red-500 text-xs lg:text-sm font-semibold">
                              Bị trả {item?.NumberReturnWork} lần
                            </p>
                          )}
                        </div>
                        <div className="lg:hidden ml-3 flex justify-end items-center">
                          <div className="dropdown2">
                            {/* button options */}

                            {/* options fields */}
                            <div
                              id={index.toString()}
                              className="dropdown-content2 text-xs font-semibold"
                            >
                              {/* detail */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <p
                        style={{ color: item?.PriorityLevelColor }}
                        className={`font-bold`}
                      >
                        {item?.PriorityLevelName}
                      </p>
                    </td>
                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <div className="w-full h-max flex flex-col justify-center items-center">
                        <p>{moment(item?.DateAdd).format("DD/MM/YYYY")}</p>
                        <p>{moment(item?.DateAdd).format("HH:mm:ss")}</p>
                      </div>
                    </td>
                    <td className="hidden lg:table-cell text-left border border-slate-300">
                      {item?.TaskOwnerName}
                    </td>
                    <td className="hidden lg:table-cell text-left border border-slate-300">
                      {item?.RecipientName}
                      {item?.SupporterName && (
                        <p className="text-xs">
                          <span className="text-slate-600">Hỗ trợ:</span>{" "}
                          {item?.SupporterName}
                        </p>
                      )}
                    </td>

                    <td className="hidden lg:table-cell text-center border border-slate-300">
                      <div className="w-full h-max flex flex-col justify-center items-center">
                        <p>{moment(item?.DeadLine).format("DD/MM/YYYY")}</p>
                        <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
          flex items-center justify-end w-full p-12-16 footer rounded-b-md space-x-4 text-sm
        "
      >
        <button
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-400"
          onClick={() => {
            handleClose();
            _handleSubmitChooseTaskToBeInsert(
              taskToBeInsertID,
              taskToBeInsertName
            );
          }}
        >
          Xác nhận
        </button>
        {/* <button
          className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
          onClick={() => {
            resetFilter();
            handleClose();
          }}
        >
          Đóng
        </button> */}
      </div>
    </div>
  );
};

export default ChooseTaskInsert;
