import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import {
  PROPOSESENDLIST,
  PROPOSE_TYPE_LIST,
  PRIOR_LIST_DROPDOWN,
  USER_READ_ALL_DROPDOWN,
  LOAD_SEP,
} from "../../../apis/_index";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import MobileDeliverPropose from "./mobileDeliverPropose";
import FilterProposeWeb from "./filterProposeWeb";
import FilterProposeMobile from "./filterProposeMobile";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import Pagination from "@mui/material/Pagination";

import { ModalController } from "../../homeUI/_index";
import LoaderSpinner from "../../../components/loader-spinner/_index";
import { current } from "@reduxjs/toolkit";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
import HandleUI from "../../../utils/handleUI";
import {
  ONCHANGE_CURRENT_PAGE,
  ONCHANGE_ITEM_PER_PAGE,
  ONLOAD_DATA_LIST,
  ONLOAD_PRIOR_LIST,
  ONLOAD_RECEIVER_LIST,
  ONLOAD_SENDER_LIST,
  ONLOAD_STATUS_LIST,
  ONLOAD_TOTAL_ITEM,
  ONLOAD_TYPE_LIST,
} from "../../../redux/reducers/proposeReducer/_index";

const ProposeDeliverListUI = () => {
  _onClickOther();

  const shouldLog = useRef(true);
  const dispatch = useDispatch();
  const proposeData = useSelector((state: any) => state.proposeReducer);
  const [openSub, setOpenSub] = useState(false);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const context = useContext(ModalController);
  const [isLoading, setIsLoading] = useState(true);

  const statusList = [
    { Id: "0", value: "Chưa duyệt", label: "Chưa duyệt" },
    { Id: "1", value: "Đã duyệt", label: "Đã duyệt" },
    { Id: "2", value: "Không duyệt", label: "Không duyệt" },
  ];

  // load all data
  const shouldLogLoadData = useRef(true);

  useEffect(() => {
    if (shouldLogLoadData.current === true) {
      shouldLogLoadData.current = false;

      const takeStatusList = async () => {
        setIsLoading(true);
        try {
          dispatch(ONLOAD_STATUS_LIST(statusList));
          setIsLoading(false);
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeProposeTypeList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(PROPOSE_TYPE_LIST, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_TYPE_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takePriorList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_PRIOR_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeUserList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_SENDER_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeBossList = async () => {
        setIsLoading(true);
        try {
          await ServiceUtils.getV2(LOAD_SEP, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setIsLoading(false);
            if (res.data.StatusCode === 200) {
              dispatch(ONLOAD_RECEIVER_LIST(res.data.Data));
            }
          });
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeStatusList();
      takeProposeTypeList();
      takePriorList();
      takeUserList();
      takeBossList();
    }
  }, []);

  // onClick detail
  const _onClickDetail = (id: string) => {
    context.setFuncs("proposeDetail");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _loadData = () => {
    let body: any = {
      Page: proposeData.pagination.currentPage,
      Limit: proposeData.pagination.itemPerPage,
      Name: proposeData.searchFilter.name,
      Status: proposeData.searchFilter.selectedStatus,
      PriorityLevelID: proposeData.searchFilter.selectedPrior,
      UserAdd: proposeData.searchFilter.selectedSender,
      UserCheck: proposeData.searchFilter.selectedReceiver,
      DateAdd_From: proposeData.searchFilter.fromDate,
      DateAdd_To: proposeData.searchFilter.toDate,
      ProposeTypeID: proposeData.searchFilter.selectedType,
    };

    if (proposeData.searchFilter.selectedPrior === "") {
      delete body.PriorityLevelID;
    }
    if (proposeData.searchFilter.name === "") {
      delete body.Name;
    }
    if (proposeData.searchFilter.selectedStatus === "") {
      delete body.Status;
    }
    if (proposeData.searchFilter.selectedSender === "") {
      delete body.UserAdd;
    }
    if (proposeData.searchFilter.selectedReceiver === "") {
      delete body.UserCheck;
    }
    if (proposeData.searchFilter.selectedType === "") {
      delete body.ProposeTypeID;
    }
    if (proposeData.searchFilter.fromDate === "") {
      delete body.DateAdd_From;
    }
    if (proposeData.searchFilter.toDate === "") {
      delete body.DateAdd_To;
    }

    const takeData = async () => {
      try {
        await ServiceUtils.postV2(PROPOSESENDLIST, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (((res || {}).data || {}).StatusCode === 200) {
            dispatch(
              ONLOAD_DATA_LIST((((res || {}).data || {}).Data || {}).data || [])
            );
            dispatch(
              ONLOAD_TOTAL_ITEM((((res || {}).data || {}).Data || {}).count)
            );
          } else {
            toast.error(res.data.Message || "Tải dữ liệu thất bại.");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };
    takeData();
  };

  const _handleCheckedColor = (status: any) => {
    if (status === 2) {
      return "#ff0000";
    } else if (status === 1) {
      return "#00ff00";
    } else {
      return "#FFAA00";
    }
  };

  const _handleCheckedStatus = (status: any) => {
    if (status === 2) {
      return "Không duyệt";
    } else if (status === 1) {
      return "Đã duyệt";
    } else {
      return "Chưa duyệt";
    }
  };

  useEffect(() => {
    _loadData();
  }, [proposeData.pagination.currentPage, proposeData.pagination.itemPerPage]);

  // onChange page
  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_CURRENT_PAGE(value - 1));
  };
  const _onChangeItemPerPage = (e: any) => {
    dispatch(ONCHANGE_ITEM_PER_PAGE(e.target.value));
  };

  return (
    <>
      {isLoading ? (
        <LoadingControl />
      ) : (
        <div className="flex flex-col text-sm space-y-3 w-full h-[calc(100%-7rem)] lg:mt-0 pt-5 px-5">
          {/* filter */}
          <div className="hidden lg:flex w-full h-max space-y-3">
            <FilterProposeWeb _loadData={_loadData} />
          </div>

          {/* table main */}
          <div className="lg:flex flex-col space-y-3 hidden">
            {/* page selection */}
            <div className="flex w-max h-max">
              <p>Xem</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={proposeData.pagination.itemPerPage}
                onChange={(e: any) => _onChangeItemPerPage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>

            {/* table */}
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <th className="border border-slate-300 ">STT</th>
                <th className="border border-slate-300 ">Trạng thái</th>
                <th className="border border-slate-300 ">Tên đề xuất</th>
                <th className="border border-slate-300 ">Nội dung</th>
                <th className="border border-slate-300 ">Thời gian đề xuất</th>
                <th className="border border-slate-300 ">Ngày gửi</th>
                <th className="border border-slate-300 ">Người nhận</th>
                <th className="border border-slate-300 "></th>
              </thead>
              <tbody
                className="w-full h-[calc(100%-2rem)] bg-white overflow-auto"
                id="table"
              >
                {proposeData.data === null ? (
                  <tr></tr>
                ) : (
                  proposeData.data?.map((item: any, index: any) => {
                    return (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="stt text-center border border-slate-300">
                          {index +
                            1 +
                            proposeData.pagination.currentPage *
                              proposeData.pagination.itemPerPage}
                        </td>

                        <td className="trang-thai border border-slate-300 text-white">
                          <div
                            style={{
                              backgroundColor: _handleCheckedColor(item.Status),
                              color: "white",
                              padding: 5,
                              textAlign: "center",
                              borderRadius: 13,
                              width: "auto",
                            }}
                          >
                            {_handleCheckedStatus(item.Status)}
                          </div>
                        </td>

                        <td className="ten text-center border border-slate-300">
                          {item.Name}
                        </td>
                        <td className="noi-dung text-center border border-slate-300">
                          {item.Content}
                        </td>
                        {item.IsUseTime === true ? (
                          <td className="thoi-han text-center border border-slate-300">
                            {moment(item.StartTime).format("DD/MM/YYYY")}
                            &nbsp;-&nbsp;
                            {moment(item.EndTime).format("DD/MM/YYYY")}
                          </td>
                        ) : (
                          <td className="text-center border border-slate-300">
                            Không thời hạn
                          </td>
                        )}
                        <td className="ngay-gui text-center border border-slate-300">
                          {moment(item.DateAdd).format("DD/MM/YYYY")}
                          <br />
                          {moment(item.DateAdd).format("hh:mm")}
                        </td>
                        <td className="nguoi-gui text-center border border-slate-300">
                          {item.UserCheckName}
                        </td>
                        <td className="nguoi-nhan text-center border border-slate-300">
                          <div className="justify-center items-center w-full h-full lg:flex hidden">
                            <div className="dropdown">
                              <button
                                className="dropbtn"
                                onMouseEnter={() => {
                                  HandleUI.hoverMenuContextScrollTable(
                                    index,
                                    proposeData.data?.length
                                  );
                                }}
                              >
                                <MoreHorizIcon fontSize="small" />
                              </button>
                              <div className="dropdown-content text-xs font-semibold">
                                <button
                                  className="flex w-full h-full hover:text-sky-500 p-2"
                                  onClick={() => _onClickDetail(item.ID)}
                                >
                                  <div className="flex w-full h-full space-x-1">
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {/* pagination */}
            <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
              <Pagination
                count={proposeData.pagination.totalPage}
                variant="outlined"
                color="primary"
                size="small"
                page={proposeData.pagination.currentPage + 1}
                onChange={_onChangePaginate}
              />
            </div>
          </div>

          <div className="lg:hidden">
            {/* {data?.length !== 0 ? ( */}
            <>
              <FilterProposeMobile _loadData={_loadData} />
              <MobileDeliverPropose _loadData={_loadData} />
            </>
            {/* ) : null} */}
          </div>
        </div>
      )}
    </>
  );
};
export default ProposeDeliverListUI;
