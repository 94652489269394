import {
    ALL_BANGDIEM,
    ONE_BANGDIEM,
} from "../apis/_index";
import ServiceUtils from "../utils/services";

export const bangdiemnangluc_list = () => {
    return new Promise(async (resolve) => {
        return await ServiceUtils.getV2(ALL_BANGDIEM)
            .then((res: any) => {
                resolve({
                    status: true,
                    data: res.data.Data,
                });
            })
            .catch((err: any) => {
                resolve({
                    status: false,
                    err: err,
                });
            });
    });
};
