import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../homeUI/_index";
import { onTouchForm } from "../../../funcs/onTochForm";

import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ModalBase from "../../../components/modal/_index";
import Select from "react-select";
import DateTimePickerControl from "../../../controls/datetimePicker/datetimePickerControl";
import { ONCHANGE_FILTER } from "../../../redux/reducers/proposeReducer/_index";

const FilterProposeMobile = (props: any) => {
  const urlLink = window.location.pathname;
  const _loadData = props._loadData;

  const [openSub, setOpenSub] = useState(false);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  // redux
  const dispatch = useDispatch();
  const proposeData = useSelector((state: any) => state.proposeReducer);

  const context = useContext(ModalController);

  const _onClickCreatePropose = () => {
    context.handleOpen();
    context.setFuncs("createPropose");
  };

  const onOpenSub = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // take props
  const statusList = proposeData.statusList;

  const proposeTypeList = proposeData.typeList
    ? proposeData.typeList.map((item: any) => ({
        Id: item.ID,
        value: item.Name,
        label: item.Name,
      }))
    : [];

  const priorList = proposeData.priorList
    ? proposeData.priorList.map((item: any) => ({
        Id: item.Id,
        value: item.PriorityLevelName,
        label: item.PriorityLevelName,
      }))
    : [];

  const senderList = proposeData.senderList
    ? proposeData.senderList.map((item: any) => ({
        Id: item.Id,
        value: item.FullName,
        label: item.FullName,
      }))
    : [];

  const receiverList = proposeData.receiverList
    ? proposeData.receiverList.map((item: any) => ({
        Id: item.Id,
        value: item.FullName,
        label: item.FullName,
      }))
    : [];

  function handleSelectStatus(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "status", data: data ? data.Id : "" }));
  }

  function handleSelectProposeType(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "type", data: data ? data.Id : "" }));
  }

  function handleSelectPrior(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "prior", data: data ? data.Id : "" }));
  }

  function handleSelectReceiver(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "receiver", data: data ? data.Id : "" }));
  }

  function handleSelectSender(data: any) {
    dispatch(ONCHANGE_FILTER({ type: "sender", data: data ? data.Id : "" }));
  }

  const _onClickOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  return (
    <div className="w-full h-max pb-3">
      <ModalBase
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        _loadData={_loadData}
      />
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center p-2 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickOpenSearch}
        >
          <SearchIcon fontSize="small" />
        </button>

        <button
          className="
            flex items-center p-2 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
        </button>
        {urlLink !== "/propose/receiver/list" ? (
          <button
            className="flex h-[1.9rem] p-2 bg-green-500 rounded-md text-white hover:bg-green-400 items-center "
            onClick={() => onOpenSub("createPropose")}
          >
            <AddIcon fontSize="small" />
          </button>
        ) : null}
      </div>
      {openSearch ? (
        <div className="flex flex-col py-3">
          <div className="ten-de-xuat flex flex-col">
            <label>Tên đề xuất</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Nhập tên đề xuất"
              value={proposeData.searchFilter.name}
              onChange={(e) => {
                dispatch(
                  ONCHANGE_FILTER({ type: "name", data: e.target.value })
                );
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          <div className="ten-de-xuat flex flex-col py-1">
            <label>Trạng thái</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={statusList}
              onChange={handleSelectStatus}
              isClearable
              placeholder="Chọn trạng thái"
              value={statusList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedStatus
              )}
              components={{
                DropdownIndicator: () => null,
              }}
            />
          </div>
          <div className="loại flex flex-col py-1">
            <label>Loại đề xuất</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={proposeTypeList}
              value={proposeTypeList?.find(
                (item: any) => item.Id === proposeData.searchFilter.selectedType
              )}
              onChange={handleSelectProposeType}
              isClearable
              placeholder="Chọn loại đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="muc-do flex flex-col py-1">
            <label>Mức độ</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={priorList}
              value={priorList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedPrior
              )}
              onChange={handleSelectPrior}
              isClearable
              placeholder="Chọn mức độ"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="nguoi-gui flex flex-col py-1">
            <label>Người đề xuất</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={senderList}
              value={senderList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedSender
              )}
              onChange={handleSelectSender}
              isClearable
              placeholder="Chọn người đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="nguoi-nhan flex flex-col py-1">
            <label>Người nhận</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={receiverList}
              value={receiverList?.find(
                (item: any) =>
                  item.Id === proposeData.searchFilter.selectedReceiver
              )}
              onChange={handleSelectReceiver}
              isClearable
              isSearchable
              placeholder="Chọn người nhận"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="ngay-gui-from flex flex-col py-1">
            <DateTimePickerControl
              className="core-datetime-picker-resize"
              label={"Ngày gửi (Từ ngày)"}
              value={proposeData.searchFilter.fromDate}
              onChange={(e: any) => {
                const value = e;
                dispatch(ONCHANGE_FILTER({ type: "fromDate", data: value }));
              }}
            />
            {/* <label>Ngày gửi (Từ ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Từ ngày)"
              type="datetime-local"
              value={proposeData.searchFilter.fromDate}
              onChange={(e: any) => {
                const value = e;
                dispatch(ONCHANGE_FILTER({ type: "fromDate", data: value }));
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            /> */}
          </div>
          <div className="ngay-gui-to flex flex-col py-1">
            <DateTimePickerControl
              className="core-datetime-picker-resize"
              label={"Ngày gửi (Đến ngày)"}
              value={proposeData.searchFilter.toDate}
              onChange={(e: any) => {
                const value = e;
                dispatch(ONCHANGE_FILTER({ type: "toDate", data: value }));
              }}
            />
          </div>
          <div className="py-1">
            <button
              className="
            flex items-center w-full justify-center p-2 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
              onClick={_loadData}
            >
              <p>Tìm kiếm</p>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterProposeMobile;
