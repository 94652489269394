import React from "react";

import ContentCore from "../../../core/workingContent";

const VotingUI = () => {
  return (
    <ContentCore />
  )
}

export default VotingUI