import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONLOAD_TASKMYSELF_NGUOINHAN,
  ONCHANGE_RESET_FILTER,
} from "../../../redux/reducers/workingReducer/_index";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";

import { Checkcolor } from "../../../funcs/checkColor";
import { MODAL_KEYS } from "../../../constants/constants";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { NGUOI_NHAN_VIEC_TASK_MY_SELF } from "../../../apis/_index";
import LoadingControl from "../../../controls/loading/loadingControl";
import HandleUI from "../../../utils/handleUI";
import HandleDate from "../../../utils/date/handleDate";
import StepList from "../popup/StepList";
const ItemTSX = ({
  item,
  index,
  workingData,
  appData,
  _onClickDetails,
}: any) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(false);
  }, [workingData.pageCurrent]);

  const _openDropDown = () => {
    setOpen(true);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <tr key={index} className="w-max h-[3rem] bg-white">
        <td className="hidden lg:table-cell text-center border border-slate-300">
          {index + 1 + workingData?.pageCurrent * workingData?.choosePage}
        </td>
        <td className="hidden lg:table-cell border border-slate-300">
          <p>{item.TaskCode}</p>
        </td>
        <td className="hidden lg:table-cell border border-slate-300">
          {/* {item.IsRecipient ? (
                          <p>Thực hiện chính</p>
                        ) : (
                          <p>Hỗ trợ</p>
                        )} */}
          {/* <input
                          type="checkbox"
                          checked = {item?.IsAskingBoss ? true : false}
                          disabled
                        /> */}
          {item?.BossAsking ? (
            <p>
              <b>{item?.BossAsking}</b>
            </p>
          ) : (
            <p>Không</p>
          )}
        </td>
        <td className="border border-slate-300 space-y-0.5">
          <div className="lg:hidden flex justify-between gap-2 relative ">
            <p className="font-bold">
              <span className="lg:hidden">
                {index + 1 + workingData.pageCurrent * workingData.choosePage}
                .&nbsp;
              </span>
              {item.TaskName}
            </p>
            <button onClick={_openDropDown} ref={buttonRef}>
              <ArrowDropDownIcon />
              {open && <StepList id={item.ID} setOpen={setOpen} />}
            </button>
          </div>
          <div className="hidden lg:flex justify-between gap-2 relative ">
            <p>{item.TaskName}</p>
            <button onClick={_openDropDown} ref={buttonRef}>
              <ArrowDropDownIcon />
              {open && <StepList id={item.ID} />}
            </button>
          </div>

          <p className="lg:hidden">
            <span className="text-xs">Người nhận: </span>
            <span className="font-semibold">{item.RecipientName}</span>
          </p>
          {item.SupporterName !== null && (
            <p className="lg:hidden">
              <span className="text-xs">Người hỗ trợ:&nbsp;</span>
              <span className="text-slate-600">{item.SupporterName}</span>
            </p>
          )}
          <div className="lg:hidden w-full h-max flex flex-col justify-center">
            <p>
              <span className="text-xs">
                Số giờ thực hiện:{" "}
                <span className="italic">
                  {item.HoursUsed} giờ {item.MinutesUsed} phút
                </span>
              </span>
            </p>
          </div>
          <div className="lg:hidden w-full h-max flex flex-col justify-center">
            <p>
              <span className="text-xs">
                Số giờ còn lại:{" "}
                <span className="italic">
                  {item.Hours_in_month_remaining} giờ
                </span>
              </span>
            </p>
          </div>
          <div className="lg:hidden w-full h-max flex flex-col justify-center">
            <p>
              <span className="text-xs">Thời hạn: </span>
              <span className="italic">
                {moment(item.AssignmentDate).format("HH:mm")}
                &nbsp;
                {moment(item.AssignmentDate).format("DD/MM")}
              </span>
              &nbsp;-&nbsp;
              <span className="italic">
                {moment(item.DeadLine).format("HH:mm")}&nbsp;
                {moment(item.DeadLine).format("DD/MM")}
              </span>
            </p>
            <p
              style={{
                color: Checkcolor(item.PriorityLevelID, appData),
              }}
              className={`font-bold lg:hidden ${Checkcolor(
                item.PriorityLevelID,
                appData
              )}`}
            >
              {item.PriorityLevelName}
            </p>
          </div>
          <div className="lg:hidden flex justify-end items-center">
            <div className="dropdown2">
              {/* button options */}
              <button
                className="dropbtn2"
                onClick={() => _onClickFuncs(index.toString())}
              >
                ...
              </button>

              {/* options fields */}
              <div
                id={index.toString()}
                className="dropdown-content2 text-xs font-semibold"
              >
                {/* detail */}
                <button
                  className="flex w-full h-full"
                  onClick={() => _onClickDetails(item.ID)}
                >
                  {/* detail - contain */}
                  <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                    {/* icon */}
                    <DescriptionTwoToneIcon
                      className="text-sky-600"
                      sx={{ fontSize: 18 }}
                      fontSize="small"
                    />

                    {/* title */}
                    <div>Chi tiết</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </td>
        <td className="hidden lg:table-cell text-center border border-slate-300">
          <p
            style={{
              color: Checkcolor(item.PriorityLevelID, appData),
            }}
            className={`font-bold ${Checkcolor(item.PriorityLevelID, appData)}`}
          >
            {item.PriorityLevelName}
          </p>
        </td>
        <td className="hidden lg:table-cell text-center border border-slate-300">
          <div className="w-full h-max flex flex-col justify-center items-center">
            <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
            <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
          </div>
        </td>
        {/* <td className="hidden lg:table-cell text-left border border-slate-300">
                        {item.TaskOwnerName}
                      </td> */}
        <td className="hidden lg:table-cell border border-slate-300">
          {item.RecipientName}
          {item.SupporterName && (
            <p className="text-xs">
              <span className="text-slate-600">Hỗ trợ:</span>{" "}
              {item.SupporterName}
            </p>
          )}
        </td>
        <td className="hidden lg:table-cell text-center border border-slate-300">
          <div className="w-full h-max flex flex-col justify-center items-center">
            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
          </div>
        </td>
        <td className="hidden lg:table-cell text-center border border-slate-300">
          <div className="dropdown">
            {/* button options */}
            <button
              className="dropbtn"
              onMouseEnter={() => {
                HandleUI.hoverMenuContextScrollTable(
                  index,
                  workingData?.taskmyself_nguoinhan.data.length
                );
              }}
            >
              <MoreHorizIcon fontSize="small" />
            </button>

            {/* options fields */}
            <div className="dropdown-content text-xs font-semibold">
              {/* detail */}
              <button
                className="flex w-full h-full"
                onClick={() => _onClickDetails(item.ID)}
              >
                {/* detail - contain */}
                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                  {/* icon */}
                  <DescriptionTwoToneIcon
                    className="text-sky-600"
                    sx={{ fontSize: 18 }}
                    fontSize="small"
                  />

                  {/* title */}
                  <div>Chi tiết</div>
                </div>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
const WorkingTaskMyself = () => {
  _onClickOther();

  // redux
  const workingData = useSelector((state: any) => state.workingReducer);

  const appData = useSelector((state: any) => state.appReducer);

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const link = window.location.pathname;

  const shouldLog = useRef(true);

  const [searchMonth, setSearchMonth] = useState(
    workingData?.searchFilter?.searchMonth
      ? workingData?.searchFilter?.searchMonth
      : HandleDate.currentMonth(link)
  );
  const [searchYear, setSearchSearchYear] = useState(
    workingData?.searchFilter?.searchYear
      ? workingData?.searchFilter?.searchYear
      : HandleDate.currentYear(link)
  );

  const _fetchData = (page: any, limit: any) => {
    // console.log(
    //   "workingData?.searchFilter?.searchMonth, ",
    //   workingData?.searchFilter?.searchMonth
    // );
    readList(
      NGUOI_NHAN_VIEC_TASK_MY_SELF,
      page,
      limit,
      workingData?.searchFilter?.taskName,
      workingData?.searchFilter?.selectedPrior,
      null,
      workingData?.searchFilter?.fromDate,
      workingData?.searchFilter?.toDate,
      workingData?.searchFilter?.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKMYSELF_NGUOINHAN(res.data.Data));
      },
      null,
      null,
      null,
      null,
      searchMonth,
      searchYear
    );
  };

  const _onClickDetails = (id: any) => {
    context.setFuncs("taskDetaiMyself");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = e.target.value;
    dispatch(ONCHANGE_CHOOSEPAGE(value));
    dispatch(ONCHANGE_PAGINATION_TASKLIST(1));
  };

  // const [first, setFirst] = useState(false);
  useEffect(() => {
    // if (first) {
    _fetchData(workingData?.pageCurrent, workingData?.choosePage);
    // } else {
    //   setFirst(true);
    // }
  }, [workingData?.choosePage, workingData?.pageCurrent]);

  var permiss: any = localStorage.getItem("menuList");
  permiss = permiss.replace(/'/g, '"'); //replacing all ' with "
  permiss = JSON.parse(permiss);

  let checkPermiss = permiss.find((x: any) => x.FormId == 27);

  return (
    <>
      {workingData?.taskmyself_nguoinhan === null ? (
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData?.taskmyself_nguoinhan.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData?.taskmyself_nguoinhan.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData?.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div
            className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md"
            id="table"
          >
            <table className="">
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="hidden lg:flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mã công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskCode" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Việc có xin ý kiến</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "isRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người giao</p> */}
                  {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                  {/* </div>
                  </th> */}
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData?.taskmyself_nguoinhan === null ? (
                  <tr></tr>
                ) : (
                  workingData?.taskmyself_nguoinhan.data?.map(
                    (item: any, index: any) => (
                      <>
                        <ItemTSX
                          item={item}
                          index={index}
                          workingData={workingData}
                          appData={appData}
                          _onClickDetails={_onClickDetails}
                        />
                      </>
                      // <tr className="w-max h-[3rem] bg-white">
                      //   <td className="hidden lg:table-cell text-center border border-slate-300">
                      //     {index +
                      //       1 +
                      //       workingData?.pageCurrent * workingData?.choosePage}
                      //   </td>
                      //   <td className="hidden lg:table-cell border border-slate-300">
                      //     <p>{item.TaskCode}</p>
                      //   </td>
                      //   <td className="hidden lg:table-cell border border-slate-300">
                      //     {/* {item.IsRecipient ? (
                      //     <p>Thực hiện chính</p>
                      //   ) : (
                      //     <p>Hỗ trợ</p>
                      //   )} */}
                      //     {/* <input
                      //     type="checkbox"
                      //     checked = {item?.IsAskingBoss ? true : false}
                      //     disabled
                      //   /> */}
                      //     {item?.BossAsking ? (
                      //       <p>
                      //         <b>{item?.BossAsking}</b>
                      //       </p>
                      //     ) : (
                      //       <p>Không</p>
                      //     )}
                      //   </td>
                      //   <td className="border border-slate-300 space-y-0.5">
                      //     <p className="lg:hidden font-bold">
                      //       <span className="lg:hidden">
                      //         {index +
                      //           1 +
                      //           workingData?.pageCurrent *
                      //             workingData?.choosePage}
                      //         .&nbsp;
                      //       </span>
                      //       {item.TaskName}
                      //     </p>
                      //     <p className="hidden lg:flex">{item.TaskName}</p>
                      //     <p className="lg:hidden">
                      //       <span className="text-xs">Người nhận: </span>
                      //       <span className="font-semibold">
                      //         {item.RecipientName}
                      //       </span>
                      //     </p>
                      //     {item.SupporterName !== null && (
                      //       <p className="lg:hidden">
                      //         <span className="text-xs">
                      //           Người hỗ trợ:&nbsp;
                      //         </span>
                      //         <span className="text-slate-600">
                      //           {item.SupporterName}
                      //         </span>
                      //       </p>
                      //     )}
                      //     <div className="lg:hidden w-full h-max flex flex-col justify-center">
                      //       <p>
                      //         <span className="text-xs">
                      //           Số giờ thực hiện:{" "}
                      //           <span className="italic">
                      //             {item.HoursUsed} giờ {item.MinutesUsed} phút
                      //           </span>
                      //         </span>
                      //       </p>
                      //     </div>
                      //     <div className="lg:hidden w-full h-max flex flex-col justify-center">
                      //       <p>
                      //         <span className="text-xs">
                      //           Số giờ còn lại:{" "}
                      //           <span className="italic">
                      //             {item.Hours_in_month_remaining} giờ
                      //           </span>
                      //         </span>
                      //       </p>
                      //     </div>
                      //     <div className="lg:hidden w-full h-max flex flex-col justify-center">
                      //       <p>
                      //         <span className="text-xs">Thời hạn: </span>
                      //         <span className="italic">
                      //           {moment(item.AssignmentDate).format("HH:mm")}
                      //           &nbsp;
                      //           {moment(item.AssignmentDate).format("DD/MM")}
                      //         </span>
                      //         &nbsp;-&nbsp;
                      //         <span className="italic">
                      //           {moment(item.DeadLine).format("HH:mm")}&nbsp;
                      //           {moment(item.DeadLine).format("DD/MM")}
                      //         </span>
                      //       </p>
                      //       <p
                      //         style={{
                      //           color: Checkcolor(
                      //             item.PriorityLevelID,
                      //             appData
                      //           ),
                      //         }}
                      //         className={`font-bold lg:hidden ${Checkcolor(
                      //           item.PriorityLevelID,
                      //           appData
                      //         )}`}
                      //       >
                      //         {item.PriorityLevelName}
                      //       </p>
                      //     </div>
                      //     <div className="lg:hidden flex justify-end items-center">
                      //       <div className="dropdown2">
                      //         {/* button options */}
                      //         <button
                      //           className="dropbtn2"
                      //           onClick={() => _onClickFuncs(index.toString())}
                      //         >
                      //           ...
                      //         </button>

                      //         {/* options fields */}
                      //         <div
                      //           id={index.toString()}
                      //           className="dropdown-content2 text-xs font-semibold"
                      //         >
                      //           {/* detail */}
                      //           <button
                      //             className="flex w-full h-full"
                      //             onClick={() => _onClickDetails(item.ID)}
                      //           >
                      //             {/* detail - contain */}
                      //             <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                      //               {/* icon */}
                      //               <DescriptionTwoToneIcon
                      //                 className="text-sky-600"
                      //                 sx={{ fontSize: 18 }}
                      //                 fontSize="small"
                      //               />

                      //               {/* title */}
                      //               <div>Chi tiết</div>
                      //             </div>
                      //           </button>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </td>
                      //   <td className="hidden lg:table-cell text-center border border-slate-300">
                      //     <p
                      //       style={{
                      //         color: Checkcolor(item.PriorityLevelID, appData),
                      //       }}
                      //       className={`font-bold ${Checkcolor(
                      //         item.PriorityLevelID,
                      //         appData
                      //       )}`}
                      //     >
                      //       {item.PriorityLevelName}
                      //     </p>
                      //   </td>
                      //   <td className="hidden lg:table-cell text-center border border-slate-300">
                      //     <div className="w-full h-max flex flex-col justify-center items-center">
                      //       <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                      //       <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                      //     </div>
                      //   </td>
                      //   {/* <td className="hidden lg:table-cell text-left border border-slate-300">
                      //   {item.TaskOwnerName}
                      // </td> */}
                      //   <td className="hidden lg:table-cell border border-slate-300">
                      //     {item.RecipientName}
                      //     {item.SupporterName && (
                      //       <p className="text-xs">
                      //         <span className="text-slate-600">Hỗ trợ:</span>{" "}
                      //         {item.SupporterName}
                      //       </p>
                      //     )}
                      //   </td>
                      //   <td className="hidden lg:table-cell text-center border border-slate-300">
                      //     <div className="w-full h-max flex flex-col justify-center items-center">
                      //       <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                      //       <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                      //     </div>
                      //   </td>
                      //   <td className="hidden lg:table-cell text-center border border-slate-300">
                      //     <div className="dropdown">
                      //       {/* button options */}
                      //       <button
                      //         className="dropbtn"
                      //         onMouseEnter={() => {
                      //           HandleUI.hoverMenuContextScrollTable(
                      //             index,
                      //             workingData?.taskmyself_nguoinhan.data.length
                      //           );
                      //         }}
                      //       >
                      //         <MoreHorizIcon fontSize="small" />
                      //       </button>

                      //       {/* options fields */}
                      //       <div className="dropdown-content text-xs font-semibold">
                      //         {/* detail */}
                      //         <button
                      //           className="flex w-full h-full"
                      //           onClick={() => _onClickDetails(item.ID)}
                      //         >
                      //           {/* detail - contain */}
                      //           <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                      //             {/* icon */}
                      //             <DescriptionTwoToneIcon
                      //               className="text-sky-600"
                      //               sx={{ fontSize: 18 }}
                      //               fontSize="small"
                      //             />

                      //             {/* title */}
                      //             <div>Chi tiết</div>
                      //           </div>
                      //         </button>
                      //       </div>
                      //     </div>
                      //   </td>
                      // </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData?.paginateTaskList === null
                  ? 0
                  : workingData?.paginateTaskList
              }
              page={workingData?.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingTaskMyself;
