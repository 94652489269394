const reportView3 = `<html lang="en">

<head>
    <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
    <style>
        body {
            width: 100%;
            font-size: 9px;
        }
    </style>
</head>

<body>
    <div class="border-solid">
        
            <div class="mx-[5%] my-[3%]">
                

                
                <div class="section_2">

                    <div id="planOfAction"></div>
                </div>

                
                
            </div>
        </form>

    </div>


</body>

</html>`;

export default reportView3;
