import React, { useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../../ui/homeUI/_index";

import { DELETE_TASK_LIST, READ_ALL_TASK_LIST } from "../../../apis/_index";
import { ONLOAD_TASKLIST } from "../../../redux/reducers/settingReducer/_index";
import { toast } from "react-toastify";
import axios from "axios";
import ServiceUtils from "../../../utils/services";

const DeleteTaskList = (props: any) => {
  // const data = useSelector((state: any) => state.settingReducer);
  const dispatch = useDispatch();

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  const _onClickOk = async () => {
    const API = DELETE_TASK_LIST + taskId.toString();

    try {
      await ServiceUtils.getV2(API, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Xoá thành công.");
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(READ_ALL_TASK_LIST, {
                headers: {},
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_TASKLIST(res.data.Data));
                }
              });
            } catch (error: any) {
              toast.error(
                "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              );

              return;
            }
          };
          takeData();

          context.handleClose();
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div
      className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>
          Bạn có đồng ý <span className="font-semibold">XOÁ</span> đầu việc này
          không?
        </p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className="w-[5rem] h-max p-2 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickOk}
          >
            Có
          </button>
          <button
            className="w-[5rem] h-max p-2 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteTaskList;
