import * as signalR from "@microsoft/signalr";
import { DOMAIN_USED } from "../apis/_index";

export const Start = (props: any) => {
  let user = props.users;
  let tken = props.token;
  const callback = props.callback;

  const audioFile: any = require("../assets/audioFile/notification.mp3");

  let userData: any | null = JSON.stringify(user);
  let infoUser: any;

  if (userData && tken) {
    infoUser = JSON.parse(JSON.parse(userData)); //replacing all ' with "
    let _userID: any = (infoUser || {}).Data || [];
    let userID: any = "";
    if (_userID) {
      userID = (_userID[0] || {}).Id || "";
    }
    if (userID) {
      const connect = new signalR.HubConnectionBuilder()
        .withUrl(DOMAIN_USED + "/SignalrHub?userID=" + userID, {
          skipNegotiation: true,
          transport: 1,
          // accessTokenFactory: () => `${tken}`,
        })
        .withAutomaticReconnect()
        .build();

      if (connect) {
        connect.start().then(() => {
        });

        connect.on("BroadcastMessage", (message: any) => {
          const audio = new Audio(audioFile);

          if (message) {
            window.addEventListener("mousemove", () => {});

            var resp = audio.play();
            if (resp !== undefined) {
              resp
                .then((_) => {
                  // autoplay starts!
                })
                .catch((error) => {
                });
            }
          }
          callback();
        });
      }
    }
  }
};
