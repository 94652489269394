import React from "react";

import HomeUI from "../../../ui/homeUI/_index";

const WorkerCapacity = () => {
  return (
    <HomeUI />
  )
}

export default WorkerCapacity;
