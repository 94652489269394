import * as React from "react";
import { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch, AnyIfEmpty } from "react-redux";
import { CHECK_VOTING } from "../../../apis/_index";

import SubModal from "../_subModal/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import LoaderSpinner from "../../loader-spinner/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { VOTING_LIST_NEED_TO_JOIN } from "../../../apis/_index";
import {
  ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN,
  ONCHANGE_VOTING_LIST,
} from "../../../redux/reducers/workingReducer/_index";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";

import { VotingListNeedToJoin } from "../../../funcs/_votingFuncs";
import ServiceUtils from "../../../utils/services";

const VotingTask = (props: any) => {
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const urlLink = window.location.pathname;
  const userData = localStorage.getItem("userData");

  const dispatch = useDispatch();
  const context = useContext(ModalController);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const shouldLog = useRef(true);

  const [isResult, setIsResult] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [value, setValue] = React.useState("0");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    if (event.target.value === "1") {
      setIsResult(true);
    } else {
      setIsResult(false);
    }
  };

  const _onChangeResult = (e: any) => {
    setResult(e.target.value);
  };

  const _onClickSubmit = async () => {
    const body: any = {
      TaskID: taskId,
      IsResult: isResult,
      Result: result,
    };

    try {
      setIsLoading(true);
      await ServiceUtils.postV2(CHECK_VOTING, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.data.StatusCode === 200) {
          toast.success("Biểu quyết thành công");
          VotingListNeedToJoin((res: any) => {
            if (res.data.StatusCode === 200) {
              dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
            }
          });
          handleClose();
          context.handleClose();
        } else {
          toast.error("Biểu quyết thất bại");
        }
      });
    } catch (error: any) {
      handleClose();
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[30rem] w-[20rem] h-max
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chọn biểu quyết</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="w-full h-max justify-center p-4">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={value}
            onChange={handleChange}
            name="controlled-radio-buttons-group"
            className="w-max text-center"
          >
            <FormControlLabel value="1" control={<Radio />} label="Đồng ý" />
            <FormControlLabel
              value="0"
              control={<Radio />}
              label="Không đồng ý"
            />
          </RadioGroup>
        </FormControl>
        {isResult === false && (
          <textarea
            className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
            placeholder="Nhập lý do không đồng ý"
            value={result}
            onChange={(e: any) => _onChangeResult(e)}
          />
        )}
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${
            (!isResult && result === "") || isLoading === true
              ? "bg-slate-300"
              : "bg-green-500 hover:bg-green-400"
          }
        `}
            disabled={!isResult && result === ""}
            onClick={_onClickSubmit}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <p>Xác nhận</p>
            )}
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VotingTask;
