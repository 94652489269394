import React, { useRef, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../../ui/homeUI/_index";

import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import {
  NGUOI_NHAN_VIEC_NHAN_VIEC,
  NGUOI_NHAN_VIEC_LIST,
} from "../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { ONCHANGE_NGUOI_NHAN_MOI } from "../../../redux/reducers/workingReducer/_index";
import { readList } from "../../../funcs/taskNguoiGiaoViec";

import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";

const ReceiveTask = (props: any) => {
  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const urlLink = window.location.pathname;
  const shouldLog = useRef(true);

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const _onClickSubmit = () => {
    setIsLoading(true);
    const nhanviec = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const API = NGUOI_NHAN_VIEC_NHAN_VIEC + taskId;

      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if ((((res || {}).data || {}).Data[0] || {}).RCode === 200) {
            if (handleClose) {
              readList(
                NGUOI_NHAN_VIEC_LIST,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog,
                urlLink,
                (res: any) => {
                  dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
                },
                null,
                null,
                null,
                null,
                data.searchFilter.searchMonth,
                data.searchFilter.searchYear
              );
              toast.success("Nhận thành công.");
              setIsLoading(false);
              handleClose();
              context.handleClose();
            }
          } else {
            if (handleClose) {
              handleClose();
            }
            toast.error(
              (((res || {}).data || {}).Data[0] || {}).RMessage ||
                "Nhận thất bại."
            );
            setIsLoading(false);
          }
        });
      } catch (error: any) {
        if (handleClose) {
          handleClose();
        }
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
        setIsLoading(false);
      }
    };
    nhanviec();
  };

  return (
    <div
      className="
      flex bg-green-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <PanToolAltIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý nhận công việc này?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className={`
            w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === false
                ? "bg-slate-500 hover:bg-slate-400"
                : "bg-slate-300"
            }
          `}
            onClick={() => _onClickSubmit()}
            disabled={isLoading}
          >
            {isLoading === true ? (
              <div className="w-full h-full flex justify-center items-center">
                <LoaderSpinner w={20} h={20} />
              </div>
            ) : (
              <p>Có</p>
            )}
          </button>
          <button
            className={`
            w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === false ? "bg-red-500 hover:bg-red-400" : "bg-red-300"
            }
          `}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiveTask;
