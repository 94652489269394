import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import {
  READ_ALL_LIST_QUYTRINH_DROPDOWN,
  LOAD_PHONG_BAN,
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  READ_TASK_LIST_BY_USER,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  DETAIL_STEP_DROPDOWN,
  DETAIL_RESULT_DROPDOWN,
  CREATE_TASK_LIST_NGUOI_GIAO,
  NGUOI_GIAO_VIEC_LIST,
  DIFFICULT_LEVEL,
  CONFIG_IS_FORCE,
  DIFICULT_LEVEL_DROPDOWN,
  LOAD_PHONG_BAN_BY_USER,
  APPROVE_TASK,
  CREATE_TASK_MULTI_LIST_NGUOI_GIAO,
  LOAD_SUGGEST_LIST,
  READ_ALL_TASK_LIST_DROPDOWN,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import Select, { StylesConfig } from "react-select";

import { takePermission } from "../../../funcs";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import axios from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";
import CheckBox from "@mui/icons-material/CheckBox";
import DateTimePickerControl from "../../../controls/datetimePicker/datetimePickerControl";
import DimensionConstants from "../../../constants/dimensionConstants";
import OrganizationUtils from "../../../utils/organizations/organizationUtils";
import LevelComponent from "../../organizations/levels/levelComponent";
import TextareaControl from "../../../controls/textarea/textareaControl";
import DeviceUtils from "../../../utils/devices/deviceUtils";
import ServiceUtils from "../../../utils/services";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import { ON_ADD_EVENT } from "../../../redux/reducers/ScheduleReducer/_index";
import { onTouchForm } from "../../../funcs/onTochForm";


export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const CreateNewTask = (props: any) => {
  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  let userData: any = localStorage.getItem("userData");
  let defaultPhongBanTaskReviewer: any;
  let defaultTaskReviewer: any;

  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);
  userData.Data?.map(
    (item: any) =>
    (defaultPhongBanTaskReviewer = {
      IdPhongBan: item?.PhongBan,
      TenPhongBan: item?.TenPhongBan,
    })
  );

  userData.Data?.map((item: any) => {
    defaultTaskReviewer = {
      FullName: item?.FullName,
      Id: item?.Id,
    };
  });

  // take props
  const handleClose = props.handleClose;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;
  const selectDauViec = useRef(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const menu = takePermission(19);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [isShowSuggest, setIsShowSuggest] = useState<boolean>(false);
  const [suggest, setSuggest] = useState<string>("");
  const [suggestList, setSuggestList] = useState<any[] | null>(null);

  const [isExChange, setIsExChange] = useState<boolean>(true);
  const [isEvaluate, setIsEvaluate] = useState<boolean>(false);

  const [useHourSupporter, setUseHourSupporter] = useState<any[]>([]);
  const [useMinutesSupporter, setUseMinutesSupporter] = useState<any[]>([]);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [phongBanSelectedForSelect, setPhongBanSelectedForSelect] =
    useState<any>(-1);
  const [phongBanListForSelect, setPhongBanListForSelect] = useState<
    any[] | undefined
  >(undefined);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [userListForSelect, setUserListForSelect] = useState<any[] | undefined>(
    undefined
  );
  const [userListForSelected, setUserListForSelected] = useState<
    any[] | undefined
  >(undefined);
  const [loaiViecList, setLoaiViecList] = useState<any[] | null>(null);
  const [nguonVanBanList, setNguonVanBanList] = useState<any[] | null>(null);
  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);
  const [dauViecList, setDauViecList] = useState<any[] | null>(null);
  const [quyTrinhList, setQuyTrinhList] = useState<any[] | null>(null);
  const [doKhoList, setDoKhoList] = useState<any[] | null>(null);
  const uploadFileChat = useRef<any>(null);

  const [selectedDoKho, setSelectedDoKho] = useState<any>(-1);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);
  const [isMulti, setIsMulti] = useState<boolean>(false);
  const [isInsertTask, setIsInsertTask] = useState<boolean>(false);
  const [listMultiUser, setListMultiUser] = useState<any[]>([]);
  const [userDanhGiaList, setUserDanhGiaList] = useState<any>(null);
  const [phongBanDanhGiaList, setPhongBanDanhGiaList] = useState<any[] | null>(
    null
  );

  const [selectedPhongBanDanhGia, setSelectedPhongBanDanhGia] = useState<any>(
    defaultPhongBanTaskReviewer || null
  );

  const [selectedNguoiDanhGiaList, setSelectedNguoiDanhGiaList] =
    useState<any>(null);

  const [selectedNguoiDanhGia, setSelectedNguoiDanhGia] = useState<any>(
    defaultTaskReviewer || null
  );

  const [selectedLoaiViec, setSelectedLoaiViec] = useState<any>(-1);
  const [selectedNguonVanBan, setSelectedNguonVanBan] = useState<any>("");
  const [selectedUuTien, setSelectedUuTien] = useState<any>(-1);
  const [selectedDoMat, setSelectedDoMat] = useState<any>(-1);
  const [selectedDauViec, setSelectedDauViec] = useState<any>(-1);
  const [selectedQuyTrinh, setSelectedQuyTrinh] = useState<any>(-1);

  const [isAutoForce, setIsAutoForce] = useState<boolean | null>(null);
  const [isAutoRequiredUseHours, setIsAutoRequiredUseHours] = useState<
    boolean | null
  >(null);
  const [isAutoSecurity, setIsAutoSecurity] = useState<boolean | null>(null);
  const [isAutoPriority, setIsAutoPriority] = useState<boolean | null>(null);
  const [isAutoDifference, setIsAutoDifference] = useState<boolean | null>(
    null
  );
  const [isAutoLoaiViec, setIsAutoLoaiViec] = useState<boolean | null>(null);
  const [priorityDefault, setPriorityDefault] = useState<number | null>(null);
  const [isAutoOrigin, setIsAutoOrigin] = useState<boolean | null>(null);

  const [selectedNguoiNhanList, setSelectedNguoiNhanList] = useState<any>(null);
  const [selectedNguoiPhoiHopList, setSelectedNguoiPhoiHopList] =
    useState<any>(null);

  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );
  const [selectedNguoiGopYList, setSelectedNguoiGopYList] = useState<any>([]);

  const [selectedBuocList, setSelectedBuocList] = useState<any>(null);

  const [selectedKetQuaList, setSelectedKetQuaList] = useState<any>(null);
  const [taskToBeInsertID, setTaskToBeInsertID] = useState<any>(null);
  const [taskToBeInsertName, setTaskToBeInsertName] = useState<any>(null);

  const [filesList, setFilesList] = useState<any>([]);
  const [notes, setNotes] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isForce, setIsForce] = useState<boolean>(false);
  const [isChooseTaskReviewer, setIsChooseTaskReviewer] =
    useState<boolean>(false);
  const [isSelectedBuocOK, setIsSelectedBuocOK] = useState<boolean>(false);

  // other thing
  const [taskName, setTaskName] = useState<any>("");
  const [assignmentDate, setAssignmentDate] = useState<any>("");
  const [deadLine, setDeadLine] = useState<any>("");
  const [refCode, setRefCode] = useState<any>("");

  const [bodyAdding, setBodyAdding] = useState<any>();

  // props - states
  const [positionStep, setPositionStep] = useState<number | null>(null);
  const [hourStep, setHourStep] = useState<number | null>(null);
  const [contentStep, setContentStep] = useState<string>("");

  const [ketqua, setKetqua] = useState<string>("");
  const [isAllUser, setIsAllUser] = useState<boolean>(false);
  const [useHours, setUseHours] = useState<number | null>(0);
  const [useMinutes, setUseMinutes] = useState<number | null>(0);

  const context = useContext(ModalController);
  // funcs
  const _onChangeRefCode = (e: any) => {
    e.preventDefault();
    setRefCode(e.target.value);
  };

  const convertHours = (e: number): any => {
    if (e) {
      const days = Math.floor(e / 24);
      const hours = Math.floor(e % 24);
      const minutes = Math.round((e % 1) * 60);

      let result = "";

      if (days > 0) {
        result += `${days} ngày `;
      }

      if (hours > 0) {
        result += `${hours} giờ `;
      }

      if (minutes > 0) {
        result += `${minutes} phút`;
      }

      return result.trim();
    } else {
      return null;
    }
  };

  const _onChangeHours = (e: any) => {
    e.preventDefault();
    setUseHours(e?.target?.value);
  };

  const _onChangeMinutes = (e: any) => {
    e.preventDefault();
    setUseMinutes(e?.target?.value);
  };

  const _onChangeIsEvaluate = () => {
    if (isEvaluate === false) {
      setIsEvaluate(true);
    } else {
      setIsEvaluate(false);
    }
  };

  const _onConvertToSelect = (
    arr: any[] | null = [],
    name: string,
    value: string,
    color: string | null = null
  ) => {
    let newArr: any[] | null = arr === null ? [] : [...arr];
    newArr = newArr?.map((x: any) => ({
      ...x,
      label: x[name],
      value: x[value],
      color: color !== null ? x[color] : "#000",
    }));
    // const defaulOption = { label: '--- Chọn ---', value: null, color: "#000" }
    // newArr.splice(0, 0, defaulOption)
    return newArr;
  };

  const _onChangePhongBan = (e: any) => {
    e.preventDefault();

    const value = e.target.value;

    setSelectedPhongBan(
      phongBanList?.find((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == value)
          ? true
          : false
      )
    );
    setSelectedNguoiNhanList(
      _onConvertToSelect(
        userList
          ?.filter((x) => x.IdPhongBan.split(",").includes(value))
          .filter((x: any) => x.Id !== userData?.Data[0]?.Id),
        "FullName",
        "Id",
        "ColorText"
      )
    );

    setUserListForSelect(
      _onConvertToSelect(
        userList
          ?.filter((x) => x.IdPhongBan.split(",").includes(value))
          .filter((x: any) => x.Id !== userData?.Data[0]?.Id),
        "FullName",
        "Id",
        "ColorText"
      )
    );

    setSelectedNguoiNhan(null);

    setSelectedNguoiLamViecList([]);
    setSelectedNguoiGopYList([]);
    setUseHourSupporter([]);
    setUseMinutesSupporter([]);

    setSelectedQuyTrinh(-1);
    setSelectedBuocList(null);
    setSelectedKetQuaList(null);
  };

  const _onChangeNguoiNhan = (e: any) => {
    // e.preventDefault();
    const value = e?.Id;

    setIsInsertTask(false);

    setSelectedNguoiNhan(
      selectedNguoiNhanList?.find((item: any) => item?.Id === value)
    );

    // _onConvertToSelect(
    //   userList?.filter((x) => x.IdPhongBan.split(",").includes(value)).filter(
    //     (x: any) => x.Id !== userData?.Data[0]?.Id
    //   ),
    //   "FullName",
    //   "Id",
    //   "ColorText"
    // )

    setSelectedNguoiPhoiHopList(
      userList?.filter((item: any) => item?.Id !== value)
    );

    const tempArray = [
      selectedNguoiNhanList?.find((item: any) => item?.Id === value),
    ];

    setSelectedNguoiLamViecList(tempArray);
    setSelectedNguoiGopYList(tempArray);
  };

  // -- edit files
  const saveEditFile = (_index: number, fileName: string) => {
    const tempArray = [...filesList];

    const updateArray = tempArray?.map((item: any, index: any) => {
      if (index === _index) {
        const renamedFile = new Blob([item], {
          type: item?.type,
        });

        const fileWithNewName = new File([renamedFile], fileName, {
          type: item?.type,
          lastModified: item?.lastModified,
        });

        return fileWithNewName;
      } else {
        return item;
      }
    });

    setFilesList(updateArray);
  };

  const _onClickEditFile = (index: string, file: any, setFile: any) => {
    setFuncsSub("openEditFileSub");
    setBodyAdding({
      filesList2: file,
      setFilesList2: setFile,
      saveEditFile,
      index,
    });
    handleOpenSub();
  };
  // -- end edit file

  const _onChangePhongBanDanhGia = (e: any) => {
    e.preventDefault();
    setSelectedPhongBanDanhGia(
      phongBanDanhGiaList?.find((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == parseInt(e.target.value))
          ? true
          : false
      )
    );
    setSelectedNguoiDanhGiaList(
      userDanhGiaList?.filter((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == parseInt(e.target.value))
          ? true
          : false
      )
    );
    setSelectedNguoiDanhGia(-1);
  };

  const _onChangeNguoiDanhGia = (e: any) => {
    e.preventDefault();
    setSelectedNguoiDanhGia(
      selectedNguoiDanhGiaList?.find((item: any) => item?.Id === e.target.value)
    );
  };

  const _onChangeStepList = (type: string, value: any, index: any) => {
    switch (type) {
      case "stepName":
        let newArray = [...selectedBuocList];
        newArray[index].StepName = value;
        setSelectedBuocList(newArray);
        break;
      case "orderBy":
        let newArray2 = [...selectedBuocList];
        newArray2[index].SortOrder = value;
        setSelectedBuocList(newArray2);
        break;
      case "result":
        let newArray3 = [...selectedKetQuaList];
        newArray3[index].tenketqua = value;
        setSelectedKetQuaList(newArray3);
        break;
      default:
        break;
    }
  };

  const _onCheckNguoiLamViec = (id: string, users: any) => {
    const filteredList = taskTimeUsed2.filter(
      (item: any) => item.UserID !== id
    );
    setTaskTimeUsed2(filteredList);

    const isTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    const isCounterTrue = selectedNguoiGopYList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      const newUserList: any = [];

      const newUser = users?.find((item: any) => item?.Id == id);

      if (newUser) {
        newUserList.push(newUser);
      }

      setSelectedNguoiLamViecList((oldValue: any) =>
        [...oldValue].concat(newUserList)
      );

      setUseHourSupporter((oldValue: any) => [
        ...oldValue,
        {
          id: id,
          value: 0,
        },
      ]);

      setUseMinutesSupporter((oldValue: any) => [
        ...oldValue,
        {
          id: id,
          value: 0,
        },
      ]);

      // Countering
      if (isCounterTrue !== undefined) {
        const tempArray = selectedNguoiGopYList?.filter(
          (item: any) => item?.Id !== id
        );

        setSelectedNguoiGopYList(tempArray);
      }
    } else {
      const tempArray = selectedNguoiLamViecList?.filter(
        (item: any) => item?.Id !== id
      );

      const tempHours = useHourSupporter?.filter(
        (item: any) => item?.id !== id
      );

      const tempMinutes = useMinutesSupporter?.filter(
        (item: any) => item?.id !== id
      );

      setSelectedNguoiLamViecList(tempArray);

      setUseHourSupporter(tempHours);
      setUseMinutesSupporter(tempMinutes);
    }
  };

  const _onCheckNguoiGopY = (id: any, users: any) => {
    const isTrue = selectedNguoiGopYList?.find((item: any) => item?.Id === id);

    const isCounterTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      const newUserList: any = [];

      const newUser = users?.find((item: any) => item?.Id == id);

      if (newUser) {
        newUserList.push(newUser);
      }

      setSelectedNguoiGopYList((oldValue: any) =>
        [...oldValue].concat(newUserList)
      );

      // Countering
      if (isCounterTrue !== undefined) {
        const tempArray = selectedNguoiLamViecList?.filter(
          (item: any) => item?.Id !== id
        );

        const tempHours = useHourSupporter?.filter(
          (item: any) => item?.id !== id
        );
        const tempMinutes = useMinutesSupporter?.filter(
          (item: any) => item?.id !== id
        );

        setSelectedNguoiLamViecList(tempArray);
        setUseHourSupporter(tempHours);
        setUseMinutesSupporter(tempMinutes);
      }
    } else {
      const tempArray = selectedNguoiGopYList?.filter(
        (item: any) => item?.Id !== id
      );

      setSelectedNguoiGopYList(tempArray);
    }
  };

  const _onChangeQuyTrinh = (e: any) => {
    e.preventDefault();

    if (e.target.value === "-1") {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item?.id === parseInt(e.target.value))
      );

      const takeStep = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_STEP_DROPDOWN + e.target.value, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            let tempArray: any[] = [];

            res.data.Data.forEach((item: any, index: any) => {
              const body = {
                StepName: item?.tenbuoc,
                UserDo: "",
                DeadLine_Step: "",
                SortOrder: item?.orderBy,
              };
              tempArray.push(body);
            });

            setSelectedBuocList(tempArray);
          });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_RESULT_DROPDOWN + e.target.value, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setSelectedKetQuaList(res.data.Data);
          });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  // non - funcs
  const _onChangeNguoiPhoiHop = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onChangeTaskInsert = () => {
    handleOpenSub();
    setFuncsSub('chooseTaskInsert');
  };

  const _onClickEditBuoc = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditKetqua = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // props - funcs
  // buoc
  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setPositionStep(parseInt(e.target.value));
  };

  const _onChangeHourStep = (e: any) => {
    e.preventDefault();
    setHourStep(e.target.value);
  };

  const _onChangeContentStep = (e: any) => {
    e.preventDefault();
    setContentStep(e.target.value);
  };

  const _onPushStep = () => {
    const body = {
      StepName: contentStep,
      UserDo: "",
      DeadLine_Step: "",
      SortOrder: positionStep,
    };

    if (selectedBuocList === null || selectedBuocList === undefined) {
      setSelectedBuocList([body]);
    } else {
      let isExistedStep = selectedBuocList.find(
        (item: any) => item?.SortOrder === positionStep
      );

      if (isExistedStep === undefined) {
        setSelectedBuocList((oldValue: any) => [...oldValue, body]);
      } else {
      }
    }

    setPositionStep(null);
    setHourStep(null);
    setContentStep("");
  };

  const _onDeleteQuyTrinh = (step: any) => {
    setSelectedBuocList(
      selectedBuocList.filter((item: any) => item?.SortOrder !== step)
    );
  };

  const _onChangeNguoiPhoiHopEachStep = (orderBy: any, nguoiPhoiHop: any) => {
    const updateArray = selectedBuocList?.map((item: any) => {
      if (item?.SortOrder === orderBy) {
        return {
          ...item,
          UserDo: nguoiPhoiHop,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };

  const _onChangDeadLineStep = (orderBy: any, deadLine: any) => {
    console.log(orderBy);
    console.log(deadLine);

    const updateArray = selectedBuocList?.map((item: any) => {
      if (item?.SortOrder === orderBy) {
        return {
          ...item,
          DeadLine_Step: deadLine,
        };
      }

      return item;
    });
    console.log(updateArray);

    setSelectedBuocList(updateArray);
  };
  const _onChangUseHoursStep = (orderBy: any, e: any) => {
    const value = e?.target?.value;
    let updatedBuocList = [...selectedBuocList];

    updatedBuocList = selectedBuocList?.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return { ...item, UseHours: value };
      }
      return item;
    });

    setSelectedBuocList(updatedBuocList);
  };
  const _onChangUseMinutesStep = (orderBy: any, e: any) => {
    const value = e?.target?.value;
    let updatedBuocList = [...selectedBuocList];

    updatedBuocList = selectedBuocList?.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return { ...item, UseMinutes: value };
      }
      return item;
    });

    setSelectedBuocList(updatedBuocList);
  };

  const _onChangKetQuaStep = (orderBy: any, e: any) => {
    const value = e?.target?.value;
    let updatedBuocList = [...selectedBuocList];

    updatedBuocList = selectedBuocList?.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return { ...item, KetQua: value };
      }
      return item;
    });

    setSelectedBuocList(updatedBuocList);
  };


  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };
  // ket qua
  const _onChangeKetqua = (e: any) => {
    e.preventDefault();
    setKetqua(e.target.value);
  };

  const _onPushKetqua = () => {
    const body = {
      idquytrinh: selectedQuyTrinh?.Id,
      tenketqua: ketqua,
    };

    if (selectedKetQuaList === null) {
      setSelectedKetQuaList([body]);
    } else {
      setSelectedKetQuaList((oldValue: any) => [...oldValue, body]);
    }

    setKetqua("");
  };

  const _onChangePhongBanSelect = (e: any, type: number) => {
    e.preventDefault();
    // <<<<<<< HEAD
    //     const nguoiNhan = userListForSelect?.filter((item: any) => {
    //       const idPhongBanArray = (item?.IdPhongBan || "").split(",");
    //       const idPhongBanNumbers = idPhongBanArray?.map((id: any) =>
    //         parseInt(id, 10)
    //       );
    //       return idPhongBanNumbers.includes(parseInt(e?.target?.value, 10));
    //     });
    //     console.log("Nguoinhan", nguoiNhan);
    // =======

    const nguoiNhan = userListForSelect?.filter((item: any) =>
      (item?.IdPhongBan || "")
        .toString()
        .split(",")
        .find((p: any) => p == parseInt(e?.target?.value))
        ? true
        : false
    );

    setUserListForSelected(nguoiNhan);

    setPhongBanSelectedForSelect(
      phongBanListForSelect?.find(
        //         (item: any) => item?.IdPhongBan == parseInt(e?.target?.value)
        (item: any) =>
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == parseInt(e?.target?.value))
            ? true
            : false
      )
    );
  };

  const _onDeleteKetqua = (ketQua: string) => {
    setSelectedKetQuaList(
      selectedKetQuaList.filter((item: any) => item?.tenketqua !== ketQua)
    );
  };

  // files
  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onDeleteElements = (index: number) => {
    const tempArray: any[] = [...filesList];
    tempArray.splice(index, 1);
    setFilesList(tempArray);
  };

  // other things
  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  };

  const _onChangeDeadLine = (e: any) => {
    e.preventDefault();
    setDeadLine(e.target.value);
  };

  const _onChangeDeadLine2 = (e: any) => {
    const value = e;
    setDeadLine(value);
  };

  const _onChangeAssignmentDate = (e: any) => {
    const value = e;
    setAssignmentDate(value);
  };

  const _onChangeSelectedLoaiCongViec = (e: any) => {
    e.preventDefault();
    setSelectedLoaiViec(
      loaiViecList?.find(
        (item: any) => item?.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(
      nguonVanBanList?.find(
        (item: any) => item?.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNewNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(e.target.value);
  };

  const _onChangeDoKho = (e: any) => {
    e.preventDefault();
    setSelectedDoKho(
      doKhoList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeSelectedUuTien = (e: any) => {
    e.preventDefault();
    setSelectedUuTien(
      uuTienList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeDoMat = (e: any) => {
    e.preventDefault();
    setSelectedDoMat(
      doMatList?.find((item: any) => item?.Id === e.target.value)
    );
  };

  const _onChangeDauViec = (e: any) => {
    e.preventDefault();
    setSelectedDauViec(
      dauViecList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeNotes = (e: any) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  const _onChangeIsMulti = () => {
    setIsMulti((item: any) => !item);
    setIsInsertTask(false);
  };

  const _onChangeIsInsertTask = () => {
    setIsInsertTask((item: any) => !item);
    setIsMulti(false);
  };

  const _onChangeIsChooseTaskReviewer = () => {
    setIsChooseTaskReviewer((item: any) => !item);
  };

  // -- Caching temporary data
  const _onClickCaching = () => {
    /*
      - selectedDauViec
      - taskName
      - selectedLoaiViec
      - selectedDoMat
      - deadLine
      - selectedNguonVanBan
      - selectedUuTien
      - selectedDoKho
      - selectedPhongBan
      - selectedNguoiNhan
      - isChooseTaskReviewer
      - selectedPhongBanDanhGia
      - selectedNguoiDanhGia
      - selectedQuyTrinh
      - isForce
      - selectedBuocList
      - selectedKetquaList
      - description
      - notes
      - selectedNguoiNhanList
      - selectedNguoiPhoiHopList
      - selectedNguoiLamViecList
      - dauViecList,
      - quyTrinhList
    */
    const body: any = {
      selectedDauViec,
      taskName,
      selectedLoaiViec,
      selectedDoMat,
      deadLine,
      selectedNguonVanBan,
      selectedUuTien,
      selectedDoKho,
      selectedPhongBan,
      selectedNguoiNhan,
      isChooseTaskReviewer,
      selectedPhongBanDanhGia,
      selectedNguoiDanhGia,
      selectedQuyTrinh,
      isForce,
      selectedBuocList,
      selectedKetQuaList,
      description,
      notes,
      selectedNguoiNhanList,
      selectedNguoiPhoiHopList,
      selectedNguoiLamViecList,
      selectedNguoiGopYList,
      isExChange,
      isEvaluate,
      dauViecList,
      quyTrinhList,
      useHours,
      useMinutes,
      useHourSupporter,
      useMinutesSupporter,
      isMulti,
      listMultiUser,
      isAllUser,
    };

    localStorage.setItem("tempTaskCreate", JSON.stringify(body));
    handleClose();
  };
  // -- End caching

  // Take Caching

  // submit
  // funcs
  const _onClickSubmit = async (type?: boolean): Promise<any> => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu

    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList.length - 1) {
        tempStringNguoiPhoiHop += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item?.Id.toString()},`;
      }
    });

    let tempStringNguoiGopY: string = "";

    selectedNguoiGopYList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiGopYList.length - 1) {
        tempStringNguoiGopY += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiGopY += `${item?.Id.toString()},`;
      }
    });

    let tempHourUseSupporter: string = "";

    useHourSupporter.forEach((item: any, index: any) => {
      if (index === useHourSupporter.length - 1) {
        tempHourUseSupporter += `${item?.value.toString()}`;
      } else {
        tempHourUseSupporter += `${item?.value.toString()},`;
      }
    });

    let tempMinutesUseSupporter: string = "";

    useMinutesSupporter.forEach((item: any, index: any) => {
      if (index === useMinutesSupporter.length - 1) {
        tempMinutesUseSupporter += `${item?.value.toString()}`;
      } else {
        tempMinutesUseSupporter += `${item?.value.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("TaskName", taskName ? taskName : "");

    let tempArrayDauViec: string = "";

    if (selectedDauViec && selectedDauViec != -1) {

      selectedDauViec?.forEach((item: any, index: any) => {
        if (index === selectedDauViec.length - 1) {
          tempArrayDauViec += `${item?.Id.toString()}`;
        } else {
          tempArrayDauViec += `${item?.Id.toString()},`;
        }
      });
    }
    formData.append(
      "TaskListID",
      tempArrayDauViec
      // (selectedDauViec ? selectedDauViec.Id : "").toString()
    );

    formData.append(
      "PriorityLevelId",
      selectedUuTien != -1 && selectedUuTien
        ? selectedUuTien.Id
        : priorityDefault
          ? priorityDefault
          : ""
    );
    formData.append(
      "SecurityLevelID",
      selectedDoMat?.Id ? selectedDoMat?.Id : null
    );

    formData.append(
      "DifficultLevelID",
      selectedDoKho?.Id ? selectedDoKho?.Id : ""
    );

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec?.ParameterId ? selectedLoaiViec?.ParameterId : ""
    );
    formData.append("NguonViec", selectedNguonVanBan);
    formData.append(
      "QuyTrinhID",
      selectedQuyTrinh?.id ? selectedQuyTrinh?.id : ""
    );
    formData.append(
      "Recipient",
      selectedNguoiNhan?.Id ? selectedNguoiNhan?.Id : ""
    );

    formData.append(
      "TaskReviewer",
      isChooseTaskReviewer
        ? selectedNguoiDanhGia?.Id
          ? selectedNguoiDanhGia.Id
          : ""
        : userData?.Data[0]?.IsActiveOffice && !takePermission(20).PerApprove
          ? ""
          : defaultTaskReviewer?.Id
    );

    formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("IDRole", tempStringNguoiGopY);
    formData.append("IsExChange", isExChange?.toString());
    formData.append("IsEvaluate", isEvaluate?.toString());

    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append(
      "DeadLine",
      deadLine ? new Date(deadLine).toString().substring(0, 24) : ""
    );
    formData.append(
      "AssignmentDate",
      new Date(assignmentDate).toString().substring(0, 24)
    );

    formData.append(
      "IsWaitAssignment",
      assignmentDate !== "" ? "true" : "false"
    );

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          new Date(selectedBuocList[index].DeadLine_Step).toString().substring(0, 24)
        );
        formData.append(
          `TaskContent[${index}].MinutesUsed`,
          selectedBuocList[index].UseMinutes
        );
        formData.append(
          `TaskContent[${index}].HoursUsed`,
          selectedBuocList[index].UseHours
        );
        formData.append(
          `TaskContent[${index}].StepResult`,
          selectedBuocList[index].KetQua
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());

    formData.append("HoursUsed", useHours ? useHours.toString() : "");
    formData.append("HoursUsed_Supporter", tempHourUseSupporter);
    formData.append("MinutesUsed", useMinutes ? useMinutes.toString() : "");
    formData.append("MinutesUsed_Supporter", tempMinutesUseSupporter);

    // formData.append("IdPhongBan", (organizationId || "").toString());
    formData.append(
      "PhongBanId",
      (selectedPhongBan?.IdPhongBan || "").toString()
    );

    if (props.isChiaViec === true) formData.append("RefDeduct", props.taskId);

    // if (isProtractedTask) {
    //   const clearedList = taskTimeUsed2?.map((item: any) => {
    //     const { TaskId, index, ...rest } = item;
    //     return rest;
    //   });

    //   clearedList?.map((item: any, index: number) => {
    //     formData.append(`Task_TimeUsed[${index}]`, item);

    //   });
    // } else {
    //   const id = tempStringNguoiPhoiHop.split(",");
    //   const hour = tempHourUseSupporter.split(",");
    //   const minutes = tempMinutesUseSupporter.split(",");

    //   let data: any = [];
    //   data.push({
    //     TaskId: "",
    //     UserID: selectedNguoiNhan?.Id ? selectedNguoiNhan?.Id : "",
    //     Month: new Date().getMonth(),
    //     Year: new Date().getFullYear(),
    //     HoursUsed: useHours ? useHours.toString() : "",
    //     MinutesUsed: useMinutes ? useMinutes.toString() : "",
    //   });
    //   id?.map((item, index) => {
    //     data.push({
    //       TaskId: "",
    //       UserID: item || "",
    //       Month: new Date().getMonth(),
    //       Year: new Date().getFullYear(),
    //       HoursUsed: hour[index] || "",
    //       MinutesUsed: minutes[index] || "",
    //     });
    //   });
    //   formData.append("Task_TimeUsed", data);
    // }

    if (taskToBeInsertID) {
      formData.append("TaskToBeInsert", taskToBeInsertID);
      formData.append("IsInsertTask", "true");
    } else {
      formData.append("IsInsertTask", "false");
    }

    if (isProtractedTask) {
      const clearedList = taskTimeUsed2?.map((item: any) => {
        const { TaskId, index, ...rest } = item;
        return rest;
      });

      clearedList?.map((item: any, index: number) => {
        formData.append(`Task_TimeUsed[${index}].UserID`, item.UserID);
        formData.append(`Task_TimeUsed[${index}].Month`, item.Month);
        formData.append(`Task_TimeUsed[${index}].Year`, item.Year);
        formData.append(`Task_TimeUsed[${index}].HoursUsed`, item.HoursUsed);
        formData.append(
          `Task_TimeUsed[${index}].MinutesUsed`,
          item.MinutesUsed
        );
      });
    } else {
      const id = tempStringNguoiPhoiHop.split(",");
      const hour = tempHourUseSupporter.split(",");
      const minutes = tempMinutesUseSupporter.split(",");

      let data: any = [];
      data.push({
        UserID: selectedNguoiNhan?.Id ? selectedNguoiNhan?.Id : "",
        Month: new Date().getMonth(),
        Year: new Date().getFullYear(),
        HoursUsed: useHours ? useHours.toString() : "",
        MinutesUsed: useMinutes ? useMinutes.toString() : "",
      });
      if (id)
        id?.map((item, index) => {
          if (item !== "")
            data.push({
              UserID: item || "",
              Month: new Date().getMonth(),
              Year: new Date().getFullYear(),
              HoursUsed: hour[index] || "",
              MinutesUsed: minutes[index] || "",
            });
        });

      data?.map((item: any, index: number) => {
        formData.append(`Task_TimeUsed[${index}].UserID`, item.UserID);
        formData.append(`Task_TimeUsed[${index}].Month`, item.Month);
        formData.append(`Task_TimeUsed[${index}].Year`, item.Year);
        formData.append(`Task_TimeUsed[${index}].HoursUsed`, item.HoursUsed);
        formData.append(`Task_TimeUsed[${index}].MinutesUsed`, item.MinutesUsed
        );
      });
    }

    const takeData = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        ServiceUtils.postFormDataV2(CREATE_TASK_LIST_NGUOI_GIAO, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res: any) => {
            if (res?.data?.StatusCode === 200) {
              readList(
                NGUOI_GIAO_VIEC_LIST,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog3,
                urlLink,
                (res: any) => {
                  dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
                },
                null,
                null,
                null,
                null,
                data.searchFilter.searchMonth,
                data.searchFilter.searchYear
              );

              localStorage.removeItem("tempTaskCreate");

              setIsLoading(false);
              handleClose();
              if (!type) {
                if (props.isChiaViec == true) {
                  props._onFetchData();
                }
                toast.success("Tạo công việc mới thành công.");
              } else {
                resolve(res?.data.Data.RId);
              }
            } else {
              setIsLoading(false);
              resolve(false);
            }
          })
          .catch((error: any) => {
            setIsLoading(false);
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
            reject(error);
          });
      });
    };

    return takeData();
  };

  const _onClickSubmitNow = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList.length - 1) {
        tempStringNguoiPhoiHop += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item?.Id.toString()},`;
      }
    });

    let tempStringNguoiGopY: string = "";

    selectedNguoiGopYList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiGopYList.length - 1) {
        tempStringNguoiGopY += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiGopY += `${item?.Id.toString()},`;
      }
    });

    let tempHourUseSupporter: string = "";

    useHourSupporter.forEach((item: any, index: any) => {
      if (index === useHourSupporter.length - 1) {
        tempHourUseSupporter += `${item?.value.toString()}`;
      } else {
        tempHourUseSupporter += `${item?.value.toString()},`;
      }
    });

    let tempMinutesUseSupporter: string = "";

    useMinutesSupporter.forEach((item: any, index: any) => {
      if (index === useMinutesSupporter.length - 1) {
        tempMinutesUseSupporter += `${item?.value.toString()}`;
      } else {
        tempMinutesUseSupporter += `${item?.value.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("TaskName", taskName ? taskName : "");

    let tempArrayDauViec: string = "";

    selectedDauViec?.forEach((item: any, index: any) => {
      if (index === selectedDauViec.length - 1) {
        tempArrayDauViec += `${item?.Id.toString()}`;
      } else {
        tempArrayDauViec += `${item?.Id.toString()},`;
      }
    });

    formData.append(
      "TaskListID",
      tempArrayDauViec
      // (selectedDauViec ? selectedDauViec.Id : "").toString()
    );

    formData.append(
      "PriorityLevelId",
      selectedUuTien != -1 && selectedUuTien
        ? selectedUuTien.Id
        : priorityDefault
          ? priorityDefault
          : ""
    );
    formData.append(
      "SecurityLevelID",
      selectedDoMat?.Id ? selectedDoMat?.Id : null
    );

    formData.append(
      "DifficultLevelID",
      selectedDoKho?.Id ? selectedDoKho.Id : null
    );

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec?.ParameterId ? selectedLoaiViec?.ParameterId : ""
    );

    formData.append(
      "TaskReviewer",
      isChooseTaskReviewer
        ? selectedNguoiDanhGia?.Id
          ? selectedNguoiDanhGia?.Id
          : ""
        : userData?.Data[0]?.IsActiveOffice && !takePermission(20).PerApprove
          ? ""
          : defaultTaskReviewer?.Id
    );

    formData.append("NguonViec", selectedNguonVanBan);
    formData.append(
      "QuyTrinhID",
      selectedQuyTrinh?.id ? selectedQuyTrinh?.id : ""
    );

    if (taskToBeInsertID) {
      formData.append("TaskToBeInsert", taskToBeInsertID);
      formData.append("IsInsertTask", "true");
    } else {
      formData.append("IsInsertTask", "false");
    }

    if (isMulti) {
      for (let index = 0; index < listMultiUser.length; index++) {
        formData.append(
          `listReptcient_ForMultiCreate[${index}]`,
          listMultiUser[index].Id
        );
      }
    } else {
      formData.append(
        "Recipient",
        selectedNguoiNhan?.Id ? selectedNguoiNhan?.Id : ""
      );
      formData.append("Supporter", tempStringNguoiPhoiHop);
      formData.append("IDRole", tempStringNguoiGopY);
    }
    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", new Date(deadLine).toString().substring(0, 24));

    formData.append(
      "AssignmentDate",
      assignmentDate !== ""
        ? new Date(assignmentDate).toString().substring(0, 24)
        : ""
    );

    formData.append(
      "IsWaitAssignment",
      assignmentDate !== "" ? "true" : "false"
    );

    formData.append("IsExChange", isExChange ? isExChange?.toString() : "");
    formData.append("IsEvaluate", isEvaluate ? isEvaluate?.toString() : "");

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          new Date(selectedBuocList[index].DeadLine_Step).toString().substring(0, 24)
        );
        formData.append(
          `TaskContent[${index}].MinutesUsed`,
          selectedBuocList[index].UseMinutes
        );
        formData.append(
          `TaskContent[${index}].HoursUsed`,
          selectedBuocList[index].UseHours
        );
        formData.append(
          `TaskContent[${index}].StepResult`,
          selectedBuocList[index].KetQua
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());

    formData.append("HoursUsed", useHours ? useHours.toString() : "");
    formData.append(
      "HoursUsed_Supporter",
      tempHourUseSupporter ? tempHourUseSupporter.toString() : ""
    );
    formData.append("MinutesUsed", useMinutes ? useMinutes.toString() : "");
    formData.append(
      "MinutesUsed_Supporter",
      tempMinutesUseSupporter ? tempMinutesUseSupporter.toString() : ""
    );

    // formData.append("IdPhongBan", (organizationId || "").toString());
    formData.append(
      "PhongBanId",
      (selectedPhongBan?.IdPhongBan || "").toString()
    );

    if (props.isChiaViec === true) formData.append("RefDeduct", props.taskId);

    if (isProtractedTask) {
      const clearedList = taskTimeUsed2?.map((item: any) => {
        const { TaskId, index, ...rest } = item;
        return rest;
      });

      clearedList?.map((item: any, index: number) => {
        formData.append(`Task_TimeUsed[${index}].UserID`, item.UserID);
        formData.append(`Task_TimeUsed[${index}].Month`, item.Month);
        formData.append(`Task_TimeUsed[${index}].Year`, item.Year);
        formData.append(`Task_TimeUsed[${index}].HoursUsed`, item.HoursUsed);
        formData.append(
          `Task_TimeUsed[${index}].MinutesUsed`,
          item.MinutesUsed
        );
      });
    } else {
      const id = tempStringNguoiPhoiHop.split(",");
      const hour = tempHourUseSupporter.split(",");
      const minutes = tempMinutesUseSupporter.split(",");

      let data: any = [];
      data.push({
        UserID: selectedNguoiNhan?.Id ? selectedNguoiNhan?.Id : "",
        Month: new Date(deadLine).getMonth() + 1,
        Year: new Date(deadLine).getFullYear(),
        HoursUsed: useHours ? useHours.toString() : "",
        MinutesUsed: useMinutes ? useMinutes.toString() : "",
      });
      if (id)
        id?.map((item, index) => {
          if (item !== "")
            data.push({
              UserID: item || "",
              Month: new Date(deadLine).getMonth() + 1,
              Year: new Date(deadLine).getFullYear(),
              HoursUsed: hour[index] || "",
              MinutesUsed: minutes[index] || "",
            });
        });


      data?.map((item: any, index: number) => {

        formData.append(`Task_TimeUsed[${index}].UserID`, item.UserID);
        formData.append(`Task_TimeUsed[${index}].Month`, item.Month);
        formData.append(`Task_TimeUsed[${index}].Year`, item.Year);
        formData.append(`Task_TimeUsed[${index}].HoursUsed`, item.HoursUsed);
        formData.append(
          `Task_TimeUsed[${index}].MinutesUsed`,
          item.MinutesUsed
        );
      });
    }

    const takeData = async () => {
      const API_CREATE = isMulti
        ? CREATE_TASK_MULTI_LIST_NGUOI_GIAO
        : CREATE_TASK_LIST_NGUOI_GIAO;
      try {
        setIsLoading(true);
        await ServiceUtils.postFormDataV2(API_CREATE, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res: any) => {
          if (isMulti) {
            let arrResult: any[] = [];
            arrResult = res?.data;

            let _count = arrResult?.filter(
              (x: any) => x?.Data?.RCode !== 200
            ).length;

            if (_count > 0) {
              arrResult?.map((x: any) => {
                if (x?.Data?.RCode !== 200) {
                  setIsLoading(false);
                  handleClose();
                  toast.error(
                    "Giao việc cho " +
                    userListForSelect?.find(
                      (i: any) => i?.Id?.trim() == x?.Data?.RId?.trim()
                    )?.FullName +
                    " thất bại!"
                  );
                }
              });
            } else {
              setIsLoading(false);
              handleClose();
              toast.success("Giao việc thành công!");
            }
          } else {
            if (res?.data?.StatusCode === 200) {
              localStorage.removeItem("tempTaskCreate");
              if (!isMulti) {
                const approve = async () => {
                  // const accessToken = localStorage.getItem("accessToken");
                  const API = APPROVE_TASK + res.data.Data.RId;

                  try {
                    await ServiceUtils.getV2(API, {
                      headers: {
                        Accept: "text/plain",
                      },
                    }).then((res: any) => {
                      if (res.data.StatusCode === 200) {
                        if (props.isChiaViec == true) {
                          props._onFetchData();
                        }
                        toast.success("Tạo công việc và giao thành công.");
                        setIsLoading(false);
                        localStorage.removeItem("tempTaskCreate");
                        handleClose();
                        // if (urlLink === "/leave/calendar") {
                        //   const newEvent = {
                        //     event_id: res.data.Data.RId,
                        //     start: new Date(),
                        //     end: new Date(deadLine),
                        //     title: taskName,
                        //     type: 1,
                        //   };
                        //   dispatch(ON_ADD_EVENT(newEvent));
                        // }
                        context.dataModal?.tempFuncs();
                      } else {
                        toast.error(
                          res?.data?.Message ||
                          "Công việc đã được tạo nhưng giao thất bại!"
                        );
                        handleClose();
                      }
                    });
                  } catch (error: any) {
                    if (handleClose) {
                      setIsLoading(false);
                      handleClose();
                    }
                    toast.error("Giao thất bại.");
                  }
                };
                approve();
              }
            } else {
              setIsLoading(false);
              toast.error(
                res?.data?.Message ||
                "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              );
            }
          }
        });
      } catch (error: any) {
        setIsLoading(false);
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };

  // load selecting data
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      if (isAutoForce === null) {
        // const accessToken = localStorage.getItem("accessToken");

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(CONFIG_IS_FORCE, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                if (res?.data?.Data[0]?.AllowTaskReject === false) {
                  setIsForce(true);
                }

                setIsAutoForce(res?.data?.Data[0]?.AllowTaskReject);
                setIsAutoRequiredUseHours(
                  res?.data?.Data[0]?.IsRequiredUseHours
                );
                setIsAutoSecurity(res?.data?.Data[0]?.AllowUseSecurity);
                setIsAutoPriority(res?.data?.Data[0]?.AllowUsePriority);
                setIsAutoDifference(res?.data?.Data[0]?.AllowUseDifference);
                setIsAutoOrigin(res?.data?.Data[0]?.AllowUseOrigin);
                setIsAutoLoaiViec(res?.data?.Data[0]?.AllowUseLoaiViec);
                setPriorityDefault(res?.data?.Data[0]?.PriorityDefault);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (suggestList === null) {
        const fetchData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_SUGGEST_LIST, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.data.StatusCode === 200) {
                setSuggestList(res.data.Data);
              } else {
                toast.error(res.data.Message);
              }
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        fetchData();
      }

      if (phongBanList === null || userList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_PHONG_BAN_BY_USER, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                const organizations =
                  ((res.data || {}).Data || {}).PhongBan || [];

                const organizationTrees = OrganizationUtils.getListTree(
                  organizations,
                  {
                    id: "IdPhongBan",
                    parentId: "RefID",
                  }
                );
                if (!userData?.Data[0]?.IsActiveOffice) {
                  setPhongBanList(organizationTrees);
                  const defaulPhongBan = organizationTrees?.find(
                    (x: any) => x?.RefID === null
                  );
                  const tempTaskCreate = localStorage.getItem("tempTaskCreate");
                  let _selectedPhongBan = -1;
                  if (tempTaskCreate === null || tempTaskCreate === undefined) {
                  } else {
                    _selectedPhongBan =
                      JSON.parse(tempTaskCreate)?.selectedPhongBan;
                  }

                  if (
                    defaulPhongBan &&
                    (!_selectedPhongBan || _selectedPhongBan == -1)
                  ) {

                    setSelectedPhongBan(defaulPhongBan);
                    setSelectedNguoiNhanList(
                      _onConvertToSelect(
                        res?.data?.Data?.NguoiNhan?.filter((x: any) =>
                          x.IdPhongBan.split(",").includes(
                            defaulPhongBan?.IdPhongBan.toString()
                          )
                        ).filter((x: any) => x.Id !== userData?.Data[0]?.Id),
                        "FullName",
                        "Id",
                        "ColorText"
                      )
                    );
                    setUserListForSelect(
                      _onConvertToSelect(
                        res?.data?.Data?.NguoiNhan?.filter((x: any) =>
                          x.IdPhongBan.split(",").includes(
                            defaulPhongBan?.IdPhongBan.toString()
                          )
                        ).filter((x: any) => x.Id !== userData?.Data[0]?.Id),
                        "FullName",
                        "Id",
                        "ColorText"
                      )
                    );
                  } else {
                    setSelectedPhongBan(_selectedPhongBan);
                  }
                }
                setPhongBanListForSelect(
                  _onConvertToSelect(
                    res?.data?.Data?.PhongBan,
                    "TenPhongBan",
                    "IdPhongBan",
                    "ColorText"
                  )
                );
                setUserList(res?.data?.Data?.NguoiNhan);
                setUserListForSelect(
                  _onConvertToSelect(
                    res?.data?.Data?.NguoiNhan.filter(
                      (x: any) => x.Id !== userData?.Data[0]?.Id
                    ),
                    "FullName",
                    "Id"
                  )
                );
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (phongBanDanhGiaList === null || userDanhGiaList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_PHONG_BAN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                const organizations = res?.data?.Data?.PhongBan;
                const organizationTrees = OrganizationUtils.getListTree(
                  organizations,
                  {
                    id: "IdPhongBan",
                    parentId: "RefID",
                  }
                );
                if (userData?.Data[0]?.IsActiveOffice) {
                  setPhongBanList(organizationTrees);
                  const defaulPhongBan = organizationTrees?.find(
                    (x: any) => x?.RefID === null
                  );
                  const tempTaskCreate = localStorage.getItem("tempTaskCreate");
                  let _selectedPhongBan = -1;
                  if (tempTaskCreate === null || tempTaskCreate === undefined) {
                  } else {
                    _selectedPhongBan =
                      JSON.parse(tempTaskCreate)?.selectedPhongBan;
                  }

                  if (
                    defaulPhongBan &&
                    (!_selectedPhongBan || _selectedPhongBan == -1)
                  ) {
                    // setSelectedPhongBan(defaulPhongBan);
                    setSelectedNguoiNhanList(
                      _onConvertToSelect(
                        res?.data?.Data?.NguoiNhan?.filter((x: any) =>
                          x.IdPhongBan.split(",").includes(
                            defaulPhongBan?.IdPhongBan.toString()
                          )
                        ).filter((x: any) => x.Id !== userData?.Data[0]?.Id),
                        "FullName",
                        "Id",
                        "ColorText"
                      )
                    );
                    setUserListForSelect(
                      _onConvertToSelect(
                        res?.data?.Data?.NguoiNhan?.filter((x: any) =>
                          x.IdPhongBan.split(",").includes(
                            defaulPhongBan?.IdPhongBan.toString()
                          )
                        ).filter((x: any) => x.Id !== userData?.Data[0]?.Id),
                        "FullName",
                        "Id",
                        "ColorText"
                      )
                    );
                  } else {
                    setSelectedPhongBan(_selectedPhongBan);
                  }
                  setUserList(res?.data?.Data?.NguoiNhan);
                }

                setPhongBanDanhGiaList(organizationTrees);
                setUserDanhGiaList(res.data.Data.NguoiNhan);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (loaiViecList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_LOAIVIEC";

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setLoaiViecList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (nguonVanBanList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_NGUONVANBAN";

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setNguonVanBanList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (uuTienList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setUuTienList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doMatList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDoMatList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (dauViecList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(READ_TASK_LIST_BY_USER, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDauViecList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        const takeData2 = async () => {
          try {
            await ServiceUtils.getV2(READ_ALL_TASK_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDauViecList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        if (
          userData?.Data[0]?.IsActiveOffice &&
          !takePermission(20).PerApprove
        ) {
          takeData2();
        } else {
          takeData();
        }
      }

      if (quyTrinhList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(READ_ALL_LIST_QUYTRINH_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setQuyTrinhList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doKhoList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(DIFICULT_LEVEL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              if (res.status === 200) {
                setDoKhoList(res.data.Data);
              }
            });
          } catch (error: any) { }
        };

        takeData();
      }
    }
  }, []);

  useEffect(() => {
    const tempTaskCreate = localStorage.getItem("tempTaskCreate");

    if (tempTaskCreate === null || tempTaskCreate === undefined) {
    } else {
      /*
        - selectedDauViec
        - taskName
        - selectedLoaiViec
        - selectedDoMat
        - deadLine
        - selectedNguonVanBan
        - selectedUuTien
        - selectedDoKho
        - selectedPhongBan
        - selectedNguoiNhan
        - isChooseTaskReviewer
        - selectedPhongBanDanhGia
        - selectedNguoiDanhGia
        - selectedQuyTrinh
        - isForce
        - selectedBuocList
        - selectedKetquaList
        - description
        - notes
        - selectedNguoiNhanList
        - selectedNguoiPhoiHopList
        - selectedNguoiLamViecList
        - dauViecList,
        - quyTrinhList
      */

      const tempObject = JSON.parse(tempTaskCreate);

      setSelectedDauViec(tempObject?.selectedDauViec);
      setTaskName(tempObject?.taskName);
      setSelectedLoaiViec(tempObject?.selectedLoaiViec);
      setSelectedDoMat(tempObject?.selectedDoMat);
      // setDeadLine(tempObject?.deadLine);
      setIsExChange(tempObject?.isExchange);
      setIsEvaluate(tempObject?.isEvaluate);
      setSelectedNguonVanBan(tempObject?.selectedNguonVanBan);
      setSelectedUuTien(tempObject?.selectedUuTien);
      setSelectedDoKho(tempObject?.selectedDoKho);
      setSelectedPhongBan(tempObject?.selectedPhongBan);
      setSelectedNguoiNhan(tempObject?.selectedNguoiNhan);
      setIsChooseTaskReviewer(tempObject?.isChooseTaskReviewer);
      setSelectedPhongBanDanhGia(tempObject?.selectedPhongBanDanhGia);
      setSelectedNguoiDanhGia(tempObject?.selectedNguoiDanhGia);
      setSelectedQuyTrinh(tempObject?.selectedQuyTrinh);

      setIsForce(tempObject?.isForce);
      setSelectedBuocList(tempObject?.selectedBuocList);
      setSelectedKetQuaList(tempObject?.selectedKetQuaList);
      setDescription(tempObject?.description);
      setNotes(tempObject?.notes);
      setSelectedNguoiNhanList(tempObject?.selectedNguoiNhanList);
      setSelectedNguoiPhoiHopList(tempObject?.selectedNguoiPhoiHopList);
      setSelectedNguoiLamViecList(tempObject?.selectedNguoiLamViecList);
      setSelectedNguoiGopYList(tempObject?.selectedNguoiGopYList);
      setQuyTrinhList(tempObject?.quyTrinhList);
      setDauViecList(tempObject?.dauViecList);
      setUseHours(tempObject?.useHours);
      setUseMinutes(tempObject?.useMinutes);
      setUseHourSupporter(tempObject?.useHourSupporter);
      setUseMinutesSupporter(tempObject?.useMinutesSupporter);
      setIsMulti(tempObject?.isMulti);
      setListMultiUser(tempObject?.listMultiUser);
      setIsChooseTaskReviewer(tempObject?.isChooseTaskReviewer);
      setIsAllUser(tempObject?.isAllUser);
    }
  }, []);

  const _onCheckValid = (): boolean => {
    if (
      selectedBuocList === null ||
      selectedKetQuaList === null ||
      selectedQuyTrinh === -1
    ) {
      return true;
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          return false;
        }
      }
      return true;
    }
  };

  const _onCheckValidForHoursUsed_Label = (): boolean => {
    if (isAutoRequiredUseHours && !takePermission(20).PerApprove) {
      return false;
    } else if (isAutoRequiredUseHours && takePermission(20).PerApprove) {
      return true;
    } else {
      return false;
    }
  };

  const _onCheckValidForHoursUsed = (): boolean => {
    if (
      isAutoRequiredUseHours &&
      !takePermission(20).PerApprove &&
      (useHours ? useHours === 0 || !useHours : useMinutes === 0 || !useMinutes)
    ) {
      return true;
    } else if (
      isAutoRequiredUseHours &&
      takePermission(20).PerApprove &&
      (useHours ? useHours === 0 || !useHours : useMinutes === 0 || !useMinutes)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (selectedBuocList === null || selectedBuocList === undefined) {
      setIsSelectedBuocOK(false);
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === "" ||
          selectedBuocList[i].UseHours === "" ||
          selectedBuocList[i].UseMinutes === "" ||
          selectedBuocList[i].KetQua === ""
        ) {
          setIsSelectedBuocOK(false);
          break;
        } else {
          setIsSelectedBuocOK(true);
        }
      }
    }
  }, [selectedBuocList]);

  // Preprocessing
  const tempQuyTrinhList = quyTrinhList?.map((item) => {
    return { ...item, value: item?.tenquytrinh, label: item?.tenquytrinh };
  });
  const tempDauViecList = dauViecList?.map((item) => {
    return { ...item, value: item?.Tendauviec, label: item?.Tendauviec };
  });

  const convertToPercent = (total: any, param: any) => {
    let value = 0;
    if (param && total) {
      value = (param / total) * 100;
    }
    return value.toFixed(2);
  };

  const _onChangeQuytrinhList = (data: any) => {
    if (data === null) {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item?.id === parseInt(data.id))
      );

      const takeStep = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_STEP_DROPDOWN + data.id, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            let tempArray: any[] = [];

            res.data.Data.forEach((item: any, index: any) => {
              const body = {
                StepName: item?.tenbuoc,
                UserDo: "",
                DeadLine_Step: "",
                UseHours: "",
                UseMinutes: "",
                KetQua: "",
                SortOrder: item?.orderBy,
              };
              tempArray.push(body);
            });

            setSelectedBuocList(tempArray);
          });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_RESULT_DROPDOWN + data.id, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            setSelectedKetQuaList(res.data.Data);
          });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  const _onChangeDauViecList = (_data: any) => {
    if (_data === null) {
      setSelectedDauViec(-1);
    } else {
      setSelectedDauViec(
        dauViecList?.find((item: any) => item?.Id === _data?.Id)
      );
    }
  };

  const _onChangeDauViecList2 = (_data: any) => {
    setSelectedDauViec(_data);
    // console.log(_data)
  };

  const getUnique = (arr: any[], index: any) => {
    if (arr.length == 0) {
      return [];
    }
    const unique = arr
      ?.map((e) => e[index])

      // store the keys of the unique objects
      ?.map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      ?.map((e: any) => arr[e]);

    return unique;
  };

  const _onChangeListMultiUser = (type: number, e: any) => {
    const _listMultiUser = [...listMultiUser];

    if (type === 1) {
      userListForSelect?.map((item: any) => {
        if (
          item &&
          e.value &&
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == e.value)
        ) {
          if (item?.Id !== userData?.Data[0]?.Id) {
            _listMultiUser.push(item);
          }
        }
      });

      setListMultiUser(getUnique(_listMultiUser, "value"));
    } else if (type === 2 || type === 3) {
      if (e?.Id !== userData?.Data[0]?.Id) {
        _listMultiUser.push(e);
      }
      setListMultiUser(getUnique(_listMultiUser, "value"));
    } else if (type === 4) {
      setListMultiUser(e);
    } else if (type === 5) {
      // setListMultiUser(e)
      setIsAllUser((item: any) => !item);
    }
  };

  useEffect(() => {
    let _listMultiUser: any[] = [];
    _listMultiUser = [...listMultiUser];
    if (isAllUser) {
      userListForSelect?.map((item: any) => {
        if (item?.Id !== userData?.Data[0]?.Id) {
          _listMultiUser.push(item);
        }
      });
    } else {
      _listMultiUser = [];
    }
    setListMultiUser(getUnique(_listMultiUser, "value"));
  }, [isAllUser]);

  // funcs
  const _onChangeWorkerViaDepart = (data: any) => {
    if (data === null) {
      setSelectedNguoiPhoiHopList(
        userList?.filter(
          (item: any) => item?.Id !== selectedNguoiLamViecList[0]?.Id
        )
      );
    } else {
      setSelectedNguoiPhoiHopList(
        userList?.filter(
          (item: any) =>
            (item?.IdPhongBan || "")
              .toString()
              .split(",")
              .find((p: any) => p == data.ParameterId) &&
            item?.Id !== selectedNguoiLamViecList[0].Id
        )
      );
    }
  };

  const _onCheckWeek = (nguoinhan: any) => {
    let percen = 0;
    let _string: any = "";
    let hoursWeek = "";
    let hoursWeek_Total = "";

    if (nguoinhan?.currentNumberWeek == 1) {
      percen = (nguoinhan?.Week1_Hours / nguoinhan?.Week1_Total_Hours) * 100;
      hoursWeek = "Week1_Hours";
      hoursWeek_Total = "Week1_Total_Hours";
    } else if (nguoinhan?.currentNumberWeek == 2) {
      percen = (nguoinhan?.Week2_Hours / nguoinhan?.Week2_Total_Hours) * 100;
      hoursWeek = "Week2_Hours";
      hoursWeek_Total = "Week2_Total_Hours";
    }
    if (nguoinhan?.currentNumberWeek == 3) {
      percen = (nguoinhan?.Week3_Hours / nguoinhan?.Week3_Total_Hours) * 100;
      hoursWeek = "Week3_Hours";
      hoursWeek_Total = "Week3_Total_Hours";
    }
    if (nguoinhan?.currentNumberWeek == 4) {
      percen = (nguoinhan?.Week4_Hours / nguoinhan?.Week4_Total_Hours) * 100;
      hoursWeek = "Week4_Hours";
      hoursWeek_Total = "Week4_Total_Hours";
    }
    if (nguoinhan?.currentNumberWeek == 5) {
      percen = (nguoinhan?.Week5_Hours / nguoinhan?.Week5_Total_Hours) * 100;
      hoursWeek = "Week5_Hours";
      hoursWeek_Total = "Week5_Total_Hours";
    }

    _string = ` ${percen}% (${nguoinhan[hoursWeek]}/${nguoinhan[hoursWeek_Total]} giờ) thời gian làm việc của tuần và `;

    return _string;
  };

  // reset filter
  const resetFilter = () => {
    setSelectedNguoiPhoiHopList(
      userList?.filter(
        (item: any) => item?.Id !== selectedNguoiLamViecList[0]?.Id
      )
    );
  };

  const _handleSubmitChooseTaskToBeInsert = (id: any, name: any) => {
    setTaskToBeInsertID(id);
    setTaskToBeInsertName(name);
  }

  const tempSuggestList = useMemo(() => {
    return suggestList?.filter((item) => item.NameSuggest.includes(suggest));
  }, [suggest]);

  const isOkWithHourSupporter = useMemo(() => {
    if (isAutoRequiredUseHours === true) {
      if (selectedNguoiLamViecList.length === 0) {
        return true;
      } else if (selectedNguoiLamViecList.length !== 0) {
        for (let i = 0; i < useHourSupporter?.length; i++) {
          if (
            (useHourSupporter[i].value === 0 &&
              useMinutesSupporter[i].value === 0) ||
            (useHourSupporter[i].value === "0" &&
              useMinutesSupporter[i].value === "0") ||
            isNaN(useHourSupporter[i].value) === true ||
            isNaN(useMinutesSupporter[i].value) === true
          ) {
            return false;
          }
        }
        // for (let i = 0; i < useMinutesSupporter?.length; i++) {
        //   if (
        //     useMinutesSupporter[i].value === 0 ||
        //     useMinutesSupporter[i].value === "0" ||
        //     isNaN(useMinutesSupporter[i].value) === true
        //   ) {
        //     return false;
        //   }
        // }
      }
    } else {
      return true;
    }
  }, [selectedNguoiLamViecList, useHourSupporter, useMinutesSupporter]);

  // JSX
  const DauMucViecJSX = () => {
    return (
      <div className="w-full h-max">
        <label>
          Đầu mục&nbsp;
          {takePermission(20).PerApprove === true && (
            <span className="text-red-500">(*)</span>
          )}
        </label>

        <Select
          ref={selectDauViec}
          className="z-[55]"
          options={tempDauViecList}
          value={selectedDauViec}
          onChange={(data: any) => _onChangeDauViecList2(data)}
          isClearable
          isMulti
          placeholder="--- Chọn đầu mục ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        // defaultValue={tempDauViecList?.find(
        //   (item) => item?.Id === selectedDauViec?.Id
        // )}
        />
      </div>
    );
  };

  const [isProtractedTask, setIsProtractedTask] = useState<boolean>(false);
  const [taskTimeUsed, setTaskTimeUsed] = useState<any>([]);
  const [taskTimeUsed2, setTaskTimeUsed2] = useState<any>([]);
  const [isDeleteSupport, setIsDeleteSupport] = useState<boolean>(false);


  useEffect(() => {
    if (deadLine === null || deadLine === "") return;

    let currentDate: Date;

    if (assignmentDate != null && assignmentDate !== "") {
      currentDate = new Date(assignmentDate);
    } else {
      currentDate = new Date();
    }
    let currentMonth = currentDate.getMonth(); // Tháng hiện tại
    let currentYear = currentDate.getFullYear(); // Năm hiện tại

    const selectedDate = new Date(deadLine);
    let selectedMonth = selectedDate.getMonth(); // Tháng được chọn
    let selectedYear = selectedDate.getFullYear(); // Năm được chọn

    // Kiểm tra nếu tháng và năm được chọn lớn hơn tháng và năm hiện tại
    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth)
    ) {
      let listTimeUse = [];
      setIsProtractedTask(true);
      while (
        currentYear < selectedYear ||
        (currentYear === selectedYear && currentMonth <= selectedMonth)
      ) {
        listTimeUse.push({
          TaskId: "",
          UserID: selectedNguoiNhan?.Id || "",
          Month: currentMonth + 1,
          Year: currentYear,
          HoursUsed: 0,
          MinutesUsed: 0,
          index: 0,
        });
        currentMonth++;
        if (currentMonth > 12) {
          currentMonth = 1;
          currentYear++;
        }
      }

      setTaskTimeUsed2(listTimeUse);
      setTaskTimeUsed(listTimeUse);
    } else {
      setIsProtractedTask(false);
    }
  }, [assignmentDate, deadLine]);

  const AssignmentDateJSX = () => {
    return (
      <DateTimePickerControl
        className={"width-100-percent padding-right-0"}
        customLabelHTML={"<p>Thời gian bắt đầu</p>"}
        value={assignmentDate || (new Date()).toString()}
        onChange={(e: any) => {
          const value = e;
          _onChangeAssignmentDate(value);
        }}
      />
    );
  };

  const DeadLineJSX = () => {
    return (
      <DateTimePickerControl
        className={"width-100-percent padding-right-0"}
        customLabelHTML={
          takePermission(20).PerApprove
            ? '<p>Thời hạn hoàn thành <span class="text-red-500">(*)</span></p>'
            : "<p>Thời hạn hoàn thành</p>"
        }
        value={deadLine}
        onChange={(e: any) => {
          const value = e;
          _onChangeDeadLine2(value);
        }}
      />
    );
  };

  useEffect(() => {
    const updateData = [...taskTimeUsed]?.map((i) => {
      if (i.index === -1) {
        return { ...i, UserID: selectedNguoiNhan?.Id || "" };
      }
      return i;
    });
    setTaskTimeUsed(updateData);
  }, [selectedNguoiNhan]);

  useEffect(() => {
    if (!isDeleteSupport) {
      let listTimeUse: any = [];
      // let
      selectedNguoiLamViecList?.map((i1: any, index: number) =>
        taskTimeUsed?.map((i2: any) =>
          listTimeUse.push({
            TaskId: "",
            UserID: i1?.Id || "",
            Month: i2.Month,
            Year: i2.Year,
            HoursUsed: 0,
            MinutesUsed: 0,
            index: index,
          })
        )
      );

      for (const item of taskTimeUsed2) {
        let found = false;
        for (const existingItem of listTimeUse) {
          if (
            item.index === existingItem.index &&
            item.Month === existingItem.Month &&
            item.Year === existingItem.Year
          ) {
            existingItem.HoursUsed += item.HoursUsed;
            existingItem.MinutesUsed += item.MinutesUsed;
            found = true;
            break;
          }
        }
        if (!found) {
          listTimeUse.push(item);
        }
      }
      setTaskTimeUsed2(listTimeUse);
    } else {
      setIsDeleteSupport(false);
    }
  }, [selectedNguoiLamViecList]);

  // useEffect(() => {
  //   // const updateData = [...taskTimeUsed]?.map((i) => {
  //   //   if (i.index === -1) {
  //   //     return { ...i, UserID: selectedNguoiNhan?.Id || "" };
  //   //   }
  //   //   return i;
  //   // });
  //   // setTaskTimeUsed(updateData);
  // }, [selectedNguoiLamViecList]);

  const _onChangeTaskTimeUsed = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    isHoursUsed: boolean,
    subIndex: number
  ) => {
    let newTaskTimeUsed = [...taskTimeUsed2];
    let itemCount = 0;

    const updatedData = newTaskTimeUsed?.map((item) => {
      if (item.index === index) {
        itemCount++;
        if (itemCount === subIndex + 1) {
          const updatedHoursUsed = isHoursUsed
            ? (e.target.value || 0)
            : (item.HoursUsed || 0);
          const updatedMinutesUsed = !isHoursUsed
            ? (e.target.value || 0)
            : (item.MinutesUsed || 0);
          return {
            ...item,
            HoursUsed: parseInt(updatedHoursUsed, 10),
            MinutesUsed: parseInt(updatedMinutesUsed, 10),
          };
        }
      }
    });
    const mergedArray = newTaskTimeUsed?.map((targetObj) => {
      const sourceObj = updatedData.find(
        (obj) =>
          obj &&
          obj.UserID === targetObj.UserID &&
          obj.Month === targetObj.Month &&
          obj.Year === targetObj.Year
      );
      if (sourceObj) {
        return { ...targetObj, ...sourceObj };
      }
      return targetObj;
    });
    setTaskTimeUsed2(mergedArray);
  };


  const getValueTime = (
    index: number,
    isHoursUsed: boolean,
    subIndex: number
  ): number => {
    let itemCount = 0;
    const targetItem = taskTimeUsed2.find((item: any) => {
      if (item.index === index) {
        itemCount++;
        return itemCount === subIndex + 1;
      }
      return false;
    });

    if (targetItem) {
      return isHoursUsed ? targetItem.HoursUsed : targetItem.MinutesUsed;
    }

    return 0;
  };
  // const _onChangeTaskTimeUsed = (e: any, index: number, type: boolean, indexSub:number) => {
  //   let newTaskTimeUsed = [...taskTimeUsed2];
  //   console.log("newTaskTimeUsed, ", newTaskTimeUsed);
  //   console.log("index, ", index);
  //   console.log("type, ", type);
  //   console.log("indexSub, ", indexSub);

  //   let subItemIndex = 0;
  //   const updatedData = newTaskTimeUsed?.map((item) => {
  //     if (item.index == index) {
  //       subItemIndex++
  //       // const updatedSubItems = item.subItems??.map((subItem:any) => {
  //       //   if (subItemIndex === indexSub) {
  //       //     const updatedValue = isHoursUsed ? e.target.value : subItem.MinutesUsed;
  //       //     const updatedHoursUsed = isHoursUsed ? updatedValue : subItem.HoursUsed;
  //       //     const updatedMinutesUsed = !isHoursUsed ? updatedValue : subItem.MinutesUsed;
  //       //     return { ...subItem, HoursUsed: updatedHoursUsed, MinutesUsed: updatedMinutesUsed };
  //       //   }
  //       //   subItemIndex++;
  //       //   return subItem;
  //       // }) || [];

  //       // return { ...item, subItems: updatedSubItems };
  //     }
  //     return item;
  //   });
  //   console.log("updateData, ",updatedData);

  //   setTaskTimeUsed2(updatedData);
  // };

  // console.log("taskTimeUsed, ", taskTimeUsed);

  const QuytrinhJSX = () => {
    return (
      <>
        <div
          className="w-full h-max justify-start items-center flex space-x-3"
          style={{
            marginBottom: "5px",
          }}
        >
          <label>Quy trình&nbsp;(nếu có)</label>
          <button
            className="p-2 bg-primary rounded-md text-white hover:bg-sky-400"
            onClick={() => {
              setFuncsSub("createWorkflowSub");
              handleOpenSub();
            }}
          >
            Tạo mới quy trình
          </button>
          <div
            className={`
                    ${menu?.PerInsert === true ? "flex" : "hidden"}
                    w-max h-max
                  `}
          >
            {/* <button className="text-xs pl-1.5 pr-1.5 pt-0.5 pb-0.5 bg-primary
                      text-white rounded-md hover:bg-sky-400
                    "
                      onClick={() => {
                        setFuncsSub("createWorkflowSub")
                        handleOpenSub()
                      }}
                    > 
                      <p>Tạo quy trình</p>
                    </button> */}
          </div>
        </div>
        <Select
          className=""
          options={tempQuyTrinhList}
          onChange={(data: any) => _onChangeQuytrinhList(data)}
          isClearable
          placeholder="--- Chọn quy trình ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          defaultValue={tempQuyTrinhList?.find(
            (item) => item?.id === selectedQuyTrinh?.id
          )}
        />
      </>
    );
  };

  const StepListJSX = () => {
    return (
      <>
        {/* Web */}
        <div
          style={{
            zIndex: 0,
          }}
          className={`w-full tableFixHead
          ${selectedBuocList === null || selectedBuocList === undefined
              ? "lg:flex flex-col hidden"
              : "lg:flex flex-col hidden"
            }
        `}
        >
          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300 lg:w-[20px]">Bước</th>

                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300 lg:w-[225px]">
                  Người thực hiện
                </th>
                <th className="border border-slate-300 lg:w-[225px]">
                  Thời hạn
                </th>
                <th className="border border-slate-300 lg:w-[90px]">
                  Số giờ thực hiện
                </th>
                <th className="border border-slate-300 lg:w-[90px]">
                  Số phút thực hiện
                </th>
                <th className="border border-slate-300 lg:w-[225px]">
                  Kết quả cần đạt
                </th>

                <th className="border border-slate-300 w-[1rem]"></th>
              </tr>
            </thead>

            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null || selectedBuocList === undefined
                ? ""
                : selectedBuocList
                  .sort((a: any, b: any) => {
                    return a.SortOrder - b.SortOrder;
                  })
                  ?.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="text-center border border-slate-300 lg:w-[20px]">
                        <p>{item?.SortOrder}</p>
                      </td>
                      <td className="border border-slate-300">
                        <p>{item?.StepName}</p>
                      </td>
                      <td className="text-center border border-slate-300 lg:w-[225px]">
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          value={item?.UserDo}
                          onChange={(e: any) => {
                            e.preventDefault();
                            _onChangeNguoiPhoiHopEachStep(
                              item?.SortOrder,
                              e.target.value
                            );
                          }}
                        >
                          <option
                            className="text-gray-300"
                            value={""}
                            disabled
                          >
                            --- Chọn người phối hợp ---
                          </option>
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                              (item: any, index: any) => (
                                <option key={index} value={item?.Id}>
                                  {item?.FullName}
                                </option>
                              )
                            )}
                        </select>
                      </td>
                      <td className="text-center border border-slate-300 lg:w-[225px]">
                        <DateTimePickerControl
                          className={"width-100-percent padding-right-0"}
                          value={item?.DeadLine_Step}
                          onChange={(e: any) => {
                            _onChangDeadLineStep(item?.SortOrder, e);
                            //           checkLastedDealine(e);
                            //         }}
                            //       />
                            //     </td>
                            //     <td className="text-center border border-slate-300 lg:w-[90px]">
                            //       <input
                            //         className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            //         type="text"
                            //         onTouchStart={(e: any) => onTouchForm(e)}
                            //         value={item.UseHours}
                            //         onChange={(e: any) => {
                            //           _onChangUseHoursStep(
                            //             item.SortOrder,
                            //             e.target.value
                            //           );
                            //         }}
                            //       />
                            //     </td>
                            //     <td className="text-center border border-slate-300 lg:w-[90px]">
                            //       <input
                            //         className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            //         type="text"
                            //         onTouchStart={(e: any) => onTouchForm(e)}
                            //         // value={item.UseMinutes}
                            //         // onChange={(e: any) => {
                            //         //   console.log("UseMinutes, ", e.target.value);
                            //         //   _onChangUseMinutesStep(
                            //         //     item.SortOrder,
                            //         //     e.target.value
                            //         //   );
                            //         // }}
                            //       />
                            //     </td>
                            //     <td className="text-center border border-slate-300 lg:w-[200px]">
                            //       <textarea
                            //         className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            //         onTouchStart={(e: any) => onTouchForm(e)}
                            //         // value={item.KetQua}
                            //         // onChange={(e: any) => {
                            //         //   console.log("KetQua, ", e.target.value);
                            //         //   _onChangKetQuaStep(
                            //         //     item.SortOrder,
                            //         //     e.target.value
                            //         //   );
                            //         // }}
                            //       />
                            //     </td>
                            //     <td className="border border-slate-300">
                            //       <button
                            //         className="text-red-500 w-1/6"
                            //         onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                            //       >
                            //         <DeleteTwoToneIcon fontSize="small" />
                            //       </button>
                            //     </td>
                            //   </tr>
                            // ))}
                            checkLastedDealine(e);
                          }}
                        />
                      </td>
                      <td className="text-center border border-slate-300 lg:w-[90px]">
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          type="number"
                          // onTouchStart={(e: any) => onTouchForm(e)}
                          value={item?.UseHours}
                          onChange={(e: any) =>
                            _onChangUseHoursStep(item?.SortOrder, e)
                          }
                        />
                      </td>
                      <td className="text-center border border-slate-300 lg:w-[90px]">
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          type="text"
                          onTouchStart={(e: any) => onTouchForm(e)}
                        // value={item.UseMinutes}
                        // onChange={(e: any) => {
                        //   console.log("UseMinutes, ", e.target.value);
                        //   _onChangUseMinutesStep(
                        //     item.SortOrder,
                        //     e.target.value
                        //   );
                        // }}
                        />
                      </td>
                      <td className="text-center border border-slate-300 lg:w-[200px]">
                        <textarea
                          className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          onTouchStart={(e: any) => onTouchForm(e)}
                        // value={item.KetQua}
                        // onChange={(e: any) => {
                        //   console.log("KetQua, ", e.target.value);
                        //   _onChangKetQuaStep(
                        //     item.SortOrder,
                        //     e.target.value
                        //   );
                        // }}
                        />
                      </td>
                      <td className="border border-slate-300">
                        <button
                          className="text-red-500 w-1/6"
                          onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>

          <div className="my-4 w-full h-max justify-end items-end flex">
            <button
              className="
              bg-emerald-500 p-1.5 text-white rounded-md
              hover:bg-emerald-400
            "
              onClick={() => _onClickEditBuoc("editBuoc")}
            >
              Thêm bước
            </button>
          </div>
        </div>

        {/* Phone */}
        <div
          className={`w-full h-max tableFixHead ${selectedBuocList === null || selectedBuocList === undefined
            ? "flex lg:hidden flex-col"
            : "flex lg:hidden flex-col"
            }`}
        >
          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300 w-[1rem]">
                  {/* <button
                    className="
                              flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                              rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                            "
                    onClick={() => _onClickEditBuoc("editBuoc")}
                  >
                    +
                  </button> */}
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null || selectedBuocList === undefined
                ? ""
                : selectedBuocList?.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="flex flex-col border border-slate-300 space-y-2">
                      <p className="font-bold">
                        Bước&nbsp;{item?.SortOrder}:
                      </p>
                      <p>{item?.StepName}</p>
                      <select
                        className="lg:w-full sm:w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={item?.UserDo}
                        onChange={(e: any) => {
                          e.preventDefault();
                          _onChangeNguoiPhoiHopEachStep(
                            item?.SortOrder,
                            e.target.value
                          );
                        }}
                      >
                        <option className="text-gray-300" value={""} disabled>
                          --- Chọn người thực hiện ---
                        </option>
                        {selectedNguoiLamViecList === null
                          ? ""
                          : selectedNguoiLamViecList?.map(
                            (item: any, index: any) => (
                              <option key={index} value={item?.Id}>
                                {item?.FullName}
                              </option>
                            )
                          )}
                      </select>
                      <DateTimePickerControl
                        className={"width-100-percent padding-right-0"}
                        value={item?.DeadLine_Step}
                        onChange={(e: any) => {
                          _onChangDeadLineStep(item?.SortOrder, e);
                        }}
                      />
                    </td>
                    <td className="border border-slate-300">
                      <button
                        className="text-red-500 w-1/6"
                        onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="my-4 w-full h-max justify-end items-end flex">
            <button
              className="
              bg-emerald-500 p-1.5 text-white rounded-md
              hover:bg-emerald-400
            "
              onClick={() => _onClickEditBuoc("editBuoc")}
            >
              Thêm bước
            </button>
          </div>
        </div>
      </>
    );
  };

  const checkLastedDealine = (e: any) => {
    let lastedDateLine = "";
    selectedBuocList?.map((item: any) => {
      if (item.DeadLine_Step > lastedDateLine) {
        lastedDateLine = item.DeadLine_Step;
      }
    });
    if (e > lastedDateLine) {
      lastedDateLine = e;
    }
    setDeadLine(lastedDateLine);
  };

  const _onChangeHoursSupporter = (e: any, id: any) => {
    const tempArray = useHourSupporter?.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          value: parseInt(e.target.value),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setUseHourSupporter(tempArray);
  };

  const _onChangeMinutesSupporter = (e: any, id: any) => {
    const tempArray = useMinutesSupporter?.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          value: parseInt(e.target.value),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setUseMinutesSupporter(tempArray);
  };

  const _onClickSwitchToBoss = () => {
    setFuncsSub("switchBoss");
    handleOpenSub();
  };

  const isMobile = DeviceUtils.isMobile();

  const mergeTimeData = (
    hoursUsed: any,
    hoursUsedSupporter: any,
    minutesUsed: any,
    minutesUsedSupporter: any
  ) => {
    const result = [];

    if (hoursUsed !== undefined && minutesUsed !== undefined) {
      result.push({
        Hours: parseInt(hoursUsed, 10),
        Minutes: parseInt(minutesUsed, 10),
      });
    }

    if (
      hoursUsedSupporter !== undefined &&
      minutesUsedSupporter !== undefined
    ) {
      const hoursArray = hoursUsedSupporter.map((item: any) => item.value);
      const minutesArray = minutesUsedSupporter.map((item: any) => item.value);

      for (let i = 0; i < hoursArray.length; i++) {
        const hours = hoursArray[i];
        const minutes = minutesArray[i];
        result.push({
          Hours: parseInt(hours, 10),
          Minutes: parseInt(minutes, 10),
        });
      }
    }

    return result;
  };
  function convertToHoursAndMinutes(
    sumHours: number,
    sumMinutes: number
  ): { hours: number; minutes: number } {
    const hours = Math.floor(sumMinutes / 60) + sumHours;
    const minutes = sumMinutes % 60;

    return { hours, minutes };
  }

  const isOKTotalTimeByIndex = (index: number) => {
    const mergedTimeData = mergeTimeData(
      useHours || 0,
      useHourSupporter || [],
      useMinutes || 0,
      useMinutesSupporter || []
    );

    let sumHours = 0;
    let sumMinutes = 0;

    taskTimeUsed2.map((item: any, i: number) => {
      if (item.index === index) {
        sumHours += (item?.HoursUsed || 0);
        sumMinutes += (item?.MinutesUsed || 0);
      }
    });
    // const { hours, minutes } = convertToHoursAndMinutes(sumHours, sumMinutes);
    // console.log(mergedTimeData);

    // const useHours_: number = parseInt(((useHours || 0) * 60).toString()) + parseInt((useMinutes || 0).toString());

    // if (useHours_ == (sumHours * 60 + sumMinutes)) {
    //   return true;
    // }


    if (
      parseInt(mergedTimeData[index]?.Hours.toString()) * 60 + parseInt(mergedTimeData[index]?.Minutes.toString()) === parseInt((sumHours * 60 + sumMinutes).toString())
    )
      return true;

    return false;
  };
  const isOKTotalTime = () => {
    if (isProtractedTask) {
      for (let i = 0; i < selectedNguoiLamViecList.length; i++) {
        const btrue = isOKTotalTimeByIndex(i);
        if (btrue === false) return false;
      }
    }

    return true;
  };


  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[80rem] w-screen lg:h-[40rem] h-[calc(var(--view-height) - 5.2rem)]
      rounded-md
    "
      style={{
        height: isMobile
          ? "calc(var(--view-height) - 2.7rem)"
          : "calc(var(--view-height) - 8rem)",
      }}
    >
      <SubModal
        bodyAdding={bodyAdding}
        _onChangeWorkerViaDepart={_onChangeWorkerViaDepart}
        resetFilter={resetFilter}
        _onCheckNguoiGopY={_onCheckNguoiGopY}
        open={openSub}
        handleClose={handleCloseSub}
        _onCheckNguoiPhoiHop={_onCheckNguoiLamViec}
        selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
        selectedNguoiLamViecList={selectedNguoiLamViecList}
        selectedNguoiGopYList={selectedNguoiGopYList}
        selectedBuocList={selectedBuocList}
        positionStep={positionStep}
        hourStep={hourStep}
        contentStep={contentStep}
        _onChangePositionStep={_onChangePositionStep}
        _onChangeHourStep={_onChangeHourStep}
        _onChangeContentStep={_onChangeContentStep}
        _onPushStep={_onPushStep}
        _onDeleteQuyTrinh={_onDeleteQuyTrinh}
        _onChangeNguoiPhoiHopEachStep={_onChangeNguoiPhoiHopEachStep}
        selectedKetQuaList={selectedKetQuaList}
        ketqua={ketqua}
        _onPushKetqua={_onPushKetqua}
        _onChangeKetqua={_onChangeKetqua}
        _onDeleteKetqua={_onDeleteKetqua}
        _onChangeStepList={_onChangeStepList}
        funcs={funcsSub}
        _onClickUpdate={() => { }}
        _onClickSwitchToBoss={_onClickSubmit}
        setIsDeleteSupport={setIsDeleteSupport}
        UserId={selectedNguoiNhan?.Id}
        _handleSubmitChooseTaskToBeInsert={_handleSubmitChooseTaskToBeInsert}
        taskToBeInsertID={taskToBeInsertID}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tạo công việc mới</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={_onClickCaching}
        >
          <CloseIcon />
        </button>
      </div>
      {/* content */}
      {phongBanList === null ||
        userList === null ||
        loaiViecList === null ||
        nguonVanBanList === null ||
        uuTienList === null ||
        doMatList === null ||
        dauViecList === null ||
        quyTrinhList === null ||
        doKhoList === null ||
        isAutoSecurity === null ||
        isAutoForce === null ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tải biểu mẫu</p>
        </div>
      ) : isLoading === true ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tạo công việc mới</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          <div className="w-full h-max flex justify-between items-center gap-2">
            <p className="font-semibold text-red-500">
              Lưu ý: Việc đã được giao thì không được phép chỉnh sửa thông tin
            </p>
            <button
              className="w-[80px] h-max rounded-md p-2 bg-slate-300 font-bold hover:bg-slate-200"
              onClick={() => {
                localStorage.removeItem("tempTaskCreate");
                window.location.reload();
              }}
              style={{
                minWidth: "60px",
              }}
            >
              Tải lại
            </button>
          </div>

          <>
            {/* Web detail */}
            <div className="hidden lg:flex w-full h-max flex-col mt-4 text-sm space-y-2 z-[70]">
              {/* <div className="w-full h-max">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>

                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="--- Chọn đầu mục ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempDauViecList?.find(
                    (item) => item?.Id === selectedDauViec?.Id
                  )}
                />
              </div> */}

              <DauMucViecJSX />

              {/* <div className="w-full h-max relative pb-16 z-[60]">
                <div className="w-full h-max absolute">
                  <label>
                    Gợi ý&nbsp;<span className="text-red-500">(*)</span>
                  </label>

                  <div className="w-full h-max inline-block space-y-1.5">
                    <input
                      className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập gợi ý"
                      value={suggest}
                      onChange={(e: any) => {
                        e.preventDefault()
                        setSuggest(e.target.value)
                      }}
                      onClick={() => {
                        setIsShowSuggest(true)
                      }}
                    />

                    <div
                      className={`
                      w-full h-max max-h-96
                      ${isShowSuggest ? "block" : "hidden"} overflow-y-auto
                      p-2 rounded-sm bg-stone-50 drop-shadow-md
                    `}
                    >
                      {
                        tempSuggestList??.map((item: any, index: number) => (
                          <button key={index} className="w-full h-[30px] flex justify-start items-center hover:bg-slate-200 px-2"
                            onClick={() => {
                              setSuggest(item?.NameSuggest)
                              setIsShowSuggest(false)
                            }}
                          >
                            {item?.NameSuggest}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="w-full h-max">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <textarea
                  className="w-full h-[2.5rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="flex w-full space-x-4">
                <div className="w-full space-y-2">
                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Loại công việc/dịch vụ/tư vấn&nbsp;
                        {isAutoLoaiViec && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedLoaiViec === -1
                            ? -1
                            : selectedLoaiViec.ParameterId
                        }
                        onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn loại công việc ---
                        </option>
                        {loaiViecList === null ? (
                          <></>
                        ) : (
                          loaiViecList?.map((item: any, index: any) => (
                            <option key={index} value={item?.ParameterId}>
                              {item?.ParameterValue}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Cơ sở căn cứ bắt đầu công việc&nbsp;
                        {isAutoOrigin && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      {/* <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedNguonVanBan === -1
                            ? -1
                            : selectedNguonVanBan?.ParameterId
                        }
                        onChange={(e: any) => _onChangeNguonVanBan(e)}
                      >
                        <option value={-1} disabled>
                          --- Chọn căn cứ ---
                        </option>
                        {nguonVanBanList === null ? (
                          <></>
                        ) : (
                          nguonVanBanList?.map((item: any, index: any) => (
                            <option key={index} value={item?.ParameterId}>
                              {item?.ParameterValue}
                            </option>
                          ))
                        )}
                      </select> */}
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập căn cứ"
                        value={selectedNguonVanBan}
                        onChange={(e: any) => _onChangeNewNguonVanBan(e)}
                      />
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Độ mật&nbsp;
                        {isAutoSecurity && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : "font-bold"
                          }
                  `}
                        value={
                          selectedDoMat === -1 || selectedDoMat === null
                            ? -1
                            : selectedDoMat?.Id
                        }
                        onChange={(e: any) => _onChangeDoMat(e)}
                        style={{
                          color: `${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : selectedDoMat?.Color
                            }`,
                          borderColor: `${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : selectedDoMat?.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ mật ---
                        </option>
                        {doMatList === null ? (
                          <></>
                        ) : (
                          doMatList?.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.SecurityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Mức độ ưu tiên&nbsp;
                        {isAutoPriority && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                        value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                        onChange={(e: any) => _onChangeSelectedUuTien(e)}
                        style={{
                          color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                            }`,
                          borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn mức độ ---
                        </option>
                        {uuTienList === null ? (
                          <></>
                        ) : (
                          uuTienList?.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.PriorityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    {/* <div className="w-full space-y-1">
                      <label>
                        Thời hạn hoàn thành&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập tên công việc"
                        type="datetime-local"
                        value={deadLine}
                        onChange={(e: any) => _onChangeDeadLine(e)}
                      />
                    </div> */}

                    {/* <DateTimePickerControl
                      className={"width-100-percent padding-right-0"}
                      label={"Thời hạn hoàn thành (*)"}
                      value={deadLine}
                      onChange={(e: any) => {
                        const value = e;
                        _onChangeDeadLine2(value);
                      }}
                    /> */}
                    <AssignmentDateJSX />
                    <DeadLineJSX />
                  </div>
                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Độ khó&nbsp;
                        {isAutoDifference && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                        value={selectedDoKho === -1 ? -1 : selectedDoKho.Id}
                        onChange={(e: any) => _onChangeDoKho(e)}
                        style={{
                          color: `${selectedDoKho === -1 ? "" : selectedDoKho.Color
                            }`,
                          borderColor: `${selectedDoKho === -1 ? "" : selectedDoKho.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ khó ---
                        </option>
                        {doKhoList === null ? (
                          <></>
                        ) : (
                          doKhoList?.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.DifficultLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="w-full h-max">
                    <div className="w-full h-max">
                      {/* <label>Mã tham chiếu công việc</label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập mã"
                        value={refCode}
                        onChange={(e: any) => _onChangeRefCode(e)}
                      /> */}
                      <label className="w-full">
                        Thời gian hoàn thành&nbsp;
                        {_onCheckValidForHoursUsed_Label() === true && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      &nbsp;
                      {isProtractedTask && !isOKTotalTimeByIndex(0) ? (
                        <span className="font-medium text-red-500 text-xs">
                          Tổng thời hoàn thành phải bằng tổng thời hoàn thành
                          theo mỗi tháng.
                        </span>
                      ) : null}
                    </div>

                    <div className="w-full h-max flex gap-3">
                      {/* <div className="w-1/3 h-max">
                        <label>
                          Số ngày
                        </label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số ngày"
                          type="number"
                          value={useHours ? useHours : ""}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div> */}
                      <div className="w-full h-max ">
                        <label>Số giờ hoàn thành</label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số giờ"
                          type="number"
                          value={useHours ? useHours : ""}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div>
                      <div className="w-full h-max">
                        <label>Số phút hoàn thành</label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số phút"
                          type="number"
                          value={useMinutes ? useMinutes : ""}
                          onChange={(e: any) => _onChangeMinutes(e)}
                        />
                      </div>
                    </div>
                    {isProtractedTask && (
                      <div className="w-full h-max flex gap-3 mt-2">
                        <table className="w-full">
                          <thead className="text-white font-semibold text-center w-full h-[2rem]">
                            <tr>
                              <th className="border border-slate-300 text-left">
                                Tháng/Năm
                              </th>
                              <th className="border border-slate-300">
                                Số giờ hoàn thành
                              </th>
                              <th className="border border-slate-300">
                                Số phút hoàn thành
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                            {taskTimeUsed?.map(
                              (item: any, index: any) =>
                                item.index === 0 && (
                                  <tr
                                    key={index}
                                    className="w-max h-[3rem] bg-white"
                                  >
                                    <td className="text-left border border-slate-300">
                                      <div className="flex w-max h-max space-x-1 font-medium mr-2">
                                        <p>
                                          {item?.Month}/{item?.Year}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="text-center border border-slate-300">
                                      <input
                                        className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                        placeholder="Nhập số giờ"
                                        type="number"
                                        // value={item?.HoursUsed}
                                        value={getValueTime(0, true, index)}
                                        onChange={(e) =>
                                          _onChangeTaskTimeUsed(
                                            e,
                                            0,
                                            true,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="text-center border border-slate-300">
                                      <input
                                        className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                        placeholder="Nhập số phút"
                                        type="number"
                                        value={getValueTime(0, false, index)}
                                        onChange={(e) =>
                                          _onChangeTaskTimeUsed(
                                            e,
                                            0,
                                            false,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full space-y-2">

                  {props.isChiaViec == true ? (
                    <></>
                  ) : (
                    <div className="w-full">
                      <div className="flex w-full h-max">
                        {/* elemetns */}
                        <div className="flex flex-col w-1/2 space-x-3 space-y-4">
                          <div className={`flex-col w-full h-max space-y-1`}>
                            <div className={`w-max h-max flex flex-col`}>
                              <div className="w-max h-max flex space-x-2">
                                <input
                                  type="checkbox"
                                  className="w-5 h-5"
                                  checked={isMulti}
                                  onChange={_onChangeIsMulti}
                                  disabled={isInsertTask}
                                />
                                <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                                  Giao việc cho nhiều người
                                </label>
                              </div>

                              <p className="font-semibold text-red-500">
                                (Chức năng giao việc cho nhiều người không thể
                                chọn người hỗ trợ.)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isMulti && !props.isChiaViec === true ? (
                    <div className="flex space-x-2 items-top">
                      <div className="w-full space-y-2">
                        <div className="flex w-max h-max space-x-3">
                          <input
                            type="checkbox"
                            className="w-5 h-5"
                            checked={isAllUser}
                            onChange={(e: any) => _onChangeListMultiUser(5, e)}
                          />
                          <label className="text-slate-500 font-bold pl-2 pr-2">
                            Chọn toàn bộ
                          </label>
                        </div>
                        <label>Chọn nhanh cả phòng</label>
                        <Select
                          className="w-full z-[51]"
                          options={phongBanListForSelect}
                          value={null}
                          onChange={(e: any) => _onChangeListMultiUser(1, e)}
                          isClearable
                          placeholder="Chọn nhanh cả phòng"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label>Chọn từng người</label>
                        <Select
                          className="w-full z-50"
                          options={userListForSelect}
                          value={null}
                          onChange={(e: any) => _onChangeListMultiUser(2, e)}
                          placeholder="Chọn từng người"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                        {/* <div className="w-full space-y-1"> */}
                        <label>Chọn theo phòng ban</label>
                        {/* <div className="flex w-full space-x-3"> */}
                        {/* <div className="w-full space-y-1"> */}
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                          value={
                            phongBanSelectedForSelect === -1
                              ? -1
                              : phongBanSelectedForSelect?.IdPhongBan
                          }
                          onChange={(e: any) => _onChangePhongBanSelect(e, 2)}
                        >
                          <option value={-1} disabled>
                            --- Chọn phòng ban ---
                          </option>
                          {phongBanList === null ? (
                            <></>
                          ) : (
                            phongBanList?.map((item: any, index: any) => (
                              <option key={index} value={item?.IdPhongBan}>
                                {item?.TenPhongBan}
                              </option>
                            ))
                          )}
                        </select>
                        {/* </div> */}

                        <Select
                          className="w-full z-49"
                          options={userListForSelected}
                          // value={single}
                          onChange={(e: any) => _onChangeListMultiUser(3, e)}
                          placeholder="Chọn nhân viên"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />

                        {/* </div> */}
                        {/* </div> */}
                      </div>

                      <div className="w-full">
                        <Select
                          className="w-full"
                          // options={votingList}
                          isMulti
                          value={listMultiUser}
                          //defaultValue={listMultiUser}
                          onChange={(e: any) => _onChangeListMultiUser(4, e)}
                          isClearable
                          placeholder="Danh sách người nhận"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            NoOptionsMessage: () => null,
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              whiteSpace: "normal",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex w-full space-x-3">
                        <div className="w-full space-y-1">
                          <label>
                            Phòng ban&nbsp;
                            {takePermission(20).PerApprove === true && (
                              <span className="text-red-500">(*)</span>
                            )}
                          </label>
                          <select
                            className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 ${selectedPhongBan === -1 ? "" : "font-bold"
                              }`}
                            value={
                              selectedPhongBan === -1
                                ? -1
                                : selectedPhongBan?.IdPhongBan
                            }
                            onChange={(e: any) => _onChangePhongBan(e)}
                            style={{
                              color: `${selectedPhongBan === -1
                                ? ""
                                : selectedPhongBan?.ColorText
                                }`,
                              borderColor: `${selectedPhongBan === -1
                                ? ""
                                : selectedPhongBan?.ColorText
                                }`,
                            }}
                          >
                            <option value={-1} disabled>
                              --- Chọn phòng ban ---
                            </option>
                            {phongBanList === null ? (
                              <></>
                            ) : (
                              phongBanList?.map((item: any, index: any) => (
                                <option key={index} value={item?.IdPhongBan}>
                                  <LevelComponent item={item} />
                                  {item?.TenPhongBan}
                                </option>
                              ))
                            )}
                          </select>
                        </div>

                        <div className="w-full space-y-1">
                          <label>
                            Người nhận&nbsp;
                            {takePermission(20).PerApprove === true && (
                              <span className="text-red-500">(*)</span>
                            )}
                          </label>

                          <Select
                            className="w-full z-49"
                            options={selectedNguoiNhanList}
                            value={selectedNguoiNhan || ""}
                            onChange={(e: any) => _onChangeNguoiNhan(e)}
                            placeholder="Chọn nhân viên"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />

                          {/* <select
                            className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                      ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                    `}
                            style={{
                              color: `${selectedNguoiNhan === -1
                                ? ""
                                : selectedNguoiNhan?.ColorText
                                }`,
                              borderColor: `${selectedNguoiNhan === -1
                                ? ""
                                : selectedNguoiNhan?.ColorText
                                }`,
                            }}
                            value={
                              selectedNguoiNhan === -1
                                ? -1
                                : selectedNguoiNhan?.Id
                            }
                            onChange={(e: any) => _onChangeNguoiNhan(e)}
                            disabled={selectedPhongBan === -1 ? true : false}
                          >
                            <option
                              className="text-gray-300"
                              value={-1}
                              disabled
                            >
                              --- Chọn người nhận ---
                            </option>
                            {selectedNguoiNhanList === null ? (
                              <></>
                            ) : (
                              selectedNguoiNhanList??.map(
                                (item: any, index: any) => (
                                  <option
                                    key={index}
                                    value={item?.Id}
                                    style={{
                                      color: `${item?.ColorText}`,
                                    }}
                                  >
                                    {item?.FullName}
                                  </option>
                                )
                              )
                            )}
                          </select> */}
                        </div>
                      </div>
                      <div
                        className="flex w-full space-x-3"
                        style={{ flexDirection: "column" }}
                      >
                        {selectedNguoiNhan && selectedNguoiNhan != -1 && (
                          <>
                            <p>
                              <b>{selectedNguoiNhan?.FullName}</b> đã sử dụng{" "}
                              {/* <b>
                                {}%
                                ({selectedNguoiNhan?.Hours_in_month_used}/{selectedNguoiNhan?.Hours_in_month} giờ)
                              </b>{" "}
                              thời gian làm việc của tuần và {" "} */}
                              {/* {_onCheckWeek(selectedNguoiNhan)} */}
                              <b
                                style={{
                                  color: `${selectedNguoiNhan.ColorText}`,
                                }}
                              >
                                {selectedNguoiNhan?.Hours_in_month_used_percent}
                                % ({selectedNguoiNhan?.Hours_in_month_used}/
                                {selectedNguoiNhan?.Hours_in_month} giờ)
                              </b>{" "}
                              thời gian làm việc của tháng. Còn lại{" "}
                              <b>
                                {
                                  selectedNguoiNhan?.Hours_in_month_remaining_percent
                                }
                                % ({selectedNguoiNhan?.Hours_in_month_remaining}{" "}
                                giờ)
                              </b>{" "}
                              thời gian rảnh.
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  <div className="w-full">
                    <div className="w-max h-max flex space-x-2">
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isInsertTask}
                        onChange={_onChangeIsInsertTask}
                        disabled={isMulti || !selectedNguoiNhan?.Id}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                        Chèn việc
                      </label>
                    </div>
                    <p className="font-semibold text-red-500">
                      (Chức năng này sẽ thay đổi thời hạn công việc được chọn để ưu tiên công việc hiện tại)
                    </p>
                  </div>
                  {isInsertTask && (
                    <>
                      <div className="w-full">
                        <div className="flex w-max h-max space-x-3">
                          <label>Chọn công việc dời thời hạn</label>
                          <button
                            className={'text-white px-2 py-1.5 rounded-md text-xs bg-primary hover:bg-sky-400'}
                            onClick={
                              _onChangeTaskInsert
                            }
                          >
                            Chọn
                          </button>
                        </div>
                      </div>
                      {(taskToBeInsertID && taskToBeInsertName) && (
                        <>
                          <p>
                            Công việc <b>"{taskToBeInsertName}"</b> sẽ được dời thời hạn để ưu tiên công việc hiện tại.
                          </p>
                        </>
                      )}
                    </>
                  )}
                  <div className="w-full">
                    {/* <div className="flex w-full h-max"> */}
                    {/* elemetns */}
                    {/* <div className="flex flex-col w-1/2 space-x-3 space-y-4">
                        <div className={`flex-col w-full h-max space-y-1`}> */}
                    {/* <div className={`w-max h-max flex flex-col`}> */}
                    <div className="w-max h-max flex space-x-2">
                      <input
                        id="evalutionChoose"
                        className="w-5 h-5"
                        type="checkbox"
                        checked={isChooseTaskReviewer}
                        onChange={_onChangeIsChooseTaskReviewer}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                        Chọn người đánh giá
                      </label>
                    </div>

                    <p className="font-semibold text-red-500">
                      (Nếu không chọn thì mặc định người giao việc là
                      người đánh giá)
                    </p>
                    {/* </div> */}
                    {/* </div>
                      </div> */}
                    {/* </div> */}
                  </div>

                  {isChooseTaskReviewer && (
                    <div className="flex w-full space-x-3">
                      <div className="w-full space-y-1">
                        <label>
                          Phòng ban người đánh giá&nbsp;
                          <span className="text-red-500">(*)</span>
                        </label>
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                          value={selectedPhongBanDanhGia.IdPhongBan}
                          onChange={(e: any) => _onChangePhongBanDanhGia(e)}
                        >
                          {/* <option value={userData.Data[0].IdPhongBan} disabled>
                      {userData.Data[0].TenPhongBan}
                    </option> */}
                          {phongBanDanhGiaList === null ? (
                            <></>
                          ) : (
                            phongBanDanhGiaList?.map(
                              (item: any, index: any) => (
                                <option key={index} value={item?.IdPhongBan}>
                                  <LevelComponent item={item} />
                                  {item?.TenPhongBan}
                                </option>
                              )
                            )
                          )}
                        </select>
                      </div>

                      <div className="w-full space-y-1">
                        <label>
                          Người đánh giá&nbsp;
                          <span className="text-red-500">(*)</span>
                        </label>
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          value={selectedNguoiDanhGia.Id}
                          onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                          disabled={
                            selectedPhongBanDanhGia === -1 ? true : false
                          }
                        >
                          <option value={selectedNguoiDanhGia.Id} disabled>
                            {selectedNguoiDanhGia.FullName}
                          </option>
                          {selectedNguoiDanhGiaList === null ? (
                            <></>
                          ) : (
                            selectedNguoiDanhGiaList?.map(
                              (item: any, index: any) => (
                                <option key={index} value={item?.Id}>
                                  {item?.FullName}
                                </option>
                              )
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  )}
                  {!isMulti && (
                    <div className="w-full h-max space-y-1">
                      <div className="flex w-max h-max space-x-3">
                        <label>Người phối hợp (nếu có)</label>
                        <button
                          className={`
                    text-white pl-2 pr-2 py-1.5 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                              ? "bg-slate-300"
                              : "bg-primary hover:bg-sky-400"
                            }
                  `}
                          disabled={selectedNguoiNhan === -1 ? true : false}
                          onClick={() =>
                            _onChangeNguoiPhoiHop("multipleChoicePartner")
                          }
                        >
                          Chọn
                        </button>
                      </div>
                      <div className="w-full h-max flex flex-col space-y-2">
                        {/* Support Executing */}
                        {/* <div className="w-full h-max">
                          <p className="font-semibold">Hỗ trợ thực hiện / Thời gian hoàn thành</p>
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                              (item: any, index: any) => (
                                <>
                                  {item?.Id === selectedNguoiNhan.Id ? (
                                    <></>
                                  ) : (
                                    <div
                                      key={index}
                                      className="flex w-max h-max text-primary items-center justify-between py-1"
                                    >
                                      <input
                                        className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                        placeholder="Nhập số giờ"
                                        type="number"
                                        value={
                                          useHourSupporter?.find((_item: any) => _item?.id === item?.Id)?.value
                                        }
                                        onChange={(e: any) => _onChangeHoursSupporter(e, item?.Id)}
                                      />
                                      <p className="text-black">&nbsp;/&nbsp;</p>
                                      <div
                                        className="flex w-max h-max space-x-1 font-bold mr-2"
                                        style={{
                                          color: `${item?.ColorText}`,
                                        }}
                                      >
                                        <PersonOutlineTwoToneIcon fontSize="small" />
                                        <p>{item?.FullName}</p>
                                      </div>
                                      <button
                                        className="text-red-500 justify-end"
                                        onClick={() =>
                                          _onCheckNguoiLamViec(item?.Id)
                                        }
                                      >
                                        <DeleteTwoToneIcon fontSize="small" />
                                      </button>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                        </div> */}

                        {/* Hour complete */}
                        <div className="w-full h-max">
                          <p className="font-semibold">
                            Hỗ trợ thực hiện / Thời gian hoàn thành
                          </p>

                          {/* Result */}
                          <div className="w-full h-max">
                            <table className="w-full">
                              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                                <tr>
                                  <th className="border border-slate-300 text-left">
                                    Người thực hiện
                                  </th>
                                  <th className="border border-slate-300"></th>
                                  <th className="border border-slate-300">
                                    Số giờ
                                  </th>
                                  <th className="border border-slate-300">
                                    Số phút
                                  </th>
                                  <th className="border border-slate-300 w-[1rem]"></th>
                                </tr>
                              </thead>
                              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                                {selectedNguoiLamViecList === null
                                  ? ""
                                  : selectedNguoiLamViecList?.map(
                                    (item: any, index: any) => (
                                      <>
                                        {item?.Id ===
                                          selectedNguoiNhan?.Id ? (
                                          <></>
                                        ) : (
                                          <tr
                                            key={index}
                                            className="w-max h-[3rem] bg-white"
                                          >
                                            <td className="text-left border border-slate-300">
                                              <div
                                                className="flex w-max h-max space-x-1 font-bold mr-2"
                                                style={{
                                                  color: `${item?.ColorText}`,
                                                }}
                                              >
                                                <PersonOutlineTwoToneIcon fontSize="small" />
                                                <p>{item?.FullName}</p>
                                              </div>
                                              {isProtractedTask &&
                                                !isOKTotalTimeByIndex(index) ? (
                                                <span className="font-medium text-red-500 text-xs">
                                                  (Tổng thời hoàn thành phải
                                                  bằng tổng thời hoàn thành
                                                  theo mỗi tháng.)
                                                </span>
                                              ) : null}
                                            </td>
                                            <td className="border border-slate-300">
                                              <div className="my-auto font-medium h-[2.45rem]">
                                                <p className="pt-3">
                                                  Tổng:
                                                  <span
                                                    className={`text-red-500`}
                                                  >
                                                    (*)
                                                  </span>
                                                </p>
                                              </div>
                                              {isProtractedTask &&
                                                taskTimeUsed?.map(
                                                  (item2: any) => (
                                                    <div className="my-auto font-medium h-[2.45rem] mt-1">
                                                      <p className="pt-3">
                                                        {item2.Month}/
                                                        {item2.Year}:
                                                      </p>
                                                    </div>
                                                  )
                                                )}
                                            </td>
                                            <td className="border border-slate-300">
                                              <div className="flex justify-center">
                                                <input
                                                  className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                                  placeholder="Nhập số giờ"
                                                  type="number"
                                                  value={
                                                    useHourSupporter?.find(
                                                      (_item: any) =>
                                                        _item?.id === item?.Id
                                                    )?.value
                                                  }
                                                  onChange={(e: any) => {
                                                    _onChangeHoursSupporter(
                                                      e,
                                                      item?.Id
                                                    );
                                                  }}
                                                />
                                              </div>

                                              {isProtractedTask &&
                                                taskTimeUsed?.map(
                                                  (
                                                    item2: any,
                                                    index2: any
                                                  ) => (
                                                    <div className="flex justify-center">
                                                      {" "}
                                                      <input
                                                        className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200 mt-1"
                                                        placeholder="Nhập số giờ"
                                                        type="number"
                                                        value={getValueTime(
                                                          index,
                                                          true,
                                                          index2
                                                        )}
                                                        onChange={(e) =>
                                                          _onChangeTaskTimeUsed(
                                                            e,
                                                            index,
                                                            true,
                                                            index2
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  )
                                                )}
                                            </td>
                                            <td className="border border-slate-300">
                                              <div className="flex justify-between">
                                                <input
                                                  className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                                  placeholder="Nhập số phút"
                                                  type="number"
                                                  value={
                                                    useMinutesSupporter?.find(
                                                      (_item: any) =>
                                                        _item?.id === item?.Id
                                                    )?.value
                                                  }
                                                  onChange={(e: any) => {
                                                    _onChangeMinutesSupporter(
                                                      e,
                                                      item?.Id
                                                    );
                                                  }}
                                                />
                                              </div>

                                              {isProtractedTask &&
                                                taskTimeUsed?.map(
                                                  (
                                                    item2: any,
                                                    index2: any
                                                  ) => (
                                                    <div className="flex justify-between">
                                                      <input
                                                        className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200 mt-1"
                                                        placeholder="Nhập số phút"
                                                        type="number"
                                                        value={getValueTime(
                                                          index,
                                                          false,
                                                          index2
                                                        )}
                                                        onChange={(e) =>
                                                          _onChangeTaskTimeUsed(
                                                            e,
                                                            index,
                                                            false,
                                                            index2
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  )
                                                )}
                                            </td>
                                            <td className="text-left border border-slate-300">
                                              <button
                                                className="text-red-500 justify-end"
                                                onClick={() => {
                                                  _onCheckNguoiLamViec(
                                                    item?.Id,
                                                    userList
                                                  );
                                                  setIsDeleteSupport(true);
                                                }}
                                              >
                                                <DeleteTwoToneIcon fontSize="small" />
                                              </button>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Support Recomment */}
                        <div className="w-full h-max">
                          <p className="font-semibold">Hỗ trợ góp ý</p>
                          {selectedNguoiGopYList === null
                            ? ""
                            : selectedNguoiGopYList?.map(
                              (item: any, index: any) => (
                                <>
                                  {item?.Id === selectedNguoiNhan?.Id ? (
                                    <></>
                                  ) : (
                                    <div
                                      key={index}
                                      className="flex w-max h-max text-primary items-center justify-between"
                                    >
                                      <div
                                        key={index}
                                        className="flex w-max h-max text-primary items-center justify-between"
                                      >
                                        <div
                                          className="flex w-max h-max space-x-1 font-bold mr-2"
                                          style={{
                                            color: `${item?.ColorText}`,
                                          }}
                                        >
                                          <PersonOutlineTwoToneIcon fontSize="small" />
                                          <p>{item?.FullName}</p>
                                        </div>
                                        <button
                                          className="text-red-500 justify-end"
                                          onClick={() =>
                                            _onCheckNguoiGopY(
                                              item?.Id,
                                              userList
                                            )
                                          }
                                        >
                                          <DeleteTwoToneIcon fontSize="small" />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                        </div>
                      </div>
                      <div className="flex w-full h-max items-center space-x-6">
                        {/* overload */}
                        <div className="flex items-center space-x-1">
                          <CircleIcon className="text-[#00ff00]" />
                          <p>Có thể nhận việc</p>
                        </div>
                        <div className="flex items-center space-x-1">
                          <CircleIcon className="text-[#FFAA00]" />
                          <p>Đang nhiều việc</p>
                        </div>
                        <div className="flex items-center space-x-1">
                          <CircleIcon className="text-[#ff0000]" />
                          <p>Quá tải</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* )} */}
              </div>

              <div className="w-full">
                <div className="flex w-full h-max">
                  {/* elemetns */}
                  <div className="flex flex-col w-1/2 space-y-4">
                    {/* <div
                      className={`
                flex-col w-full h-max space-y-1
              `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          checked={isForce}
                          onChange={_onChangeIsForce}
                        />
                        <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                          KHÔNG CHO PHÉP TRẢ VIỆC
                        </label>
                      </div>
                    </div> */}
                    <div
                      className={`
                        flex-col w-full h-max space-y-1
                      `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          className="w-5 h-5"
                          checked={isExChange}
                          onChange={() => {
                            if (isExChange === true) {
                              setIsExChange(false);
                            } else {
                              setIsExChange(true);
                            }
                          }}
                          id="isExChange"
                        // checked={isForce}
                        // onChange={_onChangeIsForce}
                        />
                        <label
                          htmlFor="isExChange"
                          className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                        >
                          CHO PHÉP THOẢ THUẬN THỜI HẠN
                        </label>
                      </div>
                    </div>

                    <div
                      className={`
                        flex-col w-full h-max space-y-1
                      `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          className="w-5 h-5"
                          checked={isEvaluate}
                          onChange={() => {
                            if (isEvaluate === true) {
                              setIsEvaluate(false);
                            } else {
                              setIsEvaluate(true);
                            }
                          }}
                          id="IsEvaluate"
                        // checked={isForce}
                        // onChange={_onChangeIsForce}
                        />
                        <label
                          htmlFor="IsEvaluate"
                          className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                        >
                          CÔNG VIỆC KHÔNG CẦN ĐÁNH GIÁ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full h-max ">
                <QuytrinhJSX />
              </div>
              <div className="flex flex-col w-full h-max">
                {/* buoc */}
                <div
                  style={{
                    zIndex: 0,
                  }}
                  className={`w-full tableFixHead
          ${selectedBuocList === null || selectedBuocList === undefined
                      ? "lg:flex flex-col hidden"
                      : "lg:flex flex-col hidden"
                    }
        `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 lg:w-[20px]">
                          Bước
                        </th>

                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300 lg:w-[225px]">
                          Người thực hiện
                        </th>
                        <th className="border border-slate-300 lg:w-[225px]">
                          Thời hạn
                        </th>
                        <th className="border border-slate-300 lg:w-[90px]">
                          Số giờ thực hiện
                        </th>
                        <th className="border border-slate-300 lg:w-[90px]">
                          Số phút thực hiện
                        </th>
                        <th className="border border-slate-300 lg:w-[225px]">
                          Kết quả cần đạt
                        </th>

                        <th className="border border-slate-300 w-[1rem]"></th>
                      </tr>
                    </thead>

                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedBuocList === null ||
                        selectedBuocList === undefined
                        ? ""
                        : selectedBuocList
                          .sort((a: any, b: any) => {
                            return a.SortOrder - b.SortOrder;
                          })
                          ?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className="w-max h-[3rem] bg-white"
                            >
                              <td className="text-center border border-slate-300 lg:w-[20px]">
                                <p>{item?.SortOrder}</p>
                              </td>
                              <td className="border border-slate-300">
                                <p>{item?.StepName}</p>
                              </td>
                              <td className="text-center border border-slate-300 lg:w-[225px]">
                                <select
                                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  value={item?.UserDo}
                                  onChange={(e: any) => {
                                    e.preventDefault();
                                    _onChangeNguoiPhoiHopEachStep(
                                      item?.SortOrder,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option
                                    className="text-gray-300"
                                    value={""}
                                    disabled
                                  >
                                    --- Chọn người phối hợp ---
                                  </option>
                                  {selectedNguoiLamViecList === null
                                    ? ""
                                    : selectedNguoiLamViecList?.map(
                                      (item: any, index: any) => (
                                        <option
                                          key={index}
                                          value={item?.Id}
                                        >
                                          {item?.FullName}
                                        </option>
                                      )
                                    )}
                                </select>
                              </td>
                              <td className="text-center border border-slate-300 lg:w-[225px]">
                                <DateTimePickerControl
                                  className={
                                    "width-100-percent padding-right-0"
                                  }
                                  value={item?.DeadLine_Step}
                                  onChange={(e: any) => {
                                    _onChangDeadLineStep(item?.SortOrder, e);
                                    //           checkLastedDealine(e);
                                    //         }}
                                    //       />
                                    //     </td>
                                    //     <td className="text-center border border-slate-300 lg:w-[90px]">
                                    //       <input
                                    //         className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                    //         type="text"
                                    //         onTouchStart={(e: any) => onTouchForm(e)}
                                    //         value={item.UseHours}
                                    //         onChange={(e: any) => {
                                    //           _onChangUseHoursStep(
                                    //             item.SortOrder,
                                    //             e.target.value
                                    //           );
                                    //         }}
                                    //       />
                                    //     </td>
                                    //     <td className="text-center border border-slate-300 lg:w-[90px]">
                                    //       <input
                                    //         className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                    //         type="text"
                                    //         onTouchStart={(e: any) => onTouchForm(e)}
                                    //         // value={item.UseMinutes}
                                    //         // onChange={(e: any) => {
                                    //         //   console.log("UseMinutes, ", e.target.value);
                                    //         //   _onChangUseMinutesStep(
                                    //         //     item.SortOrder,
                                    //         //     e.target.value
                                    //         //   );
                                    //         // }}
                                    //       />
                                    //     </td>
                                    //     <td className="text-center border border-slate-300 lg:w-[200px]">
                                    //       <textarea
                                    //         className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                    //         onTouchStart={(e: any) => onTouchForm(e)}
                                    //         // value={item.KetQua}
                                    //         // onChange={(e: any) => {
                                    //         //   console.log("KetQua, ", e.target.value);
                                    //         //   _onChangKetQuaStep(
                                    //         //     item.SortOrder,
                                    //         //     e.target.value
                                    //         //   );
                                    //         // }}
                                    //       />
                                    //     </td>
                                    //     <td className="border border-slate-300">
                                    //       <button
                                    //         className="text-red-500 w-1/6"
                                    //         onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                                    //       >
                                    //         <DeleteTwoToneIcon fontSize="small" />
                                    //       </button>
                                    //     </td>
                                    //   </tr>
                                    // ))}
                                    checkLastedDealine(e);
                                  }}
                                />
                              </td>
                              <td className="text-center border border-slate-300 lg:w-[90px]">
                                <input
                                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  type="number"
                                  onTouchStart={(e: any) => onTouchForm(e)}
                                  value={item?.UseHours}
                                  onChange={(e: any) =>
                                    _onChangUseHoursStep(item?.SortOrder, e)
                                  }
                                />
                              </td>
                              <td className="text-center border border-slate-300 lg:w-[90px]">
                                <input
                                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  type="text"
                                  onTouchStart={(e: any) => onTouchForm(e)}
                                  value={item?.UseMinutes}
                                  onChange={(e: any) => {
                                    _onChangUseMinutesStep(
                                      item?.SortOrder,
                                      e
                                    );
                                  }}
                                />
                              </td>
                              <td className="text-center border border-slate-300 lg:w-[200px]">
                                <textarea
                                  className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  onTouchStart={(e: any) => onTouchForm(e)}
                                  value={item?.KetQua}
                                  onChange={(e: any) => {
                                    _onChangKetQuaStep(item?.SortOrder, e);
                                  }}
                                />
                              </td>
                              <td className="border border-slate-300">
                                <button
                                  className="text-red-500 w-1/6"
                                  onClick={() =>
                                    _onDeleteQuyTrinh(item?.SortOrder)
                                  }
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  <div className="my-4 w-full h-max justify-end items-end flex">
                    <button
                      className="
              bg-emerald-500 p-1.5 text-white rounded-md
              hover:bg-emerald-400
            "
                      onClick={() => _onClickEditBuoc("editBuoc")}
                    >
                      Thêm bước
                    </button>
                  </div>
                </div>
                {/* <StepListJSX /> */}

                {/* ket qua */}
                <div
                  className={`
                    w-full h-max
                    ${selectedKetQuaList === null
                      ? "flex flex-col"
                      : "flex flex-col"
                    }
                  `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">STT</th>
                        <th className="border border-slate-300 ">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          {/* <button
                            className="
                              flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                              rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                            "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item?.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button
                                className="text-red-500"
                                onClick={() => _onDeleteKetqua(item?.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>

                  <div className="my-4 w-full h-max justify-end items-end flex">
                    <button
                      className="
                        bg-emerald-500 p-1.5 text-white rounded-md
                        hover:bg-emerald-400
                      "
                      onClick={() => _onClickEditKetqua("editKetqua")}
                    >
                      Thêm kết quả
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Phone detail */}
            <div className="lg:hidden space-y-1.5">
              {/* <div className="w-full h-max space-y-0.5">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>
                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="--- Chọn đầu mục ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempDauViecList?.find(
                    (item) => item?.Id === selectedDauViec?.Id
                  )}
                />
              </div> */}
              <DauMucViecJSX />
              <div className="w-full h-max space-y-0.5">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="w-full h-max space-y-0.5">
                <label>
                  Loại công việc/dịch vụ/tư vấn&nbsp;
                  {isAutoLoaiViec && <span className="text-red-500">(*)</span>}
                </label>
                <select
                  className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200"
                  value={
                    selectedLoaiViec === -1 ? -1 : selectedLoaiViec.ParameterId
                  }
                  onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    Chọn loại công việc
                  </option>
                  {loaiViecList === null ? (
                    <></>
                  ) : (
                    loaiViecList?.map((item: any, index: any) => (
                      <option key={index} value={item?.ParameterId}>
                        {item?.ParameterValue}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full h-max space-x-1 core-control-group">
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Mức độ ưu tiên&nbsp;
                    {isAutoPriority && (
                      <span className={`text-red-500`}>(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                      w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                    value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                    onChange={(e: any) => _onChangeSelectedUuTien(e)}
                    style={{
                      color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                      borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn mức độ
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList?.map((item: any, index: any) => (
                        <option
                          key={index}
                          value={item?.Id}
                          style={{ color: `${item?.Color}` }}
                        >
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Cơ sở căn cứ bắt đầu công việc&nbsp;
                    {isAutoOrigin && <span className="text-red-500">(*)</span>}
                  </label>
                  {/* <select
                    className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500"
                    value={
                      selectedNguonVanBan === -1
                        ? -1
                        : selectedNguonVanBan?.ParameterId
                    }
                    onChange={(e: any) => _onChangeNguonVanBan(e)}
                  >
                    <option value={-1} disabled>
                      Chọn căn cứ
                    </option>
                    {nguonVanBanList === null ? (
                      <></>
                    ) : (
                      nguonVanBanList?.map((item: any, index: any) => (
                        <option key={index} value={item?.ParameterId}>
                          {item?.ParameterValue}
                        </option>
                      ))
                    )}
                  </select> */}
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập căn cứ"
                    value={selectedNguonVanBan}
                    onChange={(e: any) => _onChangeNewNguonVanBan(e)}
                  />
                </div>
              </div>

              <div className="flex w-full h-max space-x-1 core-control-group">
                <div className={`w-full flex-col h-max space-y-0.5`}>
                  <label>
                    Độ mật&nbsp;
                    {isAutoSecurity && (
                      <span className={`text-red-500`}>(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : "font-bold"
                      }
                  `}
                    value={
                      selectedDoMat === -1 || selectedDoMat === null
                        ? -1
                        : selectedDoMat.Id
                    }
                    onChange={(e: any) => _onChangeDoMat(e)}
                    style={{
                      color: `${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : selectedDoMat.Color
                        }`,
                      borderColor: `${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : selectedDoMat.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ mật
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList?.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item?.Color}` }}
                          key={index}
                          value={item?.Id}
                        >
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Độ khó&nbsp;
                    {isAutoDifference && (
                      <span className="text-red-500">(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoKho === -1 ||
                        selectedDoKho === null ||
                        selectedDoKho === 0 ||
                        selectedDoKho === undefined
                        ? -1
                        : selectedDoKho.Id
                    }
                    onChange={(e: any) => _onChangeDoKho(e)}
                    style={{
                      color: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                      borderColor: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ khó
                    </option>
                    {doKhoList === null ? (
                      <></>
                    ) : (
                      doKhoList?.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item?.Color}` }}
                          key={index}
                          value={item?.Id}
                        >
                          {item?.DifficultLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>
              <AssignmentDateJSX />
              <DeadLineJSX />
              <div className="w-full h-max">
                <div className="w-full h-max">
                  {/* <label>Mã tham chiếu công việc</label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập mã"
                        value={refCode}
                        onChange={(e: any) => _onChangeRefCode(e)}
                      /> */}
                  <label className="w-full">
                    Thời gian hoàn thành&nbsp;
                    {_onCheckValidForHoursUsed_Label() === true && (
                      <span className={`text-red-500`}>(*)</span>
                    )}
                    {isProtractedTask && !isOKTotalTimeByIndex(0) ? (
                      <span className="font-medium text-red-500 text-xs">
                        Tổng thời hoàn thành phải bằng tổng thời hoàn thành theo
                        mỗi tháng.
                      </span>
                    ) : null}
                  </label>
                </div>

                <div className="w-full h-max flex gap-3">
                  {/* <div className="w-1/3 h-max">
                        <label>
                          Số ngày
                        </label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số ngày"
                          type="number"
                          value={useHours ? useHours : ""}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div> */}
                  <div className="w-full h-max ">
                    <label>Số giờ hoàn thành</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập số giờ"
                      type="number"
                      value={useHours ? useHours : ""}
                      onChange={(e: any) => _onChangeHours(e)}
                    />
                  </div>
                  <div className="w-full h-max">
                    <label>Số phút hoàn thành</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập số phút"
                      type="number"
                      value={useMinutes ? useMinutes : ""}
                      onChange={(e: any) => _onChangeMinutes(e)}
                    />
                  </div>
                </div>
              </div>

              {/* <DateTimePickerControl
                className={"width-100-percent padding-right-0"}
                label={"Thời hạn hoàn thành (*)"}
                value={deadLine}
                onChange={(e: any) => {
                  const value = e;
                  console.log(e);
                  _onChangeDeadLine2(value);
                }}
              /> */}

              {isProtractedTask && (
                <div className="w-full h-max flex gap-3 mt-2">
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 text-left">
                          Tháng/Năm
                        </th>
                        <th className="border border-slate-300">
                          Số giờ hoàn thành
                        </th>
                        <th className="border border-slate-300">
                          Số phút hoàn thành
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {taskTimeUsed?.map(
                        (item: any, index: any) =>
                          item.index === 0 && (
                            <tr key={index} className="w-max h-[3rem] bg-white">
                              <td className="text-left border border-slate-300">
                                <div className="flex w-max h-max space-x-1 font-medium mr-2">
                                  <p>
                                    {item?.Month}/{item?.Year}
                                  </p>
                                </div>
                              </td>
                              <td className="text-center border border-slate-300">
                                <input
                                  className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                  placeholder="Nhập số giờ"
                                  type="number"
                                  // value={item?.HoursUsed}
                                  value={getValueTime(0, true, index)}
                                  onChange={(e) =>
                                    _onChangeTaskTimeUsed(e, 0, true, index)
                                  }
                                />
                              </td>
                              <td className="text-center border border-slate-300">
                                <input
                                  className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                  placeholder="Nhập số phút"
                                  type="number"
                                  value={getValueTime(0, false, index)}
                                  onChange={(e) =>
                                    _onChangeTaskTimeUsed(e, 0, false, index)
                                  }
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              {/* <div className="flex w-full h-max">
                <div className="flex w-full h-max space-x-3">
                  <div className="w-full h-max">
                    <label>
                      Thời hạn hoàn thành&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      type="datetime-local"
                      value={deadLine}
                      onChange={(e: any) => _onChangeDeadLine(e)}
                    />
                  </div>
                </div>
              </div> */}

              {props.isChiaViec == true ? (
                <></>
              ) : (
                <div
                  className="w-full"
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <div className="flex w-full h-max">
                    {/* elemetns */}
                    <div className={`w-max h-max`}>
                      <div className="w-max h-max flex space-x-2">
                        <input
                          type="checkbox"
                          className="w-5 h-5"
                          checked={isMulti}
                          onChange={_onChangeIsMulti}
                        />
                        <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                          Giao việc cho nhiều người
                        </label>
                      </div>
                      <p className="font-semibold text-red-500">
                        (Chức năng giao việc cho nhiều người không thể chọn
                        người hỗ trợ.)
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {isMulti && !props.isChiaViec === true ? (
                <div className="flex space-x-2 items-top">
                  <div className="w-full space-y-2">
                    <div className="flex w-max h-max space-x-3">
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isAllUser}
                        onChange={(e: any) => _onChangeListMultiUser(5, e)}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2">
                        Chọn toàn bộ
                      </label>
                    </div>
                    <label>Chọn nhanh cả phòng</label>
                    <Select
                      className="w-full z-50"
                      options={phongBanListForSelect}
                      value={null}
                      onChange={(e: any) => _onChangeListMultiUser(1, e)}
                      isClearable
                      placeholder="Chọn nhanh cả phòng"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    <label>Chọn từng người</label>
                    <Select
                      className="w-full z-49"
                      options={userListForSelect}
                      value={null}
                      onChange={(e: any) => _onChangeListMultiUser(2, e)}
                      placeholder="Chọn từng người"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {/* <div className="w-full space-y-1"> */}
                    <label>Chọn theo phòng ban</label>
                    {/* <div className="flex w-full space-x-3"> */}
                    {/* <div className="w-full space-y-1"> */}
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                      value={
                        phongBanSelectedForSelect === -1
                          ? -1
                          : phongBanSelectedForSelect?.IdPhongBan
                      }
                      onChange={(e: any) => _onChangePhongBanSelect(e, 2)}
                    >
                      <option value={-1} disabled>
                        --- Chọn phòng ban ---
                      </option>
                      {phongBanList === null ? (
                        <></>
                      ) : (
                        phongBanList?.map((item: any, index: any) => (
                          <option key={index} value={item?.IdPhongBan}>
                            {item?.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                    {/* </div> */}

                    <Select
                      className="w-full z-48"
                      options={userListForSelected}
                      // value={single}
                      onChange={(e: any) => _onChangeListMultiUser(3, e)}
                      placeholder="Chọn nhân viên"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />

                    {/* </div> */}
                    {/* </div> */}
                  </div>

                  <div className="w-full">
                    <Select
                      className="w-full"
                      // options={votingList}
                      isMulti
                      value={listMultiUser}
                      defaultValue={listMultiUser}
                      onChange={(e: any) => _onChangeListMultiUser(4, e)}
                      isClearable
                      placeholder="Danh sách người nhận"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        NoOptionsMessage: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          whiteSpace: "normal",
                        }),
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="w-full h-max space-y-0.5">
                    <label>
                      Phòng ban&nbsp;
                      {takePermission(20).PerApprove === true && (
                        <span className="text-red-500">(*)</span>
                      )}
                    </label>
                    <select
                      className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 text-gray-500 ${selectedPhongBan === -1 ? "" : "font-bold"
                        }`}
                      value={
                        selectedPhongBan === -1
                          ? -1
                          : selectedPhongBan?.IdPhongBan
                      }
                      onChange={(e: any) => _onChangePhongBan(e)}
                      style={{
                        color: `${selectedPhongBan === -1
                          ? ""
                          : selectedPhongBan?.ColorText
                          }`,
                        borderColor: `${selectedPhongBan === -1
                          ? ""
                          : selectedPhongBan?.ColorText
                          }`,
                      }}
                    >
                      <option value={-1} disabled>
                        --- Chọn phòng ban ---
                      </option>
                      {phongBanList === null ? (
                        <></>
                      ) : (
                        phongBanList?.map((item: any, index: any) => (
                          <option key={index} value={item?.IdPhongBan}>
                            {item?.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                    <label>
                      Người nhận&nbsp;
                      {takePermission(20).PerApprove === true && (
                        <span className="text-red-500">(*)</span>
                      )}
                    </label>

                    {/* <select
                      className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 text-gray-500
                    ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                  `}
                      style={{
                        color: `${selectedNguoiNhan === -1
                          ? ""
                          : selectedNguoiNhan?.ColorText
                          }`,
                        borderColor: `${selectedNguoiNhan === -1
                          ? ""
                          : selectedNguoiNhan?.ColorText
                          }`,
                      }}
                      value={
                        selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan?.Id
                      }
                      onChange={(e: any) => _onChangeNguoiNhan(e)}
                      disabled={selectedPhongBan === -1 ? true : false}
                    >
                      <option value={-1} disabled>
                        --- Chọn người nhận ---
                      </option>
                      {selectedNguoiNhanList === null ? (
                        <></>
                      ) : (
                        selectedNguoiNhanList??.map((item: any, index: any) => (
                          <option
                            style={{ color: `${item?.ColorText}` }}
                            key={index}
                            value={item?.Id}
                          >
                            {item?.FullName}
                          </option>
                        ))
                      )}
                    </select> */}
                    <Select
                      className="w-full z-49"
                      options={selectedNguoiNhanList}
                      value={selectedNguoiNhan || ""}
                      onChange={(e: any) => _onChangeNguoiNhan(e)}
                      placeholder="Chọn nhân viên"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="w-full h-max space-y-0.5">
                    {selectedNguoiNhan && selectedNguoiNhan != -1 && (
                      <p>
                        <b>{selectedNguoiNhan?.FullName}</b> đã sử dụng{" "}
                        <b style={{ color: `${selectedNguoiNhan.ColorText}` }}>
                          {selectedNguoiNhan?.Hours_in_month_used_percent}% (
                          {selectedNguoiNhan?.Hours_in_month_used}/
                          {selectedNguoiNhan?.Hours_in_month} giờ)
                        </b>{" "}
                        thời gian làm việc của tháng. Còn lại{" "}
                        <b>
                          {selectedNguoiNhan?.Hours_in_month_remaining_percent}%
                          ({selectedNguoiNhan?.Hours_in_month_remaining} giờ)
                        </b>{" "}
                        thời gian rảnh.
                      </p>
                    )}
                  </div>
                </>
              )}
              <div
                className="w-full flex flex-col gap-3"
                style={{
                  marginTop: "15px",
                }}
              >
                <div className="w-full">
                  <div className="w-max h-max flex space-x-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5"
                      checked={isInsertTask}
                      onChange={_onChangeIsInsertTask}
                      disabled={isMulti || !selectedNguoiNhan?.Id}
                    />
                    <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                      Chèn việc
                    </label>
                  </div>
                  <p className="font-semibold text-red-500">
                    (Chức năng này sẽ thay đổi thời hạn công việc được chọn để ưu tiên công việc hiện tại)
                  </p>
                  {isInsertTask && (
                    <>
                      <div className="w-full">
                        <div className="flex w-max h-max space-x-3">
                          <label>Chọn công việc dời thời hạn</label>
                          <button
                            className={'text-white px-2 py-1.5 rounded-md text-xs bg-primary hover:bg-sky-400'}
                            onClick={
                              _onChangeTaskInsert
                            }
                          >
                            Chọn
                          </button>
                        </div>
                      </div>
                      {(taskToBeInsertID && taskToBeInsertName) && (
                        <>
                          <p>
                            Công việc <b>"{taskToBeInsertName}"</b> sẽ được dời thời hạn để ưu tiên công việc hiện tại.
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="flex w-full h-max">
                  {/* elemetns */}
                  <div className={`w-max h-max`}>
                    <div className="w-max h-max flex space-x-2">
                      <input
                        className="mr-2 w-5 h-5"
                        type="checkbox"
                        checked={isChooseTaskReviewer}
                        onChange={_onChangeIsChooseTaskReviewer}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                        Chọn người đánh giá
                      </label>
                    </div>
                    <p className="font-semibold text-red-500">
                      (Nếu không chọn thì mặc định người giao việc là người đánh
                      giá)
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="flex w-full items-center">
                <input
                  className="mr-2 w-5 h-5"
                  type="checkbox"
                  checked={isChooseTaskReviewer}
                  onChange={_onChangeIsChooseTaskReviewer}
                />
                <label
                  className="hover:cursor-pointer"
                  htmlFor="evalutionChoose"
                >
                  Chọn người đánh giá
                </label>
              </div>
              <div className="flex w-full">
                <p className="font-semibold text-red-500">
                  (Nếu không chọn thì mặc định người giao việc là người đánh
                  giá)
                </p>
              </div> */}
              {isChooseTaskReviewer && (
                <>
                  <div className="w-full space-y-0.5">
                    <label>
                      Phòng ban người đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                      value={selectedPhongBanDanhGia.IdPhongBan}
                      onChange={(e: any) => _onChangePhongBanDanhGia(e)}
                    >
                      {phongBanDanhGiaList === null ? (
                        <></>
                      ) : (
                        phongBanDanhGiaList?.map((item: any, index: any) => (
                          <option key={index} value={item?.IdPhongBan}>
                            <LevelComponent item={item} />
                            {item?.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full space-y-0.5">
                    <label>
                      Người đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      value={selectedNguoiDanhGia.Id}
                      onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                      disabled={selectedPhongBanDanhGia === -1 ? true : false}
                    >
                      <option value={selectedNguoiDanhGia.Id} disabled>
                        {selectedNguoiDanhGia.FullName}
                      </option>
                      {selectedNguoiDanhGiaList === null ? (
                        <></>
                      ) : (
                        selectedNguoiDanhGiaList?.map(
                          (item: any, index: any) => (
                            <option key={index} value={item?.Id}>
                              {item?.FullName}
                            </option>
                          )
                        )
                      )}
                    </select>
                  </div>
                </>
              )}

              <div className="w-full space-y-4">
                <div className="w-full space-y-1">
                  <div className="flex w-max h-max space-x-3">
                    <label>Người phối hợp (nếu có)</label>
                    <button
                      className={`
                      text-white px-2 py-1.5 rounded-md
                      text-xs
                    ${selectedNguoiNhan === -1
                          ? "bg-slate-300"
                          : "bg-primary hover:bg-sky-400"
                        }
                  `}
                      disabled={selectedNguoiNhan === -1 ? true : false}
                      onClick={() =>
                        _onChangeNguoiPhoiHop("multipleChoicePartner")
                      }
                    >
                      Chọn
                    </button>
                  </div>

                  {/* <div className="flex flex-col h-max">
                    <p>Hỗ trợ thực hiện / Số giờ hoàn thành</p>
                    {selectedNguoiLamViecList === null
                      ? ""
                      : selectedNguoiLamViecList?.map(
                          (item: any, index: any) => (
                            <>
                              {item?.Id === selectedNguoiNhan.Id ? (
                                <></>
                              ) : (
                                <div
                                  key={index}
                                  className="flex w-max h-max text-primary space-y-2 items-center justify-between"
                                >
                                  <input
                                    className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                    placeholder="Nhập số giờ"
                                    type="number"
                                    value={
                                      useHourSupporter?.find(
                                        (_item: any) => _item?.id === item?.Id
                                      )?.value
                                    }
                                    onChange={(e: any) =>
                                      _onChangeHoursSupporter(e, item?.Id)
                                    }
                                  />

                                  <div
                                    className="flex w-max h-max space-x-1 font-bold"
                                    style={{
                                      color: `${item?.ColorText}`,
                                    }}
                                  >
                                    <PersonOutlineTwoToneIcon fontSize="small" />
                                    <p>{item?.FullName}</p>
                                  </div>
                                  <button
                                    className="text-red-500 flex justify-end"
                                    onClick={() =>
                                      _onCheckNguoiLamViec(item?.Id)
                                    }
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              )}
                            </>
                          )
                        )}
                  </div> */}

                  <div className="w-full h-max">
                    <p className="font-semibold">
                      Hỗ trợ thực hiện / Thời gian hoàn thành
                    </p>

                    {/* Result */}
                    <div className="w-full h-max">
                      <table className="w-full">
                        <thead className="text-white font-semibold text-center w-full h-[2rem]">
                          <tr>
                            <th className="border border-slate-300 text-left">
                              Người thực hiện
                            </th>
                            <th className="border border-slate-300"></th>
                            <th className="border border-slate-300">Số giờ</th>
                            <th className="border border-slate-300">Số phút</th>
                            <th className="border border-slate-300 w-[1rem]"></th>
                          </tr>
                        </thead>
                        <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                              (item: any, index: any) => (
                                <>
                                  {item?.Id === selectedNguoiNhan?.Id ? (
                                    <></>
                                  ) : (
                                    <tr
                                      key={index}
                                      className="w-max h-[3rem] bg-white"
                                    >
                                      <td className="text-left border border-slate-300">
                                        <div
                                          className="flex w-max h-max space-x-1 font-bold mr-2"
                                          style={{
                                            color: `${item?.ColorText}`,
                                          }}
                                        >
                                          <PersonOutlineTwoToneIcon fontSize="small" />
                                          <p>{item?.FullName}</p>
                                        </div>
                                        {isProtractedTask &&
                                          !isOKTotalTimeByIndex(index) ? (
                                          <span className="font-medium text-red-500 text-xs">
                                            (Tổng thời hoàn thành phải bằng
                                            tổng thời hoàn thành theo mỗi
                                            tháng.)
                                          </span>
                                        ) : null}
                                      </td>
                                      <td className="border border-slate-300">
                                        <div className="my-auto font-medium h-[2.45rem]">
                                          <p className="pt-3">
                                            Tổng:
                                            <span className={`text-red-500`}>
                                              (*)
                                            </span>
                                          </p>
                                        </div>
                                        {isProtractedTask &&
                                          taskTimeUsed?.map((item2: any) => (
                                            <div className="my-auto font-medium h-[2.45rem] mt-1">
                                              <p className="pt-3">
                                                {item2.Month}/{item2.Year}:
                                              </p>
                                            </div>
                                          ))}
                                      </td>
                                      <td className="border border-slate-300">
                                        <div className="flex justify-center">
                                          <input
                                            className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                            placeholder="Nhập số giờ"
                                            type="number"
                                            value={
                                              useHourSupporter?.find(
                                                (_item: any) =>
                                                  _item?.id === item?.Id
                                              )?.value
                                            }
                                            onChange={(e: any) => {
                                              _onChangeHoursSupporter(
                                                e,
                                                item?.Id
                                              );
                                            }}
                                          />
                                        </div>

                                        {isProtractedTask &&
                                          taskTimeUsed?.map(
                                            (item2: any, index2: any) => (
                                              <div className="flex justify-center">
                                                {" "}
                                                <input
                                                  className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200 mt-1"
                                                  placeholder="Nhập số giờ"
                                                  type="number"
                                                  value={getValueTime(
                                                    index,
                                                    true,
                                                    index2
                                                  )}
                                                  onChange={(e) =>
                                                    _onChangeTaskTimeUsed(
                                                      e,
                                                      index,
                                                      true,
                                                      index2
                                                    )
                                                  }
                                                />
                                              </div>
                                            )
                                          )}
                                      </td>
                                      <td className="border border-slate-300">
                                        <div className="flex justify-between">
                                          <input
                                            className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                            placeholder="Nhập số phút"
                                            type="number"
                                            value={
                                              useMinutesSupporter?.find(
                                                (_item: any) =>
                                                  _item?.id === item?.Id
                                              )?.value
                                            }
                                            onChange={(e: any) => {
                                              _onChangeMinutesSupporter(
                                                e,
                                                item?.Id
                                              );
                                            }}
                                          />
                                        </div>

                                        {isProtractedTask &&
                                          taskTimeUsed?.map(
                                            (item2: any, index2: any) => (
                                              <div className="flex justify-between">
                                                <input
                                                  className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200 mt-1"
                                                  placeholder="Nhập số phút"
                                                  type="number"
                                                  value={getValueTime(
                                                    index,
                                                    false,
                                                    index2
                                                  )}
                                                  onChange={(e) =>
                                                    _onChangeTaskTimeUsed(
                                                      e,
                                                      index,
                                                      false,
                                                      index2
                                                    )
                                                  }
                                                />
                                              </div>
                                            )
                                          )}
                                      </td>
                                      <td className="text-left border border-slate-300">
                                        <button
                                          className="text-red-500 justify-end"
                                          onClick={() => {
                                            _onCheckNguoiLamViec(
                                              item?.Id,
                                              userList
                                            );
                                            setIsDeleteSupport(true);
                                          }}
                                        >
                                          <DeleteTwoToneIcon fontSize="small" />
                                        </button>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="w-full h-max">
                      <table className="w-full">
                        <thead className="text-white font-semibold text-center w-full h-[2rem]">
                          <tr>
                            <th className="border border-slate-300 text-left">
                              Người thực hiện
                            </th>
                            <th className="border border-slate-300">
                              Số giờ hoàn thành
                            </th>
                            <th className="border border-slate-300">
                              Số phút hoàn thành
                            </th>
                            <th className="border border-slate-300 w-[1rem]"></th>
                          </tr>
                        </thead>
                        <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                                (item: any, index: any) => (
                                  <>
                                    {item?.Id === selectedNguoiNhan?.Id ? (
                                      <></>
                                    ) : (
                                      <tr
                                        key={index}
                                        className="w-max h-[3rem] bg-white"
                                      >
                                        <td className="text-left border border-slate-300">
                                          <div
                                            className="flex w-max h-max space-x-1 font-bold mr-2"
                                            style={{
                                              color: `${item?.ColorText}`,
                                            }}
                                          >
                                            <PersonOutlineTwoToneIcon fontSize="small" />
                                            <p>{item?.FullName}</p>
                                          </div>
                                        </td>
                                        <td className="text-center border border-slate-300">
                                          <div className="flex gap-2 justify-between mt-1">
                                            <label className="my-auto font-medium">
                                              Tổng số giờ:
                                            </label>
                                            <input
                                              className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                              placeholder="Nhập số giờ"
                                              type="number"
                                              value={
                                                useHourSupporter?.find(
                                                  (_item: any) =>
                                                    _item?.id === item?.Id
                                                )?.value
                                              }
                                              onChange={(e: any) => {
                                                _onChangeHoursSupporter(
                                                  e,
                                                  item?.Id
                                                );
                                              }}
                                            />
                                          </div>
                                          {isProtractedTask &&
                                            taskTimeUsed?.map(
                                              (item2: any, index2: any) => (
                                                <div className="flex gap-2 justify-between mt-1">
                                                  <label className="my-auto font-medium">
                                                    {item2.Month}/{item2.Year}:
                                                  </label>
                                                  <input
                                                    className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                                    placeholder="Nhập số giờ"
                                                    type="number"
                                                    value={getValueTime(
                                                      index,
                                                      true,
                                                      index2
                                                    )}
                                                    onChange={(e) =>
                                                      _onChangeTaskTimeUsed(
                                                        e,
                                                        index,
                                                        true,
                                                        index2
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                        </td>
                                        <td className="text-center border border-slate-300">
                                          <div className="flex gap-2 justify-between mt-1">
                                            <label className="my-auto font-medium">
                                              Tổng số phút:
                                            </label>
                                            <input
                                              className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                              placeholder="Nhập số phút"
                                              type="number"
                                              value={
                                                useMinutesSupporter?.find(
                                                  (_item: any) =>
                                                    _item?.id === item?.Id
                                                )?.value
                                              }
                                              onChange={(e: any) => {
                                                _onChangeMinutesSupporter(
                                                  e,
                                                  item?.Id
                                                );
                                              }}
                                            />
                                          </div>
                                          {isProtractedTask &&
                                            taskTimeUsed?.map(
                                              (item2: any, index2: any) => (
                                                <div className="flex gap-2 justify-between mt-1">
                                                  <label className="my-auto font-medium">
                                                    {item2.Month}/{item2.Year}:
                                                  </label>
                                                  <input
                                                    className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                                    placeholder="Nhập số phút"
                                                    type="number"
                                                    value={getValueTime(
                                                      index,
                                                      false,
                                                      index2
                                                    )}
                                                    onChange={(e) =>
                                                      _onChangeTaskTimeUsed(
                                                        e,
                                                        index,
                                                        false,
                                                        index2
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                        </td>
                                        <td className="text-left border border-slate-300">
                                          <button
                                            className="text-red-500 justify-end"
                                            onClick={() =>
                                              _onCheckNguoiLamViec(
                                                item?.Id,
                                                userList
                                              )
                                            }
                                          >
                                            <DeleteTwoToneIcon fontSize="small" />
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )
                              )}
                        </tbody>
                      </table>
                    </div> */}
                  </div>

                  <div className="w-full h-max">
                    <p>Hỗ trợ góp ý</p>
                    {selectedNguoiGopYList === null
                      ? ""
                      : selectedNguoiGopYList?.map((item: any, index: any) => (
                        <>
                          {item?.Id === selectedNguoiNhan?.Id ? (
                            <></>
                          ) : (
                            <div
                              key={index}
                              className="flex w-max h-max text-primary items-center justify-between"
                            >
                              <div
                                key={index}
                                className="flex w-max h-max text-primary items-center justify-between"
                              >
                                <div
                                  className="flex w-max h-max space-x-1 font-bold mr-2"
                                  style={{
                                    color: `${item?.ColorText}`,
                                  }}
                                >
                                  <PersonOutlineTwoToneIcon fontSize="small" />
                                  <p>{item?.FullName}</p>
                                </div>
                                <button
                                  className="text-red-500 justify-end"
                                  onClick={() =>
                                    _onCheckNguoiGopY(item?.Id, userList)
                                  }
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                  <div
                    className={`
                flex-col w-full h-max space-y-1
              `}
                  >
                    {/* <div
                    className={`${isAutoForce === false ? "hidden" : "flex"
                      } w-max h-max space-x-3`}
                  >
                    <input
                      type="checkbox"
                      checked={isForce}
                      onChange={_onChangeIsForce}
                    />
                    <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                      KHÔNG CHO PHÉP TRẢ VIỆC
                    </label>
                  </div> */}
                    <div
                      className={`${isAutoForce === false ? "hidden" : "flex"
                        } w-max h-max space-x-3`}
                    >
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isExChange}
                        onChange={() => {
                          if (isExChange === true) {
                            setIsExChange(false);
                          } else {
                            setIsExChange(true);
                          }
                        }}
                        id="isExChange"
                      // checked={isForce}
                      // onChange={_onChangeIsForce}
                      />
                      <label
                        htmlFor="isExChange"
                        className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                      >
                        CHO PHÉP THOẢ THUẬN THỜI HẠN
                      </label>
                    </div>
                  </div>

                  <div
                    className={`
                        flex-col w-full h-max space-y-1
                      `}
                  >
                    <div
                      className={`${isAutoForce === false ? "hidden" : "flex"
                        } w-max h-max space-x-3`}
                    >
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isEvaluate}
                        onChange={() => {
                          if (isEvaluate === true) {
                            setIsEvaluate(false);
                          } else {
                            setIsEvaluate(true);
                          }
                        }}
                        id="IsEvaluate"
                      // checked={isForce}
                      // onChange={_onChangeIsForce}
                      />
                      <label
                        htmlFor="IsEvaluate"
                        className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                      >
                        CÔNG VIỆC KHÔNG CẦN ĐÁNH GIÁ
                      </label>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  {/* overload */}
                  <div className="flex items-center space-x-1">
                    <CircleIcon className="text-[#00ff00]" />
                    <p>Có thể nhận việc</p>
                  </div>
                  <div className="flex items-center space-x-1">
                    <CircleIcon className="text-[#FFAA00]" />
                    <p>Đang nhiều việc</p>
                  </div>
                  <div className="flex items-center space-x-1">
                    <CircleIcon className="text-[#ff0000]" />
                    <p>Quá tải</p>
                  </div>
                </div>

                {/* <div className="w-full h-max space-y-0.5">
                <label>Mã tham chiếu công việc</label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mã"
                  value={refCode}
                  onChange={(e: any) => _onChangeRefCode(e)}
                />
              </div> */}

                <div className="flex flex-col w-full h-max space-y-0.5">
                  <QuytrinhJSX />
                </div>

                <div className="flex w-full h-max my-1.5">
                  {/* buoc */}
                  <div
                    className={`w-full h-max tableFixHead ${selectedBuocList === null ||
                      selectedBuocList === undefined
                      ? "flex lg:hidden flex-col"
                      : "flex lg:hidden flex-col"
                      }`}
                  >
                    <table className="w-full">
                      <thead className="text-white font-semibold text-center w-full h-[2rem]">
                        <tr>
                          <th className="border border-slate-300">Nội dung</th>
                          <th className="border border-slate-300 w-[1rem]">
                            {/* <button
                    className="
                              flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                              rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                            "
                    onClick={() => _onClickEditBuoc("editBuoc")}
                  >
                    +
                  </button> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                        {selectedBuocList === null ||
                          selectedBuocList === undefined
                          ? ""
                          : selectedBuocList?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className="w-max h-[3rem] bg-white"
                            >
                              <td className="flex flex-col border border-slate-300 space-y-2">
                                <p className="font-bold">
                                  Bước&nbsp;{item?.SortOrder}:
                                </p>
                                <p>{item?.StepName}</p>
                                <select
                                  className="lg:w-full sm:w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  value={item?.UserDo}
                                  onChange={(e: any) => {
                                    e.preventDefault();
                                    _onChangeNguoiPhoiHopEachStep(
                                      item?.SortOrder,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option
                                    className="text-gray-300"
                                    value={""}
                                    disabled
                                  >
                                    --- Chọn người thực hiện ---
                                  </option>
                                  {selectedNguoiLamViecList === null
                                    ? ""
                                    : selectedNguoiLamViecList?.map(
                                      (item: any, index: any) => (
                                        <option
                                          key={index}
                                          value={item?.Id}
                                        >
                                          {item?.FullName}
                                        </option>
                                      )
                                    )}
                                </select>
                                <div className="w-full">
                                  <label>Thời hạn</label>
                                  <DateTimePickerControl
                                    className={
                                      "width-100-percent padding-right-0"
                                    }
                                    value={item?.DeadLine_Step}
                                    onChange={(e: any) => {
                                      _onChangDeadLineStep(
                                        item?.SortOrder,
                                        e
                                      );
                                    }}
                                  />
                                </div>

                                <div className="w-full flex gap-4">
                                  <div className="w-ful">
                                    <label>Số giờ</label>
                                    <input
                                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                      type="number"
                                      onTouchStart={(e: any) =>
                                        onTouchForm(e)
                                      }
                                      value={item?.UseHours}
                                      onChange={(e: any) =>
                                        _onChangUseHoursStep(
                                          item?.SortOrder,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="w-ful">
                                    <label>Số phút</label>{" "}
                                    <input
                                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                      type="text"
                                      onTouchStart={(e: any) =>
                                        onTouchForm(e)
                                      }
                                      value={item?.UseMinutes}
                                      onChange={(e: any) => {
                                        _onChangUseMinutesStep(
                                          item?.SortOrder,
                                          e
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-ful">
                                  <label>Kết quả</label>
                                  <textarea
                                    className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                    onTouchStart={(e: any) => onTouchForm(e)}
                                    value={item?.KetQua}
                                    onChange={(e: any) => {
                                      _onChangKetQuaStep(item?.SortOrder, e);
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="border border-slate-300">
                                <button
                                  className="text-red-500 w-1/6"
                                  onClick={() =>
                                    _onDeleteQuyTrinh(item?.SortOrder)
                                  }
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="my-4 w-full h-max justify-end items-end flex">
                      <button
                        className="
              bg-emerald-500 p-1.5 text-white rounded-md
              hover:bg-emerald-400
            "
                        onClick={() => _onClickEditBuoc("editBuoc")}
                      >
                        Thêm bước
                      </button>
                    </div>
                  </div>
                  {/* <StepListJSX /> */}
                </div>
                <div className="flex w-full h-max my-1.5">
                  {/* ket qua */}
                  <div
                    className={` w-full h-max ${selectedKetQuaList === null
                      ? "flex flex-col"
                      : "flex flex-col"
                      }`}
                  >
                    <table className="w-full">
                      <thead className="text-white font-semibold text-center w-full h-[2rem]">
                        <tr>
                          <th className="border border-slate-300">STT</th>
                          <th className="border border-slate-300 w-full">
                            Kết quả cần đạt
                          </th>
                          <th className="border text-center border-slate-300 w-[1rem]">
                            {/* <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                        {selectedKetQuaList === null ? (
                          <tr></tr>
                        ) : (
                          selectedKetQuaList?.map((item: any, index: any) => (
                            <tr key={index} className="w-max h-[3rem] bg-white">
                              <td className="text-center border border-slate-300">
                                {index + 1}
                              </td>
                              <td className="text-left border border-slate-300">
                                {item?.tenketqua}
                              </td>
                              <td className="border text-center border-slate-300 w-[1rem]">
                                <button
                                  className="text-red-500"
                                  onClick={() =>
                                    _onDeleteKetqua(item?.tenketqua)
                                  }
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>

                    <div className="my-4 w-full h-max justify-end items-end flex">
                      <button
                        className="
                        bg-emerald-500 p-1.5 text-white rounded-md
                        hover:bg-emerald-400
                      "
                        onClick={() => _onClickEditKetqua("editKetqua")}
                      >
                        Thêm kết quả
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-max space-y-4">
              {/* elements */}
              <div className="flex flex-col w-full h-max">
                <label>Mô tả/ Hướng dẫn cách làm</label>
                <textarea
                  className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={description}
                  onChange={(e: any) => _onChangeDescription(e)}
                />
                {/* <TextareaControl
                placeholder="Nhập mô tả"
                value={description}
                onChange={(e: any) => _onChangeDescription(e)}
              /> */}
              </div>
              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={notes}
                  onChange={(e: any) => _onChangeNotes(e)}
                />
                {/* <TextareaControl
                placeholder="Nhập ghi chú"
                value={notes}
                onChange={(e: any) => _onChangeNotes(e)}
              /> */}
              </div>
              <div className="flex w-full h-max">
                {/* elements */}
                <div className="flex flex-col w-1/2 h-max">
                  <div className="flex w-max h-full space-x-2 text-xs">
                    <button
                      className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                      onClick={_onClickChooseFileChat}
                    >
                      <AttachFileIcon fontSize="small" />
                      {/* <p>Chọn tệp tin</p> */}
                      <p>Chọn tệp tin đính kèm</p>
                    </button>
                  </div>
                  <input
                    ref={uploadFileChat}
                    className={`w-max h-max`}
                    multiple
                    type="file"
                    onChange={(e: any) => _onChangeFileImage(e)}
                    hidden
                  />
                  {/* fields of file */}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}
                    <div className="flex w-full h-max text-primary items-center justify-between">
                      <div className="flex flex-col w-full h-max">
                        {filesList.length === 0 ||
                          typeof filesList === "undefined" ? (
                          <></>
                        ) : (
                          filesList?.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <div className="flex w-full h-max space-x-1">
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item?.name}</p>

                                <div className="w-max h-max flex space-x-6">
                                  <button
                                    className="text-green-500 w-1/6 flex"
                                    onClick={() =>
                                      _onClickEditFile(
                                        index,
                                        filesList,
                                        setFilesList
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </button>

                                  <button
                                    className="text-red-500 w-1/6 flex"
                                    onClick={() => _onDeleteElements(index)}
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )
      }

      {/* footer */}
      <div
        style={{
          width: "100%",
          // overflowX: "auto",
        }}
        className={`footer
        flex w-full p-12-16 rounded-b-md pl-4 pr-4 text-sm space-x-4
        ${selectedPhongBan === -1 ||
            selectedNguoiNhan === -1 ||
            (isAutoLoaiViec && selectedLoaiViec === -1) ||
            selectedNguonVanBan === -1 ||
            selectedUuTien === -1 ||
            selectedDoMat === -1 ||
            selectedDauViec === -1 ||
            // selectedQuyTrinh === -1 ||
            selectedDoKho === -1 ||
            deadLine === "" ||
            isLoading === true
            ? "justify-between"
            : "justify-end"
          }
        items-center
      `}
      >
        <div
          className={`
          items-center space-x-1 w-full h-max text-white rounded-md
          ${selectedPhongBan === -1 ||
              selectedNguoiNhan === -1 ||
              (isAutoLoaiViec && selectedLoaiViec === -1) ||
              selectedNguonVanBan === -1 ||
              selectedUuTien === -1 ||
              selectedDoMat === -1 ||
              selectedDauViec === -1 ||
              // selectedQuyTrinh === -1 ||
              selectedDoKho === -1 ||
              deadLine === "" ||
              isLoading === true
              ? "flex"
              : "hidden"
            }
        `}
        >
          {/* <p className="text-red-500 font-semibold text-xs">
            Vui lòng nhập đầy đủ thông tin công việc
          </p> */}
        </div>

        <div className="flex w-max h-max space-x-3">
          <div className="w-max h-max multiple">
            {/* button options */}
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md core-button
              ${isMulti ||
                  (takePermission(20).PerApprove && selectedPhongBan === -1) ||
                  (takePermission(20).PerApprove && selectedNguoiNhan === -1) ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  (takePermission(20).PerApprove && selectedDauViec === -1) ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  taskName === "" ||
                  (takePermission(20).PerApprove && deadLine === "") ||
                  _onCheckValidForHoursUsed() === false ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  isOKTotalTime() == false
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  "bg-slate-300"
                  : "bg-primary hover:bg-sky-400"
                }
            `}
              onClick={() => _onClickSubmit(false)}
              disabled={
                isMulti ||
                  (takePermission(20).PerApprove && selectedPhongBan === -1) ||
                  (takePermission(20).PerApprove && selectedNguoiNhan === -1) ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  (takePermission(20).PerApprove && selectedDauViec === -1) ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  taskName === "" ||
                  (takePermission(20).PerApprove && deadLine === "") ||
                  _onCheckValidForHoursUsed() === false ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  isOkWithHourSupporter === false ||
                  isOKTotalTime() == false
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  true
                  : false
              }
            >
              <AssignmentIcon fontSize="small" />
              <p>Xác nhận</p>
            </button>

            {/* delivery at the moment */}

            {/* options fields */}
            <div
              className={`
              text-xs font-semibold
              ${selectedPhongBan === -1 ||
                  selectedNguoiNhan === -1 ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  selectedNguonVanBan === -1 ||
                  selectedUuTien === -1 ||
                  selectedDoMat === -1 ||
                  selectedDauViec === -1 ||
                  selectedDoKho === -1 ||
                  deadLine === "" ||
                  taskName === "" ||
                  _onCheckValid() === false ||
                  isLoading === true
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  "hidden"
                  : "multiple-content"
                }
            `}
            >
              {/* delete */}

              {/* detail */}
            </div>
          </div>
          {takePermission(20).PerApprove === true && (
            <button
              className={`items-center space-x-1 w-max flex h-max p-2 bg-emerald-500 text-white rounded-md core-button
              ${(!isMulti && selectedPhongBan === -1) ||
                  (isMulti && listMultiUser && listMultiUser.length <= 0) ||
                  (!isMulti &&
                    (selectedNguoiNhan === -1 || !selectedNguoiNhan)) ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  selectedDauViec === -1 ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  taskName === "" ||
                  deadLine === "" ||
                  (isAutoRequiredUseHours &&
                    (useHours
                      ? useHours === 0 || !useHours
                      : useMinutes === 0 || !useMinutes)) ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  isOkWithHourSupporter === false ||
                  isOKTotalTime() == false
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  "bg-slate-300"
                  : "bg-emerald-500 hover:bg-emerald-400"
                }         
            `}
              onClick={_onClickSubmitNow}
              disabled={
                (!isMulti && selectedPhongBan === -1) ||
                  (isMulti && listMultiUser && listMultiUser.length <= 0) ||
                  (!isMulti &&
                    (selectedNguoiNhan === -1 || !selectedNguoiNhan)) ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  selectedDauViec === -1 ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  deadLine === "" ||
                  taskName === "" ||
                  (isAutoRequiredUseHours &&
                    (useHours
                      ? useHours === 0 || !useHours
                      : useMinutes === 0 || !useMinutes)) ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  isOkWithHourSupporter === false ||
                  isOKTotalTime() === false
                  ? true
                  : false
              }
            >
              <SendTwoToneIcon fontSize="small" />

              <p>Giao việc ngay</p>
            </button>
          )}

          {userData?.Data[0]?.IsActiveOffice &&
            !takePermission(20).PerApprove ? (
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 bg-amber-500 text-white rounded-md core-button
                ${isMulti ||
                  (takePermission(20).PerApprove && selectedPhongBan === -1) ||
                  (takePermission(20).PerApprove && selectedNguoiNhan === -1) ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  (takePermission(20).PerApprove && selectedDauViec === -1) ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  taskName === "" ||
                  (takePermission(20).PerApprove && deadLine === "") ||
                  _onCheckValidForHoursUsed() === false ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  isOkWithHourSupporter === false
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  "bg-slate-300"
                  : "bg-amber-500 hover:bg-amber-400"
                }
            `}
              onClick={_onClickSwitchToBoss}
              disabled={
                isMulti ||
                  (takePermission(20).PerApprove && selectedPhongBan === -1) ||
                  (takePermission(20).PerApprove && selectedNguoiNhan === -1) ||
                  (isAutoLoaiViec && selectedLoaiViec === -1) ||
                  (isAutoOrigin && selectedNguonVanBan === -1) ||
                  (isAutoPriority && selectedUuTien === -1) ||
                  (isAutoSecurity && selectedDoMat === -1) ||
                  (takePermission(20).PerApprove && selectedDauViec === -1) ||
                  (isAutoDifference && selectedDoKho === -1) ||
                  taskName === "" ||
                  (takePermission(20).PerApprove && deadLine === "") ||
                  _onCheckValidForHoursUsed() === false ||
                  _onCheckValid() === false ||
                  isLoading === true ||
                  isOkWithHourSupporter === false
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  true
                  : false
              }
            >
              <CameraFrontTwoToneIcon fontSize="small" />

              <p>Chuyển sếp</p>
            </button>
          ) : null}

          <button
            className="
              hidden lg:flex md:flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200 core
            "
            onClick={_onClickCaching}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div >
  );
};

export default CreateNewTask;
