import React from "react";

// ui importing
import HomeUI from "../../../ui/homeUI/_index";

const ProposeGet = () => {
  return (
    <>
      <HomeUI />
    </>
  )
}

export default ProposeGet;

