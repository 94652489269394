import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";

const linkIncludes = [
  "/working/receiver/task-myself",
  "/working/task-proposed",
  "/working/new-task",
  "/working/task-change-deadline",
  "/working/task-need-give",
  "/working/task-finish",
  "/working/receiver/task-new",
  "/working/receiver/task-waiting",
  "/working/receiver/task-received",
  "/working/task-recovery",
  "/working/receiver/task-recovery",
  "/working/receiver/task-returnwork",
  "/working/task-delay",
  "/working/receiver/task-delay",
  "/working/task-return",
];

// const firstDayOfMonth = (link: any, month?: any, year?: any) => {
//   if (linkIncludes.find((p) => p == link)) {
//     if (month == null || year == null) {
//       return "";
//     } else {
//       return new Date(year, month - 1, 1, 0, 0, 0).toISOString().slice(0, 16);
//     }
//   } else {
//     if (month == null || year == null) {
//       return new Date(
//         new Date().getFullYear(),
//         new Date().getMonth(),
//         1,
//         7,
//         0,
//         0
//       )
//         .toISOString()
//         .slice(0, 16);
//     } else {
//       return new Date(year, month - 1, 1, 7, 0, 0).toISOString().slice(0, 16);
//     }
//   }
// };

const firstDayOfTheMonth = (link: any, month: any, year: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
    // if (month == null || year == null) {
    //   return "";
    // } else {
    //   return new Date(year, month - 1, 1, 0, 0, 0).toISOString().slice(0, 16);
    // }
  } else {
    return new Date(year, month, 1, 7, 0, 0).toISOString().slice(0, 16);
    // if (month == null || year == null) {
    //   return new Date(
    //     new Date().getFullYear(),
    //     new Date().getMonth(),
    //     1,
    //     7,
    //     0,
    //     0
    //   )
    //     .toISOString()
    //     .slice(0, 16);
    // } else {
    //   return new Date(year, month - 1, 1, 7, 0, 0).toISOString().slice(0, 16);
    // }
  }
};

const currentDate = (link: any, month?: any, year?: any) => {
  if (linkIncludes.find((p) => p == link)) {
    if (month == null || year == null) {
      return "";
    } else {
      return new Date(year, month, 1, 7, 0, 0).toISOString().slice(0, 16);
    }
  } else {
    if (
      month == null ||
      year == null ||
      (month - 1 == new Date().getMonth() && year == new Date().getFullYear())
    ) {
      return new Date(
        new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
      )
        .toISOString()
        .slice(0, 16);
    } else {
      return new Date(year, month, 1, 7, 0, 0).toISOString().slice(0, 16);
    }
  }
};

const firstDayOfYear = (link: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
      .toISOString()
      .slice(0, 24);
  }
};

const lastDayOfYear = (link?: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0)
      .toISOString()
      .slice(0, 24);
  }
};

const convertDateToISOString = (date: any) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 7, 0, 0)
    .toISOString()
    .slice(0, 24);
};
const convertFullDateToISOString = (date: any) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.Ho,
    0,
    0
  )
    .toISOString()
    .slice(0, 24);
};

const currentMonth = (link: string) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return (new Date().getMonth() + 1).toString();
  }
};

const currentYear = (link: string) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date().getFullYear().toString();
  }
};

const firstDayMonthSchedule = () => {
  const newDate = new Date();
  newDate.setMonth(newDate.getMonth());
  const first = startOfWeek(new Date(startOfMonth(newDate)), {
    weekStartsOn: 0,
  });
  return HandleDate.convertDateToISOString(first);
};

const lastDayMonthSchedule = () => {
  const newDate = new Date();
  newDate.setMonth(newDate.getMonth());
  const end = endOfWeek(new Date(endOfMonth(newDate)), {
    weekStartsOn: 0,
  });
  end.setDate(end.getDate() + 1);
  return HandleDate.convertDateToISOString(end);
};

function firstDayOfMonth(month: any, year: any): Date {
  const intMonth = parseInt(month);
  const intYear = parseInt(year);
  return new Date(intYear, intMonth - 1, 1);
}

function firstDayOfNextMonth(month: any, year: any): Date {
  const intMonth = parseInt(month);
  const intYear = parseInt(year);
  if (month === 12) {
    return firstDayOfMonth(1, intYear + 1);
  } else {
    return firstDayOfMonth(intMonth + 1, intYear);
  }
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function getQuarterStartDate(quarter: any, year: any): Date {
  const month =
    quarter == "1"
      ? 1
      : quarter == "2"
      ? 4
      : quarter == "3"
      ? 7
      : quarter == "4"
      ? 10
      : "";

  return new Date(`${year}-${month}-01`);
}

function getQuarterEndDate(quarter: any, year: any): Date {
  const month =
    quarter == "1"
      ? 4
      : quarter == "2"
      ? 7
      : quarter == "3"
      ? 10
      : quarter == "4"
      ? 1
      : "";
  if (quarter == "4") return new Date(`${year + 1}-${month}-01`);
  return new Date(`${year}-${month}-01`);
}

function getQuarterString(quarter: any, year: any) {
  const startDate = `${HandleDate.formatDate(
    HandleDate.getQuarterStartDate(quarter, year)
  )} 00:00:00.000`;
  let endDate = `${HandleDate.formatDate(
    HandleDate.getQuarterEndDate(quarter, year)
  )} 00:00:00.000`;

  return `${quarter},${startDate},${endDate}`;
}

const HandleDate = {
  getQuarterStartDate,
  getQuarterEndDate,
  formatDate,
  firstDayOfMonth,
  firstDayOfNextMonth,
  firstDayOfTheMonth,
  firstDayOfYear,
  currentDate,
  lastDayOfYear,
  convertDateToISOString,
  currentYear,
  currentMonth,
  lastDayMonthSchedule,
  firstDayMonthSchedule,
  getQuarterString,
};

export default HandleDate;
