import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { ModalController } from "../../../ui/homeUI/_index";
import { PROPOSEGETLIST, PROPOSE_CONFIRM } from "../../../apis/_index";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";
import {
  ONLOAD_DATA_LIST,
  ONLOAD_TOTAL_ITEM,
} from "../../../redux/reducers/proposeReducer/_index";
import LoaderSpinner from "../../loader-spinner/_index";

const ConfirmPropose = (props: any) => {
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rateValue, setRateValue] = React.useState<number | null>(0);

  const dispatch = useDispatch();
  const proposeData = useSelector((state: any) => state.proposeReducer);

  const _loadData = () => {
    let body: any = {
      Page: proposeData.pagination.currentPage,
      Limit: proposeData.pagination.itemPerPage,
      Name: proposeData.searchFilter.name,
      Status: proposeData.searchFilter.selectedStatus,
      PriorityLevelID: proposeData.searchFilter.selectedPrior,
      UserAdd: proposeData.searchFilter.selectedSender,
      UserCheck: proposeData.searchFilter.selectedReceiver,
      DateAdd_From: proposeData.searchFilter.fromDate,
      DateAdd_To: proposeData.searchFilter.toDate,
      ProposeTypeID: proposeData.searchFilter.selectedType,
    };

    if (proposeData.searchFilter.selectedPrior === "") {
      delete body.PriorityLevelID;
    }
    if (proposeData.searchFilter.name === "") {
      delete body.Name;
    }
    if (proposeData.searchFilter.selectedStatus === "") {
      delete body.Status;
    }
    if (proposeData.searchFilter.selectedSender === "") {
      delete body.UserAdd;
    }
    if (proposeData.searchFilter.selectedReceiver === "") {
      delete body.UserCheck;
    }
    if (proposeData.searchFilter.selectedType === "") {
      delete body.ProposeTypeID;
    }
    if (proposeData.searchFilter.fromDate === "") {
      delete body.DateAdd_From;
    }
    if (proposeData.searchFilter.toDate === "") {
      delete body.DateAdd_To;
    }

    const takeData = async () => {
      try {
        await ServiceUtils.postV2(PROPOSEGETLIST, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (((res || {}).data || {}).StatusCode === 200) {
            dispatch(
              ONLOAD_DATA_LIST((((res || {}).data || {}).Data || {}).data || [])
            );
            dispatch(
              ONLOAD_TOTAL_ITEM((((res || {}).data || {}).Data || {}).count)
            );
          }
        });
      } catch (error: any) {}
    };
    takeData();
  };

  // funcs
  const _onClickOK = async () => {
    setIsLoading(true);

    const body: any = {
      ID: taskId,
      Star: rateValue,
    };

    try {
      await ServiceUtils.postV2(PROPOSE_CONFIRM, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.data.StatusCode === 200) {
          toast.success("Đã duyệt đề xuất");
          _loadData();
          context.handleClose();
        } else {
          toast.error(res.data.Message || "Duyệt đề xuất thất bại");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className=" flex flex-col bg-white lg:w-[30rem] md:w-[30rem] h-[18rem] w-screen rounded-md ">
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Xác nhận duyệt đề xuất</p>
        <button className="" onClick={handleClose}>
          X
        </button>
      </div>

      {/* content */}
      <div className="grid grid-cols-1 lg:w-[30rem] md:w-[30rem] w-full h-[calc(100%-2rem)] text-center content-center text-xl">
        <p>
          Đánh giá đề xuất&nbsp;<span className="text-red-500">(*)</span>
        </p>
        <Box
          sx={{
            "& > legend": { mt: 2 },
          }}
          className="scale-150"
        >
          <Typography component="legend"></Typography>
          <Rating
            name="simple-controlled"
            value={rateValue}
            onChange={(event, newValue) => {
              setRateValue(newValue);
            }}
          />
        </Box>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer  rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${
            rateValue === null || rateValue === 0 || isLoading
              ? "bg-slate-300"
              : "bg-green-500 hover:bg-green-400"
          }
        `}
            disabled={rateValue === null || rateValue === 0 || isLoading}
            onClick={_onClickOK}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <>
                <CheckIcon fontSize="small" />
                <p>Xác nhận</p>
              </>
            )}
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-red-500
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPropose;
